import Box from '@mui/material/Box'
import React from 'react'
import { CartMiniView } from '@ggs/components/ecomm'
import { useSelector } from '@ggs/store'

/**
 * Sidebar content component resolution.
 * @return {JSX.Element}
 */
const SidebarContent = () => {
  const sidebar = useSelector((state) => state.ui?.sidebar)

  // Resolve content component
  const resolveContentComponent = () => {
    switch (sidebar.contentType) {
      case 'cart':
        return CartMiniView
    }
  }

  const ContentComponent = resolveContentComponent()

  return (
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
    >
      {ContentComponent && (<ContentComponent/>)}
    </Box>
  )
}

export default SidebarContent

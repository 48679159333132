// @ts-nocheck
import React, { useContext } from 'react'
import { useOneTrustLinks } from '@ggs/components/onetrust/useOneTrust'

export const OneTrustContext = React.createContext({
  privacySettingsModalLink: null,
  privacySettingsModal: null,
})

/**
 * @typedef {Object} OneTrustContextProvider
 * @property {JSX.Element} children
 */
/**
 * @param {OneTrustContextProvider} props
 * @return {JSX.Element}
 */

export const OneTrustContextProvider = ({
  children
}) => {
  const {
    PrivacySettingsModalLink,
    CookieListModalLink,
    CookieListModal
  } = useOneTrustLinks()
  return (
    <OneTrustContext.Provider value={{
      PrivacySettingsModalLink,
      CookieListModalLink,
      // api
    }}>
      {children}
      {CookieListModal}
    </OneTrustContext.Provider>
  )
}

export const useOneTrustContext = () => {
  const oneTrustContext = useContext(OneTrustContext)
  const {
    PrivacySettingsModalLink,
    CookieListModalLink
  } = oneTrustContext

  return {
    PrivacySettingsModalLink,
    CookieListModalLink,
  }
}

export default OneTrustContextProvider

// @ts-nocheck

export const syntheticInputEvent = (name, params = {}, eventType = 'change') => {
  const i = document.createElement('input')
  i.name = name
  Object.assign(i, params)
  const e = new Event(eventType)
  Object.defineProperty(e, 'target', { writable: false, value: i })
  return e
}

export const forceEvent = (input, value, eventType = 'change') => {
  input.value = value
  const e = new Event(eventType)
  Object.defineProperty(e, 'target', { writable: false, value: input })
  input.dispatchEvent(e)
  return e
}

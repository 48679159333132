// @ts-nocheck
import React, {forwardRef, useLayoutEffect, useState} from 'react'
import * as PropTypes from 'prop-types'
// Components
import MuiCheckbox from '@mui/material/Checkbox'
import MuiSwitch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import {Forms} from '@ggs/forms/schema'
import {events} from '@ggs/utils'
// Data
// Assets
import './_Checkbox.scss'
import './_Switch.scss'

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  labelPlacement: PropTypes.string,
}

const defaultProps = {
  label: '',
  name: '',
  value: false,
  disabled: false,
  labelPlacement: Forms.LabelPlacement.top,
}

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef(
  (
    {
      value,
      onChange,
      className,
      label,
      name,
      isSwitch = false,
      disabled,
      defaultValue,
      labelPlacement,
      inputProps,
      checked,
      defaultChecked,
    },
    ref
  ) => {
    const [isChecked, setIsChecked] = useState((checked ?? defaultValue) || false)
    const handleChange = (event) => {
      // console.log('State: ', event.target.checked)
      setIsChecked(event.target.checked)
      // console.log('change', event, ref)
      // debugger // eslint-disable-next-line no-debugger
      onChange && onChange(event)
    }

    const controllerProps = {
      defaultChecked,
      disabled,
      inputProps,
      name,
      onChange: handleChange,
      value,
    }

    if (isChecked) {
      controllerProps.checked = isChecked
    }

    const reset = () => {
      handleChange(events.syntheticInputEvent(name, {checked: false}))
    }
    // console.log(`Checkbox::render ${name}=${isChecked}`, {
    // labelPlacement,
    // controllerProps,
    // value,
    // isChecked,
    // disabled,
    // })
    /*const focusClass = 'focused';
    const inputFocus = (e) => {
      const classList = e.target.parentElement.classList;
      classList.add(focusClass)
      console.log('Checkbox::inputFocus', classList.contains(focusClass))
    }

    const inputBlur = (e) => {
      const classList = e.target.parentElement.classList;
      classList.remove(focusClass)
      console.log('Checkbox::inputBlur', !classList.contains(focusClass))
    }

    useLayoutEffect(() => {
      const input = ref.current.querySelector('input')
      console.log('Checkbox::useLayoutEffect', {input})
      input.addEventListener('focus', inputFocus)
      input.addEventListener('blur', inputBlur)
      return () => {
        input.removeEventListener('focus', inputFocus)
        input.removeEventListener('blur', inputBlur)
      }
    }, [])*/

    return (
      <>
        <FormControlLabel
          ref={ref}
          className={className}
          control={
            isSwitch ? <MuiSwitch {...controllerProps} /> : <MuiCheckbox {...controllerProps} />
          }
          label={label}
          labelPlacement={labelPlacement}
        />
        <button
          className="form-field__reset"
          data-name={name}
          type="button"
          aria-hidden
          onClick={reset}
        />
      </>
    )
  }
)

// Export Props
Checkbox.defaultProps = defaultProps
Checkbox.propTypes = propTypes
// Export Component
export default Checkbox

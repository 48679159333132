// @ts-nocheck
import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import { GridContainer, LanguageSelector } from '@ggs/components/layout'
import { CustomerSelector, StoreSelector } from '@ggs/components/nav'
import { useSelector } from '@ggs/store'
// Assets
import './Header.scss'
import { colors, common } from '@ggs/styles'
import { useWindowSize } from '@ggs/hooks'
import { Memoized } from '@ggs/components/common'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 * @typedef {import('@ggs/types').Store} Store
 * @typedef {import('@ggs/types').CustomerGroup} CustomerGroup
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */

/**
 * @return {JSX.Element}
 */
export default function HeaderSelectors() {
  const layoutMeta = useSelector((state) => state.ui.layoutMeta)
  const border = common.borderLightGrey
  const windowSizes = useWindowSize()

  // Memoized data
  const customerType = useSelector((state) => state.commerce.customer.customerType)
  const countryCode
    = layoutMeta?.currentStore?.country?.length === 1
      ? layoutMeta?.currentStore?.country?.[0]?.iso2
      : ''

  const sx = useMemo(
    () => ({
      p: 0,
      py: {
        xs: 2,
        md: 0,
      },
      px: {
        md: 6,
      },
      borderBottom: {
        xs: border,
        md: 0,
      },
      '.MuiButton-root:focus': {
        borderRadius: 0,
        mt: '1px',
        mb: '-1px',
        outline: `1px dotted ${colors.white} !important`,
      },
      '.button, .button.MuiButton-text': {
        py: 1,
        span: {
          p: 0,
        },
      },
      '& .button': windowSizes.md
        ? {
          '&, span': {
            color: `${colors.white} !important`,
          },
        }
        : {
          '&, span': {
            color: `${colors.black} !important`,
          },
          svg: {
            fill: `${colors.black} !important`,
          },
        },
    }),
    [windowSizes.md]
  )

  return (
    <GridContainer
      className={'header-selectors'}
      fullWidthBackground={windowSizes.md}
      backgroundColor={windowSizes.md ? colors.darkGrey : null}
      sx={sx}
    >
      <Grid
        container
        justifyContent={{
          xs: 'space-between',
          md: 'end',
        }}
      >
        <Grid
          className={'header-selector__items'}
          item
          sx={{
            flex: {
              xs: '0 0 100%',
              md: '0 0 auto',
            },
          }}
        >
          <Memoized deps={[customerType]}>
            <CustomerSelector layoutMeta={layoutMeta}/>
          </Memoized>
        </Grid>
        <Grid item xs="auto">
          <Memoized deps={[countryCode]}>
            <StoreSelector layoutMeta={layoutMeta}/>
          </Memoized>
        </Grid>
        <LanguageSelector layoutMeta={layoutMeta}/>
      </Grid>
    </GridContainer>
  )
}

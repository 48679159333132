import { resetOrderPaymentMethod } from '@ggs/commercelayer'
import { StripePaymentForm, StripePaymentReview } from '@ggs/components/ecomm'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import StripeLoader from '@ggs/components/ecomm/Checkout/elements/payment/StripeLoader'
import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'
import usePaymentSource from '@ggs/components/hooks/usePaymentSource'
import { useSelector } from '@ggs/store'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js'
import { delay, noop } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

/**
 * @typedef {import("@commercelayer/sdk").Order} Order
 */
const {
  PAYMENT,
} = CHECKOUT_STEP_ENUMS

/**
 * @typedef {Object} StripePaymentProviderProps
 * @property {String} clientSecret
 * @property {String} publicKey
 * @property {function} onSuccess
 * @property {Order} order
 * @property {JSX.Element} paypalButton
 * @property {function} scrollToTopOfForm
 */

/**
 * Stripe Payment Details Form.
 * @param {StripePaymentProviderProps} props
 * @return {JSX.Element}
 */
export default function StripePaymentProvider({
  order,
  clientSecret,
  publicKey,
  onSuccess = noop,
  paypalButton,
  scrollToTopOfForm,
}) {
  const [stripeClient, setStripeClient] = useState(null)
  const layoutMeta = useSelector((state) => state.ui.layoutMeta)
  const { resetPaymentSource } = usePaymentSource()
  const dispatch = useDispatch()

  const {
    markStepComplete,
    handlers: { goToReview },
    clClient
  } = useCheckoutContext()

  const goToNextStep = () => {
    markStepComplete(PAYMENT)
    delay(() => {
      goToReview()
    }, 350)
  }
  const initStripeClient = async () => {
    // Already initialized.
    if (stripeClient) {
      return
    }

    try {
      const client = await loadStripe(publicKey)
      setStripeClient(client)
    } catch (e) {
      console.log('Stripe client initialization failed', e)
    }
  }

  /**
   * @return {StripeElementLocale}
   */
  const getCurrentLocale = () => {
    /**
     * @type {'auto'}
     */
    const defaultLocale = 'auto'
    return (/** @type {StripeElementLocale} */
      (layoutMeta?.currentStore?.language?.id) || defaultLocale
    )
  }

  const options = {
    clientSecret,
    locale: getCurrentLocale(),
  }

  useEffect(() => {
    if (stripeClient) {
      return
    }
    if (!stripeClient && clientSecret && publicKey) {
      initStripeClient().then(() => {
        console.log('Stripe client is initialized')
      })
    } else if (!clientSecret || !paymentSourceMethodId) {
      // payment source has been corrupted, bail out.
      dispatch(resetOrderPaymentMethod({ clClient, order }))
      resetPaymentSource()
    }
  }, [clientSecret, publicKey])

  const [paymentSourceMethodId, setPaymentSourceMethodId] = useState(
    // @ts-ignore
    order?.payment_source?.payment_method?.id
  )

  useEffect(() => {
    // @ts-ignore
    const paymentId = order?.payment_source?.payment_method?.id
    if (
      paymentId
      && paymentSourceMethodId !== paymentId
      && order?.payment_source?.type !== 'paypal_payments'
    ) {
      goToNextStep()
    } else {
      setPaymentSourceMethodId(paymentId)
    }
    // @ts-ignore
  }, [order?.payment_source?.payment_method?.id, order?.payment_source?.type])

  return stripeClient && clientSecret && publicKey ? (
    <>
      {paymentSourceMethodId ? (
        <StripePaymentReview order={order} onSuccess={onSuccess}
          paypalButton={paypalButton}/>
      ) : (
        <Elements stripe={stripeClient} options={options}>
          <StripePaymentForm
            onSuccess={goToNextStep}
            paypalButton={paypalButton}
            scrollToTopOfForm={scrollToTopOfForm}
          />
        </Elements>
      )}
    </>
  ) : (
    <StripeLoader paypalButton={paypalButton}/>
  )
}

// @ts-nocheck
import { OrderSummary, PurchasePolicy, TermsOfSale } from '@ggs/components/ecomm'
import StepperControl from '@ggs/components/ecomm/Checkout/elements/StepperControl'
import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'
import { OrderInformation, PaymentInformation, Review } from '@ggs/components/ecomm/CheckoutSteps'
import { useSelector } from '@ggs/store'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useLayoutEffect } from 'react'

const { ORDER: SHIPPING, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS

const CurrentStepRender = ({ CHECKOUT_STEPS_COMP }) => {
  const {
    currentStepName,
  } = useSelector((state) => state.checkout)
  return CHECKOUT_STEPS_COMP?.[currentStepName] ?? CHECKOUT_STEPS_COMP[SHIPPING]
}

// should we pass params or use context.
// this is where we manage the multiple orders,
export const CheckoutLayoutBuilder = ({
  order,
  checkoutTermsOfSale,
  checkoutDisclaimer,
  currentStepName,
  currentStepperIndex,
  stepperControlProps,
  handlers,
  orderComplete,
  ...props
}) => {
  const CHECKOUT_STEPS_COMP = {
    // [AUTH]: <CheckoutAs order={inProgressOrder}
    //   handlers={handlers} {...props} />,
    [SHIPPING]: <OrderInformation {...props} order={order}
      handlers={handlers}/>,
    [PAYMENT]: <PaymentInformation {...props} order={order}
      handlers={handlers}/>,
    [REVIEW]: <Review {...props} order={order} handlers={handlers}/>,
    [COMPLETE]: (
      <Box sx={{ mx: 'auto' }} className={'loading-indicator'}>
        <CircularProgress size={60}/>,
      </Box>
    )
  }

  return (
    <>
      {checkoutTermsOfSale && <TermsOfSale text={checkoutTermsOfSale}/>}
      <InProgressOrderLayout
        order={order}
        currentStepName={currentStepName}
        checkoutDisclaimer={checkoutDisclaimer}
        checkoutTermsOfSale={checkoutTermsOfSale}
        stepperControlProps={stepperControlProps}
      >
        <CurrentStepRender CHECKOUT_STEPS_COMP={CHECKOUT_STEPS_COMP}
          currentStepName={currentStepName}/>
      </InProgressOrderLayout>
    </>
  )
}

const InProgressOrderLayout = (
  {
    order,
    checkoutDisclaimer,
    checkoutTermsOfSale,
    currentStepName,
    stepperControlProps,
    children,
  }) => {

  // While on inProgress Checkout flow all T&C, policy links, etc.
  // must send to new tabs.
  useLayoutEffect(() => {
    // @ts-ignore
    const links = window?.document?.querySelectorAll('a:not([target])') || []
    links.forEach((/** @type Element */ link) => {
      link.setAttribute('target', '_blank')
      link.classList.add('a__proceed')
    })
  }, [currentStepName])

  useLayoutEffect(() => {
    const links = document.querySelectorAll('a:not(.btn,.a__button,.a__proceed)')
    links.forEach((/** @type Element */ link) => {
      link.setAttribute('target', '_blank')
      link.classList.add('a__proceed')
    })
  }, [currentStepName])

  return (
    <>
      <StepperControl {...stepperControlProps}/>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          {children}
        </Grid>
        <Grid item xs={12} lg={4}>
          <OrderSummary order={order} variant={'checkout'}/>
          <PurchasePolicy disclaimer={checkoutDisclaimer}/>
        </Grid>
      </Grid>
    </>
  )
}

import React from 'react'
import { uniqueId } from 'lodash'
import Box from '@mui/material/Box'
import { GridContainer } from '@ggs/components/layout'
import Tab from '../Tab'
import Tabs from './Tabs'
import { Accordion } from '@ggs/components/common'
import { zIndex } from '@ggs/styles'

// Assets

/**
 * @typedef {import("../Tab").TabProps} TabProps
 * @typedef {import('@ggs/types').GridContainer} GridContainer
 */
/**
 * @typedef {Object} TabsProps
 * @property {Array<TabProps>=} tabs
 * @property {Boolean=} useDivider
 * @property {GridContainer=} background
 */
/**
 *
 * @param {TabsProps} props
 * @return {JSX.Element}
 */
export default function TabsWithMobileAccordions({ tabs, useDivider = false, background }) {
  // eslint-disable-next-line prefer-rest-params
  // console.log('Tabs render', arguments[0]) //, JSON.stringify(arguments[0]))

  if (!Array.isArray(tabs) || !tabs.length) {
    return null
  }

  return (
    <GridContainer {...background} className="tabs" useDivider={useDivider} sx={{
      mb: {
        xs: 5,
        md: 10,
      }
    }}>
      <>
        <Box
          className="tabs__content-desktop"
          sx={{
            zIndex: zIndex.zContent,
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <Tabs tabs={tabs} />
        </Box>
        <Box
          className="tabs__content-mobile"
          sx={{
            display: {
              md: 'none',
            },
            '& > div:last-of-type': {
              marginBottom: 0,
            },
          }}
        >
          {tabs.map(({ id, label, tabContent, customerGroup }, index) => {
            return (
              <Accordion
                key={uniqueId(`tab-mobile-${id}`)}
                id={id}
                label={label}
                content={<Tab tabContent={tabContent} />}
                customerRole={customerGroup?.tid}
                defaultExpanded={index === 0}
              />
            )
          })}
        </Box>
      </>
    </GridContainer>
  )
}

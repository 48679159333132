import React from 'react'
import { CardGrid } from '@ggs/components/grids'
import Box from '@mui/material/Box'

/**
 * @typedef {import("@ggs/components/common/ImageTextItem/ImageTextItem").ImageTextItemProps} ImageTextItemProps
 */
/**
 * @typedef {Object} ImageAndTextProps
 * @property {Array<ImageTextItemProps>=} imageTextItems
 */
/**
 * ImageAndText component.
 * @param {ImageAndTextProps} props
 * @return {JSX.Element}
 */
export default function ImageText({ imageTextItems = [] }) {
  return (
    <Box
      className="image-text"
      sx={{
        mb: 2,
        '.card-grid__item': {
          '&, *': {
            background: 'transparent',
          },
        },
      }}
    >
      <CardGrid items={imageTextItems} component={CardGrid.component.ImageTextItem} />
    </Box>
  )
}

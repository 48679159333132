// @ts-nocheck
import React from 'react'
import { GridContainer, Layout, PageHeader } from '@ggs/components/layout'
import Box from '@mui/material/Box'
import { breakpoints, colors, rem, zIndex } from '@ggs/styles'
import { Component } from '@ggs/gatsby/components'
import { Carousel } from '@ggs/components/banners'
import { uniqueId } from 'lodash'
import { ArticleCard } from '@ggs/components/cards'
import dayjs from 'dayjs'

/**
 * @typedef {import('@ggs/types').ArticleCard} ArticleCard
 * @typedef {import('@ggs/types').EventCard} EventCard
 */

/**
 *
 * @param {Object=} site
 * @param {String=} accentVariant
 * @param {Object=} pageContext
 * @param {Object=} props
 * @return {JSX.Element}
 * @constructor
 */
export default function NewsPage({
  queries: { site = '' } = {},
  accentVariant,
  pageContext,
  ...props
}) {
  const { title, subtitle, footerContent, /** @type {{article: Array<ArticleCard>, event: Array<EventCard>}} */ items }
    = pageContext.data

  const sortedItems = items?.article?.sort((a, b) =>
    dayjs(b.publishedDate).diff(dayjs(a.publishedDate)))

  const listItems = sortedItems.map((item) => ({ ...item, variant: 'listing' }))

  const carouselItems = [sortedItems?.[0], sortedItems?.[1], sortedItems?.[2]].map((item) => ({
    ...item,
    variant: 'carousel',
  }))

  const sxProps = {
    position: 'relative',
    zIndex: zIndex.zOverlay,
    '.article-card': {
      width: 856,
      maxWidth: '100%',
      pt: {
        xs: 3,
        md: 5,
      },
      pb: {
        xs: 2,
        md: 3,
      },
      mx: 'auto',
      mb: {
        xs: 2,
        md: 2,
      },
      '&:last-of-type': {
        mb: 0,
      },
      '.article-card__content': {
        p: 0,
      },
      '.event-card__content > div': {
        m: 0,
      },
      '.article-card__copy': {
        ml: 0,
        mb: {
          xs: 2,
          md: 0,
        },
      },
    },
  }

  return (
    <Layout
      className="news-landing-page landing-page"
      site={site}
      pageContext={pageContext}
      backgroundVariant={accentVariant}
    >
      <PageHeader title={title} subtitle={subtitle} backgroundVariant={accentVariant}/>
      <Carousel
        carouselItems={carouselItems}
        variant={Carousel.variant.landingPageHeroes}
        component={'ArticleCarouselItem'}
      />
      {listItems.length > 0 && (
        <GridContainer>
          <Box
            sx={{ ...sxProps }}
          >

            {listItems
              .map((item) => (
                <ArticleCard
                  key={uniqueId(`list-item_${item.id}`)}
                  {...item}
                  variant={'listing'}
                />
              ))}
          </Box>
        </GridContainer>
      )}
      <GridContainer
        backgroundColor={colors.pageBackgroundBlue}
        fullWidthBackground
        divider={true}
        sx={{
          '& .grid-container': {
            maxWidth: rem(breakpoints.xl),
            py: {
              xs: 6,
              md: 0,
            },
            px: {
              md: 6,
            },
          },
        }}
      >
        {footerContent?.[0] && <Component className="landing-page__cta" {...footerContent?.[0]} />}
      </GridContainer>
    </Layout>
  )
}

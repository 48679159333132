import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'
import { useDispatch } from '@ggs/store'
import { usePaymentSourceDispatcher } from '@ggs/components/ecomm/Customer/usePaymentSourceDispatcher'
import { noop } from 'lodash'

const { PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS
/**
 * @typedef {Object} PaymentSourceHook
 * @property {function} resetPaymentSource
 */

/**
 * Handle CL payment source actions dispatching for reuse.
 * @return {PaymentSourceHook}
 */
export default function usePaymentSource() {
  const dispatch = useDispatch()
  const {
    order,
    currentStepName,
    resetStepByName,
    handlers: { goToPayment },
    clClient
  } = useCheckoutContext()

  const {
    resetInitDefaultPaymentMethod,
    initDefaultPaymentMethod
  } = usePaymentSourceDispatcher({ onSuccess: noop, onError: noop })

  /**
   * Reset order payment source.
   */
  const resetPaymentSource = () => {
    // clClientFactory.setToken(token)
    // const clClient = clClientFactory.getClient()
    resetStepByName(PAYMENT)
    resetInitDefaultPaymentMethod()

    if (currentStepName === REVIEW || currentStepName === COMPLETE) {
      goToPayment()
    }
    // resetSteps(currentStepIndex)
  }

  const data = {
    resetPaymentSource,
  }

  return data
}

import React from 'react'

// Assets.
import { Text } from '@ggs/components/paragraphs'
import { Accordion } from '@ggs/components/common'
import { uniqueId } from 'lodash'
import striptags from 'striptags'
/**
 * @typedef {import('@ggs/types').Faq} Faq
 */

/**
 * @typedef {Object} FaqProps
 * @property {Number=} id
 * @property {String=} title
 * @property {String=} textContent
 */

/**
 * Faq component.
 * @param {FaqProps} props
 * @return {JSX.Element}
 */
export default function Faq(props ) {
  const { id, title, textContent } = props
  return (
    <Accordion
      id={uniqueId(`faq-item-${id}`)}
      label={striptags(String(title))}
      content={<Text className={'accordion-text-content'} textContent={textContent}/>}
      variant={Accordion.variant.faq}
    />
  )
}

import { get } from 'lodash'
import { getWindow } from '@ggs/utils'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').Product} Product
 */

/**
 * @typedef {import('@commercelayer/sdk').LineItemCreate} LineItemCreate
 */

/**
 * Get a product option product cart image.
 * @param {ProductOption} productOption Product option entity
 * @return {string}
 */
export const getCartImage = (productOption) => {
  const origin = getWindow('origin') || ''
  const firstImage = productOption?.productImages[0] || {}
  return (
    origin
    + get(
      firstImage,
      'styles.productCartThumbnail.childImageSharp.gatsbyImageData.images.fallback.src',
      ''
    )
  )
}

/**
 * Compose product option CL metadata used for shopping cart display.
 * @param {Product} product
 * @param {ProductOption} productOption
 * @param {Array<String>=} includedItems
 * @return {Partial<LineItemCreate>}
 */
export const composeOrderItemMetadata = (product, productOption, includedItems) => {
  const data = {
    metadata: {
      url: `/${product.url}`.replace('//', '/'),
      name: product.name,
      subtitle: product.subtitle,
      color: productOption.color,
      image: getCartImage(productOption),
      optionLabel: productOption?.label,
      reminder_programs: product?.reminderProgram?.tid,
      includedItems,
    },
  }
  // console.log('composeOrderItemMetadata', data)
  return data
}

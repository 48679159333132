// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react'
import { cloneDeep, defer, delay, remove, uniq } from 'lodash'
import { ProductCard } from '@ggs/components/cards'

/**
 *
 * @enum {string}
 */
export const filterTypes = {
  usage: 'usage',
  categories: 'categories',
}

/**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 * @typedef {import('@ggs/types').ProductUse} ProductUse
 * @typedef {import('@ggs/types').ProductCategory} ProductCategory
 */
/**
 * @typedef {Object} ProductFilters
 * @property {Array<String>} usage
 * @property {Array<String>} categories
 */
/**
 * @typedef {Object} FilterableProductList
 * @property {Array<ProductCard>} entries
 */

const initialFilterState = {
  [filterTypes.usage]: [],
  [filterTypes.categories]: [],
}

// eslint-disable-next-line valid-jsdoc,require-jsdoc
export default function useFilterableProductListState({ entries }) {
  const [selected, setSelected] = useState(cloneDeep(initialFilterState))
  const [products, setProducts] = useState(Array(9).fill(<ProductCard />))

  const filters = useMemo(() => {
    // @ts-ignore
    const f = {
      [filterTypes.usage]: [],
      [filterTypes.categories]: [],
      counts: {
        [filterTypes.usage]: {},
        [filterTypes.categories]: {},
      },
    }
    entries.forEach((prod) => {
      f.usage = f.usage.concat(
        prod.productUse.map(({ name }) => {
          if (!f.counts.usage[name]) {
            f.counts.usage[name] = 1
          } else {
            f.counts.usage[name] = f.counts.usage[name] + 1
          }
          return name
        })
      )
      f.categories = f.categories.concat(
        prod.productCategories.map(({ name }) => {
          if (!f.counts.categories[name]) {
            f.counts.categories[name] = 1
          } else {
            f.counts.categories[name] = f.counts.categories[name] + 1
          }
          return name
        })
      )
    })
    f.usage = uniq(f.usage)
    f.categories = uniq(f.categories)
    return f
  }, [entries])

  const filterProducts = (f = selected) =>
    setProducts(() => {
      const filtered = entries.filter((prod) => {
        return (
          (!f.usage.length
            || (f.usage.length
              && prod.productUse.filter(({ name }) => f.usage.includes(name)).length))
          && (!f.categories.length
            || (f.categories.length
              && prod.productCategories.filter(({ name }) => f.categories.includes(name))
                .length))
        )
      })
      // console.log('filtered', { filtered, entries, f })
      return filtered
    })

  /**
   * Will unselect any active filters.
   */
  const resetFilters = () => {
    // console.log('resetFilters', initialFilterState)
    setSelected(cloneDeep(initialFilterState))
    // filterProducts(initialFilterState)
  }

  /**
   *
   * @param {String} type
   * @param {String} item
   * @return {boolean}
   */
  const selectFilter = (type, item) => {
    setSelected((old) => {
      // @ts-ignore
      const list = old[type]
      if (list.includes(item)) {
        remove(list, (name) => name === item)
      } else {
        list.push(item)
      }
      // console.log('selectFilter', list, old)
      defer(() => filterProducts())
      return old
    })

    return true
  }

  useEffect(() => {
    filterProducts()
  }, [])

  return {
    products,
    filters,
    selected,
    selectFilter,
    resetFilters,
  }
}

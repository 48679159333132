// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react'
import { defaults, defer, delay, forEach, isEqual, keyBy, map, noop, uniqueId } from 'lodash'
import { useFormik } from 'formik'
import * as PropTypes from 'prop-types'
import * as Yup from 'yup'
// Data
import { useI18n } from '@ggs/gatsby/lib'
import { Forms } from '@ggs/forms/schema'
// Components
import FormField from '../FormField/FormField'
import FormActions from './FormActions'
import Box from '@mui/material/Box'
import { addNotification, useDispatch } from '@ggs/store'
// Assets
import './_Form.scss'

export const formikFormErrorKey = 'formik_form_error'

const propTypes = {
  ...Forms.FormPropTypes,
  autoComplete: PropTypes.bool,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
}

const defaultProps = {
  ...Forms.FormDefaultProps,
  autoComplete: '',
  className: '',
  onSubmit: (formValues, { setSubmitting }) => {
    setTimeout(() => {
      // console.log('Form submit', JSON.stringify(formValues, null, 2))
      setSubmitting(false)
    }, 400)
  },
}

/*let debouncedValues = {}
 /!**
 * @type {DebouncedFunc<(function(): void)|*>}
 *!/
 const fireDebounce = debounce(() => {
 debouncedValues._update(debouncedValues)
 debouncedValues = {}
 },100)
 const valueChanged = (name, { target: { value } }, update) => {
 debouncedValues._update = update
 debouncedValues[name] = value
 // console.log('Form::valueChanged', name, value)
 fireDebounce()
 }*/

const recursiveFieldData = (field, name, initialValues, validationSchema) => {
  if (field?.defaultValue) {
    initialValues[name] = field.defaultValue
  }
  // If we have a group of validators, parse them.
  if (Array.isArray(field.validators)) {
    field.validators.forEach(({ name: fieldName, validator }) => {
      validationSchema[fieldName] = validator
    })
  }
  // If the field has a dedicated validator
  if (field?.validator) {
    validationSchema[name] = field.validator
  }

  // Ensure to include subfields if present.
  if (field?.subfields) {
    // console.log('::recursiveFieldData subfields found')
    forEach(field.subfields, (subfield, subfieldName) =>
      recursiveFieldData(subfield, subfieldName, initialValues, validationSchema)
    )
  }
}

const obtainFieldData = (fields) => {
  // console.log('rebuilding form field data')
  const initialValues = {}
  const validationSchema = {}
  forEach(fields, (field, name) => recursiveFieldData(field, name, initialValues, validationSchema))
  // console.log('::obtainFieldData results', initialValues, validationSchema)
  return { initialValues, validationSchema }
}

const useForm = (
  {
    fields = [],
    className = '',
    actions = [],
    content = {},
    onSubmit = noop,
    autoComplete,
    ...props
  },
  formikProps = null
) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const [state, setState] = useState(() => {
    const settledFields = Array.isArray(fields) ? keyBy(fields, 'name') : fields
    const s = {
      fields: settledFields,
      content,
      actions,
      ...obtainFieldData(settledFields),
    }
    console.log('::useForm state onLoad', s)
    return s
  })
  const { header, middle, footer } = state.content || {}
  const formik = useFormik({
    initialValues: state.initialValues,
    validationSchema: Yup.object().shape(state.validationSchema),
    onSubmit: (values) => {
      console.log('FormSubmitValues:', values, formik)
      delay(
        () =>
          onSubmit(values, formik, (message, type = 'success') =>
            dispatch(addNotification(uniqueId(formikFormErrorKey), type, message))
          ),
        500
      )
    },
    formikProps,
  })

  const setValidators = (updateCallback) => {
    const revised = updateCallback(state.validationSchema)
    setState((old) =>
      Object.assign(old, {
        validationSchema: {
          ...old.validationSchema,
          ...revised,
        },
      })
    )
    // console.log('Form::setValidators', revised)
  }

  /**
   * State supporting checks.
   */
  // Fields can only be updated post load, never replace the original fields to reduce effort.
  const setFields = (newFields, newActions = null) =>
    defer(() => {
      // console.log('useForm::setFields check', newFields)
      if (!isEqual(newFields, state.fields)) {
        const newState = defaults({ fields: newFields, ...obtainFieldData(newFields) }, state)
        // Optionally, also update actions
        if (newActions && !isEqual(newActions, newState.actions)) {
          newState.actions = newActions
        }
        setState(newState)
        // console.log('useForm::setFields updated', newState)
      }
    })

  /**
   * An isolated setter for just a single field to safe guard.
   * @param name
   * @param data
   */
  const setField = (name, data) => {
    setFields({ ...fields, [name]: Object.assign(fields[name], data) })
    // console.log('useForm::setField', name, data)
  }

  // Actions can only be updated post load, never replace the original fields to reduce effort.
  const setActions = (newActions) =>
    defer(() => {
      if (!isEqual(newActions, state.actions)) {
        setState({ ...state, actions: newActions })
      }
    })

  // Refresh content changes
  useEffect(() => {
    defer(() => {
      if (!isEqual(content, state.content)) {
        setState({ ...state, content })
      }
    })
  }, [content])

  // If we have a watcher for the formContext, trigger it as state changes.
  useEffect(() => {
    // Only ever trigger the fallback error (invalid fields) if there's no pressing API/user error.
    if (formik.isSubmitting && formik.submitCount > 0 && !formik.isValid) {
      dispatch(
        addNotification(formikFormErrorKey, 'error', t('global:form.error.invalidSubmission'))
      )
      console.log('FormErrorRaised:', formik.errors)
    }

    // console.log('formik default error check', {
    //   submitCount: formik.submitCount,
    //   isDirty: formik.dirty,
    //   isValid: formik.isValid,
    // })
  }, [formik.isSubmitting, formik.submitCount, formik.isValid])

  /*const updateForm = (newValues = {}) => {
   // Update all fields, once..
   formik.setValues((oldValues) => {
   const revisedValues = { ...oldValues, ...newValues }
   debouncedValues = {}
   console.log('Form::updateForm', revisedValues, debouncedValues)

   // Revalidate, once...
   delay(()=>{
   formik.validateForm()
   })

   return revisedValues
   })
   }*/

  const formRef = useRef()

  const FormComp = (
    // useMemo(
    // () => (
    <Box {...props}>
      <form
        ref={formRef}
        className={`${className} form`}
        // Determine whether or not this form should allow auto-completion of fields.
        autoComplete={autoComplete ? 'on' : 'off'}
      >
        <div className="form__content">
          {header && <header className="form__header">{header}</header>}
          <div className="form__fields">
            {map(state.fields, (field, name) => {
              const extraProps = { ...field }
              return (
                <FormField
                  key={`formInput_${name}`}
                  name={name}
                  formik={formik}
                  // onChange={(event) => valueChanged(name, event, updateForm)}
                  setField={(data) => setField(name, data)}
                  setValidators={setValidators}
                  {...extraProps}
                />
              )
            })}
          </div>
          {middle && <section className="form__middle">{middle}</section>}
        </div>
        <FormActions actions={actions} formik={formik} />
        {footer && <footer className="form__footer">{footer}</footer>}
      </form>
    </Box>
  )
  // )
  // ,[state.fields, state.actions, state.content, formik, formRef, props]
  // )

  // console.log('useForm has re-rendered.')
  // console.log('Form::render', { values: formik.values, errors: formik.errors })
  //console.log('render', state, formik)
  // eslint-disable-next-line no-debugger
  // debugger

  return {
    context: formik,
    fields: state.fields,
    setFields,
    setActions,
    FormComp,
    ref: formRef,
    actions,
  }
}

useForm.defaultProps = defaultProps
useForm.propTypes = propTypes
export default useForm

// @ts-nocheck
import { useI18n } from '@ggs/gatsby/lib'
import { typography } from '@ggs/styles'
import Box from '@mui/material/Box'
import React from 'react'

const ContactInformationReview = ({
  email = '',
  phone = '',
  first_name = '',
  last_name = '',
  ...props
}) => {
  const { t } = useI18n()
  console.log('ContactInformationReview', props)
  return (
    <Box
      className={'contact-information-review'}
      sx={{
        '.contact-information-review-label': {
          typography: typography.pSmall,
        },
      }}
    >
      <Box sx={{ typography: 'body1' }}>
        {/*<span className={'contact-information-review-label'}>{t('account:input.label.name')}:</span>{' '}*/}
        {first_name} {last_name}
      </Box>
      <Box sx={{ typography: 'body1' }}>
        {/*<span className={'contact-information-review-label'}>{t('account:input.label.email')}:</span>{' '}*/}
        {email}
      </Box>
      <Box sx={{ typography: 'body1' }}>
        {/*<span className={'contact-information-review-label'}>{t('account:input.label.phone')}:</span>{' '}*/}
        {phone}
      </Box>
      {/*{JSON.stringify(props)}*/}
    </Box>
  )
}

export default ContactInformationReview

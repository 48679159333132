import React, { useMemo } from 'react'

/**
 * @typedef {object} MemoizedProps
 * @property {JSX.Element} children
 * @property {Array<*>} deps
 */
/**
 * Memoized component.
 * @param {MemoizedProps} props
 * @return {JSX.Element}
 * @constructor
 */
export default function Memoized({ children,  deps = [] }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => <>{children}</>, [...deps, children])
}

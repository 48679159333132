import React from 'react'
import { useWindowSize } from '@ggs/hooks'
import MuiTimeline from '@mui/lab/Timeline'
import { GridContainer } from '@ggs/components/layout'
import { TimelineItem, Title } from '@ggs/components/paragraphs'

// Assets.
import './Timeline.scss'

/**
 * @typedef {import('@ggs/components/paragraphs/TimelineItem/TimelineItem').TimelineItemProps} TimelineItem
 */
/**
 * @typedef {Object} TimelineProps
 * @property {String=} title
 * @property {Array<TimelineItem>=} timelineItems
 */
/**
 * Timeline component.
 * @param {TimelineProps} props
 * @return {JSX.Element}
 */
export default function Timeline({ title = '', 	timelineItems = [] }) {
  const sizes = useWindowSize()

  return (
    <GridContainer sx={{ py: { xs: 5, md: 10 } }} className="timeline">
      {title && (
        <Title style="h3" title={title} useFlare={true} />
      )}
      <MuiTimeline position={sizes.sm ? 'alternate' : 'left'}>
        {timelineItems.length > 0 && timelineItems.map((item) => (
          <TimelineItem key={item.id} {...item} />
        ))}
      </MuiTimeline>
    </GridContainer>
  )
}

import { get } from 'lodash'
import React from 'react'

/**
 * @typedef {Object} RawImage
 * @property {Object} imageData
 * @property {string=} imageStyle
 */
/**
 * @param {RawImage} props
 * @return {JSX.Element}
 */
export default function RawImage({ imageData, imageStyle = 'mediaLibrary', ...props }) {
  const src = get(
    imageData,
    `styles.${imageStyle}.childImageSharp.gatsbyImageData.images.fallback.src`
  )
  // @ts-ignore
  return src ? <img src={src} alt={imageData?.styles?.alt} {...props} /> : null
}

import commerceLayerClient from '@commercelayer/sdk'

const localEnvs = {
  GATSBY_COMMERCE_LAYER_ORG_ID:
    process.env.GATSBY_COMMERCE_LAYER_ORG_ID || process.env.GATSBY_COMMERCE_LAYER_ORG_ID || null,
}

// Gatsby env variables is not compatible with CommonJS, use it only from browser.
if (!localEnvs.GATSBY_COMMERCE_LAYER_ORG_ID) {
  localEnvs.GATSBY_COMMERCE_LAYER_ORG_ID = `${
    process.env.GATSBY_COMMERCE_LAYER_ORG_ID || process.env.GATSBY_COMMERCE_LAYER_ORG_ID
  }`
}

/**
 * @typedef {Object} CLConfig
 * @property {string} organization
 * @property {string=} domain
 * @property {string} accessToken
 * @property {number} timeout
 */

/**
 * @typedef OAuthTokenResponse
 * @property {string} token
 * @property {Date} expiresAt
 * @property {string=} refreshToken
 */

/**
 * @typedef {import("@commercelayer/sdk").CommerceLayerClient} CLClient
 */

const globalDefaultScope = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`

/**
 * Commerce Layer client factory.
 */
export class CommerceLayerClientFactory {
  /**
   * CL client factory constructor.
   * @param {string} scope
   */
  constructor(scope) {
    /**
     * CL client instance
     * @type {CLClient|null}
     */
    this.client = null

    // required to get session
    this.orgId = localEnvs.GATSBY_COMMERCE_LAYER_ORG_ID
    this.scope = scope ?? globalDefaultScope

    // the actual token string used in the HTTP header
    this.token = null

    this.tokenExpiresAt = null
    // customer token
    this.refreshToken = null

    this.rawToken = null

    // meta data
    this.tokenType = ''
  }

  /**
   * Set client authorization token.
   * @param {string} token CL access token
   */
  setToken(token) {
    this.token = token
    try {
      this.buildClient()
    } catch (error) {
      // no org?
    }
  }

  // /**
  //    * Set organization.
  //    * @param {string} orgId CL organization ID
  //    */
  // setOrganization(orgId) {
  //   this.orgId = orgId
  // }

  /**
   * Prepare CL client configuration.
   * @return {CLConfig}
   */
  prepareConfig() {
    return {
      organization: this.orgId || '',
      accessToken: this.token || '',
      timeout: 5000,
    }
  }

  /**
   * Get CL client instance.
   */
  buildClient() {
    if (!this.token) {
      throw new Error('CL token is not defined')
    }

    if (!this.orgId) {
      throw new Error('CL organization ID is not defined')
    }

    const config = this.prepareConfig()
    if (!this.client) {
      this.client = commerceLayerClient(config)
    } else {
      this.client.config(config)
    }
  }

  /**
   * Get CL client instance.
   * @return {CLClient}
   */
  getClient() {
    try {
      console.log('ClientScope:', this.scope)
      this.buildClient()
    } catch (error) {
      // should this be handled by the caller
    }
    return this.client
  }

  /**
   * Set organization.
   * @param {string} orgId CL organization ID
   */
  setOrganization(orgId) {
    this.orgId = orgId
  }

  /**
   * Get CL client instance.
   */
  isGuestToken() {
    /**
     *   "access_token": "your-access-token",
     *   "token_type": "bearer",
     *   "expires_in": 7200,
     *   "scope": "market:1234",
     *   "created_at": 123456789
     */
  }

  /**
   *                  "access_token": "your-access-token",
   *                  "token_type": "bearer",
   *                   "expires_in": 7200,
   *                   "refresh_token": "your-refresh-token",
   *                   "scope": "market:1234",
   *                   "created_at": 123456789,
   *                   "owner_id": "zxcVBnMASd",
   *                   "owner_type": "customer"
   *
   *                   Refresh token - How to execute the authorization flow and get your access token
   *                   The refresh_token grant type is used by clients to exchange a refresh token for an expired
   * access token. Sales channel applications can use the refresh_token grant type to refresh a customer's access
   * token with a "remember me" option. Webapp applications can use the refresh_token grant type to refresh the
   * access token.
   *
   */
  isCustomerToken() {
  }

  //
  /**
   * Set organization.
   * @param {OAuthTokenResponse} rawTokenResponse CL organization ID
   */
  updateCustomerToken(rawTokenResponse) {
    // parse for customer fields
    // assign token
    // assign token.token
    // assign token type
  }

  /**
   * Get clear current session.
   */
  clearToken() {
    this.token = null
  }
}

const clClientFactory = new CommerceLayerClientFactory(globalDefaultScope)

export { clClientFactory }

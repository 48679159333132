import React, { useRef } from 'react'
import { uniqueId } from 'lodash'
import { Text } from '@ggs/components/paragraphs'
import { Button } from '@ggs/gatsby/components/nav'
import Grid from '@mui/material/Grid'
import { colors, typography } from '@ggs/styles'
import { useI18n } from '@ggs/gatsby/lib'
import { useCustomerGroupSelect } from '@ggs/components/nav/CustomerSelector/useCustomerGroupSelect'

/**
 * @typedef {import('@ggs/components/common/Modal/Dialog')} Dialog
 * @typedef {import('@ggs/components/common/Modal/useModal').ModalState} ModalState
 * @typedef {import('@ggs/types').CustomerGroup} CustomerGroup
 */
/**
 * @typedef {Object} CustomerTypeSelectProps
 * @property {Array<CustomerGroup>} customerGroups All available customer groups
 * @property {function} onClose Closes the modal
 */
/**
 *
 * @param {CustomerTypeSelectProps} props
 * @return {JSX.Element}
 */
export default function CustomerTypeSelect({ customerGroups, onClose }) {
  const { t } = useI18n()
  const ref = useRef()
  const { selectCustomerGroup } = useCustomerGroupSelect({ customerGroups })

  /**
   * Handle menu option selection and customer group state change.
   * @param {CustomerGroup} props
   */
  const handleSelect = ({ tid, name }) => {
    selectCustomerGroup(tid)
    // Close the modal.
    onClose()


  }

  return (
    <Grid
      container
      ref={ref}
      sx={{
        '.button': {
          width: '100%',
          borderRadius: '4px',
          border: `1px solid ${colors.red}`,
          mb: 1,
          span: {
            typography: typography.menuItemSmall,
          },
        },
        '.text': {
          textAlign: 'left',
          '& p': {
            typography: typography.pSmall,
          },
        },
      }}
      spacing={5}
    >
      {customerGroups.map((group) => (
        <Grid item key={uniqueId(`customer_selector_${group.tid}`)} xs={12} md={6}>
          <Button label={group.name} onClick={() => handleSelect(group)} variant={'outlined'}/>
          <Text textContent={group.description}/>
        </Grid>
      ))}
    </Grid>
  )
}

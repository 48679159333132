// @ts-nocheck
import {viewsActionType} from '../actions/views'
import {initialState} from '../initialState'
import {createReducer} from '@reduxjs/toolkit'
import {uiActionType} from "@ggs/store"

/**
 * @typedef {import("../initialState").DefaultViews} ViewsState
 */

/**
 * @typedef {import("@ggs/commercelayer/index").OrderAction} OrderAction
 */

/**
 * @returns {ViewsState} cached views.
 */
const views = createReducer(initialState.views, {
  /**
   * Used for caching orders locally
   * @param {ViewsState} state
   * @param {OrderAction} action
   */
  [viewsActionType.SET_ORDER_STATUS]: (state, action) => {
    const order = action?.payload
    if (!action.payload) {
      return
    }

    if (order && order.status && order.id) {
      // console.log('OrderStatusAction:', action)
      // When order is transitioned to placed set data in views state.
      if (order.status === 'placed') {
        state.orders.placed = {
          ...state.orders.placed,
          [order.id]: order,
        }
      } else {
        state.orders.draft = {
          ...state.orders.draft,
          [order.id]: order,
        }
      }
    }
  },
  /**
   * Set product local cart state.
   * @param {InitialStateUI} state
   * @param {SetProductLocalCartAction} action
   */
  [viewsActionType.SET_PRODUCT_TRANS]: (state, action) => {
    const productId = action.payload.productId
    const translationsMap = {}
    action.payload.linkTranslations.reduce((obj, element, index) => translationsMap[element.lang] = element.url)
    state.translations.products[productId] = {
      optionalItems: action.payload.optionalItems,
      linkTranslations: translationsMap
    }
  }

})

export default views

// @ts-nocheck
import { useState } from 'react'
import { AddressController } from '@ggs/components/ecomm/Customer/AddressController'
import { isEmpty } from 'lodash'
import { ADDRESS_FIELDS } from '@ggs/components/ecomm/Customer/AddressForm'

export const useAddressController = ({
  orderPropertyKey,
  // labels
}) => {
  const [stateCodeSelected, setStateCodeSelected] = useState({ label: null, value: null })
  const [countryCodeSelected, setCountryCodeSelected] = useState({ label: null, value: null })
  const addressController = AddressController({ orderPropertyKey })
  // initially set to true to help w/ payment auth.
  // const ui = useSelector((state) => state.ui)
  // const { t } = useI18n()
  // // const wrapperRef = useRef(null)
  // const settings = useSelector((state) => state.settings)
  // const currentLanguage = decomposeLanguageLocaleCode(
  //     ui?.layoutMeta?.currentStore?.language?.id || settings?.language
  // )
  const [draftAddress, setDraftAddress] = useState(addressController.initialState())
  const addressType = orderPropertyKey // useState('')
  const [isoCodes, setIsoCodes] = useState({})
  const [addressFormSubmit, setAddressFormSubmit] = useState()

  const [addressFormatted, setAddressFormatted] = useState({
    billing_address: null,
    shipping_address: null,
  })

  const onAddressSubmit = (values) => {
    setAddressFormSubmit(values)
  }

  const onAddressSubmitIsoCodes = ({
    isoCodes,
    country, //: values?.[ADDRESS_FIELDS.countryCode],
    state, //: values?.[ADDRESS_FIELDS.stateProvince],
  }) => {
    setIsoCodes({
      countryValue: country,
      stateValue: state,
      isoCodes,
    })
    return {
      isoCodes,
    }
  }

  // stateless helper functions
  const parseEmptyAs = ({ addressValues, KEY, value }) =>
    !isEmpty(addressValues[KEY]) ? addressValues[KEY] : value
  const parseEmptyAsEmptyStr = ({ addressValues, KEY }) =>
    parseEmptyAs({ addressValues, KEY, value: '' })
  const parseEmptyAsDash = ({ addressValues, KEY }) =>
    parseEmptyAs({ addressValues, KEY, value: '-' })
  const parseIsoCodeEmptyAsDash = ({ addressValues, isoCodes, KEY }) =>
    parseEmptyAsEmptyStr({ addressValues, KEY }) ? isoCodes[KEY] : '-'

  const addressValuesToIso = ({ addressValues, stateProvince, countryCode, orderPropertyKey }) => {
    // assigns address values to isoCodes
    // Contact

    const addressValuesProcessed = {
      [ADDRESS_FIELDS.email]: addressValues[ADDRESS_FIELDS.email],
      [ADDRESS_FIELDS.phone]: addressValues[ADDRESS_FIELDS.phone],
      // Address
      [ADDRESS_FIELDS.firstName]: addressValues[ADDRESS_FIELDS.firstName],
      [ADDRESS_FIELDS.lastName]: addressValues[ADDRESS_FIELDS.lastName],
      [ADDRESS_FIELDS.address1]: addressValues[ADDRESS_FIELDS.address1],
      [ADDRESS_FIELDS.address2]: parseEmptyAsEmptyStr({
        addressValues,
        KEY: ADDRESS_FIELDS.address2,
      }),
      // !isEmpty(addressValues[ADDRESS_FIELDS.address2])
      //     ? addressValues.address2 : '',
      [ADDRESS_FIELDS.city]: parseEmptyAsDash({ addressValues, KEY: ADDRESS_FIELDS.city }),
      [ADDRESS_FIELDS.stateProvince]: stateProvince /*parseIsoCodeEmptyAsDash({
        addressValues,
        isoCodes,
        KEY: ADDRESS_FIELDS.stateProvince
      }), */,
      // !isEmpty(addressValues[ADDRESS_FIELDS.stateProvince]) ? isoCodes.stateProvince : '-',
      [ADDRESS_FIELDS.zipPostal]: parseEmptyAsDash({
        addressValues,
        KEY: ADDRESS_FIELDS.zipPostal,
      }),
      // !isEmpty(addressValues[ADDRESS_FIELDS.zipPostal]) ? addressValues[ADDRESS_FIELDS.zipPostal] : '-',
      [ADDRESS_FIELDS.countryCode]: countryCode, //parseIsoCodeEmptyAsDash({
      // addressValues, isoCodes, KEY: ADDRESS_FIELDS.countryCode,
    }

    setAddressFormatted((currentAddresses) => ({
      ...currentAddresses,
      [orderPropertyKey]: addressValuesProcessed,
    }))

    console.log('processed values', addressValuesProcessed)

    // const requiredFields = [
    //   ADDRESS_FIELDS.stateProvince,
    //   ADDRESS_FIELDS.city,
    //   ADDRESS_FIELDS.zipPostal,
    // ]
    //
    // // Prevent line_2 set to null when not filled.
    // if (isEmpty(addressValues[ADDRESS_FIELDS.address2])) {
    //   addressValues[ADDRESS_FIELDS.address2] = ''
    // }
    //
    // requiredFields.forEach((fieldKey) => {
    //   if (isEmpty(addressValues[fieldKey])) {
    //     addressValues[fieldKey] = '-'
    //   }
    // })

    return addressValuesProcessed
  }

  // const requiredFields = [
  //   ADDRESS_FIELDS.stateProvince,
  //   ADDRESS_FIELDS.city,
  //   ADDRESS_FIELDS.zipPostal,
  // ]
  //
  // // Prevent line_2 set to null when not filled.
  // if (isEmpty(addressValues[ADDRESS_FIELDS.address2])) {
  //   addressValues[ADDRESS_FIELDS.address2] = ''
  // }
  //
  // requiredFields.forEach((fieldKey) => {
  //   if (isEmpty(addressValues[fieldKey])) {
  //     addressValues[fieldKey] = '-'
  //   }
  // })
  return {
    [orderPropertyKey]: {
      address: draftAddress,
      geoCodes: {
        stateCodeSelected,
        countryCodeSelected,
      },
      isoCodes,
      setStateCodeSelected,
      countryCodeSelected,
      setCountryCodeSelected,
      addressType,
      addressFormSubmit,
      addressFormatted,
      setAddressFormatted,
      setIsoCodes,
      setAddressFormSubmit,
      onAddressSubmit,
      onAddressSubmitIsoCodes,
      set: setDraftAddress,
      addressValuesToIso,
    },
    // onSubmitHandler,
  }
}

/*
select_item
This event signifies an item was selected from a list.

Parameters
Name	Type	Required	Example value	Description
item_list_id	string	No	related_products	The ID of the list in which the item was presented to the user.

Ignored if set at the item-level.
item_list_name	string	No	Related products	The name of the list in which the item was presented to the user.

Ignored if set at the item-level.
items	Array<Item>	Yes*		The items for the event.

* The items array is expected to have a single element, representing the selected item. If multiple elements are provided, only the first element in items will be used.
 */
// Measure product detail views
// After clicking a product listing, a user might view the product details page.
// To measure product detail views, send a view_item event with the product details:
import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'

export const fireSelectItem = ({ items, item_list_name, item_list_id }) => {
  return pushDataLayer({
    eventName: EaActionType.selectItem,
    dataLayer: {
      // currency,
      // value,
      items: items.map((i, k) => {
        return {
          ...i,
          item_list_name,
          item_list_id,
          quantity: 1,
          index: k,
        }
      }),
      // items
      item_list_name,
      item_list_id,
      // items
      // items
    },
  })
}

// @ts-nocheck
/* eslint-disable dot-notation,no-use-before-define */
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { find, keyBy, forEach, has, debounce, size } from 'lodash'
// Components
// Data
// Assets
// import './_CheckboxModal.scss'
// import { Button } from '../../nav'
import { useI18n } from '@ggs/gatsby/lib'
import Checkbox from './Checkbox'
import { Forms } from '@ggs/forms/schema'
import SelectableAccordion from './SelectableAccordionList/SelectableAccordion'
import { Button } from '@ggs/gatsby/components/nav'
import { useModal } from '@ggs/components/common'

const CheckboxModal = forwardRef(
  (
    {
      name,
      label,
      onChange,
      value: formikValue = [],
      defaultValue,
      options: { selection, max },
      ...props
    },
    ref
  ) => {
    // console.log(`CheckboxModal::render ${props?.name}`,props, onChange)
    const { t } = useI18n()
    const modalRef = useRef()
    const getValues = (fallback = []) => keyBy(formikValue || fallback, (value) => value)
    const [values, setValues] = useState(getValues(defaultValue) || [])
    const getItemList = () => {
      let arr = []
      selection.forEach(({ items }) => {
        arr = arr.concat(items)
      })
      return arr
    }
    const [itemList, setItemList] = useState(getItemList())

    const modalId = `modal-${name}`
    const { modal, triggerModal } = useModal({
      className: 'checkbox-modal__modal',
      closeButton: {
        label: t('global:button.confirmSelection'),
      },
      onOpen: () => {
        // Must wait till modal displaying before it can be triggered.
        let tick = 100
        const check = setInterval(() => {
          // If modal is found...
          const modalOnDom = document.querySelector(`#${modalId}`)
          if (modalOnDom || !tick) {
            // Update checked items
            refreshBoxes(values, modalOnDom)
            clearInterval(check)
          }
          tick--
          console.log('modalOnDom', modalOnDom)
        }, 100)
      },
      content: (
        <div id={modalId} ref={modalRef}>
          <h5>{label}</h5>
          <p>
            You can optionally select up to {max} competencies from non-primary disciplines below.
          </p>
          {selection.map(({ type, items }) => (
            <div key={type} className="checkbox-modal__section">
              <div className="checkbox-modal__section-title">{type}</div>
              <div className="checkbox-modal__section-items">
                {items.map(({ id, description }) => {
                  return (
                    <>
                      <Checkbox
                        key={id}
                        name={name}
                        value={id}
                        checked={has(values, id)}
                        onChange={onChange}
                        label={description}
                        labelPlacement={Forms.LabelPlacement.end}
                      />
                      <br />
                    </>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      ),
    })

    /**
     *
     * @param {Object} valueObj
     */
    function refreshBoxes(valueObj = values, el = null) {
      const { current = el } = modalRef
      // Check if the modal is active, if so, fill checks
      console.log('refreshBoxes', modal, current)
      if (current) {
        const atMaxItems = max && size(valueObj) === max

        forEach(itemList, ({ id: value }) => {
          const input = current.querySelector(`input[value='${value}']`)
          // if (input) {
          const wrapper = input.closest('.MuiFormControlLabel-root')

          if (valueObj[value]) {
            // If selected, ensure it shows as such
            input.checked = true
          } else {
            // Else if we're at max, disable
            input.disabled = atMaxItems
          }

          // Adjust disabled styling
          if (input.disabled) {
            wrapper.classList.add('Mui-disabled')
            for (let i = 0; i < wrapper.children.length; i++) {
              wrapper.children[i].classList.add('Mui-disabled')
            }
          } else {
            wrapper.classList.remove('Mui-disabled')
            for (let i = 0; i < wrapper.children.length; i++) {
              wrapper.children[i].classList.remove('Mui-disabled')
            }
          }

          console.log('CheckboxModal::input', input.value, input.checked, input.disabled)
          // } else {
          // console.log('CheckboxModal::input missing?', value)
          // }
        })
      }
    }

    useEffect(() => {
      if (formikValue) {
        const newValues = getValues()
        setValues(newValues)
        refreshBoxes(newValues)
        // console.log(`CheckboxModal::formikChange ${name}`, formikValue, values)
      }
    }, [formikValue])

    useEffect(() => {
      setItemList(getItemList())
    }, [selection])

    /**
     * After the modal is opened, ensure we check off selected values.
     */
    const openModal = debounce(() => triggerModal(), 100)

    const details = useMemo(() => {
      if (!formikValue.length) {
        return null
      }
      const items = formikValue.map((item) => find(itemList, ['id', item]))

      return (
        <div>
          <h3>Competencies added from non-primary disciplines</h3>
          {items.map(({ id, ...rest }) => (
            <SelectableAccordion {...rest} key={id} isChecked />
          ))}
          <br />
        </div>
      )
    }, [formikValue])

    console.log(`CheckboxModal::render ${props.name}`, formikValue, values, selection, itemList)

    return (
      <div className="checkbox-modal">
        {details}
        <Button className="checkbox-modal__trigger" onClick={openModal} label={`+ ${label}`} />
        {modal}
        {/*<button className="form-field__reset" type="button" aria-hidden onClick={reset} />*/}
      </div>
    )
  }
)

// Exports
// CheckboxModal.defaultProps = defaultProps
// CheckboxModal.propTypes = propTypes
CheckboxModal.displayName = 'CheckboxModal'
export default CheckboxModal

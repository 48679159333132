/**
 * Item parameters
 * Name  Type  Required  Example value  Description
 * item_id  string  Yes*  SKU_12345
 * The ID of the item.
 *
 * One of item_id or item_name is required.
 *
 * item_name  string  Yes*  Stan and Friends Tee
 * The name of the item.
 *
 * One of item_id or item_name is required.
 *
 * affiliation  string  No  Google Store
 * A product affiliation to designate a supplying company or brick and mortar store location.
 *
 * Event-level and item-level affiliation parameters are independent.
 * coupon  string  No  SUMMER_FUN  The coupon name/code associated with the item.
 *
 * Event-level and item-level coupon parameters are independent.
 * currency  string  No  USD  The currency, in 3-letter ISO 4217 format.
 *
 * If set, event-level currency is ignored.
 *
 * Multiple currencies per event is not supported. Each item should set the same currency.
 * discount  number  No  2.22  The monetary discount value associated with the item.
 * index  number  No  5  The index/position of the item in a list.
 * item_brand  string  No  Google  The brand of the item.
 * item_category  string  No  Apparel  The category of the item. If used as part of a category hierarchy
 * or taxonomy then this will be the first category.
 * item_category2  string  No  Adult  The second category hierarchy or additional taxonomy for the item.
 * item_category3  string  No  Shirts  The third category hierarchy or additional taxonomy for the item.
 * item_category4  string  No  Crew  The fourth category hierarchy or additional taxonomy for the item.
 * item_category5  string  No  Short sleeve  The fifth category hierarchy or additional taxonomy for the item.
 * item_list_id  string  No  related_products  The ID of the list in which the item was presented to the user.
 *
 * If set, event-level item_list_id is ignored.
 * If not set, event-level item_list_id is used, if present.
 * item_list_name  string  No  Related products  The name of the list in which the item was presented to the user.
 *
 * If set, event-level item_list_name is ignored.
 * If not set, event-level item_list_name is used, if present.
 * item_variant  string  No  green  The item variant or unique code or description for additional item details/options.
 * location_id  string  No  ChIJIQBpAG2ahYAR_6128GcTUEo (the Google Place ID for San Francisco)  The physical location associated with the item (e.g. the physical store location). It's recommended to use the Google Place ID that corresponds to the associated item. A custom location ID can also be used.
 *
 * If set, event-level location_id is ignored.
 * If not set, event-level location_id is used, if present.
 * price  number  No  9.99  The monetary price of the item, in units of the specified currency parameter.
 * quantity  number  No  1
 * Item quantity.
 *
 * If not set, quantity is set to 1.
 *
 *
 */

//(LineItem) => gtm Item
export const mapItemsToGTM = ({
  name,
  currency_code,
  sku_code,
  bundle_code,
  quantity = 1,
  total_amount_float,
  unit_amount_float,
  item_category = null,
  item_category2 = null,
  item_category3 = null,
  item_category4 = null,
  item_category5 = null,
  item_list_id = null,
  item_list_name = null,
  item_variant = null,
  location_id = null,
  price = null,
}) => ({
  item_id: sku_code || bundle_code,
  item_name: name,
  price: Number(
    parseFloat(quantity === 1 ? Number(total_amount_float) : quantity * unit_amount_float).toFixed(
      2
    )
  ),
  currency: currency_code,
  quantity: quantity,
  item_category: item_category ?? undefined,
  item_category2: item_category2 ?? undefined,
  item_category3: item_category3 ?? undefined,
  item_category4: item_category4 ?? undefined,
  item_category5: item_category5 ?? undefined,
  // affiliation: "Google Merchandise Store",
  // coupon: "SUMMER_FUN",
  // discount: 2.22,
  // index: 0,
  // item_brand: "Google",
  // item_category: "Apparel",
  // item_category2: "Adult",
  // item_category3: "Shirts",
  // item_category4: "Crew",
  // item_category5: "Short sleeve",
  // item_list_id: "related_products",
  // item_list_name: "Related Products",
  // item_variant: "green",
  // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
})

export const mapProductToGtm = ({
  productOptionSku,
  productName,
  totalAmountFloat,
  productCurrencyCode,
  unitAmountFloat,
  quantity,
}) => ({
  item_id: productOptionSku,
  item_name: productName,
  price: Number(
    parseFloat(quantity === 1 ? Number(totalAmountFloat) : quantity * unitAmountFloat).toFixed(2)
  ),
  currency: productCurrencyCode,
  quantity: quantity,
  // affiliation: "Google Merchandise Store",
  // coupon: "SUMMER_FUN",
  // discount: 2.22,
  // index: 0,
  // item_brand: "Google",
  // item_category: "Apparel",
  // item_category2: "Adult",
  // item_category3: "Shirts",
  // item_category4: "Crew",
  // item_category5: "Short sleeve",
  // item_list_id: "related_products",
  // item_list_name: "Related Products",
  // item_variant: "green",
  // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
})

export const productValueQuantityGTM = ({ skuPrice, productOption, quantity }) => {
  return {
    gtmProduct: mapProductToGtm({
      productOptionSku: skuPrice.sku_code,
      productName: productOption.label,
      totalAmountFloat: skuPrice.amount_float,
      productCurrencyCode: skuPrice.currency_code,
      quantity,
    }),
    value: skuPrice.amount_float,
    quantity,
  }
}

const isItemTypeQuantityEqual = (o1, o2) =>
  o1.item_id === o2.item_id
  && o1.price === o2.price
  && o1.item_name === o2.item_name
  && o1.quantity === o2.quantity

/*
{
  "item_id": "11050191008",
  "item_name": "Aerobika OPEP Device",
  "price": 169.98,
  "currency": "CAD",
  "quantity": 2
}
 */
export const compareItems = (o1, o2) => {
  // if one isnt defined or is empty, they are different.
  if (!Array.isArray(o1) || !o1.length || !Array.isArray(o2) || !o1.length) {
    return false
  }

  // if they have different lengths, they are different.
  if (o1.length !== o2.length) {
    return false
  }

  // sort by item ID
  const sortedO1 = o1.sort((a, b) => a.item_id - b.item_id)
  const sortedO2 = o2.sort((a, b) => a.item_id - b.item_id)

  for (let k = 0; k < sortedO1.length; k++) {
    if (!isItemTypeQuantityEqual(sortedO1[k], sortedO2[k])) {
      return false
    }
  }
  return true
}

export const gtmItemsList = (items) => (items ? items.map(mapItemsToGTM) : [])

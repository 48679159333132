import React, { useMemo } from 'react'
import { map, omit, pick } from 'lodash'
import { Image } from '@ggs/gatsby/components/media'
// Assets
import './ImageStyles.scss'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */

/**
 * @param {ImageStyles} props
 * @return {JSX.Element}
 */
export default function ImageStyles({ styles, selectedStyles, loading }) {
  const { alt } = styles || {}
  // Either attempt to limit based on the selectedStyles or pull all and omit the fragment field.
  const availableStyles = styles
    ? Array.isArray(selectedStyles) && selectedStyles.length
      ? pick(styles, selectedStyles)
      : omit(styles, ['alt'])
    : styles
  const images = useMemo(
    () =>
      map(availableStyles, (image, imageType) => (
        // @ts-ignore
        <Image
          key={imageType}
          type={imageType}
          image={image}
          alt={alt || imageType}
          loading={loading}
        />
      )),
    [styles]
  )
  // console.log('ImageStyles', { styles, selectedStyles, images })
  return <>{images}</>
}

import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import { GridContainer } from '@ggs/components/layout'
import { Link } from '@ggs/gatsby/components/nav'

// Assets
import './SkipLink.scss'

/**
 * @typedef {Object} SkipLinkProps
 * @property {String=} targetId
 * @property {String=} label
 */
/**
 * SkipLink component
 * @param {SkipLinkProps} props
 * @return {JSX.Element}
 */
export default function SkipLink({ targetId, label }) {
  const { t } = useI18n()
  return (
    <GridContainer className="skip-link" sx={{ boxSizing: 'border-box', py: { md: 0, xs: 0 } }}>
      <div className="skip-link__inner">
        <Link href={`#${targetId}`} className="skip-link__link">{label || t('global:skipToContent')}</Link>
      </div>
    </GridContainer>
  )
}
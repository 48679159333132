import React, { useEffect, useRef, useState } from 'react' // [![License](https://badgen.net/badge/License/MIT/blue)](https://github.com/juliencrn/usehooks-ts/blob/master/LICENSE)

// [![License](https://badgen.net/badge/License/MIT/blue)](https://github.com/juliencrn/usehooks-ts/blob/master/LICENSE)
// eslint-disable-next-line require-jsdoc
function useIntersectionObserver(
  elementRef,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }
) {
  const [entry, setEntry] = useState()
  const frozen
    = (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && freezeOnceVisible
  const updateEntry = ([entry]) => {
    setEntry(entry)
  }
  useEffect(() => {
    const node = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current
    const hasIOSupport = !!window.IntersectionObserver
    if (!hasIOSupport || frozen || !node) return
    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)
    observer.observe(node)
    return () => observer.disconnect()
  }, [
    elementRef === null || elementRef === void 0 ? void 0 : elementRef.current,
    JSON.stringify(threshold),
    root,
    rootMargin,
    frozen,
  ])
  return entry
}

// const createIntersectionObserver = ({
//   selector, callback, options = {
//     root: document.querySelector(`${selector ?? '#scrollArea'}`),
//     rootMargin: '0px',
//     threshold: 1.0,
//   }
// }) => new IntersectionObserver(callback, options)

/*
{
    "entry": {//entry: IntersectionObserverEntry
      boundingClientRect: DOMRectReadOnly {x: 0, y: 1076.875, width: 1284, height: 971, top: 1076.875, …}
      intersectionRatio: 0.06295056641101837
      intersectionRect: DOMRectReadOnly {x: 0, y: 1076.875, width: 1284, height: 61.125, top: 1076.875, …}
      isIntersecting: true
      isVisible: false
      rootBounds: DOMRectReadOnly {x: 0, y: 0, width: 1284, height: 1138, top: 0, …}
      target: div
      time: 110402.90000000224
      isVisible: true
    }
 */
/**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 */
function getPriceFromSku(sku) {
  return sku
}

export const ObservedElement = ({ callback, children }) => {
  const ref = useRef(null)
  const entry = useIntersectionObserver(ref, {
    threshold: 0.5,
    freezeOnceVisible: true,
  })

  useEffect(() => {
    const isVisible = !!entry?.isIntersecting
    if (isVisible) {
      callback()
    }
  }, [entry?.isIntersecting])

  return (
    <div
      ref={ref}
      // style={{
      //   // for debug
      //   backgroundColor: isVisible ? 'green' : 'red',
      // }}
    >
      {children}
    </div>
  )
}

/* eslint-disable require-jsdoc */
import React from 'react'

export class ErrorBoundary extends React.Component {
  constructor() {
    super()
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    // alert(JSON.stringify({ error }))

    return { hasError: true }
  }

  componentDidCatch(
    /** @type {Error} **/ error, /** @type {import('react').ErrorInfo} }*/
    errorInfo) {
    // You can also log the error to an error reporting service
    // alert(JSON.stringify({ error, errorInfo }))
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}
// @ts-nocheck
import { extend, filter } from 'lodash'

const formatMarketAsScope = (/** @type {{ marketId: any; }}  */ marketObj) => {
  if (!marketObj?.marketId) {
    console.log('invalid marketObj', marketObj)
    return null
  }
  return `market:${marketObj.marketId}`
}

const formatMarketIdAsScope = (/** @type {string} */ marketId) => {
  return `market:${marketId}`
}
/**
 * @typedef {import('@ggs/commercelayer/lib/types').Order} Order
 * @typedef {import('@ggs/types').Store} CurrentStore
 * @typedef {import('@ggs/types').OrderMarketRegion} OrderMarketRegion
 */
/**
 * @typedef {Object} lookupOrderMarketInCurrentStoreProps
 * @property {Order} order
 * @property {CurrentStore} currentStore
 */

/**
 * Lookup order market in current store supported markets.
 * @param {lookupOrderMarketInCurrentStoreProps} props
 * @return {OrderMarketRegion|null} Order market/region object or null when no match
 */
export const lookupOrderMarketInCurrentStore = ({ order, currentStore }) => {
  /**
   * @type {Number}
   */
  const orderMarketId = order.metadata?.market_number
  /**
   * @type {Number}
   */
  const orderRegionId = order.metadata?.region_id

  /**
   * @type {OrderMarketRegion}
   */
  const defaultResolution = {
    storePath: order.metadata?.store_path || '/',
    region: null,
    market: null,
  }

  return findRegionMarketMatch({
    ...defaultResolution,
    marketId: String(orderMarketId),
    regionId: String(orderRegionId),
    currentStore: String(orderRegionId),
  })
}

// @ts-ignore
const currentStoreAllRegions = ({ defaultRegion, regions }) =>
  filter([defaultRegion].concat(regions))

// @ts-ignore
const findRegionMatch = ({ regionId, currentStore }) => {
  return currentStoreAllRegions(currentStore).find(
    (region) => String(region.entityId) === String(regionId)
  )
}

// @ts-ignore
const findRegionMarketMatch = ({ regionId, marketId, currentStore, defaultResolution }) => {
  // find region
  const regionMatch = findRegionMatch({ regionId, currentStore })
  if (regionMatch) {
    // find market within the region
    const marketMatch = regionMatch.markets.find(
      (/** @type {{ marketId: any; }} */ market) => String(market.marketId) === String(marketId)
    )
    // if match extend the default with our match
    if (marketMatch) {
      return extend(defaultResolution, {
        region: regionMatch,
        market: marketMatch,
      })
    }
    // no (region AND market) match, use the injected default.
    return defaultResolution
  }
}

const scopeStringCheck = (marketIdScopeStr = '') =>
  marketIdScopeStr
  && marketIdScopeStr?.includes
  && marketIdScopeStr.includes('market:')
  && Number(marketIdScopeStr.split(':')[1])

const getMarketIdFromScope = (scopeStr = '') =>
  scopeStr.includes('market:') ? scopeStr.split(':')[1] : !Number.isNaN(scopeStr) ? scopeStr : null

const isScopeValid = (newScope) => {
  return !!newScope && scopeStringCheck(newScope)
}

const formatMainItem = ({ order, productOptionSku, quantity }) => ({
  order: order,
  sku_code: productOptionSku,
  quantity,
})

export {
  formatMarketIdAsScope,
  formatMarketAsScope,
  findRegionMatch,
  findRegionMarketMatch,
  currentStoreAllRegions,
  scopeStringCheck,
  getMarketIdFromScope,
  isScopeValid,
  formatMainItem,
}

import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'

import { ImageStyles } from '@ggs/gatsby/components/media'
import { Link } from '@ggs/gatsby/components/nav'
import { getSelectedStyles } from './helpers'
import { useUri } from '@ggs/hooks'
import { icons } from '@ggs/components'
import { ButtonVariant } from '@ggs/types'
import { getGTMEventLabel } from '@ggs/utils/getGTMEventLabel'

import './ResourceCardsGrid.scss'

export const ResourceCard = ({ card, linkStyle, gridSize, className }) => {
  const { imageOrientation, label, image, textContent, link, language } = card
  const uri = useUri(link, language.id)

  return (
    <Grid item>
      <Card className="resource-card__inner">
        <CardContent className="resource-card__content_columns">
          {label && (
            <Typography variant="h5" component="div" className="resource-card__title">
              {label}
            </Typography>
          )}
          <div className={`resource-card__info resource-card__${imageOrientation} ${className}`}>
            <div className="resource-card__image">
              {image && (
                <ImageStyles
                  className="card-media"
                  selectedStyles={[getSelectedStyles(imageOrientation)]}
                  {...image}
                />
              )}
            </div>
            <div className="resource-card__description">
              {textContent && (
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: textContent }}
                  className="resource-card__text"
                />
              )}
              {link && link.url && (
                <Link
                data-label={link?.title || label}
                data-action-type={getGTMEventLabel(link?.title || label, 'navigate-resource-card')}
                  href={uri}
                  className={'resource-card__link-wrapper'}
                >
                  {/* {link.title} */}
                  <div className={`resource-card__link resource-card__link--${linkStyle}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        // @ts-ignore
                        __html: link?.title,
                      }}
                    />
                    {linkStyle !== ButtonVariant.contained && icons.ChevronDown}
                  </div>
                </Link>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

const React = require('react')
const { RootProviders } = require('@ggs/gatsby/store')

/**
 *
 * @param {Element} element
 * @return {JSX.Element}
 */
function wrapRootElement({ element: children }) {
  return (
    <RootProviders>
      {children}
    </RootProviders>
  )
}

module.exports = wrapRootElement

// @ts-nocheck
export const ADDRESS_FIELDS = {
  // Contact
  email: 'email',
  phone: 'phone',
  // Address
  firstName: 'first_name',
  lastName: 'last_name',
  address1: 'line_1',
  address2: 'line_2',
  city: 'city',
  stateProvince: 'state_code',
  zipPostal: 'zip_code',
  countryCode: 'country_code',
}

// eslint-disable-next-line valid-jsdoc
/**
 * @param {{orderPropertyKey: string}} params
 */
export const AddressController = ({ orderPropertyKey }) => {
  // helpers
  const isBillingAddress = orderPropertyKey === 'billing_address'
  const isShippingAddress = orderPropertyKey === 'shipping_address'

  const initialState = {
    addressId: '',
    addressType: '',
    countryCode: '', //capitalize(String(address?.metadata?.country_name || '')),
    stateCode: '',
    orderPropertyKey: orderPropertyKey,
    // metadata
    firstName: '', // address?.first_name || customer?.metadata?.first_name || '',
    lastName: '', //address?.last_name || customer?.metadata?.last_name || '',
    email: '',
    // shippingAddress, // order['shipping_address']
    // billingAddress, // order['billing_address']
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
    }, //   const address = order[orderPropertyKey]
    // special handling for bug
    phoneNumber: '',
    zipCode: '',
    billingSameAsShipping: true,
  }

  return {
    initialState: () => initialState,
    isBillingAddress,
    isShippingAddress,
  }
}

// @ts-nocheck
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { defaultsDeep, uniqueId, get, delay, find } from 'lodash'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { Forms } from '@ggs/forms/schema'
import TextField from './TextField'

const propTypes = Forms.FormFieldProps
const defaultProps = Forms.FormFieldDefaults

const AutoCompleteSelect = forwardRef(
  ({ label, name, onChange: formikOnChange, disabled, ...fieldProps }, wrapperRef, ...props) => {
    // Provide some fallbacks based on form field data if not included.
    const autoCompleteOptions = defaultsDeep(
      fieldProps.autoCompleteOptions,
      Forms.FormFieldAutoCompleteDefaults,
      {
        valueKey: name,
        labelKey: name,
      }
    )
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const loading = open && options.length === 0
    const inputRef = useRef()
    const [defaultValue, setDefaultValue] = useState(null)
    const input = inputRef?.current?.querySelector('input')

    // For the auto complete on change, we must convert the select data to trigger formik by means
    // of the associated valueKey for this data set. Get the input ref and force set the value.
    const onChange = (e, value) => {
      if (input) {
        input.value = get(
          value,
          autoCompleteOptions.valueKey,
          autoCompleteOptions.freeSolo ? input?.value : ''
        )
      }
      // console.log(`${name} onChange`, value, input.value)
      e.target = input
      // Call custom on change
      autoCompleteOptions.onChange(input?.value)
      // Finally pass through to formik change handler.
      return formikOnChange(e)
    }

    // Obtain the matching value from the option's values.
    const getOptionSelected = (option, value) =>
      option[autoCompleteOptions.valueKey] === value[autoCompleteOptions.valueKey]

    // Obtain the correct label from the option.
    const getOptionLabel = (option) => option[autoCompleteOptions.labelKey] ?? ''

    // Generate the resulting input holding the label for the auto complete.
    const renderInput = (params) => (
      <TextField
        {...params}
        ref={inputRef}
        label={label}
        name={name}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
        type="text"
      />
    )

    // Obtain the API data for the given auto complete.
    useEffect(() => {
      let active = true
      // If loading don't re-trigger, or if options are loaded don't pull from the API again this page
      // view.
      if (
        (!loading && !autoCompleteOptions.forceLoad)
        || (autoCompleteOptions.forceLoad && options.length > 0)
      ) {
        return undefined
      }

      (async () => {
        const response = autoCompleteOptions.options || (await autoCompleteOptions.loader())

        if (active) {
          const newOptions = autoCompleteOptions.transpose(response)
          setOptions(newOptions)
          // console.log(` ${name} options loaded`, newOptions)
        }
      })()

      return () => {
        active = false
      }
    }, [loading, autoCompleteOptions.forceLoad])

    // Clear from state, as these options could change depending, and caching should be done on the
    // fetch side of things.
    useEffect(() => {
      if (!open && !autoCompleteOptions.forceLoad) {
        setOptions([])
      }
    }, [open])

    // If this input was flagged by a dependant field to be reset, do so in display and formik.
    useEffect(() => {
      // console.log('should reset?', autoCompleteOptions.reset)
      if (autoCompleteOptions.reset) {
        // Trigger the clear action.
        const clear = wrapperRef.current.querySelector('.MuiAutocomplete-clearIndicator')
        clear && clear.click()
        // As this action will force select the field, blur so user can choose when to enter it
        if (input) {
          input.value = ''
          input.blur()
        }
      }
    }, [autoCompleteOptions.reset])

    const setInitValue = (value) => {
      const opts = [...options]
      const def = {
        [autoCompleteOptions.valueKey]: value,
        [autoCompleteOptions.labelKey]: value,
      }
      // No force load, assume no default as there won't be any options to work against.
      setOptions([def])
      delay(setDefaultValue, 10, def)
      delay(setOptions, 100, autoCompleteOptions.options || opts || [])
      if (input) {
        input.value = value
      }
      // console.log('initValue', { value, def, input, options })
    }

    // When the options, for field updates, handle changes.
    useEffect(() => {
      const { value } = fieldProps

      // Don't process if we have set the default
      if (!defaultValue) {
        const currentOption = find(autoCompleteOptions.options || options, [autoCompleteOptions.valueKey, value])
        // If we're force loading, wait for the options to load
        if (autoCompleteOptions.forceLoad && currentOption) {
          // console.log('value differs, setting', value, currentOption)
          // With the selected option, update the default value
          delay(setDefaultValue, 10, currentOption)
        } else {
          setInitValue(value)
        }

        // To avoid failing displaying the input, always show later.
        if (autoCompleteOptions.forceLoad) {
          delay(() => {
            setDefaultValue((def) => def || {})
          }, 1000)
        }
      }
    }, [options, fieldProps])

    // console.log(`AutoComplete ${name}`, {
    //   open,
    //   loading,
    //   options,
    //   autoCompleteOptions,
    //   fieldProps,
    //   inputRef,
    //   defaultValue,
    // })

    return defaultValue !== null
    && (!autoCompleteOptions.forceLoad || (autoCompleteOptions.forceLoad && options.length > 0)) && (
      <Autocomplete
        ref={wrapperRef}
        id={uniqueId(`${name}-auto-complete`)}
        // style={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onChange={onChange}
        onBlur={autoCompleteOptions.freeSolo ? onChange : autoCompleteOptions.onBlur}
        onClose={() => {
          setOpen(false)
        }}
        isOptionEqualToValue={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        freeSolo={autoCompleteOptions.freeSolo}
        disabled={disabled}
        blurOnSelect
        renderInput={renderInput}
        defaultValue={defaultValue}
        openOnFocus={autoCompleteOptions.openOnFocus}
        autoFocus
        autoFill
        autoHighlight
        autoSelect
        {...autoCompleteOptions}
        {...props}
      />
    )
  }
)

// Exports
AutoCompleteSelect.defaultProps = defaultProps
AutoCompleteSelect.propTypes = propTypes
export default AutoCompleteSelect

import {
  gtmCheckoutValue,
  gtmCoupon,
  gtmCurrency,
  gtmItems,
} from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/gtagFromOrderParse'
import { fireCustomEvent } from '@ggs/components/ecommerceAnalytics/gtm/events/customEvents/fireCustomEvent'
import { find } from 'lodash'

export const fireSelectShippingMethod = async ({ order, shippingMethodId }) => {
  let selectedShippingMethodData = null
  // Find the selected shipping method details from order available shipping methods.
  find(order.shipments, (shipment) => {
    const shippingMethodMatch = find(shipment.available_shipping_methods, { id: shippingMethodId })
    if (shippingMethodMatch) {
      selectedShippingMethodData = shippingMethodMatch
      return true
    }
  })

  fireCustomEvent({
    customEventName: 'select_shipping_method',
    dataLayer: {
      coupon: gtmCoupon(order),
      currency: gtmCurrency(order),
      value: gtmCheckoutValue(order),
      items: gtmItems(order),
      shipping_method: selectedShippingMethodData?.name || 'unknown',
    },
  })
}

import {useLayoutMeta} from '@ggs/hooks'
import {useLink} from '@ggs/gatsby/lib'
import {useCurrentMetadata} from '@ggs/components/ecomm/ProductPurchaseOptions/useCurrentMetadata'
import {useSelector} from '@ggs/store'

export const useCurrentLanguage = () => {
  const layoutMeta = useLayoutMeta()
  const {lang: currentLangCode} = layoutMeta
  const {store_lang: currentOrderLanguage} = useCurrentMetadata()
  const userLanguage = useSelector((state) => state.settings.language)

  if (currentLangCode !== currentOrderLanguage !== userLanguage) {
    // console.log('our languages don\'t match')
  }
  return currentLangCode
}

const useLinkChangeLanguage = ({targetUrl = null, targetLanguage = null}) => {
  const currentLang = useCurrentLanguage()
  const safeUrl = useLink(false, false)
  const matchesLang = currentLang && currentLang === targetLanguage
  return () => {
    const targetLink = matchesLang
      ? 'internal:/' + targetUrl
      : targetUrl

    const {uri: newPageUrl} = safeUrl({
      href: targetLink,
    })

    return matchesLang ? newPageUrl : window.location.origin + targetUrl.pathname
  }
}

// checks local store to see if translation exists for this product, and returns link to it if possible
const useProductLinkTrans = ({entityId, langCode}) => {
  const transLinks = useSelector((state) => state.views.translations.products)
  if (entityId in transLinks) {
    return transLinks[entityId]?.linkTranslations?.[langCode] ?? transLinks[entityId]?.linkTranslations?.['x-default']
  }

  return null
}

// when you want to follow a (product) link even if it will change your language (but not your store).
// gets the link from canonical if possible, falls back
export const useProductLink = ({entityId, productLink}) => {
  // const { langCode } = storeLanguagePath({ pathname: productLink })
  const currentLang = useCurrentLanguage()
  const transLink = useProductLinkTrans({entityId, langCode: currentLang})
  const missingTransLink = useLinkChangeLanguage({targetUrl: productLink, targetLanguage: currentLang})
  return transLink ?? missingTransLink()
}
import { useDispatch } from 'react-redux'
import {
  resetAddPaymentMethod,
  resetAddShippingInfo,
  resetBeginCheckout,
  resetPurchase,
  setAddPaymentMethod,
  setAddShippingInfo,
  setBeginCheckout,
  setPurchase,
  setViewCart,
  useSelector,
} from '@ggs/store'
import { fireBeginCheckout } from '@ggs/components/ecommerceAnalytics/gtm/events/fireBeginCheckout'
import { fireAddShippingInfo } from '@ggs/components/ecommerceAnalytics/gtm/events/fireAddShippingInfo'
import { fireAddPaymentInfo } from '@ggs/components/ecommerceAnalytics/gtm/events/fireAddPaymentInfo'
import { firePurchase } from '@ggs/components/ecommerceAnalytics/gtm/events/firePurchase'
import { fireAddToCart } from '@ggs/components/ecommerceAnalytics/gtm/events/fireAddToCart'
import { fireRemoveFromCart } from '@ggs/components/ecommerceAnalytics/gtm/events/fireRemoveFromCart'
import { fireViewCart } from '@ggs/components/ecommerceAnalytics/gtm/events/fireViewCart'
import {
  getCheckoutState,
  hasCheckoutChanged,
  hasPaymentInfoChanged,
  hasShippingInfoChanged,
} from '@ggs/components/ecommerceAnalytics/hooks/checkoutAnalyticsHelpers'
import { gtmItems } from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/gtagFromOrderParse'
import { compareItems } from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/mapItemsToGTM'
import { fireViewProduct } from '@ggs/components/ecommerceAnalytics/gtm/events/fireViewProduct'
import { fireViewProductList } from '@ggs/components/ecommerceAnalytics/gtm/events/fireViewProductList'
import { fireSelectContent } from '@ggs/components/ecommerceAnalytics/gtm/events/fireSelectContent'
import { fireSelectItem } from '@ggs/components/ecommerceAnalytics/gtm/events/fireSelectItem'
import { fireSelectShippingMethod } from '../gtm/events/customEvents/fireSelectShippingMethod'
import {
  fireAddShippingAddressSuccess,
  fireAddShippingAddressFailure,
} from '@ggs/components/ecommerceAnalytics/gtm/events/customEvents/fireAddShippingAddress'

// has a shipping method been added or changed
// has the order id changed
// has a field been updated
export const useCheckoutAnalytics = () => {
  const dispatch = useDispatch()
  const { checkoutState } = useSelector((state) => state.checkout)
  const analytics = useSelector((state) => state.analytics)

  const dispatchClearAllAnalyticsReported = () => {
    dispatch(resetBeginCheckout())
    dispatchClearShipping()
  }

  const dispatchClearShipping = () => {
    dispatch(resetAddShippingInfo())
    dispatchClearPayment()
  }

  const dispatchClearPayment = () => {
    dispatch(resetAddPaymentMethod())
    dispatch(resetPurchase())
  }

  return {
    dispatchClearAllAnalyticsReported,
    dispatchClearShipping,
    dispatchClearPayment,
    fireBeginCheckout: ({ order }) => {
      const { reportedCheckout } = analytics
      if (hasCheckoutChanged({ checkoutState: { orderId: order?.id }, reportedCheckout })) {
        // dispatchClearAllAnalyticsReported()
        dispatch(setBeginCheckout({ checkoutState: { orderId: order?.id } }))
        fireBeginCheckout({ order })
      }
    },
    fireAddShippingInfo: ({ order }) => {
      const { reportedShippingInfo } = analytics
      if (
        hasShippingInfoChanged({
          checkoutState: getCheckoutState(order),
          reportedShippingInfo,
        })
      ) {
        // if (reported) return
        dispatch(setAddShippingInfo({ checkoutState: getCheckoutState(order) }))
        fireAddShippingInfo({ order })
      }
    },
    fireAddPaymentInfo: ({ order }) => {
      const { reportedPaymentInfo } = analytics
      if (
        hasPaymentInfoChanged({
          checkoutState: getCheckoutState(order),
          reportedPaymentInfo,
        })
      ) {
        dispatch(setAddPaymentMethod({ checkoutState }))
        fireAddPaymentInfo({ order })
      }
    },
    firePurchase: ({ order }) => {
      dispatch(setPurchase(getCheckoutState(order)))
      firePurchase({ order })
    },
    fireAddToCart: ({ gtmProduct, quantity, value, order }) => {
      // const { addToCart: { reported } } = analytics
      const { reportedCart } = analytics
      if (value && !compareItems(reportedCart, gtmItems(order))) {
        dispatch(setViewCart(gtmItems(order)))
        fireAddToCart({ gtmProduct, quantity, value })
      }
    },
    fireViewCart: ({ order }) => {
      // const { reportedCart } = analytics
      dispatch(setViewCart(gtmItems(order)))
      fireViewCart({ order })
    },
    fireRemoveFromCart: ({ gtmProduct, quantity, value, order }) => {
      // const { removeFromCart: { reported } } = analytics
      const { reportedCart } = analytics
      if (value && !compareItems(reportedCart, gtmItems(order))) {
        dispatch(setViewCart(gtmItems(order)))
        fireRemoveFromCart({ gtmProduct, quantity, value })
      }
    },
    fireSelectShippingMethod: ({ order, shippingMethodId }) => {
      fireSelectShippingMethod({ order, shippingMethodId })
    },
    fireAddShippingAddress: ({ order, type }) => {
      // Fire success or failure add shipping event based on type value.
      switch (type) {
        case 'success':
          fireAddShippingAddressSuccess({ order })
          break
        case 'failure':
          fireAddShippingAddressFailure({ order })
          break
        default:
          // Throw not implemented type.
          throw new Error(`Not implemented shipping event type: ${type}`)
      }
    },
    fireViewProduct: ({ items, value, currency }) => {
      fireViewProduct({ items, value, currency })
    },
    fireViewProductInList: ({ items, item_list_name, item_list_id }) => {
      fireViewProductList({ items, item_list_name, item_list_id })
    },
    fireSelectItem: ({ items, item_list_name, item_list_id }) => {
      fireSelectItem({ items, item_list_name, item_list_id })
    },
    fireSelectContent: ({ items, item_list_name, item_list_id }) => {
      fireSelectContent({ items, item_list_name, item_list_id })
    },
  }
}

import {
  ShippingAddressForm,
  ShippingAddressReview,
  ShippingMethodForm,
} from '@ggs/components/ecomm'
import { ConfirmOrCancelButtons } from '@ggs/components/ecomm/CheckoutSteps/ConfirmOrCancelButtons'
import { colors } from '@ggs/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import React, { useEffect } from 'react'
import OrderStatus from '../Checkout/elements/OrderStatus'
import CHECKOUT_STEP_ENUMS from '../Checkout/enums/CHECKOUT_STEP_ENUMS'
import { useShippingInformation } from './useShippingInformation'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'

const { ORDER } = CHECKOUT_STEP_ENUMS

/**
 * @typedef {Object} OrderInformationProps
 * @property {import('@commercelayer/sdk').Order|null} order CL viewed order
 */

/**
 * Checkout order information second step.
 * @return {JSX.Element}
 */
export default function OrderInformation() {
  const {
    order,
    shippingAddressComplete,
    shippingMethodComplete,
    clearShippingInformation,
    showShippingAddressReview,
    showShippingMethodReview,
    onSuccessAddress,
    onSuccessMethod,
    enableConfirm,
    goToPayment,
    clearShippingMethod,
    markStepComplete,
  } = useShippingInformation()

  // const taxCount = order?.tax_calculations_count ?? 0
  const { fireBeginCheckout } = useCheckoutAnalytics({ order })

  useEffect(() => {
    if (
      shippingAddressComplete
      && shippingMethodComplete
      // && taxCount
      && !enableConfirm()
    ) {
      markStepComplete(ORDER)
    }
  }, [
    // taxCount,
    order,
    shippingAddressComplete,
    shippingMethodComplete,
  ])

  useEffect(() => {
    fireBeginCheckout({ order })
  }, [])

  return order ? (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: colors.white,
            borderRadius: '4px',
            px: {
              xs: 3,
              md: 7,
            },
            pt: {
              xs: 3,
              md: 5,
            },
            pb: {
              xs: 3,
              md: 7,
            },
            '.form__actions': {
              justifyContent: 'flex-start',
            },
          }}
        >
          {showShippingAddressReview() ? (
            <ShippingAddressReview
              order={order}
              editOrder={() => {
                clearShippingInformation()
              }}
              contact={true}
            />
          ) : (
            <ShippingAddressForm
              order={order}
              onSuccess={() => {
                onSuccessAddress()
              }}
              onError={() => clearShippingInformation()}
            />
          )}
          {showShippingMethodReview() ? (
            <OrderStatus order={order} showStatus={false} />
          ) : (
            <ShippingMethodForm
              order={order}
              onSuccess={onSuccessMethod}
              onFailure={(/** @type {string} */ response) => {
                clearShippingInformation()
                console.log('failure!' + response)
              }}
              pendingAddress={!shippingAddressComplete}
            />
          )}
          {/*) : null}*/}
          <ConfirmOrCancelButtons
            enabled={enableConfirm()}
            displayCancel={false}
            displayContinue={shippingAddressComplete && shippingMethodComplete}
            goToNextStep={() => {
              onSuccessMethod()
              // markStepComplete(ORDER)
              // goToPayment()
            }}
          />
        </Box>
      </Grid>
    </Grid>
  ) : null
}

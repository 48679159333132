// @ts-nocheck
import { useSelector } from '@ggs/store'
import { getWindow } from '@ggs/utils'
import { isString } from 'lodash'
import { useDispatch } from 'react-redux'
import { useLayoutMeta } from '@ggs/hooks'

export const useCurrentMetadata = () => {
  const currentMarket = useSelector((state) => state?.settings?.currentMarket)
  const currentStore = useSelector(({ ui }) => ui?.layoutMeta?.currentStore)

  const { market_number, region_id, store_url, store_path, store_lang } = formatMetaData({
    currentMarket,
    currentStore,
  })

  return {
    market_number,
    region_id,
    store_url,
    store_path,
    store_lang,
  }
}

const langCodeFromDrupalLanguage = (lang) => {
  const langArr = lang?.drupalLanguage
  const flattenLangArr = []

  if (Array.isArray(langArr)) {
    const arrMap = langArr
      .map((e) => e.entityId)
      .reduce((i, j, k) => {
        return j
      })

    return arrMap
  }
  return []
}
const globalDefaultScope = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`
const isStaging = process.env.GATSBY_IS_STAGING === '1'

export const useOnAllowEcommListCheck = () => {
  const dispatch = useDispatch()
  const layoutMeta = useLayoutMeta()
  const { lang: currentLangCode, canonicalUrls } = layoutMeta
  const { market_number } = useCurrentMetadata()
  // const currentMarket = useSelector((state) => state?.settings?.currentMarket)
  const currentStore = useSelector(({ ui }) => ui?.layoutMeta?.currentStore)

  const allowedLanguages = currentStore?.drupalLanguages ?? []
  const allowedLanguageMap = allowedLanguages.map((e) => langCodeFromDrupalLanguage(e))
  return isStaging || ['10332', '7796', '8949'].includes(String(market_number))
}

export const useAllowLanguageForStore = () => {
  const layoutMeta = useLayoutMeta()
  const checkLanguage = useIsLanguageAllowed()
  const { lang: currentLangCode } = layoutMeta

  return checkLanguage(currentLangCode)
}

export const useIsLanguageAllowed = () => {
  const currentStore = useSelector(({ ui }) => ui?.layoutMeta?.currentStore)
  const allowedLanguages = currentStore?.drupalLanguages ?? []
  const allowedLanguageMap = allowedLanguages.map((e) => langCodeFromDrupalLanguage(e))

  return (langCode) =>
    allowedLanguageMap.filter((e) => {
      return langCode === e
    })
}

const stringCheck = (str) => {
  return isString(str) ? str : null
}

const numberCheck = (num) => {
  return !Number.isNaN(Number(num)) ? Number(num) : null
}

export const formatMetaData = ({ currentMarket, currentStore } = {}) => {
  const market_number = numberCheck(currentMarket?.marketId) ?? stringCheck(currentMarket?.marketId)
  const region_id
    = numberCheck(currentMarket?.region?.entityId) ?? stringCheck(currentMarket?.region?.entityId)
  const store_url = stringCheck(getWindow('location.origin'))
  const store_path = stringCheck(currentStore?.url ?? null)
  const store_lang = stringCheck(currentStore?.language?.id)

  return {
    market_number,
    region_id,
    store_url,
    store_path,
    store_lang,
  }
}

// @ts-nocheck
import React, { useContext } from 'react'

export const CommerceApiContext = React.createContext({
  accessToken: {
    token: null,
    tokenRefresh: null,
    tokenExpiresAt: null,
    // tokenType: null,
  },
  // customer: {
  //   customerId: null,
  //   customerEmail: null
  // },
  marketId: null,
  scope: '',
  clClient: null
})
/**
 * @typedef {Object} CommerceSessionContextProvider
 * @property {JSX.Element} children
 */
/**
 * @param {CommerceSessionContextProvider} props
 * @return {JSX.Element}
 */

// THIS MUST ALWAYS FUNCTION.. USE GLOBAL DEFAULT MARKET TO GET FALLBACK IF
// TOKEN / MARKET INVALID.
export const CommerceApiContextProvider = ({
  scope,
  marketId,
  accessToken,
  clClient,
  children,
}) => {
  return (
    <CommerceApiContext.Provider value={{
      scope,
      marketId,
      accessToken,
      clClient,
      children,
    }}>
      {children}
    </CommerceApiContext.Provider>
  )
}

// hook for using the checkout context state; makes sure context: token,
// scope, etc. is valid, then passes checkout context values (using
// useactivecheckout functional hook) to checkout context consumers.
export const useCommerceApiContext = () => {
  const commerceApiContext = useContext(CommerceApiContext)
  if (commerceApiContext === undefined) {
    throw new Error(
      'commerceApiContext is undefined- something wrong with token or scope or client... fallback default scope.'
    )
  }

  const {
    scope,
    marketId,
    accessToken,
    clClient,
  } = commerceApiContext

  // bootstrap API with token if set on local && valid..
  return {
    scope,
    marketId,
    accessToken,
    clClient
  }
}
// @ts-nocheck
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { isBoolean, isFunction, uniqueId } from 'lodash'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { useMemo } from 'react'
import zipcodes from 'zipcodes-regex'
import { PhoneNumberUtil } from 'google-libphonenumber'

/**
 * Transform boolean input to 'yes' / 'no' string.
 * @param {boolean} value Boolean flag
 * @return {string} Transformed value
 */
export const yesNoFromBoolean = (value) => {
  // eslint-disable-next-line no-nested-ternary
  return isBoolean(value) ? (value ? 'yes' : 'no') : ''
}

/**
 * @param {Object=} country
 * @return {*}
 */
export default function useValidators({ country = {} } = {}) {
  const { t } = useTranslation()
  const today = useMemo(() => dayjs(), [])
  const phoneUtil = PhoneNumberUtil.getInstance()

  const yupString = Yup.string(t('global:form.error.validValue'))
  const yupNumber = Yup.number(t('global:form.error.validValue'))
  const yupBoolean = Yup.boolean(t('global:form.error.validValue'))
  const yupArray = Yup.array(t('global:form.error.validValue'))

  return useMemo(
    () => ({
      yupString,
      yupNumber,
      yupBoolean,
      yupArray,
      nonBlankArray: Yup.array()
        .min(1, t('global:form.error.required'))
        .required(t('global:form.error.required')),
      date: yupString.test(
        uniqueId('validDate_'),
        t('global:form.error.invalidDate'),
        (newValue, { path: name }) => {
          let result = true
          const input = document.querySelector(`input[name='${name}']`)
          // eslint-disable-next-line no-param-reassign
          if (!input?.disabled) {
            const { value } = input
            const selectedDate = dayjs(value, 'YYYY-MM-DD')

            if (
              !value
              || !selectedDate
              || !selectedDate?.isValid()
              || selectedDate?.isAfter(today)
              || selectedDate?.format('YYYY-MM-DD') !== value
            ) {
              result = false
            }
          }

          // console.log('selectedDate', {
          //   result,
          //   fieldName,
          //   value,
          //   selectedDate: selectedDate.format('YYYY-MM-DD'),
          //   context,
          // })

          return result
        }
      ),
      username: yupString
        .min(4, t('global:form.error.minXCharactersLong', { min: 4 }))
        .max(32, t('global:form.error.maxXCharactersLong', { max: 32 }))
        .matches(/^[_.@A-Za-z0-9]*$/g, t('global:form.error.username'))
        .required(t('global:form.error.required')),
      password: yupString.required(t('global:form.error.required')),
      newPassword: yupString
        .min(8, t('global:form.error.minXCharactersLong', { min: 8 }))
        .max(32, t('global:form.error.maxXCharactersLong', { max: 32 }))
        .required(t('global:form.error.required')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('new_password')], t('global:forms.errors.confirmPassword'))
        .required(t('global:form.error.required')),
      countryCanadaOnly: Yup.string()
        .oneOf(['Canada', 'CA', 'CANADA', 'canada'], t('global:forms.errors.shippingCountryWarningLabel'))
        .required(t('global:form.error.required')),
      firstName: yupString
        .min(2, t('global:form.error.minXCharactersLong', { min: 2 }))
        .max(30, t('global:form.error.maxXCharactersLong', { max: 30 }))
        .required(t('global:form.error.required')),
      city: yupString
        .min(2, t('global:form.error.minXCharactersLong', { min: 2 }))
        .max(35, t('global:form.error.maxXCharactersLong', { max: 35 }))
        .required(t('global:form.error.required')),
      lastName: yupString
        .min(2, t('global:form.error.minXCharactersLong', { min: 2 }))
        .max(30, t('global:form.error.maxXCharactersLong', { max: 30 }))
        .required(t('global:form.error.required')),
      email: yupString
        .email(t('global:form.error.email'))
        .required(t('global:form.error.required'))
        .max(61, t('global:form.error.maxXCharactersLong', { max: 61 })),
      // Check for all valid phone number types
      phoneNumber: Yup.string()
        .max(21, t('global:form.error.maxXCharactersLong', { max: 21 }))
        .test(
          uniqueId('validPhone_'),
          t('global:form.error.phoneNumber'),
          (newValue, { path: name }) => {
            let result = true
            const input = document.querySelector(`input[name='${name}']`)
            // eslint-disable-next-line no-param-reassign
            if (input && !input?.disabled) {
              const { value } = input
              const countryCode = country?.current || ''
              if (countryCode && value) {
                try {
                  const number = phoneUtil.parseAndKeepRawInput(value, countryCode)
                  result = phoneUtil.isValidNumberForRegion(number, countryCode)
                } catch (e) {
                  // Prevent string supplied is too short to be a phone number
                  console.log('Phone util parse error:', e)
                }

                // console.log('phone check', {
                //   value,
                //   number: number.getNationalNumber(),
                //   result,
                //   countryCode,
                // })
              } else {
                result = false
              }
            }
            return result
          }
        ),
      verificationCode: yupString.required(t('global:form.error.required')),
      postalCode: Yup.string(t('global:form.error.validValue'))
        .max(11, t('global:form.error.maxXCharactersLong', { max: 11 }))
        .test(
          uniqueId('validPostal_'),
          t('global:form.error.postalCode'),
          (newValue, { path: name }) => {
            let result = true
            const input = document.querySelector(`input[name='${name}']`)
            // eslint-disable-next-line no-param-reassign
            if (input && !input?.disabled) {
              const { value } = input
              const countryCode = country?.current || ''
              const zipcode = String(countryCode ? zipcodes[countryCode] : '').replace(' +?', ' *')
              if (zipcode) {
                const regex = new RegExp(zipcode, 'gi')
                result = regex.test(value)

                // console.log('zipcode check', {
                //   zipcode,
                //   result,
                //   regex,
                //   value,
                //   countryCode,
                // })
              }
            }

            return result
          }
        )
        .required(t('global:form.error.required')),
      amount: (min) =>
        yupNumber
          .min(min, t('global:form.error.minMaxUnlimited', { min }))
          .required(t('global:form.error.required')),
      yesNoOptions: yupString
        .oneOf(['yes', 'no'], t('global:form.error.required'))
        .required(t('global:form.error.required')),
      acceptTerms: yupBoolean
        .oneOf([true], t('global:form.error.acceptTerms'))
        .required(t('global:form.error.acceptTerms')),
      /**
       * Validate amount within mix/max range.
       * @param {Number} min
       * @param {Number} max
       * @param {function} formatter
       * @return {Yup.number}
       */
      minMaxAmount: (min, max, formatter = null) =>
        yupNumber
          .min(
            min,
            t('global:form.error.minXAmount').replace(
              '{amount}',
              isFunction(formatter) ? formatter(min) : min.toString()
            )
          )
          .max(
            max,
            t('global:form.error.maxXAmount').replace(
              '{amount}',
              isFunction(formatter) ? formatter(max) : max.toString()
            )
          ),
      nonBlank: yupString
        .min(2, t('global:form.error.minXCharactersLong', { min: 2 }))
        .max(61, t('global:form.error.maxXCharactersLong', { max: 61 }))
        .required(t('global:form.error.required')),
    }),
    [country]
  )
}

import { ErrorBoundary } from '@ggs/gatsby/store/ErrorBoundary'
import OneTrustContextProvider from '@ggs/components/onetrust/OneTrustContext'
import { CommerceApi } from '@ggs/components/ecomm/Checkout/CommerceApi'

const React = require('react')

// Dates
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

// Theme
const { ModalContextProvider } = require('@ggs/components/common')
const { MuiThemeProvider } = require('@ggs/styles')
const { StoreProvider } = require('./StoreProvider')
const { GlobalActionsDispatcher } = require('./GlobalActionsDispatcher')
const { ApolloProvider } = require('./ApolloProvider')
const { CommerceApp } = require('@ggs/components/ecomm/Checkout/CommerceApp')

/**
 * @typedef {Object} RootProvidersProps
 * @property {JSX.Element} children
 */
/**
 *
 * @param {RootProvidersProps} props
 * @return {JSX.Element}
 */
export default function RootProviders({ children }) {
  return (
    <CommerceApi>
      <ModalContextProvider>
        <OneTrustContextProvider>
          <MuiThemeProvider>
            <StoreProvider>
              <ErrorBoundary>
                <GlobalActionsDispatcher>
                  <CommerceApp>
                    <ApolloProvider>
                      {children}
                    </ApolloProvider>
                  </CommerceApp>
                </GlobalActionsDispatcher>
              </ErrorBoundary>
            </StoreProvider>
          </MuiThemeProvider>
        </OneTrustContextProvider>
      </ModalContextProvider>
    </CommerceApi>
  )
}


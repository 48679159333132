import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import { Divider } from '@ggs/components/paragraphs'
import { Button } from '@ggs/gatsby/components/nav'
import { ButtonVariant } from '@ggs/types'
import React from 'react'

export const ConfirmOrCancelButtons = ({
  enabled = false,
  displayCancel = false,
  displayContinue = false,
  goToNextStep = null,
}) => {
  const { t } = useI18n()

  return enabled && (displayCancel || displayContinue) ? (
    <>
      <Divider
        sx={{
          mt: 3,
          mb: 3,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ my: 2 }}>
          {displayContinue ? (
            <Button
              variant={ButtonVariant.contained}
              onClick={() => goToNextStep()}
              label={t('ecomm:button.continueToPaymentInformation')}
            >
              {t('ecomm:button.saveAndContinueToPaymentInformation', {
                nextStep: t('ecomm:button.saveAndContinueToPaymentInformation'),
              })}
            </Button>
          ) : null}
        </Box>
        {/*</Box>*/}
        <Box sx={{ my: 2 }}>
          {displayCancel ? (
            <Button
              variant={ButtonVariant.outlined}
              onClick={() => goToNextStep()}
              label={t('ecomm:button.cancelAndContinueToPaymentInformation')}
            >
              {t('ecomm:button.cancelAndContinueToPaymentInformation')}
            </Button>
          ) : null}
        </Box>
      </Box>
    </>
  ) : null
}

// @ts-nocheck
import React, { forwardRef, useState } from 'react'
import List from '@mui/material/List'
import * as PropTypes from 'prop-types'
import SelectableAccordion from './SelectableAccordion'

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const defaultProps = {
  // return an empty array
  options: [],
}

const SelectableAccordionList = ({
  onChange,
  name,
  options,
  value,
  defaultOpen,
  disabled,
  footer = null,
  ...props
}) => {
  const [list, setList] = useState(() => {
    const newList = [...options]
    newList &&
      newList.forEach((data) => {
        data.isChecked = value && value.indexOf(data.description) >= 0
        data.isOpen = defaultOpen && defaultOpen.indexOf(data.description) >= 0
      })
    return newList
  })

  return (
    <List className="selectable-accordion-list">
      {list &&
        list.map(({ panelId, description, competency, liItems, isOpen, isChecked, className }) => {
          return (
            <SelectableAccordion
              key={panelId}
              className={`selectable-accordion-list-item${className}`}
              description={description}
              competency={competency}
              liItems={liItems}
              isOpen={isOpen}
              onChange={onChange}
              name={name}
              isChecked={isChecked}
              isDisabled={disabled}
            />
          )
        })}
      {footer && <div className="selectable-accordion-list__footer">{footer}</div>}
    </List>
  )
}

SelectableAccordionList.propTypes = propTypes
SelectableAccordionList.defaultProps = defaultProps

export default SelectableAccordionList

// Measure product detail views
// After clicking a product listing, a user might view the product details page.
// To measure product detail views, send a view_item event with the product details:
import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'

export const fireViewProductList = ({ items, item_list_name, item_list_id }) => {
  return pushDataLayer({
    eventName: EaActionType.viewItemList,
    dataLayer: {
      // currency,
      // value,
      items: items.map((i, k) => {
        return {
          ...i,
          item_list_name,
          item_list_id,
          quantity: 1,
          index: k,
        }
      }),
      item_list_name,
      item_list_id,
      // items
    },
  })
}

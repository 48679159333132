import React from 'react'
import { Button, Link } from '@ggs/gatsby/components/nav'
import { useI18n } from '@ggs/gatsby/lib'
import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'

// Assets
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { common, typography } from '@ggs/styles'
import Box from '@mui/material/Box'

/**
 * @typedef {import('@ggs/types').ProductCategory} ProductCategory
 * @typedef {import('@ggs/types').ProductUsage} ProductUsage
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').Store} Store
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import('@ggs/layouts/Search/useSearchQuery').SearchResultProps} SearchResultProps
 */
/**
 *
 * @param {SearchResultProps} props
 * @return {JSX.Element}
 */
export default function SearchResult({ url = '', title = '', description = '', image = null }) {
  const { t } = useI18n()
  const isLoading = !title
  // Determine if there is an available image
  const hasImage = image?.src && title
  // Determine if we're still loading or have an image
  const imageOrLoading = isLoading || hasImage

  // eslint-disable-next-line prefer-rest-params,no-undef
  // console.log('SearchResult render', arguments[0]) //, JSON.stringify(arguments[0]))

  return (
    <Box
      className="search-result"
      sx={{
        pb: 4,
        mb: 4,
        borderBottom: common.borderLightGrey,
        '.search-result__title': {
          typography: typography.pBold,
          mt: 0,
        },
        '.search-result__button': {
          p: '0 !important',
        },
      }}
    >
      <Grid container flexDirection={{ xs:'column',md: 'row' }} flexWrap={'wrap'}>
        {imageOrLoading && (
          <Grid
            item
            flex={{ xs: '0 0 100%', md: '0 0 184px' }}
            margin={{ xs: '0 auto 16px', md: '0 16px 0 0' }}
          >
            {hasImage ? (
              <Link href={url}>
                <img src={image.src} alt={image.alt} />
              </Link>
            ) : (
              <Skeleton
                variant="rectangular"
                style={{ display: 'block', borderRadius: 0 }}
                width={184}
                height={112}
                animation={!url ? false : null}
              />
            )}
          </Grid>
        )}
        <Grid item flex={{ xs: '0 0 100%', md: '1 1 calc(100% - 200px)' }}>
          {title ? (
            <p className="search-result__title">
              <Link
                link={{ uri: url, title: <span dangerouslySetInnerHTML={{ __html: title }} /> }}
              />
            </p>
          ) : (
            <Skeleton variant="text" width="100%" />
          )}
          {description && (
            <div
              className="search-result__description"
              dangerouslySetInnerHTML={{
                __html: description.replace(/<p>(\s|&nbsp;)<\/p>/, ''),
              }}
            />
          )}
          {isLoading && <Skeleton variant="text" width="100%" height={100} />}
          {url ? (
            <Button
              className="search-result__button"
              link={{
                title: t('global:button.viewDetails'),
                uri: url,
              }}
              variant={Button.variant.text}
              endIcon={<ChevronRightIcon />}
            />
          ) : (
            <Skeleton variant="rectangular" width={142} height={46} />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

import React from 'react'
import dayjs from 'dayjs'
import { Button } from '@ggs/gatsby/components/nav'
import { isObject } from 'lodash'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { Title } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { GridContainer } from '@ggs/components/layout'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { ArticleMeta } from '@ggs/components/common'
import { typography, colors, rem } from '@ggs/styles'
import { CardVariant } from '@ggs/types'

// Assets
import './ArticleCard.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

/**
 * @typedef {import("@ggs/types").NewsAndEventCategory} NewsAndEventCategory
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import('@ggs/types').ArticleCard} ArticleCard
 */

/**
 * Article Card component.
 * @param {ArticleCard} props
 * @return {JSX.Element}
 */
export default function ArticleCard({
  url = '',
  title = '',
  publishedDate,
  articleCategories,
  articleImage,
  summary = '',
  variant = CardVariant.default,
}) {
  const { t } = useI18n()
  const sx = {}

  switch (variant) {
    case CardVariant.condensed:
      Object.assign(sx, {
        '.article-card__content': {
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        },
        '.article-card__copy': {
          backgroundColor: colors.white,
          flex: {
            xs: '0 0 100%',
            lg: '0 0 45%',
          },
          margin: 3,
          order: {
            xs: 2,
            lg: 1,
          },
        },
        '.article-card__image': {
          flex: {
            xs: '0 0 100%',
            lg: '1 0 48%',
          },
          '.gatsby-image-wrapper, img': {
            lg: {
              width: '100%',
              // height: '100%',
              minHeight: 280,
            },
          },
          order: {
            xs: 1,
            lg: 2,
          },
        },
        '.article-meta__categories': {
          display: 'none',
        },
        button: {
          p: '0 !important',
        },
        '.MuiButton-endIcon': {
          span: {
            svg: {
              color: colors.red,
            },
          },
        },
      })
      break
    case CardVariant.listing:
      Object.assign(sx, {
        '.article-card__card': {
          pl: 0,
          pr: 0,
        },
        '.article-card__content': {
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        },
        '.article-card__copy': {
          flex: {
            xs: '0 0 100%',
            md: '1 1 auto',
          },
          mr: {
            md: 5,
          },
          ml: {
            md: 3,
          },
          maxWidth: '100%',
          '& .title': {
            mb: 4,
            textAlign: 'left',
            '.title__subtitle': {
              typography: typography.pRegular,
              mt: 2,
            },
          },
          '.button': {
            p: 0,
          },
        },
        '.article-card__image': {
          flex: {
            xs: '0 0 100%',
            md: '0 0 296px',
          },
          maxWidth: '100%',
          mb: 0,
        },
        '.MuiButton-endIcon': {
          svg: {
            path: {
              fill: colors.red,
            },
          },
        },
      })
      break
  }

  const viewButton = (
    <Button
      data-label={title}
      data-action-type="navigate-article-card"
      link={{
        title: t('global:button.readMore'),
        uri: url,
      }}
      variant={Button.variant.text}
      endIcon={<ChevronRightIcon />}
    />
  )

  const cardCopy = (
    <div className="article-card__copy">
      <ArticleMeta
        url={url}
        publishDate={dayjs
          // @ts-ignore
          .utc(publishedDate)}
        articleCategories={articleCategories}
      />
      <Title title={title} subtitle={variant !== CardVariant.condensed ? summary : ''} style="h4" />
      {url && variant !== CardVariant.default ? viewButton : null}
    </div>
  )

  const cardImage = (
    <>
      {isObject(articleImage) && (
        <div className="article-card__image">
          <ImageStyles {...articleImage} selectedStyles={['pageHeroRelated']} />
        </div>
      )}
    </>
  )

  // eslint-disable-next-line prefer-rest-params
  // console.log('ArticleCard render', arguments[0]) //, JSON.stringify(arguments[0]))

  return (
    <GridContainer className="article-card" data-variant={variant} sx={sx}>
      <Card className="article-card__card">
        <CardContent className="article-card__content">
          {cardCopy}
          {cardImage}
        </CardContent>
        {url && variant === CardVariant.default && (
          <CardActions className="article-card__actions">{viewButton}</CardActions>
        )}
      </Card>
    </GridContainer>
  )
}

ArticleCard.variant = CardVariant

import { groupBy, orderBy } from 'lodash'
import dayjs from 'dayjs'

/**
 * @typedef {import('dayjs').Dayjs} Dayjs
 * @typedef {import('@ggs/types').DrupalCalendarDayRaw} DrupalCalendarDayRaw
 * @typedef {import('@ggs/types').DrupalCalendarDay} DrupalCalendarDay
 */
/**
 * * Digests raw drupal date data and formats it to usable date references.
 * @param {DrupalCalendarDayRaw} date
 * @return {DrupalCalendarDay}
 */
export function digestDrupalDate(date) {
  const { start, end } = date || {}
  const timezone = date?.timezone || 'America/Toronto'
  return {
    ...date,
    timezone,
    // @ts-ignore
    start: dayjs.unix(start).tz(timezone),
    // @ts-ignore
    end: dayjs.unix(end).tz(timezone),
  }
}

/**
 * * Digests raw drupal date data and formats it to usable date references.
 * @param {Array<DrupalCalendarDayRaw>} dates
 * @return {Array<Array<DrupalCalendarDay>>}
 */
export function digestDrupalDates(dates) {
  const grouped = orderBy(groupBy(dates, 'repeatId'), ['repeatId', 'repeatIndex', 'start'])
  const formatted = grouped.map((dateGroups) => dateGroups.map(digestDrupalDate))
  // @ts-ignore
  // console.log('dates', {
  //   0: formatted?.[0]?.[0]?.start?.format('LLL z'),
  //   1: formatted?.[1]?.[0]?.start?.format('LLL z')
  // })

  return formatted
}

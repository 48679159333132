// @ts-nocheck
import { icons } from '@ggs/components'
import { Memoized, Tooltips } from '@ggs/components/common'
import { useSupportingLinks } from '@ggs/components/ecomm'
import { Button } from '@ggs/gatsby/components/nav'
import { useI18n } from '@ggs/gatsby/lib'
import { useSelector } from '@ggs/store'
import Badge from '@mui/material/Badge'
import React from 'react'
import Box from '@mui/material/Box'
import { colors } from '@ggs/styles'
import { useLayoutMeta } from '@ggs/hooks'

/**
 * @return {JSX.Element}
 */
export default function HeaderActions() {
  const { t } = useI18n()
  const itemsInCart = useSelector((state) => state.commerce.order?.skus_count)
  const {
    currentStore: { ecommEnabled },
  } = useLayoutMeta()
  const { cart, search } = useSupportingLinks()

  // console.log('HeaderActions', {
  //   itemsInCart,
  // })

  return (
    <Box
      className="header__buttons"
      sx={{
        '.header__cart-btn:focus': {
          '.MuiBadge-badge': {
            color: `${colors.white} !important`,
          },
        },
      }}
    >
      <Button
        className="header__search-btn"
        variant={Button.variant.text}
        link={{
          uri: search,
          title: icons.Search(t('global:button.search')),
        }}
      />
      {/*<AccountStatus/>*/}
      <Memoized deps={[ecommEnabled, itemsInCart]}>
        {ecommEnabled
          && (itemsInCart ? (
            <Button className="header__cart-btn" link={{ uri: cart }} variant={Button.variant.text}>
              <Badge badgeContent={itemsInCart} color="secondary">
                {icons.Cart(t('global:button.cart'))}
              </Badge>
            </Button>
          ) : (
            <Tooltips.LightTooltip title={t('global:tooltip.noItemsInCart')} describeChild={true}>
              <span
                className="header__cart-btn header__cart-btn--empty"
              >
                <Badge badgeContent={itemsInCart} color="secondary">
                  {icons.Cart(t('global:button.cart'))}
                </Badge>
              </span>
            </Tooltips.LightTooltip>
          ))}
      </Memoized>
    </Box>
  )
}

const React = require('react')
// Inject global ENVs to FE.
const globals = require('../../definitions/globals.json')
// Style wide base styling
require('../../web/styles/_root.scss')

// Set up sentry on page.
const { triggerSentry } = require('@ggs/gatsby/sentry')
const { bindApiError } = require('@ggs/commercelayer')
bindApiError(triggerSentry)

try {
  window.globals = globals
  // console.log('wrapPageElement', window.globals)
} catch (e) {
  // console.log('window.globals not defined')
}

const wrapPageElement = ({ element, props }) => {
  return <>{element}</>
}

module.exports = wrapPageElement

import { QuantitySelect } from '@ggs/components/ecomm'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { useOrderLookup, useSkuLookup } from '@ggs/components/ecomm/hooks'
import useFullWidthButtonSx from '@ggs/components/hooks/useFullWidthButtonSx'
import useQuantitySelect from '@ggs/components/hooks/useQuantitySelect'
import { Text } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { defaults, filter, isEmpty, isFunction, noop } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useExistingQuantities from '@ggs/components/ecomm/ProductAddCart/useExistingQuantities'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { colors } from '@ggs/styles'
import MenuItem from '@mui/material/MenuItem'
import { Button } from '@ggs/gatsby/components/nav'
import { useLayoutMeta } from '@ggs/hooks'
import { triggerSentry } from '@ggs/gatsby/sentry'
import { Severity } from '@sentry/gatsby'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').Product} Product
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * @typedef {Object} ProductAddCart
 * @property {function} addToCart Function to add item to local cart or CL draft
 *   order
 * @property {function=} optionalItemExists Function to retrieve a product
 *   option correlated item from local cart
 * @property {Product} product The host product of the current option
 * @property {ProductOption|null} currentOption Product option selected by
 *   customer
 * @property {Boolean=} displayOptionsSelect Flag to indicate if options
 *   selector should be rendered
 * @property {string} buttonLabel Label for the 'Add to cart' action button
 */

/**
 * @typedef {import('@commercelayer/sdk').Sku} Sku
 */

/**
 * @typedef {Object} ProductAddCartState
 * @property {ProductOption|null} currentOptionalProductOption A selected
 *   optional product option
 */

/**
 * @typedef {Object} OptionalProductOption
 * @property {string} name
 * @property {string} value
 */

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {ProductAddCart} props
 * @return {{handleSubmit: handleSubmit, displayUnavailableForSale: (false|JSX.Element),
 * renderOptionsSelect: (false|boolean), sxButtonWhite: (SxProps|*), displayAddButton: JSX.Element,
 * displayQuantitySelector: (false|JSX.Element)}}
 */
export default function useProductAddCart(props) {
  const {
    addToCart,
    optionalItemExists,
    currentOption,
    displayOptionsSelect = false,
    product,
    buttonLabel = '',
  } = props
  const { productOptions } = product
  const { setNewOrder } = useCheckoutContext()
  const { existingQuantity } = useExistingQuantities(currentOption)

  /**
   * @type {ProductAddCartState}
   */
  const defaultState = {
    currentOptionalProductOption: null,
    // existingQuantity: 0,
  }

  const [localState, setLocalState] = useState(defaultState)
  const { t } = useI18n()
  const { lookupProductOptionSku, lookupProductOptionCurrentMarketSku } = useSkuLookup()
  const { lookupLineItem, lineItems } = useOrderLookup()
  const productOptionSku = lookupProductOptionCurrentMarketSku(currentOption)

  // Use selected optional product when available in local state to determine
  // the quantity options, when not available use the current option.
  const selectedOption = localState.currentOptionalProductOption || currentOption
  const currentSku = selectedOption ? lookupProductOptionSku(selectedOption) : null
  const itemInLocalCart = useMemo(() => {
    const localCartItem = isFunction(optionalItemExists)
      ? optionalItemExists(selectedOption, productOptions)
      : null

    // If local cart has a product option set in state.
    if (localCartItem) {
      setLocalState({
        ...localState,
        currentOptionalProductOption: localCartItem.productOption,
      })
    }

    return localCartItem
  }, [])

  const temporalQuantity = itemInLocalCart ? itemInLocalCart.quantity : null

  // useEffect(() => {
  //   const currentLineItem = lookupLineItem(productOptionSku)
  //   const existingQuantity = currentLineItem?.quantity || 0
  //   // console.log('ExistingSkuQuantity:', existingQuantity)
  //   setLocalState({
  //     ...localState,
  //     existingQuantity,
  //   })
  // }, [lineItems, selectedOption])

  const {
    currentQuantity,
    quantityOptions,
    emptyQuantityOptions,
    clearQuantity,
    handleQuantitySelection,
  } = useQuantitySelect(currentSku, existingQuantity, temporalQuantity)
  const dispatch = useDispatch()
  const layoutMeta = useLayoutMeta()
  /**
   * Trigger add to cart indicating the selected quantity value.
   */
  const handleAddToCart = () => {
    // console.log('CurrentSku:', currentSku)
    // console.log('Product to add: ', product)
    // console.log('Product option to add: ', productOptionToAdd)
    // On product options that are in local cart we need to ensure we remove
    // existing option when option changed.

    if (itemInLocalCart) {
      const optionChanged = itemInLocalCart.sku_code !== selectedOptionSku
      if (optionChanged) {
        addToCart(currentQuantity, product, selectedOption)
        addToCart(0, product, itemInLocalCart.productOption)
      }
    } else {
      addToCart(currentQuantity, product, selectedOption)
      // we need to store the canonicals for links to other languages of this product
    }
    clearQuantity()
    setNewOrder()
    triggerSentry({
      name: 'addToCart',
      actionPerformed: 'add_to_cart',
      level: Severity.Info,
    })
  }

  /**
   * Set selected optional product option.
   * @param {React.ChangeEvent<HTMLInputElement>} event Option selector change
   *   event
   */
  const handleOptionSelection = (event) => {
    const { target } = event
    const optionSku = target.value
    // console.log('Selected option SKU: ', optionSku)
    setLocalState(
      defaults(
        {
          currentOptionalProductOption: productOptions.find((productOption) => {
            const productOptionSku = lookupProductOptionCurrentMarketSku(productOption)
            return optionSku === productOptionSku
          }),
        },
        localState
      )
    )
  }

  /** @type {OptionalProductOption[]} */
  const optionalProductsOptions = useMemo(() => {
    const digestedProductOptions = productOptions.map((productOption) => {
      const productOptionSku = lookupProductOptionCurrentMarketSku(productOption)
      if (!productOptionSku) {
        return null
      }

      return {
        name: productOption.label,
        value: productOptionSku,
      }
    })

    return filter(digestedProductOptions)
  }, [currentSku])
  // console.log('OptionalProductsOptions: ', optionalProductsOptions)

  /**
   * Try to intercept modal onSubmit.
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const handleSubmit = (e) => {
    // Prevent real submission, the event is only needed to trigger the modal
    // close.
    e.preventDefault()
  }

  const emptyOptions = productOptions.length <= 0
  const sxButtonWhite = useFullWidthButtonSx('white')
  const renderOptionsSelect = !emptyOptions && displayOptionsSelect

  // Non sellable, don't display 'add to cart' UI.
  const unavailableForSale = selectedOption && isEmpty(selectedOption.sku) && !displayOptionsSelect

  // console.log('ProductAddCart: ', {
  //   currentSku,
  //   emptyQuantityOptions,
  //   emptyOptions,
  // })

  const selectedOptionSku = lookupProductOptionCurrentMarketSku(
    localState.currentOptionalProductOption
  )

  const disableAddButton = !(selectedOption || localState.currentOptionalProductOption)
  const shouldDisableAddButton = disableAddButton || emptyQuantityOptions

  const quantitySelection = (
    <QuantitySelect
      existingQuantity={existingQuantity}
      currentQuantity={currentQuantity}
      quantityOptions={quantityOptions}
      handleQuantitySelection={handleQuantitySelection}
    />
  )

  const displayUnavailableForSale = unavailableForSale && (
    <Text
      textContent={t('ecomm:product.notice.unavailableForSale')}
      sx={{
        mb: 5,
      }}
    />
  )

  const displayQuantitySelector = !emptyQuantityOptions && (
    <Grid className={'product-add-cart__select'} item xs={3} md={renderOptionsSelect ? 'auto' : 4}>
      {renderOptionsSelect && (
        <TextField
          onChange={handleOptionSelection}
          select
          value={selectedOptionSku || ''}
          label={'Options'}
          sx={{
            minWidth: '130px',
            maxWidth: {
              sm: '170px',
              md: '250px',
            },
            mr: {
              md: 5,
              sm: 1,
            },
            '.MuiSelect-select': {
              padding: 1.5,
            },
            '& .MuiInputBase-root': {
              background: colors.white,
            },
          }}
        >
          {optionalProductsOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {quantitySelection}
    </Grid>
  )

  const displayAddButton = (
    <Button
      className={`product-add-cart__button ${
        shouldDisableAddButton ? 'product-add-cart__button-disabled' : ''
      }`}
      onClick={shouldDisableAddButton ? noop : handleAddToCart}
      label={buttonLabel}
      // @ts-ignore
      type="submit"
      tooltip={
        shouldDisableAddButton
          ? {
            title: emptyQuantityOptions
              ? t('ecomm:tooltip.maxItemsInCart')
              : t('ecomm:tooltip.selectAnOption'),
            placement: 'top',
          }
          : null
      }
    />
  )

  return {
    handleSubmit,
    renderOptionsSelect,
    sxButtonWhite,
    displayUnavailableForSale,
    displayQuantitySelector,
    displayAddButton,
  }
}

import React from 'react'
import { ImageStyles } from '@ggs/gatsby/components/media'
import Grid from '@mui/material/Grid'
import { Button } from '@ggs/gatsby/components/nav'
import { GridContainer } from '@ggs/components/layout'
import { Title } from '@ggs/components/paragraphs'
// Assets
import './Hero.scss'
import { icons } from '@ggs/components'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

/**
 * Hero Variant
 * @enum {string}
 */
const HeroVariant = {
  default: 'default',
  carousel: 'carousel',
}

/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */

/**
 * @typedef {Object} HeroProps
 * @property {String=} title
 * @property {String=} subtitle
 * @property {LinkField=} link
 * @property {ImageStyles=} heroImage
 * @property {HeroVariant=} variant
 * @property {Boolean=} showCircle
 */

/**
 * Hero component.
 * @param {HeroProps} props
 * @return {JSX.Element}
 */
export default function Hero({
  title = '',
  subtitle = '',
  link,
  heroImage,
  variant = HeroVariant.default,
  showCircle = true,
}) {
  const sx = {}
  switch (variant) {
    case HeroVariant.carousel:
      Object.assign(sx, {
        p: 0,
        position: 'relative',
        maxWidth: 1560,
      })
      break
  }

  return (
    <GridContainer className={`hero hero--${variant}`} sx={sx}>
      <Grid
        container
        className="hero__inner"
        justifyContent={variant === HeroVariant.carousel ? 'space-between' : 'flex-start'}
        alignItems={variant === HeroVariant.carousel ? 'stretch' : 'center'}
        spacing={2}
      >
        <Grid
          className="hero__outer"
          item
          xs={12}
          md={variant === HeroVariant.carousel ? 12 : 5}
          lg={5}
        >
          <div className="hero__content">
            {variant === HeroVariant.carousel && showCircle && (
              <div className="hero__circle-icon">{icons.Circle}</div>
            )}
            <Title title={title} subtitle={subtitle} style={'h1'} component={'h2'} useFlare />
            {link?.url && (
              <div className="hero__link">
                <Button link={link} endIcon={<ChevronRightIcon />} />
              </div>
            )}
          </div>
        </Grid>
        <Grid
          className="hero__image"
          item
          xs={12}
          md={variant === HeroVariant.carousel ? 12 : 7}
          lg={7}
        >
          <ImageStyles
            {...heroImage}
            selectedStyles={HeroVariant.carousel ? ['heroImageLarge'] : ['heroImage']}
          />
        </Grid>
      </Grid>
    </GridContainer>
  )
}

Hero.variant = HeroVariant
import React from 'react'
import { CardGrid } from '@ggs/components/grids'

// Assets.
import './IconGrid.scss'

/**
 * @typedef {import('@ggs/types').InfoIcon} InfoIcon
 */
/**
 * @typedef {Object} IconGridProps
 * @property {String=} title
 * @property {Array<InfoIcon>=} gridItems
 */
/**
 * IconGrid component.
 * @param {IconGridProps} props
 * @return {JSX.Element}
 */
export default function IconGrid({ title, gridItems = [] }) {
  return (
    <div className="icon-grid">
      <CardGrid
        title={title}
        items={gridItems}
        component={CardGrid.component.InfoIcon}
        headingStyle="h3"
      />
    </div>
  )
}
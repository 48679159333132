import React, { useContext, useEffect, useState } from 'react'
import { defer, isFunction } from 'lodash'
import Button from '@mui/material/Button'
import Dialog from './Dialog'
import ModalContext from './ModalContext'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 * @typedef {import('./Dialog')} Dialog
 * @typedef {import('./Dialog').ModalProps} ModalProps
 */

/** @type {SxProps} */
export const formDialogSx = {
  '.MuiPaper-root': {
    // Positioning
    position: 'fixed',
    top: {
      xs: '0',
      md: '10vh',
    },
    // Sizing
    height: {
      xs: '100vh',
      md: '80vh',
    },
    maxHeight: {
      xs: '100vh',
      md: '80vh',
    },
    width: {
      xs: '100vw',
      md: 840,
    },
    maxWidth: {
      xs: '100vw',
      md: 840,
    },
    minWidth: {
      xs: '100vw',
      md: 840,
    },
    // Spacing
    m: 0,
    py: 0,
    pt: 4,
    pb: 0,
    borderRadius: {
      xs: 0,
      md: 10,
    },
    // Inner content
    '.dialog__content': {
      maxHeight: '100%',
      height: '100%',
      '& *': {
        minHeight: '100%',
        maxHeight: '100%',
        height: '100%',
      },
    },
    '.dialog__close': {
      mt: 3,
    },
  },
}

/**
 * @typedef {Object} ModalState
 * @property {function} clearModal
 * @property {function} triggerModal
 * @property {function}  closeModal
 * @property {JSX.Element} modalTrigger,
 * @property {Dialog} modal
 */
/**
 * @param {ModalProps} props
 * @return {ModalState}
 */
export default function useModal({
  onClose,
  onSubmit,
  button,
  closeOnSubmit = true,
  ...modalProps
}) {
  const { activeModal, setActiveModal } = useContext(ModalContext)
  const [/** @type Dialog */ modal, setModal] = useState(null)

  const clearModal = () => {
    setModal()
    setActiveModal()
  }

  const closeModal = () => {
    // console.log('modal closing')
    clearModal()
    isFunction(onClose) && onClose()
  }

  const submitModal = () => {
    if (closeOnSubmit) {
      clearModal()
    }
    isFunction(onSubmit) && onSubmit()
  }

  /**
   *
   * @param {ModalProps=} stateOverrides
   */
  const triggerModal = (stateOverrides) => {
    setModal()
    setActiveModal()
    //const ContentComponent = modalProps.content
    // console.log('content component', ContentComponent)
    defer(() => {
      const m = (
        <Dialog {...modalProps} {...stateOverrides} onSubmit={submitModal} onClose={closeModal}/>
      )
      setModal(m)
      setActiveModal(m)
      defer(() => console.log('triggerModal', { modal, activeModal, m, modalProps }))
    })
  }

  const modalTrigger = <Button onClick={triggerModal}>{button}</Button>

  // Rebuild modal on error
  useEffect(() => {
    if (modalProps.submitError) {
      triggerModal()
    }
  }, [modalProps.submitError])

  return { modal, triggerModal, closeModal, clearModal, modalTrigger }
}

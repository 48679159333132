import { useMemo } from 'react'
import { useLayoutMeta } from '@ggs/hooks'

/**
 * Convert an array of objects to an array of objects localized to the current language.
 * @param {Array<Object>} arr The array to memoize
 * @return {Array<*>} The memoized localized array
 */
export default function useLocalizedList(arr) {
  const { lang } = useLayoutMeta()
  return useMemo(() => {
    // @ts-ignore
    return (arr || []).filter((item) => item?.language?.id === lang)
  }, [arr])
}

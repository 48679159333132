import React from 'react'
import Grid from '@mui/material/Grid'
import { rem } from '@ggs/styles'
import { GridContainer } from '@ggs/components/layout'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { ImpressionClickObserver } from '@ggs/components/ecommerceAnalytics'

// Assets.
import './InfoBlock.scss'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */
/**
 * @typedef {Object} InfoBlockProps
 * @property {String=} summary
 * @property {ImageStyles=} infoBlockImage
 */
/**
 * InfoBlock component.
 * @param {InfoBlockProps} props
 * @return {JSX.Element}
 */
export default function InfoBlock({ summary = '', infoBlockImage }) {
  return (
    <GridContainer className="info-block">
      <ImpressionClickObserver>
        <div
          data-label={'Info Block'}
          data-action-type={'view-info-block'}
          className="info-block__inner"
        >
          <div className="info-block__image">
            <ImageStyles {...infoBlockImage} selectedStyles={['eventSpeaker']} />
          </div>
          <div
            className="info-block__content"
            dangerouslySetInnerHTML={{
              __html: summary,
            }}
          />
        </div>
      </ImpressionClickObserver>
    </GridContainer>
  )
}

import React from 'react'
import Box from '@mui/material/Box'

/**
 * @typedef {import('@ggs/types').Color} Color
 */
/**
 *
 * @param {Color} props
 * @return {JSX.Element}
 */
export default function ProductOptionColor({ color, opacity }) {
  return color ? (
    <Box
      sx={{
        display: 'inline-block',
        content: '" "',
        backgroundColor: color,
        opacity,
        width: 16,
        height: 16,
        borderRadius: '100%',
        mr: 1,
      }}
    />
  ) : null
}

import React, { useEffect } from 'react'
import Alert from '@mui/material/Alert'
import { colors, rem } from '@ggs/styles'
import { Button } from '@ggs/gatsby/components/nav'
import { icons } from '@ggs/components'
import { clearAlert, useDispatch } from '@ggs/store'
import useCodeTranslationMap from '@ggs/components/hooks/useCodeTranslationMap'
import { useI18n } from '@ggs/gatsby/lib'

/**
 * @typedef {import('@ggs/store').AlertMessage} AlertMessage
 * @typedef {import('@mui/material').AlertColor} AlertColor
 */

/**
 * @typedef {Object} SnackbarAlertProps
 * @property {AlertMessage} alert
 * @property {AlertColor} severity
 */

/**
 * Snackbar notification alert item.
 * @param {SnackbarAlertProps} props
 * @return {JSX.Element}
 */
const SnackbarAlert = ({ alert, severity }) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const mapCodeToTranslation = useCodeTranslationMap()
  const autoHideDuration = 6000

  useEffect(() => {
    setTimeout(() => {
      // Auto close after 6 seconds.
      dispatch(clearAlert(alert.id))
    }, autoHideDuration)
  }, [alert.id])

  /**
   * Handle single alert close.
   * @param {React.SyntheticEvent<any>} event User interaction event
   */
  const handleAlertClose = (event) => {
    dispatch(clearAlert(alert.id))
  }

  return (
    <Alert
      key={alert.id}
      severity={severity}
      sx={{
        mt: 2,
        '& .MuiAlert-message': {
          alignSelf: 'center',
          display: 'block',
          width: '85%',
        },
        '& .MuiAlert-action': {
          margin: 'auto',
          padding: 0,
          position: 'absolute',
          right: rem(5),
          top: rem(5),
        },
        '& .MuiSvgIcon-root': {
          fontSize: rem(30),
        },
        '& .MuiIconButton-sizeSmall': {
          padding: 0,
        },
        '& .MuiAlert-standardError': {
          backgroundColor: colors.red,
        },

        '& .MuiAlert-standardSuccess': {
          backgroundColor: colors.lightBlue,
        },
      }}
    >
      {mapCodeToTranslation(alert.code, alert.title)}
      <Button
        className="snackbar__close"
        onClick={handleAlertClose}
        variant={Button.variant.text}
        aria-label={t('global:aria.label.closeThisNotice')}
      >
        {icons.CloseBlue}
      </Button>
    </Alert>
  )
}

export default SnackbarAlert

import React, { useMemo } from 'react'
import { upperFirst } from 'lodash'
import striptags from 'striptags'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { HeadingFlare } from '@ggs/components/flare'
// Assets
import colors from '@ggs/styles/theme/colors'
import { Link } from '@ggs/gatsby/components/nav'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * Heading Styles
 * @enum {string}
 */
export const TitleHeading = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
}

/**
 * @typedef {Object} TitleProps
 * @property {String=} className
 * @property {String=} title
 * @property {String=} subtitle
 * @property {TitleHeading=} style
 * @property {TitleHeading=} component
 * @property {Boolean=} useFlare
 * @property {string=} href
 * @property {JSX.Element=} children
 * @property {SxProps=} sx
 */
/**
 *
 * @param {TitleProps} props
 * @return {JSX.Element}
 */
export default function Title({
  className = '',
  title = '',
  subtitle = '',
  useFlare = false,
  style = 'h2',
  component,
  href = '',
  children,
  ...props
}) {
  // Get a clean HTML title with desired tags
  const cleanTitle = useMemo(
    () => upperFirst(striptags(String(title), ['strong', 'b', 'i', 'em'])),
    [title]
  )

  const displayTitle = (
    <Typography
      // @ts-ignore
      variant={style}
      component={component || /h[1-6]/.test(style) ? style : null}
      dangerouslySetInnerHTML={{
        __html: cleanTitle,
      }}
    />
  )

  return (
    <Box
      className={`title ${className}`}
      {...props}
      sx={{
        color: colors.black,
        ...(props?.sx || {}),
        '.title__subtitle': {
          // @ts-ignore
          ...(props?.sx?.['.title__subtitle'] || {}),
          img: {
            maxWidth: '100%',
          },
        },
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
          alignItems: 'center',
          flex: 'wrap',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {title && (
          <div className="title__title">
            {useFlare && <HeadingFlare />}
            {href ? (
              <Link
                data-label={title}
                data-action-type={'navigate-title-link'}
                data-source={className}
                href={href}
                tabIndex={-1}
                aria-label={`View ${striptags(String(title))}`}
              >
                {displayTitle}
              </Link>
            ) : (
              displayTitle
            )}
          </div>
        )}
        {subtitle && (
          <div className="title__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}
      </Box>
      {children}
    </Box>
  )
}

import { addNotification } from '@ggs/store'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useI18n } from '@ggs/gatsby/lib'

// const { ORDER, PAYMENT } = CHECKOUT_STEP_ENUMS

/**
 * @typedef {import('@ggs/commercelayer').Order} Order
 */

/**
 * @typedef {Object} useWatchForOrderChanges
 * @property {Order=} lastOrder
 * @property {Order=} order
 * @property {boolean=} orderComplete
 */

/**
 * watch for order changes.
 * @return {{
 *     notifyOrderChanges: function,
 *     notifyShippingChanges: function,
 *     notifyPaymentChanges: function
 * }}
 */
export const useWatchForChanges = () => {
  const dispatch = useDispatch()
  const { t } = useI18n()

  /**
   * @param {boolean=} silence
   */
  const notifyOrderChanges = (silence = true) => {
    if (!silence) {
      dispatch(addNotification(
        'order_changes', 'success',
        t('ecomm:label.orderChanges')))
    }
  }
  /**
   * @param {boolean=} silence
   */
  const notifyShippingChanges = (silence = true) => {
    if (!silence) {
      dispatch(addNotification(
        'shipping_changes', 'info',
        t('ecomm:label.shippingChangesReset'))
      )
    }
  }
  /**
   * @param {boolean=} silence
   */
  const notifyPaymentChanges = (silence = false) => {
    if (!silence) {
      dispatch(addNotification(
        'payment_changes', 'info',
        t('ecomm:label.paymentChangesReset')))
    }
  }

  return {
    notifyOrderChanges,
    notifyShippingChanges,
    notifyPaymentChanges
  }
}

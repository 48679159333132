import { useMemo } from 'react'
import { useLink } from '@ggs/gatsby/lib'

/**
 * Get URI for link
 * @param {any} link Link object to generate URI
 * @param {string} languageId Language id to generate URI
 * @return {string} URI string
 */
// eslint-disable-next-line require-jsdoc
function useUri(link, languageId) {
  const safeUrl = useLink(true)

  const processLinkLangCode = ({ link, langCode = 'en-CA' }) => {
    if (link?.url) {
      try {
        const langLinkMap = JSON.parse(link.url)
        return langLinkMap?.[langCode] ?? langLinkMap['en-CA']
      } catch {
        return link?.url
      }
    }
  }

  const { uri } = useMemo(
    () =>
      languageId
        ? safeUrl({ href: processLinkLangCode({ link, languageId }) ?? link?.uri ?? link?.url })
        : safeUrl({ href: link?.uri || link?.url }),
    [link?.url, link?.uri]
  )

  return uri
}

export default useUri
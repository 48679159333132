import { useSelector } from '@ggs/store'
import { useEffect, useState } from 'react'
import { isEqual } from 'lodash'

/**
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */

/**
 * @param {LayoutMeta} pageLayoutMeta
 * @return {LayoutMeta}
 */
export default function useLayoutMeta() {
  const layoutMeta = useSelector((state) => state.ui.layoutMeta)
  const [localMeta, setLocalMeta] = useState(layoutMeta)
  useEffect(() => {
    if (!isEqual(layoutMeta, localMeta)) {
      setLocalMeta(layoutMeta)
      // console.log('_useLayoutMeta', layoutMeta, localMeta)
    }
  }, [layoutMeta])
  return localMeta
}

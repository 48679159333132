import React from 'react'
import { Link } from '@ggs/gatsby/components/nav'
import { GridContainer } from '@ggs/components/layout'
import { SiteNotice } from '@ggs/components/nav'
import { useI18n } from '@ggs/gatsby/lib'
// Assets
import './Header.scss'
import icons from '@ggs/components/icons'
import { colors } from '@ggs/styles'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 * @typedef {import('@ggs/types').Store} Store
 * @typedef {import('@ggs/types').CustomerGroup} CustomerGroup
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */

/**
 * @param {{layoutMeta: LayoutMeta}} props
 * @return {JSX.Element}
 */
export default function CheckoutHeader({ checkoutNotice, checkoutNoticeStyle }) {
  const { t } = useI18n()


  return (

    <header className="header header--checkout">
      <SiteNotice
        label={checkoutNotice}
        variant={checkoutNoticeStyle ?? SiteNotice.variant.belowMenu}
      />
      <div className="header__main">
        <GridContainer
          sx={{ pt: { xs: 2, md: 3 }, pb: { xs: 2, md: 3 } }}
          fullWidthBackground
          backgroundColor={colors.white}
        >
          <div className="header__inner">
            <div className="header__logo">
              <Link href="/">{icons.TrudellLogo}</Link>
            </div>
            <h1 className="header__checkout-title">{t('ecomm:label.checkout')}</h1>
          </div>
        </GridContainer>
      </div>
    </header>
  )
}

import React, { useMemo } from 'react'
import  MuiChip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import { colors, typography } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 * @typedef {Object} Chip
 * @property {String} label
 * @property {'blue'|'pink'=} variant
 */
/**
 *
 * @param {Chip} props
 * @return {JSX.Element}
 */
export default function Chip({ label, variant = 'blue' }) {
  const sx = useMemo(() => {
    /** @type SxProps */
    const style = {
      display: 'inline-block',
      mb: 1,
      mr: 1,
      '&:last-of-type': {
        mr: 0,
      },
      '& .MuiChip-root': {
        padding: '4px',
        borderRadius: '4px',
        backgroundColor: colors.ultraLightBlue,
        height: 'auto',
      },
      '& .MuiChip-label': {
        typography: typography.h6,
        color: colors.blue,
        textTransform: 'uppercase',
        p: 0,
      },
    }

    switch (variant) {
      case 'pink':
        Object.assign(style, {
          '& .MuiChip-root': {
            // @ts-ignore
            ...style['& .MuiChip-root'],
            backgroundColor: colors.ultraLightRed,
          },
          '& .MuiChip-label': {
            // @ts-ignore
            ...style['& .MuiChip-label'],
            color: colors.red,
          },
        })
        break
    }

    return style
  }, [variant])

  // eslint-disable-next-line prefer-rest-params
  // console.log('ArticleMeta render', arguments[0]) //, JSON.stringify(arguments[0]))
  return (
    <Box sx={sx}>
      <MuiChip label={label} />
    </Box>
  )
}

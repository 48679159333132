// @ts-nocheck
import {
  addPaymentSourcePaypal,
  addPaymentSourceStripe,
  extractAvailablePaymentMethods,
  resetOrderPaymentMethod,
  setOrderPaymentMethod,
} from '@ggs/commercelayer'
import { delay, find, get, isEmpty, noop } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  addNotification,
  clearPaypalMetadata,
  setPaypalMetadata,
  updatePaypalStatus,
  useDispatch,
  useSelector,
} from '@ggs/store'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { getSearchParams, getWindow } from '@ggs/utils'
import CHECKOUT_STEP_ENUMS from '../Checkout/enums/CHECKOUT_STEP_ENUMS'
import { useI18n } from '@ggs/gatsby/lib'
import { useSupportingLinks } from '@ggs/components/ecomm'

const { ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS

/**
 * @typedef {import('@ggs/commercelayer').Order} Order
 * @typedef {import('@ggs/commercelayer').PaymentMethod} PaymentMethod
 */

const sanitizeLocationData = () => {
  window && window.history.replaceState(null, null, window.location.pathname)
}

/**
 * @typedef {Object} usePaymentSourceDispatcherProps
 * @property {function=} onSuccess
 * @property {function=} onError
 */

/**
 * @param {usePaymentSourceDispatcherProps} props
 * @return {any}
 */
export const usePaymentSourceDispatcher = ({ onSuccess, onError }) => {
  const origin = getWindow('location.origin')
  const commerce = useSelector((state) => state.commerce)
  const ecommLinks = useSupportingLinks()
  const { t } = useI18n()
  const { token } = commerce
  const dispatch = useDispatch()
  const {
    order,
    handlers: { goToReview: goToNextStep },
    clClient,
    markStepComplete,
  } = useCheckoutContext()
  const { publishable_key, client_secret } = order?.payment_source || {}

  const paymentMethods = extractAvailablePaymentMethods(order)
  const stripePaymentMethod = find(paymentMethods, ['payment_source_type', 'stripe_payments'])
  const paypalPaymentMethod = find(paymentMethods, ['payment_source_type', 'paypal_payments'])
  const selectedPaymentMethod
    = order?.payment_method && order.payment_source ? order.payment_method : stripePaymentMethod

  const defaultPaymentMethodId = useRef(selectedPaymentMethod?.id)

  /**
   * Get CL payment method definition for a given ID.
   * @param {String} paymentMethodId
   * @return {PaymentMethod}
   */
  const getPaymentMethodDefinition = (paymentMethodId) => {
    return paymentMethods.find((paymentMethod) => paymentMethod.id === paymentMethodId)
  }

  const selectedPaymentMethodDefinition = getPaymentMethodDefinition(selectedPaymentMethod?.id)

  const isStripePaymentMethod = useMemo(
    () => selectedPaymentMethodDefinition?.payment_source_type === 'stripe_payments',
    [selectedPaymentMethod?.id, selectedPaymentMethodDefinition?.payment_source_type]
  )
  /**
   * Show payment method is required warning, raised when unexpected CL payment
   * source response occurs.
   */
  const showPaymentMethodRequiredNotice = () => {
    dispatch(
      addNotification(
        'payment_method_required',
        'error',
        t('ecomm:notice.selectPaymentMethodRequired')
      )
    )
  }

  const handlePaypalMethodError = () => {
    dispatch(clearPaypalMetadata())
    if (shouldRedirectToPaypal) {
      dispatch(
        addNotification(
          'payment_method_required',
          'error',
          t('ecomm:notice.errorConfiguringPaypal')
        )
      )
    }
    setShouldRedirectToPaypal(false)
  }

  const continueToReview = () => {
    if (!order?.payment_source) {
      showPaymentMethodRequiredNotice()
      resetInitDefaultPaymentMethod()
    } else {
      markStepComplete(PAYMENT)
      onSuccess()
    }
  }

  /**
   * Prepare CL payment source that is needed for Payment Gateway provider form processing.
   * @param {PaymentMethod} currentPaymentMethodDefinition
   * @param {function=} onSuccess
   */
  const preparePaymentSource = (currentPaymentMethodDefinition, onSuccess) => {
    // Determine that payment source is waiting payment method details input from customer
    const paymentSourceMethodId = get(order, 'payment_source.payment_method.id', null)
    const needPaymentSourceDetails = isEmpty(paymentSourceMethodId)
    if (
      currentPaymentMethodDefinition?.payment_source_type === 'stripe_payments'
      && needPaymentSourceDetails
    ) {
      dispatch(clearPaypalMetadata())
      dispatch(
        addPaymentSourceStripe({
          clClient,
          order,
          paymentSource: get(order, 'payment_source.id', null),
          callbacks: {
            onSuccess: () => {
              onSuccess()
            },
            onError: () => {
              // Sometimes the payment intent is corrupted so better to reset to force new one.
              resetInitDefaultPaymentMethod()
            },
          },
        })
      )
    } else if (
      currentPaymentMethodDefinition?.payment_source_type === 'paypal_payments'
      && order?.payment_source
    ) {
      // dispatch(setPaypalMetadata({
      //   PayerID: null,
      //   responseCode: null,
      //   paypalStatus: 'INIT_EXT_PAYPAL'
      // }))
      dispatch(
        addPaymentSourcePaypal({
          clClient,
          order,
          paymentSource: {
            order,
            return_url: `${origin}${ecommLinks.checkout}?responseCode=extsuccess`,
            cancel_url: `${origin}${ecommLinks.checkout}?responseCode=extfailure`,
          },
          callbacks: {
            onSuccess: (e) => {
              dispatch(
                setPaypalMetadata({
                  PayerID: null,
                  responseCode: null,
                  paypalStatus: 'INIT_EXT_PAYPAL',
                })
              )
              // this fires right before redirecting to paypal, wait for #extsuccess to fire onsuccess, advance checkout
            },
            onError: () => {
              // Sometimes the payment intent is corrupted so better to reset to force new one.
              resetInitDefaultPaymentMethod()
              handlePaypalMethodError()
            },
          },
        })
      )
    } else {
      resetInitDefaultPaymentMethod()
    }
  }

  // Tolerate CL payment initialization failures, when not loading retry up to 10 times.
  const maxAttempts = 5
  const attemptDelay = 1000
  const [retryAttempts, setRetryAttempts] = useState(0)

  /**
   *
   * @param {string} paymentMethodId
   * @param {function=} onSuccess
   */
  const dispatchOrderPaymentMethodSet = (paymentMethodId, onSuccess = noop) => {
    dispatch(
      // Use the selected ID, either defaults to initial value or the one provided.
      setOrderPaymentMethod({
        clClient,
        order,
        paymentMethodId,
        callbacks: {
          onSuccess: () => {
            preparePaymentSource(getPaymentMethodDefinition(paymentMethodId), onSuccess)
          },
          onError: (e) => {
            console.log('order payment method set', e)
            handlePaypalMethodError()
            setRetryAttempts((currentRetryAttempts) => {
              // retry for stripe, not paypal
              if (shouldRedirectToPaypal) {
                return currentRetryAttempts
              }
              if (currentRetryAttempts <= maxAttempts && !shouldRedirectToPaypal) {
                delay(() => {
                  dispatchOrderPaymentMethodSet(paymentMethodId, onSuccess)
                }, attemptDelay * retryAttempts)
              }
              return currentRetryAttempts + 1
            })
            // Something went wrong, retry payment preparation.
          },
        },
      })
    )
  }

  /**
   * Check if a given payment method ID is same as order selected payment method.
   * @param {string} paymentMethodId
   * @return {boolean}
   */
  const isCurrentOrderPaymentMethod = (paymentMethodId) => {
    return order?.payment_method?.id === paymentMethodId
  }

  /**
   * @param {String=} selectedPaymentMethodId
   * @param {function=} onSuccess
   * Display Payment Gateway specific form elements when payment method option
   *   is selected.
   */
  const handlePaymentSourceFormState = (selectedPaymentMethodId = null, onSuccess = noop) => {
    // Payment method not changed, nothing to do.
    if (isCurrentOrderPaymentMethod(selectedPaymentMethodId)) {
      setShouldRedirectToPaypal(false)
      return
    }

    dispatch(
      resetOrderPaymentMethod({
        clClient,
        order,
        callbacks: {
          onSuccess: (action) => {
            // After successful reset, mark as default method for initialization effect.
            defaultPaymentMethodId.current = selectedPaymentMethodId
            dispatchOrderPaymentMethodSet(selectedPaymentMethodId, onSuccess)
          },
          onError: () => {
            handlePaypalMethodError()
          },
        },
      })
    )
  }

  /**
   * Pre-select a default payment method when no one is selected.
   */
  const initDefaultPaymentMethod = () => {
    if (order.payment_method === null && defaultPaymentMethodId.current) {
      dispatchOrderPaymentMethodSet(defaultPaymentMethodId.current)
      // For next change, default again to selected payment method
      defaultPaymentMethodId.current = selectedPaymentMethod
    }
  }

  const resetInitDefaultPaymentMethod = () => {
    dispatch(
      resetOrderPaymentMethod({
        clClient,
        order,
        callbacks: {
          onSuccess: (action) => {
            // After successful reset, mark as default method for initialization effect.
            defaultPaymentMethodId.current = stripePaymentMethod.id
            dispatchOrderPaymentMethodSet(stripePaymentMethod.id, onSuccess)
          },
          onError: () => {
            handlePaypalMethodError()
          },
        },
      })
    )
  }

  // Track and handle when the user should be sent to paypal.
  const [shouldRedirectToPaypal, setShouldRedirectToPaypal] = useState(false)

  const purchaseWithStripe = () => {
    handlePaymentSourceFormState(stripePaymentMethod.id)
  }

  const purchaseWithPaypal = useCallback(
    (e) => {
      dispatch(clearPaypalMetadata())
      setShouldRedirectToPaypal(true)
      handlePaymentSourceFormState(paypalPaymentMethod.id)
    },
    [paypalPaymentMethod, token, order]
  )

  // get from redux
  const checkForPaypalMetaParams = (qs) => {
    if (qs) {
      if (qs?.get('responseCode')) {
        return {
          // ...paypalMeta,
          PayerID: qs?.get('PayerID'),
          responseCode: qs?.get('responseCode'),
          paypalStatus: 'EXT_RETURN',
        }
      }
    } else {
      return null
    }
  }

  const paypalMeta = useSelector((state) => state.checkout.paypalMeta)

  // On Page load, and when paypal status changes, attempt to pick up success check in uri params
  useEffect(() => {
    if (paypalMeta.paypalStatus === 'INIT_EXT_PAYPAL') {
      const qs = getSearchParams()
      const paypalParams = checkForPaypalMetaParams(qs)
      if (
        paypalParams
        && paypalParams.paypalStatus === 'EXT_RETURN'
        && paypalParams.responseCode === 'extsuccess'
        && paypalParams.PayerID
      ) {
        dispatch(setPaypalMetadata(paypalParams))
        // we have captured the payment ID, remove the token from search params and browser history
        sanitizeLocationData()
        dispatch(updatePaypalStatus('EXT_SUCCESS_CLEAN'))
        continueToReview()
      } else if (
        paypalParams
        && paypalParams.responseCode
        && paypalParams.responseCode === 'extfailure'
      ) {
        delay(() => {
          dispatch(clearPaypalMetadata())
          resetInitDefaultPaymentMethod()
          // sanitizeLocationData()
        }, 0)
      }
    }
    sanitizeLocationData()
  }, [paypalMeta])

  // Track and handle when the user should be sent to paypal.
  useEffect(() => {
    // At the earlier possible moment, check if we can redirect to paypal.
    if (shouldRedirectToPaypal && order?.payment_source?.approval_url) {
      window.location.href = order.payment_source.approval_url
    }
  }, [shouldRedirectToPaypal, order?.payment_source?.approval_url])

  return {
    paymentMethods,
    initDefaultPaymentMethod,
    resetInitDefaultPaymentMethod,
    purchaseWithStripe,
    purchaseWithPaypal,
    shouldRedirectToPaypal,
    isStripePaymentMethod,
    continueToReview,
    goToNextStep,
    markStepComplete,
    publishable_key,
    client_secret,
  }
}

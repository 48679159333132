import React from 'react'
import { ImageStyles } from '@ggs/gatsby/components/media'

// Assets.
import './InfoIcon.scss'

/**
* @typedef {import('@ggs/types').ImageStyles} ImageStyles
*/
/**
 * @typedef {Object} InfoIconProps
 * @property {String=} label
 * @property {ImageStyles=} icon
 */
/**
 * InfoIcon component.
 * @param {InfoIconProps} props
 * @return {JSX.Element}
 */
export default function InfoIcon({ label, icon }) {
  return (
    <div className="info-icon">
      <div className="info-icon__image">
        <div className="info-icon__image-inner">
          <ImageStyles
            {...icon}
            selectedStyles={['icon']}
          />
        </div>
      </div>
      <div className="info-icon__label">{label}</div>
    </div>
  )
}
import React from 'react'
import dayjs from 'dayjs'
import useEventElements from './useEventElements'
import Grid from '@mui/material/Grid'
import { GridContainer } from '@ggs/components/layout'
import { Link } from '@ggs/gatsby/components/nav'

// Assets
import './CarouselItem.scss'
import { ImageStyles } from '@ggs/gatsby/components/media'

/**
 * @typedef {import('@ggs/types').Event} Event
 */
/**
 * @param {Event} event
 * @return {JSX.Element}
 */
export default function EventCarouselItem(event) {
  const { title = '', eventImage = null, location = '', url } = event
  const { categories, eventActions, publishDate, eventDateFormat } = useEventElements(event)

  return (
    <GridContainer
      className="carousel-item"
      sx={{
        pt: { xs: 0, md: 4 },
        pb: { xs: 3, md: 5 },
        'span.MuiButton-endIcon > svg': {
          transform: 'none !important',
        },
      }}
    >
      <>
        <Grid
          className="carousel-item__inner"
          container
          justifyContent="flex-start"
          alignItems="stretch"
        >
          {eventImage && (
            <Grid className="carousel-item__image" item xs={12} md={12} lg={7}>
              <ImageStyles {...eventImage} selectedStyles={['pageHeroFeatured']} />
            </Grid>
          )}
          <Grid
            className={`${
              eventImage
                ? 'carousel-item__outer'
                : 'carousel-item__outer carousel-item__outer--full'
            }`}
            item
            xs={12}
            md={12}
            lg={5}
          >
            <div className="carousel-item__content">
              {categories && <div className="carousel-item__category">{categories}</div>}
              <div className="carousel-item__date-and-location">
                <span className="carousel-item__date">{dayjs(publishDate).format(eventDateFormat)}</span>
                <span className="carousel-item__location">{location}</span>
              </div>
              <h2 className="carousel-item__title">
                <Link href={url}>{title}</Link>
              </h2>
              {eventActions && <div className="carousel-item__actions">{eventActions}</div>}
            </div>
          </Grid>
        </Grid>
      </>
    </GridContainer>
  )
}

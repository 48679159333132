// @ts-nocheck
import { AddressView, ContactInformationReview, ReviewTitle } from '@ggs/components/ecomm'
import Box from '@mui/material/Box'
import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
/**
 * @typedef {('checkout'|'complete')} ShippingAddressReviewVariants
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} ShippingAddressReviewProps
 * @property {ShippingAddressReviewVariants=} variant
 * @property {function=} editOrder
 * @property {boolean=} contact
 * @property {Order} order
 */

/**
 * Checkout shipping address review.
 * @param {ShippingAddressReviewProps} props
 * @return {JSX.Element}
 */
const ShippingAddressReview = ({ variant = 'checkout', order, contact = true, editOrder }) => {
  const { t } = useI18n()

  const { shipping_address: address } = order

  if (!address) {
    return null
  }

  const titleVariant = variant === 'checkout' ? 'h3' : 'body1'
  const buttonLabel = variant === 'checkout' ? t('account:button.edit') : undefined
  return (
    <Box className={'shipping-address-review'}>
      <ReviewTitle
        title={`${t('account:label.shippingInformation')}`}
        titleVariant={titleVariant}
        buttonLabel={buttonLabel}
        goToEdit={editOrder}
      />
      <AddressView address={address} />
      {contact ? (
        <Box
          sx={{
            mt: 5,
            mb: 5,
          }}
        >
          <ReviewTitle
            title={`${t('account:label.contactInformation')}`}
            titleVariant={titleVariant}
          />
          <ContactInformationReview {...order.metadata} {...address} />
        </Box>
      ) : null}
    </Box>
  )
}

export default ShippingAddressReview

import React from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import muiTheme from './muiTheme'

// console.log('muiTheme', muiTheme)

/**
 * @typedef {Object} MuiThemeProviderProps
 * @property {Element|JSX.Element} children
 */
/**
 *
 * @param {MuiThemeProviderProps} props
 * @return {JSX.Element}
 */
export default function MuiThemeProvider({ children }) {
  return (
    <StyledEngineProvider>
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  )
}

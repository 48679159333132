import {
  BillingAddressReview,
  CartTotals,
  MiniCartItemsList,
  OrderStatus,
  ShippingAddressReview,
} from '@ggs/components/ecomm'
import { useI18n } from '@ggs/gatsby/lib'
import { colors, common } from '@ggs/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import React from 'react'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} CheckoutPlacedProps
 * @property {Order} order
 * @property {boolean} complete
 */

/**
 * Checkout placed message.
 * @param {CheckoutPlacedProps} last order placed
 * @return {JSX.Element}
 */
const OrderPlaced = ({ order, complete = false }) => {
  const { t } = useI18n()

  if (!order) {
    return null
  }

  const containerSx = {
    padding: 4,
    mb: 3,
    borderBottom: common.borderLightBlue,
  }

  const orderDetailsSx = {
    ...containerSx,
    border: 0,
    width: {
      // Accommodate for extra spacing needed on FR content.
      aboveLargeMobile: 'calc(100% + 80px)',
    },
    '& > .MuiGrid-item': {
      flex: {
        xs: '0 0 100%',
        md: '0 0 50%',
        aboveLargeMobile: '0 0 33%',
      },
    },
    '.order-placed-cart-totals': {
      flex: {
        xs: '0 0 100%',
        md: '0 0 100%',
        aboveLargeMobile: '0 0 33%',
      },
    },
    '.cart-totals': {
      maxWidth: 256,
      mt: {
        xs: 4,
        md: 0,
      },
      ml: {
        xs: 0,
        md: 'calc(50% + 16px)',
        aboveLargeMobile: 0,
      },
    },
    '.shipping-address-review > div:last-child, .contact-information-review > div:last-child': {
      mb: 0,
    },
    '.contact-information-review-label': {
      display: 'block',
      fontSize: '0.9rem',
      // fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  }


  return (
    <Box
      sx={{
        backgroundColor: colors.white,
        borderRadius: '4px',
        boxShadow: '0 0 16px rgba(0, 0, 0, .04)',
        padding: 4,
        '& .MuiFilledInput-root': {
          backgroundColor: colors.white,
        },
        '& .MuiButton-root': {
          mt: 2,
          mb: 5,
        },
      }}
    >
      <Grid className={'order-placed-number-and-date'} container sx={containerSx}>
        <Grid item xs={12} md={7}>
          <Typography variant={'h4'}>
            {t('ecomm:label.orderNumber')} {order.number}
          </Typography>
          {order.skus_count ? (
          // complete
          //   ? (
          //     <Typography>
          //       {order.skus_count === 1
          //         // For pluralization, we must determine if we need a
          // label // for a single item, or, items. ?
          // t('ecomm:label.orderItem', { count: order.skus_count }) :
          // t('ecomm:label.orderItems', { count: order.skus_count })} :{'
          // '}{order.skus_count} </Typography> ) : (
            <Typography>

              {// For pluralization, we must determine if we need a label
                order.skus_count === 1
                  ? t('ecomm:cart.label.itemsInCart', { count: 1 }) // for a single item, or, items.
                  : t('ecomm:cart.label.itemsInCart', { count: order.skus_count })}
            </Typography>
          ) // )
            : null}
        </Grid>
        <Grid item xs={12} md={5} textAlign={{ md: 'right' }}>
          <Typography variant={'h5'}>
            {t('ecomm:label.orderPlaced')}:{' '}
            {dayjs(order.placed_at || order.updated_at).format('ll')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        className={'order-placed-order-details'}
        container
        sx={orderDetailsSx}
        spacing={{
          xs: 2,
          md: 4,
          aboveLargeMobile: 8,
        }}
      >
        <Grid item>
          <ShippingAddressReview order={order} variant={'complete'}/>
        </Grid>
        <Grid item>
          <BillingAddressReview order={order} variant={'complete'}/>
        </Grid>
        <Grid className={'order-placed-cart-totals'} item>
          <CartTotals order={order} variant={'complete'}/>
        </Grid>
      </Grid>
      <Grid
        className={'order-placed-order-items'}
        container
        sx={{
          borderTop: common.borderLightGrey,
          pt: 3,
          ml: 3,
          width: 'calc(100% - 24px)',
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant={'h4'}
            sx={{
              mb: 3,
            }}
          >
            {t('ecomm:label.orderItems')}
          </Typography>
          <MiniCartItemsList order={order}/>
        </Grid>
      </Grid>
      <Grid
        className={'order-placed-shipping-status'}
        container
        sx={{
          ...containerSx,
          pt: 0,
        }}
      >
        <Grid item xs={12}>
          <OrderStatus order={order}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OrderPlaced

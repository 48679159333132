import React from 'react'

// Assets.
import './HalfWidthFlare.scss'

/**
 *
 * @return {JSX.Element}
 */
export default function HalfWidthFlare() {
  return (
    <div className="half-width-flare">
      <div className="half-width-flare__line"/>
      <div className="half-width-flare__ball" />
    </div>
  )
}

// @ts-nocheck
import React, { useRef, useState } from 'react'
import { compact, defer, find } from 'lodash'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { Button } from '@ggs/gatsby/components/nav'
import { getGlobals, useLink } from '@ggs/gatsby/lib'
// Assets
import { colors, typography } from '@ggs/styles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Grid from '@mui/material/Grid'
import { useLayoutMeta } from '@ggs/hooks'
import { setCurrentLanguage, setLayoutMeta } from '@ggs/store'
import { navigate } from 'gatsby'
import { useIsLanguageAllowed } from '@ggs/components/ecomm/ProductPurchaseOptions/useCurrentMetadata'

const { LANGUAGES } = getGlobals()

/**
 * @type {Languages}
 */
export const languages = Array.isArray(LANGUAGES)
  ? LANGUAGES
  : []


// check if we are on a product page
export const storeLanguagePath = ({ pathname }) => {
  const pathArray = pathname.split('/').filter(function (v) {
    return v
  })
  if (!pathArray.length) return
  const [store, langCode, ...rest] = pathArray
  if (rest.length > 1) {
    // there is more than one level of nesting in the URI.  likely a webseminar, but there will be other cases.
  }
  return {
    store,
    langCode,
    path: rest
  }
}

export const useGenerateLanguage = () => {
  const safeUrl = useLink(false, false)
  const dispatch = useDispatch()
  const layoutMeta = useLayoutMeta()
  const { lang: currentLangCode, canonicalUrls } = layoutMeta
  const isLanguageAllowed = useIsLanguageAllowed()

  const {
    menus: { supporting = {} } = {},
    currentStore = { storeAlias: '' },
    lang,
  } = layoutMeta || {}


  const PRODUCT_LISTING_ALIAS = layoutMeta.listing?.productListingAlias?.uri
    ?? layoutMeta?.menus?.supporting?.orderProcessing?.productListingAlias?.uri
  const url = (langCode) => safeUrl({ href: targetPage(langCode) })?.uri
  const { productListingAlias } = useAliases()

  const isNotCurrentLanguage = (langCode) => langCode !== currentLangCode
  const targetPage = (langCode) => find(canonicalUrls, ['lang', langCode])?.url
  const shouldDisplay = (langCode) => {
    return isLanguageAllowed(langCode).length && isNotCurrentLanguage(langCode) && targetPage(langCode)
  }

  // useEffect(() => {
  //   // Finally, after a short delay, we can navigate to the new language page.
  //   navigate(url(currentLangCode))
  // }, [currentLangCode])

  const selectLanguage = ({ langCode }) => {
    // If the language is the same, do nothing.
    const language = getLanguageFromLangCode(langCode)
    // alert(JSON.stringify(language))
    // if (shouldDisplay(langCode)) {
    // Set new language in state
    // Now, to prepare moving between a different language, we need to also pre-emptively update layoutMeta in redux.
    dispatch(setLayoutMeta({
      ...layoutMeta,
      lang: language,
    }))
    dispatch(setCurrentLanguage(language))

    // addNotification(settingsActionType.SET_CURRENT_LANGUAGE, 'success', 'Language changed')
    defer(() => navigate(url(langCode)))
    // }
  }

  return { url, targetPage, selectLanguage, shouldDisplay }
}

/**
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 * @typedef {import('@ggs/types').LanguageDefinition} LanguageDefinition
 * @typedef {import('@ggs/types').Languages} Languages
 */

const useGenerateLanguageMenuItem = ({
  closeMenu,
}) => {
  const { url, selectLanguage, shouldDisplay } = useGenerateLanguage()
  return ({
    langCode,
    name,
  }) => {
    const menuData = {
      key: langCode,
      name,
      onClick: async (event) => {
        event.preventDefault()
        selectLanguage({ langCode })
        closeMenu()
      },
      'data-lng': langCode,
      href: url(langCode),
    }

    return shouldDisplay(langCode)
      ? menuData
      : null
  }
}

const getLanguageFromLangCode = (langCode) => {
  /**
   * Filter full language object of current language.
   * @type {LanguageDefinition}
   */
  // @ts-ignore
  return languages.find(({ lc }) => {
    return lc === langCode
  }) || {}
}

const getLanguages = () => languages.map(({ name, langCode, weight }) => ({
  langCode,
  name,
  weight,
}))

export const getLanguageFromId = (langCode) => {
  return getLanguages().filter((l) => l.langCode === langCode)[0]
}

const useLanguageOptions = ({ closeMenu }) => {
  const layoutMeta = useLayoutMeta()
  const { lang: currentLangCode } = layoutMeta

  /**
   * Filter full language object of current language.
   * @type {LanguageDefinition}
   */
  // @ts-ignore
  const currentLanguage = getLanguageFromId(currentLangCode)

  const generateLanguageMenuItem = useGenerateLanguageMenuItem({
    closeMenu,
  })

  const languageSelectOptions = compact(
    languages.map(({ name, langCode, weight }) =>
      generateLanguageMenuItem(getLanguageFromId(langCode))),
  )

  const hasLanguages = languageSelectOptions.length

  return { activeLanguageName: currentLanguage?.name, generateLanguageMenuItem, languageSelectOptions, hasLanguages }
}

const useAliases = () => {
  const layoutMeta = useLayoutMeta()
  if (!layoutMeta?.menus?.supporting?.orderProcessing?.productListingAlias) {
    console.log('layoutMeta', layoutMeta)
    return {
      productListingAlias: 'products',
      cartAlias: 'cart',
      checkoutAlias: 'checkout'
    }
  }

  const {
    menus: {
      supporting: {
        orderProcessing: {
          productListingAlias: {
            uri: productListingAlias
          },
          cartAlias: {
            uri: cartAlias,
          },
          checkoutAlias: {
            uri: checkoutAlias,
          },
        },
        // accountPages: {
        //   accountAlias: 'internal:/account',
        // },
      },
    },
  } = layoutMeta

  return {
    productListingAlias,
    cartAlias,
    checkoutAlias
  }
}

/**
 * @return {JSX.Element}
 */
export default function LanguageSelector() {
  const ref = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  // @ts-ignore
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    if (anchorEl !== null) {
      setAnchorEl(null)
    }
  }

  const { activeLanguageName, hasLanguages, languageSelectOptions } = useLanguageOptions({ closeMenu })

  return (
    <Grid ref={ref} item xs="auto">
      <Box
        className="language-selector"
        sx={{
          ml: 3,
          '.button': {
            pl: 0,
            pr: {
              xs: 2,
              md: 5,
            },
            '&[disabled]': {
              cursor: 'default',
              fill: `${colors.white} !important`,
              opacity: 1,
            },
            span: {
              color: `${colors.white} !important`,
              typography: typography.menuItemSmall,
            },
            svg: {
              fill: `${colors.white} !important`,
            },
          },
        }}
      >
        <>
          {/*<div>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={openMenu}
            >
              Dashboard
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={closeMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={closeMenu}>Profile</MenuItem>
              <MenuItem onClick={closeMenu}>My account</MenuItem>
              <MenuItem onClick={closeMenu}>Logout</MenuItem>
            </Menu>
          </div>*/}
          <Button
            id="language-selector__button"
            className="language-selector__button"
            aria-controls={open ? 'languages-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={open ? closeMenu : openMenu}
            variant={'text'}
            endIcon={hasLanguages ? open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/> : <>&nbsp;</>}
            disabled={!hasLanguages}
          >
            {activeLanguageName}
          </Button>
          <Menu
            id="languages-menu"
            className="language-selector__menu"
            // keepMounted={true}
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'language-selector__button',
            }}
            sx={{
              '.MuiList-root': {
                p: 0,
              },
              '> .MuiMenu-paper': {
                borderRadius: '0 0 4px 4px',
                boxShadow: '0 16px 16px rgba(0, 0, 0, .16)',
              },
            }}
          >
            {languageSelectOptions.map(({ key, name, ...item }) => (
              <MenuItem key={key} {...item}>
                {name}
              </MenuItem>
            ))}
            {/*<MenuItem onClick={closeMenu}>Profile</MenuItem>*/}
            {/*<MenuItem onClick={closeMenu}>My account</MenuItem>*/}
            {/*<MenuItem onClick={closeMenu}>Logout</MenuItem>*/}
          </Menu>
        </>
      </Box>
    </Grid>
  )
}

LanguageSelector.languages = languages

import { isFunction } from 'lodash'

/**
 * Use a data layer with event handlers
 * The dataLayer object uses an event command to initiate the sending of events.
 *
 * The Google tag and Tag Manager use a special data layer variable called event that is used by JavaScript event
 * listeners to fire tags when a user interacts with website elements. For example, you may want to fire a conversion
 * tracking tag when a user clicks a purchase confirmation button. Events may be called whenever a user interacts with
 * website elements such as links, buttons, scrolls, etc.
 *
 * This functionality is accomplished by calling dataLayer.push() when an event occurs. The syntax for sending an event
 * with dataLayer.push() is as follows:
 *
 *
 * dataLayer.push({'event': 'event_name'});
 * Where event_name is a string that describes the event, such as 'login', purchase, or search.
 *
 * Use dataLayer.push() to send event data when an action occurs that you'd like to measure. For example, to send an
 * event when a user clicks a button, modify the button's onclick handler to call dataLayer.push():
 *
 *
 * <button onclick="dataLayer.push({'event': 'login'});">Button 1</button>
 * You can push data layer variables to the data layer dynamically to capture information such as values entered or
 * selected in a form, metadata associated with a video that the visitor is playing, the color of a product
 * (e.g. a car) customized by the visitor, the destination URLs of clicked links, etc.
 *
 * As with events, this functionality is accomplished by calling the push() API to add or replace data layer variables
 * in the data layer. The basic syntax for setting dynamic data layer variables is as follows:
 *
 *
 * dataLayer.push({'variable_name': 'variable_value'});
 * Where 'variable_name' is a string indicating the name of the data layer variable to be set, and 'variable_value'
 * is a string indicating the value of the data layer variable to be set or replaced.
 *
 * For example: To set a data layer variable with a color preference when a visitor engages with a product
 * customization tool, you might push the following dynamic data layer variable:
 *
 *
 * dataLayer.push({'color': 'red'});
 * One push, multiple variables
 * You can push multiple variables and events at once:
 *
 *
 * dataLayer.push({
 *   'color': 'red',
 *   'conversionValue': 50,
 *   'event': 'customize'
 * });
 * from https://developers.google.com/tag-platform/tag-manager/web/datalayer#use_a_data_layer_with_event_handlers
 * @param eventName
 * @param dataLayer
 */
export const pushDataLayer = ({ eventName = '', dataLayer }) => {
  try {
    if (isFunction(window.gtag)) {
      // console.log(`Invoked: window.gtag for ${eventName}`)
      window.gtag('event', eventName, {
        ...dataLayer,
        // Sometimes debug mode enables is not enough for GA4 DebugView, there are some knwon issues:
        // https://support.google.com/analytics/thread/184895880/google-tag-manager-events-not-showing-in-ga4?hl=en
        // In order to reduce this problems ensure to have installed the extension:
        // https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna
        debug_mode: true,
      })
    }
  } catch (error) {
    console.log(error)
  }
}

import { useMemo } from 'react'
// import { breakpoints, colors, typography } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 * @typedef {import('@ggs/types').ComponentProps} ComponentProps
 * @typedef {import('@ggs/types').SettingsProps} SettingsProps
 */

/**
 * @typedef {Object} StackedContentProps
 * @property {Number=} maxWidth
 * @property {Number=} spacing
 * @property {Array<ComponentProps>=} content
 */
/**
 * @param {StackedContentProps} props
 * @return {SxProps}
 */
export default function useStackedContentSx(props) {
  const { maxWidth } = props

  return useMemo(() => {
    const sx = {
      '.grid-container__inner': {
        m: 0,
        p: 0,
        '> :first-of-type': {
          pt: 0,
          mt: 0,
        },
        '> :last-of-type': {
          pb: 0,
          mb: 0,
        },
      },
      // Overrides to tidy up how Image CTA is displayed.
      '.image-cta .grid-container__inner': {
        py: {
          xs: 2,
          md: 5,
        },
        px: {
          xs: 2,
          md: 5,
        },
      },
      // Cleaning up handling for the FAQs
      '.faq-category': {
        p: 0,
        m: 0,
        mb: 3,
        boxShadow: 'none',
        '> div': {
          m: 0,
        },
      },
    }

    if (maxWidth) {
      Object.assign(sx, {
        maxWidth,
        m: {
          lg: '0 auto',
        },
      })
    }

    return sx
  }, [props])
}

import React from 'react'
import { Button } from '@ggs/gatsby/components/nav'
import { GridContainer } from '@ggs/components/layout'
import Grid from '@mui/material/Grid'
import { Title } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { useModal } from '@ggs/components/common'
// Assets
import './Reimbursement.scss'
import { colors, rem } from '@ggs/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import icons from '@ggs/components/icons'
import { useSelector } from '@ggs/store'

/**
 * @typedef {Object} ReimbursementProps
 * @property {String=} title
 * @property {String=} subtitle
 * @property {Array<{title: String, textContent: String}>} modal
 */
/**
 *
 * @param {ReimbursementProps} props
 * @return {JSX.Element}
 */
export default function Reimbursement({ title = '', subtitle = '', modal }) {
  const { t } = useI18n()
  const settings = useSelector((state) => state.settings)
  const modalTitle = modal?.[0]?.title
  const modalContent = modal?.[0]?.textContent

  const { modal: renderedModal, triggerModal } = useModal({
    title: modalTitle && (
      <Title className="reimbursement__modal-title" title={modalTitle} useFlare style="h2" />
    ),
    content: <div dangerouslySetInnerHTML={{ __html: modalContent }} />,
  })
  // eslint-disable-next-line prefer-rest-params
  // console.log('Reimbursement render', arguments[0]) //, JSON.stringify(arguments[0]))

  // Don't show if not configured
  if (!title || !modalContent) {
    return null
  }

  // whether or not we want to see dollar signs all the time
  const dollarSigns = String(settings?.currentMarket?.marketId) !== String(10331)

  return (
    <GridContainer
      className="reimbursement"
      backgroundColor={colors.blue}
      sx={{
        py: {
          md: 3,
        },
      }}
    >
      <>
        <Grid
          className="reimbursement__inner"
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid
            className="reimbursement__icon"
            item
            xs={12}
            alignItems="center"
            textAlign="center"
            sx={{
              flexBasis: {
                lg: `${rem(42)} !important`,
              },
              // px: 2,
            }}
          >
            {dollarSigns ? icons.ReimbursementIcon : null}
          </Grid>
          <Grid
            className="reimbursement__content"
            item
            xs={12}
            flex={{
              lg: '0 1 auto',
            }}
            maxWidth={{
              lg: '60%',
            }}
            textAlign={{
              xs: 'center',
              lg: 'inherit',
            }}
          >
            <Title title={title} subtitle={subtitle} style="h3" />
          </Grid>
          <Grid
            className="reimbursement__link"
            item
            xs={12}
            lg={3}
            textAlign={{
              xs: 'center',
              lg: 'right',
            }}
            marginLeft={'auto'}
          >
            <Button
              onClick={triggerModal}
              endIcon={<ChevronRightIcon />}
              variant={Button.variant.outlined}
              data-label={title}
              data-action-type="open-reimbursement-modal"
            >
              {t('ecomm:product.button.findOutCoverage')}
            </Button>
          </Grid>
        </Grid>
        {renderedModal}
      </>
    </GridContainer>
  )
}

import { applyMiddleware, createStore } from 'redux'
import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'
import thunk from 'redux-thunk'
import { useSelector as useSelectorBase } from 'react-redux'
import { viewsActionType } from './actions/views'
import rootReducer from './reducers/rootReducer'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { APP_STORE_KEY, initialState } from './initialState'
import { defaults, values } from 'lodash'
import { createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync'
import { commerceActionType } from './actions/commerce'
import { authActionTypes, customerActionType, orderActionType, skuActionTypes } from '@ggs/commercelayer'
import { commonActionType } from '@ggs/commercelayer/actions/CommonActions'
import { checkoutActionsType } from './actions/checkout'
import { composeWithDevTools } from 'redux-devtools-extension'
import { analyticsActionsType } from './actions/analytics'
/**
 * @typedef {import("redux").Action} Action
 * @typedef {import("redux").Middleware} Middleware
 * @typedef {import("redux-thunk").ThunkAction<void, Function, unknown, Action>}
 *   AppThunk
 * @typedef {import("redux-persist").PersistedState} PersistedState
 * @typedef {import("./initialState").DefaultState} DefaultState
 * @typedef {import('redux-persist').MigrationManifest} MigrationManifest
 * @typedef {import('redux-state-sync').Config} SyncConfig
 */

/**
 * @typedef {DefaultState & PersistedState} PersistedDefaultState
 */

/**
 * @type {MigrationManifest}
 */
const migrations = {
  6201: (/** @type {PersistedDefaultState} */ state) => {
    return state
  },
  // Migrate existing state initializing views section.
  6202: (/** @type {PersistedDefaultState} */ state) => {
    return {
      ...state,
      views: initialState.views,
    }
  },
  6203: (/** @type {PersistedDefaultState} */ state) => {
    return defaults(initialState, state, {
      settings: {
        hidePreferredStoreNotice: false,
      },
    })
  },
  6204: (/** @type {PersistedDefaultState} */ state) => {
    return {
      ...state,
      settings: initialState.settings,
    }
  },
  6205: (/** @type {PersistedDefaultState} */ state) => {
    return {
      ...state,
      checkout: initialState.checkout,
    }
  },
  6206: (/** @type {PersistedDefaultState} */ state) => {
    return {
      ...state,
      analytics: initialState.analytics,
    }
  }
}

// Noop storage using on server side.
const createNoopStorage = () => {
  return {
    /**
     * Get item.
     * @param {string} key
     * @return {Promise<null>}
     */
    getItem(key) {
      return Promise.resolve(null)
    },

    /**
     * Set item.
     * @param {string} key
     * @param {string} value
     * @return {Promise<string>}
     */
    setItem(key, value) {
      return Promise.resolve(value)
    },

    /**
     * Remove item.
     * @param {string} key
     * @return {Promise<void>}
     */
    removeItem(key) {
      return Promise.resolve()
    },
  }
}

/**
 * Redux state parts that must be ignored from persistence and sync so each
 * browser tab have it's own ephemeral
 * (memory) state of this parts.
 * @type {string[]}
 */

/**
 * @type {SyncConfig}
 */
const tabsSyncConfig = {
  // Reduce the actions that sync state between tabs to those ones that affect
  // commerce state.
  whitelist: [].concat(
    values(authActionTypes),
    values(commerceActionType),
    values(commonActionType),
    values(customerActionType),
    values(orderActionType),
    values(skuActionTypes),
    values(checkoutActionsType),
    values(analyticsActionsType),
    values(viewsActionType)
  ),
}

/**
 * Redux middlewares to inject.
 * @type {Array<Middleware>}
 */
const middlewares = [thunk]
// Inject only when running in browser.
if (typeof window !== 'undefined') {
  const sync = createStateSyncMiddleware(tabsSyncConfig)
  middlewares.push(sync)
}

const blackListParts = [
  'session',
  'ui',
  // 'views'
]
const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage()
const persistConfig = {
  key: APP_STORE_KEY,
  storage,
  stateReconciler: autoMergeLevel2,
  version: 6299,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: blackListParts,
}

const persistedReducer = persistReducer(persistConfig, withReduxStateSync(rootReducer))
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
  // applyMiddleware(...middlewares)
)

export const persistor = persistStore(store)
// initStateWithPrevTab(store)

/**
 * @type {import("./types").TypedUseSelector}
 */
export const useSelector = useSelectorBase

import React from 'react'
import Grid from '@mui/material/Grid'
import { Button } from '@ggs/gatsby/components/nav'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { GridContainer } from '@ggs/components/layout'
import { Text, Title } from '@ggs/components/paragraphs'
import useImageCtaSx, { ImageCtaImagePosition, ImageCtaVariant } from './useImageCtaSx'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { uniqueId } from 'lodash'

/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import('@ggs/types').ButtonVariant} ButtonVariant
 */

/**
 * @typedef {Object} ImageCtaProps
 * @property {String=} id
 * @property {String=} className
 * @property {String=} title
 * @property {String=} subtitle
 * @property {Array<LinkField>=} links
 * @property {ImageCtaImagePosition=} imagePosition
 * @property {ImageCtaVariant=} imageCtaTheme
 * @property {ButtonVariant=} buttonTheme
 * @property {ImageStyles=} ctaImage
 */

/**
 * Image Cta component.
 * @param {ImageCtaProps} props
 * @return {JSX.Element}
 */

/**
 * Parse the serialized URL and return the correctly formatted URL
 * @param {String} serializedUrl 
 * @return {String} - Formatted URL if it matches the pattern, else original URL
 */
function parseAndFormatUrl(serializedUrl) {
    // Check if URL matches the described format
    const regexPattern = /^{"en-CA":"http.*"}$/
    if (!regexPattern.test(serializedUrl)) return serializedUrl

    try {
        // Parse the serialized URL
        const parsedData = JSON.parse(serializedUrl)
        
        // Extract the URL
        let url = parsedData["en-CA"]
        
        // Correct the URL format by replacing multiple slashes with a single slash
        url = url.replace(/(https?:\/\/)|(\/)+/g, '$1$2')
        
        return url
    } catch (error) {
        console.error("Error parsing URL:", error)
        return serializedUrl // Return original URL in case of error
    }
}

const ImageCta = ({
  className,
  title = '',
  subtitle = '',
  links,
  imagePosition = ImageCtaImagePosition.right,
  imageCtaTheme = ImageCtaVariant.default,
  buttonTheme = Button.variant.contained,
  ctaImage,
}) => {
  const alignImage = imagePosition.toString().toLowerCase()
  const { sx, backgroundColor, accent } = useImageCtaSx(imageCtaTheme, alignImage)

  const ctaLinks = (
    <>
      {Array.isArray(links)
        && links.map(
          (link) =>
            link?.url && (
              <div key={uniqueId('image-cta-link_')} className="image-cta__link">
                <Button
                  variant={buttonTheme}
                  link={{
                    uri: parseAndFormatUrl(link.url), // Use the function here
                    title: (
                      <span
                        dangerouslySetInnerHTML={{
                          // @ts-ignore
                          __html: link.title,
                        }}
                      />
                    ),
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  endIcon={<ChevronRightIcon />}
                  data-label={link.title}
                  data-page-path={parseAndFormatUrl(link.url)}
                  data-action-type="navigate-image-cta"
                  data-source={className}
                />
              </div>
            )
        )}
    </>
  )

  const isFullStyle = alignImage === ImageCtaImagePosition.full

  // @ts-ignore
  return (
    <GridContainer className={`image-cta ${className}`} backgroundColor={backgroundColor} sx={sx}>
      {accent}
      <Grid
        container
        className={'image-cta__container'}
        justifyContent="flex-start"
        alignItems="center"
        spacing={{
          xs: 2,
          md: isFullStyle ? 4 : 5,
        }}
      >
        <Grid
          className="image-cta__content"
          item
          xs={12}
          md={isFullStyle ? 12 : 7}
          order={{
            xs: isFullStyle ? 1 : 2,
            md: alignImage === ImageCtaImagePosition.left ? 2 : 1,
          }}
        >
          <Title title={title} style={!isFullStyle ? 'h3' : 'h2'}>
            {!isFullStyle ? <Text textContent={subtitle} /> : null}
          </Title>
          {!isFullStyle && ctaLinks}
        </Grid>
        <Grid
          className="image-cta__image"
          item
          xs={12}
          md={isFullStyle ? 12 : 5}
          order={{
            xs: 1,
            md: alignImage === ImageCtaImagePosition.left ? 1 : 2,
          }}
        >
          <ImageStyles {...ctaImage} />
          {isFullStyle && (
            <>
              {subtitle && <Text textContent={subtitle} />}
              {ctaLinks}
            </>
          )}
        </Grid>
      </Grid>
    </GridContainer>
  )
}

ImageCta.variant = ImageCtaVariant
ImageCta.imagePosition = ImageCtaImagePosition
export default ImageCta
import React from 'react'
import Grid from '@mui/material/Grid'
import { useI18n } from '@ggs/gatsby/lib'
import { Button } from '@ggs/gatsby/components/nav'
import { Divider } from '@ggs/components/paragraphs'
import { PaymentSourceDetails } from '@ggs/components/ecomm'
import usePaymentSource from '@ggs/components/hooks/usePaymentSource'
import { WontBeChargedLine } from '@ggs/components/ecomm/Checkout/elements/payment/StripeLoader'
import { capitalize } from 'lodash'

/**
 * @typedef {import("@commercelayer/sdk").Order} Order
 */

/**
 * @typedef {Object} StripePaymentReviewProps
 * @property {Order} order
 * @property {function} onSuccess
 * @property {JSX.Element} paypalButton
 */

/**
 * Stripe Payment Review (display existing payment source details already associated to CL order).
 * @param {StripePaymentReviewProps} props
 * @return {JSX.Element}
 */
const StripePaymentReview = ({ order, onSuccess, paypalButton }) => {
  const { t } = useI18n()
  const { resetPaymentSource } = usePaymentSource()

  /**
     * Trigger payment source reset.
     * @type {React.FormEventHandler<HTMLFormElement>}
     * @param {React.FormEvent<HTMLFormElement>=} event
     */
  const handleReset = (event) => {
    resetPaymentSource()
  }

  /**
     * Handle continue to next step.
     * @type {React.FormEventHandler<HTMLFormElement>}
     * @param {React.FormEvent<HTMLFormElement>} event
     */
  const handleContinue = (event) => {
    onSuccess()
  }

  /*const switchPaymentButton = <FormActions
      actions={[
        {
          type: 'button',
          // className: 'form__action-submit form__action-button',
          label: t('ecomm:button.switchStripePaymentSource'),
          // variant: 'outlined',
          // endIcon: <ChevronRightIcon/>,
          /!**
           *
           * @param {React.FormEvent<HTMLFormElement>} e
           *!/
          onClick: (e)=>{
            FormActions.forceButtonLoading(e)
            handleReset(e)
          },
        },
      ]}
    />*/

  return (
    <Grid id={'stripe-payment-review'} container alignItems={{
      xs: 'flex-start',
      sm: 'center',
    }}>
      <Grid
        item
        xs={12}
        sx={{
          flex: {
            xs: '0 0 100%',
            sm: '1 1 auto',
          },
          mb: {
            xs: '16px !important',
            sm: 0,
          }
        }}
      >
        <p>{t('ecomm:label.chosenPaymentMethod')}</p>
        <PaymentSourceDetails order={order} variant="payment"/>
      </Grid>
      <Grid item sx={{
        flex: {
          xs: '0 0 100%',
          sm: '0 0 auto',
        },
      }}>
        {/*<Divider/>*/}
        {/*{switchPaymentButton}*/}
        <p>{capitalize(t('ecomm:label.orPayAnotherWay'))}</p>
        {paypalButton}
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 4, mb: 2 }}/>
        <Button
          onClick={handleContinue}
          className="stripe-payment__submit"
          label={t('ecomm:button.continueToReview')}
          sx={{
            mt: 2,
          }}
        />
        <WontBeChargedLine/>
      </Grid>
    </Grid>
  )
}

export default StripePaymentReview

import React, { useRef, useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import { get, forIn, isFunction } from 'lodash'

/**
 * @typedef {Object} ImpressionClickObserverProps
 * @property {Boolean=} ignoreImpression Indicate if the element should be observed.
 * @property {JSX.Element} children Element that is observed.
 */

/**
 * Component that observes when a child element is in view and when is clicked.
 * @param {ImpressionClickObserverProps} props
 * @return {JSX.Element}
 */
const ImpressionClickObserver = ({ ignoreImpression = false, children }) => {
  /**
   * @type {HTMLElement}
   */
  const defaultRefValue = null
  const childRef = useRef(defaultRefValue)

  // Extract all data attributes from observed child element.
  const dataAttributes = useMemo(() => {
    const data = []
    forIn(children.props, (value, key) => {
      if (key.startsWith('data-')) {
        const cleanKey = key.replace('data-', '').replace(/-/g, '_')
        data[cleanKey] = value
      }

      if (key === 'href' && value && !data['page_path']) {
        data['page_path'] = value
      }
    })

    return data
  }, [children])

  /**
   * Handler that tracks the impression event of observed element.
   * @param {boolean} inView Indicate if the element is in view.
   * @param {any} entry Intersection observer entry object.
   */
  const onChangeHandler = (inView, entry) => {
    if (inView) {
      pushDataLayer({
        eventName: 'impression',
        dataLayer: {
          ...dataAttributes,
        },
      })
    }
  }

  /**
   * @param {MouseEvent<HTMLButtonElement|HTMLAnchorElement>} e
   * @param e
   */
  const onClickHandler = (e) => {
    // Prevent propagation to avoid parent observers react on child observers events.
    e.stopPropagation()

    // Only prevent default behaviour for non-link elements.
    if (e.target.tagName !== 'A' && e.currentTarget.tagName !== 'A') {
      e.preventDefault()
    }

    if (e.target) {
      console.log('sendingClickEvent:', dataAttributes)
      pushDataLayer({
        eventName: 'click',
        dataLayer: {
          ...dataAttributes,
        },
      })
    }

    // If child element originally has an onClick handler, call it.
    if (isFunction(children.props.onClick)) {
      children.props.onClick()
    }
  }

  const eventType = get(dataAttributes, 'action_type', null)

  // If there's no event, return the children without any modifications.
  if (!eventType) {
    return <>{children}</>
  }

  // If the element should ignore impression, return just the children with injected onClick handler.
  if (ignoreImpression) {
    return (
      <>
        {React.cloneElement(children, {
          onClick: onClickHandler,
        })}
      </>
    )
  }

  // Element is cloned in order to inject the onClick handler which monitor the click, when event type is not defined
  // we assume that the element should not be observed.
  return (
    <InView
      as="div"
      className="inview-element"
      onChange={onChangeHandler}
      threshold={0.5}
      triggerOnce={true}
    >
      {React.cloneElement(children, {
        ref: childRef,
        onClick: onClickHandler,
      })}
    </InView>
  )
}

export default ImpressionClickObserver

// @ts-nocheck
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, noop, uniqueId } from 'lodash'
// Components
import MuiRadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from './TextField'
// Data
import { useI18n } from '@ggs/gatsby/lib'
// Assets
// import './_RadioGroup.scss'
import { events } from '@ggs/utils'

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({
    maxLength: PropTypes.number,
  }),
  fieldProps: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
}

const defaultProps = {
  label: '',
  name: '',
  disabled: false,
  fieldProps: {
    value: undefined,
    onChange: noop,
  },
  inputProps: {
    maxLength: undefined,
  },
  defaultValue: '',
  options: [],
}

// eslint-disable-next-line react/display-name
const RadioGroup = forwardRef(
  (
    { disabled, fieldProps: { value: formikValue, onChange, ...fieldProps }, inputProps, ...props },
    ref
  ) => {
    const { t } = useI18n()
    const {
      label,
      name,
      options,
      useOther = false,
      defaultValue,
    } = defaultsDeep(props, defaultProps)
    // Value tracking
    const [value, setValue] = useState('')
    const [otherValue, setOtherValue] = useState('')
    const [otherFocused, setOtherFocused] = useState(false)

    // Allow on click of other radio to focus on text field.
    const otherRef = useRef()
    /**
     * By focusing on the other field, it will dependably clean up state/value settings for us.
     */
    const focusOnOther = () => {
      const { /** @type Element */ current = window?.document } = otherRef
      current?.querySelector('input')?.focus()
    }

    /**
     * When the user updates a radio option
     * @param event
     */
    const radioOptionChange = (event) => {
      setValue(event?.target?.value)
      // clear out other value
      setOtherValue('')
      setOtherFocused(false)
      // Update formik
      onChange(event)
      // console.log('radioOptionChange',event.target.value, event)
    }

    /**
     * When the user enters a value in the other field
     * @param event
     */
    const otherOptionChange = (event) => {
      setOtherFocused(true)
      setOtherValue(event?.target?.value)
      // Clear out radio value
      setValue('')
      // Update formik
      onChange(event)
      // console.log('otherOptionChange',event.target.value)
    }

    /**
     * On load, detect the initial value to set from previous form usage.
     */
    useEffect(() => {
      const init = formikValue || defaultValue
      if (!otherValue) {
        const matches =
          options.find((o) => o?.value === init || (!o?.value && o?.label === value)) || null
        // If set, does it exist inside the options as value? If so, use the setFieldValue
        if (matches) {
          if (matches !== value) {
            setValue(init)
          }
        } else if (init && init !== otherValue) {
          // If set and does not exist, assume the string is for the other and use setOtherValue
          setOtherValue(init)
        }
      }
    }, [])

    const reset = () => {
      radioOptionChange(events.syntheticInputEvent(fieldProps.name, { checked: false }))
    }

    // console.log(`radio ${name}`, { value, otherValue, formikValue, defaultValue })

    return (
      <FormControl className="form-field__radiogroup" component="fieldset" disabled={disabled}>
        <FormLabel component="legend">{label}</FormLabel>
        <MuiRadioGroup
          {...fieldProps}
          ref={ref}
          onChange={radioOptionChange}
          name={name}
          value={value}
          aria-label={label}
        >
          {options.map((item, index) => (
            <FormControlLabel
              key={uniqueId(`radio-item-${index}_`)}
              value={item?.value || item?.label}
              disabled={item?.disabled}
              control={<Radio color="primary" />}
              label={item?.label}
              labelPlacement={item?.labelPlacement}
            />
          ))}
        </MuiRadioGroup>
        {useOther === true && (
          <div className="form-field__radio-other">
            <FormControlLabel
              key={uniqueId('radio-item--other-')}
              value={otherValue}
              onClick={focusOnOther}
              control={
                <Radio
                  onClick={focusOnOther}
                  onTouchStart={focusOnOther}
                  color="primary"
                  checked={!!otherValue || otherFocused}
                />
              }
              label={t('global:form.label.other')}
            />
            <TextField
              ref={otherRef}
              name={name}
              onChange={otherOptionChange}
              onFocus={otherOptionChange}
              value={otherValue}
              inputProps={{ ...inputProps, 'aria-label': 'Other' }}
            />
          </div>
        )}
        <button
          className="form-field__reset"
          data-name={fieldProps.name}
          type="button"
          aria-hidden
          onClick={reset}
        />
      </FormControl>
    )
  }
)

RadioGroup.defaultProps = defaultProps
RadioGroup.propTypes = propTypes
// Export Component
export default RadioGroup

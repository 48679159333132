import React from 'react'
import dayjs from 'dayjs'
import { useI18n } from '@ggs/gatsby/lib'
import Grid from '@mui/material/Grid'
import { GridContainer } from '@ggs/components/layout'
import { Button, Link } from '@ggs/gatsby/components/nav'
import { Chip } from '@ggs/components/common'

// Assets
import './CarouselItem.scss'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { icons } from '@ggs/components'

/**
 * @typedef {import("@ggs/types").ArticleCard} ArticleCard
 */
/**
 * ArticleCarouselItem component.
 * @param {ArticleCard} props
 * @return {JSX.Element}
 */
export default function ArticleCarouselItem({
  title,
  articleImage,
  publishedDate,
  articleCategories,
  url
}) {
  const { t } = useI18n()

  return (
    <GridContainer
      className="carousel-item"
      sx={{ pt: { xs: 0, md: 4 }, pb: { xs: 3, md: 5 } }}
    >
      <>
        <Grid className="carousel-item__inner" container justifyContent="flex-start" alignItems="stretch">
          {articleImage && (
            <Grid
              className="carousel-item__image"
              item
              xs={12}
              md={12}
              lg={7}
            >
              <ImageStyles {...articleImage} selectedStyles={['pageHeroFeatured']} />
            </Grid>
          )}
          <Grid
            className={`${articleImage ? 'carousel-item__outer' : 'carousel-item__outer carousel-item__outer--full' }`}
            item
            xs={12}
            md={12}
            lg={5}
          >
            <div className="carousel-item__content">
              {articleCategories && (
                <div className="carousel-item__category">
                  {articleCategories.map(({ name }) => (
                    <Chip key={name} label={name} />
                  ))}
                </div>
              )}
              <div className="carousel-item__date-and-location">
                <span className="carousel-item__date">{dayjs(publishedDate).format('MMM D, YYYY')}</span>
              </div>
              <h2 className="carousel-item__title">
                <Link href={url}>{title}</Link>
              </h2>
              <div className="carousel-item__actions">
                <Button
                  link={{
                    title: t('global:button.readMore'),
                    uri: url,
                  }}
                  variant={Button.variant.text}
                  endIcon={icons.ChevronDown}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    </GridContainer>
  )
}

/**
 *
 * @param {string} videoPathOrId
 * @return {string}
 */
export default function safeVideoId(videoPathOrId) {
  let videoId = videoPathOrId
  if ((videoPathOrId || '').toString().indexOf('v=') !== -1) {
    videoId = videoPathOrId?.split('v=')?.[1]?.split('&')?.[0]
  }
  return videoId
}

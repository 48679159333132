import React, { useEffect } from 'react'
import { debounce } from 'lodash'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Text, Title } from '@ggs/components/paragraphs'
import { Button } from '@ggs/gatsby/components/nav'
import { colors, common } from '@ggs/styles'
import { icons } from '@ggs/components'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { MiniCartItemsList, useSupportingLinks } from '@ggs/components/ecomm'
// State
import { hideSidebar, useSelector } from '@ggs/store'
import { useDispatch } from 'react-redux'
// Utils
import { useI18n } from '@ggs/gatsby/lib'
import { navigate } from 'gatsby'
import useOrderLabels from '@ggs/components/ecomm/Customer/useOrderLabels'

/**
 * Shopping cart mini view for sidebar display.
 *
 * @return {JSX.Element}
 */
export default function CartMiniView() {
  const { t } = useI18n()
  const border = common.borderLightBlue
  const order = useSelector((state) => state.commerce.order)
  const dispatch = useDispatch()
  const ecommLinks = useSupportingLinks()
  // console.log('ecommLinks', ecommLinks)
  const isOrderLoaded = !(
    !order
    || !Array.isArray(order.line_items)
    || order?.line_items?.length === 0
  )
  const orderLabels = useOrderLabels(order)

  const closeCart = () => {
    dispatch(hideSidebar())
  }

  /**
   * Navigate user to shopping cart.
   * @param {React.MouseEvent<HTMLButtonElement>} e Click event
   */
  const handleGoToCart = debounce((e) => {
    navigate(ecommLinks.cart)
    closeCart()
    // console.log('Go to cart', ecommLinks.cart)
    e.preventDefault()
  }, 100)

  useEffect(() => {
    if (isOrderLoaded) {
      // @ts-ignore
      document?.querySelector('.cart-mini-view__go-to-cart')?.focus()
    }
  }, [isOrderLoaded])

  // Displayed when the cart is loading.
  const displayCartLoader = (
    <>
      <Grid container spacing={2} padding={2} alignItems={'baseline'}>
        <Grid item sm={'auto'}>
          <Title title={t('global:label.loading')} style="h4" />
          <Box sx={{ my: 2 }}>
            <CircularProgress />
          </Box>
          <Text textContent={`<p>${t('ecomm:cart.label.loadingCartNotice')}</p>`} />
        </Grid>
      </Grid>
    </>
  )

  // Displaying the cart data once loaded.
  const displayCart = isOrderLoaded && (
    <>
      <Grid
        container
        spacing={2}
        alignItems={'baseline'}
        sx={{
          borderBottom: border,
          pb: 4,
          mb: 3,
          '.button.MuiButton-root.MuiButton-text.cart-mini-view__close': {
            position: 'absolute',
            right: 10,
            top: 24,
            px: 1.5,
            py: 1,
            // minWidth: 24,
            width: 24,
          },
        }}
      >
        <Grid item sm={'auto'}>
          <Title
            title={t('ecomm:cart.label.itemsInCart', {
              count: `(${order.skus_count})`,
            })}
            style="h4"
          />
        </Grid>
        <Button
          className="cart-mini-view__close"
          onClick={closeCart}
          variant={Button.variant.text}
          aria-label={t('global:aira.label.closeCart')}
        >
          {icons.CloseBlue}
        </Button>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          borderBottom: border,
          mb: 3,
          pb: 3,
          alignItems: 'center',
        }}
      >
        <Grid item sm={5} md={6} padding={0}>
          <Typography variant={'caption'}>{t('ecomm:cart.label.subtotal')}</Typography>
          <Text textContent={orderLabels.totals.orderTotal} />
        </Grid>
        <Grid item sm={7} md={6} textAlign={'right'}>
          <Button
            className={'cart-mini-view__go-to-cart'}
            onClick={handleGoToCart}
            label={t('ecomm:cart.button.goToCart')}
            endIcon={<ChevronRightIcon />}
          />
        </Grid>
      </Grid>
      <MiniCartItemsList order={order} />
    </>
  )

  return (
    <Box
      className="cart-mini-view"
      sx={{
        position: 'relative',
        backgroundColor: colors.white,
        width: 320,
        maxWidth: '100vw',
        height: '100vh',
        padding: {
          xs: 2,
          md: 5,
        },
        '& div': {
          maxWidth: '100%',
        },
      }}
    >
      {isOrderLoaded ? displayCart : displayCartLoader}
      {/*{displayCartLoader}*/}
    </Box>
  )
}

// @ts-nocheck
import { clearActiveOrder, getOrder, placeOrder, updatePaymentSourcePaypal } from '@ggs/commercelayer'
import {
  addNotification,
  clearPaypalMetadata,
  resetCheckoutStateExceptShippingAddress,
  setLastOrderId,
  useSelector,
} from '@ggs/store'
import { delay, find, noop } from 'lodash'
import { useDispatch } from 'react-redux'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { useI18n } from '@ggs/gatsby/lib'
import CHECKOUT_STEP_ENUMS from '../Checkout/enums/CHECKOUT_STEP_ENUMS'
import { usePaymentSourceDispatcher } from '@ggs/components/ecomm/Customer/usePaymentSourceDispatcher'
import { Severity } from '@sentry/gatsby'
import { triggerSentry } from '@ggs/gatsby/sentry'
import { setViewsOrders } from '@ggs/store/actions/views'
import { formatMetaData } from '@ggs/components/ecomm/ProductPurchaseOptions/useCurrentMetadata'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'

const { ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS

/**
 * @typedef {import('@ggs/commercelayer').Order} Order
 */


export const usePlaceOrderController = ({ reminderPrograms = [] }) => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  const {
    order,
    handlers: {
      goToPayment,
      goToComplete,
    },
    navigateToOrderReview,
    markStepComplete,
    PayerID,
    clClient,
  } = useCheckoutContext()
  const { firePurchase } = useCheckoutAnalytics({ order })

  // In the event the language changed since the order started, apply latest.
  const currentStore = useSelector(({ ui }) => ui?.layoutMeta?.currentStore)
  const currentMarket = useSelector((state) => state.settings.currentMarket)

  const {
    resetInitDefaultPaymentMethod,
  } = usePaymentSourceDispatcher({ onSuccess: noop, onError: noop })

  const checkOrderPlaced = ({ setSubmitting }) => {
    dispatch(
      // check if order has been placed
      getOrder(clClient, order.id, {
        onSuccess: ({ payload: { order } }) => {
          // Besides the exception the order was placed so take user to completion step.
          if (order?.status === 'placed') {
            handlePlacedOrder(order)
          }
          // what do we do if the order never updates?
        },
        onError: (e) => {
          if (e && e.errors) {
            for (const err of e.errors) {
              errorCheck(err)
            }
            // Sometimes "place" operation is exceeding timeout but still completing the operation after exception
            // delay(() => checkOrderPlaced, 15000)
            setSubmitting(false)
          }
        }
      })
    )
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   */
  const handlePlacedOrder = (order) => {
    dispatch(setViewsOrders(order))
    dispatch(setLastOrderId(order))
    dispatch(clearPaypalMetadata())
    markStepComplete(REVIEW)
    firePurchase({ order })
    goToComplete()
  }

  // this tries to complete the order, by changing its status to _placed
  // eslint-disable-next-line valid-jsdoc
  /**
   * Process needed CL actions when determined that webapp returned from
   * external payment webapp.
   */
  const handleExternalPaymentReturn = (values, formik, formError) => {
    const { setSubmitting } = formik
    const paymentSourceUpdate = {
      id: order.payment_source.id,
      paypal_payer_id: PayerID,
    }

    // this doesnt actually do anything.
    dispatch(
      updatePaymentSourcePaypal(clClient, order, paymentSourceUpdate, {
        onSuccess: (action) => {
          // Complete the order.
          onAcceptTermsSubmit(values, formik, formError)
        },
        onError: (error) => {
          console.log('PaymentSourceUpdateError:', error)
          setSubmitting(false)
          dispatch(
            addNotification(
              'paypal_payment_source_fail',
              'error',
              t('ecomm:notice.paypalPaymentSourceError')
            )
          )
          // Take user to payment step to offer a payment retry or opportunity to change payment method.
          goToPayment()
        },
      })
    )
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * Handle order processing and proceed to complete step.
   */
  const onAcceptTerms = (values, { setSubmitting }, callbacks, formError) => {
    // dispatch(setLastOrderId(order))
    if (PayerID) {
      handleExternalPaymentReturn(values, { setSubmitting }, formError)
    } else {
      onAcceptTermsSubmit(values, { setSubmitting }, formError)
    }
  }

  const addReminderToOrder = (values) => {
    // Add reminder programs to order metadata
    const foundPrograms = Object.keys(values)
      .filter((key) => key.indexOf('reminder_') !== -1 && values[key])
      .map((value) => {
        const id = parseInt(value.replace('reminder_', ''))
        const program = find(reminderPrograms, ['tid', id])
        // console.log('program', id, program, props?.items?.reminderPrograms)
        return {
          id,
          name: program?.name,
        }
      })
    return foundPrograms.length ? JSON.stringify(foundPrograms) : null
  }

  // method bound to the submit 'place order' - checks if terms accepted
  const onAcceptTermsSubmit = (values, { setSubmitting }, formError) => {
    const { acceptTerms } = values
    const reminder_programs = addReminderToOrder(values)

    const formattedMeta = formatMetaData({ currentMarket, currentStore })
    // If the user accepted the terms...
    if (acceptTerms) {
      dispatch(placeOrder(
        clClient,
        order,
        {
          ...order.metadata,
          ...formattedMeta,
          reminder_programs,
          PayerID
        },
        {
          onSuccess: (res) => {
            const placedOrder = res?.payload?.order?.status === 'placed' ? res.payload.order : null
            if (placedOrder) {
              handlePlacedOrder(placedOrder)
            } else {
              // Sometimes "place" operation is exceeding timeout but still completing the operation
              delay(() => checkOrderPlaced({ setSubmitting }), 15000)
            }
          },
          onError: (e) => {
            if (e && e.errors) {
              for (const err of e.errors) {
                errorCheck(err)
              }
              // Sometimes "place" operation is exceeding timeout but still completing the operation after exception
              delay(() => checkOrderPlaced({ setSubmitting }), 15000)
            }

          }
        }))
    } else {
      setSubmitting(false)
    }
  }

  const authorizationFailedError = (e) => e.status === '422' && e.meta.error === 'authorization_failed'
  const notPermittedTransitionError = (e) => e.status === '422' && e.meta.error === 'not_permitted_transition'
  const paymentSourceBlankError = (e) => e.status === 422 && e.details === 'payment_source - can\'t be blank'

  const cannotModifyOrder = (e) => e.code === 'UNAUTHORIZED' && e.status === '401'
  const missingShipmentMethod = (e) => e.status === '422' && e.meta.error === 'missing_shipping_methods'

  const errorCheck = (e) => {
    if (missingShipmentMethod(e)
      || authorizationFailedError(e)
      || notPermittedTransitionError(e)
      || paymentSourceBlankError(e)) {
      // order or payment was invalid, or corrupted, bail out, force reset of payment and checkout progress.
      resetInitDefaultPaymentMethod()
      // initDefaultPaymentMethod()
      dispatch(resetCheckoutStateExceptShippingAddress(order))
      // navigate(checkoutUrl)
    } else if (cannotModifyOrder(e)) {
      // clear order and redirect to review
      dispatch(clearActiveOrder())
      // if last order exists, it will display, otherwise will redirect to products listing
      navigateToOrderReview()
    } else {
      // TODO: Analyze other possible scenarios where "placed" can fail and how to react.
      console.log('placeOrderError', e)
      triggerSentry({
        name: 'place_order_unknown_error',
        error: e,
        actionPerformed: 'place_order',
        level: Severity.Error,
      })
    }
  }

  return {
    // completeOrder,
    onAcceptTerms,
    // addReminderToOrder,
    // onAcceptTermsSubmit
  }
}

import { useSelector } from '@ggs/store'

/**
 * @typedef {import('@ggs/types').CustomerModal} CustomerModal
 */
/**
 *
 * @return {function(CustomerModal[]): number|null}
 */
export default function useFindModalForCustomerType() {
  const customer = useSelector((state) => state.commerce.customer)

  /**
   * @param {CustomerModal[]} customerForms
   * @return {number|null}
   */
  return (customerForms) => {
    let activeIndex = null
    // Get all matches.
    const allMatchedItems = customerForms?.filter(
      ({ customerGroup }) => !customerGroup?.tid || customer.customerType === customerGroup?.tid
    )
    // Find first match for the users customer type
    let matchedItem = allMatchedItems?.find((customerForm) => {
      return customer?.customerType === customerForm?.customerGroup?.tid
    })
    // If no match found, see if there's a patient level catch all version.
    if(!matchedItem) {
      matchedItem = allMatchedItems?.find((customerForm) => {
        return !customerForm?.customerGroup?.tid
      })
    }
    // Now, if we have a match, make sure it has actual content too to validate it's usable.
    if (matchedItem?.formEmbed?.length > 0 || matchedItem?.textContent?.length > 0) {
      // Get matched key index.
      activeIndex = customerForms?.findIndex((customerForm) => customerForm === matchedItem)
    }

    // console.log('useFindModalForCustomerType', {
    //   customer,
    //   customerForms,
    //   allMatchedItems,
    //   matchedItem,
    //   activeIndex,
    // })

    return activeIndex
  }
}

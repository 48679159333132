import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import { GridContainer } from '@ggs/components/layout'
import Grid from '@mui/material/Grid'
import { Title } from '@ggs/components/paragraphs'

// Assets
import useFooterSx from '@ggs/components/layout/Footer/useFooterSx'
import { colors } from '@ggs/styles'
import { useOneTrustContext } from '@ggs/components/onetrust/OneTrustContext'

/**
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */
/**
 * @typedef {Object} CheckoutFooterProps
 * @property {String=} notice
 * @property {String=} details
 * @property {LayoutMeta=} layoutMeta
 */
/**
 * @param {CheckoutFooterProps} props
 * @return {JSX.Element}
 */
export default function CheckoutFooter({ notice, details, ...layoutMeta }) {
  // @ts-ignore
  const { currentStore } = layoutMeta || {}
  const { t } = useI18n()
  const sx = useFooterSx()
  const officePhone = currentStore?.officePhone

  const {
    PrivacySettingsModalLink,
    CookieListModalLink,
  } = useOneTrustContext()

  return (
    <Box sx={sx}>
      <GridContainer
        sx={{ py: { xs: 2, md: 6 } }}
        className="footer"
        fullWidthBackground
        backgroundColor={colors.darkGrey}
      >
        <Grid className="footer__checkout" container alignItems="flex-start" spacing={{ xs: 1, lg: 5 }}>
          <Grid item xs={12} md={4}>
            <Title title={t('ecomm:cart.label.checkoutFooter')} style="h2"/>
          </Grid>
          <Grid className="footer__checkout-details" item xs={12} md={4}>
            <div dangerouslySetInnerHTML={{ __html: details }}/>
          </Grid>
          <Grid className="footer__checkout-notice" item xs={12} md={4}>
            <div dangerouslySetInnerHTML={{ __html: notice }}/>
            <Box sx={{ mt: 2 }}>
              <PrivacySettingsModalLink/>
            </Box>
            <Box sx={{ mt: 2 }}>
              <CookieListModalLink/>
            </Box>
          </Grid>
        </Grid>
      </GridContainer>
    </Box>
  )
}

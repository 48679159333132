// @ts-nocheck
import { StripePaymentProvider } from '@ggs/components/ecomm'
import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'
import { PayPalIcon } from '@ggs/components/ecomm/Customer/PaymentPayPalChosen'
import PaypalPaymentReview from '@ggs/components/ecomm/Customer/PaypalPaymentReview'
// @ts-ignore
import { Text, Title } from '@ggs/components/paragraphs'
import { FormActions } from '@ggs/forms/Form'
import { Button } from '@ggs/gatsby/components/nav'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import animateScrollTo from 'animated-scroll-to'
import { delay, isEmpty, noop } from 'lodash'
import React, { useMemo } from 'react'
import { usePaymentSourceDispatcher } from '@ggs/components/ecomm/Customer/usePaymentSourceDispatcher'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'

const { PAYMENT } = CHECKOUT_STEP_ENUMS

/**
 * @typedef {import("@commercelayer/sdk").PaymentMethod} PaymentMethod
 * @typedef {import("@commercelayer/sdk").Order} Order
 */

/**
 * @typedef {Object} PaymentMethodFormProps
 * @property {Order} order
 * @property {function=} onSuccess
 * @property {function=} onFailure
 */

/**
 * Checkout payment methods form (order payment step).
 * @param {PaymentMethodFormProps} props
 * @return {JSX.Element}
 */
const PaymentMethodForm = ({ order, billingAddressComplete, onSuccess = noop, onFailure = noop }) => {
  const { t } = useI18n()
  const {
    checkout: {
      checkoutState: { billingAddressId },
    },
  } = useCheckoutContext()

  const {
    paymentMethods,
    purchaseWithStripe,
    purchaseWithPaypal,
    shouldRedirectToPaypal,
    isStripePaymentMethod,
    continueToReview,
    markStepComplete,
    publishable_key,
    client_secret,
  } = usePaymentSourceDispatcher({ onSuccess, onError: onFailure })

  const scrollToTopOfForm = () => {
    animateScrollTo(document.querySelector('#payment-method-form'), {
      elementToScroll: window,
      maxDuration: 3000,
      minDuration: 250,
      speed: 500,
    }).catch((e) => {
    })
  }

  const paypalButton = useMemo(
    () => (
      <FormActions
        actions={[
          {
            type: 'button',
            className: 'payment-method-button__paypal',
            label: PayPalIcon,
            variant: Button.variant.text,
            // endIcon: PayPalIcon,
            onClick: (e) => {
              const target = FormActions.forceButtonLoading(e)
              target.querySelector('img').style.opacity = 0.5
              delay(() => {
                purchaseWithPaypal(e)
              }, 0)
            },
          },
        ]}
      />
    ),
    [purchaseWithPaypal]
  )

  // console.log('PaymentMethodForm', {
  //   paypalPaymentMethod,
  //   stripePaymentMethod,
  //   selectedPaymentMethodDefinition,
  //   order,
  // })

  // Wait if not ready.
  if (isEmpty(paymentMethods)) {
    return null
  }
  // Don't render until billing address is set.
  else if (!billingAddressId) {
    return (
      <Box>
        <Title title={t('ecomm:label.paymentMethod')} style={'h3'} sx={{ mb: 2 }}/>
        <Typography variant={'body1'}>{t('ecomm:notice.enterBillingAddress')}</Typography>
      </Box>
    )
  }
  //
  // console.log('PaymentMethodForm', {
  //   paypalPaymentMethod,
  //   stripePaymentMethod,
  //   selectedPaymentMethodDefinition,
  //   order,
  // })

  const sx = {
    '& .form__actions': {
      display: 'block',
    },
    '.payment-method-button__paypal': {
      // display: 'inline-flex',
      // alignItems: 'center',
      // background: 'gold',
      p: '0 !important',
      '.form__action-loader': {
        mt: 1,
      },
      // '&, span': {
      //   color: colors.black ,
      //   verticalAlign: 'middle',
      // },
      // '.MuiButton-endIcon': {
      //   display: 'inline-block',
      //   ml: 1,
      //   mt: '-3px',
      //   height: 30,
      // },
    },
  }
  return (
    <Box id={'payment-method-form'} sx={sx}>
      <Title title={t('ecomm:label.paymentMethod')} style={'h3'} sx={{ mb: 3 }}/>
      {shouldRedirectToPaypal ? (
        <Grid container>
          <Grid item xs={12} textAlign={'center'}>
            <CircularProgress
              size={60}
              sx={{
                my: 2,
              }}
            />
            <Text>
              <p className="small">{t('ecomm:notice.preparingPaypalRedirect')}</p>
            </Text>
          </Grid>
        </Grid>
      ) : isStripePaymentMethod ? (
        <StripePaymentProvider
          order={order}
          clientSecret={client_secret}
          publicKey={publishable_key}
          onSuccess={() => {
            markStepComplete(PAYMENT)
            continueToReview()
            onSuccess()
          }}
          paypalButton={paypalButton}
          scrollToTopOfForm={scrollToTopOfForm}
        />
      ) : (
        <PaypalPaymentReview
          continueToReview={continueToReview}
          purchaseWithStripe={purchaseWithStripe}
        />
      )}
    </Box>
  )
}

export default PaymentMethodForm

import React, { useMemo } from 'react'
import { CardGrid } from '@ggs/components/grids'
import { Text } from '@ggs/components/paragraphs'
// Assets.
import './ImageGrid.scss'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */
/**
 * @typedef {Object} ImageGridProps
 * @property {String=} title
 * @property {String=} summary
 * @property {String=} footerContent
 * @property {Array<ImageStyles>=} images
 */
/**
 * ImageGrid component.
 * @param {ImageGridProps} props
 * @return {JSX.Element}
 */
export default function ImageGrid({ title, summary, footerContent, images = [] }) {
  const imageItems = useMemo(() => {
    return images.map((image) => {
      return {
        type: 'image-styles',
        styles: image.styles,
        selectedStyles:
          images.length > 2 ? ['inlineImageSmall'] : ['inlineImageFull', 'inlineImageSmall'],
      }
    })
  }, [images])

  return (
    <div className="image-grid" data-count={imageItems.length}>
      {imageItems.length > 0 && (
        <CardGrid
          title={title}
          subtitle={summary}
          items={imageItems}
          component={CardGrid.component.ImageStyles}
          headingStyle="h3"
        />
      )}
      {footerContent && <Text className={'image-grid__footer'} textContent={footerContent} />}
    </div>
  )
}

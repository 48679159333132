import React from 'react'
import { Title } from '@ggs/components/paragraphs'
import { EventSpeaker } from '@ggs/components/cards'

// Assets
import './EventSpeakerGroup.scss'

/**
 * @typedef {import('@ggs/components/cards/EventSpeaker/EventSpeaker').EventSpeakerProps} EventSpeaker
 */
/**
 * @typedef {Object} EventSpeakerGroupProps
 * @property {Number=} id
 * @property {String=} label
 * @property {Array<EventSpeaker>=} eventSpeakers
 */
/**
 * EventSpeakerGroup component.
 * @param {EventSpeakerGroupProps} props
 * @return {JSX.Element}
 */
export default function EventSpeakerGroup({ label = '', eventSpeakers = [] }) {
  return (
    <div className="event-speaker-group">
      <Title className="event-speaker-group__title" title={label} style="h4" />
      {eventSpeakers.length > 0 &&
        eventSpeakers.map((item, i) => (
          <EventSpeaker key={`event-speaker-${i}-${item.id}`} {...item} />
        ))}
    </div>
  )
}

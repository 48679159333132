// @ts-nocheck
import { CommerceLayerClientFactory } from '@ggs/commercelayer/index'
import { useEffect, useState } from 'react'

const expireCheckIntervalMinutes = 1
const expireCheckIntervalMs = 60000 * expireCheckIntervalMinutes
const globalDefaultScope = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`

const currentMarketId = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`
if (!currentMarketId) {
  throw new Error(
    'Default market ID is undefined, please review [GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID] env variable'
  )
}
/**
 *
 * 4.2.2.  Access Token Response
 *
 *    If the resource owner grants the access request, the authorization
 *    server issues an access token and delivers it to the client by adding
 *    the following parameters to the fragment component of the redirection
 *    URI using the "application/x-www-form-urlencoded" format, per
 *    Appendix B:
 *
 *    access_token
 *          REQUIRED.  The access token issued by the authorization server.
 *
 *    token_type
 *          REQUIRED.  The type of the token issued as described in
 *          Section 7.1.  Value is case insensitive.
 *
 *    expires_in
 *          RECOMMENDED.  The lifetime in seconds of the access token.  For
 *          example, the value "3600" denotes that the access token will
 *          expire in one hour from the time the response was generated.
 *          If omitted, the authorization server SHOULD provide the
 *          expiration time via other means or document the default value.
 *
 *    scope
 *          OPTIONAL, if identical to the scope requested by the client;
 *          otherwise, REQUIRED.  The scope of the access token as
 *          described by Section 3.3.
 *
 *    state
 *          REQUIRED if the "state" parameter was present in the client
 *          authorization request.  The exact value received from the
 *          client.
 *
 *    The authorization server MUST NOT issue a refresh token.
 *
 *    For example, the authorization server redirects the user-agent by
 *    sending the following HTTP response (with extra line breaks for
 *    display purposes only):
 *
 *      HTTP/1.1 302 Found
 *      Location: http://example.com/cb#access_token=2YotnFZFEjr1zCsicMWpAA
 *                &state=xyz&token_type=example&expires_in=3600
 *
 *    Developers should note that some user-agents do not support the
 *    inclusion of a fragment component in the HTTP "Location" response
 *    header field.  Such clients will require using other methods for
 *    redirecting the client than a 3xx redirection response -- for
 *    example, returning an HTML page that includes a 'continue' button
 *    with an action linked to the redirection URI.
 *
 *
 */
export const useStartCommerceSession = ({
  scope = globalDefaultScope,
  accessToken = { token: '', tokenExpiresAt: 0 }
  // customer
}) => {
  const [activeClClient, setActiveClClient] = useState(() => {
    if (accessToken.token) {
      const clClientFactory = new CommerceLayerClientFactory()
      clClientFactory.setToken(accessToken.token)
      return clClientFactory.getClient()
    }
    return null
  })
  const {
    token,
    tokenRefresh,
    tokenExpiresAt
  } = accessToken

  /**
   * Initialize commerce layer token whenever market is set (default or changed).
   */
  useEffect(() => {
    const clClientFactory = new CommerceLayerClientFactory()

    setActiveClClient(() => {
      if (accessToken.token) {
        clClientFactory.setToken(accessToken.token)
        return clClientFactory.getClient()
      }
      return null
    })
  }, [scope, accessToken, token, tokenExpiresAt])

  return {
    clClient: activeClClient
  }
}
import React, { useMemo } from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import { useSupportingLinks } from '@ggs/components/ecomm'
import Box from '@mui/material/Box'
import { CardGrid } from '@ggs/components/grids/index' // Assets.
import './ProductGrid.scss'
import { ProductGridVariant } from '@ggs/components/paragraphs/ProductGrid/ProductGrid'
import { ViewProductListObserver } from '@ggs/components/hooks/ViewProductListObserver' /**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 */
/**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 */
/**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 */

/**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 */
/**
 * @typedef {Object} ProductGridProps
 * @property {String=} title
 * @property {Array<ProductCard|{productCard:ProductCard,descriptionOverride:String}>=} relatedProducts
 * @property {String=} actionLabel
 * @property {Boolean=} displayAllProductsButton
 * @property {ProductGridVariant=} theme
 */
/**
 * ProductGrid component.
 * @param {ProductGridProps} props
 * @return {JSX.Element}
 */
export default function ProductGridUkHomepage({
  title = '',
  relatedProducts = [],
  actionLabel = '',
  displayAllProductsButton = false,
  theme = ProductGridVariant.default,
  ...productGridProps
}) {
  const { t } = useI18n()
  const { productListing } = useSupportingLinks()
  const item_list_name = `${productGridProps.type}--${
    productGridProps?.id ?? productGridProps?.entityId
  }`
  /** @type {Array<ProductCard>} */
  const activeProducts = useMemo(
    () =>
      relatedProducts.map((entry) =>
        // @ts-ignore
        entry?.productCard
          ? {
            // @ts-ignore
            ...entry.productCard,
            listingDescription:
                // @ts-ignore
                entry?.descriptionOverride || entry?.productCard?.listingDescription,
          }
          : entry
      ),
    [relatedProducts]
  )
  /** @type {Array<ProductCard>} */
  const items = activeProducts

  // console.log('ProductGrid', relatedProducts)

  return relatedProducts?.length > 0 ? (
    <Box
      className={`product-grid product-grid--${theme}`}
      sx={{
        '.card-grid__content .grid-container, .card-grid__content .grid-container-inner': {
          p: 0,
        },
      }}
    >
      <ViewProductListObserver
        items={items}
        item_list_name={item_list_name}
        item_list_id={item_list_name}
      >
        <CardGrid
          {...productGridProps}
          title={title || t('global:label.recommendedSolutions')}
          items={items}
          actionLabel={actionLabel}
          // @ts-ignore
          ctaLink={
            displayAllProductsButton
              ? { uri: productListing, title: t('global:button.viewAll') }
              : null
          }
          component={CardGrid.component.ProductCard}
          headingStyle="h2"
        />
      </ViewProductListObserver>
    </Box>
  ) : null
}

import { CheckoutPlacedMessage, OrderPlaced } from '@ggs/components/ecomm'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import React from 'react'

/**
 * @typedef {Object} OrderCompleteView
 * @property {import('@commercelayer/sdk').Order|null} order CL viewed order
 * @property {boolean|null} orderComplete CL viewed order
 */

/**
 * Checkout completed step.
 * @param {OrderCompleteView} props
 * @return {JSX.Element}
 */
export default function Complete({ orderComplete, order }) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          sx={{
            width: 856,
            maxWidth: '100%',
            mx: 'auto',
          }}
        >
          {order && orderComplete ? (
            <>
              <CheckoutPlacedMessage order={order}/>
              {/*{!isAuth() ? <CreateAccountBanner order={lastOrder}/> : null}*/}
              <OrderPlaced order={order} complete={orderComplete}/>
            </>
          ) : (
            <CircularProgress size={60}/>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

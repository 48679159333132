import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { forEach, isString } from 'lodash'

/**
 * @typedef {Object} I18nHook
 * @property {function} labelGroup
 * @property {function} t
 * @property {Array<string>} languages
 * @property {string} language
 * @property {function} changeLanguage
 */

/**
 * Internationalization hook, expose i18next function in SSR safe mode.
 * @return {I18nHook}
 */
export default function useI18n() {
  const { t, i18n } = useTranslation()
  const { navigate: i18navigate, language, languages, changeLanguage } = useI18next()

  /**
   * Turn a list of keys into valid labels.
   * @param {String} target
   * @param {Object} labels
   * @return {{}}
   */
  const labelGroup = (target, labels) => {
    // @ts-ignore
    const translated = {}
    forEach(labels, (options, key) => {
      // @ts-ignore
      const { fallback, fill = {} } = options || {}
      const resolvedFallback = isString(fallback) ? t(fallback, fill) : fallback
      const labelKey = `${target}.${key}`
      // @ts-ignore
      translated[key] = t(labelKey) !== labelKey ? t(labelKey, fill) : resolvedFallback
    })
    return translated
  }

  // console.log('language', language)

  return {
    // @ts-ignore
    t,
    labelGroup,
    language,
    languages,
    changeLanguage,
  }
}

import { useStoreSelectors } from '@ggs/components/ecomm/Checkout/useStoreSelectors'
import { getSearchParams } from '@ggs/utils'
import { useCurrentAudience } from '@ggs/components/hooks/useVisibility'
import { useStoreOrderMarket } from '@ggs/components/ecomm/Checkout/useStoreOrderMarket'
import { useResolveUserOptions } from '@ggs/components/ecomm/hooks'
import { useGenerateLanguage } from '@ggs/components/layout/LanguageSelector'
import { useLayoutEffect } from 'react'
import { navigate } from 'gatsby'

export const useAudienceLangStoreOptions = () => {
  const {
    settings,
    stores,
  } = useStoreSelectors()
  const { country: userCountry } = settings

  const qs = getSearchParams()

  const currentAudience = useCurrentAudience()
  useStoreOrderMarket()

  useResolveUserOptions({
    stores,
    userCountry
  })

  const { selectLanguage, shouldDisplay, targetPage, url } = useGenerateLanguage()

  useLayoutEffect(() => {
    const langCodeParams = qs.get('langCode')
    if (langCodeParams) {
      selectLanguage({ langCode: langCodeParams }) && navigate(url(langCodeParams))
    }
  }, [])

  return {
    currentAudience
  }
}
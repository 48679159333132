/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 * @typedef {import('dayjs').Dayjs} Dayjs
 */

/**
 * @typedef {Object} drupalLanguage
 * @property {string} langCode
 * @property {string} name
 * @property {number} weight
 */

/**
 * @typedef {Object} drupalStore
 * @property {string} lang
 * @property {string=} url
 */

/**
 * @typedef {Object} Globals
 * @property {string} SITE_URL
 * @property {string[]} LANG_CODES
 * @property {drupalLanguage[]} LANGUAGES
 * @property {drupalStore[]} STORES
 */

///****************************************************************
/// COMPONENTS
///****************************************************************

/**
 * ButtonVariant
 * @enum {string}
 */
export const ButtonVariant = {
  contained: 'contained', // Primary
  outlined: 'outlined', // Secondary
  text: 'text', // Link style
  icon: 'icon', // Icon only
  tooltip: 'tooltip',
}

/**
 * @enum {string}
 * @type {{default: string, listing: string, condensed:string}}
 */
export const CardVariant = {
  default: 'default',
  listing: 'listing',
  condensed: 'condensed',
}

/**
 * @typedef {Object} GridContainer
 * @property {String=} className
 * @property {Boolean=} useDivider
 * @property {JSX.Element=} children Child components elements
 * @property {string=} backgroundColor Color hex code
 * @property {boolean=} fullWidthBackground Flag to indicate if background
 *   should extend full width
 * @property {SxProps=} sx
 */
/**
 * @typedef {Object} DrupalFile
 * @property {Number=} id
 * @property {String=} name
 * @property {String=} summary
 * @property {Array<{name: String, tid: Number}>=} category
 * @property {{url: string}} fieldMediaFile
 * @property {String} url
 */
/**
 * @typedef {Object} DrupalCalendarDayRaw
 * @property {Number} start
 * @property {Number} end
 * @property {Number} duration
 * @property {Number} repeatId
 * @property {Number} repeatIndex
 * @property {String=} timezone
 */
/**
 * @typedef {Object} DrupalCalendarDay
 * @property {Dayjs} start
 * @property {Dayjs} end
 * @property {Number} duration
 * @property {Number} repeatId
 * @property {Number} repeatIndex
 * @property {String} timezone
 */

///****************************************************************
/// TAXONOMIES
///****************************************************************
/**
 * @typedef {Object} Color
 * @property {String} color
 * @property {String} opacity
 */
/**
 * @typedef {Object} ProductUse
 * @property {Number} tid
 * @property {String} name
 */
/**
 * @typedef {Object} ProductCategory
 * @property {Number} tid
 * @property {String} name
 */
/**
 * @typedef {Object} ProductUsage
 * @property {Number} tid
 * @property {String} name
 */

/**
 * @typedef {Object} CustomerGroup
 * @property {Number} tid
 * @property {String} name
 * @property {Boolean} enabled
 * @property {String=} description
 */

/**
 * @typedef {Object} Market
 * @property {Number} tid
 * @property {Number} marketId
 * @property {CustomerGroup} customerGroup
 * @property {RegionReference} region
 */

/**
 * @typedef {Object} NewsAndEventCategory
 * @property {Number} tid
 * @property {String} name
 */

///****************************************************************
/// ARTICLES & EVENTS
///****************************************************************
/**
 * @typedef {Object} EventType
 * @property {Number} tid
 * @property {String} name
 */
/**
 * @typedef {Object} EventSpeaker
 * @property {String} name
 */
/**
 * @typedef {Object} EventCard
 * @property {String=} url
 * @property {String=} title
 * @property {String=} publishDate
 * @property {Array<NewsAndEventCategory>=} eventCategories
 * @property {ImageStyles=} eventImage
 * @property {DrupalCalendarDayRaw} eventDate
 * @property {Array<EventType>|EventType=} eventType
 * @property {String=} location
 * @property {{uri: String, url: string, title: String}} registerLink
 * @property {{uri: String, url: string, title: String}} recordingLink
 * @property {DrupalFile=} eventSummary
 * @property {CardVariant=} variant
 */
/**
 * @typedef {Object} EventBase
 * @property {String=} summary
 * @property {EventSpeaker} speaker
 * @property {Array<Object>=} content
 * @property {Array<ProductCard>=} relatedProducts
 */
/**
 * @typedef {EventCard & EventBase} Event
 */
/**
 * @typedef {Object} ArticleCard
 * @property {String=} id
 * @property {String=} type
 * @property {String=} url
 * @property {String=} title
 * @property {Array<NewsAndEventCategory>=} articleCategories
 * @property {String=} summary
 * @property {ImageStyles=} articleImage
 * @property {String=} publishedDate
 * @property {CardVariant=} variant
 */
/**
 * @typedef {Object} ArticleBase
 * @property {Array<Object>=} content
 * @property {Array<ArticleCard>=} relatedArticles
 * @property {Array<ProductCard>=} relatedProducts
 */
/**
 * @typedef {ArticleCard & ArticleBase} Article
 */
///****************************************************************
/// NAVIGATION
///****************************************************************
/**
 * @typedef {Object} LinkField
 * @property {String|JSX.Element|Element=} title
 * @property {String=} url
 * @property {String=} uri
 */
///****************************************************************
/// MEDIA
///****************************************************************
/**
 * @typedef {import('gatsby-plugin-image')} ImageDataLike
 */
/**
 * @typedef {Object} DrupalImage
 * @property {String} alt
 */
/**
 * @typedef {String|'medium'|'large'|'productMedium'|'articleMedium'|'heroMedium'}
 *   ImageStyle
 */
/**
 * @typedef {Object} GatsbyImageStyles
 * @property {String=} alt
 * @property {ImageDataLike=} quoteLarge
 * @property {ImageDataLike=} quoteSmall
 * @property {ImageDataLike=} quoteInModalLargest
 * @property {ImageDataLike=} pageHeroFull
 * @property {ImageDataLike=} pageHeroThumbnail
 * @property {ImageDataLike=} pageHeroFeatured
 * @property {ImageDataLike=} pageHeroFeaturedSmall
 * @property {ImageDataLike=} pageHeroRelated
 * @property {ImageDataLike=} inlineImageFull
 * @property {ImageDataLike=} inlineImageSmall
 * @property {ImageDataLike=} productFull
 * @property {ImageDataLike=} productGalleryThumbnail
 * @property {ImageDataLike=} productCartThumbnail
 * @property {ImageDataLike=} productCard
 * @property {ImageDataLike=} menuFaqIcon
 * @property {ImageDataLike=} icon
 * @property {ImageDataLike=} heroImage
 * @property {ImageDataLike=} heroImageLarge
 * @property {ImageDataLike=} eventSpeaker
 * @property {ImageDataLike=} ctaImageHighlight
 * @property {ImageDataLike=} ctaImageFull
 * @property {ImageDataLike=} ctaImageSmall
 * @property {ImageDataLike=} searchThumbnail
 */
/**
 * @typedef {Object} ImageStyles
 * @property {GatsbyImageStyles=} styles Collection of image styles from the
 *   data source
 * @property {Array<ImageStyle>=} selectedStyles When provided, will limit which
 *   image styles are rendered
 * @property {"eager"|"lazy"|undefined=} loading
 */
///****************************************************************
/// PRODUCTS
///****************************************************************

/**
 * @typedef {Object} ProductOptionSku
 * @property {String} sku
 * @property {Partial<Market>} market
 * @property {Boolean} enabled
 */

/**
 * @typedef {Object} ProductOption
 * @property {String=} id
 * @property {String=} label
 * @property {Boolean=} included
 * @property {String=} productDescription
 * @property {Array<ImageStyles>=} productImages
 * @property {Array<ProductOptionSku>} sku
 * @property {string} marketId
 * @property {Color=} color
 * @property {string} url Product detail URL
 */
/**
 * @typedef {String|'details'|'cart'|'include'} ProductOptionVariant
 */

/**
 * @typedef {Object} RegionReference
 * @property {Number=} tid Region taxonomy ID
 * @property {Number=} entityId Region taxonomy ID
 * @property {String} name
 */

/**
 * @typedef {Object} Region
 * @property {string} label
 * @property {Number} entityId Region paragraph ID
 * @property {Array<Market>} markets
 */

/**
 * @typedef {Array<Region>} RegionList
 */

/**
 * @typedef {Object} StoreLocaleName
 * @property {string} name Store localized name
 * @property {string} langCode Localized name language code
 */

/**
 * @typedef {Object} StoreLanguage
 * @property {string} id
 */

/**
 * @typedef {Object} Store
 * @property {String} entityId
 * @property {StoreLanguage} language
 * @property {String} name
 * @property {Array<StoreLocaleName>} localeNames
 * @property {String=} url The store homepage URL, found only in the layoutMeta
 * @property {String} storeAlias The stores unique alias
 * @property {Region} defaultRegion
 * @property {Array<Market>} markets
 * @property {Array<Region>} regions
 * @property {Array<{iso2: String}>} country
 * @property {Boolean=} ecommEnabled
 * @property {Number=} hcpCustomerRole
 * @property {Boolean=} hcpGatedContent
 * @property {Boolean=} storeDisplayed
 * @property {String} officeName
 * @property {String} officePhone
 * @property {String} officeEmail
 * @property {String} officeAddress
 * @property {String} supportPhone
 * @property {String} supportEmail
 * @property {ImageStyles} logo
 * @property {String} footerCopyright
 */

/**
 * @typedef {Object} SalableProduct
 * @property {Number} id
 * @property {Number} entityId
 * @property {String} url
 * @property {String} name
 * @property {Array<Store>=} stores
 * @property {ImageStyles} listingImage
 * @property {ProductOption} defaultOption
 * @property {Array<ProductOption>=} productOptions
 */
/**
 * @typedef {Object} ProductCardBase
 * @property {String=} subtitle
 * @property {String=} listingDescription
 * @property {Array<ProductUse>=} productUse
 * @property {Array<ProductCategory>=} productCategories
 */
/**
 * @typedef {SalableProduct & ProductCardBase} ProductCard
 */

/**
 * @typedef {Object} WhereToBuyItem
 * @property {string} id
 * @property {string} title
 * @property {string} textContent
 * @property {string} type
 * @property {Store} store
 */

/**
 * @typedef {Array<WhereToBuyItem>} WhereToBuy
 */

/**
 * @typedef {Object} ProductBase
 * @property {Array<SalableProduct>=} frequentlyBought
 * @property {WhereToBuy=} whereToBuy
 * @property {String=} optionsLabel
 * @property {String=} optionsFooter
 * @property {{tid: number, name: string}=} reminderProgram
 * @property {FormParagraph=} contactARep
 * @property {Array<String>=} includedItems
 * @property {Array<ProductCard>=} optionalProducts
 * @property {Array<Object>=} productDetails
 * @property {Array<ProductCard>=} relatedProducts
 */
/**
 * @typedef {SalableProduct & ProductCard & ProductBase} Product
 */

///****************************************************************

///****************************************************************
/// E-COMMERCE
///****************************************************************

/**
 * @typedef {import('@commercelayer/sdk').CustomerGroup} CLCustomerGroup
 * @typedef {import('@commercelayer/sdk').CustomerAddress} CLCustomerAddress
 * @typedef {import('@commercelayer/sdk').CustomerPaymentSource}
 *   CLCustomerPaymentSource
 * @typedef {import('@commercelayer/sdk').Order} CLOrder
 */

/**
 * @typedef {Object} CustomerMetaData
 * @property {string} first_name
 * @property {string} last_name
 */

/**
 * @typedef {Object} Customer Simplified customer entity
 * @property {string} email
 * @property {number} customerType Customer group taxonomy term ID (TID)
 * @property {string=} status
 * @property {CLCustomerGroup=} customer_group
 * @property {Array<CLCustomerAddress>=} customer_addresses
 * @property {Array<CLCustomerPaymentSource>=} customer_payment_sources
 * @property {Array<CLOrder>=} orders
 * @property {CustomerMetaData=} metadata
 */

/**
 * @typedef {Object} OptionalProductCartItem
 * @property {string} sku_code
 * @property {number} quantity
 * @property {Product} product
 * @property {ProductOption} productOption
 */

/**
 * @typedef {Object} OrderMarketRegion
 * @property {Region|null} region Initiator region (from where order was created)
 * @property {Market|null} market Initiator market
 * @property {string} storePath Initiator store URL path
 */

///****************************************************************

///****************************************************************
/// Drupal Menu Link
///****************************************************************
/**
 * @typedef {Object} DrupalMenuLink
 * @property {number} id
 * @property {string=} uuid
 * @property {string} label
 * @property {Array<DrupalMenuLink>=} menuItems
 * @property {string} url
 * @property {ImageStyles=} icon
 * @property {boolean} megaMenu
 * @property {string} footerContent
 */
///****************************************************************

///****************************************************************
/// Component
///****************************************************************

/**
 * @typedef {Object} SettingsProps
 * @property {boolean|null=} include
 * @property {String=} visibility
 */

/**
 * @typedef {Object} ComponentProps
 * @property {string=} type
 * @property {String=} id
 * @property {string=} variant
 * @property {function=} openModal
 * @property {string=} wrapperClassName
 * @property {SettingsProps=} settings
 */
///****************************************************************

///****************************************************************
/// CarouselItem
///****************************************************************
/**
 * @typedef {Object} CarouselItem
 * @property {string} type
 * @property {string=} id
 * @property {string} title
 * @property {string} subtitle
 * @property {LinkField} link
 * @property {ImageStyles=} heroImage
 * @property {string} variant
 */
///****************************************************************

///****************************************************************
/// CustomerForm
///****************************************************************

/**
 * @typedef {Object} CustomerModal
 * @property {number} id
 * @property {string=} formEmbed
 * @property {string=} textContent
 * @property {{tid:Number,name:String}} customerGroup
 */

/**
 * @typedef {Object} CustomerForm
 * @property {number} id
 * @property {string} formEmbed
 * @property {{tid:Number,name:String}} customerGroup
 */

/**
 * Form Variant
 * @enum {string}
 */
export const FormVariant = {
  default: 'default',
  selectGroup: 'select-group',
}

/**
 * @typedef {Object} FormParagraph
 * @property {String=} title
 * @property {Array<CustomerForm>=} customerForms
 * @property {FormVariant=} variant
 */
///****************************************************************

///****************************************************************
/// InfoIcon
///****************************************************************
/**
 * @typedef {Object} InfoIcon
 * @property {number} id
 * @property {string} label
 * @property {ImageStyles=} icon
 */
///****************************************************************

///****************************************************************
/// Faq
///****************************************************************
/**
 * @typedef {Object} Faq
 * @property {number} id
 * @property {string} title
 * @property {string} textContent
 * @property {string} type
 */
///****************************************************************

///****************************************************************
/// FaqCategory
///****************************************************************
/**
 * @typedef {Object} FaqCategory
 * @property {String=} faqCategory
 * @property {ImageStyles=} icon
 * @property {Number=} id
 * @property {Array<Faq|SharedContent>=} faqs
 */
///****************************************************************

///****************************************************************
/// SharedContent
///****************************************************************
/**
 * SharedContent component.
 * @typedef {Object} SharedContent
 * @property {string} type
 * @property {Number=} id
 * @property {{content: Array<Object>}=} props
 * @property {Array<Object>=} content
 */
///****************************************************************

///****************************************************************
/// Meta Data
///****************************************************************
/**
 * @typedef {Object} LanguageDefinition
 * @property {string} langCode Language code of the language
 * @property {string} name Language name
 * @property {number} weight Weight (order) of the language in Drupal
 */

/**
 * @typedef {Array<LanguageDefinition>} Languages
 */

/**
 * @typedef {Object} MetaTagsItem
 * @property {string} key Meta tag name or property key
 * @property {string} value Meta tag value
 */

/**
 * @typedef {Object} MetaCanonicalItem
 * @property {('canonical'|'alternate')} rel Meta link reference type
 *   (canonical|alternate)
 * @property {string} url Link reference URL
 * @property {string} lang Link reference language code
 */

/**
 * @typedef {Object} MetaStoreUrl
 * @property {string} url Link reference URL
 * @property {number} storeId Link store the URL belongs to
 */
/**
 * @typedef {Object} SiteNotices
 * @property {string} sitewideAnnoucement
 * @property {string} sitewideAnnouncementStyle
 * @property {string} hcpChangeProfile
 * @property {string} hcpGatedModal
 * @property {string} storeRegionSelector
 */
/**
 * @typedef {Object} LayoutMetaSupportingLink
 * @property {string} uri
 */

/**
 * @typedef {Object} LayoutMetaSupporting
 * @property {LayoutMetaSupportingLink} productListingAlias
 * @property {LayoutMetaSupportingLink} cartAlias
 * @property {LayoutMetaSupportingLink} checkoutAlias
 */

/**
 * @typedef {Object} LayoutMetaLinks
 * @property {any} main
 * @property {any} footer
 * @property {any} socialLinks
 * @property {{orderProcessing: LayoutMetaSupporting}} supporting
 */

/**
 * @typedef {Object} LayoutMeta
 * @property {boolean=} skip Whether or not to skip this node on build
 * @property {LayoutMetaLinks} menus
 * @property {string=} lang The current language
 * @property {Store=} currentStore The current store
 * @property {Array<Store>} stores Array of all stores
 * @property {Object} regions The selection of regions for all stores
 * @property {Object=} listing
 * @property {Array<CustomerGroup>} customerGroups All available customer groups
 * @property {Array<MetaCanonicalItem>=} canonicalUrls Array of canonical URLs
 *   for the current page
 * @property {Array<MetaStoreUrl>=} pageUrlForStores Array of store URLs for the
 *   current page
 * @property {SiteNotices} siteNotices
 */

export {}

import React from 'react'
import { delay, find, keyBy, sortBy } from 'lodash'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { setCurrentMarket, useSelector } from '@ggs/store'
import { Button } from '@ggs/gatsby/components/nav'
import { useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { getWindow } from '@ggs/utils'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
// Assets
import './StoreRegionSelect.scss'
import { useLink } from '@ggs/gatsby/lib'
import { resolveRegionMarket } from '@ggs/gatsby/lib/storeRegion'

/**
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 * @typedef {import('@ggs/types').Store} Store
 * @typedef {import('@ggs/types').Market} Market
 * @typedef {import('@ggs/types').Region} Region
 * @typedef {import('@ggs/types').Customer} Customer
 */

/**
 * Store / region selector UI.
 * @param {LayoutMeta} layoutMeta
 * @return {JSX.Element}
 */
export default function StoreRegionSelect({ layoutMeta }) {
  const { stores, pageUrlForStores } = layoutMeta
  const safeUrl = useLink(true, false)
  const customer = useSelector((state) => state.commerce.customer)
  const dispatch = useDispatch()
  const pathname = getWindow('location.pathname')
  const storesIndex = keyBy(stores, 'entityId')
  const defaultLangCode = 'en-CA'
  const defaultStoreLanguage = {
    uk: 'en-GB',
    ca: defaultLangCode,
    global: defaultLangCode,
  }

  /**
   * Dispatch action to set current market.
   * @param {Market} market Market to set as current
   * @param {Region} region Store region
   * @param {String} storeId ID of target store
   */
  const handleSetMarket = (market, region, storeId) => {
    // console.log('handleSetMarket', market, storeId)
    if (market) {
      dispatch(setCurrentMarket(market, region))
      // dispatch(addNotification(
      //   settingsActionType.SET_CURRENT_MARKET,
      //   'success',
      //   `Current Market changed to ${market.marketId}`
      // ))
    }

    /**
     * @type {Store}
     */
    const currentStore = storesIndex[storeId]
    const storeAlias = currentStore?.storeAlias || ''
    const currentStoreLanguage = defaultStoreLanguage[storeAlias]

    // After we've updated the market, hopefully successfully, switch to the new store page. If the store page is
    // missing for the current page, send users to the store homepage instead.
    // const { uri: newPageUrl } = safeUrl({
    //   href:
    //     find(pageUrlForStores, ['storeId', storeId])?.url
    //     || find(stores, ['entityId', storeId])?.url,
    // })
    const currentStoreUrl = currentStoreLanguage
      ? `/${storeAlias}/${currentStoreLanguage}`
      : find(pageUrlForStores, ['storeId', storeId])?.url || currentStore?.url
    const { uri: newPageUrl } = safeUrl({
      href: currentStoreUrl,
    })

    // If we're on a different page to where the new store page is, change to it. It's possible we're just changing
    // regions within the current store and thus should not change URLs.
    if (pathname !== newPageUrl) {
      delay(() => navigate(newPageUrl), 200)
    }
  }

  /**
   * Find store by ID from stores collection.
   * @param {Array<Store>} stores Stores to lookup
   * @param {String} storeId Store ID to find
   * @return {Store} Match store
   */
  const findStoreById = (stores, storeId) => {
    return stores.find((store) => {
      return store.entityId === storeId
    })
  }

  /**
   * Find region by label from regions collection.
   * @param {Array<Region>} regions Regions to lookup
   * @param {String} regionLabel Region label
   * @return {Region} Match region
   */
  const findRegionByLabel = (regions, regionLabel) => {
    return regions.find((region) => {
      return region.label === regionLabel
    })
  }

  /**
   * Prevent accordion open when button store select is clicked.
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const preventAccordionExpand = (e) => {
    e.preventDefault()
  }

  /**
   * Get data from event current target element.
   * @param {React.MouseEvent<HTMLButtonElement>} e
   * @param {String} dataKey Data attribute key
   * @return {String} Retrieved data attribute value
   */
  const getEventCurrentTargetData = (e, dataKey) => {
    const { currentTarget } = e
    return currentTarget.getAttribute(`data-${dataKey}`) || ''
  }

  /**
   * Handle store selection.
   * @param {React.MouseEvent<HTMLButtonElement>} e
   */
  const handleStoreSelect = (e) => {
    const storeId = getEventCurrentTargetData(e, 'id')
    const selectedStore = findStoreById(stores, storeId)
    const resolvedMarket = resolveRegionMarket(selectedStore?.defaultRegion?.markets, customer)
    // console.log('ResolvedMarket: ', resolvedMarket)
    handleSetMarket(resolvedMarket, selectedStore.defaultRegion, storeId)
  }

  /**
   * Handle store region selection.
   * @param {React.MouseEvent<HTMLButtonElement>} e
   */
  const handleStoreRegionSelect = (e) => {
    const storeId = getEventCurrentTargetData(e, 'id')
    const selectedStore = findStoreById(stores, storeId)
    if (!selectedStore) {
      return
    }

    const regionLabel = getEventCurrentTargetData(e, 'region')
    const selectedRegion = findRegionByLabel(selectedStore.regions, regionLabel)
    const resolvedMarket = resolveRegionMarket(selectedRegion.markets, customer)
    // console.log('SelectMarket: ', resolvedMarket)
    handleSetMarket(resolvedMarket, selectedRegion, storeId)
  }

  /**
   * Prevent default action.
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const handleSubmit = (e) => {
    preventAccordionExpand(e)
  }

  return (
    <div className="store-region-selector">
      <Box
        className={'stores-region-list'}
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {stores.map((store) => {
          // @ts-ignore
          const countryCode = store?.country?.length === 1 ? store?.country?.[0]?.iso2 : ''
          const countryFlagIcon = countryCode ? getUnicodeFlagIcon(countryCode) : null
          const regions = sortBy(store.regions || [], ['label'])
          const hasRegions = regions?.length > 0
          return (
            <Accordion key={store.entityId} square sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={hasRegions && <ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="store-selector"
                sx={{
                  'store-region__icon': {
                    display: 'inline-block',
                    mr: 1,
                  },
                }}
                disabled={!hasRegions}
              >
                <Button
                  className="store-region__select"
                  onClick={handleStoreSelect}
                  variant={Button.variant.text}
                  data-id={store.entityId}
                  // @ts-ignore
                  type="submit"
                >
                  <>
                    <span className="store-region__icon">{countryFlagIcon}</span>
                    <span className="store-region__label">{store.name}</span>
                  </>
                </Button>
              </AccordionSummary>
              {hasRegions && (
                <AccordionDetails>
                  <List>
                    {regions.map((region) => (
                      <ListItem key={region.label}>
                        <Button
                          className="store-region__select"
                          onClick={handleStoreRegionSelect}
                          variant={Button.variant.text}
                          data-id={store.entityId}
                          data-region={region.label}
                          // @ts-ignore
                          type="submit"
                        >
                          <span className="store-region__label">{region.label}</span>
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              )}
            </Accordion>
          )
        })}
      </Box>
    </div>
  )
}

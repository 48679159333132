// create and edit the CHECKOUT STEPS FLOW depending on the user's role (auth or
// guest)
import { stepIndexFrom, stepIsInFlow } from '@ggs/components/ecomm/Checkout/hooks/useStepFlow'
import { setCurrentStep, useSelector } from '@ggs/store'
import { getWindow } from '@ggs/utils'
import { isFunction, noop } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import CHECKOUT_STEP_ENUMS from '../enums/CHECKOUT_STEP_ENUMS'

// const { AUTH, COMPLETE, ORDER, PAYMENT, REVIEW } = CHECKOUT_STEP_ENUMS
const { COMPLETE, ORDER, PAYMENT, REVIEW } = CHECKOUT_STEP_ENUMS

/**
 * @typedef {Object} useStepperControlsParams
 * @property {Array<String>} stepFlow stepflow, array of all the steps
 */

/**
 * @typedef {Object} currentStepReducerArgs
 * @property {Array<String>} stepFlow stepflow, array of all the steps
 * @property {String} currentStepName
 * @property {Number} currentStepperIndex
 */

/**
 * Checkout in progress steps layout.
 * the users current progress through the checkout step flow.
 * @param {useStepperControlsParams} args
 * @return {any}
 */
export const useStepperControls = ({
  stepFlow,
}) => {
  const win = getWindow()
  // if there's an initial state inject it here.
  // link the checkout steps to the stepper index
  const dispatch = useDispatch()

  const {
    /** @type {string} currentStepName step name currently viewed */
    currentStepName,
    /** @type {number} currentStepperIndex step index currently viewed. */
    currentStepperIndex
  } = useSelector((state) => state.checkout)

  const scrollToTop = noop

  const {
    stepperIndex,
    nextStep,
    stepName,
    reset
  } = currentStepReducer({
    stepFlow,
    currentStepName,
    currentStepperIndex
  })

  const setStepper = {
    stepperIndex: (/** @type {number} */ index) => {
      dispatch(setCurrentStep(
        stepperIndex({
          index: index
        })))
      scrollToTop()
    },
    nextStep: () => {
      dispatch(setCurrentStep(nextStep()))
      scrollToTop()
    },
    stepName: (/** @type {string} */ STEP_NAME) => {
      dispatch(setCurrentStep(stepName(STEP_NAME)))
      scrollToTop()
    },
    reset: () => {
      dispatch(setCurrentStep(reset()))
      scrollToTop()
    },
  }

  const setStepperIndexFromStepName = (/** @type {string} */ STEP_NAME) => {
    // scrollToTop()
    setStepper.stepName(STEP_NAME)
  }

  const setStepperIndexTo = {
    // [AUTH]: () => setStepperIndexFromStepName(AUTH),
    [ORDER]: () => setStepperIndexFromStepName(ORDER),
    [PAYMENT]: () => setStepperIndexFromStepName(PAYMENT),
    [REVIEW]: () => setStepperIndexFromStepName(REVIEW),
    [COMPLETE]: () => setStepperIndexFromStepName(COMPLETE),
  }

  const goToPayment = () => {
    setStepperIndexTo[PAYMENT]()
    scrollToTop()
  }
  const goToOrder = () => {
    setStepperIndexTo[ORDER]()
    scrollToTop()
  }
  const goToReview = () => {
    setStepperIndexTo[REVIEW]()
    scrollToTop()
  }
  // const goToAuth = () => {
  //   setStepperIndexTo[AUTH]()
  //   scrollToTop()
  // }
  const goToComplete = () => {
    setStepperIndexTo[COMPLETE]()
    scrollToTop()
  }

  const getStepperIndexFromCheckoutStepName = (/** @type {string} */ STEP_NAME) =>
    stepIndexFrom({ STEP_NAME, stepFlow })

  const setStepperIndexToNextStepCb = (onNext = noop) => {
    setStepper.nextStep()
    isFunction(onNext) && onNext()
    scrollToTop()
  }
  // // whenever the step changes, scroll to top.
  // useEffect(() => {
  //   scrollToTop()
  //   setCurrentStepperIndex(() => {
  //     return stepIndexFrom({
  //       STEP_NAME: currentStepName,
  //       stepFlow,
  //     })
  //   })
  // }, [currentStepName])

  return {
    currentStepperIndex,
    setStepFromStepName: setStepperIndexFromStepName,
    handlers: {
      setStepper,
      getStep: () => currentStepperIndex,
      goToNextStep: setStepperIndexToNextStepCb,
      getStepperIndexFromCheckoutStepName,
      goToStep: (/** @type {number} */ stepIndex) => setStepper.stepperIndex(stepIndex),
      goToPayment,
      goToOrder,
      goToReview,
      // goToAuth,
      goToComplete
    },
  }
}

/**
 * Checkout in progress steps layout.
 * the users current progress through the checkout step flow.
 * @param {currentStepReducerArgs} args
 * @return {any}
 */
const currentStepReducer = ({
  stepFlow,
  currentStepperIndex,
  currentStepName
}) => ({
  stepperIndex: (/** @type {number} */ index) => {
    const validIndex = index < stepFlow.length ? index : currentStepperIndex
    return {
      currentStepName: stepFlow[validIndex],
      currentStepperIndex: validIndex
    }
  },
  // scrollToTop()
  nextStep: () => {
    const validNextStep = currentStepperIndex + 1 < stepFlow.length
      ? currentStepperIndex + 1 : currentStepperIndex
    return {
      // this has error handling
      currentStepName: stepFlow[validNextStep],
      currentStepperIndex: validNextStep,
    }
  },
  stepName: (/** @type {string} */ STEP_NAME) => stepIsInFlow({
    STEP_NAME,
    stepFlow
  })
    ? ({
      currentStepName: STEP_NAME,
      currentStepperIndex: stepIndexFrom({
        STEP_NAME,
        stepFlow
      })
    }) : ({
      currentStepName: currentStepName,
      currentStepperIndex: currentStepperIndex,
    }),
  reset: () => ({
    // TODO: needs logic or extension for auth / guest flow.
    currentStepName: ORDER,
    currentStepperIndex: 0,
  })
})

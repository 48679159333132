/* eslint-disable max-len */
import React from 'react'
import { get, merge } from 'lodash'
import { addOrderItems, clClientFactory, clearActiveOrder, createOrder, orderActionType } from '@ggs/commercelayer'
import { useOrderLookup, useSkuLookup } from '@ggs/components/ecomm/hooks'
import { composeOrderItemMetadata, useI18n } from '@ggs/gatsby/lib'
import {
  addNotification,
  resetCheckoutState,
  setProductLocalCart,
  showSidebar,
  useDispatch,
  useSelector,
} from '@ggs/store'
import { formatMetaData } from '@ggs/components/ecomm/ProductPurchaseOptions/useCurrentMetadata'
import { setProductTrans } from '@ggs/store/actions/views'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'
import useProductPrice from '@ggs/components/ecomm/ProductPrice/useProductPrice'
import { productValueQuantityGTM } from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/mapItemsToGTM'
/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 * @typedef {import('@ggs/types').WhereToBuy} WhereToBuy
 * @typedef {import('@ggs/types').Product} Product
 * @typedef {import('@ggs/types').OptionalProductCartItem}
 *   OptionalProductCartItem
 */

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 * @typedef {import('@ggs/types').WhereToBuy} WhereToBuy
 * @typedef {import('@ggs/types').Product} Product
 * @typedef {import('@ggs/types').OptionalProductCartItem}
 *   OptionalProductCartItem
 */

/**
 * @typedef {Object} ProductPurchaseOptions
 * @property {Product} product
 * @property {ProductOption|null} currentOption
 * @property {Array<String>=} includedItems
 */

/**
 * @typedef {import('@commercelayer/sdk').LineItemCreate} LineItemCreate
 */

/**
 Will be provided a list of salable SKUs, whereToBuy modal(s), and possibly a contactRep modal (or it should be just a link) displayed only when users are HCP
 We will need to get the form workspace up to snuff to support just the select field, and add to cart button with dynamic validation.
 Simple use cases:
 1. We have a single product option, with no optional products available.
 In this case, we just use the SKU for the current store/market, and display the price, with a quantity selector.
 CL will container some custom meta data for the SKUs min/max amounts, which will have to inform the quantity selector.
 2. The product has no salable SKU, and thus cannot be purchased
 In this case, the user can only use the whereToBuy, and sees a simple notice of unavailable.
 Complex use cases:
 1. There are multiple product options
 This component here will need to preload all SKU prices from all of the options up front
 On load it would need to display, while no option is selected and we're on default, to use the lowest price found, prefixed with a 'staring from' UI label
 The use will not be able to select a quantity or click add to cart until the first select a product option
 Once they do select a product option, the SKU/price will update to reflect the chosen option, and they can now add to cart
 This selection will use the meta data from the SKU price list entry to limit the min/max amount
 2. When there are optional products included, and the current product is not salable:
 In this case we can display the optional items that go with this product, but only as simple line items
 From the optionalProducts data provided, we can generate the list with the product name/URL, and lowest starting price from options
 3. When there are multiple optional products, and the current product is salable:
 The normal product quantity/add to card will work as part complex case #1 above
 But, there could be additional cart line items that may be included when they add to card. These will only be actionable IF these products have a salable SKU in the current store/market.
 For the optional products in this scenario we must list the products with a checkbox, name, lowest price:
 a) The product has only 1 product option (default), and the product name will be clickable
 b) The product has multiple options, and there will be a label like 'select a size' or something for them click with the product name beside it
 When clicking option a) (single product option) as per above, a modal will open with:
 From the productCardContent fragment data, this product option will be able to display in the modal:
 * Name
 * Listing image
 * Listing description
 * Link to learn more
 * Price
 * Quantity min/max
 * Finally, they can add to the order
 When clicking option b) (multiple product options) this modal will have the same as a) above, with a option selector
 * This option selector will let the user first pick the product option they
 * desire for the product This will update the visible price, and min/max
 * amounts for quantity Finally, they can add to the order
 Once the user has added optional products, the list on the main product section will update to show their current selection, but nothing has yet been added to the cart.
 Finally, when the user is happy, they click add to card, and the main product, as well as optional products then all get added to the cart.
 * @param {ProductPurchaseOptions} props
 * @return {{addToCart: function}}
 */
export default function ProductPurchaseOptions(props) {
  const { product, currentOption, includedItems, alias } = props
  // const { clClient } = useCheckoutContext()
  // const { isAuth, customer } = useIsAuth()
  // const { optionalProducts } = product
  const commerce = useSelector((state) => state.commerce)
  const currentMarket = useSelector((state) => state.settings.currentMarket)
  const layoutMeta = useSelector(({ ui }) => ui?.layoutMeta)
  const optionalItems = useSelector((state) =>
    get(state.ui.localCart[String('/' + product.id)]?.optionalItems, String(product.id), [])
  )
  const { fireAddToCart } = useCheckoutAnalytics({ order: commerce.order })
  const dispatch = useDispatch()

  const { currentStore, canonicalUrls } = layoutMeta
  const { lookupProductOptionCurrentMarketSku, lookupProductOptionSku } = useSkuLookup()
  const { skuPrice } = useProductPrice({ currentOption })
  const { t } = useI18n()
  /**
   * @typedef {Object} LocalStateAction
   * @property {string} type
   */

  /**
   * @type {Array<OptionalProductCartItem>}
   */
  const defaultState = []
  const { lookupLineItem } = useOrderLookup()

  /**
   * Compose main and optional line items into CL line items array.
   * @param {LineItemCreate} mainItem Main product cart item
   * @param {Array<OptionalProductCartItem>} optionalItems Optional products
   *   cart items
   * @return {Array<LineItemCreate>} Combined items into single CL line items
   *   array.
   */
  const composeOrderItems = (mainItem, optionalItems) => {
    const clClient = clClientFactory.getClient()
    const productSku = lookupProductOptionSku(currentOption)
    const mainItemMetadata = merge(
      composeOrderItemMetadata(product, currentOption, includedItems),
      {
        metadata: {
          sku: productSku?.metadata,
        },
      }
    )

    const mainItemAugmented = {
      ...mainItem,
      ...mainItemMetadata,
    }

    const optionalItemsAugmented = optionalItems.map((optionalItem) => {
      const { sku_code, quantity, product, productOption } = optionalItem
      const productOptionSku = lookupProductOptionSku(productOption)
      const optionalItemMetadata = merge(
        composeOrderItemMetadata(product, productOption, includedItems),
        {
          metadata: {
            sku: productOptionSku.metadata,
          },
        }
      )

      return {
        order: clClient.orders.relationship(commerce.order),
        sku_code,
        quantity,
        ...optionalItemMetadata,
      }
    })

    if (optionalItemsAugmented.length > 0) {
      return [mainItemAugmented].concat(optionalItemsAugmented)
    }
    // store info we need locally about the product, suchas links to translations and optional items
    dispatch(setProductLocalCart(product.entityId, defaultState))
    dispatch(setProductTrans('/' + product.alias, defaultState, canonicalUrls))

    return [mainItemAugmented]
  }

  /**
   * Performs CL add order item to cart.
   * @param {number} quantity Order item quantity
   * @param {Product} product Product entity
   * @param {ProductOption} productOption Product option entity
   */
  const addToCart = (quantity, product, productOption) => {
    // console.log(commerce)
    const productOptionSku = lookupProductOptionCurrentMarketSku(productOption)

    if (!productOptionSku) {
      // console.log('Product option SKU not available')
      return
    }

    const clClient = clClientFactory.getClient()

    /**
     * @type {LineItemCreate}
     */
    const mainItem = {
      order: clClient.orders.relationship(commerce.order),
      sku_code: productOptionSku,
      quantity,
    }

    const orderItems = composeOrderItems(mainItem, optionalItems)

    /**
     * On success action callback.
     * @param {any} action
     */
    const onSuccess = (action) => {
      // resetAll()
      // dispatch(setCurrentStepByName(isAuth() ? ORDER : AUTH))

      // syncCheckoutAndStepperProgress()productOptionSku.productName = "NEBrhRmxXK"
      // resetAll()

      if (action.type === orderActionType.ADD_ORDER_ITEMS) {
        dispatch(
          addNotification(
            orderActionType.ADD_ORDER_ITEMS,
            'success',
            t('ecomm:cart.notification.itemAdded')
          )
        )
        dispatch(resetCheckoutState())
      }
    }

    /**
     * On success action callback.
     * @param {any} action
     */
    const onError = (action) => {
      // syncCheckoutAndStepperProgress()
      // resetAll()
      dispatch(clearActiveOrder())
      // if (action.type === orderActionType.ADD_ORDER_ITEMS) {
      dispatch(
        addNotification(
          orderActionType.ADD_ORDER_ITEMS,
          'error',
          t('ecomm:cart.notification.itemAddedError')
        )
      )
      // }
    }

    // Order don't exits, initialize and add items.
    if (!commerce.order) {
      dispatch(
        createOrder(
          clClient,
          orderItems,
          {
            metadata: {
              ...formatMetaData({ currentMarket, currentStore }),
            },
          },
          {
            onSuccess,
            onError,
          }
        )
      )
    } else {
      const finalOrderItems = orderItems.map((orderItem) => {
        const productOptionSku = lookupProductOptionSku(productOption)
        const existingLineItem = lookupLineItem(productOptionSku.code)
        return {
          ...orderItem,
          _update_quantity: !!existingLineItem,
        }
      })

      dispatch(
        addOrderItems(clClient, commerce.order, finalOrderItems, {
          onSuccess,
        })
      )
    }

    // Reset optional items state.
    if (optionalItems.length > 0) {
      dispatch(setProductLocalCart(product.entityId, defaultState))
      dispatch(setProductTrans('/' + product.alias, defaultState, canonicalUrls))
    }

    fireAddToCart({
      ...productValueQuantityGTM({ skuPrice, productOption, quantity }),
      order: commerce.order,
    })

    // Show mini cart sidebar.
    dispatch(showSidebar('cart'))
  }

  return {
    addToCart,
  }
}

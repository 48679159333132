import React, { useMemo } from 'react'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { Faq, Text, Title } from '@ggs/components/paragraphs'
import { ImpressionClickObserver } from '@ggs/components/ecommerceAnalytics'
// Assets.
import './FaqCategory.scss'
import Grid from '@mui/material/Grid'
import { compact, flatten, uniqueId } from 'lodash'
import { useI18n } from '@ggs/gatsby/lib'
import { ComponentSettings } from '@ggs/gatsby/components/Component'

/**
 *
 * @param {*} content
 * @return {*[]|null}
 */
const sharedContentFaq = (content) => {
  if (content?.[0]?.type === 'SharedContent') {
    return sharedContentFaq(content[0].content)
  }

  return Array.isArray(content)
    ? content.filter((/** @type {{ type: Faq|*; }} */ { type }) => {
        return type === 'Faq'
      })
    : []
}

/**
 *
 * @param {Faq[]} faqs
 * @param {string=} filterBy
 * @return {unknown[]}
 */
export const filterFaqs = (faqs, filterBy = '') =>
  compact(
    flatten(
      (faqs || []).map((/** @type {Faq|SharedContent} */ item) => {
        // @ts-ignore
        const { type, content = null } = item
        return type === 'Faq' ? item : sharedContentFaq(content) || null
      })
    )
  ).filter(
    (faq) =>
      !filterBy
       || (filterBy
        && (faq?.title?.toLowerCase()?.includes(filterBy)
       || faq?.textContent?.toLowerCase()?.includes(filterBy)))
  )

/**
 * @typedef {import('@ggs/types').Faq} Faq
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import('@ggs/types').FaqCategory} FaqCategory
 * @typedef {import('@ggs/types').SharedContent} SharedContent
 */
/**
 * @typedef {Object} FaqCategoryProps
 * @property {Number=} id
 * @property {String=} faqCategory
 * @property {ImageStyles=} icon
 * @property {Array<Faq|SharedContent>=} faqs
 * @property {string=} filterBy
 */

/**
 * FaqCategory component.
 * @param {FaqCategoryProps} props
 * @return {JSX.Element}
 */
export default function FaqCategory(props) {
  const { t } = useI18n()
  const { id, faqCategory = '', icon } = props
  // @ts-ignore
  const faqs = useMemo(() => filterFaqs(props.faqs, props.filterBy), [props.faqs, props.filterBy])

  const nestedSettings = props.faqs
    .filter((faq) => {
      return faq?.settings || faq?.entitySettingsValue
    })
    .map(({ settings = null, entitySettingsValue = null }) => ({
      entitySettingsValue,
      settings,
    }))

  return (
    <ComponentSettings
      settings={nestedSettings[0]?.settings}
      entitySettingsValue={nestedSettings[0]?.entitySettingsValue}
    >
      <ImpressionClickObserver>
        <div data-label={faqCategory} data-action-type="view-faq-category" className="faq-category">
          <Grid className="faq-category__head" container alignItems="center" spacing={3}>
            {icon ? (
              <Grid className="faq-category__icon" item xs={'auto'}>
                <ImageStyles {...icon} selectedStyles={['menuFaqIcon']} />
              </Grid>
            ) : null}
            {faqCategory ? (
              <Grid item xs={'auto'}>
                <Title title={faqCategory} style="h3" />
              </Grid>
            ) : null}
          </Grid>
          <div className={'faq-list'}>
            {Array.isArray(faqs) && faqs.length ? (
              faqs.map((/** @type {Faq} */ item) =>
                item?.title ? (
                  <Faq
                    title={item?.title}
                    key={uniqueId('faq-item')}
                    id={item?.id}
                    textContent={item?.textContent}
                  />
                ) : null
              )
            ) : (
              <Text textContent={t('global:label.noFaqsFound')} />
            )}
          </div>
        </div>
      </ImpressionClickObserver>
    </ComponentSettings>
  )
}

// Measure product detail views
// After clicking a product listing, a user might view the product details page.
// To measure product detail views, send a view_item event with the product details:
import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'

export const fireCustomEvent = ({ customEventName, dataLayer, ...args }) => {
  return pushDataLayer({
    eventName: customEventName,
    dataLayer: {
      // currency,
      ...dataLayer,
      // value,
      ...args,
      // items
    },
  })
}

// create custom event

const { createTheme } = require('@mui/material/styles')
const colors = require('./theme/colors')
const breakpoints = require('./theme/breakpoints')
const typography = require('./theme/typography')
const zIndex = require('./theme/zIndex')

/**
 * @typedef {import('@mui/material/styles').ThemeOptions}
 */

/**
 * Converts px to rems.
 * @param {Number} px
 * @param {Number=} base
 * @return {String}
 */
export const rem = (px, base = 16) => `${px / base}rem`

export const common = {
  borderLightGrey: `1px solid ${colors.ultraLightGrey}`,
  borderLightBlue: `1px solid ${colors.lightBlue}`,
  borderWhite: `1px solid ${colors.white}`,
}

const config = {
  spacing: 8,
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    action: {
      selected: colors.red,
    },
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.red,
    },
    warning: {
      main: colors.lighterRed,
    },
    error: {
      main: colors.red,
    },
  },
  typography: {
    fontFamily: ['HelveticaNeue', 'sans-serif'].join(','),
    [typography.largeHeading]: {
      fontSize: rem(48),
      fontWeight: 'bold',
      lineHeight: 1.25,
    },
    [typography.h2]: {
      fontSize: rem(32),
      fontWeight: 'bold',
      lineHeight: 1.25,
    },
    [typography.h3]: {
      fontSize: rem(24),
      fontWeight: 'bold',
      lineHeight: 1.33,
    },
    [typography.h4]: {
      fontSize: rem(20),
      fontWeight: 'bold',
      lineHeight: 1.2,
    },
    [typography.h6]: {
      fontSize: rem(12),
      fontWeight: 500,
      lineHeight: 1.5,
    },
    [typography.menuItemSmall]: {
      fontSize: rem(14),
      fontWeight: 'bold',
      lineHeight: 1.71,
    },
    [typography.menuItemRegular]: {
      fontSize: rem(16),
      fontWeight: 500,
      lineHeight: 1.5,
    },
    [typography.superHeading]: {
      fontSize: rem(56),
      fontWeight: 'bold',
      lineHeight: 1.14,
    },
    [typography.pRegular]: {
      fontSize: rem(15),
      fontWeight: 400,
      lineHeight: 1.5,
    },
    [typography.pBold]: {
      fontSize: rem(16),
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    [typography.pSmall]: {
      fontSize: rem(14),
      fontWeight: 'normal',
      lineHeight: 1.71,
    },
    [typography.paragraphLarge]: {
      fontSize: rem(20),
      fontWeight: 400,
      lineHeight: 1.6,
    },
    [typography.captionBold]: {
      fontSize: rem(12),
      fontWeight: 'bold',
      lineHeight: 1.33,
    },
    [typography.caption]: {
      fontSize: rem(12),
      fontWeight: 400,
      lineHeight: 1.33,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: rem(12),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1.2,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // No more ripple, on the whole application 💣!
        disableRipple: true,
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: colors.lightBlue,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          color: colors.black,
        },
        daySelected: {
          backgroundColor: colors.lightBlue,
        },
        dayDisabled: {
          color: colors.black,
        },
        current: {
          color: colors.black,
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
      },
    },
    MuiPickersModal: {
      styleOverrides: {
        dialogAction: {
          color: colors.black,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: colors.white,
          backgroundColor: colors.red,
          borderRadius: '2000px',
          gap: 1,
          '&[disabled]': {
            opacity: 0.4,
          },
          '&:hover, &:focus': {
            backgroundColor: colors.logoRead,
          },
          span: {
            textAlign: 'left',
          },
          '&, > span': {
            textTransform: 'none',
          }
        },
      },
      variants: [
        {
          props: { variant: /** @type {'text'} */ ('text') },
          style: {
            color: colors.black,
            background: 'none',
            boxShadow: 'none',
            span: {
              color: colors.black,
            },
            '&:hover, &:focus': {
              background: 'transparent',
            },
          },
        },
        {
          props: { variant: /** @type {'text'} */ ('textRed') },
          style: {
            color: colors.red,
            background: 'none',
            boxShadow: 'none',
            span: {
              color: colors.red,
            },
            '&:hover, &:focus': {
              background: 'transparent',
            },
          },
        },
        {
          props: { variant: /** @type {'outlined'} */ ('outlined') },
          style: {
            fontSize: rem(14),
            fontWeight: 800,
            color: colors.red,
            background: 'transparent',
            borderColor: colors.red,
            borderWidth: rem(2),
            paddingLeft: rem(24),
            paddingRight: rem(24),
            paddingTop: `${rem(10)} !important`,
            paddingBottom: `${rem(10)} !important`,
            span: {
              color: colors.red,
            },
            '&:hover, &:focus': {
              borderColor: colors.red,
              backgroundColor: colors.red,
              borderWidth: rem(2),
              color: colors.white,
            },
            '&:hover span, &:focus span': {
              color: `${colors.white} !important`,
            },
          },
        },
        {
          props: { variant: /** @type {'contained'} */ ('contained') },
          style: {},
        },
      ],
    },
  },
}

export { colors, breakpoints, typography, zIndex }

export default createTheme(config)

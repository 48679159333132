/* eslint-disable no-param-reassign */
import { isObject } from 'lodash'

/**
 *
 * Hashes the provided value as a string.
 * @param {*} s
 * @return {string}
 */
export default function hashValue(s) {
  // If s is an object, we need to convert to a string to be hashed accurately.
  if (isObject(s)) {
    s = JSON.stringify(s || '')
  }

  return s
    ? s
      .toString()
      .split('')
      .reduce((/** @type {number} */ a, /** @type {string} */ b) => {
        // eslint-disable-next-line no-param-reassign
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
      }, 0)
      .toString()
    : ''
}

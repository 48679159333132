// @ts-nocheck
import React, { useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { CardGrid } from '@ggs/components/grids'
import { useI18n } from '@ggs/gatsby/lib'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import { Title } from '@ggs/components/paragraphs'
import useFilterableProductListSx from './useFilterableProductListSx'
import useFilterableProductListState, { filterTypes } from './useFilterableProductListState'
import { Button } from '@ggs/gatsby/components/nav'
import { Accordion } from '@ggs/components/common'
import { useWindowSize } from '@ggs/hooks'
import { icons } from '@ggs/components'
import { shopOrLearnMore } from '@ggs/components/cards/ProductCard/ProductCard'
import { ViewProductListObserver } from '@ggs/components/hooks/ViewProductListObserver'

/**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 */
/**
 * @typedef {Object} FilterableProductList
 * @property {Array<ProductCard>} entries
 * @property {Boolean} desktopFilters
 * @property {Function} toggleDesktopFilters
 */
/**
 *
 * @param {FilterableProductList} props
 * @return {JSX.Element}
 */
export default function FilterableProductList({ entries, desktopFilters, toggleDesktopFilters }) {
  const { t } = useI18n()
  const windowSize = useWindowSize()
  const ref = useRef()
  const sx = useFilterableProductListSx()
  const { products, filters, selected, selectFilter } = useFilterableProductListState({ entries })
  const [categoryFilters, setCategoryFilters] = useState([])

  const resetFilters = () => {
    const { /** @type Element */ current = window?.document } = ref
    const checkboxes = current.querySelectorAll('.Mui-checked input')
    checkboxes.forEach((box) => box.click())
    setCategoryFilters([])
    // console.log('checkboxes', checkboxes)
  }

  const updateCategoryFilters = (
    /** @type {EventTarget & HTMLInputElement} */ target,
    /** @type {string} */ name
  ) => {
    if (!categoryFilters.includes(name)) {
      setCategoryFilters(categoryFilters.concat(name))
    } else if (categoryFilters.includes(name)) {
      setCategoryFilters(categoryFilters.filter((item) => item !== name))
    }
    selectFilter(filterTypes.categories, name)
  }

  // console.log('FilterableProductList', { products, filters, selected, entries })

  const productCategories = useMemo(
    () => (
      <ul className="product-listing__categories">
        {filters.categories.map((name) => (
          <li key={name}>
            <FormControlLabel
              control={
                <Checkbox
                  color={'secondary'}
                  checked={categoryFilters.includes(name) || false}
                  onChange={(e) => updateCategoryFilters(e.target, name)}
                />
              }
              label={`${name} (${filters.counts.categories[name]})`}
            />
          </li>
        ))}
      </ul>
    ),
    [categoryFilters, filters.categories, filters.counts.categories]
  )

  return (
    <Box
      ref={ref}
      className="filterable-product-list"
      data-desktop-filters-visible={desktopFilters}
      sx={sx}
    >
      <Grid container spacing={5}>
        <Grid
          item
          className="filterable-product-list__filters"
          xs={12}
          md={3}
          sx={{
            display: {
              md: !desktopFilters ? 'none' : 'block',
            },
          }}
        >
          <Title
            className="filterable-product-list__filters-label"
            title={t('ecomm:productListing.label.filterByUseCase')}
            style="h4"
          />
          <ul className="product-listing__usage">
            {filters.usage.map((name) => (
              <li key={name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={'secondary'}
                      // @ts-ignore
                      onClick={() => selectFilter(filterTypes.usage, name)}
                      // checked={selected.usage.includes(name)}
                    />
                  }
                  label={`${name} (${filters.counts.usage[name]})`}
                />
              </li>
            ))}
          </ul>
          <div className="filterable-product-list__category-list">
            <Title
              className="filterable-product-list__categories-label"
              title={t('global:label.productCategories')}
              style="h5"
            />
            {windowSize.belowMd ? (
              <div className="product-listing__mobile-filters">
                <Accordion
                  id="product-listing__categories"
                  label={
                    <span>
                      {`${t('global:label.productCategories')} (${selected.categories.length})`}{' '}
                      {icons.Filters}
                    </span>
                  }
                  content={productCategories}
                />
              </div>
            ) : (
              productCategories
            )}
            {(selected.categories.length > 0 || selected.usage.length > 0) && (
              <Button
                className="product-listing__reset-btn"
                onClick={resetFilters}
                variant={Button.variant.text}
              >
                {t('global:button.resetFilters')}
              </Button>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={windowSize.md && !desktopFilters ? 12 : 9}
          sx={{
            '.card-grid': {
              mx: {
                md: !desktopFilters ? 0 : 'inherit',
              },
              maxWidth: '100%',
            },
            '.card-grid, .product-card': {
              maxWidth: '100%',
            },
          }}
        >
          {products.length ? (
            <ViewProductListObserver
              items={products}
              item_list_name={'FilterableProductList'}
              item_list_id={'FilterableProductList-grid-id'}
            >
              <CardGrid
                items={products}
                component={CardGrid.component.ProductCard}
                actionLabel={shopOrLearnMore}
                resetHeight={{ desktopFilters }}
              />
            </ViewProductListObserver>
          ) : (
            // noProductsMatchYourSelectedFilters
            <Title title={t('global:label.noProductsFound')} style="h3" />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

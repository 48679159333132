import React, { useMemo } from 'react'
// Components
import MuiDialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// Assets
import './_Dialog.scss'
import icons from '@ggs/components/icons'
import { isFunction } from 'lodash'
import { Button } from '@ggs/gatsby/components/nav'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 * @typedef {import('@ggs/types').ButtonVariant} ButtonVariant
 * @typedef {('paper'|'body')} DialogScrollTypes
 */
/**
 * @typedef {Object} ModalButton
 * @property {string=} label
 * @property {ButtonVariant=} variant
 */
/**
 * @typedef {Object} ModalProps
 * @property {String|JSX.Element|function(ModalProps)=} title
 * @property {String=} titleStyle
 * @property {String=} subtitle
 * @property {String=} notice
 * @property {String|JSX.Element|function(ModalProps)=} content
 * @property {ModalButton=} submitButton
 * @property {ModalButton=} closeButton
 * @property {import('react').MouseEventHandler=} onSubmit
 * @property {import('react').MouseEventHandler=} onClose
 * @property {String=} className
 * @property {String=} submitError
 * @property {Object=} button
 * @property {Boolean=} closeOnSubmit
 * @property {import('@mui/system').Breakpoint=} maxWidth
 * @property {DialogScrollTypes=} scroll
 * @property {SxProps=} sx
 */
/**
 * @param {ModalProps} props
 * @return {JSX.Element}
 */
export default function Dialog(props) {
  const {
    title,
    titleStyle,
    subtitle,
    notice,
    content,
    submitButton,
    closeButton,
    onSubmit,
    onClose,
    className,
    submitError = '',
    maxWidth = 'lg',
    scroll = 'paper',
    ...modalProps
  } = props
  const fullWidth = true
  const open = true

  const sx = useMemo(() => {
    const styles = {
      ...props.sx,
      '.MuiDialog-container': {
        width: {
          md: scroll === 'paper' ? 844 : '100%',
        },
        margin: {
          md: '0 auto',
        },
        // @ts-ignore
        ...(props.sx?.['.MuiDialog-container'] || {}),
      },
      '.MuiPaper-root': {
        m: {
          xs: 2,
          md: 0,
        },
        maxHeight: {
          xs: '80vh',
          md: 'auto'
        },
        maxWidth: {
          xs: 'calc(100% - 32px)',
          md: 840,
        },
        minWidth: {
          xs: 'calc(100% - 32px)',
          md: 'auto',
        },
        py: {
          xs: 3,
          md: 8,
        },
        // @ts-ignore
        ...(props.sx?.['.MuiPaper-root'] || {}),
      },
    }

    if (!submitButton?.label) {
      // @ts-ignore
      styles['.dialog__actions'] = {
        display: 'none',
      }
    }

    return styles
  }, [props.sx, onSubmit])

  return (
    <MuiDialog
      {...modalProps}
      className={`dialog dialog--${scroll} ${className}`}
      open={open}
      fullWidth={fullWidth}
      // @ts-ignore
      onSubmit={onSubmit}
      maxWidth={maxWidth}
      sx={sx}
      scroll={scroll}
      onBackdropClick={onClose}
    >
      <IconButton aria-label="close" className="dialog__close" onClick={onClose} size="large">
        {icons.ModalClose}
      </IconButton>
      {notice && (
        <Typography variant="h3" className="dialog__notice" data-type={notice}>
          {notice}
        </Typography>
      )}
      {title && (
        <DialogTitle className={`dialog__title ${titleStyle}`}>
          {isFunction(title) ? title(props) : title}
        </DialogTitle>
      )}
      {subtitle && (
        <Typography variant="h3" className="dialog__subtitle">
          {subtitle}
        </Typography>
      )}
      <DialogContent className="dialog__content">
        {isFunction(content) ? content(props) : content}
      </DialogContent>
      <DialogActions className="dialog__actions">
        {onSubmit && submitButton?.label && (
          <Button
            className="dialog__submit-button"
            variant={submitButton?.variant || Button.variant.contained}
            onClick={onSubmit}
          >
            {submitButton.label}
          </Button>
        )}
        {onClose && closeButton?.label && (
          <Button className="dialog__close-button" variant={closeButton?.variant || Button.variant.outlined} onClick={onClose}>
            {closeButton.label}
          </Button>
        )}
      </DialogActions>
      {submitError && <div className="dialog__error">{submitError}</div>}
    </MuiDialog>
  )
}

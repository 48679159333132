import Box from '@mui/material/Box'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useWindowSize } from '@ggs/hooks'
import { ImageStyles } from '@ggs/gatsby/components/media'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
// Assets
import './ImageGallery.scss'
import { isEqual } from 'lodash'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import('@ggs/types').ImageStyle} ImageStyle
 */
/**
 * @typedef {Object} ImageGallery
 * @property {String=} className
 * @property {Array<ImageStyles>=} images
 * @property {ImageStyle} fullStyle
 * @property {ImageStyle} thumbnailStyle
 */
/**
 Custom component for an image gallery, or general images
 ProductHero will  pass down the images based on the selected option
 As the handler changes on the ProductHero, new images will be provided to this component
 * @param {ImageGallery} props
 * @return {JSX.Element}
 */
export default function ImageGallery({ className, images, fullStyle, thumbnailStyle }) {
  const windowSize = useWindowSize()
  const [mainImage, setMainImage] = useState(/** @type ImageStyles */ images?.[0] || null)
  const [source, setSource] = useState(images)
  const [selectedImage, setSelectedImage] = useState(0)
  const componentRef = useRef(null)

  /**
   * @param {string} cn
   * @return {JSX.Element}
   */
  const arrow = (cn) => {
    return (
      <div className={cn}>
        <div className="image-gallery__arrow-icon">
          <ChevronRightIcon/>
        </div>
      </div>
    )
  }

  const arrowLeft = arrow('image-gallery__arrow-prev')
  const arrowRight = arrow('image-gallery__arrow-next')

  const triggerMainImage = (key = 0) => {
    // @ts-ignore
    setMainImage(images[key])
    setSelectedImage(key)
  }

  const imageList = useMemo(() => {
    return (images || []).map((item, index) => {
      // Need to update logic for the image keys.
      const imageId = index
      return (
        <div
          key={imageId}
          className={`image-gallery__image-item ${selectedImage === imageId
            ? 'active'
            : ''}`}
        >
          <ImageStyles {...item} selectedStyles={[thumbnailStyle]}/>
        </div>
      )
    })
  }, [images, selectedImage])

  useLayoutEffect(() => {
    // Fix the issue after resize browser.
    if (componentRef?.current?.handleArrowClick) {
      componentRef?.current?.handleArrowClick()
    }
  }, [windowSize.width])

  useEffect(() => {
    if (!isEqual(source, images)) {
      setSource(images)
      triggerMainImage(0)
    }
  }, [images])

  if (!images || !images.length) {
    return null
  }

  return (
    <Box
      className={`image-gallery ${className}`}
      sx={{
        maxWidth: '100%',
      }}
    >
      <div className="image-gallery__images-container">
        <div className="image-gallery__main-image">
          <ImageStyles {...mainImage} selectedStyles={[fullStyle]}/>
        </div>
        <div className="image-gallery__image-list">
          {source.length > 1 && <ScrollMenu
            ref={componentRef}
            data={imageList}
            arrowLeft={arrowLeft}
            arrowRight={arrowRight}
            selected={selectedImage.toString()}
            onSelect={triggerMainImage}
            dragging={false}
            wheel={false}
            hideSingleArrow
            scrollToSelected
            alignCenter={false}
            disableTabindex
            useButtonRole={false}
          />}
        </div>
      </div>
    </Box>
  )
}

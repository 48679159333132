import CountryDivisionData from './data/CountryDivision.json'
import { mapValues, isArray, memoize } from 'lodash'

const getCountryDivisionsIndex = () => {
  return mapValues(CountryDivisionData, (countryDivision) => {
    const countryFormat = countryDivision?.format || ''
    const matches = countryFormat.matchAll(/[a-zA-Z0-9]+/gi)
    const divisionParts = []
    for (const match of matches) {
      if (isArray(match)) {
        divisionParts.push(match[0])
      }
    }

    return divisionParts
  })
}

export default memoize(getCountryDivisionsIndex)

import React, { useMemo } from 'react'
import { useLink } from '@ggs/gatsby/lib'
import { ImpressionClickObserver } from '@ggs/components/ecommerceAnalytics'
// Assets
import { icons } from '@ggs/components'
import './Link.scss'

/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 */
/**
 * @typedef {Object} LinkProps
 * @property {JSX.Element|String=} children
 * @property {LinkField=} link
 * @property {String=} className
 * @property {number=} tabindex
 * @property {String=} href
 * @property {Boolean=} ignoreImpression
 */
/**
 *
 * @param {LinkProps} props
 * @return {JSX.Element}
 */
export default function Link({
  children,
  link,
  className,
  href = '',
  ignoreImpression = false,
  ...linkProps
}) {
  const safeUrl = useLink(true)
  const {
    // isInternal,
    isExternal,
    uri,
    label,
    isAssetLink,
    isFileLink,
    fileName,
    fileExt,
  } = useMemo(() => safeUrl({ link, href }), [link, href])
  const displayLabel = label || children

  // if (!uri) {
  //   comp = <span {...linkProps}>{displayLabel}</span>
  // } else {
  if (isExternal || isAssetLink) {
    // Send off-site links to new tabs by default
    // @ts-ignore
    linkProps.target = '_blank'
    // @ts-ignore
    linkProps.rel = 'noopener noreferrer'
  }

  // If the link is a file, add/override the data attributes for analytics
  if (isFileLink) {
    linkProps['data-action-type'] = 'click-download'
    linkProps['data-file-name'] = fileName
    linkProps['data-file-ext'] = fileExt
  }

  // eslint-disable-next-line prefer-const
  return (
    <ImpressionClickObserver ignoreImpression={ignoreImpression}>
      <a {...linkProps} href={uri} className={className}>
        {displayLabel} {isExternal && typeof displayLabel === 'string' ? icons.OffsiteLink : null}
      </a>
    </ImpressionClickObserver>
  )
}

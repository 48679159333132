import React from 'react'

const expireCheckIntervalMinutes = 1
const expireCheckIntervalMs = 60000 * expireCheckIntervalMinutes

const currentMarketId = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`
if (!currentMarketId) {
  throw new Error(
    'Default market ID is undefined, please review [GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID] env variable'
  )
}

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 * @typedef {import('@ggs/types').OrderMarketRegion} OrderMarketRegion
 * @typedef {import('@ggs/types').Store} CurrentStore
 */

/**
 * @typedef {Object} GlobalActionsDispatcherProps
 * @property {Element|JSX.Element} children
 */

/**
 * Component responsible of application global actions initialization.
 * @param {GlobalActionsDispatcherProps} props
 * @return {JSX.Element}
 */
export function GlobalActionsDispatcher({ children }) {


  return <>{children}</>
}

// @ts-nocheck
/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 * @typedef {import('@commercelayer/sdk').ShippingMethod} ShippingMethod
 */

/**
 * @typedef {Object} OrderShippingLabels
 * @property {*} reference
 * @property {*|string} leadTime
 * @property {JSX.Element} costLabel
 * @property {JSX.Element} label
 * @property {JSX.Element} fulfillmentStatus
 */
import useCodeTranslationMap from '@ggs/components/hooks/useCodeTranslationMap'
import { numberFormatter } from '@ggs/utils'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import dayjs from 'dayjs'
import { useSelector } from '@ggs/store'
import { useLayoutMeta } from '@ggs/hooks'
import { decomposeLanguageLocaleCode } from '@ggs/utils/languagesDigest'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'

const calculateShippingDates = (
  shipping_times = {
    min_days: 0,
    max_days: 0,
    min_hours: 0,
    max_hours: 0,
  }
) => {
  const { min_days, max_days, min_hours, max_hours } = shipping_times
  if (min_days && max_days) {
    const day1 = dayjs().add(min_days, 'days')
    const day2 = dayjs().add(max_days, 'days')
    let format = 'MMMM Do'
    if (day1.year() !== day2.year()) {
      format += ' YYYY'
    }
    return { fromDate: day1.format(format), toDate: day2.format(format) }
  } else if (min_hours && max_hours) {
    return {
      fromDate: dayjs().add(min_hours, 'hours').format('MMMM Do LT'),
      toDate: dayjs().add(max_hours, 'hours').format('LT'),
    }
  }

  return {
    fromDate: null,
    toDate: null,
  }
}
/**
 * @typedef {Object} UseOrderLabelsProps
 * @property {OrderShippingLabels} shippingMethod
 * @property {OrderCostLabels} totals
 */

/**
 * @typedef {Object} useShippingMethodLabelMakerProps
 * @property {Order} order
 * @property {string} lang
 */

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {Order} order
 * @return {function(object=): {reference: string, costLabel: JSX.Element, rawLeadTime: *, shippingAmount: JSX.Element,
 *   leadTime: *|string, freeIfOverAmount: string|null, label}}
 */
export const useShippingMethodLabelMaker = (order) => {
  const mapCodeToTranslation = useCodeTranslationMap()
  const { lang } = useLayoutMeta()
  const currencyFormatter = numberFormatter.currency(order?.currency_code, lang)
  const { t } = useTranslation()
  const layoutMeta = useSelector((state) => state.ui.layoutMeta)
  const currentLanguage = decomposeLanguageLocaleCode(
    layoutMeta?.currentStore?.language?.id
  )
  // Localize the formatted dates in current store langauge.
  dayjs.locale(currentLanguage.langCode)

  // console.log('ShippingMethodForm option', {
  //   id,
  //   // shippingMethod,
  //   isFreeShipping,
  //   // label,
  //   costLabel,
  //   leadTime,
  //   // totals,
  // })
  // eslint-disable-next-line react/display-name
  return (
    shippingMethod = {
      id: null,
      reference: '',
      free_over_amount_cents: '',
      free_over_amount_float: '',
      currency_code: '',
      price_amount_float: '',
      delivery_lead_time_for_shipment: '',
    }
  ) => {
    if (!shippingMethod?.id) {
      return
    }

    const {
      reference,
      free_over_amount_cents,
      free_over_amount_float,
      currency_code,
      price_amount_float,
      delivery_lead_time_for_shipment,
    } = shippingMethod

    const isFreeShipping
      = free_over_amount_cents > 0 && order?.subtotal_amount_cents > free_over_amount_cents

    const { fromDate, toDate } = calculateShippingDates(delivery_lead_time_for_shipment)
    const shippingAmount = (
      <>
        {currencyFormatter(price_amount_float)} {currency_code}
      </>
    )
    // Check if we have a free
    const freeIfOverAmount
      = free_over_amount_cents > 0
        ? `(${t('global:label.freeIfOverAmount', {
          amount: currencyFormatter(free_over_amount_float),
        })})`
        : null
    const rawLeadTime = t('ecomm:label.expectedDelivery', { fromDate, toDate })
    const leadTime
      = rawLeadTime !== 'label.expectedDelivery'
        ? rawLeadTime
        : `Expected delivery: <strong>${fromDate} to ${toDate}</strong>`

    const costLabel = (
      <>
        {isFreeShipping ? (
          <>
            <s>{shippingAmount}</s>&nbsp;{t('global:label.free')}
          </>
        ) : (
          <>
            {shippingAmount} {freeIfOverAmount}
          </>
        )}
      </>
    )

    return {
      shippingAmount,
      reference,
      leadTime,
      freeIfOverAmount,
      rawLeadTime,
      label: (
        <>
          <p>
            {mapCodeToTranslation(reference?.toLowerCase())}: {costLabel}
          </p>
          {leadTime && (
            <p
              className={'shipping-method-option__lead-time'}
              dangerouslySetInnerHTML={{
                __html: leadTime,
              }}
            />
          )}
        </>
      ),
      costLabel,
    }
  }
}

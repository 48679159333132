import { CHECKOUT_STEPS } from '@ggs/components/ecomm/Checkout/enums/index'
import CHECKOUT_STEP_ENUMS from './CHECKOUT_STEP_ENUMS'

const { ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS

const {
  ORDER_STEP,
  PAYMENT_STEP,
  REVIEW_STEP,
  COMPLETE_STEP
} = CHECKOUT_STEPS

export const STEP_MAP = {
  [ORDER]: ORDER_STEP,
  [PAYMENT]: PAYMENT_STEP,
  [REVIEW]: REVIEW_STEP,
  [COMPLETE]: COMPLETE_STEP
}

export const STEP_FLOW = [ORDER, PAYMENT, REVIEW, COMPLETE]

/*
const { AUTH, ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS
const {
  AUTH_STEP,
  ORDER_STEP,
  PAYMENT_STEP,
  REVIEW_STEP,
  COMPLETE_STEP
} = CHECKOUT_STEPS

export const AUTH_STEP_FLOW = [ORDER, PAYMENT, REVIEW, COMPLETE]

export const GUEST_STEP_FLOW = [AUTH, ...AUTH_STEP_FLOW]

export const STEP_MAP = {
  [AUTH]: AUTH_STEP,
  [ORDER]: ORDER_STEP,
  [PAYMENT]: PAYMENT_STEP,
  [REVIEW]: REVIEW_STEP,
  [COMPLETE]: COMPLETE_STEP
}

*/
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// const { NODE_ENV } = require('./env')
const {
  onClientEntry,
  onInitialClientRender,
  // onPreRouteUpdate
  onRouteUpdate,
  wrapPageElement,
  wrapRootElement,
} = require('./gatsby/browser')

// exports.onPreRouteUpdate = onPreRouteUpdate
exports.onRouteUpdate = onRouteUpdate
exports.wrapRootElement = wrapRootElement
exports.wrapPageElement = wrapPageElement
exports.onInitialClientRender = onInitialClientRender
exports.onClientEntry = onClientEntry

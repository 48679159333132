import { useSelector } from '@ggs/store'

export const useStoreSelectors = () => {
  const layoutMeta = useSelector((state) => state.ui.layoutMeta)
  const settings = useSelector((state) => state.settings)
  const checkout = useSelector((state) => state.checkout)
  const commerce = useSelector((state) => state.commerce)
  const analytics = useSelector((state) => state.analytics)

  const { order, customer, token } = commerce
  const { currentStore, stores } = layoutMeta


  return {
    settings,
    stores,
    customer,
    checkout,
    currentStore,
    order,
    token,
    analytics
  }
}
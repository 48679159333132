import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import { useI18n } from '@ggs/gatsby/lib'
import { useQuantitySelect } from '@ggs/components/hooks'
import { useOrderLookup, useSkuLookup } from '@ggs/components/ecomm/hooks'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 */

/**
 * @typedef {Object} ExistingQuantityNoticeProps
 * @property {Number} existingQuantity
 * @property {Number} currentQuantity
 * @property {boolean} emptyQuantityOptions
 */

// eslint-disable-next-line valid-jsdoc
/**
 *
 * Determine if we need to provide a notice on quantity selection
 * @param {ProductOption} currentOption
 * @return {{emptyQuantityOptions: boolean, existingQuantity: number|number, currentQuantity: number,
 * notice: JSX.Element|null}}
 */
export default function useExistingQuantities(currentOption) {
  const { t } = useI18n()
  const { lookupProductOptionSku, lookupProductOptionCurrentMarketSku } = useSkuLookup()
  const { lookupLineItem } = useOrderLookup()
  const productOptionSku = lookupProductOptionCurrentMarketSku(currentOption)
  const currentLineItem = lookupLineItem(productOptionSku)
  const existingQuantity = currentLineItem?.quantity || 0
  const currentSku = currentOption ? lookupProductOptionSku(currentOption) : null
  const { currentQuantity, emptyQuantityOptions } = useQuantitySelect(
    currentSku,
    existingQuantity,
    null
  )

  const notice = useMemo(() => {
    let notice = null
    if (emptyQuantityOptions) {
      notice = (
        <Grid className={'existing-quantities'} item xs={12}>
          <p>{t('ecomm:cart.notice.allAvailableItemsInCart')}</p>
        </Grid>
      )
    } else if (existingQuantity > 0) {
      notice = (
        <Grid className={'existing-quantities'}item xs={12} marginTop={2}>
          <p>{t('ecomm:cart.notice.existingQuantityNotice', { existingQuantity })}</p>
        </Grid>
      )
    }
    return notice
  }, [existingQuantity, currentQuantity, emptyQuantityOptions])

  return { existingQuantity, currentQuantity, emptyQuantityOptions, notice }
}

import { useMemo } from 'react'
import { colors } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * @enum {string}
 * @type {{default: string, carousel: string}}
 */
export const ArticleHeroVariant = {
  default: 'default',
  carousel: 'carousel',
}

/**
 *
 * @param {ArticleHeroVariant|String=} variant
 * @return {SxProps}
 */
export default function useNewsAndEventsHeroSx(variant) {
  return useMemo(() => {
    const sx = {
      maxWidth: 856,
      mb: {
        xs: 3,
        md: 5,
      },
      mx: {
        lg: 'auto',
      },
      p: 0,
      '& p': {
        p: 0,
        m: 0,
      },
      '.gatsby-image-wrapper': {
        minHeight: {
          xs: 176,
          md: 'auto',
        },
        // For handling the image pulling out of the content container on this layout.
        mx: {
          md: -14,
        },
      },
      '.title__title': {
        mb: 4,
        mt: 0,
        pt: 0,
      },
    }

    switch (variant) {
      case 'eventCarousel':
        Object.assign(sx, {
          maxWidth: '100%',
          '&, & > div, .gatsby-image-wrapper': {
            minHeight: {
              xs: 200,
              md: 352,
            },
          },
          '.gatsby-image-wrapper': {
            // @ts-ignore
            ...sx['.gatsby-image-wrapper'],
            mx: 0,
            height: '100%',
            maxHeight: {
              xs: 200,
              md: 'auto',
            }
          },
          '.title': {
            mb: 4,
            textAlign: 'left',
          },
        })
        break
      case ArticleHeroVariant.carousel:
        Object.assign(sx, {
          maxWidth: '100%',
          '&, & > div, .gatsby-image-wrapper': {
            minHeight: {
              xs: 200,
              md: 352,
            },
          },
          '.gatsby-image-wrapper': {
            // @ts-ignore
            ...sx['.gatsby-image-wrapper'],
            mx: 0,
            height: '100%',
            maxHeight: {
              xs: 200,
              md: 'auto',
            }
          },
          '.title': {
            mb: 4,
            textAlign: 'left',
          },
          '.article-meta': {
            mb: {
              xs: 0,
              md: 2,
            },
          },
          '.article-hero__inner > div > div': {
            height: '100%',
          },
          '.article-hero__content': {
            p: 5,
            backgroundColor: colors.white,
          },
          '.button': {
            p: 0,
          },
        })
        break
    }

    return sx
  }, [])
}

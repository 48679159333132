import { CommerceLayerClient, Sku } from '@commercelayer/sdk'
import { getAllPageItems } from '../lib/pagination'
import { ThunkAction } from 'redux-thunk'
import { handleApiError } from './CommonActions'

/**
 * @typedef {import("@commercelayer/sdk/lib/cjs/resource").ListResponse<Sku>} CLSkuListResponse
 */

/**
 * SKU action types.
 * @enum {string}
 */
export const skuActionTypes = {
  GET_SKU_DATA: 'GET_SKU_DATA',
  GET_MARKET_SKUS_DATA: 'GET_MARKETS_SKU_DATA',
}

/**
 * @typedef {Object} SkuDataActionPayload
 * @property {Array<Sku>} skus SKUs retrieved data
 */

/**
 * @typedef {Object} SkuDataAction
 * @property {skuActionTypes} type Action type key
 * @property {SkuDataActionPayload} payload Action input data
 */

/**
 * Compose a SKU data get action.
 *
 * @param {skuActionTypes} actionType Action type
 * @param {*} skuData Commerce layer products data
 * @return {SkuDataAction} SKU get data action
 */
const composeDataAction = (actionType, skuData) => {
  return {
    type: actionType,
    payload: {
      skus: skuData,
    },
  }
}

/**
 * Get CL SKUs data for a list of SKU codes, max SKUs to get is 25.
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Array<string>} skus SKUs array
 * @return {ThunkAction.<void, any, null, SkuDataAction>} Get products data action thunk
 */
export const getSkuData = (clClient, skus) => {
  return async (dispatch) => {
    if (skus.length > 25) {
      throw new Error('The maximum SKUs items to query in one request is 25.')
    }

    try {
      const skusData = await clClient.skus.list({
        include: ['prices'],
        filters: {
          code_in: skus.join(','),
        },
        pageSize: 25,
      })

      dispatch(composeDataAction(skuActionTypes.GET_SKU_DATA, skusData))
    } catch (error) {
      handleApiError(dispatch, error)
    }
  }
}

/**
 * Get CL all SKUs data for current session.
 * Note: CL automatically filter SKUs that are associated to the selected market scope (in session).
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @return {ThunkAction.<void, any, null, SkuDataAction>} Get products data action thunk
 */
export const getAllSkusData = (clClient) => {
  return async (dispatch) => {
    /**
     * Get SKUs page items callback.
     * @param {number} pageNumber Page number
     * @return {Promise<CLSkuListResponse>}
     */
    const getDataCallback = async (pageNumber = 1) => {
      return await clClient.skus.list({
        include: ['prices'],
        pageSize: 25,
        pageNumber: pageNumber,
      })
    }

    try {
      const skusData = await getAllPageItems(getDataCallback)
      dispatch(composeDataAction(skuActionTypes.GET_MARKET_SKUS_DATA, skusData))
    } catch (error) {
      handleApiError(dispatch, error)
    }
  }
}

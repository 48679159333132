/**
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 * @typedef {import('@ggs/types').Store} Store
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

import { uiActionType } from '@ggs/store'

/**
 * Customer action types.
 * @enum {string}
 */
export const viewsActionType = {
  SET_ORDER_STATUS: 'SET_ORDER_STATUS',
  SET_PRODUCT_TRANS: 'SET_PRODUCT_TRANS'
}

/**
 * Compose customer action.
 *
 * @param {viewsActionType} actionType UI action type
 * @param {Boolean} visible Visibility flag state
 * @param {string} contentType Content type to display
 * @return {SetViewsOrdersAction}
 */

/**
 * @typedef {Object} SetViewsOrdersAction
 */
/**
 * Set current market action.
 *
 * @param {Order} order CL market
 * @return {{type: string, payload: Order}}
 */
export const setViewsOrders = (order) => {
  return {
    type: viewsActionType.SET_ORDER_STATUS,
    payload: order,
  }
}

/**
 * Set product local cart state.
 *
 * @param {String|Number} productAlias product path /meta data
 * @param {Array<OptionalProductCartItem>} optionalItems Product optional items
 * @param linkTranslations
 * @return {SetProductLocalCartAction} Action object.
 */
export const setProductTrans = (productAlias, optionalItems, linkTranslations) => {
  return {
    type: viewsActionType.SET_PRODUCT_TRANS,
    payload: {
      productId: String(productAlias),
      optionalItems,
      linkTranslations
    },
  }
}
import React from 'react'
import Grid from '@mui/material/Grid'
import { rem, typography } from '@ggs/styles'
import { GridContainer } from '@ggs/components/layout'

// Assets
import { colors } from '@ggs/styles'
import icons from '@ggs/components/icons'
import { Text } from '@ggs/components/paragraphs'

/**
 * @typedef {Object} TermsOfSaleProps
 * @property {String=} text
 */
/**
 * Shopping cart image ads block.
 * @param {TermsOfSaleProps} props
 * @return {JSX.Element}
 */
export default function TermsOfSale({ text = '' }) {
  return (
    <GridContainer
      className="terms-of-sale"
      sx={{
        backgroundColor: colors.white,
        borderRadius: `${rem(4)}`,
        mb: {
          xs: 2,
          md: 4,
        },
        p: {
          xs: 1,
          md: 2,
        },
      }}
    >
      <>
        <Grid
          className="terms-of-sale__inner"
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{
            px: {
              xs: 1,
              md: 2,
            },
            flexWrap: 'initial',
          }}
        >
          <Grid
            className="terms-of-sale__icon"
            item
            xs={12}
            alignItems="center"
            textAlign="center"
            sx={{
              boxSizing: 'content-box',
              flex: {
                xs: `0 0 ${rem(30)}`,
                md: `0 0 ${rem(44)}`,
              },
              '> svg': {
                maxWidth: {
                  xs: `${rem(30)}`,
                  md: `${rem(44)}`,
                },
                height: 'auto',
              },
            }}
          >
            {icons.Information}
          </Grid>
          <Grid
            className="terms-of-sale__content"
            item
            xs={12}
            sx={{
              flexBasis: 'auto',
              flex: '0 1 auto',
              '& .terms-of-sale__text': {
                typography: typography.pBold,
                '& p': {
                  m: 0,
                },
              },
            }}
          >
            <Text className="terms-of-sale__text" textContent={text} />
          </Grid>
        </Grid>
      </>
    </GridContainer>
  )
}

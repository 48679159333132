import React, { useRef } from 'react'
import Grid from '@mui/material/Grid'
import { Title } from '@ggs/components/paragraphs'
import { GridContainer } from '@ggs/components/layout'
import { Button } from '@ggs/gatsby/components/nav'
import { Component } from '@ggs/gatsby/components'
import { useMatchHeight } from '@ggs/hooks'
import { uniqueId } from 'lodash' // Assets
import './CardGrid.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
/**
 * @typedef {import("@mui/material/Grid").GridSize} GridSize
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import("@ggs/components/paragraphs/TextCard/TextCard").TextCardProps} TextCardProps
 * @typedef {import("@ggs/components/cards/ArticleCard/ArticleCard").ArticleCard} ArticleCard
 * @typedef {import('@ggs/types').GridContainer} GridContainer
 * @typedef {import("@ggs/components/paragraphs/Video/Video").VideoProps} VideoProps
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 * @typedef {import('@ggs/types').InfoIcon} InfoIcon
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import("@ggs/components/common/ImageTextItem/ImageTextItem").ImageTextItemProps} ImageTextItemProps
 */
/**
 * @typedef {import("@mui/material/Grid").GridSize} GridSize
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import("@ggs/components/paragraphs/TextCard/TextCard").TextCardProps} TextCardProps
 * @typedef {import("@ggs/components/cards/ArticleCard/ArticleCard").ArticleCard} ArticleCard
 * @typedef {import('@ggs/types').GridContainer} GridContainer
 * @typedef {import("@ggs/components/paragraphs/Video/Video").VideoProps} VideoProps
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 * @typedef {import('@ggs/types').InfoIcon} InfoIcon
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import("@ggs/components/common/ImageTextItem/ImageTextItem").ImageTextItemProps} ImageTextItemProps
 */

/**
 * @typedef {import("@mui/material/Grid").GridSize} GridSize
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import("@ggs/components/paragraphs/TextCard/TextCard").TextCardProps} TextCardProps
 * @typedef {import("@ggs/components/cards/ArticleCard/ArticleCard").ArticleCard} ArticleCard
 * @typedef {import('@ggs/types').GridContainer} GridContainer
 * @typedef {import("@ggs/components/paragraphs/Video/Video").VideoProps} VideoProps
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 * @typedef {import('@ggs/types').InfoIcon} InfoIcon
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import("@ggs/components/common/ImageTextItem/ImageTextItem").ImageTextItemProps} ImageTextItemProps
 */
/**
 * @enum {string}
 */
export const CardGridComponent = {
  TextCard: 'TextCard',
  ArticleCard: 'ArticleCard',
  ProductCard: 'ProductCard',
  Video: 'Video',
  InfoIcon: 'InfoIcon',
  ImageStyles: 'ImageStyles',
  ImageTextItem: 'ImageTextItem',
}
/**
 * @typedef {Object} CardGridProps
 * @property {String=} className
 * @property {String=} title
 * @property {String=} subtitle
 * @property {Array<TextCardProps|ArticleCard|ProductCard|InfoIcon|VideoProps|ImageStyles|ImageTextItemProps>=}
 *   items
 * @property {GridSize=} itemsPerRow
 * @property {LinkField=} ctaLink
 * @property {Boolean=} useDivider
 * @property {GridContainer=} container
 * @property {String=} actionLabel
 * @property {CardGridComponent=} component
 * @property {Object=} resetHeight
 * @property {String=} headingStyle
 * @property {String=} matchHeightTarget
 */
/**
 *
 * @param {CardGridProps} props
 * @return {JSX.Element}
 */
const CardGrid = ({
  className,
  title = '',
  subtitle = '',
  items,
  itemsPerRow = 3,
  ctaLink,
  useDivider = false,
  container,
  actionLabel,
  component,
  resetHeight,
  headingStyle = 'h5',
  matchHeightTarget = '.card-grid__item [class*="__content"]',
}) => {
  const itemsRef = useRef()
  // eslint-disable-next-line prefer-rest-params,no-undef
  // console.log('CardGrid render', { items }) //, JSON.stringify(arguments[0]))

  // @ts-ignore
  useMatchHeight({
    ref: itemsRef,
    resultList: items.length > 0,
    target:
      component === CardGrid.component.ProductCard ? '.product-card__card' : matchHeightTarget,
    stateChange: resetHeight,
  })

  if (!Array.isArray(items) || !items.length) {
    return null
  }

  return (
    <GridContainer
      {...container}
      className={`card-grid ${className}`}
      useDivider={useDivider}
      sx={{
        py: {
          xs: 5,
          lg: 10,
        },
        px: 0,
      }}
    >
      <Grid container className="card-grid__content" alignItems="center">
        {title && (
          <Title title={title} subtitle={subtitle} style={headingStyle} component={'h2'} useFlare />
        )}
        <Grid
          container
          className="card-grid__items"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          ref={itemsRef}
        >
          {items.map((item) => (
            <Grid
              item
              className="card-grid__item"
              // @ts-ignore
              key={uniqueId(`card-item-${item.id}`)}
              xs={12}
              md={itemsPerRow !== 1 ? 6 : 12}
              // @ts-ignore
              lg={12 / itemsPerRow}
            >
              {/*@ts-ignore*/}
              <Component {...item} type={component || item?.type} actionLabel={actionLabel} />
            </Grid>
          ))}
        </Grid>
        {ctaLink?.uri && (
          <Grid item className="card-grid__link" alignItems="center" xs={12}>
            <Button
              link={ctaLink}
              endIcon={<ChevronRightIcon />}
              data-label={actionLabel}
              data-page-path={ctaLink}
              data-source={className}
              data-action-type="navigate-card-grid-item"
            />
          </Grid>
        )}
      </Grid>
    </GridContainer>
  )
}

CardGrid.component = CardGridComponent
export default CardGrid

// @ts-nocheck
// Data
import {Forms} from '@ggs/forms/schema'
// Assets
// import './_FormField.scss'
import {debounceQueue, getWindow} from '@ggs/utils'
import {defer, get, has, isString, noop} from 'lodash'
import React, {createRef, useEffect, useMemo} from 'react'

const propTypes = Forms.FormFieldProps
const defaultProps = Forms.FormFieldDefaults

const useFormField = (
  {
    formik = {},
    validator, // extract to ignore.
    onChange = noop,
    inputProps: {ariaLabel, ...origInputProps} = {},
    ...compProps
  }) => {
  // Pool local state
  const doc = getWindow('document') || {querySelector: noop}
  const state = useMemo(() => compProps, [compProps])
  const {
    autoCompleteOptions,
    displayed = true,
    waitForTouched = true,
    maxDate,
    name,
    type = Forms.FieldTypes.text,
    onBlur,
    icon = null,
    descriptor,
    helpText = '',
    caption,
    defaultValue,
    labelPlacement,
    afterFieldText,
    modal,
    controlElement,
    // onChange,
    ...props
  } = state
  // Supporting field data
  const {id = name, label = name, placeholder = '', disabled, options} = props
  // Build input ref type based on needs
  const ref = createRef()
  // Formik field data.
  // Obtain formik props and determine if there's an error for this input.
  const value = has(state, 'value') ? state.value : get(formik.values, name) // refernce[0].asdf
  const checked = has(formik.values, name) ? get(formik.values, name) : false
  const defaultChecked = state.defaultChecked
  let error = get(formik.errors, name)
  if (!isString(error)) {
    // Prevent non-string errors from displaying, causing issues.
    error = ''
  }
  const touched = get(formik.touched, name)

  const checkLabel = () => {
    // If the value was entered, ensure the filled class shows on the input
    // label...
    const { /** @type Element */ current = window?.document} = ref
    const input
      = (has(current, 'querySelector') && current.querySelector('input'))
      || doc.querySelector(`input[name="${name}"]`)
    if (value || defaultValue || (input && input.value)) {
      // switch (type) {
      //   case Forms.FieldTypes.text:
      //   case Forms.FieldTypes.email:
      //   case Forms.FieldTypes.password:
      //   case Forms.FieldTypes.number:
      //     try {
      const labelEl = current?.closest('.form-field')?.querySelectorAll('label') || []
      if (labelEl.length) {
        defer(() => {
          labelEl.forEach((item) => {
            item.classList.add('MuiInputLabel-filled')
            item.classList.add('MuiInputLabel-shrink')
          })
        })
      }
      // } catch (e) {
      // ignore
      // }
      // console.log('forcing label due to value', value, labelEl)
      // break
      // }
    }
    // console.log('forcing label due to value?', value, current)
  }

  const debounceFieldType = (formType) => {
    switch (formType) {
      case (Forms.FieldTypes.date):
        return debounceQueue.QUEUE_TYPE.short
      // All text related inputs should have a debounce to avoid flooding,
      case (Forms.FieldTypes.currency):
      case (Forms.FieldTypes.tel):
      case (Forms.FieldTypes.number):
      case (Forms.FieldTypes.text):
      case (Forms.FieldTypes.password):
      case (Forms.FieldTypes.email):
      case (Forms.FieldTypes.textarea):
        return debounceQueue.QUEUE_TYPE.micro
      default:
        // non-typing should be ok direct (and desirable to avoid UX lag)
        return debounceQueue.QUEUE_TYPE.micro
    }
  }

  // Pool common input props for fields.
  const fieldProps = useMemo(() => {
    const debounceOnChange = (event) =>
      debounceQueue(
        name,
        () => {
          formik.handleChange(event)
          onChange(event)
        },
        debounceFieldType(type)
      )
    // console.log(`new fieldProps ${name}`)
    return {
      checked,
      className: 'form-field__field',
      defaultChecked,
      defaultValue,
      id,
      label,
      modal,
      name,
      onBlur: checkLabel,
      onChange: debounceOnChange,
      placeholder,
      ref,
      controlElement,
      // All text, safer with blur
      // onBlur: !forChange ? debounce(formik.handleBlur, 100) : null,
    }
  }, [id, name, placeholder, label, controlElement])
  // Always changes, no point including in memo
  fieldProps.value = value
  // Pull in other possible inputProps that were external previously.
  const inputProps = useMemo(
    () =>
      Object.assign(origInputProps || {}, {
        'aria-label': ariaLabel || placeholder,
      }),
    [origInputProps]
  )
  // console.log(`inputProps for ${name}`, inputProps, origInputProps)
  // Determine whether the field error should be displaying based on conditions.
  const hasError
    = (formik.submitCount > 0
      || (((touched && waitForTouched) || !waitForTouched) && value !== undefined))
    && !!error

  /**
   * Method to abstract the input from this form field.
   *
   * @return {Object|undefined}
   */
  const getInputFromRef = () => {
    const { /** @type Element */ current = window?.document} = ref
    let input
    if (has(current, 'querySelector')) {
      input = current.nodeName === 'INPUT' ? current : current.querySelector('input')
    }
    return input
  }

  /**
   * Cleanly handle adding/removing disabled attribute to input.
   */
  useEffect(() => {
    const input = getInputFromRef()
    // AutoComplete handles disabled in the wrapper, not the input.
    if (input) {
      if (disabled) {
        input.setAttribute('disabled', 'disabled')
      } else {
        input.removeAttribute('disabled')
      }
    }
    // console.log('input disable status', name, disabled, input)
  }, [disabled])

  /**
   * Track data validity.
   */
  useEffect(() => {
    const { /** @type Element */ current = window?.document} = ref
    if (has(current, 'setAttribute')) {
      current.setAttribute('data-valid', current?.dataset?.valid)
    }
  }, [ref?.current?.dataset?.valid])

  /**
   * Limit which fields ensure their label reacts to the value being filled.
   */
  useEffect(checkLabel, [value])

  // console.log(`${name} render`, renderField, renderedSubfields, subfields)
  // eslint-disable-next-line no-debugger
  // debugger

  return {
    value,
    touched,
    id,
    label,
    placeholder,
    disabled,
    options,
    autoCompleteOptions,
    name,
    type,
    icon,
    maxDate,
    waitForTouched,
    onChange,
    inputProps,
    fieldProps,
    ref,
    formik,
    labelPlacement,
    hasError,
    displayed,
    descriptor,
    caption,
    helpText,
    afterFieldText,
    error,
  }
}

// Exports
useFormField.defaultProps = defaultProps
useFormField.propTypes = propTypes
export default useFormField

import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import { useWindowSize } from '@ggs/hooks'
import { Title } from '@ggs/components/paragraphs'
import { Button } from '@ggs/gatsby/components/nav'
import { Accordion } from '@ggs/components/common'
import SearchFiltersList from './SearchFiltersList'
// Assets.
import './SearchFilters.scss'

/**
 * @typedef {import('@ggs/types').FaqCategory} FaqCategory
 */
/**
 * @typedef {Object} FaqFiltersProps
 * @property {Array<*>=} categories
 * @property {Array<Number>} activeFilters
 * @property {function=} updateFilters
 * @property {function=} resetFilters
 */
/**
 * FaqFilters component.
 * @param {FaqFiltersProps} props
 * @return {JSX.Element}
 */
export default function SearchFilters({ categories, activeFilters, updateFilters, resetFilters }) {
  const { t } = useI18n()
  const windowSize = useWindowSize()

  return (
    <div className="search-filters">
      {!windowSize.belowMd && (
        <Title
          className="search-filters__label"
          title={t('global:label.filterByCategory')}
          style="h4"
        />
      )}
      {windowSize.belowMd ? (
        <Accordion
          id="product-listing__categories"
          label={<span>{`${t('global:label.filterByCategory')} (${activeFilters.length})`} </span>}
          content={
            <SearchFiltersList
              categories={categories}
              updateFilters={updateFilters}
              filters={activeFilters}
            />
          }
          variant={Accordion.variant.filters}
        />
      ) : (
        <SearchFiltersList
          categories={categories}
          filters={activeFilters}
          updateFilters={updateFilters}
        />
      )}
      {activeFilters.length > 0 && (
        <Button
          className="search-filters__reset-btn"
          onClick={resetFilters}
          variant={Button.variant.text}
        >
          {t('global:button.resetFilters')}
        </Button>
      )}
    </div>
  )
}

// @ts-nocheck
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} CheckoutPlacedMessageProps
 * @property {Order} order
 */

/**
 * Checkout placed message.
 * @param {CheckoutPlacedMessageProps} props
 * @return {JSX.Element}
 */
const CheckoutPlacedMessage = ({ order }) => {
  const { t } = useI18n()
  const address = order?.shipping_address

  return (
    <Box
      sx={{
        padding: 8,
      }}
    >
      <Grid
        container
        sx={{
          textAlign: 'center',
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant={'h1'}
            sx={{
              mb: 4,
            }}
          >
            {t('ecomm:label.orderHasBeenPlaced')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('ecomm:complete.notification.body1.placed')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('ecomm:complete.notification.body2.placed', {
                email: `<strong>${address?.email}</strong>`,
              }),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CheckoutPlacedMessage

import React, { useMemo } from 'react'
import { colors, common, rem, typography } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 *
 * @return {SxProps}
 */
export default function useFilterableProductListSx() {
  const border = common.borderLightGrey
  return useMemo(
    () => ({
      '& .card-grid': {
        maxWidth: rem(974),
        margin: '0 auto',
      },
      '& .card-grid > div': {
        p: 0,
      },
      '& .card-grid__items': {
        justifyContent: 'start',
      },
      '& .product-card': {
        maxWidth: rem(298),
      },
      '& .product-listing__mobile-filters': {
        position: 'relative',
        '& .accordion': {
          mb: 0,
          '& > .MuiPaper-root': {
            borderBottom: border,
            '&.Mui-expanded': {
              borderBottom: 'transparent',
            }
          },
          '& .MuiAccordionSummary-root': {
            borderBottom: 0,
            borderTop: 0,
            '& .MuiAccordionSummary-content': {
              boxShadow: 'none',
              m: 0,
              pr: {
                xs: 12,
                md: 0,
              },
            },
            '& .MuiTypography-root': {
              'svg': {
                ml: 1,
                mt: 1,
                mb: 1,
              }
            }
          }
        },
        '& .MuiAccordionDetails-root': {
          p: 0,
          borderRadius: '0 !important',
        }
      },
      '& .filterable-product-list__category-list': {
        position: {
          xs: 'relative',
          md: 'static',
        }
      },
      '& .filterable-product-list__categories-label': {
        display: {
          xs: 'none',
          md: 'block',
        },
        mb: 2,
        wordBreak: 'break-word',
      },
      '& .filterable-product-list__filters': {
        '& .MuiCheckbox-root': {
          '&.Mui-focusVisible': {
            backgroundColor: 'rgba(147, 23, 35, 0.04)'
          },
          '&:focus': {
            backgroundColor: 'rgba(147, 23, 35, 0.04)'
          }
        }
      },
      '& .filterable-product-list__filters-label':  {
        display: {
          xs: 'none',
          md: 'block',
        },
        mb: 2,
        wordBreak: 'break-word',
      },
      '& .filterable-product-list__filters .button': {
        mt: {
          xs: 0,
          md: 5,
        },
        position: {
          xs: 'absolute',
          md: 'relative',
        },
        top: {
          xs: rem(13),
          md: 'inherit',
        },
        right: {
          xs: 1,
          md: 'inherit',
        },
      },
      '& ul': {
        listStyle: 'none',
      },
      '& ul, & li': {
        p: 0,
        m: 0,
      },
      '& li': {
        borderTop: border,
        '&:last-child': {
          borderBottom: border,
        },
        '& .MuiFormControlLabel-root': {
          '& .MuiTypography-root': {
            fontSize: {
              xs: rem(13),
              md: rem(16),
            },
            fontWeight: 400,
          }
        },
        '& .MuiCheckbox-root': {
          '&.Mui-focusVisible, &:focus': {
            backgroundColor: 'rgba(0, 0, 0, .04)',
          },
          '&.Mui-checked': {
            '~ span': {
              fontWeight: 'bold',
              typography: {
                md: typography.pBold,
              }
            }
          },
          '& svg': {
            '&[data-testid="CheckBoxOutlineBlankIcon"]': {
              fill: colors.lightGrey,
            }
          }
        }
      },
      '& .MuiFormControlLabel-label, & .accordion .MuiTypography-root': {
        typography: typography.pRegular,
      },
      '& .Mui-checked + .MuiFormControlLabel-label, & .Mui-checked + .MuiTypography-root': {
        fontWeight: 'bold !important',
      },
      '& .product-listing__usage': {
        mb: {
          md: 5,
        },
      },
      '& .title__title': {
        pb: 0,
        mb: 0,
        // borderBottom,
      },
      '& .filterable-product-list__filters h5': {
        typography: typography.pBold,
      },
      '& .accordion .MuiPaper-root, & .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
        background: 'transparent !important',
        boxShadow: 'none',
      },
      '& .accordion .MuiAccordionSummary-root': {
        borderTop: border,
        borderBottom: border,
        p: 0,
      },
      '& .accordion .MuiAccordionSummary-root .MuiTypography-root span': {
        fontSize: rem(13),
        fontWeight: 400,
        display: 'inline-flex',
        alignItems: 'center',
      },
      '& .accordion .MuiAccordionSummary-root .MuiTypography-root svg': {
        ml: 0.5,
      },
      '& .accordion .MuiAccordionSummary-expandIconWrapper': {
        display: 'none',
      },
      '& .product-listing__reset-btn': {
        '&.button': {
          typography: {
            xs: typography.caption,
            md: typography.pSmall,
          },
          color: colors.red,
          p: 0,
          '&:hover, &:focus': {
            'span': {
              textDecoration: 'underline',
            }
          },
          'span': {
            color: colors.red,
          }
        }
      }
    }),
    []
  )
}

import React, { useMemo } from 'react'
import { uniqBy, uniqueId } from 'lodash'
import { Link } from '@ggs/gatsby/components/nav'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 */
/**
 * @typedef {Object} FooterMenuListProps
 * @property {Number=} level
 * @property {Array<DrupalMenuLink>=} menuItems
 */
/**
 * @param {FooterMenuListProps} props
 * @return {JSX.Element}
 */
export default function FooterMenuList({ level, menuItems }) {
  const menu = useMemo(() => uniqBy(menuItems, 'uuid'), [menuItems])

  return (
    <ul className={`footer-menu-list footer-menu-list--level-${level}`}>
      {menu.map((menuItem) =>
        <li key={uniqueId(`footer-menu-${menuItem.id}`)} className="footer-menu-list__item">
          {menuItem.menuItems ? (
            <>
              <span>{menuItem.label}</span>
              <FooterMenuList level={level + 1} menuItems={menuItem.menuItems}/>
            </>
          ) : (
            <Link href={menuItem.url}>{menuItem.label}</Link>
          )}
        </li>
      )}
    </ul>
  )
}

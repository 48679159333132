// @ts-nocheck
import { BillingAddressReview, ShippingAddressReview } from '@ggs/components/ecomm'
import { Divider } from '@ggs/components/paragraphs'
import React, { useEffect } from 'react'
import OrderStatus from '../Checkout/elements/OrderStatus'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'

export const OrderReview = ({ editPayment, editOrder, variant, order }) => {
  const { fireAddPaymentInfo } = useCheckoutAnalytics({ order })
  const {
    checkout: {
      checkoutState: {
        orderId,
        billingCountryCode,
        billingStateCode,
        paymentMethodId,
        paymentSourceId,
      },
    },
  } = useCheckoutContext()

  useEffect(() => {
    if (orderId && paymentMethodId && paymentSourceId) {
      fireAddPaymentInfo({ order })
    }
  }, [
    orderId,
    billingCountryCode,
    billingStateCode,
    paymentMethodId,
    paymentSourceId,
  ])

  return (
    <>
      <ShippingAddressReview variant={variant} contact={true}
        editOrder={editOrder} order={order}/>
      <OrderStatus order={order} showStatus={false}/>
      <Divider
        sx={{
          mt: 5,
          mb: 5,
        }}
      />
      <BillingAddressReview variant={variant} editPayment={editPayment}
        order={order}/>
    </>
  )
}
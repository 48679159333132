import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import Button from '@mui/material/Button'

/**
 * @typedef {Object} ToggleMenuProps
 * @property {function} toggleMenu
 * @property {Boolean=} mobileOpen
 */
/**
 * @param {ToggleMenuProps} props
 * @return {JSX.Element}
 */
export default function ToggleMenu({
  toggleMenu,
  mobileOpen
}) {
  const { t } = useI18n()
  return (
    <Button
      onClick={() => toggleMenu(!mobileOpen)}
      className={mobileOpen ? 'toggle-menu mobile-open' : 'toggle-menu'}
      style={{
        borderRadius: 0,
        minWidth: 'auto',
        padding: 0
      }}
      aria-label={t('global:button.toggleMenu')}
    >
      <span className="toggle-menu__bar"/>
      <span className="toggle-menu__bar"/>
      <span className="toggle-menu__bar"/>
      <span className="toggle-menu__bar"/>
    </Button>
  )
}

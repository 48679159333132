import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'
/*
add_to_cart
This event signifies that an item was added to a cart for purchase.

Parameters
Name	Type	Required	Example value	Description
currency	string	Yes*	USD	Currency of the items associated with the event, in 3-letter ISO 4217 format.

If set, item-level currency is ignored.
If not set, currency from the first item in items is used.

* If you set value then currency is required for revenue metrics to be computed accurately.
value	number	Yes*	7.77	The monetary value of the event.
* value is typically required for meaningful reporting. If you mark the event as a conversion then it's recommended you set value.
* currency is required if you set value.
items	Array<Item>	Yes		The items for the event.
 */
// To measure the removal of a product from a shopping cart, send remove_from_cart event with the product information
export const fireRemoveFromCart = async ({ gtmProduct, quantity, value }) => {
  return pushDataLayer({
    eventName: EaActionType.removeFromCart,
    dataLayer: {
      items: [{ ...gtmProduct, quantity }],
      currency: gtmProduct.currency_code,
      value
    },
  })
}

const getSiteNotices = ({ layoutMeta }) => {
  return layoutMeta?.siteNotices ?? []
}

const filterSiteNotice = ({ siteNotice = [], marketId, language }) => Array.isArray(siteNotice.displayOnMarkets)
  ? siteNotice.language.id === language
  && siteNotice.displayOnMarkets.filter(((m) => m.marketId === marketId)).length > 0
  : false

const marketIdFromDefaultRegion = ({ currentStore }) => currentStore?.defaultRegion?.markets?.[0]?.marketId

const filterSiteNotices = ({ siteNotices = [], marketId, language }) => Array.isArray(siteNotices)
  ? siteNotices.filter((sn) => filterSiteNotice({ siteNotice: sn, marketId, language }))
  : []

const activeSiteNotice = ({ currentStore, layoutMeta, language }) =>
  filterSiteNotices({
    siteNotices: getSiteNotices({ layoutMeta }),
    marketId: marketIdFromDefaultRegion({ currentStore }),
    language
  })

export const useActiveNotice = ({ currentStore, layoutMeta }) => {
  const activeNotice = activeSiteNotice({
    currentStore,
    layoutMeta: layoutMeta,
    language: layoutMeta.lang
  })?.[0]

  return {
    checkoutNotice: activeNotice?.checkoutNotice,
    checkoutNoticeStyle: activeNotice?.checkoutNoticeStyle,
    sitewideAnnouncement: activeNotice?.sitewideAnnouncement,
    sitewideAnnouncementStyle: activeNotice?.sitewideAnnouncementStyle
  }
}
import React from 'react'
import { Form, Text, Title } from '@ggs/components/paragraphs'
import { Button } from '@ggs/gatsby/components/nav'
import { formDialogSx, useCustomerModal, useModal } from '@ggs/components/common'
import { useI18n } from '@ggs/gatsby/lib'
import Grid from '@mui/material/Grid'
import { useFindModalForCustomerType, useUserPermissions } from '@ggs/components/hooks'
import { find, omit } from 'lodash'
import useExistingQuantities from '@ggs/components/ecomm/ProductAddCart/useExistingQuantities'
import { useStoreSelectors } from '@ggs/components/ecomm/Checkout/useStoreSelectors'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').Product} Product
 * @typedef {import('@ggs/components/common/Modal/useModal').ModalState} ModalState
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * @typedef {Object} ProductPurchaseActions
 * @property {function} addToCart
 * @property {ProductOption|null} currentOption
 * @property {Product} product
 */

/**
 *
 * @param {ProductPurchaseActions} props
 * @return {{unavailableForSale: JSX.Element, showContactARep: (Boolean|*|{title}|boolean),
 * displayContactARep: (Boolean|*|{title}|JSX.Element|boolean),
 * contactRepModalContent: (ModalState|*), whereToBuyModalContent: (ModalState|*), displayWhereToBuy: JSX.Element,
 * sx: SxProps, isSalable: boolean, showWhereToBuy: boolean}}
 */
export default function ProductPurchaseActions(props) {
  const { currentOption, product } = props
  const { whereToBuy, contactARep } = product
  const { t } = useI18n()
  const { isHcpUser } = useUserPermissions()
  const { emptyQuantityOptions } = useExistingQuantities(currentOption)
  const { currentStore } = useStoreSelectors()
  const currentWhereToBuyModal = whereToBuy.find((modal) => modal.store.storeAlias === currentStore.storeAlias)

  // If whereToBuy content is provided, allow the trigger button to show.
  const availableWhereToBuyModal = useCustomerModal(whereToBuy)
  const showWhereToBuy = availableWhereToBuyModal !== null
  const { modal: whereToBuyModalContent, triggerModal: triggerWhereToBuyModal } = useModal({
    title: (
      <Title
        title={
          // @ts-ignore
          currentWhereToBuyModal?.title
          || t('ecomm:product.label.whereToBuyModalTitle', { productName: product.name })
        }
        style="h3"
      />
    ),
    content: <Text textContent={currentWhereToBuyModal?.textContent}/>,
  })

  // If the user is an HCP, allow the trigger button to show.
  const { modal: contactRepModalContent, triggerModal: triggerContactRepModal } = useModal({
    title: (
      <Title
        title={contactARep?.title || t('ecomm:product.label.contactRepModalTitle')}
        style="h3"
      />
    ),
    content: <Form {...omit(contactARep, ['title'])} />,
    sx: formDialogSx,
  })

  /*
   * Check if the product is sellable, based on the following
   * 1. The current option has a valid SKU
   * 2. This is the default option, and a valid SKU exists inside its productOptions
   */
  const skuFromCurrentOption = !!find(currentOption?.sku || [], ['enabled', true])?.sku
  // @ts-ignore
  const skuFromOtherOptions = !!find(
    product?.productOptions || [],
    (option) => !!find(option?.sku || [], ['enabled', true])?.sku
  )?.sku?.[0]?.sku
  const isSalable = skuFromCurrentOption || (!currentOption && skuFromOtherOptions)
  const findModalForCustomerType = useFindModalForCustomerType()
  // Determine if we have a form with content to display.
  const hasContactARepForm = findModalForCustomerType(contactARep?.customerForms) !== null
  // To display contact a rep form, we must have a product that's salable, an HCP user, and, a modal with content.
  const showContactARep = isHcpUser && (isSalable || whereToBuy) && hasContactARepForm

  // console.log('ProductPurchaseActions', {
  //   currentOption,
  //   currentQuantity,
  //   currentSku,
  //   existingQuantity,
  //   isSalable,
  //   product,
  //   productOptionSku,
  //   skuFromCurrentOption,
  //   skuFromOtherOptions,
  // })

  const sx = {
    '& .button': {
      width: {
        xs: '100% !important',
        md: 'auto !important',
      },
      display: {
        xs: 'block',
        md: 'inherit',
      },
      mx: {
        xs: 'auto',
        md: 'inherit',
      },
      mb: {
        xs: 2,
        md: 0,
      },
    },
    '.product-purchase-actions__salable': {
      flex: {
        xs: '0 0 100%',
        md: '0 0 auto',
        lg: '0 0 55%',
        aboveSmallDesktop: '0 0 45%',
      },
    },
    '.product-purchase-actions__additional': {
      flex: {
        xs: '0 0 100%',
        md: '0 0 auto',
        lg: '0 0 45%',
        aboveSmallDesktop: '0 0 55%',
      },
      ml: {
        lg: -1,
      },
      mr: {
        lg: 1,
      },
      mt: {
        md: 0,
        lg: 2,
        aboveSmallDesktop: 0,
      },
    },
  }

  // If we've run out of products, condense the spacing around the add button section.
  if (emptyQuantityOptions) {
    // @ts-ignore
    // sx['& > div:not(.existing-quantities)'] = {
    //   flex: {
    //     xs: '0 0 100%',
    //     md: '0 1 auto !important',
    //   },
    //   width: 'auto',
    //   '& > div, form': {
    //     width: 'auto !important',
    //   },
    // }
  }

  // And, if not salable, that there is where to buy content, display unavailable message.
  const unavailableForSale = showWhereToBuy && (
    <Grid className={'product-purchase-actions__unavailble'} item xs={12}>
      <Text
        // Not available for purchase through this website.
        textContent={t('ecomm:product.notice.unavailableForSale')}
        sx={{
          mb: 5,
        }}
      />
    </Grid>
  )

  const displayWhereToBuy = whereToBuy && (
    <Grid
      className={'product-purchase-actions__whereToBuy'}
      item
      xs={12}
      md={'auto'}
      marginRight={{
        md: 1,
        xl: 3,
      }}
    >
      <Button
        className="product-purchase-actions__where-to-buy"
        label={t('ecomm:product.button.whereToBuy')}
        data-label='where to buy'
        data-action-type='open-modal-where-to-buy'
        variant={'outlined'}
        onClick={triggerWhereToBuyModal}
      />
    </Grid>
  )
  const displayContactARep = showContactARep && (
    <Grid className={'product-purchase-actions__contactARep'} item xs={12} md={'auto'}>
      <Button label={t('ecomm:product.button.contactARep')} onClick={triggerContactRepModal}/>
    </Grid>
  )

  return {
    sx,
    isSalable,
    showWhereToBuy,
    showContactARep,
    unavailableForSale,
    displayContactARep,
    displayWhereToBuy,
    whereToBuyModalContent,
    contactRepModalContent,
  }
}

import React, { useMemo } from 'react'
import { isObject } from 'lodash'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { Title } from '@ggs/components/paragraphs'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import { useI18n } from '@ggs/gatsby/lib'
import { GridContainer } from '@ggs/components/layout'
import { useEventElements } from '@ggs/components/banners'
import { CardVariant } from '@ggs/types'
// Assets
import './EventCard.scss'
import { colors, common, typography } from '@ggs/styles'
import { Button, Link } from '@ggs/gatsby/components/nav'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import('@ggs/types').Event} Event
 * @typedef {import('dayjs').Dayjs} Dayjs
 */

/**
 * Article Card component.
 * @param {Event} event
 * @return {JSX.Element}
 */
export default function EventCard(event) {
  const { t } = useI18n()

  const { title = '', eventImage, eventType } = event
  const { variant, url } = event
  const isCondensed = variant === CardVariant.condensed
  const hasImage = isObject(eventImage) && !isCondensed
  const { publishDate, isUpcoming, categories, dateAndTime, eventLocation, eventActions }
    = useEventElements(event)

  // eslint-disable-next-line prefer-rest-params
  // console.log('EventCard render', arguments[0]) //, JSON.stringify(arguments[0]))

  const sx = useMemo(() => {
    const styles = {
      borderTop: common.borderLightGrey,
      mx: 2,
      'a:hover': {
        color: colors.black,
      },
    }
    switch (variant) {
      case CardVariant.condensed: {
        const hideElements = [
          '.event-hero__location',
          '.event-hero__actions-container',
          '.event-hero__categories',
          '.event-hero__field-label',
          '.event-card__type',
        ]

        Object.assign(styles, {
          borderTop: 0,
          mx: 0,
          // hide stuff we don't want.
          [hideElements.join(',')]: {
            display: 'none',
          },
          '.event-card__card, .event-card__content': {
            width: '85%',
            p: 0,
          },
          '.event-card__content > div': {
            m: 0,
          },
          '.event-card__content-date': {
            order: 1,
          },
          '.event-hero__date': {
            typography: typography.pSmall,
            mb: 2,
          },
          '.title': {
            pb: 3,
            mb: 3,
            borderBottom: common.borderLightGrey,
          },
          '.event-card__content-title': {
            order: 2,
          },
        })
        break
      }
      case CardVariant.listing:
        Object.assign(styles, {
          '.event-card__card, .event-card__content': {
            width: '100%',
            px: 0,
          },
          '& [class*="-card__content"]': {
            pr: {
              md: '16px !important',
            },
          },
          '.event-hero__field-label': {
            typography: typography.menuItemSmall,
            color: colors.blue,
          },
          '.event-hero__p-bold': {
            typography: typography.pBold,
            mb: 2,
          },
          '.MuiButton-outlined': {
            span: {
              color: colors.red,
            },
          },
          '.share-page': {
            justifyContent: {
              lg: 'start',
            },
          },
        })
    }
    return styles
  }, [variant])

  const innerContainerFlex = hasImage ? 4 : isCondensed ? 12 : 6

  return (
    <GridContainer className="event-card" sx={sx}>
      <Card className="event-card__card">
        <CardContent className="event-card__content">
          <Grid container spacing={5}>
            <Grid className="event-card__content-title" item xs={12} md={innerContainerFlex}>
              {categories}
              <Link href={url}>
                <Title title={title} style="h4" />
              </Link>
              {eventActions}
            </Grid>
            <Grid className="event-card__content-date" item xs={12} md={innerContainerFlex}>
              <p className="event-hero__p-bold event-card__type">
                {
                  // @ts-ignore
                  eventType?.name
                }
              </p>
              {dateAndTime}
              {eventLocation}
            </Grid>
            {hasImage && (
              <Grid className={'event-card__image-container'} item xs={12} md={4}>
                <div className="event-card__image">
                  <ImageStyles {...eventImage} selectedStyles={['pageHeroRelated']} />
                </div>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </GridContainer>
  )
}

EventCard.variant = CardVariant

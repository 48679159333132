let isGatsby = false
let isNetlify = false
let isDev = true
let isStaging = false
let isProd = false
let isTrueProd = false
let isPreview = false
let environment = 'development'
let hosting = 'local'
let serverlessPath = '/api/'

try {
  isGatsby = window.location.origin.includes('gtsb.io')
  isPreview = isGatsby && window.location.origin.includes('preview-')
  isNetlify = window.location.origin.includes('netlify.app')
  isStaging = window.location.origin.includes('staging')
  isProd = window.location.origin.includes('master')
  isTrueProd = window.location.origin.includes('trudellmed.com')
  isDev = !isStaging && !isProd

  if (isProd || isTrueProd) {
    environment = 'production'
  } else if (isStaging) {
    environment = 'staging'
  }

  if (isPreview) {
    hosting = `${environment}-preview`
  } else if (isGatsby) {
    hosting = `${environment}-main`
  } else if (isNetlify) {
    hosting += `${environment}-netlify`
  }

  if (isNetlify || isTrueProd) {
    serverlessPath = '/.netlify/functions/'
  }
} catch (error) {
  // ignore
}

export {
  isGatsby,
  isNetlify,
  isDev,
  isStaging,
  isProd,
  isTrueProd,
  isPreview,
  environment,
  hosting,
  serverlessPath,
}

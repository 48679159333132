const endpointUrl = `${process.env.GATSBY_COMMERCE_LAYER_API}`
const clientId = `${process.env.GATSBY_COMMERCE_LAYER_CLIENT_ID}`
const globalDefaultScope = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`
const gtmId = `${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}`

/**
 * Start a guest session using sales channel.
 * @param {string=} scope Market access scope, e.x. 'market:5812'.
 * @return {ThunkAction.<void, any, null, SessionAction>} return action object
 */
export const env = {
  endpointUrl, //: `${process.env.GATSBY_COMMERCE_LAYER_API}`,
  clientId, //: `${process.env.GATSBY_COMMERCE_LAYER_CLIENT_ID}`,
  globalDefaultScope, //: `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`
  gtmId
}
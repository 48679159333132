/**
 * Breakpoints
 * @enum {number}
 */
const breakpoints = {
  xs: 0,
  sm: 320,
  md: 768,
  aboveLargeMobile: 993,
  lg: 1016,
  aboveSmallDesktop: 1200,
  xl: 1400,
}

module.exports = breakpoints

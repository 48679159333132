import { AcceptTermsForm } from '@ggs/components/ecomm'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { OrderReview } from '@ggs/components/ecomm/CheckoutSteps/OrderReview'
import { colors } from '@ggs/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import React from 'react'

/**
 * Checkout information, payment and terms/conditions review.
 * @param {{acceptTCs: string}} props
 * @return {JSX.Element}
 */
export default function Review(props) {
  const {
    order,
    handlers: { goToOrder, goToPayment },
  } = useCheckoutContext()

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: colors.white,
            borderRadius: '4px',
            px: {
              xs: 3,
              md: 7,
            },
            pt: {
              xs: 3,
              md: 5,
            },
            pb: {
              xs: 3,
              md: 7,
            },
            '& .divider': {
              p: 0,
            },
          }}
        >
          <OrderReview
            order={order}
            editOrder={() => goToOrder()}
            editPayment={() => goToPayment()}
            variant="checkout"
          />
          <AcceptTermsForm  {...props} order={order}/>
        </Box>
      </Grid>
    </Grid>
  )
}

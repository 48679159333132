// @ts-nocheck
import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import {
  clone,
  compact,
  defaultsDeep,
  delay,
  extend,
  find,
  get,
  isEqual,
  isObject,
  uniqueId,
  values as _values,
} from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import MuiSelect from '@mui/material/Select'
import Autocomplete from '@mui/material/Autocomplete'
import { Forms } from '@ggs/forms/schema'
import { events } from '@ggs/utils'

// Components
import Checkbox from './Checkbox'
import TextField from './TextField'
// Assets
import './_Select.scss'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}

const propTypes = {
  style: PropTypes.oneOf(_values(Forms.SelectStyles).concat([undefined, ''])),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
      name: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
}

const defaultProps = {
  style: '',
  label: '',
  options: [],
}

// eslint-disable-next-line react/display-name
const Select = forwardRef(
  (
    { className, name, disabled, onChange, freeSolo = false, value: formikValue, ...props },
    ref
  ) => {
    const {
      style,
      label,
      valueKey = 'value',
      labelKey = 'label',
      placeholder,
    } = defaultsDeep(props, defaultProps)
    const selectId = uniqueId(`mui-select-${style}-`)
    const commonProps = {
      ref,
      name,
      id: selectId,
      placeholder,
      disabled,
    }
    const [defaultValue, setDefaultValue] = useState(null)
    const [options, setOptions] = useState(clone(props.options))

    const [selectedValue, setSelectedValue] = useState(() => {
      let initialValue = formikValue || props.defaultValue?.value || props.defaultValue || ''
      if (style && style !== Forms.SelectStyles.autocomplete) {
        initialValue = Array.isArray(formikValue) ? formikValue : []
      }
      // console.log(`Select::fieldValue ${name}`, style, initialValue)
      return initialValue
    })
    // if you want a default value with dependencies
    const [isReady, setIsReady] = useState(style !== Forms.SelectStyles.autocomplete)

    const handleChange = (e, option) => {
      const input = ref?.current?.querySelector('input')

      switch (style) {
        // For these style, synthetically add the custom selection option for formik.
        case Forms.SelectStyles.autocomplete:
          // eslint-disable-next-line no-param-reassign
          e = events.syntheticInputEvent(name, { value: get(option, 'value', input?.value || '') })
          break
      }
      setSelectedValue(e.target?.value)
      onChange(e)
      // console.log('Select::handleChange', e.target.value, option)
    }

    useEffect(() => {
      // We should only need to update from fomrik if the value was changed
      !selectedValue && formikValue && setSelectedValue(formikValue)
    }, [formikValue])

    const autocompleteDefaultProps = {
      options,
      freeSolo,
      getOptionLabel: (option) => option.label ?? '',
    }

    const renderAttributes = useMemo(() => {
      const attributes = {}
      attributes.onChange = handleChange

      switch (style) {
        case Forms.SelectStyles.multiple:
        case Forms.SelectStyles.multipleCheckboxes:
          attributes.multiple = true
          attributes.value = !Array.isArray(selectedValue) ? [] : selectedValue
          attributes.renderValue = (selected) =>
            compact(
              options.map((o) => (selected.indexOf(o.value || o.label) !== -1 ? o.label : null))
            ).join(', ')
          break
        case Forms.SelectStyles.checkboxes:
        default:
          attributes.multiple = false
          attributes.value = selectedValue
          attributes.renderValue = (selected) => (find(options, ['value', selected]) || {}).label
      }
      return attributes
    }, [style, selectedValue])

    // console.log(`Select::render ${name}`, { style, formikValue })

    useLayoutEffect(() => {
      switch (style) {
        case Forms.SelectStyles.autocomplete:
          if (isObject(defaultValue)) {
            console.log('Default value for autoComplete, defer reset for formik', defaultValue)
            delay(() => {
              handleChange(null, defaultValue)
              const input = ref?.current?.querySelector('input')
              if (input) {
                input.value = defaultValue
              }
            }, 1000)
          }
          break
      }
    }, [])

    useEffect(() => {
      const normDefaultValue = props.defaultValue || ''
      const defaultOptionMatch = find(props.options, [valueKey, normDefaultValue])
      const currentOption = defaultOptionMatch || {
        [valueKey]: props.defaultValue,
        [labelKey]: props.defaultValue,
      }
      // No force load, assume no default as there won't be any options to work against.
      if (currentOption) {
        setOptions([currentOption])
        delay(setDefaultValue, 10, currentOption)
      }

      if (props.options) {
        delay(() => {
          setOptions(props.options)
          setIsReady(true)
        }, 100)
      }
      // console.log('initValue', { defaultValue, currentOption, options })
    }, [props.options, props.defaultValue])

    useEffect(() => {
      // Update only if things have in fact changed
      if (!isEqual(props.options, options)) {
        delay(setOptions, 100, props.options || [])
      }
    }, [props.options])

    const reset = () => {
      handleChange(new Event('change'), { value: '' })
    }

    /**
     * Stop propagation of touch events to prevent ___gatsby element touch handlers interfer with MUI.
     * @param {React.TouchEvent<HTMLButtonElement>} e
     */
    const handleOnTouch = (e) => {
      e.stopPropagation()
      e.preventDefault()
    }

    // const onInputChange = (event) => {
    //   try {
    //     const input = event.target.closest('.form-field__autocomplete').querySelector('input')
    //     console.log('onInputChange', input)
    //   } catch (e) {
    //     // Ignore
    //   }
    // }

    return (
      <>
        {style === Forms.SelectStyles.autocomplete ? (
          isReady && (
            <Autocomplete
              {...autocompleteDefaultProps}
              {...commonProps}
              onTouchEnd={handleOnTouch}
              onChange={handleChange}
              onBlur={(e) => delay(() => handleChange(e), 10)}
              defaultValue={defaultValue}
              className={`${className} form-field__autocomplete`}
              freeSolo={freeSolo}
              autoFocus
              autoHighlight
              autoSelect
              isOptionEqualToValue={(a, b) => {
                // alert(JSON.stringify({aValue: a.value, bValue: b.value}))
                return a.value === b.value
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    placeholder={`Start typing to find a selection${
                      freeSolo ? ' or enter your own' : ''
                    }`}
                    name={name}
                    label={label}
                    inputProps={{
                      ...extend({}, params.inputProps),
                      autoComplete: 'no',
                    }}
                  />
                )
              }}
            />
          )
        ) : (
          <FormControl className={`${className} form-field__select`} data-style={style}>
            <InputLabel htmlFor={selectId}>{label}</InputLabel>
            <MuiSelect
              {...renderAttributes}
              {...commonProps}
              input={<OutlinedInput label={label} />}
              MenuProps={MenuProps}
            >
              {options.map((item, index) => {
                const common = {
                  key: uniqueId(`select-item-${index}_`),
                  className: 'form-field__select-option',
                  value: item.value || item.label,
                }
                let inner = item.label
                switch (style) {
                  case Forms.SelectStyles.checkboxes:
                  case Forms.SelectStyles.multipleCheckboxes:
                    // console.log('item', item.label, itemValue)
                    inner = (
                      <Checkbox
                        label={item.label}
                        name={item.name || item.label}
                        checked={
                          formikValue && formikValue.indexOf(item.value || item.label) !== -1
                        }
                        labelPlacement={Forms.LabelPlacement.end}
                      />
                    )
                }

                // eslint-disable-next-line react/jsx-key
                return <MenuItem {...common}>{inner}</MenuItem>
              })}
            </MuiSelect>
          </FormControl>
        )}
        <button
          className="form-field__reset"
          data-name={name}
          type="button"
          aria-hidden
          onClick={reset}
        />
      </>
    )
  }
)

// Export Props
Select.defaultProps = defaultProps
Select.propTypes = propTypes
// Export Enums
// Export Component
export default Select

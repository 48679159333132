import React from 'react'
import { useModal } from '@ggs/components/common'
import { Title } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import StoreRegionSelect from './StoreRegionSelect'
import { BackLink } from '@ggs/components/nav/index'

/**
 * @typedef {import('@ggs/components/common/Modal/Dialog')} Dialog
 * @typedef {import('@ggs/components/common/Modal/useModal').ModalState} ModalState
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */

/**
 *
 * @param {LayoutMeta} layoutMeta
 * @return {ModalState}
 */
export default function StoreSelectorModal(layoutMeta) {
  const { t } = useI18n()
  const storeRegionSelector = layoutMeta?.siteNotices?.storeRegionSelector || ''

  return useModal({
    sx: {
      // '.MuiDialog-container': {
      // },
      '.MuiPaper-root': {
        // p: 0,
      },
      '.dialog__content': {
        md: {
          width: 480,
          margin: '0 auto',
        },
      },
      '.back-link': {
        textAlign: 'left',
        pt: 0,
        ml: -1,
        button: {
          p: 0,
          mb: 2,
        },
      },
      '.title': {
        textAlign: 'left',
      },
      '.store-region__icon': {
        display: 'inline-block',
        mr: 1,
      },
    },
    content: ({ onClose }) => (
      <>
        <BackLink label={t('global:button.back')} onClick={onClose}/>
        {storeRegionSelector ? (
          <div className="title" dangerouslySetInnerHTML={{ __html: storeRegionSelector }}/>
        ) : (
          <Title
            title={t('global:nav.label.storeAndRegion')}
            subtitle={`<p>${t('global:nav.label.chooseAStore')}</p>`}
            style="h4"
          />
        )}
        <StoreRegionSelect layoutMeta={layoutMeta}/>
      </>
    ),
  })
}

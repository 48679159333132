// @ts-nocheck

import { envConfig } from './envConfig'

export const APP_STORE_KEY = 'trudell_webapp'


/**
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 * @typedef {import('@ggs/types').Market} Market
 * @typedef {import('@ggs/types').Store} Store
 */

/**
 * @typedef {Object} Geolocation
 * @property {number|null} latitude
 * @property {number|null} longitude
 * @property {number|null} accuracy
 */

/**
 * @typedef {Object} DefaultStateSettings
 * @property {Market} currentMarket CL selected market
 * @property {String} language User selected / resolved language code
 * @property {Boolean} privateMode Flag that indicate user don't accepted to be
 *   tracked by cookies (privacy mode)
 * @property {Geolocation} geolocation User geolocation (lat/lon)
 * @property {string} country User reverse geocoding lat/lon country
 * @property {Array<String>} clearedNotices Notices which were cleared
 * @property {Boolean} hidePreferredStoreNotice Flag that indicate to hide
 *   preferred store notice.
 *   @property {any} state
 */

/**
 * @typedef {Object} DefaultStateSession
 * @property {string} appVersion Loaded version of the webapp
 */

/**
 * @typedef {('success' | 'info' | 'warning' | 'error')} AlertType
 */

/**
 * @typedef {Object} AlertMessage
 * @property {String} id Alert unique ID
 * @property {String} code Alert code (identifier) to map with translatable
 *   strings
 * @property {String} title Alert message title
 * @property {String} body Alert message detailed description
 * @property {AlertType} type Alert type/style
 * @property {Boolean} visible Indicates if alert is visible
 */

/**
 * @typedef {Object} DefaultUIAlerts
 * @property {Array<AlertMessage>} notifications
 */

/**
 * @typedef {Object} DefaultSidebarUI
 * @property {Boolean} visible Flag that indicate if sidebar is visible or not
 * @property {string} contentType Indicate the content type used to resolve the
 *   sidebar content component
 */

/**
 * @typedef {Object} EcommLinksActionPayload
 * @property {String=} cart
 * @property {String=} checkout
 * @property {String=} productListing
 */

/**
 * @typedef {import('@ggs/types').OptionalProductCartItem}
 *   OptionalProductCartItem
 */

/**
 * @typedef {Object<string, Array<OptionalProductCartItem>>} LocalCartUI
 */

/**
 * @typedef {Object} DefaultUI
 * @property {DefaultUIAlerts} alerts Alerts notification
 * @property {Boolean} loading Flag that indicates that Redux action is loading
 *   data
 * @property {DefaultSidebarUI} sidebar Sidebar drawer state
 * @property {LayoutMeta} layoutMeta Sidebar drawer state
 * @property {Store} preferredStore User preferred store resolved from user
 *   language / IP location
 * @property {LocalCartUI} localCart Product local cart optionals state
 */

/**
 * @typedef {Object} DefaultCommerce
 * @property {string} token Customer CL session token
 * @property {string} tokenExpiresAt Customer CL session token expiration date
 *   timestamp
 * @property {string} tokenRefresh Registered customer CL session token renew
 * @property {import('@ggs/types').Customer} customer Customer simplified entity
 * @property {import('./types').SkusIndex} skus SKUs entities list
 * @property {import('@commercelayer/sdk').Order|null} order CL draft order
 */

/**
 * @typedef {Object} PaypalMeta
 * @property {string} responseCode extsuccess | extfailure
 * @property {string} PayerID paypal payerID sent back as searchParam
 * @property {string} paypalStatus flag indicating the offsite has initiated, etc
 */

/**
 * @typedef {Object} DefaultCheckoutState
 * @property {string} orderId order id
 * @property {string} customerId Customer
 * @property {string} shippingCountryCode
 * @property {string} shippingCountryLabel
 * @property {string} shippingStateCode
 * @property {string} shippingStateLabel
 * @property {string} shippingAddressId
 * @property {string} shippingMethodId
 * @property {string} billingCountryCode Customer CL session token
 * @property {string} billingCountryLabel Customer CL session token
 * @property {string} billingStateCode Customer CL session token expiration date
 *   timestamp
 * @property {string} billingStateLabel Customer CL session token expiration
 *   date timestamp
 * @property {string} paymentMethodId Registered customer CL session token renew
 * @property {string} paymentSourceId Registered customer CL session token renew
 * @property {boolean} acceptTerms
 * /

 /**
 * @typedef {Object} DefaultCheckoutStepsComplete
 * @property {boolean} ORDER order id
 * @property {boolean} PAYMENT order id
 * @property {boolean} REVIEW order id
 * @property {boolean} COMPLETE order id
 */

/**
 * @typedef {Object} DefaultBeginCheckout
 * @property {string} orderId
 * @property {boolean} reported
 */

/**
 * @typedef {Object} DefaultAddShippingInfo
 * @property {string} orderId
 * @property {string} shippingMethodId
 * @property {boolean} reported
 */

/**
 * @typedef {Object} DefaultAddPaymentMethod
 * @property {string} orderId
 * @property {string} paymentMethodId
 * @property {boolean} reported
 */

/**
 * @typedef {Object} DefaultPurchase
 * @property {string} orderId
 * @property {boolean} reported
 */

/**
 * @typedef {Object} DefaultCheckoutReview
 */
/**
 * @typedef {Object} DefaultCheckout
 * @property {DefaultCheckoutStepsComplete} stepsComplete
 * @property {DefaultCheckoutState} checkoutState
 * @property {String} currentStepName
 * @property {Number} currentStepperIndex
 * @property {String} lastOrderId
 * @property {PaypalMeta} paypalMeta
 */

/**
 * @typedef {Object} DefaultViews
 * @property {{placed: any, draft: any}} orders CL
 */

/**
 * @typedef {Object} DefaultAnalytics
 * @property {DefaultBeginCheckout} beginCheckout
 * @property {DefaultAddShippingInfo} addShippingInfo
 * @property {DefaultAddPaymentMethod} addPaymentMethod
 * @property {DefaultPurchase} purchase
 */

/**
 * @typedef {Object} DefaultState
 * @property {DefaultStateSession} session User data related to a session
 * @property {DefaultStateSettings} settings User webapp settings
 * @property {DefaultCommerce} commerce CommerceLayer state
 * @property {DefaultUI} ui Data that is show through common UI components
 * @property {DefaultViews} views External data that support page views
 * @property {DefaultCheckout} checkout
 * @property {DefaultAnalytics} analytics
 */


/**
 * @type {DefaultState} initialState
 */
export const initialState = {
  session: {
    appVersion: '',
  },
  settings: {
    currentMarket: envConfig.marketSettings,
    language: envConfig.language,
    geolocation: {
      latitude: null,
      longitude: null,
      accuracy: null
    },
    country: envConfig.country,
    privateMode: false,
    clearedNotices: [],
    hidePreferredStoreNotice: false
  },
  commerce: {
    token: '',
    tokenExpiresAt: '',
    tokenRefresh: '',
    lastAuth: null,
    customer: {
      email: '',
      customerType: null,
      status: '',
      customer_group: null,
      customer_addresses: [],
      customer_payment_sources: [],
      orders: [],
      metadata: {
        first_name: '',
        last_name: '',
        market_number: null,
        region_id: null,
        store_url: null,
        store_path: null,
        store_lang: null,
      },
    },
    skus: {},
    order: null,
  },
  ui: {
    alerts: {
      notifications: [],
    },
    sidebar: {
      visible: false,
      contentType: 'cart',
    },
    loading: false,
    layoutMeta: {
      menus: {
        main: {},
        footer: {},
        socialLinks: [],
        supporting: {
          orderProcessing: {
            productListingAlias: {
              uri: 'internal:/products',
            },
            cartAlias: {
              uri: 'internal:/cart',
            },
            checkoutAlias: {
              uri: 'internal:/checkout',
            },
          },
          // accountPages: {
          //   accountAlias: 'internal:/account',
          // },
        },
      },
      currentStore: {
        entityId: null,
        language: null,
        name: null,
        localeNames: null,
        url: null,
        storeAlias: null,
        defaultRegion: {
          markets: null,
          label: null,
          entityId: null,
        },
        markets: null,
        regions: null,
        country: null,
        ecommEnabled: null,
        hcpCustomerRole: null,
        hcpGatedContent: null,
        storeDisplayed: null,
        officeName: null,
        officePhone: null,
        officeEmail: null,
        officeAddress: null,
        supportPhone: null,
        supportEmail: null,
        logo: null,
        footerCopyright: null,
      },
      stores: [],
      regions: {},
      customerGroups: [],
      siteNotices: {
        sitewideAnnoucement: '',
        hcpChangeProfile: '',
        hcpGatedModal: '',
        storeRegionSelector: '',
      },
    },
    localCart: {},
    preferredStore: null,
  },
  views: {
    orders: {
      draft: {},
      placed: {},
    },
    translations: {
      products: {}
    }
    // addresses: {}
  },
  checkout: {
    stepsComplete: {
      // AUTH: false,
      ORDER: false,
      PAYMENT: false,
      REVIEW: false,
      COMPLETE: false,
    },
    checkoutState: {
      orderId: null,
      customerId: null,
      shippingCountryCode: null,
      shippingCountryLabel: null,
      shippingStateCode: null,
      shippingStateLabel: null,
      shippingAddressId: null,
      shippingMethodId: null,
      billingCountryCode: null,
      billingCountryLabel: null,
      billingStateCode: null,
      billingStateLabel: null,
      paymentMethodId: null,
      paymentSourceId: null,
      acceptTerms: false,
    },
    paypalMeta: {
      responseCode: null,
      PayerID: null,
      paypalStatus: '',
    },
    currentStepName: 'ORDER',
    currentStepperIndex: 0,
    lastOrderId: '',
    taxCalc: 0
  },
  analytics: {
    reportedCart: [],
    reportedPurchase: {
      orderId: '',
    },
    reportedShippingInfo: {
      orderId: '',
      shippingCountryCode: '',
      shippingStateCode: '',
      shippingAddressId: '',
      shippingMethodId: ''
    },
    reportedPaymentInfo: {
      orderId: '',
      billingCountryCode: '',
      billingStateCode: '',
      paymentMethodId: '',
      paymentSourceId: '',
    },
    reportedCheckout: {
      orderId: ''
    }
  }
}

import React from 'react'
import { generateGatsbyImage, useI18n } from '@ggs/gatsby/lib'
import { useModal } from '@ggs/components/common'
import Grid from '@mui/material/Grid'
import { Button } from '@ggs/gatsby/components/nav'
import { Divider, Text, Title, Video, VideoPlayIcon } from '@ggs/components/paragraphs'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { GridContainer } from '@ggs/components/layout'
import { safeVideoId } from '@ggs/utils'
// Assets
import './Quote.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { icons } from '@ggs/components'

/**
 * @typedef {import('@ggs/types').CarouselItem} CarouselItem
 * @typedef {import('@ggs/components/common/Modal/useModal').ModalProps} ModalProps
 * @typedef {import('@ggs/components/paragraphs/Video/Video').VideoProps} VideoProps
 */

/**
 * Quote Variant
 * @enum {string}
 */
const QuoteVariant = {
  default: 'default',
  small: 'small',
}

/**
 * @typedef {Object} quoteProps
 * @property {String=} title
 * @property {String=} quote
 * @property {ImageStyles=} quoteImage
 * @property {VideoProps=} quoteVideo
 * @property {String=} quoteAuthor
 * @property {QuoteVariant=} variant
 * @property {Function=} openModal
 */

/**
 *
 * @param {quoteProps} props
 * @return {JSX.Element}
 */
export default function Quote({
  title = '',
  quote = '',
  quoteImage = null,
  quoteVideo,
  quoteAuthor = '',
  variant = QuoteVariant.default,
  openModal,
}) {
  const { t } = useI18n()
  const isSmall = variant !== QuoteVariant.default
  const imageStyleToRender = isSmall ? 'quoteSmall' : 'quoteLarge'

  const videoId = safeVideoId(quoteVideo?.videoId)
  /** @type ModalProps */
  const modalContent = {
    title: <Title className="quote__modal-title" title={title} useFlare style="h2" />,
    content: (
      <>
        {videoId ? (
          <div className="quote__modal-video">
            <Video {...quoteVideo} autoplay={true} />
          </div>
        ) : (
          <div className="quote__modal-image">
            <ImageStyles {...quoteImage} selectedStyles={['quoteInModalLargest']} />
          </div>
        )}
        <div className="quote__modal-content">
          <Text textContent={quote} />
          <Divider sx={{ pb: 2, pt: 2 }} />
          <Text className="quote__modal-author" textContent={quoteAuthor} />
        </div>
      </>
    ),
    scroll: 'body',
  }
  const { modal: renderedModal, triggerModal } = useModal(modalContent)

  const modalAction = () => {
    if (openModal) {
      openModal(modalContent)
    } else {
      triggerModal()
    }
  }

  const imageUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
  return (
    <GridContainer
      className={`quote quote--${variant}`}
      sx={{ pt: { xs: 3, md: 5 }, pb: { xs: 3, md: 5 } }}
    >
      <>
        <Grid className="quote__inner" container justifyContent="flex-start" alignItems="stretch">
          {quoteImage && (
            <Grid
              className="quote__image"
              item
              xs={12}
              md={12}
              lg={variant === QuoteVariant.small ? 12 : 7}
            >
              {videoId ? (
                <Button
                  data-label={quoteVideo?.title || title}
                  data-action-type={'open-quote-video-modal'}
                  data-page-path={imageUrl}
                  className="quote__video-button"
                  onClick={modalAction}
                >
                  <>
                    <ImageStyles
                      {...generateGatsbyImage({
                        alt: quoteVideo?.title || 'Video thumbnail',
                        img: imageUrl,
                        styles: [
                          {
                            style: 'quoteLarge',
                            width: 744,
                            height: 456,
                          },
                          {
                            style: 'quoteSmall',
                            width: 296,
                            height: 168,
                          },
                        ],
                      })}
                      selectedStyles={[imageStyleToRender]}
                    />
                    <VideoPlayIcon />
                  </>
                </Button>
              ) : (
                <ImageStyles {...quoteImage} selectedStyles={[imageStyleToRender]} />
              )}
            </Grid>
          )}
          <Grid
            className={`${quoteImage ? 'quote__outer' : 'quote__outer quote__outer--full'}`}
            item
            xs={12}
            md={12}
            lg={variant === QuoteVariant.small ? 12 : 5}
          >
            <div className="quote__content">
              <div className="quote__quote-icon">{icons.Quote}</div>
              <Title title={title} style="h3" />
              <div className="quote__author">{quoteAuthor}</div>
              <Button
                data-label={title}
                data-action-type={'open-quote-modal'}
                className="quote__open-modal"
                onClick={modalAction}
                endIcon={<ChevronRightIcon />}
                variant={Button.variant.text}
              >
                {t('global:quote.label.fullStory')}
              </Button>
            </div>
          </Grid>
        </Grid>
        {!openModal && renderedModal}
      </>
    </GridContainer>
  )
}

Quote.variant = QuoteVariant

// @ts-nocheck
import React, { useState } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import * as PropTypes from 'prop-types'
import { noop } from 'lodash'

const propTypes = {
  panelId: PropTypes.number,
  summaryContent: PropTypes.string,
  handleClick: PropTypes.func,
  isOpen: PropTypes.func,
}

const defaultProps = {
  panelId: NaN,
  summaryContent: '',
  handleClick: noop,
  isOpen: noop,
}

const MaterialUIWrapperAccordionCheckbox = ({
  panelId,
  summaryContent,
  handleClick,
  isOpen,
  children,
  inputRef,
}) => {
  const [open, setOpen] = useState(isOpen)
  const handleChange = () => {
    setOpen(!open)
    handleClick()
  }
  return (
    <MuiAccordion
      square
      expanded={open}
      onChange={handleChange}
      className="material-ui-wrapper--accordion material-ui-wrapper--accordion-checkbox"
      ref={inputRef}
    >
      <MuiAccordionSummary
        aria-controls={`panel-${panelId}-content`}
        id={`panel-${panelId}-header`}
        className="material-ui-wrapper--accordion-summary material-ui-wrapper--accordion-checkbox-summary"
      >
        {summaryContent}
      </MuiAccordionSummary>
      <MuiAccordionDetails className="material-ui-wrapper--accordion-details material-ui-wrapper--accordion-checkbox-details">
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  )
}

MaterialUIWrapperAccordionCheckbox.propTypes = propTypes
MaterialUIWrapperAccordionCheckbox.defaultProps = defaultProps

export default MaterialUIWrapperAccordionCheckbox

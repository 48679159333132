import React from 'react'
import dayjs from 'dayjs'
import { isObject } from 'lodash'
import Grid from '@mui/material/Grid'
import { useI18n } from '@ggs/gatsby/lib'
import { Title } from '@ggs/components/paragraphs'
import { Button } from '@ggs/gatsby/components/nav'
import { ArticleMeta } from '@ggs/components/common'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { GridContainer } from '@ggs/components/layout'
// Assets
import useNewsAndEventsHeroSx, { ArticleHeroVariant } from './useNewsAndEventsHeroSx'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

/**
 * @typedef {import("@ggs/types").ArticleCard} ArticleCard
 */

/**
 *
 * @param {ArticleCard} props
 * @return {JSX.Element}
 */
export default function ArticleHero({
  url = '',
  title = '',
  publishedDate,
  articleCategories,
  articleImage,
  summary = '',
  variant = ArticleHeroVariant.default,
}) {
  const { t } = useI18n()
  const sx = useNewsAndEventsHeroSx(variant)
  const isHero = variant === ArticleHeroVariant.carousel
  const hasImage = isObject(articleImage)

  // eslint-disable-next-line prefer-rest-params
  // console.log('ArticleHero render', arguments[0]) //, JSON.stringify(arguments[0]))

  const heroTitle = (
    <Grid item xs={12}>
      <Title title={title} subtitle={isHero ? summary : null} style={isHero ? 'h2' : 'h1'} />
    </Grid>
  )

  const heroMeta = (
    <Grid item xs={12}>
      <ArticleMeta
        url={url}
        publishDate={dayjs
          // @ts-ignore
          .utc(publishedDate)}
        articleCategories={articleCategories}
        displaySharing={!isHero}
      />
    </Grid>
  )
  const heroButton = (
    <>
      {isHero && (
        <Grid item xs={12}>
          <Button
            link={{
              title: t('global:button.readMore'),
              uri: url,
            }}
            variant={Button.variant.text}
            endIcon={<ChevronRightIcon />}
          />
        </Grid>
      )}
    </>
  )
  const heroImage = (
    <>
      {hasImage && (
        <Grid item xs={12} md={isHero ? 6 : 12} className="article-hero__image" alignItems="center">
          <ImageStyles
            {...articleImage}
            selectedStyles={[isHero ? 'pageHeroFeatured' : 'pageHeroFull']}
          />
        </Grid>
      )}
    </>
  )

  return (
    <GridContainer className="article-hero" sx={sx}>
      <Grid container className="article-hero__inner" p={0} spacing={0}>
        <Grid item className="article-hero__content" xs={12} md={isHero && hasImage ? 6 : 12}>
          <Grid container className="article-hero__content-inner">
            {isHero ? (
              <>
                {heroMeta}
                {heroTitle}
                {heroButton}
              </>
            ) : (
              <>
                {heroTitle}
                {heroMeta}
              </>
            )}
          </Grid>
        </Grid>
        {heroImage}
      </Grid>
    </GridContainer>
  )
}

ArticleHero.variant = ArticleHeroVariant

import { colors, typography } from '@ggs/styles'
import { useMemo } from 'react'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 * @typedef {import('@ggs/types').ProductOptionVariant} ProductOptionVariant
 */
/**
 * @param {ProductOptionVariant} variant
 * @return {SxProps}
 */
export default function useProductOptionSx(variant) {
  return useMemo(() => {
    const sx = {
      width: '100%',
      '&[disabled]': {
        cursor: 'default',
      },
      '.product-option__labels': {
        maxWidth: '80%',
        textAlign: 'left'
      },
      '& .button.MuiButton-root': {
        width: '100%',
        py: '8px !important',
        px: '8px !important',
        backgroundColor: 'transparent',
        typography: typography.menuItemSmall,
        border: `2px solid ${colors.lightGrey}`,
        justifyContent: 'start',
      },
      '& .button.MuiButton-root > span': {
        color: colors.black,
        display: 'flex',
        alignItems: 'center',
      },
      '.product-option__label': {
        textTransform: 'none',
      },
    }

    // Override styling based on theme variant
    switch (variant) {
      case 'details':
        Object.assign(sx, {
          '& .button.MuiButton-root:hover, &[data-active="true"] .button.MuiButton-root': {
            borderColor: colors.blue,
            backgroundColor: colors.lightBlue,
          },
          '.button.MuiButton-root.MuiButton-text:focus .product-option__label': {
            color: colors.black,
          },
        })
        break
      case 'included':
        Object.assign(sx, {
          maxWidth: '100%',
          width: 'auto',
          '& .button.MuiButton-root': {
            ...sx['& .button.MuiButton-root'],
            width: 'auto',
            opacity: 1,
            borderColor: colors.ultraLightBlue,
            backgroundColor: colors.ultraLightBlue,
          },
          svg: {
            display: 'inline-block',
            mr: 1,
          },
        })
        break
      case 'cart':
        Object.assign(sx, {
          '& .button.MuiButton-root': {
            ...sx['& .button.MuiButton-root'],
            p: 0,
            borderColor: 'transparent',
          },
        })
        break
    }

    return sx
  }, [variant])
}

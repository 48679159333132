import React from 'react'
import { Grid, Box } from '@mui/material'
import { GridContainer } from '@ggs/components/layout'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import './ResourceCardsGrid.scss'
import { ResourceCard } from './ResourceCard'
import { getGridItemSize } from './helpers'
import { ButtonVariant } from '@ggs/types'

/**
 * @typedef {import("@ggs/components/paragraphs/ResourceCards/ResourceCards").ResourceCardsProps} ResourceCardsProps
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */

/**
 * @typedef {Object} ResourceCardsGridProps
 * @property {Element=} image
 * @property {String=} label
 * @property {String=} textContent
 * @property {LinkField=} link
 * @property {String=} linkStyle
 * @property {Element=} children
 * @property {Array<ResourceCards>=} gridItems
 */
/**
 * ResourceCardsGrid component.
 * @param {ResourceCardsGridProps} props
 * @return {JSX.Element}
 */

const ResourceCardsGrid = ({ title, summary, resourceCards, gridRatios, linkStyle = ButtonVariant.text }) => {
  // Custom theme with breakpoints
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  })

  const getGridTemplateColumnValue = () => {
    switch (gridRatios) {
      case '50/50':
        return 'repeat(2, 1fr)'
      case '25/75':
        return '25% 75%'
      case '75/25':
        return '75% 25%'
      case '66/33':
        return '66% 33%'
      case '33/66':
        return '33% 66%'
      case '33/33/33':
        return 'repeat(3, 1fr)'
      case '25/25/25/25':
        return 'repeat(4, 1fr)'
      default:
        return 'auto'
    }
  }

  const renderResourceCards = () => {
    return resourceCards.map((card) => (
      <Box key={card.id}>
        <ResourceCard key={card.id} card={card} linkStyle={card.linkStyle} gridSize={getGridItemSize(resourceCards.length)} />
      </Box>
    ))
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <GridContainer sx={{ py: { xs: 5, md: 10 } }} className="resource-grid">
          {(title || summary) && (
            <>
              <h3 className="resource-grid__header">{title}</h3>
              <div
                className="resource-grid__summary"
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            </>
          )}
          <Grid
            container
            spacing={2}
            className="resource-grid__cards"
            sx={{
              display: 'grid',
              gridTemplateColumns: getGridTemplateColumnValue(),
              '@media (max-width: 768px)': {
                gridTemplateColumns: '1fr',
              },
            }}
          >
            {renderResourceCards()}
          </Grid>
        </GridContainer>
      </ThemeProvider>
    </>
  )
}

// Export Component
export default ResourceCardsGrid

// @ts-nocheck
/* eslint-disable react/jsx-no-duplicate-props */
import React, { forwardRef } from 'react'
import NumberFormat from 'react-number-format'
// Components
import TextField from './TextField'
// Data
// Assets

function NumberMask(props) {
  // eslint-disable-next-line react/prop-types
  const { ref, onChange, precision, ...other } = props

  // console.log('::coinMask', precision, other)

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      // thousandSeparator
      onValueChange={(values) => {
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: values.value,
          },
        })
      }}
      // prefix=""
      decimalScale={precision}
    />
  )
}

// eslint-disable-next-line react/prop-types,react/display-name
const NumberInput = forwardRef(({ label, inputProps, disabled, ...fieldProps }, ref) => {
  return (
    <TextField
      {...fieldProps}
      ref={ref}
      label={label}
      inputProps={inputProps}
      disabled={disabled}
      InputProps={{
        inputComponent: NumberMask,
      }}
    />
  )
})

// Exports
export default NumberInput

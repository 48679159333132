import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { addNotification, setCurrentMarket, setPreferredStoreNotice, settingsActionType, useSelector } from '@ggs/store'
import { getGlobals, useI18n, useLink } from '@ggs/gatsby/lib'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { Button } from '@ggs/gatsby/components/nav'
import { navigate } from 'gatsby'
import { colors } from '@ggs/styles'
import { useDispatch } from 'react-redux'
import { resolveRegionMarket } from '@ggs/gatsby/lib/storeRegion'

const { LANGUAGES } = getGlobals()

/**
 * @typedef {import('@ggs/gatsby/lib/usePreferredStoreSettings').PreferredStoreHook} PreferredStoreHook
 */

/**
 * @param {{preferredStoreSettings: PreferredStoreHook}} props
 * @return {JSX.Element|null}
 */
const PreferredStore = ({ preferredStoreSettings, layoutMeta }) => {
  const { t } = useI18n()
  const settings = useSelector((state) => state.settings)
  const { country, hidePreferredStoreNotice } = settings
  const preferredStore = useSelector((state) => state.ui.preferredStore)
  const countryFlagIcon = country ? getUnicodeFlagIcon(country) : null
  const dispatch = useDispatch()
  // const currentPath = getWindow('location.pathname') || ''
  // const pattern = new RegExp(`/${preferredStoreSettings?.storeAlias}/`)
  const prefDefaultRegionStoreId = Array.isArray(preferredStore?.defaultRegion?.markets) ? preferredStore.defaultRegion.markets[0].marketId : null
  const isInPreferredStore = preferredStoreSettings?.suggestAlternativeStore
  const { stores } = layoutMeta
  const safeUrl = useLink(true, false)
  const customer = useSelector((state) => state.commerce.customer)

  // const pathname = getWindow('location.pathname')
  // const storesIndex = keyBy(stores, 'entityId')
  // const defaultLangCode = 'en-CA'
  // const defaultStoreLanguage = {
  //   uk: 'en-GB',
  //   ca: defaultLangCode,
  //   global: defaultLangCode,
  // }
  /**
   * Find store by ID from stores collection.
   * @param {Array<Store>} stores Stores to lookup
   * @param {String} storeId Store ID to find
   * @return {Store} Match store
   */
  const findStoreById = (stores, storeId) => {
    return stores.find((store) => {
      return store.entityId === storeId
    })
  }


  /**
   * Handle store selection.
   * @param {React.MouseEvent<HTMLButtonElement>} e
   */
  const handleStoreSelect = (id) => {
    const selectedStore = findStoreById(stores, id)
    const resolvedMarket = resolveRegionMarket(selectedStore?.defaultRegion?.markets, customer)
    // console.log('ResolvedMarket: ', resolvedMarket)
    handleSetMarket(resolvedMarket, selectedStore.defaultRegion, id)
  }

  const handleSetMarket = (market, region, storeId) => {
    if (market) {
      dispatch(setCurrentMarket(market, region))
      addNotification(
        settingsActionType.SET_CURRENT_MARKET,
        'success',
        `Current Market changed to ${market.marketId}`
      )
    }
  }

  /**
   * Hide the preferred store banner.
   * @type {React.MouseEventHandler}
   * @param {React.MouseEvent<HTMLButtonElement>} e Click event
   */
  const handleReject = (e) => {
    e.preventDefault()
    dispatch(setPreferredStoreNotice(true))
  }

  /**
   * Handle redirection to preferred store URL.
   * @type {React.MouseEventHandler}
   * @param {React.MouseEvent<HTMLButtonElement>} e Click event
   */
  const handleAccept = (e) => {
    e.preventDefault()
    dispatch(setPreferredStoreNotice(true))

    const { uri: newPageUrl } = safeUrl({
      href: preferredStoreSettings?.preferredStorePath,
    })
    try {
      handleStoreSelect(prefDefaultRegionStoreId)
      navigate(newPageUrl)
    } catch (e) {
      console.log('Navigage error:', e)
    }
  }

  const preferredStoreLanguage = useMemo(() => {
    const localeName = LANGUAGES.find(
      (/** @type {{ langCode: string; }} */ language) =>
        language.langCode === preferredStoreSettings?.preferredLanguage
    )

    return localeName?.name || ''
  }, [preferredStoreSettings?.preferredLanguage])

  /**
   * Resolve preferred store localized name based on preferred store optimal language.
   * @return {string}
   */
  const localeName = useMemo(
    () =>
      preferredStore?.localeNames?.find(
        ({ langCode }) => langCode === preferredStoreSettings?.preferredLanguage
      )?.name || preferredStore?.name,
    [preferredStoreSettings?.preferredLanguage, preferredStore?.name, preferredStore?.localeNames]
  )

  // console.log('Preferred store settings:', {
  //   isInPreferredStore,
  //   shouldDisplayNotice,
  //   preferredStoreSettings,
  //   localeName,
  // })

  if (
    !preferredStoreSettings?.suggestAlternativeStore
    || !preferredStoreSettings?.suggestAlternativeLanguage
    || hidePreferredStoreNotice
  ) {
    return null
  }

  return (
    <Grid
      className={'preferred-store'}
      container
      alignItems={'baseline'}
      justifyContent={'center'}
      spacing={2}
      padding={2}
      sx={{
        '.preferred-store__notice': {
          fontWeight: 'bold',
          '.store-region__icon': {
            ml: 2,
          },
          '.store-region__label': {
            ml: 2,
          },
        },
        button: {
          textTransform: 'none',
        },
        '.preferred-store__button-reject:hover': {
          '&, span': {
            color: `${colors.white} !important`,
          },
        },
      }}
    >
      <Grid item sm={'auto'}>
        <Typography className={'preferred-store__notice'} variant={'body1'}>
          {isInPreferredStore ? (
            <>{t('global:notice.preferredLanguage')}</>
          ) : (
            <>
              {t('global:notice.preferredStore')}
              {countryFlagIcon && <span className="store-region__icon">{countryFlagIcon}</span>}
              <span className="store-region__label">{localeName}</span>
            </>
          )}
          <span className="store-region__label">{preferredStoreLanguage}</span>
        </Typography>
      </Grid>
      <Grid item sm={'auto'}>
        <Button
          className={'preferred-store__button-reject'}
          onClick={handleReject}
          variant={'outlined'}
          sx={{
            mr: 2,
            '& > span': {
              color: colors.red,
            },
          }}
        >
          {t('global:label.rejectPreferredStore')}
        </Button>
        <Button className={'preferred-store__button-accept'} onClick={handleAccept}>
          {t('global:label.acceptPreferredStore')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default PreferredStore

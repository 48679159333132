// @ts-nocheck
/* eslint-disable no-unused-vars */
import React, { forwardRef, useLayoutEffect, useMemo, useState } from 'react'
import * as PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { defaultsDeep, delay, get, noop, uniqueId } from 'lodash'
// Data
import CircularProgress from '@mui/material/CircularProgress'
import { useI18n } from '@ggs/gatsby/lib'
import { BaseDocumentData, DOCUMENT_STATUS, DocumentDataProps } from '@ggs/forms/schema/Documents'
// Components
// import { Button, useSnackbar } from '../../nav'
// Assets
// import './_FileUpload.scss'
import FormActions from '../Form/FormActions'

export const FILE_UPLOAD_STATUS = {
  loading: 'loading',
  empty: 'empty',
  error: 'error',
  uploaded: 'uploaded',
  verified: 'verified',
}

const propTypes = {
  fileData: DocumentDataProps,
  options: PropTypes.shape({
    type: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    // step: PropTypes.number,
    // currentStep: PropTypes.number,
    accepts: PropTypes.arrayOf(PropTypes.string),
    acceptType: PropTypes.string,
  }),
  disabled: PropTypes.bool,
}

const defaultProps = {
  fileData: BaseDocumentData,
  options: {
    type: '',
    onSuccess: null,
    onError: null,
    // step: 1,
    // currentStep: 1,
    accepts: [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/pdf',
      // 'image/heic'
    ],
    acceptType: 'jpeg/jpg, png, pdf', // , heic
  },
  disabled: false,
}

const FileUpload = forwardRef((props, ref) => {
  // const {
  //   account: { addDocument, updateDocument },
  //   transaction: { submitWireTransfer },
  // } = useBitbuyApi()
  const { t } = useI18n()
  const {
    label,
    modal,
    options: {
      docList,
      type,
      onSuccess = noop,
      onError = noop,
      // step,
      // currentStep,
      acceptType,
      onUpload,
      onRemove,
    },
    disabled,
  } = defaultsDeep(props, defaultProps)
  const accepts = get(props, 'accepts', defaultProps.accepts)
  const fileData = defaultsDeep(get(props, 'fileData', {}), BaseDocumentData)
  const icon = ''
  // eslint-disable-next-line react/destructuring-assignment
  const [status, setStatus] = useState(
    docList[type]?.uploaded ? FILE_UPLOAD_STATUS.uploaded : FILE_UPLOAD_STATUS.empty
  )
  const validFile = status === FILE_UPLOAD_STATUS.verified
  const [isLoading, setLoading] = useState(false)
  const [allowUpload, setAllowUpload] = useState(true)
  const buttonLabel = status === 'uploaded' ? t('fileUpload.reUpload') : t('fileUpload.buttonLabel')
  // const snackbar = useSnackbar()

  const openDialog = () => {
    // console.log('openDialog', ref)
    if (ref.current) {
      ref.current.open()
    } else {
      // snackbar({ message: 'Error uploading' })
    }
  }

  const removeFile = () => {
    setLoading(true)
    modal.triggerModal({
      content: <div dangerouslySetInnerHTML={{ __html: t('fileUpload.removeFile') }} />,
      onSubmit: () => {
        delay(
          () =>
            onRemove()
              .then(() => {
                setStatus(FILE_UPLOAD_STATUS.empty)
                setAllowUpload(true)
              })
              .catch((e) => {
                // Todo: Handle error
              })
              .finally(() => {
                setLoading(false)
              }),
          500
        )
      },
      onClose: () => {
        setLoading(false)
      },
      submitButton: {
        label: t('fileUpload.okRemove'),
      },
      closeButton: {
        label: t('fileUpload.cancelKeep'),
      },
    })
  }

  const uploadButton = useMemo(() => {
    switch (status) {
      // case 'PENDING':
      //   return <span className="successful">{t('fileUpload.pending')}</span>
      // case 'REJECTED':
      //   return <span className="unsuccessful">{t('fileUpload.rejected')}</span>
      // case 'APPROVED':
      //   return <span className="successful">{t('fileUpload.verified')}</span>
      // case 'CANCELLED':
      //   return <span className="unsuccessful">{t('fileUpload.canceled')}</span>
      default:
        const isUploaded = status === FILE_UPLOAD_STATUS.uploaded
        return (
          <>
            <FormActions
              actions={[
                {
                  type: 'button',
                  variant: isLoading ? 'contained' : isUploaded ? 'outline' : 'outline',
                  label: isUploaded
                    ? `<span class='file-upload__filename'>${docList[type]?.name}</span>`
                    : isLoading
                    ? t('fileUpload.loading')
                    : buttonLabel,
                  loading: isLoading,
                  disabled,
                  onClick: isUploaded ? removeFile : openDialog,
                },
              ]}
            />
            {status === FILE_UPLOAD_STATUS.empty && (
              <span className="file-upload__accept-type">({acceptType})</span>
            )}
          </>
        )
    }
  }, [status, isLoading])

  const handleAddDocument = ([file]) => {
    // Ignore if the file is valid already.
    if (validFile) {
      return
    }
    // console.log('Uploaded file:', file)

    // Don't show loader for receipts
    // if (!isReceipt) {
    setLoading(true)
    // }

    // delay(()=> setStatus(FILE_UPLOAD_STATUS.empty))
    // const reader = new FileReader()
    //
    // reader.onabort = () => con.log('file reading was aborted')
    // reader.onerror = () => con.log('file reading has failed')
    // reader.onload = () => {
    //   // Do whatever you want with the file contents
    //   const { result } = reader
    //   // console.log(binaryStr)
    //   delay(() => setState({ ...state, file: result }))
    // }
    // reader.readAsBinaryString(file)

    if (
      fileData.status &&
      (fileData.status === DOCUMENT_STATUS.NEW ||
        (fileData.status === DOCUMENT_STATUS.REJECTED && fileData.id))
    ) {
      setTimeout(() => {
        // console.log('::updateDocument', fileData.id, file)
        /*updateDocument(file, fileData.id)
         .then((res) => {
         // console.log('::updateDocument res', res)
         // Handle post-upload action
         onSuccess()
         delay(() => setLoading(false), 200)
         })
         .catch((e) => {
         // console.log('error', { e })
         // Flag error.
         setStatus(FILE_UPLOAD_STATUS.error)
         onError()
         setLoading(false)
         })*/
      }, 1000)
    } else {
      setTimeout(() => {
        // console.log('::addDocument', file)
        onUpload(file, type)
          .then((res) => {
            // console.log('::addDocument res', res, file)
            // Update this file status to good
            setStatus(FILE_UPLOAD_STATUS.uploaded)
            // Handle post-upload action
            onSuccess(file)
            // snackbar({
            //   message: 'Your document was successful uploaded.',
            //   type: useSnackbar.SNACKBAR_TYPE.success,
            // })
            setLoading(false)
          })
          .catch((e) => {
            // console.log('error', { e })
            // Flag error.
            setStatus(FILE_UPLOAD_STATUS.error)
            // snackbar({ message: 'There was an error uploading your document. Please try again.' })
            // onError()
            setLoading(false)
          })
      }, 1000)
    }
  }

  // Set initial status based on the file status.
  useLayoutEffect(() => {
    switch (fileData.status) {
      case DOCUMENT_STATUS.NEW:
        setLoading(false)
        setStatus(FILE_UPLOAD_STATUS.uploaded)
        setAllowUpload(true)
        break
      case DOCUMENT_STATUS.REVIEW:
        setLoading(false)
        setStatus(FILE_UPLOAD_STATUS.uploaded)
        setStatus(FILE_UPLOAD_STATUS.uploaded)
        setAllowUpload(false)
        break
      case DOCUMENT_STATUS.VERIFIED:
      case DOCUMENT_STATUS.ADMIN_UPLOAD:
        setLoading(false)
        setStatus(FILE_UPLOAD_STATUS.verified)
        setAllowUpload(false)
        break
      case DOCUMENT_STATUS.REJECTED:
        setLoading(false)
    }
  }, [fileData.createdOn, fileData.id, fileData.status])

  // console.log(`render ${fileData.type}`, { fileStatus: fileData.status, status, allowUpload })
  const id = uniqueId('file_')
  return (
    <div className="file-upload" id={id}>
      {/*<label htmlFor={id}>{label}</label>*/}
      <Dropzone
        ref={ref}
        noClick
        noKeyboard
        accept={accepts}
        multiple={false}
        onDrop={(acceptedFiles) => handleAddDocument(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({ className: 'dropzone' })}
            data-status={isLoading ? FILE_UPLOAD_STATUS.loading : status}
            // data-progress-status={step > currentStep && !checker ? 'hidden' : 'visibly'}
            data-progress-status={disabled ? 'hidden' : 'visibly'}
            data-cy="dropzone"
          >
            {!!icon && (
              <span className="form__action-loader">
                <CircularProgress size={30} />
              </span>
            )}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input {...getInputProps()} />
            {/*<p
             className="dropzone-title"
             dangerouslySetInnerHTML={{
             __html: isLoading ? t('fileUpload.loading') : t(`fileUpload.${status}`),
             }}
             />*/}
            {allowUpload ? uploadButton : null}
          </div>
        )}
      </Dropzone>
    </div>
  )
})

// Export Props
FileUpload.defaultProps = defaultProps
FileUpload.propTypes = propTypes
FileUpload.displayName = 'FileUpload'
// Export Component
export default FileUpload

import React from 'react'
import Box from '@mui/material/Box'
import { useI18n } from '@ggs/gatsby/lib'
import { GridContainer } from '@ggs/components/layout'
import { Button } from '@ggs/gatsby/components/nav'
import { Title } from '@ggs/components/paragraphs'
import { common } from '@ggs/styles'

// Assets
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { rem } from '@ggs/styles'
import './FileList.scss'

/**
 * @typedef {import('@ggs/types').DrupalFile} DrupalFile
 */

/**
 * @typedef {Object} FileListProps
 * @property {String=} title
 * @property {String=} subtitle
 * @property {Array<DrupalFile>=} files
 */

/**
 * FileList component.
 * @param {FileListProps} props
 * @return {JSX.Element}
 */
export default function FileList({ title= '', subtitle = '', files }) {
  const { t } = useI18n()

  // console.log('FileList', files)

  return (
    <GridContainer
      className="fileList"
      sx={{
        mt: {
          xs: `${rem(16)} !important`,
          md: `${rem(40)} !important`,
        },
        pt: {
          xs: `${rem(16)} !important`,
          md: `${rem(40)} !important`,
        },
        '& .fileList__title, & .title__title': {
          mt: 0,
        },
        '& .fileList__title': {
          mb: `${rem(28)} !important`,
        }
      }}
    >
      <Box
        sx={{
          '& .fileList__items': {
            borderTop: common.borderLightGrey
          },
        }}
      >
        <Title className="fileList__title" title={title} subtitle={subtitle} style="h2"/>
        <div className="fileList__items">
          {files.length && files.map((/** @type DrupalFile */ file) =>
            <Box
              key={file.id}
              sx={{
                '& .fileList__item-label': {
                  mt: 0,
                },
                '& .button': {
                  p: '0 !important',
                },
                borderBottom: common.borderLightGrey,
              }}
            >
              <div className="fileList__item" key={file.id}>
                <Title className="fileList__item-title" title={file.name} subtitle={file.summary ?? null} style="h4" sx={{
                  mt: {
                    xs: 2,
                    md: 4,
                  }, p: 0
                }}/>
                <Button
                  link={{ uri: file.fieldMediaFile ? file.fieldMediaFile.url : file.url }}
                  endIcon={<ChevronRightIcon/>}
                  className="fileList__item-button"
                  variant={Button.variant.text}
                  aria-label={t('global:button.downloadFile', { fileName: file.name })}
                  sx={{
                    my: {
                      xs: 2,
                      md: 4,
                    },
                  }}
                >
                  {t('global:button.download')}
                </Button>
              </div>
            </Box>
          )}
        </div>
      </Box>
    </GridContainer>
  )
}

import { hashValue } from '@ggs/utils'

/**
 * @typedef {import('@ggs/types').Market} Market
 * @typedef {import('@ggs/types').Region} Region
 */

/**
 * Settings action types.
 * @enum {string}
 */
export const settingsActionType = {
  CLEAR_NOTICE: 'CLEAR_NOTICE',
  SET_CURRENT_LANGUAGE: 'SET_CURRENT_LANGUAGE',
  SET_CURRENT_MARKET: 'SET_CURRENT_MARKET',
  SET_PREFERRED_STORE_NOTICE: 'HIDE_PREFERRED_STORE_NOTICE',
}

/**
 * @typedef {Market} SetCurrentMarketActionPayload
 */

/**
 * @typedef {Object} SetCurrentMarketAction
 * @property {settingsActionType} type Action type key
 * @property {SetCurrentMarketActionPayload} payload Action input data
 */

/**
 * Set current market action.
 *
 * @param {Market} market CL market
 * @param {Region} region Store region
 * @return {SetCurrentMarketAction}
 */
export const setCurrentMarket = (market, region) => {
  return {
    type: settingsActionType.SET_CURRENT_MARKET,
    payload: {
      ...market,
      region: {
        name: region.label,
        entityId: region.entityId,
      },
    },
  }
}

/**
 * @typedef {Object} SetCurrentLanguageActionPayload
 * @property {String} language
 */

/**
 * @typedef {Object} SetCurrentLanguageAction
 * @property {settingsActionType} type Action type key
 * @property {SetCurrentLanguageActionPayload} payload Action input data
 */

/**
 * Set current market action.
 *
 * @param {String} language CL market ID
 * @return {SetCurrentLanguageAction}
 */
export const setCurrentLanguage = (language) => {
  return {
    type: settingsActionType.SET_CURRENT_LANGUAGE,
    payload: {
      language,
    },
  }
}

/**
 * @typedef {Object} ClearNoticeAction
 * @property {settingsActionType} type Action type key
 * @property {String} payload
 */

/**
 * Action to clear specific UI alert by ID.
 * @param {String} label The alert ID to clear
 * @return {ClearNoticeAction} Action object.
 */
export const clearNotice = (label) => {
  return {
    type: settingsActionType.CLEAR_NOTICE,
    payload: hashValue(label),
  }
}

/**
 * @typedef {Object} HidePreferredStoreActionPayload
 * @property {Boolean} status
 */

/**
 * @typedef {Object} HidePreferredStoreAction
 * @property {settingsActionType} type Action type key
 * @property {HidePreferredStoreActionPayload} payload Action input data
 */

/**
 * Set preferred store banner visibility flag
 *
 * @param {Boolean} status Ignore flag status
 * @return {HidePreferredStoreAction}
 */
export const setPreferredStoreNotice = (status) => {
  return {
    type: settingsActionType.SET_PREFERRED_STORE_NOTICE,
    payload: {
      status,
    },
  }
}

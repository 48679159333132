import React, { useEffect, useMemo, useState } from 'react'
import { uniqueId } from 'lodash'
import { a11yProps } from '@ggs/utils'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import TabPanel from './TabPanel'
import { common, zIndex } from '@ggs/styles'

// Assets
import './Tabs.scss'
import useTabSelectorsSx from './useTabSelectorsSx'
import useHcpGatedAction from '@ggs/components/hooks/useHcpGatedAction'
import { useSelector } from '@ggs/store'
import { ImpressionClickObserver } from '@ggs/components/ecommerceAnalytics'
import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
/**
 * @typedef {import("../Tab").TabProps} TabProps
 */

/**
 * @typedef {Object} TabsProps
 * @property {Array<TabProps>=} tabs
 */

/**
 *
 * @param {TabsProps} props
 * @return {JSX.Element}
 */
export default function Tabs(props) {
  const { t } = useI18n()
  const [tabId] = useState(uniqueId('tab_'))
  const [currentTab, setCurrentTab] = useState(0)
  const tabs = useMemo(() => props.tabs, [props.tabs])
  const tabSelectorsSx = useTabSelectorsSx(tabs)

  const customerRole = useSelector((state) => state.commerce.customer.customerType)
  const { shouldTriggerModal, triggerHcpAction, modal } = useHcpGatedAction({
    resolve: (/** @type String */ value) => {
      setCurrentTab(parseInt(value))
    },
  })

  /**
   * When users change tabs, update state.
   * @param {Event} event
   * @param {Number} tabId
   */
  const changeTab = (event, tabId) => {
    // Find out the tabs customer role if present.
    const targetId = tabs?.[tabId]?.customerGroup?.tid || 0
    // Attempt to trigger tab. If gating is required, show modal, else open normally.
    triggerHcpAction({ targetId, targetValue: tabId.toString() })
    // Add GTM event for tab change
    pushDataLayer({
      eventName: 'click',
      dataLayer: {
        label: tabs[tabId].label,
        action_type: 'open-content-tab',
      },
    })
  }

  // eslint-disable-next-line prefer-rest-params
  // console.log('Tabs render', arguments[0]) //, JSON.stringify(arguments[0]))

  // If the user changes type, but was on a tab that requires HCP, move them back to the first tab.
  useEffect(() => {
    setTimeout(() => {
      const targetId = tabs?.[currentTab]?.customerGroup?.tid || 0
      if (shouldTriggerModal(targetId)) {
        setCurrentTab(0)
      }
      console.log('Tabs useEffect', targetId, shouldTriggerModal(currentTab))
    }, 200)
  }, [shouldTriggerModal, currentTab, customerRole])

  if (!Array.isArray(tabs) || !tabs.length) {
    return null
  }

  return (
    <div className="tabs">
      <Box className="tabs__selectors" sx={tabSelectorsSx}>
        <MuiTabs
          id={tabId}
          value={currentTab}
          // @ts-ignore
          onChange={changeTab}
          // variant="scrollable"
          // allowScrollButtonsMobile
          selectionFollowsFocus
          // scrollButtons="auto"
          aria-label={t('aria.tabNavigation')}
        >
          {tabs.map(({ label, id }, index) => {
            // console.log('tabItem', id, label)
            return (
              id && (
                <MuiTab
                  className="tabs__selector"
                  key={uniqueId(`tabLink_${id}`)}
                  label={label}
                  {...a11yProps(tabId, index)}
                />
              )
            )
          })}
        </MuiTabs>
      </Box>
      <Box
        className="tabs__panels"
        sx={{
          zIndex: zIndex.zContent,
        }}
      >
        {tabs.map((tabItem, index) => {
          const { id } = tabItem
          // console.log('tabItem', id, tabItem)
          return (
            id && (
              <TabPanel
                key={uniqueId(`tabPanel_${id}`)}
                tabId={tabId}
                value={currentTab}
                index={index}
                content={tabItem}
              />
            )
          )
        })}
      </Box>
      {modal}
    </div>
  )
}

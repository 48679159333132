import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import SidebarContent from './SidebarContent'
import { hideSidebar, useSelector } from '@ggs/store'
import { useDispatch } from 'react-redux'

/**
 * Sidebar UI.
 * @return {JSX.Element}
 */
export default function Sidebar() {
  const anchor = 'right'
  const sidebar = useSelector((state) => state.ui?.sidebar)
  const dispatch = useDispatch()

  /**
   * @typedef {React.KeyboardEvent<KeyboardEvent> & React.MouseEvent<HTMLButtonElement>} ToggleEvent
   */

  /**
   * Toggle the Drawer visibility.
   * @param {ToggleEvent} event
   */
  const toggleHandler = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    if (sidebar) {
      dispatch(hideSidebar())
    }
  }

  return (
    sidebar.visible && (
      <Box key={anchor}>
        <Drawer
          anchor={anchor}
          open={sidebar.visible}
          onClose={toggleHandler}
          sx={{
            '.MuiPaper-root': {
              minWidth: '300px',
            },
          }}
        >
          <SidebarContent />
        </Drawer>
      </Box>
    )
  )
}

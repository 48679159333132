import {initialState} from '../initialState'
import {createReducer} from '@reduxjs/toolkit'
import {uiActionType} from '../actions/ui'
import {commonActionType} from '@ggs/commercelayer/actions/CommonActions'
import {filter, uniqueId} from 'lodash'

/**
 * @typedef {import("../initialState").DefaultUI} InitialStateUI
 * @typedef {import("../actions/ui").SidebarAction} UISidebarAction
 * @typedef {import("../actions/ui").LayoutMetaAction} UILayoutMetaAction
 * @typedef {import("../actions/ui").SetPreferredStoreAction}
 *   SetPreferredStoreAction
 * @typedef {import("../actions/ui").AddNotificationAction}
 *   AddNotificationAction
 * @typedef {import("../actions/ui").SetProductLocalCartAction}
 *   SetProductLocalCartAction
 * @typedef {import("../actions/ui").RemoveProductLocalCartItemAction}
 *   RemoveProductLocalCartItemAction
 * @typedef {import("@ggs/commercelayer/index").ApiErrorAction} ApiErrorAction
 */

/**
 * @returns {InitialStateUI} New UI state.
 */
const ui = createReducer(initialState.ui, {
  /**
   * Set common Ecomm links form the current page.
   * @param {InitialStateUI} state
   * @param {UILayoutMetaAction} action
   */
  [uiActionType.SET_LAYOUT_META]: (state, action) => {
    console.log(action)
    state.layoutMeta = action?.payload
      ? {...state.layoutMeta, ...action.payload}
      : state.layoutMeta
  },

  /**
   * Show sidebar reducer.
   * @param {InitialStateUI} state
   * @param {UISidebarAction} action
   */
  [uiActionType.SHOW_SIDEBAR]: (state, action) => {
    state.sidebar = action.payload
  },

  /**
   * Hide sidebar reducer.
   * @param {InitialStateUI} state
   * @param {UISidebarAction} action
   */
  [uiActionType.HIDE_SIDEBAR]: (state, action) => {
    state.sidebar = action.payload
  },

  /**
   * Add API error alert.
   * @param {InitialStateUI} state
   * @param {ApiErrorAction} action
   */
  [commonActionType.API_ERROR]: (state, action) => {
    /**
     * @type {('error')}
     */
    const defaultType = 'error'
    const existingErrorsCodes = state.alerts.notifications.map((notification) => notification.code)
    const newErrors
      // eslint-disable-next-line array-callback-return
      = action.payload.errors.map((error) => {
      // Inject error only if no other error with same code exists.
      if (!existingErrorsCodes.includes(error.code)) {
        return {
          id: uniqueId('error'),
          code: error.code,
          title: error.message,
          body: '',
          visible: true,
          type: defaultType,
        }
      }
    }) || []

    state.alerts.notifications = state.alerts.notifications.concat(filter(newErrors))
  },

  /**
   * Add notification message alert.
   * @param {InitialStateUI} state
   * @param {AddNotificationAction} action
   */
  [uiActionType.ADD_NOTIFICATION]: (state, action) => {
    const existingNotificationCodes = state.alerts.notifications.map(
      (notification) => notification.code
    )
    const newNotification = action.payload

    if (!existingNotificationCodes.includes(newNotification.code)) {
      state.alerts.notifications = state.alerts.notifications.concat(action.payload)
    }
  },

  /**
   * Clear UI alerts.
   * @param {InitialStateUI} state
   * @param {any} action
   */
  [uiActionType.CLEAR_ALERTS]: (state, action) => {
    state.alerts.notifications = action.payload || []
  },

  /**
   * Clear UI alerts by ID.
   * @param {InitialStateUI} state
   * @param {any} action
   */
  [uiActionType.CLEAR_ALERT]: (state, action) => {
    const alertId = action.payload.id
    state.alerts.notifications = state.alerts.notifications.filter(
      (notification) => notification.id !== alertId
    )
  },

  /**
   * Set preferred store resolved from user country.
   * @param {InitialStateUI} state
   * @param {SetPreferredStoreAction} action
   */
  [uiActionType.SET_PREFERRED_STORE]: (state, action) => {
    state.preferredStore = action.payload.store
  },

  /**
   * Set product local cart state.
   * @param {InitialStateUI} state
   * @param {SetProductLocalCartAction} action
   */
  [uiActionType.SET_PRODUCT_LOCAL_CART]: (state, action) => {
    const productId = action.payload.productId
    state.localCart[productId] = action.payload.optionalItems
  },

  /**
   * Remove product local cart item.
   * @param {InitialStateUI} state
   * @param {RemoveProductLocalCartItemAction} action
   */
  [uiActionType.REMOVE_PRODUCT_LOCAL_CART_ITEM]: (state, action) => {
    const productId = action.payload.productId
    const optionId = action.payload.optionId
    const optionalItems = state.localCart[String(productId)] || []
    const existingItems = optionalItems.filter((optionalItem) => optionalItem.sku_code !== optionId)

    state.localCart[productId] = existingItems
  },
})

export default ui

import React, { useMemo } from 'react'
import { digestDrupalDate } from '@ggs/utils'
import dayjs from 'dayjs'
import { useI18n } from '@ggs/gatsby/lib'
import { Button } from '@ggs/gatsby/components/nav'
import Grid from '@mui/material/Grid'
import { Chip } from '@ggs/components/common'
import { CardVariant } from '@ggs/types'
// Assets
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import('@ggs/types').Event} Event

 * @typedef {import('dayjs').Dayjs} Dayjs
 */

/**
 *
 * @param {Event} event
 * @return {{eventDateFormat: string, eventActions: JSX.Element, dateAndTime: JSX.Element, eventLocation: JSX.Element,
 *   publishDate: Dayjs, categories: JSX.Element, isUpcoming: *}}
 */
export default function useEventElements(event) {
  const { t } = useI18n()
  const {
    url = '',
    title = '',
    eventCategories,
    eventImage,
    eventDate,
    location,
    eventType,
    registerLink,
    recordingLink,
    eventSummary,
    variant,
  } = event
  const { publishDate, isUpcoming } = useMemo(() => {
    const { start } = digestDrupalDate(eventDate)
    return {
      publishDate: start,
      isUpcoming: start.isAfter(dayjs()),
    }
  }, [eventDate])

  // If the duration is 24h aka all day, don't show the time.
  const eventDateFormat = eventDate?.duration === 1439 ? 'LL' : 'LLL z'

  return useMemo(() => {
    const fileName = eventSummary?.name || 'document'
    const categories = eventCategories?.length ? (
      <div className="event-hero__categories">
        {eventCategories?.map((item) => (
          <Chip key={item.tid} label={item.name} variant="pink" />
        ))}
      </div>
    ) : null
    const dateAndTime = (
      <div className="event-hero__date">
        <p className="event-hero__field-label">{t('events:label.dateAndTime')}</p>
        <p className="event-hero__p-bold">{dayjs(publishDate).format(eventDateFormat)}</p>
      </div>
    )
    const eventLocation = (
      <div className="event-hero__location">
        <p className="event-hero__field-label">{t('events:label.location')}</p>
        <p className="event-hero__location-value">{location}</p>
      </div>
    )

    const viewEventLink = [CardVariant.listing, 'carousel'].includes(variant)
    const eventActions = (
      <div className="event-hero__actions-container">
        {viewEventLink ? (
          <Button
            data-label={title}
            data-action-type="view-event-content"
            className="event-hero__button"
            link={{ uri: url, title: t('global:button.viewEvent') }}
            variant={Button.variant.text}
            endIcon={<ChevronRightIcon />}
          />
        ) : (
          <>
            {isUpcoming && registerLink?.title && (registerLink?.uri || registerLink?.url) && (
              <Button
                data-label={title}
                data-action-type="register-for-event"
                link={registerLink}
                endIcon={<ChevronRightIcon />}
              />
            )}
            {!isUpcoming && (
              <Grid
                container
                className={'event-hero__actions'}
                justifyContent="space-between"
                flexDirection={{
                  xs: 'column',
                  lg: 'row',
                }}
                spacing={{ xs: 2, lg: 0 }}
              >
                {eventSummary?.url && (
                  <Grid item xs={'auto'}>
                    <Button
                      data-label={fileName}
                      data-action-type="download-event-file"
                      link={{ uri: eventSummary.url }}
                      variant={Button.variant.outlined}
                      aria-label={t('global:button.downloadFile', { fileName })}
                    >
                      {t('events:button.summaryWhitepaper')}
                    </Button>
                  </Grid>
                )}
                {recordingLink?.title && (recordingLink?.uri || recordingLink?.url) && (
                  <Grid item xs={'auto'}>
                    <Button
                      data-label={title}
                      data-action-type="view-recording"
                      link={recordingLink}
                      endIcon={<PlayArrowIcon />}
                      ignoreOffsite
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </div>
    )

    // eslint-disable-next-line prefer-rest-params
    console.log('useEventElements render', {
      title,
      publishDate,
      eventDateFormat,
    }) //, JSON.stringify(arguments[0]))

    return {
      publishDate,
      eventDateFormat,
      isUpcoming,
      categories,
      dateAndTime,
      eventLocation,
      eventActions,
    }
  }, [event])
}

const getWindow = require('./getWindow')
/** @type {string} */
// @ts-ignore
const search = getWindow('location.search') || ''

/**
 * Safe way to obtain search query parameters from the current URL.
 * @param {string=} params
 * @return {module:url.URLSearchParams}
 */
const getSearchParams = (params = '') => {
  const target = params || search || ''
  const qs = new URLSearchParams(target)
  // console.log('getSearchParams', {
  //   qs,
  //   target,
  //   location,
  // })
  return qs
}

module.exports = getSearchParams

// @ts-nocheck
import { has } from 'lodash'

/**
 * Check if a provided object/function is a React class component.
 * @param {Any} component Object or function to check
 * @return {boolean} true if is a class component, false otherwise
 */
export function isClassComponent(component) {
  return typeof component === 'function' && !!component.prototype.isReactComponent
}

/**
 * Check if a provided object/function is a functional component.
 * @param {Any} component Object or function to check
 * @return {boolean} true if is a function component, false otherwise
 */
export function isFunctionComponent(component) {
  return typeof component === 'function' && String(component).includes('return React.createElement')
}

/**
 * Check if provided object/function is a React component.
 * @param {Any} component Object or function to check
 * @return {boolean} true if is a React component, false otherwise
 */
export default function isReactComponent(component) {
  return component?.['$$typeof']
}

/**
 * Find a DOM element ancestor.
 * @param {Any} source Source DOM element
 * @param {string} sel Selector
 * @return {{matches}} Matched DOM elements
 */
export function findAncestor(source, sel) {
  let el = source
  // eslint-disable-next-line no-cond-assign
  while ((el = el.parentElement) && !(el.matches || el.matchesSelector).call(el, sel));
  return el
}

// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import List from '@mui/material/List'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import Collapse from '@mui/material/Collapse'
import ErrorIcon from '@mui/icons-material/Error'
import AccordionCheckboxItem from './AccordionCheckboxItem'
import MaterialUIWrapperAccordionCheckbox from './MaterialUIWrapperAccordionCheckbox'

// import './_accordion-checkbox.scss'

const propTypes = {}

const defaultProps = {}

function AccordionCheckbox({
  itemData,
  value,
  onChange,
  name,
  disabled,
  accordionIndex,
  ...props
}) {
  // eslint-disable-next-line no-console
  const [open, setOpen] = useState(false)
  const [isNoneOfAbove, setIsNoneOfAbove] = useState(false)
  const [currentValue, setCurrentValue] = useState(() => value || [])
  const { id, description, competency, liItems } = itemData

  // convert checkboxItem from a string to an object and used to record changes in the component
  const [checkboxItems, setCheckboxItems] = useState(() =>
    liItems.map((liItem) => ({ label: liItem }))
  )

  // Seems formik updates checkbox values open the onChange event
  // Keep the event and input list in state and call formik onChange
  const [inputs, setInputs] = useState([])
  const [changeEvent, setChangeEvent] = useState(null)

  const inputRef = useRef()

  // update checkboxItems upon value changes
  useEffect(() => {
    setCheckboxItems(
      checkboxItems.map((checkboxItem) => {
        return {
          label: checkboxItem.label,
          isChecked: currentValue.indexOf(checkboxItem.label) >= 0,
        }
      })
    )
  }, [currentValue])

  // useEffect is used to trigger formike onChange event one by one
  useEffect(() => {
    if (inputs.length > 0) {
      const input = inputs.shift()
      // take the last input as option None of above and do not set it unchecked
      input.checked = inputs.length !== 0 ? false : input.checked
      changeEvent.target = input
      onChange(changeEvent)
      setInputs([...inputs])
    } else {
      setChangeEvent(null)
    }
  }, [inputs])

  const handleNoneOfAboveChange = (event) => {
    setIsNoneOfAbove(!isNoneOfAbove)
    setInputs(Array.prototype.slice.call(inputRef.current.querySelectorAll('input')))
    setCurrentValue([])
    setChangeEvent(event)
  }

  const handleChange = (event) => {
    if (event.target.checked) {
      setCurrentValue([event.target.value, ...currentValue])
    } else {
      const newValue = [...currentValue]
      newValue.splice(newValue.indexOf(event.target.value), 1)
      setCurrentValue(newValue)
    }
    onChange(event)
  }

  const handleSubclick = () => {
    setOpen(!open)
  }

  const hasLength = currentValue.length > 0
  return (
    <MaterialUIWrapperAccordionCheckbox
      handleClick={handleSubclick}
      isOpen={open}
      panelId="accordion-checkbox-list"
      className="material-ui-wrapper--accordion-checkbox-list"
      elevation={2}
      inputRef={inputRef}
      summaryContent={
        <>
          <span
            className={`material-ui-wrapper--accordion-checkbox-list--icon ${
              isNoneOfAbove ? 'error' : ''
            } ${hasLength ? 'check' : ''}`}
          >
            {isNoneOfAbove ? (
              <ErrorIcon className="material-ui-wrapper--accordion-checkbox-list--icon-warning" />
            ) : hasLength ? (
              <CheckCircleRoundedIcon className="material-ui-wrapper--accordion-checkbox-list--icon-approved" />
            ) : accordionIndex ? (
              <div className="material-ui-wrapper--accordion-checkbox-list--accordionIndex">
                {accordionIndex}
              </div>
            ) : null}
          </span>
          <section className="material-ui-wrapper--accordion-checkbox-list--details">
            <div>
              {id} {description}
            </div>
            <div>{competency}</div>
          </section>
          <span className="material-ui-wrapper--accordion-checkbox-list--expand-icon">
            {open ? <ExpandLess /> : <ExpandMore />}
          </span>
        </>
      }
    >
      <Collapse
        key="todo"
        in={open}
        // timeout auto makes it jerky
        timeout={0}
        unmountOnExit
      >
        <List className="accordion-checkbox-list">
          {checkboxItems.map((checkboxItem) => {
            return (
              <AccordionCheckboxItem
                key={`accordion-checkbox-${checkboxItem.label}`}
                className="accordion-checkbox-list-item"
                checkboxItemLabel={checkboxItem.label}
                checked={checkboxItem.isChecked}
                onChange={handleChange}
                disabled={disabled || isNoneOfAbove}
                name={name}
              />
            )
          })}
          <hr />
          <AccordionCheckboxItem
            className="accordion-checkbox-list-item"
            checkboxItemLabel="None of the above"
            onChange={handleNoneOfAboveChange}
            checked={isNoneOfAbove}
            name={name}
            disabled={disabled}
          />
        </List>
      </Collapse>
    </MaterialUIWrapperAccordionCheckbox>
  )
}

AccordionCheckbox.propTypes = propTypes
AccordionCheckbox.defaultProps = defaultProps

export default AccordionCheckbox

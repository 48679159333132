import React from 'react'
import { Button } from '@ggs/gatsby/components/nav'
import Box from '@mui/material/Box'
import CustomerSelectorModal from './CustomerSelectorModal'
import { useI18n } from '@ggs/gatsby/lib'
// Assets
import { colors, typography } from '@ggs/styles'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useCustomerGroupSelect } from '@ggs/components/nav/CustomerSelector/useCustomerGroupSelect'

/**
 * @typedef {import('@ggs/components/common/Modal/Dialog')} Dialog
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */

/**
 *
 * @param {LayoutMeta} layoutMeta
 * @return {JSX.Element}
 */
export default function CustomerSelector({ layoutMeta }) {
  const { t } = useI18n()

  const { customerGroups, currentCustomerGroup } = useCustomerGroupSelect(layoutMeta)

  const { modal, triggerModal } = CustomerSelectorModal({
    customerGroups,
    currentCustomerGroup,
  })

  return (
    <Box
      className={'customer-selector'}
      sx={{
        ml: {
          md: 2
        },
        mr: {
          md: 3,
        },
        '.customer-type__select': {
          px: 0,
        },
        '.customer-type__select__viewas': {
          display: 'inline-block',
          // @ts-ignore
          textTransform: 'none',
          typography: typography.pSmall,
          color: {
            md: colors.white
          },
        },
      }}
    >
      <span className="customer-type__select__viewas">
        {t('global:nav.label.viewingAs')}:&nbsp;
      </span>
      <Button
        className="customer-type__select"
        onClick={triggerModal}
        variant={Button.variant.text}
        endIcon={modal ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
      >
        {currentCustomerGroup?.name}
      </Button>
      {modal}
    </Box>
  )
}

/* eslint-disable max-len */
import React, { useMemo, useRef } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { decode } from 'html-entities'
import parse, { attributesToProps, domToReact } from 'html-react-parser'

// Assets
import { ImpressionClickObserver } from '@ggs/components/ecommerceAnalytics'
import { Link } from '@ggs/gatsby/components/nav'
import { useLink } from '@ggs/gatsby/lib'
import { getGTMEventLabel } from '@ggs/utils/getGTMEventLabel'
import Video from '../Video/Video'

import './Text.scss'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * @typedef {Object} TextProps
 * @property {String=} className
 * @property {JSX.Element|Element|String=} children
 * @property {String=} textContent
 * @property {SxProps=} sx
 */

/**
 *
 * @param {TextProps} props
 * @return {JSX.Element}
 */
export default function Text({ className = '', textContent = '', children, sx }) {
  const textRef = useRef()
  const safeUrl = useLink(true)

  const content = useMemo(() => {
    const digestedContent = (String(textContent) || '').replace(/h1/gi, 'h2')
    const cleanHtmlEntities = decode(digestedContent).replace(/&[#0-9a-z]+;/gi, '')
    const cleanBreaks = cleanHtmlEntities.replace(/<br \/>/gi, '')

    return parse(cleanBreaks, {
      replace: (domNode) => {
        const { attribs, name, children } = domNode

        if (!attribs) return

        if (name === 'iframe' && attribs.src.includes('youtube')) {
          let videoId = attribs.src
          if ((attribs.src || '').toString().indexOf('/embed/') !== -1) {
            videoId = attribs.src?.split('/embed/')?.[1]?.split('?')?.[0]
          }

          return <Video {...attributesToProps(attribs)} videoId={videoId} variant="text-iframe" />
        }

        if (name !== 'a') return

        const { isExternal, label } = safeUrl({ href: attribs.href })

        // Convert regular links (that are not buttons) to proper urls.
        if (
          ['btn', 'a__button, a__proceed'].every((cls) =>
            attribs.class ? !attribs.class.includes(cls) : true
          ) &&
          attribs['data-drupal-button-link'] !== 'btn'
        ) {
          return (
            <Link
              {...attributesToProps(attribs)}
              href={attribs.href}
              className={[attribs.class, 'a__proceed', isExternal ? 'a__proceed-external' : '']
                .join(' ')
                .trim()}
              data-label={attribs.title || label || children[0]?.data}
              data-action-type={`navigate-${isExternal ? 'external' : 'internal'}-text-link`}
              ignoreImpression
            >
              {domToReact(children)}
            </Link>
          )
        }

        // Convert button links to button links.
        if (
          (attribs.class && attribs.class.includes('btn')) ||
          attribs['data-drupal-button-link'] === 'btn'
        ) {
          return (
            <Link
              {...attributesToProps(attribs)}
              href={attribs.href}
              className="a__button"
              role="button"
              data-label={attribs.title || label || children[0]?.data}
              data-action-type={getGTMEventLabel(
                attribs.title || label || children[0]?.data || '',
                `navigate-${isExternal ? 'external' : 'internal'}-btn-link`
              )}
              ignoreImpression
            >
              {domToReact(children)}
            </Link>
          )
        }
      },
    })
  }, [textContent])

  // console.log('textContent:', content)

  // eslint-disable-next-line prefer-rest-params
  // console.log('Text render', arguments[0], JSON.stringify(arguments[0]))

  return (
    <ImpressionClickObserver>
      <Box
        data-label={'Text Content'}
        data-action-type={'view-text-content'}
        ref={textRef}
        className={`text ${className}`}
        sx={{
          '&, & > div': {
            maxWidth: '100%',
          },
          ...sx,
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid className="text__content" item xs={12}>
            {content && <div className="text__body">{content}</div>}
            {children && <div className="text__body">{children}</div>}
          </Grid>
        </Grid>
      </Box>
    </ImpressionClickObserver>
  )
}

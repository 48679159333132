import React, { useMemo } from 'react'
import { useLink } from '@ggs/gatsby/lib'
import { Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { GridContainer } from '@ggs/components/layout'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Link } from '@ggs/gatsby/components/nav'
import { Title } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import './ResourceCards.scss'
import { icons } from '@ggs/components'
import { ButtonVariant } from '@ggs/types'
import { colors, zIndex } from '@ggs/styles'
import { getGTMEventLabel } from '@ggs/utils/getGTMEventLabel'

/**
 * @typedef {Object} ResourceCardsProps
 * @property {String=} id
 * @property {String=} type
 * @property {Element=} image
 * @property {String=} label
 * @property {String=} textContent
 * @property {LinkField=} link
 * @property {String=} linkStyle
 */
/**
 * @param {ResourceCardsProps} props
 * @return {JSX.Element}
 */

const ResourceCards = (props) => {
  const { id, type, image, label, textContent, link, linkStyle } = props
  const { t } = useI18n()
  console.log('Cards', props)
  return (
    <>
    </>
  )
}


// Export Component
export default ResourceCards

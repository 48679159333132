// @ts-nocheck
import React from 'react'
import { uniqueId } from 'lodash'
import * as componentTypes from '@ggs/components'
import { media } from '@ggs/gatsby/components/index'
import { ComponentLayout } from '@ggs/components/layout'
import { useVisibility } from '@ggs/components/hooks'
import { Box } from '@mui/system'
import ProductGridUkHomepage from '@ggs/components/paragraphs/ProductGrid/ProductGridUkHomepage'

const parseVisData = (entitySettingsValue) => {
  const parsed = JSON.parse(entitySettingsValue)
  const include = parsed.include
  const visibility = JSON.parse(parsed.visibility[0].data)

  return { include, visibility }
}

/**
 * @typedef {import('@ggs/types').ComponentProps} ComponentProps
 */

/**
 * @param {ComponentProps} props
 * @return {JSX.Element}
 */
export default function Component(props = {}) {
  const { type = '' } = props
  const components = {
    ...componentTypes.paragraphs,
    ...componentTypes.banners,
    ...componentTypes.cards,
    ...componentTypes.grids,
    ...componentTypes.common,
    ...media,
    ProductGridUkHomepage
  }

  if (!props) return null

  const { settings, entitySettingsValue, wrapperClassName, wrapperSx, ...rest } = props
  const componentLayoutProps = { wrapperClassName, wrapperSx }

  // const entitySettings = props?.entitySettingsValue ? parseVisData(props?.entitySettingsValue) : {}
  // '{"include":[{"value":"0"}],"visibility":[{"data":"[\\"10\\",\\"19\\",\\"10-19-365\\",\\"10-19-392\\",\\"9\\",\\"27\\",\\"9-27-365\\",\\"9-27-392\\"]"}]}'

  // const fullSettings = { ...settings, ...entitySettings }
  // settings 'exclude' (include: false) is being set to undefined somewhere previously in build step.
  // either figure out where this is happening or change visibility to be string list ['include', 'exclude', '']
  return (props && typeof components[type] !== 'undefined')
    ? (
      <ComponentSettings settings={settings} entitySettingsValue={entitySettingsValue}>
        <ComponentLayout {...componentLayoutProps}>
          {React.createElement(components[type], {
            key: uniqueId(props.id),
            ...rest,
          })}
        </ComponentLayout>
      </ComponentSettings>
    )
    // component doesn't exist yet
    : React.createElement(() => <div>The component {type} has not been created yet.</div>, {
      key: uniqueId('missing_component'),
    })
}

export const ComponentSettings = ({ settings = null, entitySettingsValue = null, children } = {}) => {
  // bug where include will be set to null when it should be false, vs actual null when vis is empty
  // const includeSafe = settings?.include ?? (Array.isArray(visibility) && visibility.length)
  // console.log('comp settings', settings, entitySettingsValue, fullSettings)
  const { shouldDisplay = true, debugSx, debugText } = useVisibility({ entitySettingsValue, settings })
  if (parseInt(process.env.GATSBY_DEBUG_PARAGRAPH_SETTINGS) === 1) {
    return (
      <>
        {children}
        <Box className='debug-settings' sx={debugSx}>
          {debugText}
        </Box>
      </>
    )
  } else {
    return shouldDisplay ? children : null
  }
}

import {
  gtmCheckoutValue,
  gtmCoupon,
  gtmCurrency,
  gtmItems,
} from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/gtagFromOrderParse'
import { fireCustomEvent } from '@ggs/components/ecommerceAnalytics/gtm/events/customEvents/fireCustomEvent'

export const fireAddShippingAddressSuccess = async ({ order }) => {
  fireCustomEvent({
    customEventName: 'add_shipping_address_success',
    dataLayer: {
      coupon: gtmCoupon(order),
      currency: gtmCurrency(order),
      value: gtmCheckoutValue(order),
      items: gtmItems(order),
    },
  })
}

export const fireAddShippingAddressFailure = async ({ order }) => {
  fireCustomEvent({
    customEventName: 'add_shipping_address_failure',
    dataLayer: {
      coupon: gtmCoupon(order),
      currency: gtmCurrency(order),
      value: gtmCheckoutValue(order),
      items: gtmItems(order),
    },
  })
}
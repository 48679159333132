// @ts-nocheck
/**
 * Mapping out account related data schemas.
 */
import * as PropTypes from 'prop-types'

export const DOCUMENT_STATUS = {
  // - Not able to replace
  NEW: 'NEW',
  REVIEW: 'REVIEW',
  VERIFIED: 'VERIFIED',
  ADMIN_UPLOAD: 'ADMIN_UPLOAD',
  // - Able to replace
  REJECTED: 'REJECTED',
}

export const BaseDocumentData = {
  checkedBy: null,
  checkedOn: null,
  content: null,
  createdOn: '',
  documentType: '',
  fileLocation: '',
  fileName: '',
  fileType: '',
  id: '',
  note: null,
  status: '',
  userId: '',
  verified: null,
}

export const DocumentDataProps = PropTypes.shape({
  checkedBy: PropTypes.string,
  checkedOn: PropTypes.string,
  content: PropTypes.string,
  createdOn: PropTypes.string,
  documentType: PropTypes.string,
  fileLocation: PropTypes.string,
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  id: PropTypes.string,
  note: PropTypes.string,
  status: PropTypes.string,
  userId: PropTypes.string,
  verified: PropTypes.string,
})

export const DocumentTypes = {
  financial: 'FINANCIAL',
  utility: 'UTILITY',
  government: 'GOVERNMENT',
  selfie: 'SELFIE',
  receipt: 'RECEIPT',
  forms: 'FORMS',
  edd: 'EDD',
  disclosure: 'BUS_DISCLOSURE',
  certificate: 'BUS_CERTIFICATE',
  address: 'BUS_ADDRESS',
  directors: 'BUS_DIRECTORS',
  shareholders: 'BUS_SHAREHOLDERS',
}

// @ts-nocheck
import { AddressView, PaymentSourceDetails, ReviewTitle } from '@ggs/components/ecomm'
import { Divider } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import { noop, omit, pick } from 'lodash'
import React, { useState } from 'react'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { hashValue } from '@ggs/utils'
import { addNotification, useDispatch } from '@ggs/store'
import { addBillingAddress, orderActionType } from '@ggs/commercelayer'
import { ADDRESS_FIELDS } from '@ggs/components/ecomm/Customer/AddressForm'
import { CircularProgress } from '@mui/material'

/**
 * @typedef {('checkout'|'complete')} BillingAddressReviewVariants
 */

/**
 * @typedef {Object} BillingAddressReviewProps
 * @property {string=} title
 * @property {Order} order
 * @property {BillingAddressReviewVariants=} variant
 * @property {function=} editPayment
 * @property {boolean=} showPayment
 * @property {boolean=} showSameAsControl
 * @property {string=} title
 */

/**
 * Checkout billing address review.
 * @param {BillingAddressReviewProps} props
 * @return {JSX.Element}
 */
const BillingAddressReview = ({
  title = '',
  order,
  variant = 'checkout',
  editPayment = noop,
  showPayment = true,
  showSameAsControl = false,
}) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { billing_address = null, shipping_address, payment_method: paymentMethod = null } = order
  const { clClient } = useCheckoutContext()

  const [address, setAddress] = useState(billing_address || shipping_address)
  const [billingMatchesShipping, setBillingMatchesShipping] = useState(
    shipping_address
      && billing_address
      && hashValue(omit(shipping_address, ['id', 'updated_at', 'created_at']) || '')
        === hashValue(omit(billing_address, ['id', 'updated_at', 'created_at']) || '')
  )
  const [checked, setChecked] = useState(billingMatchesShipping)
  const [loading, setLoading] = useState(false)

  const resetBillingSameAsShipping = () => {
    setLoading(true)
    dispatch(
      addBillingAddress(
        clClient,
        order,
        pick(shipping_address, [...Object.values(ADDRESS_FIELDS), 'metadata']),
        {
          onSuccess: (action) => {
            setBillingMatchesShipping(true)
            setChecked(true)
            setLoading(false)
            setAddress(shipping_address)
          },
          onError: (e) => {
            setLoading(false)
            dispatch(
              addNotification(
                orderActionType.UPDATE_ORDER_BILLING_ADDRESS,
                'error',
                t('ecomm:notice.errorResettingBillingAddress')
              )
            )
          },
        }
      )
    )
  }

  const titleVariant = variant === 'checkout' ? 'h3' : 'body1'
  const buttonLabel = variant === 'checkout' ? t('account:button.edit') : ''

  if (!billing_address || !paymentMethod) {
    return null
  }

  return (
    <Box
      className={'billing-address-review'}
      sx={{
        position: 'relative',
        '.loading': {
          display: loading ? 'block' : 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 33,
          background: 'rgba(255,255,255,0.5)',
          '& > *': {
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        },
        '.billing-address-review-payment': {
          mt: 5,
        },
      }}
    >
      <div className="loading">
        <CircularProgress />
      </div>
      <ReviewTitle
        title={t('account:label.billingAddress')}
        titleVariant={titleVariant}
        buttonLabel={buttonLabel}
        goToEdit={editPayment}
      />
      {showSameAsControl && (
        <FormControlLabel
          control={
            <Checkbox
              color={'secondary'}
              checked={checked}
              onChange={() => (checked ? editPayment() : resetBillingSameAsShipping())}
            />
          }
          label={t('ecomm:label.billingSameAsShippingAddress')}
        />
      )}
      <AddressView address={address} />
      {showPayment ? (
        <Box className={'billing-address-review-payment'}>
          <ReviewTitle
            title={title || t('ecomm:label.paymentMethod')}
            titleVariant={titleVariant}
          />
          <PaymentSourceDetails order={order} variant="review" />
        </Box>
      ) : null}
      {variant === 'checkout' && (
        <Divider
          sx={{
            mt: 5,
            mb: 5,
          }}
        />
      )}
    </Box>
  )
}

export default BillingAddressReview

import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'

export const PayPalIcon
  = '<img src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/44_Grey_PayPal_Pill_Button.png" alt="PayPal" />'

/**
 * @typedef {Object} PaymentPayPalChosenProps
 * @property {'payment' | 'review' | 'complete'=} variant
 */

/**
 * Chosen PayPal payment method indicator.
 * @param {PaymentPayPalChosenProps} props
 * @return {JSX.Element}
 */
export default function PaymentPayPalChosen({ variant = 'payment' }) {
  const { t } = useI18n()
  return (
    <Box>
      {variant === 'payment' && <p>{t('ecomm:label.chosenPaymentMethod')}</p>}
      <p dangerouslySetInnerHTML={{ __html: PayPalIcon }} />
    </Box>
  )
}

import React from 'react'
// @ts-ignore
import fetch from 'isomorphic-fetch'
import { ApolloClient, ApolloProvider as AP, InMemoryCache } from '@apollo/client'

const options = {
  uri: '/api/search',
  // @ts-ignore
  fetch,
  cache: new InMemoryCache(),
}

// If bearer token is set, add it to the headers.
/*if (GQL_FE_AUTH_TOKEN) {
  Object.assign(options, {
    headers: {
      Authorization: `Bearer ${GQL_FE_AUTH_TOKEN}`,
    },
  })
}*/

const client = new ApolloClient(options)

// console.log('Apollo', GQL_FE_SEARCH, GQL_FE_AUTH_TOKEN)

/**
 * @typedef {{children: Element|JSX.Element}} ApolloProviderProps
 */
/**
 *
 * @param {ApolloProviderProps} props
 * @return {JSX.Element}
 */
export function ApolloProvider({ children }) {
  // @ts-ignore
  return <AP client={client}>{children}</AP>
}

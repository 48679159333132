/* eslint-disable max-len */
import React from 'react'
import ProductPurchaseActions from '@ggs/components/ecomm/ProductPurchaseActions/ProductPurchaseActions'
import Box from '@mui/material/Box'
import useProductPurchaseOptions from './useProductPurchaseOptions'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 * @typedef {import('@ggs/types').WhereToBuy} WhereToBuy
 * @typedef {import('@ggs/types').Product} Product
 * @typedef {import('@ggs/types').OptionalProductCartItem}
 *   OptionalProductCartItem
 */

/**
 * @typedef {Object} ProductPurchaseOptions
 * @property {Product} product
 * @property {ProductOption|null} currentOption
 * @property {Array<String>=} includedItems
 */

/**
 * @typedef {import('@commercelayer/sdk').LineItemCreate} LineItemCreate
 */

/**
 Will be provided a list of salable SKUs, whereToBuy modal(s), and possibly a contactRep modal (or it should be just a link) displayed only when users are HCP
 We will need to get the form workspace up to snuff to support just the select field, and add to cart button with dynamic validation.
 Simple use cases:
 1. We have a single product option, with no optional products available.
 In this case, we just use the SKU for the current store/market, and display the price, with a quantity selector.
 CL will container some custom meta data for the SKUs min/max amounts, which will have to inform the quantity selector.
 2. The product has no salable SKU, and thus cannot be purchased
 In this case, the user can only use the whereToBuy, and sees a simple notice of unavailable.
 Complex use cases:
 1. There are multiple product options
 This component here will need to preload all SKU prices from all of the options up front
 On load it would need to display, while no option is selected and we're on default, to use the lowest price found, prefixed with a 'staring from' UI label
 The use will not be able to select a quantity or click add to cart until the first select a product option
 Once they do select a product option, the SKU/price will update to reflect the chosen option, and they can now add to cart
 This selection will use the meta data from the SKU price list entry to limit the min/max amount
 2. When there are optional products included, and the current product is not salable:
 In this case we can display the optional items that go with this product, but only as simple line items
 From the optionalProducts data provided, we can generate the list with the product name/URL, and lowest starting price from options
 3. When there are multiple optional products, and the current product is salable:
 The normal product quantity/add to card will work as part complex case #1 above
 But, there could be additional cart line items that may be included when they add to card. These will only be actionable IF these products have a salable SKU in the current store/market.
 For the optional products in this scenario we must list the products with a checkbox, name, lowest price:
 a) The product has only 1 product option (default), and the product name will be clickable
 b) The product has multiple options, and there will be a label like 'select a size' or something for them click with the product name beside it
 When clicking option a) (single product option) as per above, a modal will open with:
 From the productCardContent fragment data, this product option will be able to display in the modal:
 * Name
 * Listing image
 * Listing description
 * Link to learn more
 * Price
 * Quantity min/max
 * Finally, they can add to the order
 When clicking option b) (multiple product options) this modal will have the same as a) above, with a option selector
 * This option selector will let the user first pick the product option they
 * desire for the product This will update the visible price, and min/max
 * amounts for quantity Finally, they can add to the order
 Once the user has added optional products, the list on the main product section will update to show their current selection, but nothing has yet been added to the cart.
 Finally, when the user is happy, they click add to card, and the main product, as well as optional products then all get added to the cart.
 * @param {ProductPurchaseOptions} props
 * @return {JSX.Element}
 */
export default function ProductPurchaseOptions({ product, currentOption, includedItems, skuPrice }) {
  // const { displayOptionalProducts } = usePurchasingOptionalProducts(props)
  const { addToCart } = useProductPurchaseOptions({ product, currentOption, includedItems, skuPrice })

  return (
    <Box
      className="product-purchase-options"
      sx={{
        mb: 4,
        '& .divider': {
          mt: 4,
          mb: 0,
        },
      }}
    >
      {/*{displayOptionalProducts}*/}
      <ProductPurchaseActions
        addToCart={addToCart}
        currentOption={currentOption}
        product={product}
      />
    </Box>
  )
}

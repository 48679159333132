import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import {
  gtmCheckoutValue,
  gtmCoupon,
  gtmCurrency,
  gtmItems
} from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/gtagFromOrderParse'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'

/**
 *   add_shipping_info
 *   This event signifies a user has submitted their shipping information.
 *
 *   Parameters
 *   Name  Type  Required
 *   Example value  Description
 *
 *   currency  string  Yes*
 *   USD  Currency of the items associated with the event, in 3-letter ISO 4217 format.
 *
 *   value  number  Yes* currency is required if you set value.
 *   7.77  The monetary value of the event.
 *
 *   coupon  string  No
 *   SUMMER_FUN  The coupon name/code associated with the event.
 *
 *   shipping_tier  string  No
 *   Ground  The shipping tier (e.g. Ground, Air, Next-day) selected for delivery of the purchased item.
 *
 *   items  Array<Item>  Yes
 *   The items for the event.
 *
 */

const ShippingEvent = {
  currency: 'usd',
  value: 7.77,
  coupon: 'SUMMER_FUN',
  shipping_tier: 'GROUND',
  items: [
    /* GTMItems(commerce item) */
  ],
}

export const fireAddShippingInfo = async ({ order }) => {
  // const order = await getOrderFromRef()
  // const { id: orderId } = order
  const shipments = order?.shipments

  if (!shipments) return
  for (const shipment of shipments) {
    pushDataLayer({
      eventName: EaActionType.addShippingInfo,
      dataLayer: {
        coupon: gtmCoupon(order),
        currency: gtmCurrency(order),
        value: gtmCheckoutValue(order),
        items: gtmItems(order),
        shipping_tier: shipment.shipping_method?.name,
      },
    })
  }
}

// @ts-nocheck
import {noop, values} from 'lodash'
import {useI18n} from '@ggs/gatsby/lib'
import * as PropTypes from 'prop-types'

export const FormPropTypes = {
  field: PropTypes.shape({}),
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  content: PropTypes.shape({
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    middle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  }),
}

export const FormDefaultProps = {
  fields: {},
  actions: [],
  content: {
    header: null,
    middle: null,
    footer: null,
  },
}

export const FieldTypes = {
  text: 'text',
  email: 'email',
  tel: 'tel',
  password: 'password',
  textarea: 'textarea',
  checkbox: 'checkbox',
  date: 'date',
  select: 'select',
  switch: 'switch',
  dropdown: 'select',
  autoComplete: 'autocomplete',
  radioGroup: 'radiogroup',
  currency: 'currency',
  number: 'number',
  dollar: 'dollar',
  hidden: 'hidden',
  dropdownTreeSelect: 'dropdownTreeSelect',
  selectableAccordionList: 'selectableAccordionList',
  nocSelect: 'nocSelect',
  accordionCheckbox: 'accordionCheckbox',
  formCollection: 'formCollection',
  subFormCollection: 'subFormCollection',
  checkboxModal: 'checkboxModal',
  file: 'file',
  content: 'content',
  shippingSelect: 'shippingSelect',
  controlLabel: 'controlLabel'
}

export const SelectStyles = {
  multiple: 'multiple',
  checkboxes: 'checkboxes',
  multipleCheckboxes: 'multiple-checkboxes',
  autocomplete: 'autocomplete',
}

export const LabelPlacement = {
  end: 'end',
  start: 'start',
  top: 'top',
  bottom: 'bottom',
}

export const FormikContextFields = {
  dirty: 'dirty',
  errors: 'errors',
  isSubmitting: 'isSubmitting',
  isValid: 'isValid',
  isValidating: 'isValidating',
  status: 'status',
  submitCount: 'submitCount',
  values: 'values',
  setValues: 'setValues',
}

export const FormFieldAutoCompleteOptionsProp = PropTypes.shape({
  forceLoad: PropTypes.bool,
  loader: PropTypes.func,
  transpose: PropTypes.func,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  reset: PropTypes.bool,
  freeSolo: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
})

export const FormFieldAutoCompleteDefaults = {
  forceLoad: false,
  loader: noop,
  transpose: (value) => value,
  reset: false,
  freeSolo: false,
  openOnFocus: true,
  onChange: noop,
  onBlur: null,
  // Handled dynamically.
  options: undefined,
  valueKey: 'value',
  labelKey: 'label',
}

export const FormFieldProps = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(values(FieldTypes)).isRequired,
  label: PropTypes.string,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  valueChanged: PropTypes.func,
  autoCompleteOptions: FormFieldAutoCompleteOptionsProp,
  autoComplete: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  displayed: PropTypes.bool,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  valid: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  descriptor: PropTypes.string,
  icon: PropTypes.element,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.string,
}

export const FormFieldDefaults = {
  disabled: false,
  onBlur: noop,
  valid: undefined,
  options: [],
}

export const HeardAboutUs = () => {
  const {t} = useI18n()
  return [
    {
      value: '',
      label: t('steps.fields.chooseAnOption'),
    },
    {
      value: 'Google Search Result',
      label: 'Google Search Result',
    },
    {
      value: 'Google Ad',
      label: 'Google Ad',
    },
    {
      value: 'Other Online Ad',
      label: 'Other Online Ad',
    },
    {
      value: 'Online Review/Video',
      label: 'Online Review/Video',
    },
    {
      value: 'App Store',
      label: 'App Store',
    },
    {
      value: 'Referral Code/Friend',
      label: 'Referral Code/Friend',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ]
}

export const YesNoOption = () => {
  const {t} = useI18n()
  return [
    {
      value: '',
      label: t('steps.fields.chooseAnOption'),
    },
    {
      label: t('steps.options.yes'),
    },
    {
      label: t('steps.options.no'),
    },
  ]
}

const checkIfValueTrue = (value) => value === true || value === 'true'

// import { getWindow } from '@ggs/utils'
/** @typedef {import('@ggs/types').Globals} Globals */
// @ts-ignore
import globals from '../../../definitions/globals.json'

/** @type {Globals} */
const initialGlobalState = {
  SITE_URL: '',
  LANG_CODES: [],
  LANGUAGES: [],
  STORES: [],
}

// let globals = initialGlobalState
// import('../../definitions/globals.json').then((g) => {
//   globals = g
// }).catch((e) => {
//   console.error('Gobals failure', e)
// })

// const origin = getWindow('location.origin') || ''
//
// fetch('/globals.json').then((res) => {
//   return res.json()
// }).then((g) => {
//   globals = g
// }).catch((e) => {
//   console.error('Gobals failure', e)
// })

/**
 *
 * @return {Globals}
 */
export default function getGlobals() {
  /*/!** @type {Globals} *!/
  let globals = {
    SITE_URL: '',
    LANG_CODES: [],
    LANGUAGES: [],
    STORES: [],
  }
  try {
    // @ts-ignore
    globals = window?.globals
  } catch (e) {
    // do nothing
  }

  console.log('getGlobals', globals)*/
  // console.log('getGlobals', globals)

  return globals || initialGlobalState
}

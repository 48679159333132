// @ts-nocheck
import React, { useMemo } from 'react'
import { GridContainer } from '@ggs/components/layout'
import { colors } from '@ggs/styles'
import Grid from '@mui/material/Grid'
import { find, uniqBy, uniqueId } from 'lodash'
import { Link } from '@ggs/gatsby/components/nav'
import Box from '@mui/material/Box'
import { FooterMenu } from '@ggs/components/nav'
import { Text } from '@ggs/components/paragraphs'
// Assets.
import './Footer.scss'
import useFooterSx from '@ggs/components/layout/Footer/useFooterSx'
import { useOneTrustContext } from '@ggs/components/onetrust/OneTrustContext'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 * @typedef {import('@ggs/types').Store} Store
 */
/**
 * @typedef {Object} FooterProps
 * @property {Array<DrupalMenuLink>=} menuLinks
 * @property {Store=} store
 * @property {Array<DrupalMenuLink>=} socialLinks
 */
/**
 * @param {FooterProps} props
 * @return {JSX.Element}
 */
export default function Footer(props) {
  const { menus: { footer: menuLinks = [], socialLinks = [] } = {}, currentStore } = props
  const [brandMenu, marketingMenu, legalMenu] = useMemo(() => {
    return [
      uniqBy(find(menuLinks, ['url', '#brand'])?.menuItems || [], 'uuid'),
      uniqBy(find(menuLinks, ['url', '#marketing'])?.menuItems || [], 'uuid'),
      uniqBy(find(menuLinks, ['url', '#legal'])?.menuItems || [], 'uuid'),
    ]
  }, [menuLinks])
  const sx = useFooterSx()
  const { footerCopyright = '' } = currentStore || {}

  const {
    PrivacySettingsModalLink,
    CookieListModalLink,
  } = useOneTrustContext()

  return (
    <Box id={'footer'} sx={sx}>
      <GridContainer className="footer__branding" fullWidthBackground backgroundColor={colors.blue}>
        <Grid container className="footer__branding-inner" justifyContent="space-between">
          {brandMenu.map(({ label, url, id }) => (
            <Grid
              className="footer__branding-item"
              item
              key={uniqueId(`brand-menu-${id}`)}
              xs={12}
              md={4}
              textAlign="center"
            >
              <Link href={url}>{label}</Link>
            </Grid>
          ))}
        </Grid>
      </GridContainer>
      <FooterMenu menuItems={marketingMenu} store={currentStore} socialLinks={socialLinks}/>
      <GridContainer
        sx={{ py: { xs: 3, md: 5 } }}
        className="footer__legal"
        fullWidthBackground
        backgroundColor={colors.black}
      >
        <Grid
          className="footer__legal-inner"
          container
          columnSpacing={{ lg: 5 }}
          rowSpacing={{ xs: 4 }}
        >
          <Grid className="footer__copyright" item xs={12} md={6}>
            <Text textContent={footerCopyright}/>
          </Grid>
          <Grid className="footer__legal-menu" item xs={12} md={6}>
            <Grid
              className="footer__legal-menu-inner"
              container
              spacing={{ xs: 1 }}
              justifyContent="space-between"
            >
              {legalMenu.map(({ label, url, id }) => (
                <Grid
                  className="footer__legal-menu-item"
                  item
                  key={uniqueId(`legal-menu-${id}`)}
                  xs={12}
                  md={6}
                  lg={2}
                >
                  <Link href={url}>{label}</Link>
                </Grid>
              ))}
              <Grid
                className="footer__legal-menu-item"
                item
                key={uniqueId('legal-menu--privacy-settings')}
                xs={12}
                md={6}
                lg={2}
              >
                <CookieListModalLink/>
              </Grid>
              <Grid
                className="footer__legal-menu-item"
                item
                key={uniqueId('legal-menu--privacy-settings')}
                xs={12}
                md={6}
                lg={2}
              >
                <PrivacySettingsModalLink/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridContainer>
      {/*{cookieListModal}*/}
    </Box>
  )
}

import { useEffect, useState } from 'react'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import CHECKOUT_STEP_ENUMS from '../Checkout/enums/CHECKOUT_STEP_ENUMS'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'

const { ORDER } = CHECKOUT_STEP_ENUMS

export const useShippingInformation = () => {
  const {
    order,
    markStepComplete,
    checkout,
    isCurrentStepComplete,
    // shippingAddressController,
    handlers: { goToPayment },
    resetCheckoutExceptShippingAddress,
  } = useCheckoutContext()
  const { dispatchClearShipping, fireAddShippingInfo } = useCheckoutAnalytics({ order })
  const {
    checkoutState: { shippingAddressId, shippingMethodId },
  } = checkout

  useEffect(() => {
    if (!shippingMethodId) {
      resetCheckoutExceptShippingAddress()
    }
  }, [shippingMethodId])

  //
  // const {
  //   ['shipping_address']: {
  //     address: shippingAddress,
  //     geoCodes: {
  //       stateCodeSelected,
  //       countryCodeSelected,
  //     },
  //     isoCodes,
  //     setStateCodeSelected,
  //     setCountryCodeSelected,
  //     addressType,
  //     addressFormSubmit,
  //     addressFormatted,
  //     setAddressFormatted,
  //     setIsoCodes,
  //     setAddressFormSubmit,
  //     onAddressSubmit,
  //     onAddressSubmitIsoCodes,
  //     set: setShippingAddress,
  //     addressValuesToIso,
  //   }
  // } = shippingAddressController

  const [shippingAddressComplete, setShippingAddressComplete] = useState(() => {
    // if (isCurrentStepComplete()) {
    return !!shippingAddressId
    // } else {
  })
  const [shippingMethodComplete, setShippingMethodComplete] = useState(() => {
    if (isCurrentStepComplete() || shippingAddressComplete) {
      return !!shippingMethodId
    } else {
      return false
    }
  })

  useEffect(() => {
    setShippingAddressComplete(!!shippingAddressId)
  }, [shippingAddressId])

  // TODO: for implementing tax calc count
  // const taxCalcTrue = ({ tax_calculations_count = 0 } = {}) =>
  //   !Number.isNaN(tax_calculations_count) && Number.tax_calculations_count > 0

  const onSuccessAddress = (/** @type {any} */ values) => {
    setShippingAddressComplete(true)
    setShippingMethodComplete(false)
  }

  const onSuccessMethod = (values) => {
    setShippingMethodComplete(true)
    markStepComplete(ORDER)
    goToPayment()
  }

  const checkCurrentStepComplete = () =>
    isCurrentStepComplete() && shippingAddressComplete && shippingMethodComplete

  const clearShippingInformation = () => {
    setShippingAddressComplete(false)
    setShippingMethodComplete(false)
  }

  const clearShippingMethod = () => {
    setShippingMethodComplete(false)
  }

  const showShippingAddressReview = () => shippingAddressId && shippingAddressComplete
  const showShippingMethodReview = () => {
    return shippingMethodId && shippingMethodComplete
  }
  return {
    order,
    shippingAddressComplete,
    shippingMethodComplete,
    clearShippingInformation,
    clearShippingMethod,
    showShippingAddressReview,
    showShippingMethodReview,
    onSuccessAddress,
    onSuccessMethod,
    enableConfirm: checkCurrentStepComplete,
    goToPayment,
    markStepComplete,
  }
}

import React from 'react'
import Text from './Text/Text'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 * @typedef {Object} Divider
 * @property {SxProps=} sx
 */
/**
 * A simple text divider.
 * @param {Divider} props
 * @return {JSX.Element}
 */
export default function Divider({ sx = {} }) {
  Object.assign(sx, {
    px: {
      md: 5,
      xs: 2
    },
    '& > .MuiGrid-root': {
      width: '100%',
      ml: 0,
      '.MuiGrid-item': {
        pl: 0
      }
    }
  })
  return <Text sx={sx} className="divider" textContent={'<hr/>'} />
}

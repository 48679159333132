import React from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { Divider, Text } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { Button } from '@ggs/gatsby/components/nav'

/**
 * @return {JSX.Element}
 */
export const WontBeChargedLine = () => {
  const { t } = useI18n()
  return (
    <Text sx={{ mt: 2 }}>
      <p className="small">{t('ecomm:notice.wontBeChargedTillPlacingOrder')}</p>
    </Text>
  )
}

/**
 * Stripe Payment loader
 * @param {{paypalButton: JSX.Element}} props
 * @return {JSX.Element}
 */
export default function StripeLoader({ paypalButton }) {
  const { t } = useI18n()

  const stripDivHeight = 65
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={stripDivHeight} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={stripDivHeight} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height={stripDivHeight} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={stripDivHeight} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" height={stripDivHeight} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={16} />
        <br />
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Grid container spacing={2}>
          <Grid item>
            <Button
              className="stripe-payment__submit"
              // @ts-ignore
              type="submit"
              sx={{
                mt: 2,
              }}
              disabled
            >
              <span className="form__action-label">{t('ecomm:button.savePaymentMethod')}</span>
            </Button>
          </Grid>
          <Grid item sx={{ mt: 1.5 }}>
            <p>{t('ecomm:label.orPayAnotherWay')}</p>
          </Grid>
          <Grid item sx={{ opacity: 0.5, cursor: 'default', mt: 2 }}>
            {paypalButton}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <WontBeChargedLine />
      </Grid>
    </Grid>
  )
}

import React from 'react'
import Box from '@mui/material/Box'
import { ImageStyles } from '@ggs/gatsby/components/media'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */

/**
 * Shopping cart image ads block.
 @param {{
  cartCtaImage: ImageStyles
 }} props
 * @return {JSX.Element}
 */
export default function CartImageAds({ cartCtaImage }) {
  return (
    <Box className="cart-ads">
      <ImageStyles {...cartCtaImage} />
    </Box>
  )
}

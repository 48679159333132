import { CommerceLayerClient } from '@commercelayer/sdk'
import { clClientFactory } from '../services/CommerceLayerClientFactory'
import { defaults, delay, first, isObject } from 'lodash'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  AddressCreate,
  AddressUpdate,
  ApiErrorAction,
  Callbacks,
  Customer,
  CustomerSignup,
  LineItemCreate,
  LineItemUpdate,
  Order,
  OrderCreate,
  OrderUpdate,
  PaypalPaymentCreate,
  PaypalPaymentUpdate,
  ShipmentUpdate,
  StripePaymentCreate,
  StripePaymentUpdate,
} from '../lib/types'
import { handleApiError, invokeErrorCallback, invokeSuccessCallback } from './CommonActions'

/**
 * Order coupon error codes.
 * @enum {string}
 */
export const ORDER_COUPON_ERROR_CODES = {
  failed_to_apply: 'failed_to_apply',
  invalid_combo: 'invalid_combo',
  noent_coupon: 'noent_coupon',
  expired_coupon: 'expired_coupon',
  too_short: 'too_short',
}

/**
 * Order action types.
 * @enum {string}
 */
export const orderActionType = {
  ADD_ORDER_BILLING_ADDRESS: 'ADD_ORDER_BILLING_ADDRESS',
  ADD_ORDER_COUPON: 'ADD_ORDER_COUPON',
  ADD_ORDER_ITEMS: 'ADD_ORDER_ITEMS',
  ADD_ORDER_PAYMENT_SOURCE_PAYPAL: 'ADD_ORDER_PAYMENT_SOURCE_PAYPAL',
  ADD_ORDER_PAYMENT_SOURCE_STRIPE: 'ADD_ORDER_PAYMENT_SOURCE_STRIPE',
  ADD_ORDER_SHIPPING_ADDRESS: 'ADD_ORDER_SHIPPING_ADDRESS',
  CREATE_DRAFT_ORDER: 'CREATE_DRAFT_ORDER',
  DELETE_ORDER_PAYMENT_SOURCE_STRIPE: 'DELETE_ORDER_PAYMENT_SOURCE_STRIPE',
  EMPTY_DRAFT_ORDER: 'EMPTY_DRAFT_ORDER',
  GET_ORDER: 'GET_ORDER',
  GET_ORDER_PAYMENT_METHODS: 'GET_ORDER_PAYMENT_METHODS',
  GET_ORDER_SHIPPING_METHODS: 'GET_ORDER_SHIPPING_METHODS',
  REMOVE_ORDER_COUPON: 'REMOVE_ORDER_COUPON',
  REMOVE_ORDER_ITEMS: 'REMOVE_ORDER_ITEMS',
  RESET_ORDER_SHIPPING_METHOD: 'RESET_ORDER_SHIPPING_METHOD',
  RESET_ORDER_PAYMENT_METHOD: 'RESET_ORDER_PAYMENT_METHOD',
  SET_CUSTOMER_SIGNUP: 'SET_CUSTOMER_SIGNUP',
  SET_ORDER_BILLING_AS_SHIPPING_ADDRESS: 'SET_ORDER_BILLING_AS_SHIPPING_ADDRESS',
  SET_ORDER_CUSTOMER: 'SET_ORDER_CUSTOMER',
  SET_ORDER_PAYMENT_METHOD: 'SET_ORDER_PAYMENT_METHOD',
  SET_ORDER_SHIPPING_AS_BILLING_ADDRESS: 'SET_ORDER_SHIPPING_AS_BILLING_ADDRESS',
  SET_ORDER_SHIPPING_METHOD: 'SET_ORDER_SHIPPING_METHOD',
  SET_ORDER_STATUS: 'SET_ORDER_STATUS',
  UPDATE_ORDER_BILLING_ADDRESS: 'UPDATE_ORDER_BILLING_ADDRESS',
  UPDATE_ORDER_ITEMS: 'UPDATE_ORDER_ITEMS',
  UPDATE_ORDER_PAYMENT_SOURCE_PAYPAL: 'UPDATE_ORDER_PAYMENT_SOURCE_PAYPAL',
  UPDATE_ORDER_PAYMENT_SOURCE_STRIPE: 'UPDATE_ORDER_PAYMENT_SOURCE_STRIPE',
  UPDATE_ORDER_SHIPPING_ADDRESS: 'UPDATE_ORDER_SHIPPING_ADDRESS',
  DELETE_ORDER_SHIPPING_ADDRESS: 'DELETE_ORDER_ADDRESS',
  DELETE_ORDER_BILLING_ADDRESS: 'DELETE_ORDER_BILLING_ADDRESS',
  CLEAR_PLACED_ORDER: 'CLEAR_PLACED_ORDER',
  CLEAR_ACTIVE_ORDER: 'CLEAR_ACTIVE_ORDER'
}

/**
 * @typedef {Object} OrderActionPayload
 * @property {Order} order CL order entity
 */

/**
 * @typedef {Object} OrderAction
 * @property {orderActionType} type Action type key
 * @property {OrderActionPayload} payload Action input data
 */

/**
 * Post dispatch action hook.
 * @param {CommerceLayerClient} clClient CL API client
 * @param {ThunkDispatch.<any, null, OrderAction>} dispatch
 * @param {orderActionType} actionType Action type
 * @param {Order} order Action order object
 */
export const postDispatchActionHook = (clClient, dispatch, actionType, order) => {
  const resetPaymentMethodTriggerActions = [
    orderActionType.ADD_ORDER_COUPON,
    orderActionType.ADD_ORDER_ITEMS,
    orderActionType.REMOVE_ORDER_COUPON,
    orderActionType.REMOVE_ORDER_ITEMS,
    orderActionType.RESET_ORDER_SHIPPING_METHOD,
    orderActionType.SET_ORDER_SHIPPING_METHOD,
    orderActionType.UPDATE_ORDER_ITEMS,
  ]

  // Clear order payment method after execution of any trigger action, this is
  // to avoid order inconsistencies due to payment authorization created for
  // different amount value, we need to enforce new payment authorization in
  // this case.
  if (resetPaymentMethodTriggerActions.includes(actionType)
    && Array.isArray(order?.line_items)
    && order.line_items.length) {
    dispatch(resetOrderPaymentMethod({ clClient, order }))
  }
}

/**
 * Dispatch order data action and invoke success callbacks.
 * @param {CommerceLayerClient} clClient CL API client
 * @param {ThunkDispatch.<any, null, OrderAction>} dispatch
 * @param {orderActionType} actionType Action type
 * @param {Order} order Action order object
 * @param {Callbacks=} callbacks
 */
export const dispatchDataAction = (clClient, dispatch, actionType, order, callbacks) => {
  const action = composeDataAction(actionType, order)
  dispatch(action)
  invokeSuccessCallback(callbacks, action)
  postDispatchActionHook(clClient, dispatch, actionType, order)
}

/**
 * Retrieve order in latest state.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} orderId ID of the order entity to update
 * @return {Promise<Order>} CL order
 */
export const retrieveOrder = async (clClient, orderId) => { //, setOrderFn = noop) => {
  const client = clClient ?? clClientFactory.getClient()

  if (!orderId) {
    throw new Error('Order ID is required to retrieve an order')
  }

  const orderInfo = await client.orders.retrieve(orderId, {
    include: [
      'authorizations',
      'available_payment_methods',
      'billing_address',
      'captures',
      'customer',
      'line_items',
      'payment_method',
      'payment_source',
      'shipments.available_shipping_methods',
      'shipments.shipping_method',
      'shipments.available_shipping_methods.delivery_lead_time_for_shipment',
      'shipping_address',
      'transactions',
    ],
  })
  // setOrderFn(orderInfo)

  return orderInfo
}

/**
 * Process order update action and retrieve order in latest state.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} orderId ID of the order entity to update
 * @param {OrderUpdate} updateData Order update properties / values
 * @return {Promise<Order>} Updated order
 */
const updateOrder = async (clClient, orderId, updateData) => {
  await clClient.orders.update(updateData)
  return await retrieveOrder(clClient, orderId)
}

/**
 * Process order update action and retrieve order in latest state.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order Current order
 * @param {Object} metadata Order metadata to apply
 * @return {Promise<Order>} Updated order
 */
export const updateOrderMetadata = async (clClient, order, metadata) => {
  const updateData = {
    id: order.id,
    metadata: {
      ...order.metadata,
      ...metadata,
    },
  }
  await clClient.orders.update(updateData)
  return await retrieveOrder(clClient, order.id)
}

/**
 * Associate order reference to order item.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order Commerce layer order entity
 * @param {LineItemCreate} orderItem Order item
 * @return {LineItemCreate} Order item augmented with order reference
 */
const addOrderReferenceToItem = (clClient, order, orderItem) => {
  return {
    ...orderItem,
    order: clClient.orders.relationship(order.id),
  }
}

/**
 * Compose a order action.
 *
 * @param {orderActionType} actionType Action type
 * @param {Order|null} order Commerce layer order entity
 * @return {OrderAction} Order data action
 */
const composeDataAction = (actionType, order) => {
  return {
    type: actionType,
    payload: {
      order,
    },
  }
}

/**
 * Create CL draft order.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Array<LineItemCreate>|null} orderItems Order items
 * @param {OrderCreate} data Extra order data (like metadata)
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const createOrder = (clClient, orderItems = null, data = null, callbacks) => {
  return async (dispatch) => {
    try {
      const order = await clClient.orders.create({
        guest: true,
        ...defaults({}, data),
      })

      if (orderItems) {
        dispatch(addOrderItems(clClient, order, orderItems, callbacks))
      }

      dispatchDataAction(clClient, dispatch, orderActionType.CREATE_DRAFT_ORDER, order, callbacks)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Add items to CL draft order.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the line items
 * @param {Array<LineItemCreate>} orderItems Order items to add
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const addOrderItems = (clClient, order, orderItems, callbacks) => {
  console.log(order, orderItems)
  return async (dispatch) => {
    try {
      const createPromises = orderItems.map((orderItem) => {
        const orderItemsWithOrder = addOrderReferenceToItem(clClient, order, orderItem)
        return clClient.line_items.create(orderItemsWithOrder)
      })

      await Promise.all(createPromises)
      const updatedOrder = await clClient.orders.retrieve(order.id, {
        include: ['line_items'],
      })

      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.ADD_ORDER_ITEMS,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Update CL line items.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the line items, used for order
 *   reload after the operation
 * @param {Array<LineItemUpdate>} orderItems Order items to update
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const updateOrderItems = (clClient, order, orderItems, callbacks) => {
  return async (dispatch) => {
    try {
      const updatePromises = orderItems.map((orderItem) => {
        // @ts-ignore
        return clClient.line_items.update(orderItem, { include: ['order'] })
      })

      await Promise.all(updatePromises)
      const updatedOrder = await clClient.orders.retrieve(order.id, {
        include: ['line_items'],
      })

      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.UPDATE_ORDER_ITEMS,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Remove CL line items.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the line items, used for order
 *   reload after the operation
 * @param {Array<string>} lineItemIds Order line items IDs to remove
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const removeOrderItems = (clClient, order, lineItemIds, callbacks) => {
  return async (dispatch) => {
    try {
      const removePromises = lineItemIds.map((lineItemId) => {
        // @ts-ignore
        return clClient.line_items.delete(lineItemId)
      })

      await Promise.all(removePromises)
      const updatedOrder = await clClient.orders.retrieve(order.id, {
        include: ['line_items'],
      })

      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.REMOVE_ORDER_ITEMS,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Add order promotion coupon code.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the coupon code
 * @param {String} couponCode Coupon code to apply
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const addCouponCode = (clClient, order, couponCode, callbacks) => {
  return async (dispatch) => {
    try {
      const updateData = {
        id: order.id,
        coupon_code: couponCode,
      }

      const updatedOrder = await updateOrder(clClient, order.id, updateData)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.ADD_ORDER_COUPON,
        updatedOrder,
        callbacks
      )

      const cleanNonEligibleItemsCoupon = () => {
        dispatch(removeCouponCode(clClient, updatedOrder))
      }

      // When coupon don't resulted in a discount (due to non eligible line
      // items) remove it silently to avoid confusions when customer add
      // eligible line item afterwards.
      if (updatedOrder.discount_amount_cents === 0) {
        delay(cleanNonEligibleItemsCoupon, 1000)
      }
    } catch (error) {
      // handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Remove order promotion coupon code.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the coupon code
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const removeCouponCode = (clClient, order, callbacks) => {
  return async (dispatch) => {
    try {
      const updateData = {
        id: order.id,
        coupon_code: '',
      }

      const updatedOrder = await updateOrder(clClient, order.id, updateData)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.REMOVE_ORDER_COUPON,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Empty draft order.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order to delete
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const emptyOrder = (clClient, order, callbacks) => {
  return async (dispatch) => {
    try {
      if (!Array.isArray(order.line_items)) {
        return
      }

      const deletePromises = order.line_items.map((orderItem) => {
        return clClient.line_items.delete(orderItem.id)
      })

      await Promise.all(deletePromises)
      const updatedOrder = await clClient.orders.retrieve(order.id, {
        include: ['line_items'],
      })

      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.EMPTY_DRAFT_ORDER,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Add order shipping address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the address
 * @param {AddressCreate} address Address (shipping) to create and associate to
 *   the order
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const addShippingAddress = (clClient, order, address, callbacks) => {
  return async (dispatch) => {
    try {
      const createdAddress = await clClient.addresses.create(address)
      const updateData = {
        id: order.id,
        shipping_address: clClient.addresses.relationship(createdAddress.id),
        customer_email: createdAddress.email,
        // @ts-ignore
        _update_taxes: true
      }

      const retryIfTaxFailure = async ({ currentAttempt = 0, maxAttempts = 3 }) => {
        if (currentAttempt === maxAttempts) {
          callbacks.onError()
        }
        const updatedOrder = await updateOrder(clClient, order.id, updateData)
        if (updatedOrder.tax_calculations_count) {
          // taxes calculated successfully
          dispatchDataAction(
            clClient,
            dispatch,
            orderActionType.ADD_ORDER_SHIPPING_ADDRESS,
            updatedOrder,
            callbacks
          )
        } else {
          setTimeout(async () => {
            await retryIfTaxFailure({ currentAttempt: currentAttempt + 1, maxAttempts })
          }, 2500 * currentAttempt)
        }
      }

      await retryIfTaxFailure({ currentAttempt: 0, maxAttempts: 3 })

    } catch (error) {
      console.log(error)
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Update address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the address
 * @param {AddressUpdate} address Address (shipping) to update
 * @param {('shipping'|'billing')} type Address type
 * @return {Promise<Order>} Updated order
 */
const updateAddress = async (clClient, order, address, type) => {
  const updatedAddress = await clClient.addresses.update(address)
  const updateData = {
    id: order.id,
    [`${type}_address`]: clClient.addresses.relationship(updatedAddress.id),
    _update_taxes: true
  }

  return await updateOrder(clClient, order.id, updateData)
}

/**
 * Update order shipping address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the address
 * @param {AddressUpdate} address Address (shipping) to update
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const updateShippingAddress = (clClient, order, address, callbacks) => {
  return async (dispatch) => {
    try {
      const updatedOrder = await updateAddress(clClient, order, address, 'shipping')
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.UPDATE_ORDER_SHIPPING_ADDRESS,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Add order billing address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the address
 * @param {AddressCreate} address Address (billing) to create and associate to
 *   the order
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const addBillingAddress = (clClient, order, address, callbacks) => {
  return async (dispatch) => {
    try {
      const createdAddress = await clClient.addresses.create(address)
      const updateData = {
        id: order.id,
        billing_address: clClient.addresses.relationship(createdAddress.id),
      }

      const updatedOrder = await updateOrder(clClient, order.id, updateData)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.ADD_ORDER_BILLING_ADDRESS,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Update order billing address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the address
 * @param {AddressUpdate} address Address (billing) to update
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const updateBillingAddress = (clClient, order, address, callbacks) => {
  return async (dispatch) => {
    try {
      const updatedOrder = await updateAddress(clClient, order, address, 'billing')
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.UPDATE_ORDER_BILLING_ADDRESS,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Get order available shipping methods.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order to get shipping methods from
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const getAvailableShippingMethods = (clClient, order, callbacks) => {
  return async (dispatch) => {
    try {
      const retrievedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.GET_ORDER_SHIPPING_METHODS,
        retrievedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Set (select) order shipping method to use.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the shipping method
 * @param {String} shippingMethodId ID of the shipping method to associate
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const setOrderShippingMethod = (clClient, order, shippingMethodId, callbacks) => {
  return async (dispatch) => {
    try {
      const firstShipment = first(order.shipments)

      /**
       * @type {ShipmentUpdate}
       */
      const updateData = {
        id: firstShipment.id,
        shipping_method: clClient.shipping_methods.relationship(shippingMethodId),
      }

      await clClient.shipments.update(updateData)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.SET_ORDER_SHIPPING_METHOD,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Reset selected shipping method.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order where to clear the shipping method
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const resetOrderShippingMethod = (clClient, order, callbacks) => {
  return async (dispatch) => {
    try {
      const updatesPromises = order.shipments.map(async (shipment) => {
        await clClient.shipments.update({
          id: shipment.id,
          shipping_method: clClient.shipping_methods.relationship(null),
        })
      })

      await Promise.all(updatesPromises)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.RESET_ORDER_SHIPPING_METHOD,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Get order available payment methods.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order to get payment methods from
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const getAvailablePaymentMethods = (clClient, order, callbacks) => {
  return async (dispatch) => {
    try {
      const retrievedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.GET_ORDER_PAYMENT_METHODS,
        retrievedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}
/**
 * @typedef {Object} setOrderPaymentMethodParams
 * @property {CommerceLayerClient} clClient CL API client
 * @property {Order} order CL order associated to the payment method
 * @property {String} paymentMethodId ID of the payment method to associate
 * @property {Callbacks=} callbacks
 */
/**
 * Set (select) order payment method to use.
 * @param {setOrderPaymentMethodParams} params
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const setOrderPaymentMethod = ({
  clClient,
  order,
  paymentMethodId,
  callbacks
}) => {
  // console.log({
  //   clClient,
  //   order,
  //   paymentMethodId,
  // })

  return async (dispatch) => {
    try {
      // if these dont exist we will fail, since this is payment prob want to
      // kick em out of payment step.
      if (!(order?.id || paymentMethodId)) {
        throw Error(
          JSON.stringify({
            orderId: order?.id,
            paymentMethod: clClient?.payment_methods,
            paymentMethodId: paymentMethodId,
          })
        )
      }
      /**
       * @type {OrderUpdate}
       */
      const updateData = {
        id: order.id,
        payment_method: clClient.payment_methods.relationship(paymentMethodId),
      }

      await clClient.orders.update(updateData)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.SET_ORDER_PAYMENT_METHOD,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}
/**
 * @typedef {Object} resetOrderPaymentMethodProps
 * @property {CommerceLayerClient} clClient CL API client
 * @property {Order} order CL order associated to the payment method
 * @property {Callbacks=} callbacks
 */
/**
 * Reset order payment method.
 * @param {resetOrderPaymentMethodProps} props
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const resetOrderPaymentMethod = ({ clClient, order, callbacks }) => {
  return async (dispatch) => {
    try {
      /**
       * this unsets the payment_method
       * @type {OrderUpdate}
       */
      const updateData = {
        id: order.id,
        payment_method: clClient.payment_methods.relationship(null),
      }

      await clClient.orders.update(updateData)
      // console.log('updateOrder', updateOrder)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      // console.log('updatedOrder', updatedOrder)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.RESET_ORDER_PAYMENT_METHOD,
        updatedOrder,
        {
          onSuccess: (e) => {
            // unassociate the payment method with the order as well.
            callbacks?.onSuccess(e)
          },
          onError: callbacks?.onError
        }
      )
    } catch (error) {
      // unassociate the payment method with the order as well.
      // clClient.payment_methods.relationship(null)
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}
/**
 * @typedef {Object} addPaymentSourceStripeProps
 * @property {CommerceLayerClient} clClient CL API client
 * @property {Order} order CL order associated to the payment method
 * @property {StripePaymentCreate} paymentSource The Stripe payment source
 *   details
 * @property {Callbacks=} callbacks
 */
/**
 * Add order Stripe payment source.
 * @param {addPaymentSourceStripeProps} props
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const addPaymentSourceStripe = ({
  clClient,
  order,
  paymentSource = null,
  callbacks
}) => {
  return async (dispatch) => {
    try {
      const paymentSourceData = isObject(paymentSource) ? paymentSource : null
      const finalPaymentSource = {
        ...defaults({}, paymentSourceData),
        order: clClient.orders.relationship(order.id),
      }

      await clClient.stripe_payments.create(finalPaymentSource)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.ADD_ORDER_PAYMENT_SOURCE_STRIPE,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Update Stripe payment source.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the payment method
 * @param {StripePaymentUpdate} paymentSource The Stripe payment source update
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const updatePaymentSourceStripe = (clClient, order, paymentSource, callbacks) => {
  return async (dispatch) => {
    try {
      const finalPaymentSource = {
        ...paymentSource,
        order: clClient.orders.relationship(order.id),
      }

      await clClient.stripe_payments.update(finalPaymentSource)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.UPDATE_ORDER_PAYMENT_SOURCE_STRIPE,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Delete Stripe payment source.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the payment method
 * @param {string} paymentSourceId The Stripe payment source ID
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const deletePaymentSourceStripe = (clClient, order, paymentSourceId, callbacks) => {
  return async (dispatch) => {
    try {
      await clClient.stripe_payments.delete(paymentSourceId)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.DELETE_ORDER_PAYMENT_SOURCE_STRIPE,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}
/**
 * @typedef {Object} addPaymentSourcePaypalProps
 * @property {CommerceLayerClient} clClient CL API client
 * @property {Order} order CL order associated to the payment method
 * @property {PaypalPaymentCreate} paymentSource The Paypal payment source
 *   details
 * @property {Callbacks=} callbacks
 */
/**
 * Add order PayPal payment source.
 * @param {addPaymentSourcePaypalProps} props
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const addPaymentSourcePaypal = ({
  clClient,
  order,
  paymentSource,
  callbacks
}) => {
  return async (dispatch) => {
    try {
      const finalPaymentSource = {
        ...paymentSource,
        order: clClient.orders.relationship(order.id),
      }

      const paypalSource = await clClient.paypal_payments.create(finalPaymentSource)
      console.log('paypalSource', paypalSource)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.ADD_ORDER_PAYMENT_SOURCE_PAYPAL,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Update Paypal payment source.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the payment method
 * @param {PaypalPaymentUpdate} paymentSource The Paypal payment source update
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const updatePaymentSourcePaypal = (clClient, order, paymentSource, callbacks) => {
  return async (dispatch) => {
    try {
      const finalPaymentSource = {
        ...paymentSource,
        order: clClient.orders.relationship(order.id),
      }

      await clClient.paypal_payments.update(finalPaymentSource)
      const updatedOrder = await retrieveOrder(clClient, order.id)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.ADD_ORDER_PAYMENT_SOURCE_PAYPAL,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Get current checkout draft order by ID and set on commerce.order
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} orderId CL order ID
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const getOrder = (clClient, orderId, callbacks) => {
  return async (dispatch) => {
    try {
      const order = await retrieveOrder(clClient, orderId)
      dispatchDataAction(clClient, dispatch, orderActionType.GET_ORDER, order, callbacks)

      // const alterOrder = extend(omit(order,
      // ['payment_source.payment_method']), { payment_source: { payment_method:
      // {} } }) dispatchDataAction(clClient, dispatch,
      // orderActionType.GET_ORDER, alterOrder, callbacks)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}


/**
 * Get order by ID, returns order. does NOT set commerce.order with result.
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} orderId CL order ID
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const getOrderById = (
  clClient,
  orderId,
  callbacks
) => {
  return async (dispatch) => {
    try {
      return await retrieveOrder(clClient, orderId)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}


/**
 * @typedef {Object} OrderStatusFlags
 * @property {Boolean=} _place
 * @property {Boolean=} _archive
 * @property {Boolean=} _unarchive
 * @property {Boolean=} _cancel
 * @property {Boolean=} _capture
 * @property {Boolean=} _authorize
 */

/**
 * Map status key to boolean flag.
 * @param {string} status
 * @return {OrderStatusFlags}
 */
const mapStatusToFlag = (status) => {
  return {
    [status]: true,
  }
}

const ORDER_STATUS_FLAG = {
  authorize: '_authorize',
  capture: '_capture',
  place: '_place',
  archive: '_archive',
  unarchive: '_unarchive',
  cancel: '_cancel'
}


/**
 * Set order status- used to try to transition order statuses: place, archive, capture(finalize), cancel, etc
 * Also used to update the order metadata
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order to update status for
 * @param {string} status Status to set
 * @param {object=} metadata Status to set
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const setOrderStatus = (clClient, order, status, metadata, callbacks) => {
  return async (dispatch) => {
    try {
      /**
       * @type {OrderUpdate}
       */
      const updateData = Object.assign(
        {
          id: order.id,
          metadata: {
            ...order.metadata,
            ...metadata,
          },
        },
        mapStatusToFlag(status)
      )

      const updatedOrder = await updateOrder(clClient, order.id, updateData)

      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.SET_ORDER_STATUS,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}


/**
 * Set order status.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order to update status for
 * @param {object=} metadata Status to set
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const placeOrder = (clClient, order, metadata, callbacks) => {
  return async (dispatch) => {
    try {
      /**
       * @type {OrderUpdate}
       */
      const updateData = Object.assign(
        {
          id: order.id,
          metadata: {
            ...order.metadata,
            ...metadata,
          },
        },
        mapStatusToFlag(ORDER_STATUS_FLAG.place)
      )

      const updatedOrder = await updateOrder(clClient, order.id, updateData)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.SET_ORDER_STATUS,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}


/**
 * Set order status.
 * @param {{order: Order, callbacks: Callbacks}} props
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const clearPlacedOrder = ({ order, callbacks }) => {
  return async (dispatch) => {
    const action = composeDataAction(orderActionType.CLEAR_PLACED_ORDER, order)
    dispatch(action)
    invokeSuccessCallback(callbacks, action)
  }
}


/**
 * Set order status.
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const clearActiveOrder = (callbacks) => {
  return async (dispatch) => {
    const action = composeDataAction(orderActionType.CLEAR_ACTIVE_ORDER, null)
    dispatch(action)
    invokeSuccessCallback(callbacks, action)
  }
}


/**
 * @typedef {Object} OrderAddressCloneFlags
 * @property {Boolean=} _billing_address_same_as_shipping
 * @property {Boolean=} _shipping_address_same_as_billing
 */

/**
 * Map address source to flag.
 * @param {String} source Address source
 * @return {OrderAddressCloneFlags}
 */
const mapAddressSourceToFlag = (source) => {
  switch (source) {
    case 'shipping':
      return {
        _billing_address_same_as_shipping: true,
      }

    case 'billing':
      return {
        _shipping_address_same_as_billing: true,
      }

    default:
      break
  }
}

/**
 * Map address source to action.
 * @param {String} source Address source
 * @return {orderActionType}
 */
const mapAddressSourceToActionType = (source) => {
  switch (source) {
    case 'shipping':
      return orderActionType.SET_ORDER_SHIPPING_AS_BILLING_ADDRESS

    case 'billing':
      return orderActionType.SET_ORDER_BILLING_AS_SHIPPING_ADDRESS

    default:
      break
  }
}

/**
 * Clone shipping or billing address.
 *
 * @param {ThunkDispatch.<any, null, OrderAction>} dispatch
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order to set address for
 * @param {('billing' | 'shipping')} source Address source
 * @param {Callbacks=} callbacks
 */
export const cloneAddress = async (dispatch, clClient, order, source, callbacks) => {
  try {
    /**
     * @type {OrderUpdate}
     */
    const updateData = {
      id: order.id,
      ...mapAddressSourceToFlag(source),
    }

    const updatedOrder = await updateOrder(clClient, order.id, updateData)
    dispatchDataAction(
      clClient,
      dispatch,
      mapAddressSourceToActionType(source),
      updatedOrder,
      callbacks
    )
  } catch (error) {
    handleApiError(dispatch, error)
    invokeErrorCallback(callbacks, error)
  }
}

/**
 * Set shipping as billing address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order to set address for
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const setShippingAsBillingAddress = (clClient, order, callbacks) => {
  return async (dispatch) => {
    await cloneAddress(dispatch, clClient, order, 'shipping', callbacks)
  }
}

/**
 * Set billing as shipping address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order to set address for
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const setBillingAsShippingAddress = (clClient, order, callbacks) => {
  return async (dispatch) => {
    await cloneAddress(dispatch, clClient, order, 'billing', callbacks)
  }
}

/**
 * Reference customer in guest order (transfer ownership).
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the coupon code
 * @param {Customer} customer CL order customer to reference (owner)
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const setOrderCustomer = (clClient, order, customer, callbacks) => {
  return async (dispatch) => {
    try {
      const updateData = {
        id: order.id,
        customer: clClient.customers.relationship(customer.id),
        guest: false,
      }

      const updatedOrder = await updateOrder(clClient, order.id, updateData)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.SET_ORDER_CUSTOMER,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Setup customer account through order signup shortcut.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order CL order associated to the coupon code
 * @param {CustomerSignup} signup Customer account signup details
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const setOrderCustomerSignup = (clClient, order, signup, callbacks) => {
  return async (dispatch) => {
    try {
      const updateData = {
        id: order.id,
        ...signup,
      }

      const updatedOrder = await updateOrder(clClient, order.id, updateData)
      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.SET_CUSTOMER_SIGNUP,
        updatedOrder,
        callbacks
      )
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Delete customer address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order Address customer owner
 * @param {string} addressId Customer address to delete
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const deleteOrderShippingAddress = (clClient, order, addressId, callbacks) => {
  return async (dispatch) => {
    try {
      const deletedAddress = await clClient.addresses.delete(addressId)


      // Delete address reference to customer
      // Delete the address resource
      // const updatedOrder = await
      // clClient.orders.relationships.shipping_address.delete(addressId)

      const updateData = {
        id: order.id,
        // @ts-ignore
        shipping_address: null,
        _update_taxes: true
      }
      const updatedOrder = await updateOrder(clClient, order?.id, updateData)

      // const updatedCustomer = await retrieveCustomer(clClient, customer.id)

      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.DELETE_ORDER_SHIPPING_ADDRESS,
        updatedOrder,
        callbacks
      )
      invokeSuccessCallback(callbacks, orderActionType.DELETE_ORDER_SHIPPING_ADDRESS)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}
/**
 * Delete customer address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Order} order Address customer owner
 * @param {string} addressId Customer address to delete
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, OrderAction|ApiErrorAction>} Thunk
 *   action or error action
 */
export const deleteOrderBillingAddress = (clClient, order, addressId, callbacks) => {
  return async (dispatch) => {
    try {
      const deletedAddress = await clClient.addresses.delete(addressId)


      // Delete address reference to customer
      // Delete the address resource
      // const updatedOrder = await
      // clClient.orders.relationships.shipping_address.delete(addressId)

      const updateData = {
        id: order.id,
        // @ts-ignore
        billing_address: null,
      }
      const updatedOrder = await updateOrder(clClient, order?.id, updateData)

      // const updatedCustomer = await retrieveCustomer(clClient, customer.id)

      dispatchDataAction(
        clClient,
        dispatch,
        orderActionType.DELETE_ORDER_BILLING_ADDRESS,
        updatedOrder,
        callbacks
      )
      invokeSuccessCallback(callbacks, orderActionType.DELETE_ORDER_BILLING_ADDRESS)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

// @ts-nocheck
/* eslint-disable no-use-before-define */
import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { defaultsDeep, isFunction, noop, size, uniqueId } from 'lodash'
// Data
import { useI18n } from '@ggs/gatsby/lib'
import { Button } from '@ggs/gatsby/components/nav'
import { findAncestor } from '@ggs/utils'
// Components
// import { Button } from '../../nav'

const propTypes = {
  data: PropTypes.shape({
    buttonTheme: PropTypes.string,
    buttonLabel: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        theme: PropTypes.string,
        type: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        displayed: PropTypes.bool,
        onClick: PropTypes.func,
        loading: PropTypes.bool,
      })
    ),
  }),
  formik: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    isValidating: PropTypes.bool,
    handleSubmit: PropTypes.func,
  }),
}

const defaultProps = {
  data: {
    buttonTheme: '',
    buttonLabel: '',
    actions: [],
  },
  formik: {
    isSubmitting: false,
    isValidating: false,
    handleSubmit: noop,
  },
}

/**
 *
 * @param e
 * @param {boolean=} toggle
 */
const forceButtonLoading = (e, toggle = true) => {
  let target = e.target
  if (target.nodeName !== 'BUTTON') {
    target = findAncestor(target, 'button')
  }
  if (toggle) {
    target.setAttribute('disabled', 'disabled')
    target.setAttribute('data-loading', 'true')
  } else {
    target.removeAttribute('disabled')
    target.removeAttribute('data-loading')
  }
  // console.log('forceButtonLoading', target)
  return target
}

const FormActions = ({ actions, formik: { isSubmitting, handleSubmit, isValidating } }) => {
  const { t } = useI18n()
  const defaultActionProps = {
    label: t('Submit'),
    // variant: 'contained',
    disabled: false,
    displayed: true,
  }
  const defaultActions = [
    {
      className: '',
      variant: defaultActionProps.theme,
      label: defaultActionProps.label,
      type: 'submit',
      disabled: false,
      displayed: true,
      onClick: noop,
      loading: false,
      endIcon: null,
    },
  ]
  const actionList = useMemo(() => (size(actions) ? actions : defaultActions), [actions])
  // console.log('FormActions render', { isSubmitting, isValidating }, actionList)
  return (
    <div className="form__actions">
      {actionList.map((item, index) => {
        defaultsDeep(item, defaultActionProps)
        // console.log('actionItem', item, defaultActionProps)
        switch (item.type) {
          case 'button':
            return (
              <Button
                key={uniqueId(`action-item__${item.label}`)}
                className={`form__action-button ${item.className}`}
                variant={item.variant}
                data-displayed={item.displayed}
                type="button"
                onClick={!item.loading && isFunction(item.onClick) ? item.onClick : null}
                href={item.href}
                data-loading={item.loading}
                disabled={item.disabled || isSubmitting || isValidating}
                endIcon={item.endIcon}
              >
                <span className="form__action-loader">
                  <CircularProgress size={30} />
                </span>
                <span
                  className="form__action-label"
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
              </Button>
            )
          default:
            return (
              <Button
                key={uniqueId(`action-item-${index}_`)}
                className={`form__action-submit form__action-button ${item.className}`}
                variant={item.variant}
                data-displayed={item.displayed}
                type="submit"
                onClick={isValidating ? noop : handleSubmit}
                disabled={item.disabled || isSubmitting}
                data-loading={isSubmitting || item.loading || isValidating}
                endIcon={item.endIcon}
              >
                <span className="form__action-loader">
                  <CircularProgress size={30} />
                </span>
                <span
                  className="form__action-label"
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
              </Button>
            )
        }
      })}
    </div>
  )
}

FormActions.defaultProps = defaultProps
FormActions.propTypes = propTypes
FormActions.forceButtonLoading = forceButtonLoading
export default FormActions

import React, { useEffect, useState } from 'react'
import { FaqCategory, Text } from '@ggs/components/paragraphs'
import { cloneDeep } from '@apollo/client/utilities'
import { GridContainer, PageHeader, SearchInput } from '@ggs/components/layout'
import Grid from '@mui/material/Grid'
import { useI18n } from '@ggs/gatsby/lib'
// Assets.
import './FaqCategories.scss'
import useSearchFilters from '@ggs/components/layout/Search/useSearchFilters'

/**
 * @typedef {import('@ggs/types').FaqCategory} FaqCategory
 * @typedef {import('@ggs/types').Faq} Faq
 * @typedef {import('@ggs/types').SharedContent} SharedContent
 */

/**
 * @typedef {Object} FaqCategoriesProps
 * @property {Array<FaqCategory>=} faqCategories
 * @property {string} title
 * @property {string} subtitle
 * @property {string} getSearchQuery
 */

/**
 * FaqCategories component.
 * @param {FaqCategoriesProps} props
 * @return {JSX.Element}
 */
export default function FaqCategories({ title, subtitle, faqCategories, getSearchQuery }) {
  const { t } = useI18n()
  const [searchQuery, setSearchQuery] = useState(getSearchQuery || '')
  const { renderedFilters, activeFilters } = useSearchFilters({ categories: faqCategories })

  const [filteredFaqCategories, setFilteredFaqCategories] = useState(() =>
    cloneDeep([...faqCategories])
  )
  const [filteredAndSearchedFaqCategories, setFilteredAndSearchedFaqCategories] = useState(
    () => filteredFaqCategories
  )

  useEffect(() => {
    setFilteredFaqCategories((value) =>
      activeFilters.length
        ? [...faqCategories].filter(
          (filterCategory) => filterCategory.id && activeFilters.includes(filterCategory.id)
        )
        : faqCategories
    )
  }, [activeFilters, faqCategories])

  useEffect(() => {
    setFilteredAndSearchedFaqCategories(() =>
      searchQuery
        ? [...filteredFaqCategories]
        // .map((/** @type {{ faqs: Array<Faq>; }} */ item) => ({
        // ...item,
        // }))
        : filteredFaqCategories
    )
  }, [activeFilters, searchQuery, filteredFaqCategories])

  const labels = {
    placeholder: t('global:placeholder.searchFaqs'),
    ariaLabel: t('global:label.searchFaqs'),
  }

  return (
    <>
      <PageHeader title={title} subtitle={subtitle}>
        <SearchInput onSubmit={setSearchQuery} labels={labels} searchQuery={searchQuery}/>
      </PageHeader>
      <GridContainer sx={{ pt: { xs: 2, md: 8 }, pb: { xs: 7, md: 10 } }}>
        <Grid className="faq__content-section" container spacing={{ xs: 3, md: 5 }}>
          <Grid item className="faq__filters" xs={12} md={3}>
            {renderedFilters}
          </Grid>
          <Grid className="faq__listing" item xs={12} md={9}>
            <div className="faq-categories">
              {Array.isArray(filteredAndSearchedFaqCategories)
              && filteredAndSearchedFaqCategories.length
                ? filteredAndSearchedFaqCategories.map((faqCategory, index) =>
                  faqCategory?.id ? (
                    <FaqCategory key={faqCategory.id} {...faqCategory} filterBy={searchQuery}/>
                  ) : (
                    <Text textContent={t('global:label.noFaqsFound')} key={faqCategory.title + '---' + index}/>
                  )
                )
                : null}
            </div>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

import {
  Complete,
  OrderInformation,
  PaymentInformation,
  Review
} from '@ggs/components/ecomm/CheckoutSteps'
import CHECKOUT_STEP_ENUMS from './CHECKOUT_STEP_ENUMS'

const { ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS

// export const GUEST_CHECKOUT_STEPS_COMP = {
//   [AUTH]: CheckoutAs,
//   [ORDER]: OrderInformation,
//   [PAYMENT]: PaymentInformation,
//   [REVIEW]: Review,
//   [COMPLETE]: Complete,
// }

// export const AUTH_CHECKOUT_STEPS_COMP = {
//   [ORDER]: OrderInformation,
//   [PAYMENT]: PaymentInformation,
//   [REVIEW]: Review,
//   [COMPLETE]: Complete,
// }
//
//
// const {
//   [AUTH]: AUTH_STEP,
//   [ORDER]: ORDER_STEP,
//   [PAYMENT]: PAYMENT_STEP,
//   [REVIEW]: REVIEW_STEP,
//   [COMPLETE]: COMPLETE_STEP
// } = GUEST_CHECKOUT_STEPS_COMP

// TODO: guest / auth checkout steps
// const CHECKOUT_STEPS = {
//   AUTH_STEP,
//   ORDER_STEP,
//   PAYMENT_STEP,
//   REVIEW_STEP,
//   COMPLETE_STEP
// }

// TODO: split into guest / auth checkout steps
export const CHECKOUT_STEPS_COMP = {
  [ORDER]: OrderInformation,
  [PAYMENT]: PaymentInformation,
  [REVIEW]: Review,
  [COMPLETE]: Complete,
}

const {
  [ORDER]: ORDER_STEP,
  [PAYMENT]: PAYMENT_STEP,
  [REVIEW]: REVIEW_STEP,
  [COMPLETE]: COMPLETE_STEP
} = CHECKOUT_STEPS_COMP

const CHECKOUT_STEPS = {
  ORDER_STEP,
  PAYMENT_STEP,
  REVIEW_STEP,
  COMPLETE_STEP
}

export default CHECKOUT_STEPS
import * as React from 'react'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { getWindow } from '@ggs/utils'
import { trimEnd } from 'lodash'

/**
 * @typedef {object} SidebarMenuProps
 * @property {Array<Object>} menuItems
 * @property {function} onClick
 */

/**
 *
 * @param {SidebarMenuProps} props
 * @return {JSX.Element}
 */
export default function SidebarMenuDesktop({ menuItems, onClick }) {
  // @ts-ignore
  const pathname = trimEnd(getWindow('location.pathname') || '', '/')
  // console.log('SidebarMenuDesktop', {
  //   pathname,
  //   @ts-ignore
  // menuItems: menuItems.map(({ uri }) => ({ uri })),
  // })

  return (
    <Paper className={'sidebar-menu__desktop'}>
      <MenuList>
        {menuItems.map((/** @type * */ item) => (
          <MenuItem
            key={item.id}
            onClick={(e) => onClick(item, e)}
            selected={pathname === item.uri}
          >
            <span dangerouslySetInnerHTML={{ __html: item.title }} />
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  )
}

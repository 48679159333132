// @ts-nocheck
import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import * as PropTypes from 'prop-types'
import { noop, defer } from 'lodash'
import dayjs from 'dayjs'
import  DatePicker from '@mui/lab/DatePicker'
import MaskedInput from 'react-text-mask'
import TextField from './TextField'
import { events } from '@ggs/utils'
// import './_DatePicker.scss'

const propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.string,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
  }),
}

const defaultProps = {
  onChange: noop,
  name: '',
  value: '',
  label: '',
  max: '',
  formik: {
    values: {},
    setValues: noop,
  },
}

function DateMask(props) {
  // eslint-disable-next-line react/prop-types
  const { ref: inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef && inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={false}
    />
  )
}

// eslint-disable-next-line react/display-name
const CustomDatePicker = forwardRef(
  (
    {
      id,
      onChange,
      name,
      value: formikValue,
      defaultValue,
      label,
      max,
      inputProps = {},
      formik = {},
    },
    ref
  ) => {
    // Use local state for the MuiPicker needs
    const [value, setValue] = useState(formikValue || defaultValue)
    const [date, setDate] = useState(value ? dayjs(value, 'YYYY-MM-DD').toDate() : undefined)
    const [maxDate] = useState(max ? new Date(max) : new Date())

    // As the MuiPicker returns dates without events, and as a JS date, let's obtain the original to
    // update Formik.
    const onChangePicker = (newDate) => {
      // Update the MuiPicker
      setDate(newDate)
      // Update formik
      const newDateObj = dayjs(newDate)
      const e = events.syntheticInputEvent(name, {
        value: newDateObj.isValid() ? newDateObj.format('YYYY-MM-DD') : undefined,
      })
      onChange(e)
      // defer(() => formikOnChange(e))
      console.log('newDate', newDate, newDateObj)
    }

    useLayoutEffect(() => {
      const { /** @type Element */ current = window?.document } = ref
      if (current) {
        current
          .closest('.form-field__date-picker')
          .querySelector('.MuiInputAdornment-root button').attributes['aria-hidden'] = 'true'
      }
    }, [ref])

    const reset = () => {
      onChangePicker('')
    }

    useEffect(() => {
      if (value !== formikValue) {
        setValue(formikValue)
      }
    }, [formikValue])

    // console.log('render', { name, value, date, label, max })

    return (
      <div className="form-field__date-picker">
        <TextField
          ref={ref}
          name={name}
          label={label}
          onChange={onChange}
          value={value}
          placeholder="YYYY-MM-DD"
          InputProps={{
            inputComponent: DateMask,
          }}
        />
        <DatePicker
          id={id}
          autoOk
          onChange={onChangePicker}
          value={date}
          variant="filled"
          // InputAdornmentProps={{ position: 'start' }}
          format="YYYY-MM-DD"
          maxDate={maxDate}
          // disableFuture
          inputProps={inputProps}
          TextFieldComponent={TextField}
          invalidDateMessage=""
        />
        <button
          className="form-field__reset"
          data-name={name}
          type="button"
          aria-hidden
          onClick={reset}
        />
      </div>
    )
  }
)

// Export Props
CustomDatePicker.defaultProps = defaultProps
CustomDatePicker.propTypes = propTypes
// Export Component
export default CustomDatePicker

// @ts-nocheck
import { useMemo } from 'react'
import { addNotification, useDispatch } from '@ggs/store'
import { useI18n } from '@ggs/gatsby/lib'
import { extractAvailableShippingMethods, setOrderShippingMethod } from '@ggs/commercelayer'
import { delay, first } from 'lodash'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 * @typedef {import('@commercelayer/sdk').ShippingMethod} ShippingMethod
 */

/**
 * Get the selected shipping method.
 * @param {Order} order
 * @return {ShippingMethod|null}
 */
const getSelectedShippingMethod = (order) => {
  const firstShipment = first(order.shipments)
  return firstShipment?.shipping_method || null
}

export const shippingMethodRequiredKey = 'shipping_method_required'
export const useAvailableShippingMethods = ({ order, onSuccess, onFailure, clClient }) => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  // const { token } = commerce
  const { shippingAddress } = order

  const orderShipments = order?.shipments || null
  const shippingData = useMemo(() => {
    const shippingMethods = extractAvailableShippingMethods(order)
    const selectedMethod = getSelectedShippingMethod(order)
    return {
      shippingMethods,
      selectedMethod,
    }
  }, [order, order?.shipments, orderShipments])

  const shippingMethodCall = ({ shippingMethod, continueOnSuccess, continueOnFailure }) => {
    dispatch(
      setOrderShippingMethod(clClient, order, shippingMethod, {
        onSuccess: (action) => {
          delay(continueOnSuccess(action), 1000)
        },
        onError: (action) => {
          // console.log('ShippingMethodSetError:', action)
          continueOnFailure(action)
        },
      })
    )
  }

  const shippingMethodSubmit = (values, { setSubmitting }, formError) => {
    const { shippingMethod } = values
    // console.log('SelectedShippingMethod:', shippingMethod)

    if (!shippingMethod) {
      dispatch(
        addNotification(
          shippingMethodRequiredKey,
          'error',
          t('ecomm:form.error.selectAShippingMethod')
        )
      )
      setSubmitting(false)
      return
    }

    const continueOnSuccess = () => {
      onSuccess()
      setSubmitting(false)
    }

    const continueOnFailure = (action) => {
      onFailure(action)
      setSubmitting(false)
    }

    if (shippingData?.selectedMethod?.id !== shippingMethod) {
      shippingMethodCall({
        shippingMethod,
        continueOnSuccess,
        continueOnFailure,
      })
    } else {
      continueOnSuccess()
    }
  }

  // console.log('ShippingData:', shippingData)
  return {
    shippingAddress,
    getSelectedShippingMethod,
    shippingMethodSubmit,
    ...shippingData,
  }
}

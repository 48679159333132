import React from 'react'
import Box from '@mui/material/Box'
import { rem, colors } from '@ggs/styles'

/**
 * @enum {string} PageHeader Accent and Page backgroundColor variants.
 * @type {{pink: string, blue: string, white: string}}
 */
export const PageBackgroundVariant = {
  blue: 'blue',
  pink: 'pink',
  white: 'white',
}

/**
 * @enum {string} Accent Color for Background variants.
 */
const accentColorMap = {
  [PageBackgroundVariant.pink]: colors.lightRed,
  [PageBackgroundVariant.blue]: colors.blue,
  [PageBackgroundVariant.white]: colors.white,
}

/**
 * @typedef {Object} PageBackgroundAccentProps
 * @property {PageBackgroundVariant|null=} variant
 */
/**
 * @param {PageBackgroundAccentProps} props
 * @return {JSX.Element}
 */
export default function PageBackgroundAccent({ variant = null }) {
  const fill = variant && variant in accentColorMap ? accentColorMap[variant] : null
  // If we get a variant that doesn't match, don't render
  return fill ? (
    <Box
      className="page-background-accent"
      sx={{
        ml: rem(-335),
        pointerEvents: 'none',
        position: 'absolute',
        top: rem(-325),
        'svg, svg > *': {
          maxWidth: '100%;',
          maxHeight: '100%;',
          width: '100%;',
          height: '100%;',
        },
      }}
    >
      <svg width="968" height="968" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity=".05" cx="484" cy="484" r="484" fill={fill}/>
        <circle opacity=".05" cx="484" cy="484" r="341.347" fill={fill}/>
        <circle opacity=".05" cx="484" cy="484" fill={fill} r="188.505"/>
      </svg>
    </Box>
  ) : null
}

PageBackgroundAccent.variant = PageBackgroundVariant

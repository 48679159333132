// @ts-nocheck
import { useAddressController } from '@ggs/components/ecomm/Customer/useAddressController'
import { useState } from 'react'

export const useBillingShippingAddressController = () => {
  const billingAddressController = useAddressController({ orderPropertyKey: 'billing_address' })
  const shippingAddressController = useAddressController({ orderPropertyKey: 'shipping_address' })
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true)

  // derived state
  const isBillingAddress = (address) => address.orderPropertyKey === 'billing_address'
  const shouldHideBillingFields = isBillingAddress && billingSameAsShipping

  const {
    billing_address: {
      set: setBillingAddress,
      draftAddress: billingAddress,
      geoCodes: billingGeoCodes,
    },
  } = billingAddressController
  const {
    shipping_address: {
      set: setShippingAddress,
      draftAddress: shippingAddress,
      geoCodes: addressGeoCodes,
    },
  } = shippingAddressController

  const onShippingAddressDispatch = (values) => {
    const parsedOrder = values
    setShippingAddress((currentValue) =>
      parsedOrder?.shippingAddressId
        ? { addressId: parsedOrder.shippingAddressId, ...values.shipping_address }
        : currentValue
    )
  }

  const onBillingAddressDispatch = (values) => {
    const parsedOrder = values
    setBillingAddress((currentValue) =>
      parsedOrder?.billingAddressId
        ? { addressId: parsedOrder.billingAddressId, ...values.billing_address }
        : currentValue
    )
  }

  const onBillingSameAsShipping = (values) => {
    if (values === true) {
      setBillingSameAsShipping(true)
      setBillingAddress({
        addressId: shippingAddress.addressId,
        ...shippingAddress,
        orderPropertyKey: 'billing_address',
      })
    }
  }

  const addressStepsComplete = () => {
    return {
      billingSameAsShipping: billingSameAsShipping,
      ...addressGeoCodesValues(addressGeoCodes),
      ...billingGeoCodesValues(billingGeoCodes),
      // billingCountryCode: null,
      // billingCountryLabel: null,
      // billingStateCode: null,
      // billingStateLabel: null,
    }
  }

  const addressGeoCodesValues = ({ countryCode, stateCode }) => ({
    shippingCountryCode: countryCode?.value,
    shippingCountryLabel: countryCode?.label,
    shippingStateCode: stateCode?.value,
    shippingStateLabel: stateCode?.label,
  })

  const billingGeoCodesValues = ({ countryCode, stateCode }) => ({
    billingCountryCode: countryCode?.value,
    billingCountryLabel: countryCode?.label,
    billingStateCode: stateCode?.value,
    billingStateLabel: stateCode?.label,
  })

  return {
    billingAddress,
    shippingAddress,
    billingSameAsShipping,
    setBillingSameAsShipping,
    setShippingAddress,
    setBillingAddress,
    shouldHideBillingFields,
    billingGeoCodes,
    addressGeoCodes,
    billingAddressController,
    shippingAddressController,
    controllerByKey: (orderPropertyKey) =>
      orderPropertyKey === 'billing_address' ? shippingAddressController : billingAddressController,
    onShippingAddressDispatch,
    onBillingAddressDispatch,
    onBillingSameAsShipping,
    addressStepsComplete,
  }
}

import { Auth } from '@atoms-studio/commercelayer-sdk'

import { env } from './env.js'
//
// const {
//   setMarket,
//   getMarket,
//   getScope,
//   loginAsGuest,
//   loginAsCustomer,
//   isCustomerLoggedIn,
//   logoutCustomer,
//   loginAsIntegration,
//   getToken,
//   getCustomerToken,
//   useCustomerSession,
//   loadProfile,
//   getProfile,
//   __setCurrentToken: cacheToken
// } = Auth

const SessionData = {
  id: '', // string
  customer: null,// CustomerInstance | null
  token: '',// string
  refreshToken: '',//: string
  expires: '',//: number
}

const { globalDefaultScope } = env

const parseMarketIdFromScope = (scope) => scope.includes('market:') ? scope.split('market:')[1] : scope

/**
 * Guest
 * To authenticate as a guest, simply set the market number using Auth.setMarket:
 * Setting the market always fetches a guest access token and caches it until it expires.
 * Fetching resources will always use the cached token.
 * @param {string=} scope Market access scope, e.x. 'market:5812'.
 */
export const authMethod = ({ scope: defaultScope = globalDefaultScope, sessionData, ...options }) => {
  const defaultMarketId = parseMarketIdFromScope(defaultScope)
  // sets current market
  // resets custom session
  // invalidate current token
  // gets new one or used the cached version
  // logs in as guest
  const setMarket = async (marketId = defaultMarketId) => {
    if (!marketId || Number.isNaN(Number(marketId))) {
      throw new Error('Market ID scope is required to start guest token.')
    }

    return await Auth.setMarket(Number(marketId))
  }

  const startGuestSession = async ({ marketId = defaultMarketId, scope = defaultScope } = {}) => {
    // safety checks
    if ((marketId).includes('market:')) {
      throw new Error('remove market from scope, just pass market id.')
    }
    // set market
    const market = await setMarket(marketId ?? parseMarketIdFromScope(scope))
    // get token
    return Auth.getToken()
  }

  const getToken = () => {
    const { token, expires } = Auth.getToken()

    return {
      token, // ''
      expires // 0
    }
  }

  // returns number
  const getMarket = () => {
    return Auth.getMarket()
  }

  // returns string scope is `market:${market}`
  const getScope = () => {
    return Auth.getScope()
  }

  // // update to use customer when available
  // const setCookies = () => {
  //
  //   const scope = Auth.getScope()
  //   const { token, refreshToken } = getCurrentToken()
  //   // Save token, refresh token and scope to cookies
  //   cookies.set('cl_access_token', token)
  //   cookies.set('cl_refresh_token', refreshToken)
  //   cookies.set('cl_scope', scope)
  // }
  //
  // const restoreSessionFromCookie = async () => {
  //   // Obtain saved data
  //   const savedToken = cookies.get('cl_access_token')
  //   const savedRefreshToken = cookies.get('cl_refresh_token')
  //   const savedScope = cookies.get('cl_scope')
  //
  //   // Try to restore the session
  //   try {
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     await Auth.useCustomerSession(savedToken, savedRefreshToken, savedScope)
  //   } catch (error) {
  //     // Session cannot be restored
  //   }
  // }


  const initMarketTokenSession = () => {
    const authToken = getToken()
    const token = {
      token: authToken.token,
      tokenExpiresAt: authToken.expires,
      tokenRefresh: authToken?.refreshToken
    }

    if (token) {

      console.log(token)
    }
  }

  return {
    initMarketTokenSession,
    startGuestSession,
    getToken,
    setMarket,
    getScope,
    getMarket
  }
}

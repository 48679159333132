import Box from '@mui/material/Box'
import React from 'react'
import { Divider } from '@ggs/components/paragraphs'
import Grid from '@mui/material/Grid'
import { ProductOption } from '@ggs/components/ecomm'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 */
/**
 * @typedef {Object} ProductOptionList
 * @property {function} changeOption
 * @property {ProductOption} currentOption
 * @property {Array<ProductOption>=} productOptions
 * @property {String=} optionsLabel
 * @property {String=} optionsFooter
 */
/**
 Receives all product options, IF there are additional options available
 Receives handler for changing selected option
 Receives optionsLabel and optionsFooter text content to render around the additional options

 Must abstract out any that are flagged as included, to be a non-selectable list at the top of this component
 If there are more options which are not flagged as included,this must be displayed as a list which the user can
 select from, and uses the trigger passed down from the ProductHero
 * @param {ProductOptionList} props
 * @return {JSX.Element}
 */
export default function ProductOptionList({
  changeOption,
  currentOption,
  productOptions,
  optionsLabel,
  optionsFooter,
}) {
  // Don't display if no items are present.
  if (!productOptions || !productOptions.length) {
    return null
  }

  return (
    <Box
      className="product-options-list"
      sx={{
        '.product-options-list__label': {
          fontWeight: 'bold',
          mb: 2,
        },
        '.button.MuiButton-root.MuiButton-text:focus': {
          borderRadius: '2000px'
        },
        '.product-options-list__item .MuiButton-root': {
          width: {
            md: 'auto',
          },
        },
      }}
    >
      <div
        className="product-options-list__label"
        dangerouslySetInnerHTML={{ __html: optionsLabel }}
      />
      <Grid container spacing={2}>
        {productOptions.map((option) => (
          <Grid className="product-options-list__item" item key={option.id} xs={12} md={6}>
            <ProductOption
              option={option}
              variant="details"
              active={currentOption.id === option.id}
              selectOption={changeOption}
            />
          </Grid>
        ))}
      </Grid>
      <div
        className="product-options-list__footer"
        dangerouslySetInnerHTML={{ __html: optionsFooter }}
      />
      <Divider
        sx={{
          my: 4,
        }}
      />
    </Box>
  )
}

import { useDispatch } from 'react-redux'
import { clearOrderState, setCurrentMarket, useSelector } from '@ggs/store'
import { filter, isObject } from 'lodash'
import { resolveRegionMarket } from '@ggs/gatsby/lib/storeRegion'
import { useEffect } from 'react'

export const useStoreOrderMarket = () => {
  const dispatch = useDispatch()
  const layoutMeta = useSelector((state) => state.ui.layoutMeta)
  const settings = useSelector((state) => state.settings)
  const checkout = useSelector((state) => state.checkout)
  const commerce = useSelector((state) => state.commerce)

  const { order, customer } = commerce
  const { currentStore, stores } = layoutMeta
  /**
   * Lookup order market in current store supported markets.
   * @param {Order} order
   * @param {CurrentStore} currentStore
   * @return {OrderMarketRegion|null} Order market/region object or null when no
   *   match
   */
  const lookupOrderMarketInCurrentStore = (order, currentStore) => {
    /**
     * @type {OrderMarketRegion}
     */
    const defaultResolution = {
      storePath: order.metadata?.store_path || '/',
      region: null,
      market: null,
    }

    /**
     * @type {String}
     */
    const orderRegionId = order.metadata?.region_id

    /**
     * Combine default and other regions in single array for the order region
     * lookup.
     * @type {import('@ggs/types').RegionList}
     */
    const currentStoreAllRegions = filter([currentStore.defaultRegion].concat(currentStore.regions))
    const regionMatch = currentStoreAllRegions.find(
      (region) => String(region.entityId) === String(orderRegionId)
    )
    if (!regionMatch) {
      return defaultResolution
    }

    /**
     * @type {Number}
     */
    const orderMarketId = order.metadata?.market_number
    const marketMatch = regionMatch.markets.find(
      (market) => String(market.marketId) === String(orderMarketId)
    )
    if (!marketMatch) {
      /**
       * @type {OrderMarketRegion}
       */
      return defaultResolution
    }

    return {
      storePath: currentStore.url,
      region: regionMatch,
      market: marketMatch,
    }
  }

  /**
   * Process store transition confirmation handling.
   * @param {OrderMarketRegion} orderRegionMarket
   */
  const confirmStoreTransition = (orderRegionMarket) => {
    if (!orderRegionMarket.region) {
      dispatch(clearOrderState())
    } else {
      // Init region and market same as in the in progress order.
      if (isObject(orderRegionMarket.market)) {
        dispatch(setCurrentMarket(orderRegionMarket.market, orderRegionMarket.region))
      }
    }
  }

  /**
   * Init current region.
   * 1) When available, market should be initialized to
   *  order market to allow continue interacting with order.
   * TODO:
   * 2) When not show a confirm dialog: visiting different store that not support in
   *  progress draft order - User should confirm continue navigating
   *  current store and flush cart. - Reject flushing cart and get
   *  redirected to draft order store homepage. 3) When the market set is
   *  handled through store/region selector similar draft order flush
   *  confirmation needed.
   */
  const initCurrentMarket = () => {
    if (currentStore?.entityId) {
      if (order) {
        // alert('init current market order')
        // Lookup if current order market is available within current store regions markets:
        const orderRegionMarket = lookupOrderMarketInCurrentStore(order, currentStore)
        // Current store don't support order initiator market / region an order flush is needed.
        confirmStoreTransition(orderRegionMarket)
      } else {
        // alert('init current market else')
        const resolvedMarket = resolveRegionMarket(currentStore?.defaultRegion?.markets, customer)
        if (isObject(resolvedMarket)) {
          // alert('init current market resolved')
          dispatch(setCurrentMarket(resolvedMarket, currentStore?.defaultRegion))
        }
      }
    }
  }

  useEffect(() => {
    initCurrentMarket()
  }, [order, customer, currentStore])
}
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { useModal } from '@ggs/components/common'
import { useI18n } from '@ggs/gatsby/lib'

const ShowCookieSettingsLabel = () => {
  const { t } = useI18n()
  return t('global:onetrust.links.showSettings')
}
const ShowCookieListLabel = () => {
  const { t } = useI18n()
  return t('global:onetrust.links.cookieList')
}

const OneTrustApi = () => {
  const apiMethods = [
    'AllowAll',
    'BlockGoogleAnalytics',
    'Close',
    'getCSS',
    'GetDomainData',
    'getGeolocationData',
    'getHTML',
    'Init',
    'InitializeBanner',
    'initializeCookiePolicyHtml',
    'InsertHtml',
    'InsertScript',
    'IsAlertBoxClosed',
    'IsAlertBoxClosedAndValid',
    'LoadBanner',
    'OnConsentChanged',
    'ReconsentGroups',
    'RejectAll',
    'SetAlertBoxClosed',
    'setGeoLocation',
    'ToggleInfoDisplay',
    'TriggerGoogleAnalyticsEvent',
    'useGeoLocationService',
    'FetchAndDownloadPC',
    'changeLanguage',
    'testLog'
  ]

  return {
    Init: () => window?.OneTrust ? window.OneTrust.Init() : null,
    Close: () => window?.OneTrust ? window.OneTrust.Close() : null,
    ToggleInfoDisplay: () => window?.OneTrust ? window.OneTrust.ToggleInfoDisplay() : null,
    initializeCookiePolicyHtml: () => window?.OneTrust ? window.OneTrust.initializeCookiePolicyHtml() : null
  }
}

// timing is a bit weird on this, API inserts into a div and then react losing it after re-rendering.
// this captures / uses the API directly to implement capturing the injected data and api functionality
export const useOneTrustLinks = () => {
  const trustCookie = useRef()
  const privacySettings = useRef()
  const [modalContents, setModalContents] = useState(null)
  const api = OneTrustApi()

  const { modal, triggerModal } = useModal({
    sx: {
      // '.MuiDialog-container': {
      // },
      '.MuiPaper-root': {
        p: 0,
      },
      '.dialog__content': {
        textAlign: 'left',
      },
    },
    content: ({ onClose }) => <div dangerouslySetInnerHTML={{ __html: modalContents }}/>,
  })


  return {
    PrivacySettingsModalLink: () => (
      <a style={{ cursor: 'pointer' }} role='button' ref={privacySettings} className="optanon-show-settings"
        onClick={() => {
          try {
            api.ToggleInfoDisplay()
          } catch (e) {
            console.log('cookie api failure')
          }
        }}>
        <ShowCookieSettingsLabel/>
      </a>
    ),
    CookieListModalLink: () => {
      useEffect(() => {
        try{
        api.initializeCookiePolicyHtml()
      } catch (e) {
        console.log(e)
      }
      }, [])
      return (
        <>
          <a style={{ cursor: 'pointer' }} role='button' className="cookie-list-modal" onClick={() => {
            triggerModal({
              content: modalContents
                ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: modalContents
                    }}>
                  </div>
                )
                : <div dangerouslySetInnerHTML={{ __html: trustCookie?.current?.innerHTML }}/>
            })
            if (trustCookie?.current?.innerHTML !== '') {
              setModalContents((currentValue) => trustCookie?.current?.innerHTML)
            }
          }}>
            <ShowCookieListLabel/>
          </a>
          {/* 3rd party library injects cookie list content into here */}
          <div id="optanon-cookie-policy-parent" hidden>
            <div ref={trustCookie} id="optanon-cookie-policy"></div>
          </div>
        </>)
    },
    CookieListModal: modal,
  }
}

const sendPageView = function sendPageView() {
  const pagePath = location ? location.pathname + location.search + location.hash : undefined
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_path: pagePath,
    })
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32)
  }
}

const sendLoadingPageView = function sendPageView() {
  const pagePath = location ? location.pathname + location.search + location.hash : undefined
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_path: pagePath + '#loading',
    })
  } else {
    // simulate 2 rAF calls
    setTimeout(sendLoadingPageView, 32)
  }
}

const sendHistoryChange = () => {
  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(function () {
      requestAnimationFrame(sendPageView)
    })
  } else {
    sendLoadingPageView()
    setTimeout(sendHistoryChange, 32)
  }
}

// eslint-disable-next-line no-unused-vars
const onRouteUpdate = ({
  location,
  prevLocation,
  getResourceURLsForPathname,
  loadPage,
  loadPageSync,
  ...args
}) => {
  if (window.gtag) {
    sendHistoryChange()
  } else {
    setTimeout(sendHistoryChange, 32)
  }
}

module.exports = onRouteUpdate

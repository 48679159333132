// @ts-nocheck
import React from 'react'
import { Carousel } from '@ggs/components/banners'
import { Quote } from '@ggs/components/paragraphs/index'
import Box from '@mui/material/Box'
import { ImpressionClickObserver } from '@ggs/components/ecommerceAnalytics'

/**
 * @typedef {import("@ggs/components/banners/Carousel/Carousel").CarouselProps} CarouselProps
 */

const TESTIMONIAL_TYPES = {
  largeQuotes: 'largeQuotes',
  smallQuotes: 'smallQuotes',
}

const SmallTestimonial = ({ quotes, ...props }) => {
  /** @type {CarouselProps} */
  const testimonials = {
    ...props,
    variant: Carousel.variant.smallQuotes,
    carouselItems:
      quotes?.map((quote) => ({
        ...(quote || {}),
        variant: Quote.variant.small,
      })) ?? [],
  }

  // console.log('Testimonials render', testimonials)

  return (
    testimonials.carouselItems.length > 0 && (
      <Box
        sx={{
          mb: {
            xs: 2,
            md: 5,
          },
        }}
      >
        <Carousel
          {...testimonials}
          sx={
            props.sx ?? {
              backgroundColor: 'transparent',
              py: 10,
            }
          }
        />
      </Box>
    )
  )
}

const Testimonials = ({ testimonialType = null, title = '', quotes = [], ...props }) => {
  if (TESTIMONIAL_TYPES.largeQuotes === testimonialType) {
    return (
      <ImpressionClickObserver>
        <Carousel
          data-label={title}
          data-action-type={'view-testimonials-carousel'}
          title={title}
          carouselItems={quotes}
          variant={Carousel.variant.largeQuotes}
        />
      </ImpressionClickObserver>
    )
  } else {
    return (
      <ImpressionClickObserver>
        <SmallTestimonial
          data-label={title}
          data-action-type={'view-small-testimonial'}
          quotes={quotes}
          {...props}
        />
      </ImpressionClickObserver>
    )
  }
}

export default Testimonials

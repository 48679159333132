// @ts-nocheck
import { icons } from '@ggs/components'
import {
  useCheckoutContext
} from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { useWindowSize } from '@ggs/hooks'
import { colors, typography } from '@ggs/styles'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import CHECKOUT_STEP_ENUMS from '../enums/CHECKOUT_STEP_ENUMS'

// const { AUTH, ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS
const { ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS

// checkout steps exist as part of the STEP FLOW.  StepperControl is an Array,
// so checkout steps are in stepflow order. CHECKOUT_STEP: PAYMENT, stepFlow:
// [AUTH,ADDRESS,PAYMENT,REVIEW,COMPLETE], currentStepIndex = 2; stepFlow[2] =
// PAYMENT
const StepperControl = ({ stepFlow, currentStepIndex, ...props }) => {
  const windowSize = useWindowSize()
  const { t } = useTranslation()
  const {
    currentStepperIndex,
    isStepComplete,
    handlers: { goToStep },
  } = useCheckoutContext()

  // const { isAuth } = useIsAuth()
  const isStepIndexComplete = (stepIndex) => {
    return stepIndex in stepFlow
      ? isStepComplete[stepFlow[stepIndex]]() : false
  }

  const hasPreqs = (stepIndex) => stepIndex === 0
    // || !isAuth() && stepIndex === 1
    || isStepIndexComplete(stepIndex - 1)

  const stepperClassName = (stepIndex, currentStepIndex) =>
    ` stepper__item
    ${hasPreqs(stepIndex) && currentStepIndex <= stepIndex
    ? ' stepper__item--next-checkout-step'
    : ''}
    ${!hasPreqs(stepIndex)
    ? 'stepper__item--disabled-checkout-step'
    : ''}
    ${isStepIndexComplete(stepIndex) || currentStepIndex >= stepIndex
    ? ' stepper__item--completed'
    : null} 
    `

  const sx = {
    mb: {
      xs: 2,
      md: 6,
    },
    '> .MuiStepConnector-root': {
      height: 0,
      flex: 0,
      display: 'none',
    },
    '& .stepper__item': {
      '&:last-child': {
        '& .MuiStepLabel-label': {
          '> svg': {
            display: {
              lg: 'none',
            },
          },
        },
      },
    },
    '& .stepper__item--completed': {
      '& .stepper__item-button': {
        '& .MuiStepLabel-iconContainer': {
          '& svg': {
            opacity: 1,
            text: {
              typography: typography.pBold,
              fill: colors.red,
            },
          },
        },
        '& .MuiStepLabel-label': {
          typography: typography.pBold,
          color: colors.red,
          opacity: 1,
        },
      },
    },
    '& .stepper__item--next-checkout-step': {
      'button.MuiStepLabel': {
        'label': {
          opacity: '1 !important'
        }
      },
      '& .stepper__item-button': {
        '& .MuiStepLabel-iconContainer': {
          '& svg': {
            opacity: '1 !important',
            text: {
              typography: typography.pBold,
              fill: colors.red,
            },
          },
        },
        '& .MuiStepLabel-label': {
          color: colors.black,
          typography: typography.pBold,
          opacity: '1 !important',
        },
      },
    },
    '& .stepper__item-button': {
      '& .MuiStepLabel-vertical': {
        alignItems: 'stretch',
        '.stepper__item-label': {
          display: 'block',
        },
      },
      '& .MuiStepLabel-iconContainer': {
        pr: 0,
        display: {
          xs: 'block',
          md: 'none',
        },
        '& svg': {
          opacity: 0.5,
          '&.Mui-active': {
            borderBottom: `1px solid ${colors.red}`,
            opacity: 1,
            text: {
              typography: typography.pBold,
            },
          },
          circle: {
            display: 'none',
          },
          text: {
            typography: typography.menuItemRegular,
            fill: colors.black,
          },
        },
      },
      '& .MuiStepLabel-label': {
        '&.Mui-active': {
          color: colors.black,
          typography: typography.pBold,
          opacity: '1 !important',
          '.stepper__item-label': {
            opacity: '1 !important',
            borderBottom: `1px solid ${colors.red}`,
            pb: {
              xs: 0,
              md: 1,
            },
          },
        },
        '&.Mui-disabled': {
          opacity: .5
        },
        typography: typography.menuItemRegular,
        color: colors.black,
        opacity: 1,
        '> svg': {
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
          flex: '0 0 12px',
          height: '12px',
          width: '14px',
          ml: 2,
          transform: 'rotate(-90deg)',
        },
      },
    },
  }

  const labels = {
    // [AUTH]: t('ecomm:label.checkout'),
    [ORDER]: t('ecomm:label.shipping'),
    [PAYMENT]: t('ecomm:label.payment'),
    [REVIEW]: t('ecomm:label.review'),
    [COMPLETE]: t('ecomm:label.complete'),
  }

  return (
    <>
      <Stepper
        nonLinear
        activeStep={currentStepperIndex}
        className="stepper"
        sx={sx}
        {...props}
        orientation={windowSize.belowMd ? 'vertical' : 'horizontal'}
      >
        {stepFlow.map((step, index) => (
          <Step
            className={stepperClassName(index, currentStepperIndex)}
            key={step}
            completed={isStepIndexComplete(index)}
            disabled={!hasPreqs(index)}
          >
            <StepButton
              color="inherit"
              onClick={() => {
                // check if the step before is done.
                // eslint-disable-next-line no-unused-expressions
                index === 0 || hasPreqs(index)
                  ? goToStep(index)
                  : null
              }}
              className="stepper__item-button"
              sx={{
                cursor:
                  (index === 0) || hasPreqs(index)
                    ? 'pointer'
                    : 'initial'
              }}
            >
              <span
                className={`stepper__item-label ${!hasPreqs(index) ? 'disabled' : ''}`}>
                {labels[step]}
              </span>
              {icons.ChevronDown}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </>
  )
}
export default StepperControl

// @ts-nocheck
import React, { useRef, useState } from 'react'
import { isEmpty, noop } from 'lodash'
import { useI18n } from '@ggs/gatsby/lib'
import { colors, typography } from '@ggs/styles'
import { Divider, Title } from '@ggs/components/paragraphs'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useCheckoutAnalytics } from '../../ecommerceAnalytics/hooks/useCheckoutAnalytics'
import { useForm } from '@ggs/forms'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { useAvailableShippingMethods } from '@ggs/components/ecomm/Customer/useAvailableShippingMethods'
import { useShippingMethodLabelMaker } from '@ggs/components/ecomm/Customer/useShippingMethodLabelMaker'
import { FieldTypes } from '@ggs/forms/schema/Forms'
import FormLabel from '@mui/material/FormLabel'
import MuiRadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import { useValidators } from '@ggs/hooks'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 * @typedef {import('@commercelayer/sdk').ShippingMethod} ShippingMethod
 */

/**
 * @typedef {Object} ShippingMethodFormProps
 * @property {function=} onSuccess
 * @property {function=} onFailure
 * @property {boolean=} pendingAddress
 * @property {Order} order
 */

/**
 * Checkout shipping methods form (order information step).
 * @param {ShippingMethodFormProps} props
 * @return {JSX.Element}
 */
const ShippingMethodForm = ({ onSuccess, onFailure, pendingAddress }) => {
  const { t } = useI18n()
  const { clClient, order } = useCheckoutContext()
  const wrapperRef = useRef(null)
  // console.log(order)

  const {
    // shippingAddress,
    shippingMethods,
    selectedMethod,
    // getSelectedShippingMethod,
    shippingMethodSubmit,
  } = useAvailableShippingMethods({ order, onSuccess, onFailure, clClient })

  // console.log('ShippingMethodForm', {
  //   order,
  //   shipments,
  //   shippingAddress,
  //   shippingMethods,
  //   selectedMethod,
  // })
  //
  // currency_code,
  // delivery_lead_time_for_shipment,
  // isFreeShipping,
  // lang

  // console.log('ShippingMethodForm', {
  //   order,
  //   shipments,
  //   shippingAddress,
  //   shippingMethods,
  //   selectedMethod,
  // })
  //
  // console.log(shippingMethods)
  const shippingMethodLabelMaker = useShippingMethodLabelMaker(order)

  const shippingOptions = shippingMethods.map((shippingMethod) => {
    // console.log(shippingMethod, orderLabels)
    const shippingLabel = shippingMethodLabelMaker(shippingMethod)
    return {
      label: shippingLabel?.label,
      value: shippingMethod?.id,
    }
  })
  //

  if (isEmpty(shippingMethods)) {
    return (
      <Box>
        <Title title={t('ecomm:label.shippingMethod')} style="h3" sx={{ mb: 2 }} />
        <Typography variant={'body1'}>{t('ecomm:notice.enterShippingAddress')}</Typography>
      </Box>
    )
  }

  return (
    <Box ref={wrapperRef}>
      {isEmpty(shippingOptions) ? (
        <Typography variant={'body1'}>{t('ecomm:notice.noShippingMethodAvailable')}</Typography>
      ) : (
        <ShippingMethodSelect
          order={order}
          shippingOptions={shippingOptions}
          selectedMethodId={selectedMethod?.id ?? null}
          disabled={pendingAddress && !selectedMethod}
          shippingMethodSubmit={shippingMethodSubmit}
        />
      )}
    </Box>
  )
}

const ShippingMethodSelect = ({
  selectedMethodId,
  shippingOptions,
  shippingMethodSubmit,
  disabled,
  order,
  onSubmit = noop,
}) => {
  const { t } = useTranslation()
  const validators = useValidators()
  const { shippingMethodId } = useCheckoutContext()
  const [shippingMethodSelected, setShippingMethodSelected] = useState(shippingMethodId)
  const { fireSelectShippingMethod } = useCheckoutAnalytics()

  /**
   * Handle shipping method change.
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @return {void}
   */
  const handleShippingMethodChange = (event) => {
    const selectedMethodId = event.target.value
    fireSelectShippingMethod({ order, shippingMethodId: selectedMethodId })
    setShippingMethodSelected(selectedMethodId)
  }

  const sxProps = {
    '& .MuiFilledInput-root': {
      backgroundColor: colors.white,
    },
    '& .MuiButton-root': {
      mt: 2,
      mb: 5,
    },
    '.MuiRadio-root': {
      pt: 2,
      alignSelf: 'flex-start',
    },
    '.MuiFormControlLabel-label p': {
      mb: 0.5,
      '&:last-of-type': {
        mt: 0,
        mb: 0,
        typography: typography.pSmall,
      },
    },
    '.shipping-method-option__lead-time': {
      typography: typography.pSmall,
    },
  }

  const Form = useForm({
    className: 'shipping-method-id-form',
    name: 'shippingMethodIdForm',
    type: FieldTypes.hidden,
    fields: {
      shippingMethodId: {
        defaultValue: selectedMethodId,
        type: FieldTypes.hidden,
        disabled: true,
        validator: validators.yupString,
      },
    },
    actions: [
      {
        label: t('ecomm:button.continueToPaymentInformation'),
        disabled: disabled || undefined,
      },
    ],
    onSubmit: (values, formik, errors) => {
      console.log('ShippingMethodForm.onSubmit', values, formik, errors)
      shippingMethodSubmit({ shippingMethod: shippingMethodSelected }, formik, errors)
    },
    sx: sxProps,
  })

  return (
    <>
      <Title title={t('ecomm:label.shippingMethod')} style="h3" sx={{ mb: 3 }} />
      <Box
        className="form-field"
        // data-name={name}
        sx={sxProps}
      >
        <FormControl
          className="form-field__radiogroup shipping-method-form"
          component="fieldset"
          disabled={false}
          sx={sxProps}
        >
          <FormLabel component="legend">{t('ecomm:label.selectAShippingMethod')}</FormLabel>
          <MuiRadioGroup
            defaultValue={selectedMethodId || null}
            initialValue={selectedMethodId || null}
            value={shippingMethodSelected}
            // name={name}
          >
            {shippingOptions.map(({ label, value }) => (
              <FormControlLabel
                value={value}
                control={<Radio color="primary" />}
                onChange={handleShippingMethodChange}
                key={value}
                label={label}
              />
            ))}
          </MuiRadioGroup>
        </FormControl>
        {Form.FormComp}
        <Divider
          sx={{
            mt: 2,
            mb: 2,
          }}
        />
      </Box>
    </>
  )
}

export default ShippingMethodForm

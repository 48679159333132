
/**
 * Typography styles
 * @enum {string}
 */
const typography = {
  largeHeading: 'largeHeading',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h6: 'h6',
  superHeading: 'superHeading',
  paragraphLarge: 'paragraphLarge',
  menuItemSmall: 'menuItemSmall',
  menuItemRegular: 'menuItemRegular',
  pRegular: 'pRegular',
  pBold: 'pBold',
  pSmall: 'pSmall',
  caption: 'caption',
  captionBold: 'captionBold',
}


module.exports = typography

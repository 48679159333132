import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import colors from '@ggs/styles/theme/colors'

/**
 * @enum {string}
 * @type {{pink: string, blue: string, white: string}}
 */
export const HeaderBackgroundVariant = {
  blue: 'blue',
  pink: 'pink',
  white: 'white',
}
/**
 * @typedef {Object} HeaderBackgroundAccentProps
 * @property {HeaderBackgroundVariant=} variant
 */
/**
 * @param {HeaderBackgroundAccentProps} props
 * @return {JSX.Element}
 */
export default function HeaderBackgroundAccent({ variant = HeaderBackgroundVariant.blue }) {
  const fill = useMemo(
    () => {
      let color = ''
      switch (variant) {
        case HeaderBackgroundVariant.pink:
          color = colors.lightRed
          break
        case HeaderBackgroundVariant.blue:
          color = colors.blue
          break
        case HeaderBackgroundVariant.white:
          color = colors.white
          break
      }
      return color
    },
    [variant]
  )


  // If we get a variant that doesn't match, don't render
  return fill ? (
    <Box
      className="header-background-accent"
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        overflow: 'visible',
        top: '6.75%',
        left: '7.5%',
        md: {
          display: 'none',
        }
      }}
    >
      <svg width="224" height="224" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle fillOpacity="0.10" cx="112" cy="112" r="112" fill={fill}/>
        <circle fillOpacity="0.10" cx="112" cy="112" r="77" fill={fill}/>
        <circle fillOpacity="0.10" cx="112" cy="112" r="44" fill={fill}/>
      </svg>
    </Box>
  ) : null
}

HeaderBackgroundAccent.variant = HeaderBackgroundVariant

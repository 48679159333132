// @ts-nocheck
import { isFunction } from 'lodash'

/**
 * React to an action when the user presses enter.
 * @param {React.KeyboardEvent<HTMLAnchorElement>|KeyboardEvent<HTMLAnchorElement>} e
 * @param {function} eventHandler
 * @return {boolean}
 */
export const whenPressingEnter = (e, eventHandler) => {
  const key = e.key || e.which || e.charCode || e.code
  // Based on keycode tool https://keycode.info/ the possibles 'Enter' pressed
  // key values are check here.
  if ((key === 'Enter' || key === 13) && isFunction(eventHandler)) {
    eventHandler(e)
    e.preventDefault()
    return false
  }

  return true
}

export const whenPressingEscape = (e, eventHandler) => {
  const key = e.key || e.which || e.charCode || e.code
  // Based on keycode tool https://keycode.info/ the possibles 'Enter' pressed
  // key values are check here.
  if ((key === 'Escape' || key === 27) && isFunction(eventHandler)) {
    eventHandler(e)
    e.preventDefault()
    return false
  }

  return true
}

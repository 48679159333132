import React from 'react'
import dayjs from 'dayjs'
import Grid from '@mui/material/Grid'
import { Chip, SharePage } from '@ggs/components/common'
// Assets
import './ArticleMeta.scss'

/**
 * @typedef {import("@ggs/types").NewsAndEventCategory} NewsAndEventCategory
 * @typedef {import('dayjs').Dayjs} Dayjs
 */
/**
 * @typedef {Object} ArticleMetaProps
 * @property {String=} url
 * @property {String=} dateFormat
 * @property {String|Dayjs|Number=} publishDate
 * @property {Array<NewsAndEventCategory>=} articleCategories
 * @property {Boolean=} displaySharing
 */
/**
 *
 * @param {ArticleMetaProps} props
 * @return {JSX.Element}
 */
export default function ArticleMeta({
  url = '',
  publishDate = '',
  dateFormat = 'MMM D, YYYY',
  articleCategories = [],
  displaySharing = false,
}) {
  // eslint-disable-next-line prefer-rest-params
  // console.log('ArticleMeta render', arguments[0]) //, JSON.stringify(arguments[0]))
  return (
    <Grid className="article-meta" container spacing={0}>
      <Grid className="article-meta__tags" item xs={12} md={8}>
        <div className="article-meta__date">{dayjs(publishDate).format(dateFormat)}</div>{' '}
        <div className="article-meta__categories">
          {articleCategories.map(({ name }) => (
            <Chip key={name} label={name} />
          ))}
        </div>
      </Grid>
      {displaySharing && (
        <Grid className="article-meta__share" item xs={12} md={4}>
          <SharePage url={url} />
        </Grid>
      )}
    </Grid>
  )
}

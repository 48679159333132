// @ts-nocheck
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { CheckoutLayoutBuilder } from '@ggs/components/ecomm/Checkout/order/CheckoutBuilder'
import React from 'react'


/**
 * @typedef {Object} CheckoutInProgressLayoutProps
 * @property {String=} checkoutDisclaimer
 * @property {String=} checkoutTermsOfSale
 * @property {String=} acceptTCs
 */
/**
 * Checkout in progress steps layout.
 * @param {CheckoutInProgressLayoutProps} props
 * @return {JSX.Element}
 */
const InProgressCheckoutStepsLayout = (props) => {
  const { checkoutTermsOfSale, checkoutDisclaimer } = props
  const {
    order,
    lastOrder,
    currentStepName,
    handlers,
    currentStepperIndex,
    isStepComplete,
    isNextCheckoutStep,
    stepFlow,
    handlers: { goToStep },
    useRedirectFromCheckout
  } = useCheckoutContext()

  useRedirectFromCheckout()

  const stepperControlProps = {
    currentStepperIndex,
    isStepComplete,
    isNextCheckoutStep,
    stepFlow,
    currentStepName,
    handlers: { goToStep },
  }

  return (
    <CheckoutLayoutBuilder
      checkoutTermsOfSale={checkoutTermsOfSale}
      checkoutDisclaimer={checkoutDisclaimer}
      currentStepName={currentStepName}
      currentStepperIndex={currentStepperIndex}
      order={order}
      lastOrder={lastOrder}
      handlers={handlers}
      stepperControlProps={stepperControlProps}
      {...props}
    />
  )
}

export default InProgressCheckoutStepsLayout

// @ts-nocheck
import { CheckoutContextProvider } from '@ggs/components/ecomm'
import React, { useContext } from 'react'

export const CommerceSessionContext = React.createContext({
  accessToken: {
    token: null,
    tokenRefresh: null,
    tokenExpiresAt: null,
    // tokenType: null,
  },
  // customer: {
  //   customerId: null,
  //   customerEmail: null
  // },
  market: {
    marketId: null
  }
})
/**
 * @typedef {Object} CommerceSessionContextProvider
 * @property {JSX.Element} children
 */
/**
 * @param {CommerceSessionContextProvider} props
 * @return {JSX.Element}
 */

// THIS MUST ALWAYS FUNCTION.. USE GLOBAL DEFAULT MARKET TO GET FALLBACK IF
// TOKEN / MARKET INVALID.
export const CommerceSessionContextProvider = (
  {
    clClient,
    scope,
    marketId,
    initCommerceLayerToken,
    // upgradeToCustomerToken,
    accessToken,
    customer,
    checkout,
    currentMarket,
    currentStore,
    order,
    children,
  }) => {

  return (
    <CommerceSessionContext.Provider value={{
      clClient,
      scope,
      marketId,
      initCommerceLayerToken,
      // upgradeToCustomerToken,
      accessToken,
      customer,
      checkout,
      currentMarket,
      currentStore,
      order,
      children,
    }}>
      <CheckoutContextProvider>
        {children}
      </CheckoutContextProvider>
    </CommerceSessionContext.Provider>
  )
}

// hook for using the checkout context state; makes sure context: order, token,
// scope, etc. is valid, then passes checkout context values (using
// useactivecheckout functional hook) to checkout context consumers.
export const useCommerceSessionContext = () => {
  const commerceSessionContext = useContext(CommerceSessionContext)
  if (commerceSessionContext === undefined) {
    throw new Error(
      'commerceSessionContext is undefined- something wrong with token or scope or client... fallback default scope.'
    )
  }

  const {
    clClient,
    scope,
    marketId,
    initCommerceLayerToken,
    upgradeToCustomerToken,
    accessToken,
    customer,
    checkout,
    currentMarket,
    currentStore,
    order,
    children,
  } = commerceSessionContext

  // bootstrap API with token if set on local && valid..
  return {
    clClient,
    scope,
    marketId,
    initCommerceLayerToken,
    upgradeToCustomerToken,
    accessToken,
    customer,
    checkout,
    currentMarket,
    currentStore,
    order,
    children,
  }
}

export default CommerceSessionContextProvider

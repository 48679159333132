// @ts-nocheck
/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from 'react'
import { api } from '@ggs/commercelayer'
import { init } from '@atoms-studio/commercelayer-sdk'
import { CommerceApiContextProvider } from '@ggs/components/ecomm/CommmerceSession/CommerceApiContext'
import { getMarketIdFromScope } from '@ggs/components/ecomm/helpers/marketHelpers'
import { useStartCommerceSession } from '@ggs/components/ecomm/hooks'

const displayDebug = (parseInt(process.env.GATSBY_DEBUG_PARAGRAPH_SETTINGS) === 1)

const endpointUrl = `${process.env.GATSBY_COMMERCE_LAYER_API}`
const clientId = `${process.env.GATSBY_COMMERCE_LAYER_CLIENT_ID}`
const globalDefaultScope = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`

init({
  host: endpointUrl,
  clientId: clientId,
})

// App component that provides initial context values
export const CommerceApi = ({ children }) => {
  const initApi = api({ scope: globalDefaultScope })

  const {
    startGuestSession,
    getToken,
    setMarket,
    getScope,
    getMarket,
    getSkuData,
    getAllSkusData
  } = initApi

  const authToken = getToken()

  const [accessToken, setAccessToken] = useState(() => ({
    token: authToken.token,
    tokenExpiresAt: authToken.expires,
    tokenRefresh: authToken?.refreshToken
  }))
  const [scope, setScope] = useState(globalDefaultScope)


  useEffect(() => {
    setAccessToken(async (currentToken) => {
      if (!authToken.expires) {
        const guestSession = await setMarket(getMarketIdFromScope(scope))
      }
      const newToken = getToken()
      return {
        token: newToken.token,
        tokenExpiresAt: newToken.expires,
        tokenRefresh: newToken?.refreshToken
      }
    })
  }, [authToken.expires, scope])

  // useCommerceLayerToken({ scope })
  const { clClient } = useStartCommerceSession({ scope, accessToken })

  return (
    <CommerceApiContextProvider
      accessToken={accessToken}
      marketId={globalDefaultScope}
      clClient={clClient}
      scope={scope}
    >
      {children}
    </CommerceApiContextProvider>
  )
}

import { parseOrderFn } from '@ggs/components/ecomm/Checkout/commerce/app/helpers/OrderParse'

export const hasCheckoutChanged = ({ checkoutState, reportedCheckout }) => {
  // has the number of line items changed?
  // have the skus changed
  // have the quantities changed
  // has the order id changed
  const {
    orderId,
  } = checkoutState
  return orderId !== reportedCheckout?.orderId
}
export const hasShippingInfoChanged = ({ checkoutState, reportedShippingInfo }) =>
  !isShippingInfoSame({
    checkoutState,
    reportedShippingInfo
  })
const isShippingInfoSame = ({
  checkoutState: {
    orderId,
    shippingCountryCode,
    shippingStateCode,
    shippingAddressId,
    shippingMethodId
  }, reportedShippingInfo
}) => orderId === reportedShippingInfo.orderId
  && shippingCountryCode === reportedShippingInfo.shippingCountryCode
  && shippingStateCode === reportedShippingInfo.shippingStateCode
  && shippingAddressId === reportedShippingInfo.shippingAddressId
  && shippingMethodId === reportedShippingInfo.shippingMethodId
export const hasPaymentInfoChanged = ({ checkoutState, reportedPaymentInfo }) => !isPaymentSame({
  checkoutState,
  reportedPaymentInfo
})
const isPaymentSame = ({
  checkoutState: {
    billingCountryCode,
    billingStateCode,
    paymentMethodId,
    paymentSourceId,
  }, reportedPaymentInfo
}) => billingCountryCode === reportedPaymentInfo.billingCountryCode
  && billingStateCode === reportedPaymentInfo.billingStateCode
  && paymentMethodId === reportedPaymentInfo.paymentMethodId
  && paymentSourceId === reportedPaymentInfo.paymentSourceId
export const getCheckoutState = (o) => parseOrderFn(o)
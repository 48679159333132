import React from 'react'
import { ImageStyles, RawImage } from '@ggs/gatsby/components/media'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyle
 * @typedef {import('@ggs/types').GatsbyImageStyles} GatsbyImageStyles
 */

/**
 * @typedef {Object} DesignedBylineProps
 * @property {GatsbyImageStyles|null=} styles Collection of image styles from the data source
 * @property {Array<ImageStyle>=} selectedStyles When provided, will limit which image styles are rendered
 * @property {"eager"|"lazy"|undefined=} loading
 */

/**
 * @param {{productDndLogo: DesignedBylineProps}} props
 * @return {JSX.Element}
 */
export default function DesignedByline({ productDndLogo }) {
  // console.log(productDndLogo)
  // const { styles } = productDndLogo
  return (
    <span className='designed-byline'>
      <RawImage imageData={productDndLogo} />
    </span>
  )
}

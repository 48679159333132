const getGridItemSize = (numCards) => {
  switch (numCards) {
    case 1:
      return 12
    case 2:
      return 6
    case 3:
      return 4
    default:
      return 3
  }
}

const getSelectedStyles = (orientation) => {
  switch (orientation) {
    case 'portrait':
      return 'resourceCardThumbnailPortrait'
    case 'landscape':
      return 'resourceCardThumbnailLandscape'
    case 'square':
      return 'resourceCardThumbnailSquare'
    default:
      return ''
  }
}

export { getGridItemSize, getSelectedStyles }
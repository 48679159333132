/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */
/**
 * @typedef {Object<string, any>} sampleGatsbyImage
 * @property {String} style
 * @property {Number} width
 * @property {Number} height
 */
/** @typedef {Object} generateGatsbyImage
 * @property {Array<sampleGatsbyImage>} styles
 * @property {String=} img The Image url
 * @property {String=} alt The Image alt text
 */
/**
 *
 * @param {generateGatsbyImage} props
 * @return {ImageStyles}
 */
export default function generateGatsbyImage({ styles, img = '', alt }) {
  /** @type ImageStyles */
  const imageStyles = {
    styles: {
      alt,
    },
  }

  // Find the largest image size, and use this for all images, else they end up product unique images which makes no
  // sense for testing.
  let fakeWidth = 0
  let fakeHeight = 0
  styles.forEach(({ height, width, style }) => {
    if (height > fakeHeight) {
      fakeHeight = height
    }
    if (width > fakeWidth) {
      fakeWidth = width
    }
  })

  // Now with the image picked, we loop through the sizes to mimic Gatsby for the picture element to respect the
  // desired size even though we're using the full image size for testing. In reality, will be the proper responsive
  // sized images.
  styles.forEach(({ height, width, style }) => {
    const srcSet = `${img} ${width / 3}w,\n${img},\n${img} ${width}w`
    const sizes = `(min-width: ${width}px) ${width}px, 100vw`

    // console.log('faker img', img)

    // imageStyles.styles[`${style}Src`] = {
    //   url: img,
    //   height,
    //   width,
    // }

    // @ts-ignore
    imageStyles.styles[style] = {
      childImageSharp: {
        gatsbyImageData: {
          layout: 'constrained',
          backgroundColor: '#787878',
          images: {
            fallback: {
              src: img,
              srcSet,
              sizes,
            },
            sources: [
              {
                srcSet,
                type: 'ImageWebp',
                sizes,
              },
            ],
          },
          width,
          height,
        },
      },
    }
  })

  // console.log('sampleGatsbyImage', imageStyles, styles)

  return imageStyles
}

const zIndex = {
  zModal: 1300,
  zMenuFront: 11,
  zMenuBack: 10,
  zHeader: 3,
  zOverlay: 2,
  zContent: 1,
  zBackground: 0,
  zHidden: -1,
}

module.exports = zIndex

import { filter, get, isFunction, isObject, noop, uniqueId } from 'lodash'
import { ApiError, ApiErrorAction, Callbacks } from '../lib/types'

let triggerSentry = noop

const SKIPABLE_ERROR_CODES = ['VALIDATION_ERROR']

/**
 * @typedef {import('redux-thunk').ThunkDispatch<any, null, ApiErrorAction>}
 *   ThunkDispatchApiError
 */

/**
 * Common action types.
 * @enum {string}
 */
export const commonActionType = {
  API_ERROR: 'API_ERROR',
}

/**
 * @typedef {Object} UnprocessableEntityError
 * @property {string} title Error title
 * @property {string} detail Error description
 * @property {string} code Error key ID
 * @property {string} status Error HTTP status
 * @property {Object<string, any>} meta Error metadata
 */

/**
 *
 * @param {function} errorHandler
 */
export const bindApiError = (errorHandler) => {
  // @ts-ignore
  triggerSentry = errorHandler
}

/**
 * Normalize CL API error into simplified error object.
 * @param {Array<UnprocessableEntityError>} errors CL API errors array
 * @return {Array<ApiError>} Normalized API errors array
 */
export const simplifyApiError = (errors) => {
  const finalErrors = errors.map((error) => {
    const code = get(error, 'meta.error') || error.code

    switch (code) {
      case 'too_short':
        return ''
    }

    return {
      id: uniqueId('error'),
      message: error.detail,
      code,
    }
  })

  // @ts-ignore
  return filter(finalErrors)
}

/**
 * Compose an API error action.
 *
 * @param {Array<ApiError>} errors The API errors array digested from CL API
 *   operation
 * @return {ApiErrorAction} Error action
 */
export const composeErrorAction = (errors) => {
  return {
    type: commonActionType.API_ERROR,
    payload: {
      errors,
    },
  }
}

/**
 * Handle API error digestion and dispatch API error action.
 *
 * @param {ThunkDispatchApiError} dispatch Redux dispatch
 * TODO: Need different API errors samples to determine if we can produce a TS
 *   definition.
 * @param {any} error CL API error response
 */
export const handleApiError = (dispatch, error) => {
  const status = get(error, 'status') || get(error, 'code', '')
  // console.log('api error' + JSON.stringify(error))
  // console.log(error.body.error)

  // Obtain the CL error code. If it is not in the skip list, flag the error.
  const clErrorCode = error?.errors?.[0]?.code
  if (!SKIPABLE_ERROR_CODES.includes(clErrorCode)) {
    triggerSentry({
      error,
    })
  }

  switch (status) {
    case 401:
      if (clErrorCode === 'INVALID_TOKEN') {
        // throw new Error('INVALID_TOKEN_401')
      }
      console.log('API access denied:', error)
      break

    case 400:
    case 422:
      dispatch(composeErrorAction(simplifyApiError(error.errors)))
      break

    case 'EAUTH':
      // tried to start session with invalid scope token, use default fallback.
      if (error.body.error === 'invalid_scope') {
        // throw Error('invalid_scope')
      } else {
        dispatch(
          composeErrorAction([
            {
              message: get(error, 'body.message'),
              code: get(error, 'body.error'),
            },
          ])
        )
      }
      break

    default:
      // dispatch(startGuestSession())
      if (error.message === 'timeout longer than 5000ms') {
        console.log('timeoutlonger')
      }
      console.error('API unhandled error: ', error)
      console.log('API unhandled error: ', error)

      break
  }
}

/**
 * Invoke success callback when defined.
 * @param {Callbacks} callbacks
 * @param {Object} action
 */
export const invokeSuccessCallback = (callbacks, action) => {
  if (isObject(callbacks) && isFunction(callbacks.onSuccess)) {
    callbacks.onSuccess(action)
  }
}

/**
 * Invoke error callback when defined.
 * @param {Callbacks} callbacks
 * @param {Error} error
 */
export const invokeErrorCallback = (callbacks, error) => {
  if (isObject(callbacks) && isFunction(callbacks.onError)) {
    callbacks.onError(error)
  }
}

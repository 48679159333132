import { first } from 'lodash'

/**
 * @typedef {import('@ggs/types').Market} Market
 * @typedef {import('@ggs/types').Customer} Customer
 */

/**
 * Resolve appropriate region market for current customer type.
 * @param {Array<Market>} markets Region markets
 * @param {Customer} customer Current customer
 * @return {Market} Resolved region market
 */
function resolveRegionMarket(markets = [], customer) {
  const isSingleMarket = markets.length === 1

  if (isSingleMarket) {
    return first(markets)
  }

  const marketForCustomerType = markets.find((market) => {
    return market?.customerGroup?.tid === customer.customerType
  })

  // Market match for current customer type.
  if (marketForCustomerType) {
    return marketForCustomerType
  }

  // No market available for customer type, fallback in the fist one.
  return first(markets)
}

export {
  resolveRegionMarket
}

import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { colors } from '@ggs/styles'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} QuantitySelectProp
 * @property {number} existingQuantity Quantity that exists on draft item for the line item
 * @property {number} currentQuantity Quantity that is currently selected from the options
 * @property {React.ChangeEventHandler} handleQuantitySelection Callback to store selected quantity in local state
 * @property {Array<number>} quantityOptions
 */

/**
 * Shopping quantity select dropdown.
 * @param {QuantitySelectProp} props
 * @return {JSX.Element}
 */
export default function QuantitySelect(props) {
  const { existingQuantity, currentQuantity, handleQuantitySelection } = props
  const emptyQuantityOptions = props.quantityOptions.length <= 0
  const quantityOptions = props.quantityOptions?.length ? props.quantityOptions : ['-']

  // console.log('QuantitySelect', {
  //   existingQuantity,
  //   currentQuantity,
  //   emptyQuantityOptions,
  //   quantityOptions,
  //   props
  // })

  return (
    <>
      <TextField
        onChange={handleQuantitySelection}
        select
        value={String(currentQuantity) || '-'}
        disabled={emptyQuantityOptions}
        sx={{
          minWidth: '50px',
          mr: {
            md: 5,
            sm: 0,
          },
          '.MuiSelect-select': {
            padding: 1.5,
          },
          '& .MuiInputBase-root': {
            background: colors.white,
          },
        }}
      >
        {quantityOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}

import React from 'react'
import GridContainer from '@ggs/components/layout/GridContainer'
import { HalfWidthFlare, PageBackgroundAccent } from '@ggs/components/flare'
import { Title } from '@ggs/components/paragraphs'
// Assets.
import './PageHeader.scss'
import Box from '@mui/material/Box'
import { BackLink } from '@ggs/components/nav'
import { colors, zIndex } from '@ggs/styles'
/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * @typedef {Object} BackLinkProps
 * @property {string} title
 * @property {string=} url
 * @property {string=} uri
 */

/**
 * @typedef {Object} PageHeaderProps
 * @property {String=} title
 * @property {String=} subtitle
 * @property {String=} backgroundVariant
 * @property {Boolean=} useFlare
 * @property {SxProps=} sx
 * @property {BackLinkProps=} backLink
 * @property {JSX.Element=} children
 */
/**
 * @param {PageHeaderProps} props
 * @return {JSX.Element}
 */
export default function PageHeader({
  title,
  subtitle,
  backgroundVariant = PageBackgroundAccent.variant.blue,
  useFlare = true,
  sx,
  backLink,
  children,
}) {
  const hasBackLink = backLink?.title && (backLink?.url || backLink?.uri)

  return (
    <Box
      className="page-header"
      sx={{
        ...sx,
        '.page-header__content': {
          zIndex: zIndex.zBackground,
          position: 'relative',
        },
        '.page-header__content .grid-container__inner': {
          position: 'relative',
        },
        '.back-link': {
          position: 'absolute',
          zIndex: zIndex.zOverlay,
          // p: 0,
          pl: {
            xs: 2,
            md: 5,
          },
          top: {
            xs: '-1rem',
            md: 0
          },
          left: 0,
        },
      }}
    >
      <GridContainer
        className="page-header__content"
        sx={{ pt: { xs: hasBackLink?5 : 2, md: 9 }, pb: { xs: 2, md: 4 }, zIndex:2 }}
      >
        <PageBackgroundAccent variant={backgroundVariant} />
        {hasBackLink && (
          <BackLink label={backLink?.title} href={backLink?.url || backLink?.uri} />
        )}
        <Title title={title} subtitle={subtitle} style="h1" />
        {children}
      </GridContainer>
      {useFlare && <HalfWidthFlare />}
    </Box>
  )
}

import React, { useEffect, useState } from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { find, isEmpty, isFunction, noop } from 'lodash'
import useGeoData from '@ggs/components/ecomm/Customer/AddressForm/useGeoData'
import { Button } from '@ggs/gatsby/components/nav'

/**
 * @typedef {import('@commercelayer/sdk').Address} Address
 */

/**
 * @typedef {Object} AddressViewProps
 * @property {Address} address
 * @property {function} editAddress
 * @property {function} deleteAddress
 * @property {string} variant
 */

/**
 * Generic address view that could be applied to billing or shipping address display.
 * @param {AddressViewProps} props
 * @return {JSX.Element}
 */
const AddressView = ({
  address,
  editAddress = noop,
  deleteAddress = noop,
  variant = 'checkout',
}) => {
  const { t } = useI18n()
  const { getGeoData } = useGeoData()

  const [display, setDisplay] = useState(address || {})
  // @ts-ignore
  const { full_name, line_1, line_2, city, state_code, country_code, zip_code } = display

  const editAddressFn = (/** @type {any} */ e) => {
    // todo: implement edit address
    isFunction(editAddress) && editAddress(e)
  }

  const deleteAddressFn = (/** @type {any} */ e) => {
    // todo: implement delete address
    isFunction(deleteAddress) && deleteAddress(e)
  }

  useEffect(() => {
    const runAsync = async () => {
      const { countryOptions, countryStateOptions } = await getGeoData(address.country_code)
      // console.log('geoData', {
      //   countryOptions,
      //   countryStateOptions,
      // })
      setDisplay(() => ({
        ...address,
        country_code:
          find(countryOptions, ['value', address.country_code])?.label || address.country_code,
        state_code:
          find(countryStateOptions, ['value', address.state_code])?.label || address.state_code,
      }))
    }
    runAsync()
  }, [address])

  return isEmpty(address) ? null : (
    <Box className={'address-view'}>
      <Typography variant={'body1'}>{full_name}</Typography>
      <Typography variant={'body1'}>{line_1}</Typography>
      {line_2 && <Typography variant={'body1'}>{line_2}</Typography>}
      <Typography variant={'body1'}>
        {city}, {state_code}, {country_code},{' '}
        <span style={{ textTransform: 'uppercase' }}>{zip_code}</span>
      </Typography>
      {variant !== 'checkout' ? (
        <>
          <Button label={t('account:button.edit')} onClick={editAddressFn} />
          <Button label={t('account:button.delete')} onClick={deleteAddressFn} />
        </>
      ) : null}
    </Box>
  )
}

export default AddressView

import { detectLanguage, getUserGeolocation } from '@ggs/commercelayer/index'
import { setPreferredStore } from '@ggs/store/actions/ui'
import { findUserPreferredStore } from '@ggs/utils/regionPathMatch'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import preferredStore from '@ggs/components/nav/PreferredStore'

/**
 *
 * @param {{stores:*, userCountry:string }} props
 */
export const useResolveUserOptions = ({ stores, userCountry, allowedLanguages }) => {
  const dispatch = useDispatch()
  /**
   * Resolve user language from browser settings.
   */
  const resolveUserLanguage = () => {
    dispatch(detectLanguage(allowedLanguages))
  }

  /**
   * Resolve preferred (recommended) store based on user IP location.
   */
  const resolveUserPreferredStore = () => {
    if (!isEmpty(stores) && userCountry) {
      const preferredStore = findUserPreferredStore(stores, userCountry)
      if (preferredStore) {
        dispatch(setPreferredStore(preferredStore))
      }
    }
  }

  /**
   * Resolve user location and preferred store.
   */
  const resolveUserLocation = () => {
    dispatch(getUserGeolocation())
  }

  useEffect(() => {
    resolveUserLocation()
  }, [])
  useEffect(() => {
    resolveUserPreferredStore()
  }, [stores, userCountry])
  useEffect(() => {
    // const preferredStore = findUserPreferredStore(stores, userCountry)
    // console.log('prefstore', preferredStore, stores, userCountry)
    // resolveUserLanguage()
  }, [preferredStore, stores, userCountry])
}

// @ts-nocheck
import { has } from 'lodash'

/**
 * Support for various number formatting needs.
 */

export const CURRENCIES = {
  CAD: 'CAD',
}

const formats = {
  currency: {
    CAD: new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: CURRENCIES.CAD,
    }),
  },
  percent: {
    CAD: new Intl.NumberFormat('en-CA', { maximumSignificantDigits: 2 }),
  },
}

const floatToString = (num) => {
  const str = num.toString()
  const index = str.indexOf('e')
  if (index === -1) {
    return str
  }
  const zeros = new Array(+str.slice(index + 2)).fill(null).reduce((acc) => `${acc}0`, '')
  return str[index + 1] === '+'
    ? `${str.slice(0, index)}${zeros}`
    : `0.${zeros.slice(0, zeros.length - 1)}${str.slice(0, index).replace('.', '')}`
}

export const stripDecimalPoints = (value, precision) => {
  // Don't waste effort if we don't have a amount to cut off.
  if (!precision) {
    return value
  }
  let sAmount = floatToString(value || 0).split('.')
  if (sAmount?.[1] && precision) {
    sAmount[1] = sAmount[1].substr(0, precision)
  }
  sAmount = sAmount.join('.')
  return sAmount
}

export const currency = (type = CURRENCIES.CAD, lang = 'en-CA') => {
  const formatter = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: type,
  })
  return (value) => formatter.format(parseFloat(stripDecimalPoints(value, 2)))
}

export const percent = (value, type = CURRENCIES.CAD) =>
  formats.percent[type].format(parseFloat(stripDecimalPoints(value, 2)))

export const maskedPhoneToDigits = (maskedNumber, format = /\((\d{3})\)\s(\d{3})-(\d{4})/g) => {
  let matches = String(maskedNumber || '').matchAll(format)
  // eslint-disable-next-line prefer-destructuring
  matches = [...matches][0]
  if (Array.isArray(matches) && matches.length) {
    const [, tel1, tel2, tel3] = matches
    return `${tel1}${tel2}${tel3}`
  }
  return maskedNumber
}

export const phoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return null
}

import React from 'react'
import List from '@mui/material/List'
import { Title } from '@ggs/components/paragraphs'
import { CartLineItem } from '@ggs/components/ecomm'
import { find, isEmpty } from 'lodash'
import { useI18n } from '@ggs/gatsby/lib'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} CartTotalsProp
 * @property {Order} order
 */

/**
 * Shopping mini cart line items
 * @param {CartTotalsProp} props
 * @return {JSX.Element}
 */
export default function MiniCartItemList({ order }) {
  const { t } = useI18n()
  const cartIsEmpty = !order || !Array.isArray(order.line_items) || isEmpty(order.line_items)
  const hasIncompleteItems = !!find(order?.line_items, ['metadata'])

  return cartIsEmpty ? (
    <Title title={t('ecomm:cart.label.cartIsEmpty')} style="h4" />
  ) : (
    <>
      <List
        sx={{
          maxWidth: '100%',
          p: '0 0 2rem 0',
        }}
      >
        {order.line_items.map((lineItem) => {
          return lineItem.item_type === 'skus' ? (
            <CartLineItem order={order} key={lineItem.id} lineItem={lineItem} />
          ) : null
        })}
      </List>
      {/*
       If any items were missing their metadata, we should display a consolidated error at the end of hte list
       indicating as such.
       // someItemsCantBeRetrievedAtTheMoment
       */}
      {hasIncompleteItems && <p>{t('ecomm:cart.notice.itemsCantBeRetrieved')}</p>}
    </>
  )
}

import React from 'react'
import { icons } from '@ggs/components'
import './VideoPlayIcon.scss'

/**
 *
 * @return {JSX.Element}
 */
export default function VideoPlayIcon() {
  return <div className={'video-play-icon'}>{icons.Play}</div>
}

const globalDefaultScope = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`
const GATSBY_DEFAULT_CUSTOMER_GROUP_ID = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`
const GATSBY_DEFAULT_REGION_ID = `${process.env.GATSBY_DEFAULT_REGION_ID}`


const customerGroupConfig = ({
  customerGroupLangId,
  customerGroupTid,
  customerGroupName
}) => ({
  language: { customerGroupLangId },
  customerGroupTid,
  customerGroupName
})

const marketConfig = ({
  marketTaxId,
  marketId,
  customerGroupSettings: {
    language: { customerGroupLangId },
    customerGroupTid,
    customerGroupName
  },
  region: { regionName, regionEntityId }
}) => ({
  tid: marketTaxId,
  marketId: marketId,
  customerGroup: {
    language: {
      id: customerGroupLangId
    },
    tid: customerGroupTid,
    name: customerGroupName
  },
  region: {
    name: regionName,
    entityId: regionEntityId
  }
})

const parseMarketIdFromScope = (scope) => scope.includes('market:') ? scope.split('market:')[1] : scope

export const envConfig = () => {
  const defaultMarketId = parseMarketIdFromScope(globalDefaultScope)
  const defaultMarketTaxId = 526
  const defaultCustomerGroupId = !Number.isNaN(GATSBY_DEFAULT_CUSTOMER_GROUP_ID)
    ? Number(GATSBY_DEFAULT_CUSTOMER_GROUP_ID)
    : 365
  const defaultRegionId = !Number.isNaN(GATSBY_DEFAULT_REGION_ID)
    ? Number(GATSBY_DEFAULT_REGION_ID)
    : 19

  const customerGroupSettings = customerGroupConfig({
    customerGroupLangId: 'en-CA',
    customerGroupTid: defaultCustomerGroupId,
    customerGroupName: 'Patients & Consumers'
  })

  const marketSettings = marketConfig({
    marketTaxId: defaultMarketTaxId,
    marketId: defaultMarketId,
    customerGroupSettings,
    region: {
      regionName: 'Default',
      regionEntityId: defaultRegionId
    }
  })

  return {
    marketSettings,
    lang: 'en-CA',
    country: 'CA'
  }
}
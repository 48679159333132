import { useMemo } from 'react'
import { breakpoints, colors, typography } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 *
 * @return {SxProps}
 */
export default function useFooterSx() {
  return useMemo(
    () => ({
      '> div': {
        '&, & a': {
          color: colors.white,
        },
        svg: {
          path: {
            fill: colors.white,
          },
        },
      },
      '& .footer__branding': {
        '& a': {
          mx: {
            lg: 4,
          },
          typography: typography.menuItemRegular,
          '> svg': {
            mb: 1 / 2,
            verticalAlign: 'middle',
          },
        },
        '> .grid-container__inner': {
          py: {
            xs: 4,
            md: 4,
          },
        },
        '& .footer__branding-item': {
          '& a': {
            display: {
              xs: 'block',
              md: 'inline-block',
            },
            mb: {
              xs: 2,
              md: 0,
            },
            width: {
              xs: '100%',
              md: 'auto',
            },
          },
          '&:last-child': {
            a: {
              mb: {
                xs: 0,
              },
            },
          },
        },
      },
      '& .footer__legal': {
        '& .footer__copyright': {
          '.text__body p': {
            typography: typography.pSmall,
            color: colors.white,
          },
          a: {
            textDecoration: 'underline',
          },
        },
        '& .footer__legal-menu': {
          a: {
            typography: typography.pSmall,
            textDecoration: 'underline',
          },
        },
      },
      '& .footer__checkout': {
        color: colors.white,
        '& .title': {
          color: colors.white,
        },
        '& .footer__checkout-notice, & .footer__checkout-details': {
          'h4': {
            mt: 0,
            mb: {
              xs: 0,
              md: 3,
            }
          },
          '& p': {
            typography: {
              xs: typography.caption,
              md: typography.pSmall,
            },
            m: 0
          }
        },
      },
    }),
    []
  )
}

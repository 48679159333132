/**
 * @typedef {Object} GaProductItem
 * @property {string?} item_id SKU_12345
 * @property {string?} item_name
 * @property {string?} affiliation A product affiliation to designate a supplying company or brick and mortar store location
 * @property {string?} coupon The coupon name/code associated with the item.
 * @property {number?} discount  The monetary discount value associated with the item.
 * @property {number} index The index/position of the item in a list.
 * @property {string?} item_brand The brand of an item
 * @property {string?} item_category The category of the item.
 * @property {string?} item_category2
 * @property {string?} item_category3
 * @property {string?} item_category4
 * @property {string?} item_category5
 * @property {string?} item_list_id The ID of the list in which the item was presented to the user.
 * @property {string?} item_list_name The name of the list in which the item was presented to the user.
 * @property {string?} item_variant The item variant or unique code or description for additional item details/options.
 * @property {string?} location_id Google Place ID that corresponds with the physical location associated with the item
 * @property {number?} price The monetary price of the item, in units of the specified currency parameter.
 * @property {number?} quantity Item quantity. If not set, quantity is set to 1.
 */

/**
 * @param item_id
 * @param item_name
 * @param affiliation
 * @param coupon
 * @param discount
 * @param index
 * @param item_brand
 * @param item_category
 * @param item_category2
 * @param item_category3
 * @param item_category4
 * @param item_category5
 * @param item_list_id
 * @param item_list_name
 * @param item_variant
 * @param location_id
 * @param price
 * @param quantity
 * @returns {{quantity: null, coupon: null, item_id: null, item_category4: null, discount: null, index: null, item_category5: null, item_name: null, item_category2: null, item_category3: null, item_brand: null, item_category: null, location_id: null, item_list_id: null, affiliation: null, item_list_name: null, price: null, item_variant: null}|null}
 */
export const GaProductItem = (args) => {
  const {
    item_id = null,
    item_name = null,
    affiliation = null,
    coupon = null,
    discount = null,
    index = null,
    item_brand = null,
    item_category = null,
    item_category2 = null,
    item_category3 = null,
    item_category4 = null,
    item_category5 = null,
    item_list_id = null,
    item_list_name = null,
    item_variant = null,
    location_id = null,
    price = null,
    quantity = null,
    ...categoryObj
  } = args
  return item_id || item_name
    ? {
      item_id: item_id ?? undefined,
      item_name: item_name ?? undefined,
      affiliation: affiliation ?? undefined,
      coupon: coupon ?? undefined,
      discount: discount ?? undefined,
      index: index ?? undefined,
      item_brand: item_brand ?? undefined,
      item_category: item_category ?? undefined,
      item_category2: item_category2 ?? undefined,
      item_category3: item_category3 ?? undefined,
      item_category4: item_category4 ?? undefined,
      item_category5: item_category5 ?? undefined,
      item_list_id: item_list_id ?? undefined,
      item_list_name: item_list_name ?? undefined,
      item_variant: item_variant ?? undefined,
      location_id: location_id ?? undefined,
      price: price ?? undefined,
      quantity: quantity ?? undefined,
    }
    : null
}

import React from 'react'
import { isObject } from 'lodash'
import { useI18n } from '@ggs/gatsby/lib'
import Grid from '@mui/material/Grid'
import { Divider, Title } from '@ggs/components/paragraphs'
import { SharePage } from '@ggs/components/common'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { GridContainer } from '@ggs/components/layout'
// Assets
import { colors, typography } from '@ggs/styles'
import useNewsAndEventsHeroSx from './useNewsAndEventsHeroSx'
import useEventElements from './useEventElements'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 * @typedef {import('@ggs/types').Event} Event
 * @typedef {import('dayjs').Dayjs} Dayjs
 */

/**
 *
 * @param {Event} event
 * @return {JSX.Element}
 */
export default function EventHero(event) {
  const { t } = useI18n()
  const sx = useNewsAndEventsHeroSx()
  const { url = '', title = '', eventImage, eventType } = event
  const { publishDate, isUpcoming, categories, dateAndTime, eventLocation, eventActions }
    = useEventElements(event)

  // eslint-disable-next-line prefer-rest-params
  // console.log('EventHero render', arguments[0], publishDate) //, JSON.stringify(arguments[0]))

  return (
    <GridContainer
      className="event-hero"
      sx={{
        ...sx,
        '.event-hero__field-label': {
          typography: typography.menuItemSmall,
          color: colors.blue,
        },
        '.event-hero__p-bold': {
          typography: typography.pBold,
          fontSize: '15px',
        },
        '.MuiButton-outlined': {
          span: {
            color: colors.red,
          },
        },
        '.event-hero__date': {
          maxWidth: {
            md: '95%',
          }
        },
        '.share-page': {
          justifyContent: {
            lg: 'start',
          },
        },
      }}
    >
      <Grid container p={0} spacing={0}>
        <Grid className="event-hero__title-tags" item xs={12}>
          {categories}
          <Title title={title} style={'h1'} />
        </Grid>
        <Grid
          className="event-hero__meta"
          item
          xs={12}
          md={isUpcoming ? 9 : 6}
          marginBottom={{
            xs: 2,
            lg: 0,
          }}
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Grid container>
            {isUpcoming && (
              <Grid
                item
                className="event-hero__p-bold"
                xs={12}
                md={2}
                lg={3}
                marginBottom={{
                  xs: 1,
                  lg: 0,
                }}
              >
                {
                  // @ts-ignore
                  eventType?.name
                }
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={isUpcoming ? 6 : 12}
              lg={isUpcoming ? 6 : 7}
              marginBottom={{
                xs: 1,
                lg: 0,
              }}
            >
              {dateAndTime}
            </Grid>
            <Grid item xs={12} md={isUpcoming ? 3 : 12} lg={isUpcoming ? 3 : 5}>
              {eventLocation}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className="event-hero__actions"
          item
          xs={12}
          md={isUpcoming ? 3 : 6}
          textAlign={{
            lg: isUpcoming ? 'right' : null,
          }}
        >
          {eventActions}
        </Grid>
        <Grid className="event-hero__share" item xs={12} marginBottom={3}>
          <Divider
            sx={{
              mt: 3,
              mb: {
                xs: 0,
                lg: 2,
              },
            }}
          />
          <SharePage url={url} />
        </Grid>
        {isObject(eventImage) && (
          <Grid item xs={12} className="event-hero__content" alignItems="center">
            <ImageStyles {...eventImage} selectedStyles={['pageHeroFull']} />
          </Grid>
        )}
      </Grid>
    </GridContainer>
  )
}

import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import { AddressForm } from '@ggs/components/ecomm'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} ShippingAddressFormProps
 * @property {Order} order
 * @property {function=} onSuccess
 * @property {function=} onError
 */

/**
 * Checkout shipping address form (order payment step).
 * @param {ShippingAddressFormProps} props
 * @return {JSX.Element}
 */
const ShippingAddressForm = ({ order, onSuccess, onError }) => {
  const { t } = useI18n()

  const formProps = {
    orderPropertyKey: 'shipping_address',
    labels: {
      email: t('account:label.contactInformation'),
      country: t('account:label.shippingInformation'),
      submitLabel: t('ecomm:button.viewShippingMethods'),
      afterEmail: t('account:notice.orderConfirmationToThisEmail'),
      afterPhone: t('account:notice.orderConfirmationToThisPhone'),
    },
    onSuccess,
    onError,
  }

  return <AddressForm {...formProps} />
}

export default ShippingAddressForm

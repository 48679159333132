import * as React from 'react'
import Box from '@mui/material/Box'
import { colors, typography } from '@ggs/styles'
import SidebarMenuDesktop from './SidebarMenuDesktop'
import SidebarMenuMobile from './SidebarMenuMobile'

/**
 * @typedef {object} SidebarMenuProps
 * @property {Array<Object>} menuItems
 * @property {function} onClick
 */

/**
 *
 * @param {SidebarMenuProps} props
 * @return {JSX.Element}
 */
export default function SidebarMenu(props) {
  const hoverMenuStyle = {
    backgroundColor: `${colors.blue} !important`,
    color: colors.white,
    opacity: 1,
  }
  const mobileMenuWidth = 280
  return (
    <Box
      sx={{
        '.sidebar-menu__desktop': {
          display: {
            xs: 'none',
            lg: 'block',
          },
        },
        '.sidebar-menu__mobile': {
          m: '0 auto',
          width: mobileMenuWidth,
          display: {
            lg: 'none',
          },
        },
        '.MuiPaper-root': {
          backgroundColor: colors.white,
        },
        '.MuiList-root': {
          p: 0,
        },
        '.MuiMenuItem-root, .sidebar-menu__mobile-button': {
          typography: typography.menuItemRegular,
          p: 2,
          backgroundColor: colors.white,
          '&:hover, &.Mui-selected': hoverMenuStyle,
        },
        '.MuiMenuItem-root': {
          whiteSpace: 'break-spaces'
        },
        '.sidebar-menu__mobile-popper': {
          width: mobileMenuWidth,
        },
        '.sidebar-menu__mobile-button': {
          borderRadius: 0,
          width: mobileMenuWidth,
          ...hoverMenuStyle,
          span: {
            color: colors.white,
          },
          svg: {
            fill: colors.white,
          },
        },
      }}
    >
      <SidebarMenuDesktop {...props} />
      <SidebarMenuMobile {...props} />
    </Box>
  )
}

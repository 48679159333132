import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'
import {
  gtmCheckoutValue,
  gtmCoupon,
  gtmCurrency,
  gtmItems
} from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/gtagFromOrderParse'

export const fireViewCart = async ({ order }) => {
  return pushDataLayer({
    eventName: EaActionType.viewCart,
    dataLayer: {
      coupon: gtmCoupon(order),
      currency: gtmCurrency(order),
      items: gtmItems(order),
      value: gtmCheckoutValue(order),
    },
  })
}

import React, { useRef, useEffect } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'
import icons from '@ggs/components/icons'
// @ts-ignore
import { colors } from '@ggs/styles'
// Assets
import './Accordion.scss'
import useHcpGatedAction from '@ggs/components/hooks/useHcpGatedAction'
import { Button } from '@ggs/gatsby/components/nav'
import { useI18n } from '@ggs/gatsby/lib'
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined'
import { ImpressionClickObserver } from '@ggs/components/ecommerceAnalytics'

/**
 * Accordion Variant
 * @enum {string}
 */
const AccordionVariant = {
  default: 'default',
  faq: 'faq',
  filters: 'filters',
}

/**
 * @typedef {Object} AccordionProps
 * @property {String=} id
 * @property {String|JSX.Element=} label
 * @property {String|JSX.Element=} content
 * @property {Number=} customerRole
 * @property {AccordionVariant=} variant
 */
/**
 *
 * @param {AccordionProps} props
 * @return {JSX.Element}
 */
export default function Accordion({
  id,
  label,
  content,
  customerRole,
  variant = AccordionVariant.default,
  defaultExpanded = false
}) {
  const { t } = useI18n()
  const accordionRef = useRef()
  const [expanded, setExpanded] = React.useState(false)
  const { triggerHcpAction, modal, shouldTriggerModal } = useHcpGatedAction({
    resolve: (/** @type String */ value) => {
      // @ts-ignore
      value === 'hcp' && accordionRef.current.click()
    },
  })

  /**
   * Attempt to trigger tab. If gating is required, show modal, else open normally.
   * @param {Event} e
   * @return {boolean}
   */
  const allowAccordion = (e) => {
    // console.log('allowAccordion', e)
    triggerHcpAction({ targetId: customerRole, targetValue: 'hcp' })
    e.preventDefault()
    return false
  }

  const sx = {
    mb: 3,
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      background: colors.lightBlue,
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.04)',
      borderRadius: 0.5,
      '.MuiTypography-root': {
        textTransform: 'none',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      background: colors.white,
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
      my: 2,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded .accordion__icon': {
      display: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper .accordion__icon-expanded': {
      display: 'none',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded .accordion__icon-expanded': {
      display: 'block',
    },
    '& .MuiAccordionDetails-root': {
      boxShadow: 'none',
      backgroundColor: colors.white,
      borderRadius: '0 0 4px 4px !important',
      p: 2,
    },
  }

  const expandIcon = (
    <>
      {variant === AccordionVariant.filters ? (
        <span className="accordion__icon-filters">{icons.Filters}</span>
      ) : (
        <>
          <span className="accordion__icon">{icons.AccordionClosedRed}</span>
          <span className="accordion__icon-expanded">{icons.AccordionOpenRed}</span>
        </>
      )}
    </>
  )

  const disabled = shouldTriggerModal(customerRole)

  /**
   * Listen for accordion state changes.
   * @param {React.MouseEvent<HTMLElement>} event
   * @param {boolean} isExpanded
   *
   */
  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded)
  }

  // Determine action based on MUI accordion item state.
  const clickAction = expanded ? 'close' : 'open'
  const actionType = `${clickAction}-accordion-item`

  return (
    <ImpressionClickObserver>
      <Box
        data-label={label}
        data-action-type={actionType}
        data-source={id}
        className={`accordion accordion--${variant}`}
        sx={sx}
      >
        <MuiAccordion defaultExpanded={defaultExpanded} ref={accordionRef} disabled={disabled} onChange={handleAccordionChange}>
          <AccordionSummary
            expandIcon={expandIcon}
            className="tabs__accordion-summary"
            aria-controls={`tabs__accordion-content_${id}`}
            id={`tabs__accordion-header_${id}`}
          >
            <Typography>{label}</Typography>
          </AccordionSummary>
          <AccordionDetails>{content}</AccordionDetails>
        </MuiAccordion>
        {disabled && (
          <Button onClick={allowAccordion} variant={'text'} endIcon={<BeenhereOutlinedIcon />}>
            {t('global:label.areYouAHealthCareProfessional')}
          </Button>
        )}
        {modal}
      </Box>
    </ImpressionClickObserver>
  )
}

Accordion.variant = AccordionVariant

import { initialState } from '../initialState'
import { createReducer } from '@reduxjs/toolkit'
import { checkoutActionsType } from '../actions/checkout'

/**
 * @typedef {import('@ggs/store').initialState} DefaultState
 * @typedef {import("../initialState").DefaultCheckout} DefaultCheckout
 * @typedef {import('../actions/checkout').SetCheckoutStepsCompleteAction}
 *   SetCheckoutAction
 **/
/**
 * @returns {DefaultState['checkout']} New commerce state.
 */
const checkout = createReducer(initialState.checkout, {
  [checkoutActionsType.SET_CHECKOUT_STATE]: (state, action) => {
    console.log(action)
    state.checkoutState = action.payload ?? state.checkoutState
  },
  [checkoutActionsType.RESET_CHECKOUT_STATE]: (state, action) => {
    console.log(action)
    state.checkoutState = initialState.checkout.checkoutState
  },
  [checkoutActionsType.SET_CHECKOUT_STEPS_COMPLETE]: (state, action) => {
    state.stepsComplete = action.payload ?? state.stepsComplete
    // ?? initialState.checkout.stepsComplete
    // ?? initialState.checkout.checkoutState
  },
  [checkoutActionsType.RESET_CHECKOUT_STEPS_COMPLETE]: (state, action) => {
    state.stepsComplete = initialState.checkout.stepsComplete
    state.currentStepName = initialState.checkout.currentStepName
    state.currentStepperIndex = initialState.checkout.currentStepperIndex
  },
  [checkoutActionsType.SET_CHECKOUT_CURRENT_STEP]: (state, action) => {
    state.currentStepName = action.payload.currentStepName
    state.currentStepperIndex = action.payload.currentStepperIndex
  },
  [checkoutActionsType.RESET_CHECKOUT_EXCEPT_SHIPPING_ADDRESS]: (state, action) => {
    state.currentStepName = initialState.checkout.currentStepName
    state.currentStepperIndex = initialState.checkout.currentStepperIndex
    // state.checkoutState = { ...initialState.checkout.checkoutState,
    // ...action.payload }
    state.stepsComplete = initialState.checkout.stepsComplete
  },
  [checkoutActionsType.SET_LAST_ORDER_ID]: (state, action) => {
    state.lastOrderId = action.payload
  },
  [checkoutActionsType.SET_PAYPAL_META]: (state, action) => {
    state.paypalMeta = {
      ...state.paypalMeta,
      ...action.payload,
    }
  },
  [checkoutActionsType.SET_PAYPAL_STATUS]: (state, action) => {
    state.paypalMeta.paypalStatus = action.payload.paypalStatus
  },
  [checkoutActionsType.SET_TAX_CALC]: (state, action) => {
    state.taxCalc = action.payload
  },
})

export default checkout

import { BillingAddressForm, BillingAddressReview, PaymentMethodForm } from '@ggs/components/ecomm'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { colors } from '@ggs/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'
import CHECKOUT_STEP_ENUMS from '../Checkout/enums/CHECKOUT_STEP_ENUMS'
import { addNotification, useDispatch } from '@ggs/store'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'

// const { AUTH, ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS
const { PAYMENT } = CHECKOUT_STEP_ENUMS

/**
 * Checkout order information second step.
 * @return {JSX.Element}
 */
export const PaymentInformation = () => {
  const {
    order,
    handlers: {
      goToReview
    },
    checkout: {
      checkoutState: {
        billingAddressId,
        orderId,
        shippingCountryCode,
        shippingStateCode,
        shippingAddressId,
        shippingMethodId
      },
    },
    markStepComplete,
    // clClient,
  } = useCheckoutContext()

  const { fireAddShippingInfo } = useCheckoutAnalytics({ order })

  useEffect(() => {
    if (shippingCountryCode && shippingAddressId && shippingMethodId) {
      fireAddShippingInfo({ order })
    }
  }, [
    orderId,
    shippingCountryCode,
    shippingStateCode,
    shippingAddressId,
    shippingMethodId
  ])

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [billingAddressComplete, setBillingAddressComplete] = useState(
    true)

  /**
   * Handle continue next step.
   */
  const handleContinue = () => {
    setTimeout(() => {
      if (billingAddressComplete) {
        markStepComplete(PAYMENT)
        goToReview()
      } else {
        dispatch(addNotification('missing billing address', 'error',
                                 t('ecomm:label.missingBillingAddress')))
      }
    }, 350)
  }

  const sx = {
    backgroundColor: colors.white,
    borderRadius: '4px',
    px: {
      xs: 3,
      md: 7,
    },
    pt: {
      xs: 3,
      md: 5,
    },
    pb: {
      xs: 3,
      md: 7,
    },
    '.billing-address-review .payment-source-details': {
      display: 'none',
    },
  }

  const onSuccessAddress = (/** @type {any} */ values) => {
    setBillingAddressComplete(true)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box sx={sx}>
          {order?.billing_address && billingAddressId && billingAddressComplete ? (
            <BillingAddressReview
              title={t('account:label.billingAddress')}
              editPayment={() => {
                setBillingAddressComplete(false)
                // dispatch(
                //   resetOrderPaymentMethod({
                //     clClient,
                //     order,
                //     callbacks: {
                //       onSuccess: (action) => {
                //         // Reset URL hash to clean PayPal return parameters
                //         history.replaceState(null, null, ' ')
                //         // After successful reset, mark as default method
                // for // initialization effect. }, onError: () => { //
                // onError() }, }, }) )
              }}
              order={order}
              showPayment={false}
              showSameAsControl={true}
            />)
            : <BillingAddressForm order={order} onSuccess={onSuccessAddress}
            />}
          <PaymentMethodForm order={order} onSuccess={handleContinue}/>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PaymentInformation

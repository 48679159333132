// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { isFunction, omit } from 'lodash'
// Components
import MuiTextField from '@mui/material/TextField'
// Data
// Assets
import './_TextField.scss'
import { events } from '@ggs/utils'

const TextField = (props) => {
  const { className, name, icon, onChange, onBlur, formikValue, autoComplete } = props
  // console.log(`TextField::render ${props?.name}`,props, onChange)
  const initialValue = formikValue || props?.value || ''
  const [value, setValue] = useState(initialValue)
  const handleChange = (event) => {
    const newValue = event.target.value
    setValue(newValue)
    // console.log('TextField::handleChange', event, ref)
    isFunction(onChange) && onChange(event)
  }

  const reset = () => {
    handleChange(events.syntheticInputEvent(name, { value: '' }))
  }

  // Sync local state when formik value changed.
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // console.log(`TextField::render ${name}`, value)
  const extraProps = omit(props, ['defaultValue'])
  // console.log('TextFieldProps:', extraProps, 'Value:', value)

  return (
    <>
      {icon !== null && icon}
      <MuiTextField
        {...extraProps}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        className={`${className} form-field__text-field`}
        variant="outlined"
        margin="normal"
        value={value || ''}
        autoComplete={autoComplete}
      />
      <button
        className="form-field__reset"
        data-name={name}
        type="button"
        aria-hidden
        onClick={reset}
      />
    </>
  )
}

// Exports
// TextField.defaultProps = defaultProps
// TextField.propTypes = propTypes
TextField.displayName = 'TextField'
export default TextField

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import { extend, filter } from 'lodash'
import { normalizeMetaTags } from '@ggs/gatsby/lib/metadata'
import striptags from 'striptags'
import { getWindow } from '@ggs/utils'
import { Helmet } from 'react-helmet-async'

/**
 * @typedef {import('@ggs/types').MetaTagsItem} MetaTagsItem
 * @typedef {import('@ggs/types').MetaCanonicalItem} MetaCanonicalItem
 */

/**
 * @typedef {Object} SiteMetadataGraphql
 * @property {String=} title
 * @property {String=} author
 * @property {String=} description
 */

/**
 * @typedef {Object} SiteGraphql
 * @property {SiteMetadataGraphql=} siteMetadata
 */

/**
 * @typedef {Object} SeoProps
 * @property {String=} title Page title
 * @property {String=} description Page description
 * @property {SiteGraphql=} site Site meta data
 * @property {String=} lang Preferred language
 * @property {Array<MetaTagsItem>=} metaTags Page meta tags
 * @property {String=} metaSchema Page meta schema type
 * @property {Array<MetaCanonicalItem>=} metaCanonical Page meta canonical / alternate page variations metadata
 */

/**
 *
 * @param {SeoProps} props
 * @return {JSX.Element}

 */
export default function SEO({
  site,
  lang = 'en-CA',
  metaTags,
  metaSchema = '',
  metaCanonical = [],
  title = '',
  description = '',
  productSEO,
}) {
  const origin = getWindow('location.origin') || ''
  const metaDescription = description || site?.siteMetadata?.description
  const defaultTitle = 'Trudell Medical International'
  const cleanTitle = title && title !== '' ? striptags(title) : defaultTitle
  const normMetaTags = Array.isArray(metaTags) ? filter(metaTags.map(normalizeMetaTags)) : []
  const defaultMetaTags = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: cleanTitle,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: site?.siteMetadata?.author || '',
    },
    {
      name: 'twitter:title',
      content: cleanTitle,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ]
  const finalMetaTags = extend(defaultMetaTags, normMetaTags)

  useEffect(() => {
    const sendPageView = () => {
      if (window.ga) {
        window.ga(
          'set',
          'page',
          location ? location.pathname + location.search + location.hash : undefined
        )
        window.ga('send', 'pageview')
      }
    }

    if ('requestAnimationFrame' in window) {
      requestAnimationFrame(() => {
        requestAnimationFrame(sendPageView)
      })
    } else {
      // simulate 2 rAF calls
      setTimeout(sendPageView, 32)
    }
  }, [title])

  return (
    <Helmet
      prioritizeSeoTags={true}
      htmlAttributes={{
        lang,
      }}
      title={cleanTitle}
      titleTemplate={
        cleanTitle && cleanTitle !== defaultTitle ? `%s | ${defaultTitle}` : defaultTitle
      }
      meta={productSEO ? { ...finalMetaTags, productSEO } : finalMetaTags}
      defer={false}
    >
      {/*Todo: Need to inject current URL to ld data*/}
      {productSEO}
      {metaSchema && <script type="application/ld+json">{metaSchema}</script>}
      {metaCanonical?.length > 0 &&
        metaCanonical.map((item) => (
          <link key={item.url} rel={item.rel} href={`${origin}${item.url}`} hrefLang={item.lang} />
        ))}
    </Helmet>
  )
}

import { useEffect, useState, useRef } from 'react'
import { defaults, has, range } from 'lodash'
import { useSelector } from '@ggs/store'

/**
 * @typedef {import('@commercelayer/sdk').Sku} Sku
 */

/**
 * @typedef {React.ChangeEvent<HTMLInputElement>} QuantitySelectEvent
 * @typedef {React.ChangeEventHandler<HTMLInputElement>} QuantitySelectHandler
 */

/**
 * @typedef {Object} QuantitySelectHook
 * @property {number} currentQuantity SKU quantity selection
 * @property {Array<number>} quantityOptions Quantity options available for this product
 * @property {boolean} emptyQuantityOptions Whether quantity options are available for this product
 * @property {function} clearQuantity Function that clears quantity state
 * @property {QuantitySelectHandler} handleQuantitySelection Function that clears quantity state
 */

/**
 * @typedef {Object} QuantitySelectHookState
 * @property {number|null} quantity SKU quantity selection
 * @property {Array<number>} quantityOptions Quantity options available for this product
 */

/**
 * Handle quantity select state for a given SKU.
 * @param {Sku} sku SKU entity that needs quantity selection control
 * @param {number|null} existingQuantity Quantity that currently exists of the related SKU in draft order
 * @param {number|null} temporalQuantity Quantity that currently exists of the related SKU in temporal cart
 * @return {QuantitySelectHook}
 */
export default function useQuantitySelect(sku, existingQuantity = null, temporalQuantity = null) {
  const skus = useSelector((state) => state.commerce.skus)

  /**
   * @type {React.MutableRefObject<Object>}
   */
  const prevSkusState = useRef()

  /**
   * @type {React.MutableRefObject<String>}
   */
  const prevCurrentSkuState = useRef('')

  /**
   * @type {React.MutableRefObject<number>}
   */
  const prevExistingQuantityState = useRef(existingQuantity)

  /**
   * @type {QuantitySelectHookState}
   */
  const defaultState = {
    quantity: null,
    quantityOptions: [],
  }

  const [localState, setLocalState] = useState(defaultState)

  /**
   * Set local state quantity to a given value.
   * @param {number|null} quantity Quantity to set
   */
  function setLocalStateQuantity(quantity) {
    setLocalState(
      defaults(
        {
          quantity,
        },
        localState
      )
    )
  }

  /**
   * Set selected quantity into local state.
   * @type {QuantitySelectHandler}
   * @param {QuantitySelectEvent} event Quantity selector change event
   */
  function handleQuantitySelection(event) {
    const { target } = event
    setLocalStateQuantity(Number(target?.value) || localState.quantity)
  }

  /**
   * Compose quantity options for current SKU.
   * @return {Array<number>}
   */
  function composeQuantityOptions() {
    // Quantity that is currently added to the cart should be considered in the max limit.
    if (sku) {
      const minPurchaseLimit = Number(sku.metadata?.min_purchase_limit) || 1
      const maxPurchaseLimit = sku?.metadata?.max_purchase_limit
        ? Number(sku.metadata?.max_purchase_limit) - existingQuantity
        : 20
      const isMaxExceed = minPurchaseLimit > maxPurchaseLimit
      // console.log('Metadata:', sku.metadata)
      // console.log('Min:', minPurchaseLimit, 'Max:', maxPurchaseLimit, 'Existing:', existingQuantity)

      // Prevent negative quantity options.
      if (minPurchaseLimit <= 0 || maxPurchaseLimit <= 0) {
        return []
      }

      return range(isMaxExceed ? 1 : minPurchaseLimit, maxPurchaseLimit + 1)
    }

    return []
  }

  /**
   * Clear local state quantity.
   */
  function clearQuantity() {
    setLocalStateQuantity(null)
  }

  /**
   * Determine default quantity selection.
   */
  useEffect(() => {
    const quantityOptions = composeQuantityOptions()
    // console.log('CurrentOptionSku:', sku)
    // console.log('QuantityOptions: ', quantityOptions)
    const firstQuantityOption = Array.isArray(quantityOptions) ? quantityOptions[0] : null
    const quantity = quantityOptions.includes(localState.quantity)
      ? localState.quantity
      : firstQuantityOption
    const defaultQuantity = temporalQuantity || quantity
    const skusLoaded = prevSkusState.current && prevSkusState.current !== skus
    const currentSkuChanged = prevCurrentSkuState.current !== sku?.code
    const quantityChanged = localState.quantity != quantity
    const existingQuantityChanged = prevExistingQuantityState.current !== existingQuantity

    if (quantityChanged || skusLoaded || currentSkuChanged || existingQuantityChanged) {
      setLocalState(
        defaults(
          {
            quantity: defaultQuantity,
            quantityOptions,
          },
          localState
        )
      )
    }

    prevSkusState.current = skus
    prevCurrentSkuState.current = sku?.code
    prevExistingQuantityState.current = existingQuantity
  }, [localState.quantity, existingQuantity, sku, skus])

  const data = {
    currentQuantity: localState.quantity,
    quantityOptions: localState.quantityOptions,
    emptyQuantityOptions: sku && localState.quantityOptions?.length <= 0,
    clearQuantity,
    handleQuantitySelection,
  }

  // console.log('useQuantitySelect', data)

  return data
}

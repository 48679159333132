import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { Button } from '@ggs/gatsby/components/nav'
import { colors, typography } from '@ggs/styles'
// State
import { useDispatch, useSelector } from '@ggs/store'
// Utils
import { useI18n } from '@ggs/gatsby/lib'
import { addCouponCode, ORDER_COUPON_ERROR_CODES, orderActionType } from '@ggs/commercelayer/index'
import { whenPressingEnter } from '@ggs/utils'
import { trim } from 'lodash'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} CartCouponProp
 * @property {Order} order
 */

/**
 * Shopping cart promotion coupon.
 * @param {CartCouponProp} props
 * @return {JSX.Element}
 */
export default function CartCoupon(props) {
  //const { clClient } = useCheckoutContext()
  const { order } = props
  const { t } = useI18n()
  const [code, setCode] = useState('')
  const [lineNotice, setLineNotice] = useState({ label: '', type: 'help' })
  const commerce = useSelector((state) => state.commerce)
  const dispatch = useDispatch()
  const { clClient } = useCheckoutContext()

  const errorNotices = {
    [ORDER_COUPON_ERROR_CODES.failed_to_apply]: t('ecomm:coupon.error.failedToApply'),
    [ORDER_COUPON_ERROR_CODES.invalid_combo]: t('ecomm:coupon.error.invalidProducts'),
    [ORDER_COUPON_ERROR_CODES.noent_coupon]: t('ecomm:coupon.error.doesNotExist'),
    [ORDER_COUPON_ERROR_CODES.too_short]: t('ecomm:coupon.error.codeTooShort'),
    [ORDER_COUPON_ERROR_CODES.expired_coupon]: t('ecomm:coupon.error.codeHasExpired'),
  }

  /**
     * Set coupon code from input change.
     * @param {React.ChangeEvent<HTMLInputElement>} e Change event
     */
  const handleSetCode = (e) => {
    const { target } = e
    setCode(target.value)
  }

  /**
     * Apply coupon.
     * @param {React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLAnchorElement>} e Click event
     */
  const handleApplyCoupon = async (e) => {
    // clClientFactory.setToken(commerce.token)
    // const clClient = clClientFactory.getClient()

    // Order don't exits, cannot continue.
    if (!order) {
      // console.log('Order is empty, cannot apply a coupon')
      return
    }

    // Skip action when code is not filled
    if (!code) {
      return
    }

    /**
         * On success action callback.
         * @param {any} action
         */
    const onSuccess = (action) => {
      const {
        type,
        payload: { order: updatedOrder },
      } = action

      console.log('Coupon applied successfully?', {
        action,
        updatedOrder,
      })

      if (type === orderActionType.ADD_ORDER_COUPON) {
        // dispatch(
        //   addNotification(
        //     orderActionType.ADD_ORDER_COUPON,
        //     'success',
        //     t('ecomm:cart.notification.couponApplied')
        //   )
        // )

        // If we have a discount, it was a proper code.
        if (updatedOrder.discount_amount_cents < 0) {
          setLineNotice({ label: t('ecomm:coupon.label.successNotice'), type: 'success' })
          setCode('')
        }
        // else it was a valid code, but not for the current cart items, throw error specific to this.
        else {
          setLineNotice({
            label: errorNotices[ORDER_COUPON_ERROR_CODES.invalid_combo],
            type: 'error',
          })
        }
      }
      // If the action failed, we have a server error/malformed order set for some reason, throw general error.
      else {
        setLineNotice({
          label: errorNotices[ORDER_COUPON_ERROR_CODES.failed_to_apply],
          type: 'error',
        })
      }
    }

    /**
         * Handle failures
         * @param {Error} error
         */
    const onError = (error) => {
      setLineNotice({
        label:
        // @ts-ignore
                    errorNotices[error?.errors?.[0]?.meta?.error]
                    || errorNotices[ORDER_COUPON_ERROR_CODES.noent_coupon],
        type: 'error',
      })
      console.log('addCouponCode error', {
        error,
      })
    }

    dispatch(
      // @ts-ignore
      addCouponCode(clClient, order, trim(code), { onSuccess, onError })
    )
  }

  if (!order || !Array.isArray(order.line_items)) {
    return null
  }

  console.log('CartCoupon Render', {
    lineNotice,
  })

  // @ts-ignore
  return (
    <Box className="cart-coupon">
      <Grid container spacing={2} alignItems={'baseline'}>
        <Grid
          item
          sm={12}
          sx={{
            '& .cart-coupon-label': {
              typography: typography.menuItemRegular,
            },
          }}
        >
          <label className={'cart-coupon-label'} htmlFor={'coupon-code'}>
            {t('ecomm:cart.label.promoCode')}
          </label>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <TextField
                id="coupon-code"
                label={t('ecomm:cart.placeholder.enterPromoCode')}
                variant="outlined"
                value={code}
                onChange={handleSetCode}
                onKeyPress={(e) =>
                  whenPressingEnter(
                    e,
                    (/** @type {React.KeyboardEvent<HTMLAnchorElement>} */ ee) => {
                      // @ts-ignore
                      e.target.blur()
                      handleApplyCoupon(ee)
                    }
                  )
                }
              />
              {lineNotice.label !== '' && (
              // @ts-ignore
                <Typography
                  paragraph
                  variant="caption"
                  className={'cart-coupon-notice'}
                  sx={{
                    m: 1,
                    fontWeight: 'bold',
                    color: lineNotice.type === 'error' ? colors.red : colors.blue,
                  }}
                >
                  {lineNotice?.label}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sm={4}
              sx={{
                '& .cart-coupon__add span': {
                  color: colors.red,
                },
              }}
            >
              <Button
                className="cart-coupon__add"
                label={t('ecomm:cart.button.add')}
                variant={'outlined'}
                onClick={handleApplyCoupon}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

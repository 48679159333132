import Box from '@mui/material/Box'
import React from 'react'
import { Divider, Text } from '@ggs/components/paragraphs'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 */
/**
 * @typedef {Object} ProductOptionDetails
 * @property {ProductOption} currentOption
 */
/**
 * @param {ProductOptionDetails} props
 * @return {JSX.Element}
 */
export default function ProductOptionDetails({ currentOption }) {
  const { productDescription = '' } = currentOption || {}
  return productDescription ? (
    <Box
      className="product-option-details"
      sx={{
        mb: 4,
        '& .text': {
          mb: 3,
        },
        '& .divider': {
          mb: 0,
        },
      }}
    >
      <Text textContent={productDescription} />
      <Divider />
    </Box>
  ) : null
}

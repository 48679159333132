import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import {
  gtmCheckoutValue,
  gtmCoupon,
  gtmCurrency,
  gtmItems
} from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/gtagFromOrderParse'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'

export const fireAddPaymentInfo = async ({ order }) => {
  // const order = await getOrderFromRef()
  const paymentMethod = order.payment_method

  return pushDataLayer({
    eventName: EaActionType.addPaymentInfo,
    dataLayer: {
      coupon: gtmCoupon(order),
      currency: gtmCurrency(order),
      items: gtmItems(order),
      value: gtmCheckoutValue(order),
      payment_type: paymentMethod?.name,
    },
  })
}
// @ts-nocheck
import React from 'react'
import { GridContainer, Layout, PageHeader } from '@ggs/components/layout'
import Box from '@mui/material/Box'
import { breakpoints, colors, rem, zIndex } from '@ggs/styles'
import { Component } from '@ggs/gatsby/components'
import { Carousel } from '@ggs/components/banners'
import { uniqueId } from 'lodash'
import { EventCard } from '@ggs/components/cards'

/**
 * @typedef {import('@ggs/types').ArticleCard} ArticleCard
 * @typedef {import('@ggs/types').EventCard} EventCard
 */

/**
 *
 * @param {Object=} site
 * @param {String=} accentVariant
 * @param {Object=} pageContext
 * @param {Object=} props
 * @return {JSX.Element}
 * @constructor
 */
export default function EventsPage({
  queries: { site = '' } = {},
  backgroundVariant,
  pageContext,
  ...props
}) {
  const { title, subtitle, footerContent, /** @type {{article: Array<ArticleCard>, event: Array<EventCard>}} */ items }
    = pageContext.data

  const carouselItems = [items?.event?.[0], items?.event?.[1], items?.event?.[2]].map((item) => ({
    ...item,
    variant: 'carousel',
  }))

  const listItems = items?.event.map((item) => ({ ...item, variant: 'listing' }))

  const sxProps = {
    position: 'relative',
    zIndex: zIndex.zOverlay,
    '.event-card, .article-card': {
      width: 856,
      maxWidth: '100%',
      mx: 'auto',
      mb: {
        xs: 2,
        md: 3,
      },
      '.event-card__content-date, .event-card__content-title': {
        p: '0 important!',
      },
      '.event-card__card': {
        py: {
          md: '1rem !important',
        }
      },
      '&:last-of-type': {
        mb: 0,
      },
      '> div, > div > div': {
        m: 0,
        p: 0,
      },
      '.event-card__content > div': {
        m: 0,
      },
    },
  }

  console.log('Events page render', pageContext, {
    title,
    footerContent,
    items: {
      carouselItems,
      listItems,
    },
  })

  /*let pastEvents = <Box
   sx={{ ...sxProps, py: 0 }}
   >
   <Grid divider={true}
   sx={{
   m: '0 auto', mt: 0, textAlign: 'center', maxWidth:
   `${rem(856)} !important`,
   }} item xs={12} md={6} lg={8}
   order={1}>
   <Title title={t('global:label.pastEvents')} sx={{
   mx: '0 auto', py: 5,
   borderTop: common.borderLightGrey,
   }} align="center"/>
   </Grid>
   {listItems.map((item) => (
   <Component
   key={uniqueId(`list-item_${item.id}`)}
   {...item}
   type="EventCard"
   />
   ))}
   </Box>*/

  return (
    <Layout
      className="landing-page"
      site={site}
      pageContext={pageContext}
      backgroundVariant={backgroundVariant}
    >
      <PageHeader title={title} subtitle={subtitle} backgroundVariant={backgroundVariant}/>
      <Carousel
        carouselItems={carouselItems}
        variant={Carousel.variant.landingPageHeroes}
        component={'EventCarouselItem'}
      />
      {listItems.length > 0 && (
        <GridContainer>
          {/*Upcoming events*/}
          {/*<Grid sx={{ m: '0 auto', textAlign: 'center', py: 0 }} item xs={12} md={6} lg={8} order={1}>*/}
          {/*  <Title title={t('global:label.upcomingEvents')} sx={{ py: 5 }} align='center'/>*/}
          {/*</Grid>*/}
          <Box
            sx={{ ...sxProps }}
          >
            {listItems
              .sort((a, b) => b.eventDate.start - a.eventDate.start)
              .map((item) => (
                <EventCard
                  key={uniqueId(`list-item_${uniqueId(item.id)}`)}
                  {...item}
                />
              ))}
          </Box>
          {/*{pastEvents}*/}
        </GridContainer>
      )}
      <GridContainer
        backgroundColor={colors.pageBackgroundBlue}
        fullWidthBackground
        divider={true}
        sx={{
          '& .grid-container': {
            maxWidth: rem(breakpoints.xl),
            py: {
              xs: 6,
              md: 0,
            },
            px: {
              md: 6,
            },
          },
        }}
      >
        {footerContent?.[0] && <Component className="landing-page__cta" {...footerContent?.[0]} />}
      </GridContainer>
    </Layout>
  )
}

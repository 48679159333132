// @ts-nocheck
/* eslint-disable react/jsx-no-duplicate-props */
import React, { forwardRef } from 'react'
import NumberFormat from 'react-number-format'
// Components
import TextField from './TextField'
// Data
// Assets

function CurrencyMask(props) {
  // eslint-disable-next-line react/prop-types
  const { ref, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      thousandSeparator
      onValueChange={(values) => {
        onChange({
          target: {
            // eslint-disable-next-line react/prop-types
            name: props.name,
            value: values.value,
          },
        })
      }}
      prefix="$"
      decimalScale={2}
    />
  )
}

// eslint-disable-next-line react/display-name
const CurrencyInput = forwardRef(({ label, inputProps, disabled, ...fieldProps }, ref) => {
  return (
    <TextField
      {...fieldProps}
      ref={ref}
      label={label}
      inputProps={inputProps}
      disabled={disabled}
      InputProps={{
        inputComponent: CurrencyMask,
      }}
    />
  )
})

// Exports
export default CurrencyInput

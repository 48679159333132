// Measure product detail views
// After clicking a product listing, a user might view the product details page.
// To measure product detail views, send a view_item event with the product details:
import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'

export const fireViewProduct = ({ items, currency, value }) => {
  return pushDataLayer({
    eventName: EaActionType.viewItem,
    dataLayer: {
      currency,
      // currency,
      // value,
      items,
      value,
      // items
    },
  })
}

import React, { useMemo } from 'react'
import { navigate } from 'gatsby'
import MuiButton from '@mui/material/Button'
import { ButtonVariant } from '@ggs/types'
import { whenPressingEnter } from '@ggs/utils'
import { useLink } from '@ggs/gatsby/lib' // @ts-ignore
import { icons } from '@ggs/components' // Assets
import './Button.scss'
import { noop } from 'lodash'
import { Tooltips } from '@ggs/components/common'
import { ImpressionClickObserver } from '@ggs/components/ecommerceAnalytics'

/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * @typedef {Object} NavButtonProps
 * @property {string=} id
 * @property {String=} className
 * @property {String=} label
 * @property {JSX.Element|String=} children
 * @property {function=} onClick
 * @property {LinkField=} link
 * @property {React.ReactNode=} endIcon
 * @property {boolean=} ignoreOffsite
 * @property {ButtonVariant=} variant
 * @property {Object=} tooltip
 * @property {SxProps=} sx
 * @property {boolean=} disabled
 */

/**
 * Button component that trigger a callback when clicked.
 * @param {NavButtonProps} props
 * @return {JSX.Element}
 */
export default function Button({
  className,
  label,
  children,
  link,
  variant = ButtonVariant.contained,
  endIcon,
  ignoreOffsite = false,
  tooltip = null,
  onClick = noop,
  disabled = false,
  fireOnClickForLink = false,
  ...navButtonProps
}) {
  const safeUrl = useLink(true)
  const {
    // isInternal,
    isExternal,
    uri,
    label: linkLabel,
    isAssetLink,
  } = useMemo(() => safeUrl({ link }), [link])

  // If there was a valid URI, enforce this as the onClick action, else default to handler provided.

  const action = useMemo(
    () =>
      uri
        ? (/** @type {Event} */ e) => {
          if (fireOnClickForLink) onClick()
          if ((isExternal || isAssetLink) && uri) {
            window.open(uri, '_blank')
          } else {
            navigate(uri)
          }

          e && e.preventDefault()

          return false
        }
        : onClick,
    [uri, onClick]
  )

  // console.log('Button render', linkLabel, uri)

  let buttonLabel = linkLabel || label ? <span>{linkLabel || label}</span> : children

  if (tooltip) {
    buttonLabel = <Tooltips.LightTooltip {...tooltip}>{buttonLabel}</Tooltips.LightTooltip>
  }

  return (
    <ImpressionClickObserver>
      {/* @ts-ignore */}
      <MuiButton
        {...navButtonProps}
        className={`button ${className}`}
        onClick={action}
        onKeyDown={(e) => whenPressingEnter(e, action)}
        // @ts-ignore
        variant={[ButtonVariant.icon, ButtonVariant.tooltip].includes(variant) ? 'text' : variant}
        component={uri ? 'a' : undefined}
        href={uri || undefined}
        endIcon={uri.indexOf('http') !== -1 && !ignoreOffsite ? icons.OffsiteLink : endIcon}
        disabled={disabled}
      >
        {buttonLabel}
      </MuiButton>
    </ImpressionClickObserver>
  )
}

Button.variant = ButtonVariant

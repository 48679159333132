import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { colors } from '@ggs/styles'
// Utils
import { useI18n } from '@ggs/gatsby/lib'
import { CartTotals, MiniCartItemsList } from '@ggs/components/ecomm'
import { Divider } from '@ggs/components/paragraphs'

/**
 * @typedef {('cart'|'checkout')} OrderSummaryVariant
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} OrderSummaryProps
 * @property {OrderSummaryVariant} variant
 * @property {Order} order
 */

/**
 * Checkout order summary.
 * @param {OrderSummaryProps} props
 * @return {JSX.Element}
 */
export default function OrderSummary({ order, variant }) {
  const { t } = useI18n()
  return (
    <Box
      className="order-summary"
      sx={{
        backgroundColor: colors.white,
        borderRadius: '4px',
        boxShadow: '0 0 16px rgba(0, 0, 0, .04)',
        mb: 1,
        padding: 4,
        '> .divider': {
          p: 0,
          mb: 3,
        },
      }}
    >
      <Typography
        variant={'h4'}
        sx={{
          mb: 3,
        }}
      >
        {t('ecomm:cart.label.orderSummary')}
      </Typography>
      <Divider />
      {variant === 'checkout' && <MiniCartItemsList order={order} />}
      <CartTotals order={order} variant={variant} />
    </Box>
  )
}

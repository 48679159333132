// @ts-nocheck
import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'
import { useStepFlow } from '@ggs/components/ecomm/Checkout/hooks/useStepFlow'
import { useStepperControls } from '@ggs/components/ecomm/Checkout/hooks/useStepperControls'
import { useStepProgressController } from '@ggs/components/ecomm/Checkout/hooks/useStepProgress'
import { useCommerceSessionContext } from '@ggs/components/ecomm/CommmerceSession/CommerceSessionContext'
import { clearPaypalMetadata, resetCheckoutState, setCurrentStep, useDispatch } from '@ggs/store'
import React, { useContext, useState } from 'react'
import { useSupportingLinks } from '@ggs/components/ecomm'
import { navigate } from 'gatsby'
import { useCheckoutStateInspector } from '@ggs/components/ecomm/Checkout/useCheckoutStateInspector'
import { useCurrentMetadata } from '@ggs/components/ecomm/ProductPurchaseOptions/useCurrentMetadata'

const { ORDER, PAYMENT, REVIEW, COMPLETE } = CHECKOUT_STEP_ENUMS

export const CheckoutContext = React.createContext({
  clClient: null,
  user: null,
  accessToken: {
    token: null,
    tokenRefresh: null,
    tokenExpiresAt: null,
  },
  market: null,
  stock: null,
  order: null,
})
/**
 * @typedef {Object} CheckoutContextProviderProps
 * @property {JSX.Element} children
 */
/**
 * @param {CheckoutContextProviderProps} props
 * @return {JSX.Element}
 */
export const CheckoutContextProvider = (
  {
    children,
  }) => {
  const dispatch = useDispatch()
  const { checkout: checkoutUrl } = useSupportingLinks()

  const metadata = useCurrentMetadata()
  const navigateToOrderReview = ({ id = null } = {}) => {
    if (id) {
      navigate(checkoutUrl + '/order-review?oid=' + id)
    } else {
      navigate(checkoutUrl + '/order-review')
    }
  }


  const {
    clClient,
    accessToken,
    // customer,
    checkout,
    // currentMarket,
    // currentStore,
    order,
  } = useCommerceSessionContext()

  const orderComplete = order?.status === 'placed'

  // from checkout redux state
  const { currentStepName, stepsComplete } = checkout

  const [PayerID, setPayerID] = useState('')
  const { stepFlow } = useStepFlow({
    complete: orderComplete,
    checkoutStep: currentStepName
  })

  // for storing the checkout process progress.
  const {
    markStepComplete,
    resetSteps,
    resetExceptShippingAddress,
    resetStepByName,
    isStepComplete,
    isNextCheckoutStep,
  } = useStepProgressController({
    stepFlow,
    stepsComplete,
  })

  const {
    currentStepperIndex,
    getStep,
    nextStep,
    handlers: {
      setStepper,
      jumpToStep,
      goToStep,
      // getCurrentStep,
      goToPayment,
      goToOrder,
      goToReview,
      // goToAuth,
      goToComplete,
      getStepperIndexFromCheckoutStepName,
    },
  } = useStepperControls({
    stepFlow,
  })

  // is the step in the stepper view current.
  const isCurrentStepComplete = () => {
    return isStepComplete.stepperIndex(currentStepperIndex)
  }

  const resetAll = () => {
    resetSteps()
    setStepper.stepperIndex(0)
  }

  const setNewOrder = () => {
    dispatch(resetCheckoutState())
    dispatch(clearPaypalMetadata())
    resetAll()
  }

  const resetCheckoutExceptShippingAddress = () => {
    resetExceptShippingAddress(order)
  }

  // useEffect(() => {
  //   window.orderInfo = { activeOrder, apiOrder, localStorageOrder,
  // fetchCurrentOrder } // activeOrder?.id ? fetchCurrentOrder() : null },
  // [activeOrder, apiOrder, localStorageOrder])

  // this tells the API to check our order ID when it changes.
  // useEffect(async () => {
  //   // setApiOrder((currentActiveOrder) => order ? activeOrder :
  //   // currentActiveOrder)
  //   const apiOrder = await api.order.apiOrder
  //   window.apiOrder = apiOrder
  //   setApiOrder(apiOrder)
  // }, [])

  const {
    addressControllers,
    lastOrder,
    checkCartHasItems,
    useRedirectFromCheckout
  } = useCheckoutStateInspector({
    handlers: {
      goToPayment,
      goToOrder,
    },
    resetCheckoutExceptShippingAddress,
    resetStepByName,
    isCurrentStepComplete,
    navigateToOrderReview
  })

  return (
    <CheckoutContext.Provider
      value={{
        // higher context / redux
        order,
        // customer,
        accessToken,
        clClient,
        currentStepName,
        stepsComplete,
        checkout,
        // api
        // apiOrder,
        // checkout context
        stepFlow,
        orderComplete,
        PayerID,
        setPayerID,
        setNewOrder, //: resetAll,
        currentStepperIndex,
        getStep,
        nextStep,
        handlers: {
          setStepper,
          jumpToStep,
          goToStep: (index) => {
            dispatch(setCurrentStep({
              currentStepName: stepFlow[index],
              currentStepperIndex: index,
            }))
            // goToStep(index)
          },
          goToInitialStep: () => {
            goToStep(0)
          },
          // goToNextStep: () => goToNextStep(onNext),
          // getCurrentStep,
          goToPayment: () => {
            goToPayment()
            // dispatch(setCurrentStepByName(PAYMENT))
          },
          goToOrder: () => {
            goToOrder()
            // dispatch(setCurrentStepByName(ORDER))
          },
          goToReview: () => {
            goToReview()
            // dispatch(setCurrentStepByName(REVIEW))
          },
          // goToAuth: () => {
          //   goToAuth()
          //   setCurrentStepByName(AUTH)
          // },
          goToComplete: () => {
            goToComplete()
            // dispatch(setCurrentStepByName(COMPLETE))
          },
        },
        getStepperIndexFromCheckoutStepName,
        resetSteps: resetAll,
        resetCheckoutExceptShippingAddress,
        resetStepByName,
        markStepComplete,
        isStepComplete,
        isNextCheckoutStep,
        // useWatchCartForChanges,
        // useWatchShippingForChanges,
        // useWatchPaymentForChanges,
        isCurrentStepComplete,
        // addressControllers,
        resetAll,
        navigateToOrderReview,
        // onShippingAddressDispatch,
        // onBillingAddressDispatch,
        // onBillingSameAsShipping,
        // billingAddress,
        // shippingAddress,
        // billingSameAsShipping,
        // setBillingSameAsShipping,
        // setShippingAddress,
        // setBillingAddress,
        // billingAddressController,
        // shippingAddressController,
        addressControllers,
        lastOrder,
        checkCartHasItems,
        useRedirectFromCheckout,
        metadata
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

// hook for using the checkout context state; makes sure context: order, token,
// scope, etc. is valid, then passes checkout context values (using
// useactivecheckout functional hook) to checkout context consumers.
export const useCheckoutContext = () => {
  const checkoutContext = useContext(CheckoutContext)
  if (checkoutContext === undefined) {
    throw new Error(
      'checkout context is undefined, trying to use checkout context when checkout context invalid.'
    )
  }


  // controller for action dispatch to update order and checkout state.
  // const checkoutController = useCheckoutController(checkoutContext)

  const {
    order,
    stepsComplete,
    // currentStepperIndex: currentStepperIndex,
    // isStepComplete,
    isNextCheckoutStep,
    resetSteps,
    resetAll,
    // redirectIfEmpty,
    isStepComplete,
    orderComplete,
    /** @type CLClient */
    clClient,
    // customer,
    accessToken,
    currentStepName,
    stepFlow,
    onNext,
    setNewOrder,
    // setNewOrder,
    // useWatchCartForChanges,
    // useWatchShippingForChanges,
    // useWatchPaymentForChanges,
    currentStepperIndex,
    // setNewOrder,
    // useWatchCartForChanges,
    // useWatchShippingForChanges,
    // useWatchPaymentForChanges,
    getStepperIndexFromCheckoutStepName,
    handlers, //: {
    // goToPayment,
    // }
    onShippingAddressDispatch,
    onBillingAddressDispatch,
    onAddressSubmit,
    onAddressSubmitIsoCodes,
    addressValuesToIso,
    markStepComplete,
    checkout,
    isCurrentStepComplete,
    resetCheckoutExceptShippingAddress,
    resetStepByName,
    goToOrderReview,
    addressControllers,
    navigateToOrderReview,
    useRedirectFromCheckout,
    metadata,
    checkCartHasItems
  } = checkoutContext


  const {
    controllerByKey,
    billingAddressController,
    shippingAddressController,
    onBillingSameAsShipping,
  } = addressControllers

  // handle paypal success
  // useEffect(() => {
  //   // check hash
  //   const pathHash = getWindow('location.hash') || ''
  //   // @ts-ignore
  //   const [hash, params] = pathHash.split('?')
  //   // When specific hash is in the path we determine webapp return from external
  //   // payment. console.log('PathHash:', pathHash)
  //   if (hash === '#extsuccess') {
  //     // Take user to review page.
  //     markStepComplete(PAYMENT)
  //     setTimeout(() => {
  //       handlers.goToReview()
  //     }, 350)
  //   }
  // }, [])

  return {
    order,
    // customer,
    accessToken,
    handlers,
    stepFlow,
    onNext,
    // resetCheckoutSteps,
    stepsComplete,
    resetAll,
    // redirectIfEmpty,
    isStepComplete,
    clClient,
    isNextCheckoutStep,
    // useWatchCartForChanges,
    // useWatchShippingForChanges,
    // useWatchPaymentForChanges,
    orderComplete,
    onShippingAddressDispatch,
    onBillingAddressDispatch,
    onBillingSameAsShipping,
    onAddressSubmit,
    onAddressSubmitIsoCodes,
    addressValuesToIso,
    billingAddressController,
    shippingAddressController,
    controllerByKey,
    markStepComplete,
    resetSteps,
    checkout,
    isCurrentStepComplete,
    setNewOrder,
    currentStepName,
    currentStepperIndex,
    resetCheckoutExceptShippingAddress,
    resetStepByName,
    goToOrderReview,
    shippingMethodId: checkout.checkoutState.shippingMethodId,
    navigateToOrderReview,
    useRedirectFromCheckout,
    PayerID: checkout.paypalMeta?.PayerID,
    metadata,
    checkCartHasItems,
    ...addressControllers,
  }
}


export default CheckoutContext

import React, { useEffect, useState } from 'react'
import { GridContainer } from '@ggs/components/layout'
import Grid from '@mui/material/Grid'
import { Component } from '@ggs/gatsby/components'
import { TextCard, Title } from '@ggs/components/paragraphs'

// Assets.
import './TextGrid.scss'

/**
 * @typedef {import("@ggs/components/paragraphs/TextCard/TextCard").TextCardProps} TextCardProps
 * @typedef {import("@ggs/components/paragraphs/Text/Text").TextProps} TextProps
 */
/**
 * @typedef {Object} TextGridProps
 * @property {String=} title
 * @property {String=} subtitle
 * @property {Array<TextCardProps>=} gridItems
 * @property {Array<TextProps>=} textItems
 */
/**
 * TextGrid component.
 * @param {TextGridProps} props
 * @return {JSX.Element}
 */
export default function TextGrid({ title = '', subtitle = '', gridItems = [], textItems = [] }) {
  const [gridColumnCount, setGridColumnCount] = useState(3)
  const textColumnCount = textItems.length >= 3 ? 4 : 6

  useEffect(() => {
    // Calculate column count for the Grid items.
    /** @type {Number} */
    switch (gridItems.length) {
      case 1:
        setGridColumnCount(12)
        break
      case 2:
        setGridColumnCount(6)
        break
      case 3:
        setGridColumnCount(4)
        break
    }
  }, [gridItems])

  return (
    <GridContainer sx={{ py: { xs: 5, md: 10 } }} className="text-grid">
      <>
        {(title || subtitle) && (
          <Title style="h3" title={title} subtitle={subtitle} useFlare={true} />
        )}
        {gridItems.length > 0 && (
          <Grid
            container
            className="text-grid__card-items"
            alignItems="stretch"
            justifyContent="space-between"
            spacing={{
              xs: 3,
              md: 5
            }}
          >
            {gridItems.map((item) => (
              <Grid
                item
                key={item.id}
                className="text-grid__card-item"
                xs={12}
                md={gridItems.length > 1 ? 6 : 12}
                // @ts-ignore
                lg={gridColumnCount}
              >
                <Component {...item} variant={TextCard.variant.centered} />
              </Grid>
            ))}
          </Grid>
        )}
        {textItems.length > 0 && (
          <Grid
            sx={{ mt: { xs: 3, md: 5 } }}
            container
            className="text-grid__text-items"
            alignItems="flex-start"
            justifyContent="center"
            spacing={{
              xs: 3,
              md: 5
            }}
          >
            {textItems.map((item, index) => (
              <Grid key={`text-item-${index}`} className="text-grid__text-item" item xs={12} md={12} lg={textColumnCount}>
                {/* @ts-ignore */}
                <Component {...item} />
              </Grid>
            ))}
          </Grid>
        )}
      </>
    </GridContainer>
  )
}

import React, { useEffect, useState } from 'react'
import { ImageStyles, RawImage } from '@ggs/gatsby/components/media'
import Grid from '@mui/material/Grid'
import { Link } from '@ggs/gatsby/components/nav'
import { useWindowSize } from '@ggs/hooks'

/**
 * @typedef {Object} MegaMenuItemProps
 * @property {String=} label
 * @property {String=} url
 * @property {ImageStyles=} icon
 * @property {Number=} parentWrapperColumnCount
 */
/**
 * @param {MegaMenuItemProps} props
 * @return {JSX.Element}
 */
export default function MegaMenuItem({ label, url, icon, parentWrapperColumnCount }) {
  const windowSize = useWindowSize()
  const [columnCount, setColumnCount] = useState(3)

  useEffect(() => {
    // Calculate grid column count for the Mega Menu Item.
    /** @type {Number} */
    if (windowSize.lg === true && windowSize.smallDesktopOnly === false) {
      switch (parentWrapperColumnCount) {
        case 12:
          setColumnCount(3)
          break
        case 9:
          setColumnCount(4)
          break
        case 6:
          setColumnCount(6)
          break
        case 3:
          setColumnCount(12)
          break
      }
    } else if (windowSize.lg === true && windowSize.smallDesktopOnly === true) {
      switch (parentWrapperColumnCount) {
        case 12:
          setColumnCount(4)
          break
        case 8:
          setColumnCount(6)
          break
        case 4:
          setColumnCount(12)
          break
      }
    }

    // Clean-up function to reset column count to default on unmount.
    return () => setColumnCount(3)
  }, [windowSize.smallDesktopOnly, parentWrapperColumnCount])

  return (
    <Grid
      item
      xs={12}
      // @ts-ignore
      lg={columnCount}
    >
      <Link className="mega-menu-item__link" href={url}>
        <>
          {icon && (
            <span className="mega-menu-item__link-image">
              {/*<ImageStyles {...icon} selectedStyles={['menuFaqIcon']} loading={'eager'} />*/}
              <RawImage imageData={icon} imageStyle={'menuFaqIcon'} />
            </span>
          )}
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </>
      </Link>
    </Grid>
  )
}

// @ts-nocheck
import { CheckoutHeader, Header } from '@ggs/components/layout'
import { SkipLink } from '@ggs/components/nav'
import { SEO } from '@ggs/gatsby/components/layout'
import { useSentryState } from '@ggs/gatsby/sentry'
import { zIndex } from '@ggs/styles'
import colors from '@ggs/styles/theme/colors'
import Box from '@mui/material/Box'
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { Suspense, useMemo } from 'react'
import { PageBackgroundVariant } from '../flare/PageBackgroundAccent'
import { isEqual } from 'lodash'
import { setLayoutMeta, useSelector } from '@ggs/store'
import { useDispatch } from 'react-redux'
import { useActiveNotice } from '@ggs/components/layout/useActiveNotice'

const Sidebar = React.lazy(() => import('@ggs/components/common/Sidebar/Sidebar'))
const Snackbar = React.lazy(() => import('@ggs/components/common/Snackbar/Snackbar'))
const Footer = React.lazy(() => import('@ggs/components/layout/Footer/Footer'))
const CheckoutFooter = React.lazy(() => import('@ggs/components/layout/Footer/CheckoutFooter'))

/**
 * @typedef {import('@ggs/gatsby/components/layout/SEO').SiteGraphql} SiteGraphql
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * @typedef {Object} LayoutProps
 * @property {SiteGraphql} site
 * @property {Object=} pageContext
 * @property {String=} className
 * @property {JSX.Element} children
 * @property {SxProps} sx
 * @property {Boolean=} checkout
 */

/**
 * @enum {string} backgroundColor for PageBackground variants.
 */
const backgroundColorMap = {
  [PageBackgroundVariant.pink]: colors.pageBackgroundPink,
  [PageBackgroundVariant.blue]: colors.pageBackgroundBlue,
  [PageBackgroundVariant.white]: colors.white,
}

const ActiveHeader = ({ checkout, currentStore, layoutMeta }) => {
  const { checkoutNotice, checkoutNoticeStyle, sitewideAnnouncement, sitewideAnnouncementStyle }
    = useActiveNotice({ currentStore, layoutMeta })
  return checkout ? (
    <CheckoutHeader checkoutNotice={checkoutNotice} checkoutNoticeStyle={checkoutNoticeStyle} />
  ) : (
    <Header
      checkout={false}
      layoutMeta={layoutMeta}
      sitewideAnnouncement={sitewideAnnouncement}
      sitewideAnnouncementStyle={sitewideAnnouncementStyle}
      hcpChangeProfile={currentStore?.hcpChangeProfile}
    />
  )
}

/**
 *
 * @param {LayoutProps} props
 * @return {JSX.Element}
 */
export default function Layout({
  site,
  pageContext,
  className = '',
  children,
  sx,
  checkout = false,
  backgroundVariant = PageBackgroundVariant.blue,
  productSEO = null,
}) {
  // console.log('PageContext: ', pageContext)

  // Keep track of the current state for sentry
  useSentryState()

  const {
    data: {
      metaTags,
      metaSchema,
      title,
      layoutMeta: pageLayoutMeta = {},
      checkoutFooterNotice = '',
      checkoutFooterDetails = '',
    } = {},
  } = pageContext
  const dispatch = useDispatch()
  const stateLayoutMeta = useSelector((state) => state.ui?.layoutMeta)
  const { lang, menus: { supporting } = {}, canonicalUrls, currentStore } = pageLayoutMeta || {}

  const langCode = lang ?? 'en'
  // ? lang.split('-')[0] : 'en'

  useMemo(() => {
    const hasLayoutMetaChanged = () => {
      if (Object.keys(pageLayoutMeta).length && !isEqual(pageLayoutMeta, stateLayoutMeta)) {
        // console.log('hasLayoutMetaChanged: ', layoutMeta, stateLayoutMeta)
        dispatch(setLayoutMeta({ ...stateLayoutMeta, ...pageLayoutMeta }))
      }
    }
    hasLayoutMetaChanged()
  }, [pageLayoutMeta])

  const backgroundColor = useMemo(
    () =>
      backgroundVariant && backgroundVariant in backgroundColorMap
        ? backgroundColorMap[backgroundVariant]
        : null,
    [backgroundVariant]
  )
  // Select which header to use.
  const ActiveFooter = useMemo(
    () => (checkout ? CheckoutFooter : Footer),
    [checkout, pageLayoutMeta, lang]
  )

  // useEffect(() => {
  //   testAddressValidation()
  // }, [])

  return (
    <Box
      className={`layout layout-lang-${currentStore?.language?.id} ${className}`}
      sx={{
        minHeight: '100vh',
        position: 'relative',
        zIndex: zIndex.zBackground,
        backgroundColor,
        ...(sx || {}),
      }}
    >
      <SEO
        title={title}
        site={site}
        metaTags={metaTags}
        metaSchema={metaSchema}
        metaCanonical={canonicalUrls}
        lang={langCode}
      />
      {productSEO ?? null}
      <SkipLink targetId="main-content" />
      <Suspense fallback={null}>
        <ActiveHeader checkout={checkout} currentStore={currentStore} layoutMeta={pageLayoutMeta} />
        <div id="main-content">
          <Suspense fallback={null}>
            <Sidebar />
          </Suspense>
          <Suspense fallback={null}>
            <Snackbar />
          </Suspense>
          {children}
        </div>
        <Suspense fallback={null}>
          <ActiveFooter
            {...pageLayoutMeta}
            notice={checkoutFooterNotice}
            details={checkoutFooterDetails}
          />
        </Suspense>
      </Suspense>
    </Box>
  )
}

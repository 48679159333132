import React, { useMemo } from 'react'
import { CardGrid } from '@ggs/components/grids'
import { Video } from '@ggs/components/paragraphs'
//Assets.
import './VideoGrid.scss'

/**
 * @typedef {import("@ggs/components/paragraphs/Video/Video").VideoProps} VideoProps
 */
/**
 * @typedef {Object} VideoGridProps
 * @property {String=} title
 * @property {Array<VideoProps>=} videos
 */
/**
 * VideoGrid component.
 * @param {VideoGridProps} props
 * @return {JSX.Element}
 */
export default function VideoGrid({ title = '', videos = [] }) {
  useMemo(() => {
    videos.map((item) => {
      item.variant = Video.variant.openInModal
      return item
    })
  }, [videos])

  return (
    <div className="video-grid">
      <CardGrid
        title={title}
        items={videos}
        // @ts-ignore
        itemsPerRow={videos.length < 4 ? videos.length : 4}
        component={CardGrid.component.Video}
        headingStyle="h3"
      />
    </div>
  )
}

/* eslint-disable max-len */
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useI18n } from '@ggs/gatsby/lib'
// Assets
import './ProductPrice.scss'
import { colors, typography } from '@ggs/styles'
import { useSelector } from '@ggs/store'
import { numberFormatter } from '@ggs/utils'
import useProductPrice from '@ggs/components/ecomm/ProductPrice/useProductPrice'
import { useLayoutMeta } from '@ggs/hooks'
import { useOnAllowEcommListCheck } from '@ggs/components/ecomm/ProductPurchaseOptions/useCurrentMetadata'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 */

/**
 * @typedef {Object} ProductPriceProps
 * @property {String=} className
 * @property {ProductOption=} currentOption
 * @property {ProductOption} defaultOption
 * @property {Array<ProductOption>=} productOptions
 */

/**
 * @typedef {import('@commercelayer/sdk').Price} Price
 */

/**
 * @typedef {Object} ProductPriceState Component local state.
 * @property {Price|null} skuPrice Resolved SKU price (from business rules) for determining displayed price
 * @property {boolean} isStartingFrom Flag when resolved price is the lowest and not for a current option selection
 */

/**
 * Price resolution business rules:
 * 1) If there is only a defaultOption, we load this price and use it.
 * 2) If there are productOptions available, and currentOption is empty, then we find the lowest price,
 *    labeling 'Starting at ...' (this scenario is not possible due the fact that current option
 *    is needed for displaying the main product image).
 * 3) When we have a currentOption, we would render the correct price from the productOptions at that time.
 *
 * @param {ProductPriceProps} props
 * @return {JSX.Element}
 */
function ProductPrice({ className, currentOption, defaultOption, productOptions }) {
  const { t } = useI18n()
  const productPricing = useProductPrice({ currentOption, defaultOption, productOptions })
  const { lang } = useLayoutMeta()
  const currency_code = useSelector((state) => state.commerce?.order?.currency_code)
  const currencyFormatter = numberFormatter.currency(currency_code, lang)
  const onAllowList = useOnAllowEcommListCheck()

  if (!productPricing?.skuPrice || !onAllowList) {
    return null
  }

  return (
    <Box
      className={`product-price ${className}`}
      sx={{
        color: colors.black,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        '> div': {
          flex: '0 0 auto',
        },
        '& .product-price__price': {
          mr: 1,
        },
        '& .product-price__currency': {},
        mb: 3,
      }}
    >
      {productPricing.isStartingFrom && (
        <Typography
          className="product-price__label"
          sx={{
            typography: typography.captionBold,
          }}
        >
          {t('global:label.priceStartingFrom')}
        </Typography>
      )}
      <div className="product-price__price-wrapper">
        <div className="product-price__price">
          {currencyFormatter(productPricing.skuPrice.amount_float)}
        </div>
        <div className="product-price__currency">{productPricing.skuPrice.currency_code}</div>
      </div>
    </Box>
  )
}

export default ProductPrice

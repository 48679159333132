import { useI18n } from '@ggs/gatsby/lib'
import { customerActionType, orderActionType } from '@ggs/commercelayer/index'
import { formikFormErrorKey } from '@ggs/forms/Form/useForm'
import { shippingMethodRequiredKey } from '@ggs/components/ecomm/Customer/useAvailableShippingMethods'
import { customerNoticeCodes } from '@ggs/store'

const ERROR_CODES = {
  invalid_grant: 'invalid_grant',
  taken: 'taken',
  blank: 'blank',
  stripe_payment_source_fail: 'stripe_payment_source_fail',
  paypal_payment_source_fail: 'paypal_payment_source_fail',
}

/**
 * Handle alert machine code i18next translation mapping.
 * @return {function(String, String=)}
 */
export default function useCodeTranslationMap() {
  const { t } = useI18n()

  /**
   * Code to translation mapping.
   * @type {Object<String, String>}
   */
  const mapping = {
    max_tax_calculations_count_retry: t('ecomm:shipping.label.taxCalculationNullZero'),
    // Shipping fulfillment statuses
    unfulfilled: t('ecomm:shipping.label.unfulfilled'),
    in_progress: t('ecomm:shipping.label.inProgress'),
    fulfilled: t('ecomm:shipping.label.fulfilled'),
    // Shipping method labels
    ground: t('ecomm:shipping.label.ground'),
    express: t('ecomm:shipping.label.express'),
    express_9_am: t('ecomm:shipping.label.express9am'),
    express_10_30_am: t('ecomm:shipping.label.express1030am'),
    express_international: t('ecomm:shipping.label.expressInternational'),
    priority: t('ecomm:shipping.label.priority'),
    standard: t('ecomm:shipping.label.standard'),
    method10: t('ecomm:shipping.label.method10'),
    method11: t('ecomm:shipping.label.method11'),
    method12: t('ecomm:shipping.label.method12'),
    method13: t('ecomm:shipping.label.method13'),
    method14: t('ecomm:shipping.label.method14'),
    method15: t('ecomm:shipping.label.method15'),
    // Coupon labels
    noent_coupon: t('ecomm:cart.notice.couponNotExists'),
    too_short: t('ecomm:cart.notice.couponCodeInvalid'),
    // Order labels
    [orderActionType.REMOVE_ORDER_ITEMS]: t('ecomm:cart.notice.itemRemoved'),
    [orderActionType.ADD_ORDER_ITEMS]: t('ecomm:cart.notice.itemAdded'),
    [orderActionType.UPDATE_ORDER_ITEMS]: t('ecomm:cart.notice.itemUpdated'),
    [orderActionType.REMOVE_ORDER_COUPON]: t('ecomm:cart.notice.couponRemoved'),
    [orderActionType.ADD_ORDER_COUPON]: t('ecomm:cart.notice.couponAdded'),
    [customerActionType.LOGIN_EXISTING_CUSTOMER]: t('account:notice.invalidAccountCredentials'),
    // Account labels
    [ERROR_CODES.invalid_grant]: t('account:notice.invalidAccountCredentials'),
    [ERROR_CODES.taken]: t('account:notice.accountAlreadyExists'),
    [ERROR_CODES.blank]: t('global:form.error.invalidSubmission'),
    [ERROR_CODES.stripe_payment_source_fail]: t('ecomm:notice.paymentSourceFail'),
    [ERROR_CODES.paypal_payment_source_fail]: t('ecomm:notice.paymentSourceFail'),
    [formikFormErrorKey]: t('global:form.error.invalidSubmission'),
    [customerActionType.CREATE_CUSTOMER]: t('account:notification.signupSuccess'),
  }

  /**
   * Map alert code to localization message.
   * @param {String} code Message machine code
   * @param {String} defaultMessage Default message returned if code mapping not found
   * @return {String}
   */
  function mapCodeToTranslation(code, defaultMessage = '') {
    // Use message if matching codes, no codes.
    if (
      code.includes(formikFormErrorKey)
      || [shippingMethodRequiredKey, customerNoticeCodes.SIGN_OUT].includes(code)
    ) {
      return defaultMessage
    }
    const result = mapping[code] || `${code} - ${defaultMessage}`
    console.log(`[useCodeTranslationMap] code: ${code}`, result)
    return result
  }

  return mapCodeToTranslation
}

import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
// Assets
import './SharePage.scss'
import icons from '@ggs/components/icons'
import { getWindow } from '@ggs/utils'

/**
 * @typedef {Object} SharePageProps
 * @property {String=} url
 */
/**
 *
 * @param {SharePageProps} props
 * @return {JSX.Element}
 */
export default function SharePage({ url = '' }) {
  const { t } = useI18n()
  const origin = getWindow('location.origin') || ''
  const shareUrl = url.indexOf(origin.toString()) === -1 ? `${origin}${url}`:url
  // eslint-disable-next-line prefer-rest-params
  // console.log('SharePage render', arguments[0]) //, JSON.stringify(arguments[0]))
  return (
    <div className="share-page">
      <span>{t('global:label.share')}</span>
      <LinkedinShareButton url={shareUrl}>{icons.Linkedin}</LinkedinShareButton>
      <TwitterShareButton url={shareUrl}>{icons.Twitter}</TwitterShareButton>
      <FacebookShareButton url={shareUrl}>{icons.Facebook}</FacebookShareButton>
    </div>
  )
}

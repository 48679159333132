import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { Divider, TextCard, Title } from '@ggs/components/paragraphs'
import { GridContainer } from '@ggs/components/layout'
import { Button } from '@ggs/gatsby/components/nav'
import { useSelector } from '@ggs/store'
import { FormVariant } from '@ggs/types'
// Assets.
import './Form.scss'
import { useFindModalForCustomerType } from '@ggs/components/hooks'

/**
 * @typedef {import('@ggs/types').FormParagraph} FormParagraph
 */
/**
 * @param {FormParagraph} props
 * @return {JSX.Element}
 */
export default function Form({ title = '', customerForms, variant = FormVariant.default }) {
  const [activeItem, setActiveItem] = useState(variant === FormVariant.default ? null : 0)
  const [forms, setForms] = useState(customerForms || [])
  const findModalForCustomerType = useFindModalForCustomerType()
  // Build styles based on variant.
  const sx = useMemo(() => {
    const styles = {}

    switch (variant) {
      case FormVariant.default:
        Object.assign(styles, {
          p: 0,
        })
        break
      case FormVariant.selectGroup:
        Object.assign(styles, {
          pt: {
            xs: 5,
            md: 8,
          },
          pb: {
            xs: 0,
            md: 0,
          },
        })
        break
    }

    return styles
  }, [variant])

  useEffect(() => setForms(customerForms || []), [customerForms])

  const customer = useSelector((state) => state.commerce.customer)
  // For testing purposes only!
  // const customer = { customerType: 260 } // Patient on dev
  useEffect(() => {
    if (variant === FormVariant.default) {
      const activeIndex = findModalForCustomerType(customerForms)
      if (activeIndex !== null) {
        setActiveItem(activeIndex)
      }
    }
  }, [customerForms])

  return (
    <GridContainer className={`form form--${variant}`} sx={sx}>
      <>
        {title && <Title className="form__title" title={title} style="h3" />}
        {variant === FormVariant.selectGroup && (
          <>
            <Box
              className="form__select-form-container"
              sx={{
                display: 'grid',
                gridAutoRows: '1fr',
                gridTemplateColumns: {
                  xs: 'repeat(2, 1fr)',
                  md: `repeat(${forms.length}, 1fr)`,
                },
                gap: 3,
                mb: 5,
              }}
            >
              {forms.map((customerFormItem, index) => (
                <Button
                  className="form__filter-button"
                  key={customerFormItem.id}
                  onClick={() => setActiveItem(index)}
                  variant={Button.variant.text}
                >
                  <TextCard
                    label={customerFormItem.customerGroup.name}
                    variant={TextCard.variant.selectGroup}
                    active={index === activeItem || false}
                  />
                </Button>
              ))}
            </Box>
            <Divider sx={{ mb: 5 }} />
          </>
        )}
        {activeItem !== null && (
          <div
            className="form__embed-form"
            dangerouslySetInnerHTML={{ __html: forms[activeItem].formEmbed }}
          />
        )}
        {variant === FormVariant.selectGroup && <Divider sx={{ mt: 5 }} />}
      </>
    </GridContainer>
  )
}

Form.variant = FormVariant
import React, { useMemo } from 'react'
import { colors, typography, zIndex } from '@ggs/styles'
import { PageBackgroundAccent } from '@ggs/components/flare'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * Image Cta Variant
 * @enum {string}
 */
export const ImageCtaVariant = {
  default: 'default',
  blue: 'blue',
  pink: 'pink',
}

/**
 * Image CTA Image Position
 * @enum {string}
 */
export const ImageCtaImagePosition = {
  right: 'right',
  left: 'left',
  full: 'full',
}

/**
 * @param {ImageCtaVariant} variant
 * @param {ImageCtaImagePosition} alignImage
 * @return {{sx: SxProps, backgroundColor: string, accent: JSX.Element}}
 */
export default function useImageCtaSx(variant, alignImage) {
  return useMemo(() => {
    let backgroundColor = ''
    let accentColor = ''

    const sx = {
      zIndex: zIndex.zContent,
      overflow: 'hidden',
      '.image-cta__container': {
        zIndex: zIndex.zOverlay,
      },
      '& p': {
        m: 0,
      },
      '.page-background-accent': {
        top: '-125%',
        m: 0,
      },
    }

    // On coloured backgrounds, add padding.
    if (variant !== ImageCtaVariant.default) {
      Object.assign(sx, {
        py: {
          xs: 5,
          md: 10,
        },
        px: {
          xs: 7,
          md: 14,
        },
      })
    }

    // @ts-ignore
    sx['position'] = 'relative'

    // Override styling based on theme variant
    switch (variant) {
      case ImageCtaVariant.default:
        Object.assign(sx, {
          '.title__title': {
            typography: alignImage === ImageCtaImagePosition.full ? typography.h2 : typography.h3,
            maxWidth: alignImage === ImageCtaImagePosition.full ? 856 : '100%',
            mx: 'auto',
            mb: {
              xs: alignImage !== ImageCtaImagePosition.full ? 2 : 0,
              md: alignImage !== ImageCtaImagePosition.full ? 3 : 0,
            },
            textAlign: alignImage === ImageCtaImagePosition.full ? 'center' : 'left',
          },
          '.title__subtitle': {
            typography: typography.pRegular,
            mb: {
              xs: 2,
              md: 3,
            },
          },
          '.image-cta__image': {
            '.gatsby-image-wrapper': {
              borderRadius: '4px',
              height: {
                lg: alignImage === ImageCtaImagePosition.full ? 512 : 'inherit',
              },
              maxWidth: 1080,
              mx: 'auto',
              width: alignImage === ImageCtaImagePosition.full ? '100%' : 'inherit',
            },
          },
          '.image-cta__bottom-text': {
            typography: typography.paragraphLarge,
            textAlign: 'center',
            maxWidth: 856,
            mx: 'auto',
            mt: {
              xs: 2,
              md: 4,
            },
          },
          '.image-cta__link': {
            textAlign: alignImage === ImageCtaImagePosition.full ? 'center' : 'left',
            maxWidth: alignImage === ImageCtaImagePosition.full ? 856 : '100%',
            mx: 'auto',
            mt: {
              xs: 2,
            },
          },
        })
        break
      case ImageCtaVariant.blue:
        backgroundColor = colors.blue
        accentColor = PageBackgroundAccent.variant.white
        Object.assign(sx, {
          '.title__title': {
            typography: typography.superHeading,
            mb: 3,
          },
          '.title__subtitle': {
            typography: typography.paragraphLarge,
            mb: 3,
          },
          '.title *': {
            color: colors.white,
          },
          'svg, svg path': {
            fill: colors.white,
          },
        })
        break
      case ImageCtaVariant.pink:
        backgroundColor = colors.softPinkGradiant
        accentColor = PageBackgroundAccent.variant.white
        Object.assign(sx, {
          '.title__title': {
            typography: typography.superHeading,
            mb: 3,
          },
          '.title__subtitle': {
            typography: typography.paragraphLarge,
            mb: 3,
          },
          svg: {
            fill: colors.white,
          },
        })
        break
    }

    switch (alignImage) {
      case ImageCtaImagePosition.left:
        Object.assign(sx, {
          '.page-background-accent': {
            ...sx['.page-background-accent'],
            right: 'initial',
            left: '-40%',
          },
        })
        break
      case ImageCtaImagePosition.right:
        Object.assign(sx, {
          '.page-background-accent': {
            ...sx['.page-background-accent'],
            right: '-40%',
            left: 'initial',
          },
        })
        break
      case ImageCtaImagePosition.full:
        // Hide background accents on full position images.
        Object.assign(sx, {
          '.page-background-accent': {
            display: 'none',
          },
        })
        break
    }

    const accent = accentColor ? <PageBackgroundAccent variant={accentColor} /> : null

    return { sx, backgroundColor, accent }
  }, [variant])
}

// @ts-nocheck
import { stepIsInFlow } from '@ggs/components/ecomm/Checkout/hooks/useStepFlow'
import {
  resetCheckoutState,
  resetCheckoutStateExceptShippingAddress,
  setCheckoutStepsComplete,
  useDispatch
} from '@ggs/store'
import React from 'react'
import CHECKOUT_STEP_ENUMS from '../enums/CHECKOUT_STEP_ENUMS'

// const { AUTH, COMPLETE, ORDER, PAYMENT, REVIEW } = CHECKOUT_STEP_ENUMS
const { COMPLETE, ORDER, PAYMENT, REVIEW } = CHECKOUT_STEP_ENUMS

/**
 * @typedef {Object} useStepProgressParams
 * @property {Array<String>} stepFlow array of all the steps
 * @property {any} stepsComplete injected State from redux
 */

/**
 * the Users current progress through the checkout step flow.
 * @param {useStepProgressParams} args
 * @return {any}
 */
export const StepProgressController = ({ stepFlow, stepsComplete }) => {
  /**
   * @return {any}
   */
  const resetSteps = () =>
    stepFlow.reduce((accumulator, step) => {
      // @ts-ignore
      accumulator[step] = false
      return accumulator
    }, {})

  /**
   * @param {number} currentStepIndex
   * @return {any}
   */
  const resetStepsAfter = (currentStepIndex) =>
    stepFlow.reduce((accumulator, step, currentIndex) => {
      // @ts-ignore
      accumulator[step] = currentIndex < currentStepIndex
      return accumulator
    }, {})

  /**
   * @param {number} stepIndex
   * @return {boolean}
   */
  const isStepCompleteByStepperIndex = (stepIndex) =>
    stepsComplete[stepFlow[stepIndex]]

  /**
   * @param {string} STEP_NAME
   * @return {boolean}
   */
  const isStepCompleteByCheckoutStepName = (STEP_NAME) => {
    return STEP_NAME && stepsComplete?.[STEP_NAME] && stepsComplete[STEP_NAME]
  }

  /**
   * @param {string} STEP_NAME
   * @return {any}
   */
  const markCheckoutStepCompleteByCheckoutStepName = (STEP_NAME) => {
    // idempotent if you mark the exact step as complete
    // @ts-ignore
    return {
      ...stepsComplete,
      [STEP_NAME]: true,
    }
  }

  /**
   * @param {string} STEP_NAME
   * @return {any}
   */
  const resetCheckoutStepCompleteByCheckoutStepName = (STEP_NAME) => {
    // idempotent if you mark the exact step as incomplete
    // @ts-ignore
    return ({
      ...stepsComplete,
      [STEP_NAME]: false,
    })
  }

  /**
   * @param {number} stepIndex
   * @return {boolean}
   */
  const isPrevStepComplete = (stepIndex) => isStepCompleteByStepperIndex(stepIndex - 1)

  /**
   * @param {number} stepIndex
   * @return {boolean}
   */
  const isNextCheckoutStep = (stepIndex) => !isStepCompleteByStepperIndex(stepIndex) && isPrevStepComplete(stepIndex)
  return {
    stepsComplete,
    isPrevStepComplete,
    isNextCheckoutStep,
    isStepComplete: {
      stepperIndex: (/** @type {number} */ stepIndex) => isStepCompleteByStepperIndex(stepIndex),
      checkoutStepName: (/** @type {string} */ STEP_NAME) => isStepCompleteByCheckoutStepName(STEP_NAME),
      // [AUTH]: () => isStepCompleteByCheckoutStepName(AUTH),
      [ORDER]: () => isStepCompleteByCheckoutStepName(ORDER),
      [PAYMENT]: () => isStepCompleteByCheckoutStepName(PAYMENT),
      [REVIEW]: () => isStepCompleteByCheckoutStepName(REVIEW),
      [COMPLETE]: () => isStepCompleteByCheckoutStepName(COMPLETE),
    },
    markStepComplete: {
      // [AUTH]: () => markCheckoutStepCompleteByCheckoutStepName(AUTH),
      [ORDER]: () => markCheckoutStepCompleteByCheckoutStepName(ORDER),
      [PAYMENT]: () => markCheckoutStepCompleteByCheckoutStepName(PAYMENT),
      [REVIEW]: () => markCheckoutStepCompleteByCheckoutStepName(REVIEW),
      [COMPLETE]: () => markCheckoutStepCompleteByCheckoutStepName(COMPLETE),
      stepFlowIndex: {
        // should resolve to {0: () => markAuthComplete}, {1: markOrder}, etc.
        ...stepFlow.reduce((accumulator, step, currentIndex) => {
          // @ts-ignore
          accumulator[currentIndex] = () => markCheckoutStepCompleteByCheckoutStepName(step)
          return accumulator
        }, {}),
      },
    },
    resetCheckoutStepCompleteByCheckoutStepName: {
      // [AUTH]: () => resetCheckoutStepCompleteByCheckoutStepName(AUTH),
      [ORDER]: () => resetCheckoutStepCompleteByCheckoutStepName(ORDER),
      [PAYMENT]: () => resetCheckoutStepCompleteByCheckoutStepName(PAYMENT),
      [REVIEW]: () => resetCheckoutStepCompleteByCheckoutStepName(REVIEW),
      [COMPLETE]: () => resetCheckoutStepCompleteByCheckoutStepName(COMPLETE),
    },
    resetStepComplete: {
      // resetAuth: () => resetCheckoutStepCompleteByCheckoutStepName[AUTH](),
      resetOrder: () => resetCheckoutStepCompleteByCheckoutStepName[ORDER](),
      resetPayment: () => resetCheckoutStepCompleteByCheckoutStepName[PAYMENT](),
      resetReview: () => resetCheckoutStepCompleteByCheckoutStepName[REVIEW](),
      resetComplete: () => resetCheckoutStepCompleteByCheckoutStepName[COMPLETE](),
    },
    resetSteps,
    resetStepsAfter,
  }
}

export const useStepProgressController = ({
  // order,
  stepFlow,
  stepsComplete
}) => {
  const dispatch = useDispatch()
  const stepProgressController = StepProgressController({
    stepFlow,
    stepsComplete
  })

  const dispatchMarkStepComplete = (STEP_NAME) => {
    if (stepIsInFlow({ STEP_NAME, stepFlow })) {
      dispatch(
        setCheckoutStepsComplete(
          stepProgressController.markStepComplete[STEP_NAME]()
        ))
    }
  }

  const dispatchResetStep = (STEP_NAME) => {
    if (stepIsInFlow({ STEP_NAME, stepFlow })) {
      dispatch(setCheckoutStepsComplete(stepProgressController
        .resetCheckoutStepCompleteByCheckoutStepName[STEP_NAME]()))
    }
  }

  const dispatchResetSteps = () => {
    dispatch(resetCheckoutState())
  }

  const dispatchResetExceptShippingAddress = (order) => {
    if (order?.shipping_address) {
      dispatch(resetCheckoutStateExceptShippingAddress(order))
    } else {
      dispatch(resetCheckoutState())
    }
  }

  const {
    isStepComplete,
    isNextCheckoutStep,
  } = stepProgressController

  return {
    stepsComplete,
    markStepComplete: dispatchMarkStepComplete,
    resetSteps: dispatchResetSteps,
    resetStepByName: dispatchResetStep,
    resetExceptShippingAddress: dispatchResetExceptShippingAddress,
    isStepComplete,
    isNextCheckoutStep,
  }
}
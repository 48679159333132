// @ts-nocheck
/* eslint-disable require-jsdoc */
import { CommerceSessionContextProvider } from '@ggs/components/ecomm/CommmerceSession/CommerceSessionContext'
import React from 'react'
import { useStoreSelectors } from '@ggs/components/ecomm/Checkout/useStoreSelectors'
import { useAudienceLangStoreOptions } from '@ggs/components/ecomm/Checkout/useAudienceLangStoreOptions'
import { useCommerceApiContext } from '@ggs/components/ecomm/CommmerceSession/CommerceApiContext'
import { useCommerceLayerToken } from '@ggs/components/ecomm/hooks/useCommerceLayerToken'
import { GTMProvider } from '@ggs/components/ecommerceAnalytics/context/GTMContext'

const displayDebug = (parseInt(process.env.GATSBY_DEBUG_PARAGRAPH_SETTINGS) === 1)


// App component that provides initial context values
export const CommerceApp = ({ children }) => {
  const { marketId, scope, accessToken } = useCommerceApiContext()
  // bootstrap API with token if set on local && valid..
  //
  // console.log(
  //   accessToken,
  //   scope,
  //   marketId,
  //   currentMarket,
  //   initCommerceLayerToken,
  // )

  const {
    settings,
    stores,
    customer,
    checkout,
    currentStore,
    order,
    analytics
  } = useStoreSelectors()
  const { currentMarket } = settings
  const { currentAudience } = useAudienceLangStoreOptions({ stores, settings })

  const { clClient } = useCommerceLayerToken({ scope, accessToken })

  const sessionProps = {
    clClient,
    token: accessToken.token,
    accessToken,
    scope,
    marketId,
    // initCommerceLayerToken,
    customer,
    checkout,
    currentMarket,
    currentStore,
    order,
  }

  return (
    <CommerceSessionContextProvider {...sessionProps}>
      <GTMProvider clClient={clClient} order={order} analytics={analytics}>
        {displayDebug ? currentAudience : null}
        {children}
      </GTMProvider>
    </CommerceSessionContextProvider>
  )
}

import { ThunkAction } from 'redux-thunk'
import { resolveGeolocationPlace } from './GeocodeActions'

/**
 * @type {('GET_USER_GEOLOCATION')}
 */
export const GET_USER_GEOLOCATION = 'GET_USER_GEOLOCATION'

/**
 * @typedef {Object} UserGeolocationActionPayload
 * @property {number} latitude User location latitude
 * @property {number} longitude User location longitude
 * @property {number} accuracy User location accuracy
 */

/**
 * @typedef {Object} UserGeolocationAction
 * @property {string} type Action type ID
 * @property {UserGeolocationActionPayload} payload Action input data
 */

/**
 * Compose a get user geolocation action.
 *
 * @param {UserGeolocationActionPayload} userGeolocation User geolocation data
 * @return {UserGeolocationAction} Start guest session action.
 */
const composeUserGeolocationAction = (userGeolocation) => {
  return {
    type: GET_USER_GEOLOCATION,
    payload: {
      ...userGeolocation
    },
  }
}

/**
 * Get user geolocation through HTML5 API and reverse geocoding.
 * @return {ThunkAction.<void, any, null, UserGeolocationAction>} return action object
 */
export const getUserGeolocation = () => {
  const settings = {
    timeout: 5000,
  }

  return async (dispatch) => {
    /**
     * Dipatch geolocation coordinates action.
     * @param {GeolocationPosition} geolocation User resolved geolocation position
     */
    const onResolve = ( geolocation ) => {
      const { coords } = geolocation
      dispatch(
        composeUserGeolocationAction({
          latitude: coords.latitude,
          longitude: coords.longitude,
          accuracy: coords.accuracy
        })
      )

      dispatch(resolveGeolocationPlace(coords.latitude, coords.longitude))
    }

    // @ts-ignore
    const onError = (error) => {
      console.log('Geolocation error: ', error)
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onResolve, onError, settings)
    }
  }
}

import React from 'react'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { useWindowSize } from '@ggs/hooks'
import {
  TimelineItem as MuiTimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent
}  from '@mui/lab'
import Typography from '@mui/material/Typography'

// Assets.
import './TimelineItem.scss'

/**
 * @typedef {Object} TimelineItemProps
 * @property {Number=} id
 * @property {String=} label
 * @property {String=} summary
 * @property {ImageStyles=} timelineImage
 */
/**
 * TimelineItem component.
 * @param {TimelineItemProps} props
 * @return {JSX.Element}
 */
export default function TimelineItem({ label = '', summary = '', timelineImage }) {
  const sizes = useWindowSize()
  const image = (
    <ImageStyles {...timelineImage} selectedStyles={['searchThumbnail']} />
  )

  return (
    <MuiTimelineItem className="timeline-item">
      {sizes.sm && (
        <TimelineOppositeContent className="timeline-item__image">
          {image}
        </TimelineOppositeContent>
      )}
      <TimelineSeparator className="timeline-item__separator">
        <TimelineDot className="timeline-item__dot" />
        <TimelineConnector className="timeline-item__connector" />
      </TimelineSeparator>
      <TimelineContent className="timeline-item__content">
        {sizes.belowSm && (
          <div className="timeline-item__image">{image}</div>
        )}
        <Typography variant="h3" component="span">{label}</Typography>
        <div className="timeline-item__summary" dangerouslySetInnerHTML={{ __html: summary }} />
      </TimelineContent>
    </MuiTimelineItem>
  )
}

/* eslint-disable */
// @ts-nocheck
import React from 'react'

import {compact} from 'lodash'

/**
 * matches include visibility, show
 * @param {boolean} matches
 * @param {boolean|null} include
 * @return {boolean| null}
 */
const includeMatch = (matches, include) => matches && include

/**
 * matches exclude visibility, hide
 * @param {boolean} matches
 * @param {boolean|null} include
 * @return {boolean| null}
 */
const excludeMatch = (matches, include) => matches && include === false

/**
 * does not match visibility, hide
 * @param {boolean} matches
 * @param {boolean|null} include
 * @return {boolean|null}
 */
const includeMiss = (matches, include) => !matches && include

/**
 * does not match exclude visibility, show.
 * @param {boolean} matches
 * @param {boolean|null} include
 * @return {boolean|null}
 */
const excludeMiss = (matches, include) => !matches && include === false

const replaceall = function (replaceThis, withThis, inThis) {
  const withThisReplace = withThis.replace(/\$/g, '$$$$')
  return inThis.replace(new RegExp(replaceThis.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|<>\-\&])/g, '\\$&'), 'g'), withThisReplace)
}

/**
 * Compare visibility field against the users state (store/region/custom type) to control content display.
 * @param {Array<String>|Object|Null=} visibility
 * @return {Array<Object>|Null} Content should be displayed.
 */
const mapVisibility = (visibility) => Array.isArray(visibility)
  ? visibility.map((item) => {
    const [storeId, regionId, customerTypeId] = item.split('-')
    return {storeId, regionId, customerTypeId}
  })
  : null

/**
 * Compare visibility field against the users state (store/region/custom type) to control content display.
 * @param {String=} visibilityJSON
 * @return {Array<String>} Content should be displayed.
 */
const visibilityParse = (visibilityJSON) => {
  try {
    const parsedVis = JSON.parse(String(visibilityJSON) || '[]')
    if (parsedVis.length) {
      return parsedVis.filter((e) => e.split('-').length === 3)
    } else {
      return []
    }
  } catch (e) {
    return []
  }
}
/**
 * @param {String} settingsData
 * @return {String}
 */
const cleanSettings = (settingsData) => {
  const removedBackSlash = replaceall('\\', '', settingsData)
  return replaceall('"', '', removedBackSlash)
}

/**
 * @param {String} userSettings
 * @param {Array<String>} contentSettingsList
 * @return {boolean}
 */
export const checkMatch = (
  userSettings,
  contentSettingsList
) => {
  const mapped = contentSettingsList?.map((contentSettings) => cleanSettings(contentSettings) === cleanSettings(userSettings))
  return (compact(mapped).length > 0)
}


/**
 * checks if a content should be displayed according to exclude / include and matching visibility.
 * @param {boolean} matches
 * @param {boolean|null} include
 * @return {boolean|null}
 */
const displayVisibility = (/** @type {boolean} */ matches, /** @type {boolean|null} */ include = null) => {
  if (include || (include === false)) {
    if (excludeMatch(matches, include)) return false
    if (includeMiss(matches, include)) return false
    if (includeMatch(matches, include)) return true
    if (excludeMiss(matches, include)) return true
  } else {
    // include is null (component does not have settings or settings not set. display by default
    return true
  }
}

/**
 * @param {boolean} matches
 * @param {boolean} include
 * @return {object| null}
 */
const displayVisibilityDebug = (/** @type {boolean} */ matches, /** @type {boolean} */ include) => {
  if (include || (include === false)) {
    if (excludeMatch(matches, include)) return {backgroundColor: '#FFCCCB'}
    if (includeMiss(matches, include)) return {backgroundColor: '#FFCCCB'}
    if (includeMatch(matches, include)) return {backgroundColor: '#90EE90'}
    if (excludeMiss(matches, include)) return {backgroundColor: '#90EE90'}
    return {backgroundColor: '#FFD580'}
    // } else {
    // return {backgroundColor: '#ADD8E6'}
  }
}


/**
 * @param {String} visibility
 * @param {boolean|null} include
 * @param {String} userSettings
 * @return {{shouldDisplay: boolean, debugSx: object, debugText: string}} Parsed visibility
 */
export default function visibilityHelper(visibility, include, userSettings, raw) {
  try {
    const vSettings = visibilityParse(visibility)
    const findMatch = checkMatch(userSettings, vSettings)

    return {
      shouldDisplay: displayVisibility(findMatch, include),
      debugSx: displayVisibilityDebug(findMatch, include),
      debugText:
        <span>
          <div>
            <code>
              {include !== null && null !== findMatch ? JSON.stringify({include, findMatch}) : null}
            </code>
          </div>
          <div>
          userSettings:
            <code>
              {userSettings && JSON.stringify(userSettings)}
            </code>
          </div>
          <div>
            rawData:
            <code>
              {raw && JSON.stringify(raw)}
            </code>
          </div>
        </span>
    }
  } catch (e) {
    return {
      shouldDisplay: true,
      userSettings: null,
      debugText: e.message
    }
  }
}

import { uniqueId } from 'lodash'

/**
 * @typedef {import('../initialState').AlertMessage} AlertMessage
 * @typedef {import('../initialState').AlertType} AlertType
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 * @typedef {import('@ggs/types').Store} Store
 */

/**
 * Customer action types.
 * @enum {string}
 */
export const uiActionType = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  CLEAN_PRODUCT_LOCAL_CART: 'CLEAN_PRODUCT_LOCAL_CART',
  CLEAR_ALERT: 'CLEAR_ALERT',
  CLEAR_ALERTS: 'CLEAR_ALERTS',
  HIDE_SIDEBAR: 'HIDE_SIDEBAR',
  REMOVE_PRODUCT_LOCAL_CART_ITEM: 'REMOVE_PRODUCT_LOCAL_CART_ITEM',
  SET_LAYOUT_META: 'SET_LAYOUT_META',
  SET_PREFERRED_STORE: 'SET_PREFERRED_STORE',
  SET_PRODUCT_LOCAL_CART: 'SET_PRODUCT_LOCAL_CART',
  SHOW_SIDEBAR: 'SHOW_SIDEBAR',
}

/**
 * @typedef {Object} LayoutMetaAction
 * @property {uiActionType} type Action type key
 * @property {LayoutMeta} payload Action payload data
 */

/**
 * @typedef {Object} SidebarTypeActionPayload
 * @property {Boolean} visible
 * @property {string} contentType
 */

/**
 * @typedef {Object} SidebarAction
 * @property {uiActionType} type Action type key
 * @property {SidebarTypeActionPayload} payload Action payload data
 */

/**
 * Compose customer action.
 *
 * @param {uiActionType} actionType UI action type
 * @param {Boolean} visible Visibility flag state
 * @param {string} contentType Content type to display
 * @return {SidebarAction}
 */
const composeSidebarAction = (actionType, visible, contentType) => {
  return {
    type: actionType,
    payload: {
      visible,
      contentType,
    },
  }
}

/**
 * Action to show the webapp sidebar.
 *
 * @param {LayoutMeta} payload Content type to display
 * @return {LayoutMetaAction} Action object.
 */
export const setLayoutMeta = (payload) => {
  return {
    type: uiActionType.SET_LAYOUT_META,
    payload
  }
}

/**
 * Action to show the webapp sidebar.
 *
 * @param {string} contentType Content type to display
 * @return {SidebarAction} Action object.
 */
export const showSidebar = (contentType) => {
  return composeSidebarAction(uiActionType.SHOW_SIDEBAR, true, contentType)
}

/**
 * Action to hide the webapp sidebar.
 * @return {SidebarAction} Action object.
 */
export const hideSidebar = () => {
  return composeSidebarAction(uiActionType.SHOW_SIDEBAR, false, '')
}

/**
 * @typedef {Object} AddNotificationAction
 * @property {uiActionType} type Action type key
 * @property {AlertMessage} payload Action payload data
 */

/**
 * Add UI notification message.
 *
 * @param {string} code Message code
 * @param {AlertType} type Message type
 * @param {string} title Message title
 * @param {string} body Message body
 * @return {AddNotificationAction} Action object.
 */
export const addNotification = (code, type = 'info', title = '', body = '') => {
  return {
    type: uiActionType.ADD_NOTIFICATION,
    payload: {
      id: uniqueId('notification'),
      visible: true,
      code,
      title,
      body,
      type,
    },
  }
}

/**
 * @typedef {Object} ClearAlertsAction
 * @property {uiActionType} type Action type key
 * @property {null} payload
 */

/**
 * Action to clear UI alerts.
 * @return {ClearAlertsAction} Action object.
 */
export const clearAlerts = () => {
  return {
    type: uiActionType.CLEAR_ALERTS,
    payload: null,
  }
}

/**
 * @typedef {Object} ClearAlertActionPayload
 * @property {String} id
 */

/**
 * @typedef {Object} ClearAlertAction
 * @property {uiActionType} type Action type key
 * @property {ClearAlertActionPayload} payload
 */

/**
 * Action to clear specific UI alert by ID.
 * @param {String} id The alert ID to clear
 * @return {ClearAlertAction} Action object.
 */
export const clearAlert = (id) => {
  return {
    type: uiActionType.CLEAR_ALERT,
    payload: {
      id,
    },
  }
}

/**
 * @typedef {import('@ggs/types').OptionalProductCartItem}
 *   OptionalProductCartItem
 */

/**
 * @typedef {Object} SetProductLocalCartActionPayload
 * @property {String} productId
 * @property {Array<OptionalProductCartItem>} optionalItems
 */

/**
 * @typedef {Object} SetProductLocalCartAction
 * @property {uiActionType} type Action type key
 * @property {SetProductLocalCartActionPayload} payload
 */

/**
 * Set product local cart state.
 *
 * @param {String|Number} productId Product ID that owns the state
 * @param {Array<OptionalProductCartItem>} optionalItems Product optional items
 * @return {SetProductLocalCartAction} Action object.
 */
export const setProductLocalCart = (productId, optionalItems, linkTranslations) => {
  return {
    type: uiActionType.SET_PRODUCT_LOCAL_CART,
    payload: {
      productId: String(productId),
      optionalItems,
      linkTranslations
    },
  }
}

/**
 * @typedef {Object} RemoveProductLocalCartItemActionPayload
 * @property {String} productId
 * @property {String} optionId
 */

/**
 * @typedef {Object} RemoveProductLocalCartItemAction
 * @property {uiActionType} type Action type key
 * @property {RemoveProductLocalCartItemActionPayload} payload
 */

/**
 * Remove product local cart item.
 *
 * @param {String|Number} productId Product ID that owns the local cart
 * @param {String} optionId Product option ID
 * @return {RemoveProductLocalCartItemAction} Action object.
 */
export const removeProductLocalCartItem = (productId, optionId) => {
  return {
    type: uiActionType.REMOVE_PRODUCT_LOCAL_CART_ITEM,
    payload: {
      productId: String(productId),
      optionId,
    },
  }
}

/**
 * @typedef {Object} SetPreferredStoreActionPayload
 * @property {Store} store
 */

/**
 * @typedef {Object} SetPreferredStoreAction
 * @property {uiActionType} type Action type key
 * @property {SetPreferredStoreActionPayload} payload
 */

/**
 * Set preferred store.
 * @param {Store} store Preferred store
 * @return {SetPreferredStoreAction} Action object.
 */
export const setPreferredStore = (store) => {
  return {
    type: uiActionType.SET_PREFERRED_STORE,
    payload: {
      store,
    },
  }
}

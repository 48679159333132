// @ts-nocheck
import { useLink } from '@ggs/gatsby/lib'
import { useMemo } from 'react'
import { useLayoutMeta } from '@ggs/hooks'

/**
 * Generate ecomm links from pageContext.
 *
 * @return {{productListing, checkout, cart, account, signup, login, search,
 *   forgotPassword, resetPassword, storeUrl}}
 */
export default function useSupportingLinks() {
  const safeUrl = useLink()
  const layoutMeta = useLayoutMeta()

  const supportingLinks = useMemo(() => {
    const { menus: { supporting } = {}, lang } = layoutMeta || {}
    const orderProcessing = layoutMeta?.menus?.supporting?.orderProcessing
    const accountAlias = supporting?.accountPages?.accountAlias?.uri || 'account'
    /**
     * Common Pages
     */
    const { uri: search } = safeUrl({ href: '/search' })
    /**
     * Order Processing Pages
     */
    const { uri: cart } = safeUrl({ href: orderProcessing?.cartAlias?.uri })
    const { uri: checkout } = safeUrl({ href: orderProcessing?.checkoutAlias?.uri })
    const { uri: productListing } = safeUrl({
      href: layoutMeta.listing?.productListingAlias?.uri
        ?? layoutMeta?.menus?.supporting?.orderProcessing?.productListingAlias?.uri
    })
    /**
     * Account Pages
     */
    const { uri: account } = safeUrl({ href: accountAlias })
    const { uri: signup } = safeUrl({ href: `${accountAlias}/signup` })
    const { uri: login } = safeUrl({ href: `${accountAlias}/login` })
    const { uri: forgotPassword } = safeUrl({ href: `${accountAlias}/forgot-password` })
    const { uri: resetPassword } = safeUrl({ href: `${accountAlias}/reset-password` })

    const productPage = ({ store, language, productPath }) => {
      safeUrl({ href: `${productListing}/${productPath}` })
    }

    const links = {
      productListing,
      checkout,
      cart,
      search,
      account,
      signup,
      login,
      forgotPassword,
      resetPassword,
      productPage
    }

    // console.log('_useSupportingLinks', links)
    return links
  }, [layoutMeta])

  return supportingLinks
}

import { getWindow } from '@ggs/utils'
import { navigate } from 'gatsby'

/**
 * Redirect users to the homepage if they reject.
 */
export default function goToHomepage() {
  const homepage = String(getWindow('location.pathname') || '').split('/')
  // console.log('goToHomepage', homepage)
  navigate(`/${homepage[1]}/${homepage[2]}`)
}

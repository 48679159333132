import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import Grid from '@mui/material/Grid'
import { Divider, Title } from '@ggs/components/paragraphs'
import Box from '@mui/material/Box'
import {
  DesignedByline,
  FrequentlyBoughtProducts,
  OrderShippingAndReturnPolicies,
  ProductOption,
  ProductOptionDetails,
  ProductOptionList,
  ProductPurchaseOptions,
} from '@ggs/components/ecomm'
import { ImageGallery } from '@ggs/components/grids'
import { Chip, SharePage } from '@ggs/components/common'
import { useWindowSize } from '@ggs/hooks' // Assets
import useProductHeroSx from './useProductHeroSx'
import './ProductHero.scss'

const SupportingContent = ({ productDndLogo, frequentlyBought, productData }) => (
  <div className="product-hero__supporting">
    <DesignedByline productDndLogo={productDndLogo} />
    <Divider
      sx={{
        mt: {
          xs: 0,
          lg: 4,
        },
        mb: 4,
      }}
    />
    <FrequentlyBoughtProducts products={frequentlyBought} productData={productData} />
  </div>
)

/**
 * @typedef {import('@ggs/types').Product} Product
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 */
/**
 To receive all of the product options, the default, and any additional ones provided
 * Needs to have internal state, which will track which product option is begin viewed, always defaults to 'default'
 product option
 * Has a handler to pass into the lower components when a product have more than 1 option, so when the user selects one,
 the sub-component will trigger the state at the hero level
 @param {{
   product: Product,
   productDisclaimer: String,
   productDndLogo: object
 }} props
 * @return {JSX.Element}
 */
export default function ProductHero({ product, productDisclaimer, productDndLogo, productData }) {
  // console.log('dndLogo', productDndLogo)
  const { t } = useI18n()
  const windowSize = useWindowSize()
  const sx = useProductHeroSx()

  const {
    url,
    name,
    subtitle,
    includedItems = [],
    productOptions = [],
    productCategories,
    frequentlyBought,
    optionsLabel,
    optionsFooter,
    hasPricing,
    purchaseOption,
    activeOption,
    productImages = null,
    changeOption,
    skuPrice,
  } = productData
  // const {} = useProductMetadata()

  return (
    <Box className="product-hero" sx={sx}>
      <Grid
        container
        className="product-hero__primary"
        spacing={{
          xs: 3,
          lg: 6,
        }}
      >
        <Grid item xs={12} lg={6}>
          <ImageGallery
            className="product-hero__gallery"
            images={productImages}
            fullStyle="productFull"
            thumbnailStyle="productGalleryThumbnail"
          />
          {windowSize.lg ? (
            <SupportingContent
              frequentlyBought={frequentlyBought}
              productDndLogo={productDndLogo}
              productData={productData}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Title title={name} subtitle={subtitle} style="h2" component="h1" />
          {/*TODO: Render the productUse here by some means*/}
          <Box className="product-hero__categories">
            {productCategories.map(({ name }) => (
              <Chip key={name} label={name} />
            ))}
          </Box>
          {includedItems.length > 0 ? (
            <Box
              className="product-hero__included"
              sx={{
                mb: 3,
                '& .title__title': {
                  mb: 2,
                },
              }}
            >
              <Title title={t('ecomm:product.label.included')} style="h4" />
              <Grid container spacing={3}>
                {includedItems.map((option) => (
                  <Grid item key={option}>
                    <ProductOption
                      // @ts-ignore
                      option={{ label: option }}
                      variant="included"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : null}
          <ProductOptionDetails currentOption={activeOption} />
          {Array.isArray(productOptions) && productOptions.length > 0 ? (
            <ProductOptionList
              changeOption={changeOption}
              currentOption={activeOption}
              productOptions={productOptions}
              optionsLabel={optionsLabel}
              optionsFooter={optionsFooter}
            />
          ) : null}
          <ProductPurchaseOptions
            product={product}
            currentOption={purchaseOption}
            includedItems={includedItems}
            skuPrice={skuPrice}
          />
          {hasPricing ? (
            <OrderShippingAndReturnPolicies productDisclaimer={productDisclaimer} />
          ) : null}
          {windowSize.lg ? <SharePage url={url} /> : null}
        </Grid>
      </Grid>
      {windowSize.belowLg ? (
        <Grid
          container
          className="product-hero__secondary"
          flexDirection="column-reverse"
          spacing={5}
        >
          <Grid item xs={12} lg={6}>
            <SupportingContent
              frequentlyBought={frequentlyBought}
              productDndLogo={productDndLogo}
              productData={productData}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SharePage url={url} />
          </Grid>
        </Grid>
      ) : null}
    </Box>
  )
}

import { useMemo } from 'react'
import { useSelector } from '@ggs/store'
import { getWindow, resolveUserPreferredLangCode } from '@ggs/utils'
import getGlobals from './getGlobals'

const { LANG_CODES } = getGlobals()

/**
 * @typedef {Object} PreferredStoreHook
 * @property {String} preferredLanguage
 * @property {String} preferredStorePath
 * @property {String} storeAlias
 * @property {Boolean} suggestAlternativeStore
 * @property {Boolean} suggestAlternativeLanguage
 */

/**
 * Handle resolution of preferred store based on user country / language.
 *
 * @return {PreferredStoreHook} Resolved preferred store path
 */
function usePreferredStoreSettings() {
  const isClient = getWindow()
  const userCountry = useSelector((state) => state.settings.country)
  const userLanguage = useSelector((state) => state.settings.language)
  const preferredStore = useSelector((state) => state.ui.preferredStore)
  const currentStore = useSelector((state) => state.ui.layoutMeta?.currentStore)
  const lang = useSelector((state) => state.ui.layoutMeta?.lang)
  // const viewedPath = getViewedPagePath()

  const preferredStoreSettings = useMemo(() => {
    if (isClient && userCountry && preferredStore) {
      const preferredLanguage = resolveUserPreferredLangCode(LANG_CODES, userLanguage?.id || 'en-CA') || ''
      const preferredStorePath = `/${preferredStore.storeAlias}/${preferredLanguage}`
      // User not viewing preferred store, recommend as preferred store.
      const suggestAlternativeStore
        = preferredStorePath && preferredStore.storeAlias !== currentStore?.storeAlias
      const suggestAlternativeLanguage = preferredLanguage && preferredLanguage !== lang
      return {
        preferredLanguage,
        preferredStorePath,
        storeAlias: preferredStore.storeAlias,
        suggestAlternativeStore,
        suggestAlternativeLanguage,
      }
    }

    return null
  }, [preferredStore, userCountry, userLanguage])

  return preferredStoreSettings
}

export default usePreferredStoreSettings

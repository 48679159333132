/**
 * Decompose language localized code into code/country parts.
 * @param {string} languageLocaleCode
 * @return {{langCode: (string), countryIso2: (string)}}
 */
export function decomposeLanguageLocaleCode(languageLocaleCode) {
  const [langCode, countryIso2] = String(languageLocaleCode).split('-')
  return {
    langCode: langCode || 'en',
    countryIso2: countryIso2 || '',
  }
}

/**
 * @typedef {Object<string, Array<string>>} StoreLanguagesIndex
 */

/**
 * Compose stores languages (country - languages[]) dictionary.
 * @param {Array<string>} langCodes Localized languages codes array, e.x. ['en-US', 'en-CA']
 * @return {StoreLanguagesIndex} A dictionary indexed by country code with lang codes values
 */
export function composeStoresLanguagesDict(langCodes) {
  /**
   * @type {StoreLanguagesIndex}
   */
  const storesLanguages = {}
  langCodes.forEach((language) => {
    const [langCode, countryIso2] = language.split('-')

    if (langCode && countryIso2) {
      if (!storesLanguages[countryIso2]) {
        storesLanguages[countryIso2] = []
      }

      storesLanguages[countryIso2].push(langCode)
    }
  })

  return storesLanguages
}

// @ts-nocheck
import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import { Divider } from '@ggs/components/paragraphs'
import { PaymentPayPalChosen } from '@ggs/components/ecomm'
import Grid from '@mui/material/Grid'
// @ts-ignore
import { WontBeChargedLine } from '@ggs/components/ecomm/Checkout/elements/payment/StripeLoader'
import { FormActions } from '@ggs/forms/Form'

/**
 * @typedef {import("@commercelayer/sdk").PaymentMethod} PaymentMethod
 * @typedef {import("@commercelayer/sdk").Order} Order
 */

/**
 * @typedef {Object} PaypalPaymentReviewProps
 * @property {function=} purchaseWithStripe
 * @property {function=} purchaseWithStripe
 */

/**
 * Checkout payment methods form (order payment step).
 * @param {PaypalPaymentReviewProps} props
 * @return {JSX.Element}
 */
export default function PaypalPaymentReview({ purchaseWithStripe, continueToReview }) {
  const { t } = useI18n()

  // console.log('PaypalPaymentReview', {
  //   paypalPaymentMethod,
  //   stripePaymentMethod,
  //   selectedPaymentMethodDefinition,
  //   order,
  // })

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <PaymentPayPalChosen variant={'payment'} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <p>{t('ecomm:label.switchPaymentMethods')}</p>
          <FormActions
            actions={[
              {
                type: 'button',
                // className: 'form__action-submit form__action-button',
                label: t('ecomm:button.switchToCreditCard'),
                // variant: 'outlined',
                // endIcon: <ChevronRightIcon/>,
                onClick: (e) => {
                  FormActions.forceButtonLoading(e)
                  purchaseWithStripe()
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 4, mb: 3 }} />
      <FormActions
        actions={[
          {
            type: 'button',
            // className: 'form__action-submit form__action-button',
            label: t('ecomm:button.continueToReview'),
            // variant: 'outlined',
            // endIcon: <ChevronRightIcon/>,
            onClick: () => {
              // onSuccess()
              continueToReview()
            },
          },
        ]}
      />
      <WontBeChargedLine />
    </>
  )
}
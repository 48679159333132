import React, { forwardRef } from 'react'
import Box from '@mui/material/Box'
// @ts-ignore
import { breakpoints } from '@ggs/styles'
// @ts-ignore
import { Divider } from '@ggs/components/paragraphs'

/**
 * @typedef {import('@ggs/types').GridContainer} GridContainer
 */

/**
 *
 * Allows to compose grid items into container with background and standard spacing.
 * @param {GridContainer} props
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly sx?: *, readonly backgroundColor?: *,
 *   readonly className?: *, readonly useDivider?: *, readonly fullWidthBackground?: *, readonly children?: *}> &
 *   React.RefAttributes<unknown>>}
 */
// eslint-disable-next-line react/display-name
const GridContainer = forwardRef(
  (
    {
      className = '',
      useDivider = false,
      children,
      backgroundColor = '',
      fullWidthBackground = false,
      sx,
      ...props
    },
    ref
  ) => {
    const innerSx = {
      background: !fullWidthBackground && backgroundColor ? backgroundColor : '',
      boxSizing: 'border-box',
      maxWidth: breakpoints.xl,
      width: '100%',
      mx: 'auto',
      p: {
        xs: 2,
        md: 6,
      },
      ...(sx || {}),
    }

    // console.log('GridContainer', className, innerSx)

    return (
      <>
        <Box
          {...props}
          ref={ref}
          className={`grid-container ${className || ''}`}
          sx={{
            background: fullWidthBackground && backgroundColor ? backgroundColor : '',
          }}
        >
          <Box
            className="grid-container__inner"
            // @ts-ignore
            sx={innerSx}
            data-has-divider={useDivider}
          >
            {children}
            {useDivider && <Divider />}
          </Box>
        </Box>
      </>
    )
  }
)

export default GridContainer

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useSelector } from '@ggs/store'
import { isEmpty } from 'lodash' // Assets
import { useSkuLookup } from '@ggs/components/ecomm/hooks'
import './ProductPrice.scss'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 */

/**
 * @typedef {Object} ProductPriceProps
 * @property {ProductOption=} currentOption
 * @property {ProductOption} defaultOption
 * @property {Array<ProductOption>=} productOptions
 */

/**
 * @typedef {import('@commercelayer/sdk').Price} Price
 */

/**
 * @typedef {Object} ProductPriceState Component local state.
 * @property {Price|null} skuPrice Resolved SKU price (from business rules) for determining displayed price
 * @property {boolean} isStartingFrom Flag when resolved price is the lowest and not for a current option selection
 */

/**
 * Price resolution business rules:
 * 1) If there is only a defaultOption, we load this price and use it.
 * 2) If there are productOptions available, and currentOption is empty, then we find the lowest price,
 *    labeling 'Starting at ...' (this scenario is not possible due the fact that current option
 *    is needed for displaying the main product image).
 * 3) When we have a currentOption, we would render the correct price from the productOptions at that time.
 *
 * @param {ProductPriceProps} props
 * @return {{skuPrice: (Price|null), isStartingFrom: boolean, hasPricing: boolean}}
 */
export default function useProductPrice({ currentOption, defaultOption, productOptions }) {
  const skus = useSelector((state) => state.commerce.skus)
  const { lookupProductOptionLowerPrice, lookupProductOptionPrice } = useSkuLookup()

  /**
   * @type {ProductPriceState}
   */
  const defaultState = {
    skuPrice: null,
    isStartingFrom: false,
  }

  const [productPricing, setproductPricing] = useState(defaultState)
  useEffect(() => {
    if (skus) {
      if (currentOption) {
        setproductPricing({
          skuPrice: lookupProductOptionPrice(currentOption),
          isStartingFrom: false,
        })
      } else if (Array.isArray(productOptions) && !isEmpty(productOptions)) {
        const lowestPriceSku = lookupProductOptionLowerPrice(productOptions)
        setproductPricing({
          skuPrice: lowestPriceSku,
          isStartingFrom: !!lowestPriceSku,
        })
      } else if (defaultOption) {
        setproductPricing({
          skuPrice: lookupProductOptionPrice(defaultOption),
          isStartingFrom: false,
        })
      }
    }
  }, [skus, currentOption, defaultOption])

  return {
    ...productPricing,
    hasPricing: !!productPricing?.skuPrice || productPricing?.isStartingFrom,
  }
}

import { getOrder, resetOrderPaymentMethod, updatePaymentSourceStripe } from '@ggs/commercelayer/index'
// @ts-ignore
import { useSupportingLinks } from '@ggs/components/ecomm'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
// @ts-ignore
import StripeLoader, { WontBeChargedLine } from '@ggs/components/ecomm/Checkout/elements/payment/StripeLoader'
import usePaymentSource from '@ggs/components/hooks/usePaymentSource'
import { Divider } from '@ggs/components/paragraphs'
import { FormActions } from '@ggs/forms/Form'
import { useI18n } from '@ggs/gatsby/lib'
import { addNotification, useDispatch, useSelector } from '@ggs/store'
import { getWindow } from '@ggs/utils'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import useInterval from '@use-it/interval'
import { delay, get, noop } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

/**
 * @typedef {import("@commercelayer/sdk").Order} CLOrder
 * @typedef {import("@stripe/stripe-js").StripePaymentElement}
 *   StripePaymentElement
 */

/**
 * @typedef {Object} StripePaymentFormProps
 * @property {function} onSuccess
 * @property {function} scrollToTopOfForm
 * @property {JSX.Element} paypalButton
 */

/**
 * Stripe Payment Details Form.
 * @param {StripePaymentFormProps} props
 * @return {JSX.Element}
 */
export default function StripePaymentForm({
  onSuccess = noop,
  scrollToTopOfForm,
  paypalButton
}) {
  const {
    clClient,
    // handlers: { goToNextStep },
  } = useCheckoutContext()
  const stripe = useStripe()
  const elements = useElements()
  const { checkout: checkoutUrl } = useSupportingLinks()
  const { t } = useI18n()
  const commerce = useSelector((state) => state.commerce)
  const { order, token } = commerce
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  // For interval function don't receive latest state automatically, need to use
  // reference.
  const monitorSwitchRef = useRef(false)
  const [monitorAttempts, setMonitorAttempts] = useState(0)
  const maxMonitorAttempts = 10
  const { resetPaymentSource } = usePaymentSource()
  const origin = getWindow('location.origin')

  /**
   * Disable order monitoring and display notification when payment source
   * details not resolved.
   */
  const handleExceededAttempts = () => {
    if (monitorSwitchRef.current) {
      dispatchSourceError()
      dispatch(resetOrderPaymentMethod({ clClient, order }))
      resetPaymentSource()
      monitorSwitchRef.current = false
      setMonitorAttempts(0)
    }
  }

  /**
   * Mark the Stripe payment widget loading indicator as ready.
   * @param {number} delayMs
   */
  const markAsReady = (delayMs) => {
    // console.log('Widget loaded.')
    // Add a slight delay to avoid the stripe div still loading on dom.
    delay(() => setLoading(false), delayMs)
  }

  /**
   * Handle Stripe Form on ready event.
   * @param {StripePaymentElement} element
   */
  const handleOnReady = (element) => {
    markAsReady(200)
  }

  /**
   * Dispatch Stripe payment source fail notification.
   */
  const dispatchSourceError = () => {
    dispatch(
      addNotification(
        'stripe_payment_source_fail',
        'error',
        t('ecomm:notice.stripePaymentSourceError')
      )
    )
  }

  /**
   * Handle payment details submit.
   * @type {React.FormEventHandler<HTMLFormElement>}
   * @param {React.FormEvent<HTMLFormElement>} event
   * @param {function=} onError
   */
  // @ts-ignore
  const handleSubmit = async (event, onError = noop) => {
    // event.preventDefault()
    // clClientFactory.setToken(token)
    // const clClient = clClientFactory.getClient()

    if (!stripe || !elements) {
      return
    }

    const returnUrl = `${origin}${checkoutUrl}`
    const paymentIntent = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${returnUrl}`,
      },
      redirect: 'if_required',
    })

    // Stripe has field errors, user entered invalidate details and therefore
    // must submit again in iframe.
    if (paymentIntent.error) {
      dispatchSourceError()
      if (paymentIntent.error.code === 'payment_intent_unexpected_state') {
        // order has entered invalid state, purge pamyent source.
        dispatchSourceError()
        dispatch(resetOrderPaymentMethod({ clClient, order }))
        resetPaymentSource()
        monitorSwitchRef.current = false
        setMonitorAttempts(0)
      }
      onError()
      return
    }
    // If the user managed to submit again while the order has not yet
    // resolved for the payment source, we must wait.
    else if (!order.payment_source) {
      return
    }

    const paymentSourceUpdate = {
      id: order.payment_source.id,
      options: {
        ...get(paymentIntent, 'paymentIntent', {}),
      },
    }

    dispatch(
      updatePaymentSourceStripe(clClient, order, paymentSourceUpdate, {
        onSuccess: (action) => {
          monitorSwitchRef.current = true
          // scrollToTopOfForm()
          // goToNextStep()
          // onSuccess()
        },
        onError: () => {
          monitorSwitchRef.current = false
          onError()
          console.log('Error updating payment source.', monitorSwitchRef.current)
        },
      })
    )
  }

  /**
   * Monitor the CL order latest state while submitting payment source:
   *   - Will stop when payment source details is available.
   *   - When max attempts is reached and submission flag is turned off.
   */
  const fetchLatestOrderState = () => {
    if (monitorSwitchRef.current) {
      if (monitorAttempts >= maxMonitorAttempts) {
        handleExceededAttempts()
      }
      //
      // clClientFactory.setToken(token)
      // const clClient = clClientFactory.getClient()
      dispatch(getOrder(clClient, order.id))
      setMonitorAttempts(monitorAttempts + 1)
      // monitorSwitchRef.current = false
    }
  }

  useInterval(fetchLatestOrderState, monitorAttempts * 1000)

  // Identify if payment source details was resolved.
  useEffect(() => {
    const paymentSourceMethodId = get(order, 'payment_source.payment_method.id', null)
    if (paymentSourceMethodId) {
      // We got the data, disable the monitoring.
      monitorSwitchRef.current = false
    }
  }, [order.payment_source])

  // Mark as ready after a timeout to avoid Stripe form keeps loading in case of
  // Stripe error.
  useEffect(() => {
    if (loading) {
      markAsReady(15000)
    }
  }, [loading])

  // @ts-ignore
  return (
    <Box
      component={'form'}
      sx={{
        '.stripe-payment-form__embed': {
          minHeight: 206,
        },
        '.form__actions': {
          display: 'block !important',
          '.form__action-button': {
            display: 'inline',
          },
        },
      }}
    >
      {loading && <StripeLoader paypalButton={paypalButton}/>}
      <div className="stripe-payment-form__embed">
        <PaymentElement onReady={handleOnReady}/>
      </div>
      {!loading && (
        <>
          <Divider sx={{ mt: 4, mb: 3 }}/>
          <Grid container spacing={2}>
            <Grid item>
              <FormActions
                actions={[
                  {
                    label: t('ecomm:button.savePaymentMethod'),
                    loading: monitorSwitchRef.current,
                    disabled: monitorSwitchRef.current,
                  },
                ]}
                // @ts-ignore
                formik={{
                  handleSubmit: (/** @type {any} */ e) => {
                    // need to prevent submit default to resolve bug w/ firefox
                    e.preventDefault()
                    FormActions.forceButtonLoading(e)
                    handleSubmit(
                      e,
                      // @ts-ignore
                      () => {
                        delay(() => {
                          FormActions.forceButtonLoading(e, false)
                        }, 500)
                      }
                    )
                  },
                }}
              />
            </Grid>
            <Grid item>
              <p>{t('ecomm:label.orPayAnotherWay')}</p>
            </Grid>
            <Grid item>{paypalButton}</Grid>
          </Grid>
          <WontBeChargedLine />
        </>
      )}
    </Box>
  )
}
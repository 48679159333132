import React from 'react'
import Box from '@mui/material/Box'
import { Divider, Text } from '@ggs/components/paragraphs'
import { icons } from '@ggs/components'
import { useI18n } from '@ggs/gatsby/lib'
import { colors } from '@ggs/styles'

/**
 * @typedef {Object} PurchasePolicyProps
 * @property {String} disclaimer
 */

/**
 * Shopping cart image ads block.
 * @param {PurchasePolicyProps} props
 * @return {JSX.Element}
 */
export default function PurchasePolicy({ disclaimer = '' }) {
  const { t } = useI18n()
  return (
    <Box
      className="cart-ads"
      sx={{
        backgroundColor: colors.white,
        boxShadow: '0 0 16px rgba(0, 0, 0, .04)',
        borderRadius: '4px',
        mb: 4,
        p: 4,
        '& .divider': {
          p: 0,
          my: 2,
        }
      }}
    >
      <Text
        sx={{
          '& .text__body': {
            fontWeight: 'bold',
            'a': {
              textDecoration: 'underline',
            }
          }
        }}
        textContent={disclaimer}
      />
      <Divider />
      <Text
        sx={{
          '& .text__body': {
            display: 'flex',
            flexDirection: 'column',
          },
          '& .text__body > *:nth-of-type(1)': {
            display: 'inline-flex',
          },
          '& img':{
            maxWidth: '100%',
            alignSelf: 'flex-start',
          }
        }}
      >
        <>
          <p>
            {icons.Lock} <span>{t('ecomm:cart.label.guaranteedSafeCheckout')}</span>
          </p>
          <img src="/images/validPayments.png" alt="" aria-hidden="true" />
        </>
      </Text>
    </Box>
  )
}

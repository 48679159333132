// @ts-nocheck
import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'
import useReminderProgramFields from '@ggs/components/ecomm/CheckoutSteps/useReminderProgramFields'
import { Text } from '@ggs/components/paragraphs'
import { useForm } from '@ggs/forms/Form'
import { Forms } from '@ggs/forms/schema'
import { useI18n } from '@ggs/gatsby/lib'
import { useValidators } from '@ggs/hooks'
import { colors } from '@ggs/styles'
import React from 'react'
import { usePlaceOrderController } from '@ggs/components/ecomm/CheckoutSteps/usePlaceOrderController'

const { REVIEW } = CHECKOUT_STEP_ENUMS

/**
 * @typedef {import("@commercelayer/sdk").Order} Order
 */

/**
 * @typedef {Object} AcceptTermsFormProps
 * @property {function=} onSuccess
 * @property {function=} onFailure
 * @property {string=} acceptTCs
 * @property {Order=} order
 */

/**
 * Accept terms & conditions checkbox form.
 * @param {AcceptTermsFormProps} props
 * @return {JSX.Element}
 */
const AcceptTermsForm = ({ ...props }) => {
  const { t } = useI18n()
  const validators = useValidators()
  const reminderProgramFields = useReminderProgramFields(props)

  const reminderPrograms = props?.items?.reminderPrograms || []

  const { onAcceptTerms } = usePlaceOrderController({ reminderPrograms })

  console.log('acceptTerms', props?.acceptTCs)

  const formFields = {
    ...reminderProgramFields,
    acceptTerms: {
      label: <Text
        textContent={props?.acceptTCs || t('ecomm.checkout.acceptTerms')}/>,
      type: Forms.FieldTypes.checkbox,
      validator: validators.acceptTerms,
    },
  }

  const sx = {
    '& .MuiFilledInput-root': {
      backgroundColor: colors.white,
    },
    '& .MuiButton-root': {
      mt: 2,
      mb: 5,
    },
    '.form__actions': {
      justifyContent: 'flex-start',
    },
    '[data-name="acceptTerms"] .text > div': {
      mx: 0,
      '> div': {
        px: 0,
      },
    },
  }

  const onSubmit = (values, { setSubmitting }, formError) => {
    onAcceptTerms(values, { setSubmitting }, formError)
  }

  const Form = useForm({
    className: 'accept-terms-form',
    fields: formFields,
    actions: [
      {
        label: t('ecomm:button.placeOrder'),
      },
    ],
    onSubmit,
    sx,
  })

  return Form.FormComp
}

export default AcceptTermsForm

import { authActionTypes, orderActionType, skuActionTypes } from '@ggs/commercelayer/index'
import { customerActionType } from '../actions/customer'
import { commerceActionType } from '../actions/commerce'
import { initialState } from '../initialState'
import { createReducer } from '@reduxjs/toolkit'
import { groupBy } from 'lodash'

/**
 * @typedef {import('@commercelayer/sdk').Sku} Sku
 * @typedef {import('@ggs/store').initialState} DefaultState
 */

/**
 * @typedef {import("../initialState").DefaultCommerce} InitialCommerce
 * @typedef {import("@ggs/commercelayer/index").SessionAction} SessionAction
 * @typedef {import("../actions/customer").SetCustomerTypeAction}
 *   SetCustomerTypeAction
 */
/**
 * Set checkout state
 * @param {any} payload
 * @return {{payload, type: (authActionTypes|string)}}
 */
export const setLastAuthTime = (payload) => {
  return {
    type: authActionTypes.SET_LAST_AUTH_TIME,
    payload: payload
  }
}
/**
 * @typedef {import('lodash').Dictionary<any>} DictionaryAny
 */

/**
 * @returns {DefaultState['commerce']} New commerce state.
 */
const commerce = createReducer(initialState.commerce, {
  /**
   * Set CL session token data.
   * @param {InitialCommerce} state
   * @param {SessionAction} action
   */
  [authActionTypes.START_GUEST_SESSION]: (state, action) => {
    state.token = action.payload.token.token
    state.tokenExpiresAt = action.payload.token.expiresAt
  },

  [authActionTypes.START_CUSTOMER_SESSION]: (state, action) => {
    console.log(action)
    const customer = action.payload.customer
    state.token = action.payload.token.token
    state.tokenExpiresAt = action.payload.token.expiresAt.toISOString()
    state.tokenRefresh = action.payload.token.refreshToken
    state.customer = {
      ...initialState.commerce.customer,
      email: customer.email,
      customerType: null,
      status: customer.status,
      customer_group: null,
      customer_addresses: customer.customer_addresses,
      customer_payment_sources: customer.customer_payment_sources,
      orders: [],
      ...customer,
    }
  },

  [authActionTypes.SET_LAST_AUTH_TIME]: (state, action) => {
    state.lastAuth = action.payload.lastAuth
  },

  [authActionTypes.RENEW_CUSTOMER_SESSION]: (state, action) => {
    state.token = action.payload.token.token
    state.tokenExpiresAt = action.payload.token.expiresAt.toISOString()
    state.tokenRefresh = action.payload.token.refreshToken
  },

  [skuActionTypes.GET_MARKET_SKUS_DATA]: (state, action) => {
    const { skus } = action.payload

    /**
     * Build SKUs dictionary.
     * @return {DictionaryAny}
     */
    const buildSkusIndex = () => {
      return groupBy(skus, 'code')
    }

    state.skus = buildSkusIndex()
  },

  [orderActionType.CREATE_DRAFT_ORDER]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.EMPTY_DRAFT_ORDER]: (state, action) => {
    state.order = action.payload.order
  },

  // this gets and sets the current CHECKOUT order (draft/inProgressOrder)
  [orderActionType.GET_ORDER]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.ADD_ORDER_ITEMS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.UPDATE_ORDER_ITEMS]: (state, action) => {
    state.order = { ...state.order, ...action.payload.order }
  },

  [orderActionType.REMOVE_ORDER_ITEMS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.ADD_ORDER_COUPON]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.REMOVE_ORDER_COUPON]: (state, action) => {
    state.order = action.payload.order
  },

  [commerceActionType.CLEAR_ORDER_STATE]: (state) => {
    state.order = null
  },

  [customerActionType.SET_CUSTOMER]: (state, action) => {
    state.customer = action.payload.customer
  },

  [orderActionType.ADD_ORDER_SHIPPING_ADDRESS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.ADD_ORDER_BILLING_ADDRESS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.SET_ORDER_SHIPPING_METHOD]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.RESET_ORDER_SHIPPING_METHOD]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.SET_ORDER_PAYMENT_METHOD]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.SET_ORDER_SHIPPING_AS_BILLING_ADDRESS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.RESET_ORDER_PAYMENT_METHOD]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.ADD_ORDER_PAYMENT_SOURCE_STRIPE]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.ADD_ORDER_PAYMENT_SOURCE_PAYPAL]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.UPDATE_ORDER_PAYMENT_SOURCE_PAYPAL]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.UPDATE_ORDER_PAYMENT_SOURCE_STRIPE]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.DELETE_ORDER_PAYMENT_SOURCE_STRIPE]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.UPDATE_ORDER_SHIPPING_ADDRESS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.UPDATE_ORDER_BILLING_ADDRESS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.DELETE_ORDER_BILLING_ADDRESS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.DELETE_ORDER_SHIPPING_ADDRESS]: (state, action) => {
    state.order = action.payload.order
  },

  [orderActionType.SET_ORDER_STATUS]: (state, action) => {
    const { order } = action.payload
    if (order && order.status) {
      state.order = order
    }
  },

  [orderActionType.CLEAR_ACTIVE_ORDER]: (state, action) => {
    state.order = null
  },

  [orderActionType.CLEAR_PLACED_ORDER]: (state, action) => {
    const { order } = action.payload
    // When order is transitioned to placed clear draft order, at this point
    // checkout is completed.
    if (order && order.status === 'placed') {
      state.order = null
    }
  },

  /**
   * Set customer type.
   * @param {InitialCommerce} state
   * @param {SetCustomerTypeAction} action
   */
  [customerActionType.SET_CUSTOMER_TYPE]: (state, action) => {
    state.customer.customerType = action.payload.customerType
  },

  [orderActionType.SET_ORDER_CUSTOMER]: (state, action) => {
    state.order = action.payload.order
  },
})

export default commerce

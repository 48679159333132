import React, { useMemo } from 'react'
import { GridContainer } from '@ggs/components/layout'
import { Divider, Title } from '@ggs/components/paragraphs'
import { forEach, sortBy, uniqueId } from 'lodash'
import Box from '@mui/material/Box'
import { common, typography } from '@ggs/styles'
import { Button } from '@ggs/gatsby/components/nav'
import { useI18n } from '@ggs/gatsby/lib'
// Assets
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { rem } from '@ggs/styles'

/**
 * @typedef {import('@ggs/types').DrupalFile} DrupalFile
 */

/**
 * @typedef {Object} HeroProps
 * @property {String=} title
 * @property {Array<DrupalFile>=} hcpClinicalResources
 */

/**
 * Hero component.
 * @param {HeroProps} props
 * @return {JSX.Element}
 */
export default function HcpClinicalResources({ title = '', hcpClinicalResources }) {
  const { t } = useI18n()
  const fileCategories = useMemo(() => {
    /** @type {Object<Array<DrupalFile>, any>} */
    const categories = {}
    /** @type {Array<{name: String, content: JSX.Element}>} */
    const rendered = []
    hcpClinicalResources.forEach((file) => {
      const category = file?.category?.[0]?.name || ''
      if (!categories[category]) {
        categories[category] = []
      }
      categories[category].push(file)
    })

    // Now process files into lists
    forEach(categories, (category, name) => {
      rendered.push({
        name,
        content: (
          <Box
            key={uniqueId(`category_${name}`)}
            sx={{
              '& .title, & .hcpClinicalResources__items': {
                borderBottom: common.borderLightGrey,
                py: {
                  xs: 2,
                  md: 4,
                },
              },
              '& .hcpClinicalResources__items': {
                pt: {
                  xs: name ? 2 : 0,
                  md: name ? 4 : 2,
                },
              },
              '& .hcpClinicalResources__item': {},
              '& .hcpClinicalResources__item-label': {
                typography: typography.pBold,
                mt: 0,
              },
              '& .button': {
                p: '0 !important',
              },
            }}
          >
            {name ? <Title title={name} style="h3" /> : <Divider sx={{ pt: { xs: 2, md: 4 } }} />}
            <div className="hcpClinicalResources__items">
              {category.map((/** @type DrupalFile */ file) => (
                <div className="hcpClinicalResources__item" key={file.id}>
                  <Title title={file.name} style="h4" sx={{ m: 0, mb: 1, p: 0 }} />
                  {file.summary && (
                    <div
                      className="hcpClinicalResources__item-summary"
                      dangerouslySetInnerHTML={{ __html: file.summary }}
                    />
                  )}
                  <Button
                    link={{ uri: file.url }}
                    endIcon={<ChevronRightIcon />}
                    variant={Button.variant.text}
                    aria-label={t('global:button.downloadFile', { fileName: file.name })}
                  >
                    {t('global:button.download')}
                  </Button>
                </div>
              ))}
            </div>
          </Box>
        ),
      })
    })

    return sortBy(rendered, ['name'])
  }, [hcpClinicalResources])

  // eslint-disable-next-line prefer-rest-params
  // console.log('HcpClinicalResources render', arguments[0], fileCategories) //, JSON.stringify(arguments[0]))

  return (
    <GridContainer
      className="hcpClinicalResources"
      sx={{
        borderTop: common.borderLightGrey,
        mt: {
          xs: `${rem(16)} !important`,
          md: `${rem(40)} !important`,
        },
        pt: {
          xs: `${rem(16)} !important`,
          md: `${rem(40)} !important`,
        },
        '& .hcpClinicalResources__title, & .title__title': {
          mt: 0,
        },
      }}
    >
      <>
        <Title className="hcpClinicalResources__title" title={title} style="h2" />
        {fileCategories.map(({ name, content }) => content)}
      </>
    </GridContainer>
  )
}

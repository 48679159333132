// @ts-ignore
import { colors, common, zIndex } from '@ggs/styles'
import { useMemo } from 'react'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 * @typedef {import("../Tab").TabProps} TabProps
 */
/**
 * @param {Array<TabProps>=} tabs
 * @return {SxProps}
 */
export default function useTabSelectorsSx(tabs) {
  return useMemo(
    () => ({
      zIndex: zIndex.zOverlay,
      borderBottom: 0,
      mb: '-1px',
      // borderColor: 'divider',
      width: '100%',
      '> div': {
        flexBasis: '100%',
      },
      '& .MuiTabs-flexContainer': {
        justifyContent: tabs.length < 4 ? 'space-evenly' : 'space-between',
      },
      '& .MuiButtonBase-root': {
        backgroundColor: colors.lightBlue,
        border: `${common.borderLightBlue} !important`,
        borderBottom: 0,
        borderRadius: '4px 4px 0px 0px',
        textTransform: 'none',
        color: colors.black,
        py: 1.5,
        flexBasis: `${(100 / tabs.length) * 0.9}%`,
      },
      '& .Mui-selected': {
        border: 0,
        color: `${colors.black} !important`,
        backgroundColor: colors.white,
        borderBottom: `${common.borderWhite} !important`,
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    }),
    [tabs]
  )
}

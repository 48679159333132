import { useMemo } from 'react'
import { colors, typography } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * Heading Styles
 * @enum {string}
 */
export const SiteNoticeVariant = {
  siteWide: 'siteWide',
  belowMenu: 'belowMenu',
  modal: 'modal',
}

/**
 * @param {string} variant
 * @param {SxProps} sx
 * @return {{sx:SxProps, backgroundColor:string}}
 */
export default function useSiteNoticeSx(variant, sx) {
  // @ts-ignore
  return useMemo(() => {
    const styles = {
      textAlign: 'center',
      typography: typography.menuItemSmall,
      position: 'relative',
      '.site-notice__label': {
        maxWidth: {
          xs: '75vw',
          md: '80vw'
        },
        margin: '0 auto',
      },
      '.site-notice__label *': {
        p: 0,
        m: 0,
      },
      '.site-notice__close.button.MuiButton-root.MuiButton-text': {
        position: 'absolute',
        right: 0,
        top: 2,
        px: 0,
        py: '12px !important',
      },
    }
    let backgroundColor
    switch (variant) {
      case SiteNoticeVariant.siteWide:
        backgroundColor = colors.gold
        Object.assign(styles, {
          p: 2,
        })
        break
      case SiteNoticeVariant.belowMenu:
        backgroundColor = colors.ultraLightRed
        Object.assign(styles, {
          p: 1,
          '.site-notice__close': {
            ...styles['.site-notice__close'],
            py: 1,
          },
        })
        break
    }

    return {
      sx: {
        ...styles,
        ...(sx || {}),
      },
      backgroundColor,
    }
  }, [sx, variant])
}

// @ts-nocheck
import { Memoized } from '@ggs/components/common'
import { useUserPermissions } from '@ggs/components/hooks'
import icons from '@ggs/components/icons'
import { GridContainer } from '@ggs/components/layout'
import HeaderSelectors from '@ggs/components/layout/Header/HeaderSelectors'
import { MainMenu, PreferredStore, SiteNotice } from '@ggs/components/nav'
import { RawImage } from '@ggs/gatsby/components/media'
import { Link } from '@ggs/gatsby/components/nav'
import { usePreferredStoreSettings } from '@ggs/gatsby/lib'
import { useWindowSize } from '@ggs/hooks'
import { useSelector } from '@ggs/store'
import { colors } from '@ggs/styles'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useMemo, useRef } from 'react'
// Assets
import './Header.scss'
import HeaderActions from './HeaderActions'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 * @typedef {import('@ggs/types').Store} Store
 * @typedef {import('@ggs/types').CustomerGroup} CustomerGroup
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */

/**
 *
 * @param {{layoutMeta: LayoutMeta, checkout: boolean}} props
 * @return {JSX.Element}
 */
export default function Header({
  checkout,
  layoutMeta,
  sitewideAnnouncement,
  sitewideAnnouncementStyle,
  hcpChangeProfile
}) {
  const windowSizes = useWindowSize()
  const headerMainRef = useRef()
  const { t } = useTranslation()


  // Obtain the user permissions for HCP.
  const {
    hcp: { shouldGateHcpContent },
  } = useUserPermissions()

  // Obtain store data
  const {
    currentStore = {},
    menus: { main: menuLinks, socialLinks } = {}
  } = useMemo(
    () => layoutMeta || {},
    [layoutMeta]
  )
  const email = useMemo(() => currentStore?.officeEmail, [currentStore?.officeEmail])
  const siteLogo = useMemo(
    () => (
      <RawImage imageData={currentStore?.logo}
        style={{ minWidth: '184px', minHeight: '48px' }}/>
    ),
    [currentStore?.logo]
  )

  // Prepare data for memoized components
  const preferredStoreSettings = usePreferredStoreSettings()
  const clearedNotices = useSelector((state) => state.settings.clearedNotices)

  console.log('layoutMeta', layoutMeta)

  return (
    <header className="header">
      <Memoized
        deps={[sitewideAnnouncement, clearedNotices]}>
        {!checkout && (
          <SiteNotice
            label={sitewideAnnouncement}
            variant={sitewideAnnouncementStyle ?? SiteNotice.variant.siteWide}
          />
        )}
      </Memoized>
      <PreferredStore preferredStoreSettings={preferredStoreSettings} layoutMeta={layoutMeta}/>
      {/* Only show when above mobile here */}
      {windowSizes.md && <HeaderSelectors/>}
      <div className="header__main" ref={headerMainRef}>
        <GridContainer
          sx={{ pt: { xs: 2, md: 3 }, pb: { xs: 2, md: 3 } }}
          fullWidthBackground
          backgroundColor={colors.white}
        >
          <div className="header__inner">
            <div className="header__logo">
              <Link href="/">{siteLogo || icons.TrudellLogo}</Link>
            </div>
            <MainMenu
              menuItems={menuLinks}
              email={email}
              socialLinks={socialLinks}
              headerRef={headerMainRef}
            />
            <HeaderActions {...layoutMeta} />
          </div>
        </GridContainer>
      </div>
      <Memoized
        deps={[hcpChangeProfile, clearedNotices, shouldGateHcpContent]}
      >
        {shouldGateHcpContent && !checkout && (
          <SiteNotice
            label={hcpChangeProfile}
            variant={SiteNotice.variant.belowMenu}
            modalActive={true}
          />
        )}
      </Memoized>
    </header>
  )
}

// @ts-nocheck
import { MutableRefObject, useEffect } from 'react'
import { debounce, defer, isFunction, uniqueId } from 'lodash'
// Hook
import { getWindow } from '@ggs/utils'
import { breakpoints } from '@ggs/styles'

const listeners = {}

/**
 * @typedef {Object} AsyncGetterData
 * @property {Array<Object>} results Getter results
 */

/**
 * @typedef {Object} useMatchHeight
 * @property {MutableRefObject<any|undefined>} ref React object reference
 * @property {AsyncGetterData=} asyncGetter Async getter function
 * @property {Array<Object>|Boolean=} resultList List of results
 * @property {String|function(number)} target Elements query selector
 * @property {String} cleanTargets Elements query selector
 * @property {number=} activeAboveBreakpoint Above width size breakpoint
 * @property {Object=} stateChange Tracking state changes
 */
/**
 * Match height hook.
 * @param {useMatchHeight} props

 */
function useMatchHeight({
  ref,
  asyncGetter = {},
  resultList = [],
  target = '',
  cleanTargets = '',
  stateChange,
  activeAboveBreakpoint = breakpoints.md,
}) {
  const win = getWindow()

  /**
   * Method to dig into the article card content div to resize heights.
   */
  const matchHeights = debounce(() => {
    const { current = null } = ref
    const currentTarget = isFunction(target) ? target(win.innerWidth) : target
    const entries = current?.querySelectorAll(currentTarget)
    let height = 0

    if (win.innerWidth > activeAboveBreakpoint && entries?.length) {
      const body = document.querySelector('body')

      // First reset to assess new heights.
      entries.forEach((card) => {
        card.style.height = 'auto'
      })
      // If there were some fallback targets, clean them too
      cleanTargets
      && current.querySelectorAll(cleanTargets).forEach((card) => {
        card.style.height = 'auto'
      })
      // Check for new max
      entries.forEach((card) => {
        if (card.offsetHeight > height) {
          height = card.offsetHeight
        }
        if (!card.offsetHeight) {
          const clone = card.cloneNode(true)
          clone.id = 'matchHeightEl'
          body.appendChild(clone)
          if (clone.offsetHeight > height) {
            height = clone.offsetHeight
          }
          // console.log('matchHeights::clone height', clone.offsetHeight)
          body.removeChild(clone)
        }
      })
      // If found, update, else ignore.
      if (height) {
        entries.forEach((card) => {
          card.style.height = `${height}px`
        })
      }
    } else {
      entries?.forEach((card) => {
        card.style.height = 'auto'
      })
    }
    // console.log('matchHeights::cards', { currentTarget, height, entries })
  }, 10)

  /**
   * Run as the gridItems are updated in state.
   */
  useEffect(() => {
    const eventName = `resize.${uniqueId(`match-height-${target}`)}`

    // Only run if we have targets
    if (target) {
      // Only run once (or on resize), once we have components rendered.
      if (!listeners[eventName] && (asyncGetter.result || resultList)) {
        listeners[eventName] = true
        defer(matchHeights)
        win.addEventListener('resize', matchHeights)
        // console.log('useMatchHeight::addEventListener', eventName)
      } else {
        console.warn('useMatchHeight::no targets found', { target, resultList })
      }
    }

    return () => {
      delete listeners[eventName]
      win.removeEventListener('resize', matchHeights)
    }
  }, [asyncGetter, resultList, stateChange])
}

export default useMatchHeight

// @ts-nocheck
/* eslint-disable no-use-before-define */
// import { isFunction, isObject, has, defer } from 'lodash'
import { defer, sampleSize, sortBy, uniqBy } from 'lodash'
import getWindow from '../getWindow'
import StaticList from './StaticList'
import { getSearchParams } from '@ggs/utils'

/**
 * Gatsby articles nodes static list that provide index by node properties for fast lookup.
 */
class ArticleList extends StaticList {
  categories = []

  /**
   * List constructor.
   * @param {Object} config List configuration settings.
   */
  constructor(config= {}) {
    super(config)

    // Ensure to sort articles by date for convenience. Handled in GraphQL already!
    // this.defaultSort = (all) => sortBy(all, 'timestamp')

    // Add category index
    if (!this.list.byCategory) {
      this.list.byCategory = {}
    }
  }

  /**
   * Overriding the base method to add additional indexes.
   * @param {array<any>} nodes Array of node objects.
   * @param {function} transformMethod Node data transformation function.
   */
  parse(nodes, transformMethod) {
    super.parse(nodes, transformMethod)

    // Additional indexing against blog specific aspects.
    defer(() => {
      this.indexByCategory()
    })
  }

  /**
   * Index Gatsby nodes by category.
   */
  indexByCategory() {
    const { list, categories } = this

    list.all.forEach((item) => {
      const { primeCategory } = item
      const { slug } = primeCategory

      // If the category isn't captured yet, add it to the index.
      if (!list.byCategory[slug]) {
        primeCategory.searchUrl = `/resources/search?category=${slug}`
        categories.push(primeCategory)
        list.byCategory[slug] = []
      }

      // Now add this post to
      list.byCategory[slug].push(item)
    })
  }

  /**
   * Method to retrieve the featured article.
   * @return {Promise<Object>}
   */
  getFeaturedArticle() {
    return singleton.promise('getFeaturedArticle', () => {
      // Articles are auto-sorted as latest to oldest, so grab the latest as featured.
      return singleton.list.all[0]
    })
  }

  /**
   * Method to retrieve the latest article of a set limit.
   * @param {number} limit Max number of articles
   * @return {Promise<Array>} Job process with max time execution control
   */
  getLatestArticles(limit = 3) {
    return singleton.promise('getLatestArticles', () => {
      // As the first article is always the latest for featured article, slice against the
      // remaining.
      return singleton.list.all.slice(1, limit + 1)
    })
  }

  /**
   * Method to retrieve related articles, by category, of a set limit.
   * @param {string} slug Category key.
   * @param {number} limit Max nodes items to retrieve.
   * @return {Promise<Array>} Job process with max time execution control
   */
  getRelatedArticles(slug = '', limit = 4) {
    const { list } = singleton

    return singleton.promise('getRelatedArticles', () => {
      // Get random articles from the same category by limit amount.
      return sampleSize(list.byCategory[slug], limit)
    })
  }

  /**
   * Method to retrieve article categories.
   * @return {Promise<Array|Object>} Job process with max time execution control
   */
  getCategories() {
    const { categories } = singleton

    return singleton.promise('getCategories', () => {
      return categories
    })
  }

  /**
   * Method to retrieve a category by it's slug.
   * @param {string} categorySlug Category key
   * @return {Promise<Array|Object>} Job process with max time execution control
   */
  getCategoryBySlug(categorySlug = '') {
    const { categories } = singleton

    return singleton.promise('getCategoryBySlug', () => {
      // console.log(`getCategoryBySlug(${categorySlug})`, categories)
      return categories.find(({ slug }) => slug === categorySlug)
    })
  }

  /**
   * Method to retrieve latest article by category
   * @param {string} slug Category key
   * @return {Promise<Object>} Job process with max time execution control
   */
  getFeaturedArticleByCategory(slug = '') {
    const { list } = singleton

    return singleton.promise('getFeaturedArticleByCategory', () => {
      // Articles are auto-sorted as latest to oldest, so grab the latest as featured.
      return list.byCategory[slug][0]
    })
  }

  /**
   * Method to retrieve latest articles, by category, of a set limit
   * @param {string} slug Category key
   * @param {number} limit Max number of node items to get
   * @return {Promise<Array>} Job process with max time execution control
   */
  getLatestArticlesByCategory(slug = '', limit = 4) {
    const { list } = singleton

    return singleton.promise('getLatestArticlesByCategory', () => {
      // As the first article is always the latest for featured article, slice against the
      // remaining.
      return list.byCategory[slug].slice(1, limit + 1)
    })
  }

  /**
   * Method to retrieve all articles from a given category.
   * @param {string} slug Category key
   * @return {Promise<Array>} Job process with max time execution control
   */
  getAllByCategory(slug = '') {
    const { list } = singleton

    return singleton.promise('getAllByCategory', () => {
      return list.byCategory[slug]
    })
  }

  /**
   * Method to retrieve all articles matching a keyword, optionally from a specific category.
   * @param {string} keywords Search keywords
   * @param {string} category Category name
   * @return {Promise<Array>} Job process with max time execution control
   */
  searchArticles(keywords = '', category = '') {
    const { list } = singleton
    // Try and obtain the category list if found, else us all articles.
    const filterList = list.byCategory[category] || list.all

    return singleton.promise('getAllByCategory', () => {
      const qs = getSearchParams()
      const keywordResult = String(keywords || qs.get('keywords') || '').replace(/(null)/gi, '')
      const query = {
        keywords: keywordResult ? new RegExp(keywordResult, 'ig') : null,
        category: String(category || qs.get('category') || '')
          .toLowerCase()
          .replace(/(null)/gi, ''),
      }

      const filtered = filterList.filter((item) => {
        // Return only articles matching the keyword in one of the relevant fields.
        return (
          (!query.category || item.primeCategory.slug === query.category)
          && (!query.keywords
            || item.title.search(query.keywords) !== -1
            || item.content.search(query.keywords) !== -1
            || item.excerpt.search(query.keywords) !== -1)
        )
      })

      // console.log(':getAllByCategory processing', {
      //     filterList,
      //     filtered,
      //     queryString: qs,
      //     keywords,
      //     category,
      //   })

      // Now, strip results down to unique entries, and sort by timestamp.
      return sortBy(uniqBy(filtered, 'alias'), ['timestamp']).reverse()
    })
  }
}

const singleton = Object.freeze(new ArticleList())
export default singleton

// @ts-nocheck
import { useDispatch } from 'react-redux'
import { setCustomerType, useSelector } from '@ggs/store'
import { useEffect } from 'react'

const defaultTID = `${process.env.GATSBY_DEFAULT_CUSTOMER_GROUP_ID}`
const findCustomerGroupByTaxId = ({
  customerGroups,
  tid
}) => customerGroups?.find((customerGroup) => String(customerGroup?.tid) === String(tid))
export const useCustomerGroupSelect = ({ customerGroups }) => {
  const dispatch = useDispatch()
  const customerType = useSelector((state) => state.commerce.customer.customerType)

  const currentCustomerGroup = findCustomerGroupByTaxId({ customerGroups, tid: customerType })
  const defaultCustomerGroup = findCustomerGroupByTaxId({ customerGroups, tid: defaultTID })

  useEffect(() => {
    // Default ot first customer group when none is set.
    if (!currentCustomerGroup) {
      dispatch(setCustomerType(defaultCustomerGroup?.tid))
    }
  }, [currentCustomerGroup])

  const selectCustomerGroup = (tid) => {
    const customerGroup = findCustomerGroupByTaxId({ customerGroups, tid: tid })
    dispatch(setCustomerType(customerGroup?.tid ?? defaultCustomerGroup))
    // dispatch(delay(() => {
    //   // Display a notice of the change.
    //   addNotification(
    //     customerActionType.SET_CUSTOMER_TYPE,
    //     'success',
    //     t('global:nav.label.customerTypeSelected', { customerType: name })
    //   )
    // }, 200))
  }
  return {
    customerGroups,
    currentCustomerGroup,
    selectCustomerGroup
  }

}
import getGlobals from './getGlobals'
import { find } from 'lodash'
import { useLayoutMeta } from '@ggs/hooks'

const { LANGUAGES } = getGlobals()
const netlifyProxyUrl = `${process.env.GATSBY_NETLIFY_PROXY}`
/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 */
/**
 * @typedef {Object} useLinkProps
 * @property {LinkField=} link
 * @property {String=} href
 */
/**
 * A method to help clean up link fields to align with the current store/language.
 * @param {boolean=} enforceLanguage If enabled, it will check for the provided URI against the current language.
 * @param {boolean=} enforceStore If enabled, it will check for the provided URI against the current store.
 * @return {function(*): {isInternal: boolean, isExternal: boolean, label: any, uri: *, isAssetLink: boolean}}
 */
export default function useLink(enforceLanguage = false, enforceStore = true) {
  const layoutMeta = useLayoutMeta()
  const {
    menus: { supporting = {} } = {},
    currentStore = { storeAlias: '' },
    lang,
  } = layoutMeta || {}

  // console.log('useLink', {
  //   currentStore,
  //   supporting,
  //   lang,
  // })

  /**
   * @param {useLinkProps} props
   * @return {{isInternal: boolean, isExternal: boolean, label: any, uri: string, isAssetLink: boolean, storeAlias:
   *   string, currentLang: string}}
   */
  return (props) => {
    const { link, href = '' } = props
    const label = link?.title || ''
    // If the link has the product listing path ENV, we need to replace it with the real one.
    let uri = String(href || link?.url || link?.uri || '')
    uri = uri
      // @ts-ignore
      .replace(
        'PRODUCT_LISTING_ALIAS',
        layoutMeta.listing?.productListingAlias?.uri ??
          layoutMeta?.menus?.supporting?.orderProcessing?.productListingAlias?.uri ??
          'products'
      )
      .replace('internal:', '')

    const [, uriLang] = uri.split('/')
    const isInternal = uri.indexOf('/') === 0
    const isExternal = uri.indexOf('http') === 0
    const isAssetLink = /\/assets\/|\/static\//.test(uri)
    const isFileLink = /\/files\//.test(uri)
    const [name, fileExt] = isFileLink ? uri.split('/').slice(-1)[0].split('.') || [] : []
    const fileName = decodeURI(name)
    const pathName = window?.location?.pathname || ''
    const defaultStorePath = currentStore?.storeAlias ? `/${currentStore.storeAlias}/${lang}` : ''
    const [, storeAlias = 'global', currentLang = 'en-CA'] =
      pathName === '/' ? defaultStorePath.split('/') : pathName.split('/')

    // If the link has a embedded langauge from drupal, but it doesn't match the current langauge, disable the link.
    if (
      enforceLanguage &&
      isInternal &&
      uriLang !== lang &&
      find(LANGUAGES, ['langCode', uriLang])
    ) {
      uri = `/${storeAlias}/${currentLang}/404?page=${uri.replace('/' + uriLang, '')}`
    } else {
      // Correct internal links.
      if (isInternal && !isAssetLink) {
        // if (preferredStoreSettings && uri.indexOf(preferredStoreSettings?.preferredLanguage) === -1) {
        //   // If language is missing, add it.
        //   uri = `/${preferredStoreSettings.preferredStorePath}${uri}`
        // }

        if (storeAlias && enforceStore) {
          // If current language is missing, add it.
          if (uri.indexOf(`/${currentLang}/`) === -1) {
            uri = `/${storeAlias}/${currentLang}${uri}`
          }
          // If store alias is missing, add it.
          else if (uri.indexOf(`/${storeAlias}/`) !== 0) {
            uri = `/${storeAlias}${uri}`
          }
        }
      }
    }
    uri = uri.replace(/\/\//g, '/').replace('http:/', 'http://').replace('https:/', 'https://')
    // console.log('useLink', storeAlias, currentLang, uri, { isInternal, isExternal, isAssetLink })

    return {
      isInternal,
      isExternal,
      isFileLink,
      fileName,
      fileExt,
      uri,
      href,
      label,
      isAssetLink,
      storeAlias,
      currentLang,
    }
  }
}

import React, { useEffect, useState } from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import { getSearchParams, whenPressingEnter } from '@ggs/utils'
// Assets.
import './SearchInput.scss'
import useSearchInputSx from './useSearchInputSx'
import { icons } from '@ggs/components'

/**
 * @typedef {Object} FaqSearchProps
 * @property {{placeholder: String, ariaLabel: String}} labels
 * @property {String=} searchQuery
 * @property {function=} onSubmit
 */
/**
 * SearchInput component.
 * @param {FaqSearchProps} props
 * @return {JSX.Element}
 */
export default function SearchInput({ labels, searchQuery, onSubmit, ...props }) {
  const { t } = useI18n()
  const sx = useSearchInputSx()

  // Local state for input label
  const [keywords, setKeywords] = useState(() => {
    // @ts-ignore
    const qs = getSearchParams(props?.location?.search)
    return qs?.get('search') || ''
  })
  // @ts-ignore
  const onChange = (e) => setKeywords(e.target.value)

  // Reset action, send blank string to onSubmit
  const resetSearch = () => {
    setKeywords('')
    onSubmit('')
  }

  // Update keywords.
  const updateKeywords = () => onSubmit(keywords.toLowerCase())

  return (
    <Paper className="search-input" sx={sx}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={labels.placeholder}
        inputProps={{ 'aria-label': labels.ariaLabel }}
        onChange={onChange}
        onKeyDown={(e) => whenPressingEnter(e, updateKeywords)}
        value={keywords}
      />
      {keywords.length > 0 && (
        <IconButton
          className="search-input__reset"
          sx={{ p: '10px' }}
          aria-label={t('global:button.resetFilters')}
          onClick={resetSearch}
        >
          {' '}
          {icons.CloseBlack}
        </IconButton>
      )}
      <IconButton
        className="search-input__submit"
        sx={{ p: '10px' }}
        aria-label={t('global:label.search')}
        onClick={updateKeywords}
      >
        {icons.ArrowRight}
      </IconButton>
    </Paper>
  )
}

/**
 * Commerce action types.
 * @enum {string}
 */

export const checkoutActionsType = {
  SET_CHECKOUT_STATE: 'SET_CHECKOUT_STATE',
  SET_CHECKOUT_CURRENT_STEP: 'SET_CHECKOUT_CURRENT_STEP',
  SET_CHECKOUT_STEPS_COMPLETE: 'SET_CHECKOUT_STEPS_COMPLETE',
  RESET_CHECKOUT_STEPS_COMPLETE: 'RESET_CHECKOUT_STEPS_COMPLETE',
  SET_CHECKOUT_ACTIVE_ORDER_ID: 'SET_CHECKOUT_ACTIVE_ORDER_ID',
  RESET_CHECKOUT_EXCEPT_SHIPPING_ADDRESS: 'RESET_CHECKOUT_EXCEPT_SHIPPING_ADDRESS',
  RESET_CHECKOUT_STATE: 'RESET_CHECKOUT_STATE',
  SET_LAST_ORDER_ID: 'SET_LAST_ORDER_ID',
  SET_PAYPAL_STATUS: 'SET_PAYPAL_STATUS',
  CLEAR_PAYPAL_STATUS: 'CLEAR_PAYPAL_STATUS',
  SET_PAYPAL_META: 'SET_PAYPAL_META',
  CLEAR_PAYPAL_META: 'CLEAR_PAYPAL_META',
  SET_TAX_CALC: 'SET_TAX_CALC'
}
/**
 * @typedef {import('../initialState').DefaultCheckout} DefaultCheckout
 * @typedef {import('@ggs/commercelayer').Order} Order
 * @typedef {DefaultCheckout['stepsComplete']} DefaultCheckoutStepsComplete
 * @typedef {DefaultCheckout['currentStepName']} DefaultCheckoutCurrentStep
 * @typedef {DefaultCheckout['currentStepperIndex']} DefaultCheckoutCurrentStepperIndex
 * @typedef {DefaultCheckout['checkoutState']} DefaultCheckoutState
 */

/**
 * Set checkout state
 * @typedef {Object} setCurrentStepParams
 * @property {string} currentStepName
 * @property {number} currentStepperIndex
 * @return {SetCheckoutCurrentStepAction}
 */
/**
 * Set checkout state
 * @typedef {Object} SetCheckoutActiveOrderIdAction
 * @property {{id: string}} order
 * @property {number} currentStepperIndex
 * @return {SetCheckoutCurrentStepAction}
 */

/**
 * @typedef {Object} CheckoutStatePayload
 * @property {DefaultCheckout}  checkout
 */

/**
 * @typedef {Object} CheckoutCurrentStepPayload
 * @property {string}  currentStepName
 * @property {number}  currentStepperIndex
 */

/**
 * @typedef {Object} SetCheckoutStepsCompleteAction
 * @property {checkoutActionsType} type Action type key
 * @property {DefaultCheckoutStepsComplete} payload Action input data
 */

/**
 * @typedef {Object} SetCheckoutStateAction
 * @property {checkoutActionsType} type Action type key
 * @property {{checkoutState: DefaultCheckoutState}} payload Action input data
 */

/**
 * @typedef {Object} SetCheckoutCurrentStepAction
 * @property {checkoutActionsType} type Action type key
 * @property {setCurrentStepParams} payload Action input data
 */

/**
 * @typedef {Object} SetLastOrderId
 * @property {checkoutActionsType} type Action type key
 * @property {string} payload Action input data
 */

/**
 * @typedef {Object} parsedOrderCheckout
 * @property {DefaultCheckoutState} checkoutState type key
 */

/**
 * @typedef {Object} parsedOrderStepsComplete
 * @property {DefaultCheckoutStepsComplete} stepsComplete Action type key
 */

/**
 * @typedef {Object} ResetCheckoutAction
 * @property {checkoutActionsType} type Action type key
 * @property {CheckoutStatePayload} payload Action input data
 */

/**
 * Set checkout state
 * @param {DefaultCheckoutStepsComplete} stepsComplete
 * @return {SetCheckoutStepsCompleteAction}
 */
export const setCheckoutStepsComplete = (stepsComplete) => {
  return {
    type: checkoutActionsType.SET_CHECKOUT_STEPS_COMPLETE,
    payload: stepsComplete,
  }
}

/**
 * Set checkout state
 * @param {parsedOrderCheckout} parsedOrder
 * @return {SetCheckoutStateAction}
 */
export const setCheckoutState = (parsedOrder) => {
  return {
    type: checkoutActionsType.SET_CHECKOUT_STATE,
    payload: parsedOrder,
  }
}

/**
 * Set checkout state
 * @param {{shipping_address: any}} parsedOrder
 * @return {SetCheckoutStepsCompleteAction}
 */
export const resetCheckoutStateExceptShippingAddress = ({ shipping_address }) => {
  return {
    type: checkoutActionsType.RESET_CHECKOUT_EXCEPT_SHIPPING_ADDRESS,
    payload: shipping_address,
  }
}

/**
 * Set checkout state
 * @param {setCurrentStepParams} currentStep
 * @return {SetCheckoutCurrentStepAction}
 */
export const setCurrentStep = (currentStep) => {
  return {
    type: checkoutActionsType.SET_CHECKOUT_CURRENT_STEP,
    payload: currentStep,
  }
}

/**
 * Set checkout state
 * @param {setCurrentStepParams} currentStep
 * @return {SetCheckoutCurrentStepAction}
 */
export const setTaxCalc = ({ tax_calculations_count = 0 } = {}) => {
  return {
    type: checkoutActionsType.SET_TAX_CALC,
    payload: tax_calculations_count,
  }
}


/**
 * reset checkout state
 * @return {SetCheckoutStepsCompleteAction}
 */
export const resetCheckoutState = () => {
  return {
    type: checkoutActionsType.RESET_CHECKOUT_STEPS_COMPLETE,
    payload: null,
  }
}
/**
 * Set checkout state
 * @param {Order} order
 * @return {SetLastOrderId}
 */
export const setLastOrderId = ({ id }) => {
  return {
    type: checkoutActionsType.SET_LAST_ORDER_ID,
    payload: id,
  }
}

export const setPaypalMetadata = ({
  PayerID = null,
  responseCode = null,
  paypalStatus = null,
} = {}) => {
  return {
    type: checkoutActionsType.SET_PAYPAL_META,
    payload: {
      PayerID,
      responseCode,
      paypalStatus,
    },
  }
}

export const clearPaypalMetadata = () => {
  return setPaypalMetadata()
}

export const updatePaypalStatus = ({ paypalStatus = null } = {}) => {
  return {
    type: checkoutActionsType.SET_PAYPAL_STATUS,
    payload: {
      paypalStatus,
    },
  }
}

export const clearPaypalStatus = () => {
  return updatePaypalStatus()
}
/**
 * set active orderId
 * @param {string|null=} id
 * @return {SetCheckoutActiveOrderIdAction}
 */
// export const setCheckoutActiveOrderId = ({ id }) => {
//   return {
//     type: checkoutActionsType.SET_CHECKOUT_ACTIVE_ORDER_ID,
//     payload: id,
//   }
// }

/**
 * Commerce action types.
 * @enum {string}
 */
export const commerceActionType = {
  CLEAR_ORDER_STATE: 'CLEAR_ORDER_STATE',
}

/**
 * @typedef {Object} ClearOrderStatePayload
 * @property {null}  order
 */

/**
 * @typedef {Object} ClearOrderState
 * @property {commerceActionType} type Action type key
 * @property {ClearOrderStatePayload} payload Action input data
 */

/**
 * Set order state property to null.
 *
 * @return {ClearOrderState}
 */
export const clearOrderState = () => {
  return {
    type: commerceActionType.CLEAR_ORDER_STATE,
    payload: {
      order: null,
    },
  }
}

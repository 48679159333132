import React, { useCallback, useState } from 'react'
import { useUserPermissions } from '@ggs/components/hooks/index'
import { HcpGatedModal } from '@ggs/components/banners'
import { isFunction } from 'lodash'

/**
 * @typedef {Object} triggerHcpActionProps
 * @property {Number=} targetId
 * @property {string=} targetValue
 */

/**
 * @typedef {Object} HcpGatedActionProps
 * @property {function=} resolve
 * @property {function=} reject
 */

/**
 *
 * @param {HcpGatedActionProps} props
 * @return {{triggerHcpAction: (function(triggerHcpActionProps): boolean), shouldTriggerModal: (function(Number)),
 *   modal: JSX.Element}}
 */
export default function useHcpGatedAction({ resolve, reject } = {}) {
  const {
    customerRole,
    hcp: { shouldGateHcpContent, hcpCustomerRole, forceHcpRole },
  } = useUserPermissions()
  const [requireHcpGating, setRequireHcpGating] = useState('')

  /**
   *
   * @param {Number} targetId
   * @return {boolean}
   */
  const shouldTriggerModal = useCallback(
    (targetId) => shouldGateHcpContent && targetId === hcpCustomerRole,
    [shouldGateHcpContent, hcpCustomerRole]
  )

  /**
   *
   * @param {triggerHcpActionProps} props
   * @return {boolean}
   */
  const triggerHcpAction = ({ targetId, targetValue= 'true' }) => {
    const mustTriggerModal = shouldTriggerModal(targetId)

    // console.log('triggerHcpAction', {
    //   targetId,
    //   targetValue,
    //   mustTriggerModal,
    //   shouldGateHcpContent,
    //   hcpCustomerRole,
    //   forceHcpRole,
    // })

    if (mustTriggerModal) {
      setRequireHcpGating(targetValue)
    } else {
      isFunction(resolve) && resolve(targetValue)
    }

    // If we didn't trigger the modal, we can resolve immediately
    return !mustTriggerModal
  }

  /**
   * When the user confirms they are an HCP, close modal and set their user state.
   */
  const handleHcpGating = () => {
    forceHcpRole()
    resolve(requireHcpGating)
    setRequireHcpGating('')
  }

  /**
   * When the user declines to be an HCP, reset the modal.
   */
  const handleClose = () => {
    setRequireHcpGating('')
    isFunction(reject) && reject()
  }

  return {
    shouldTriggerModal,
    triggerHcpAction,
    modal: (
      <HcpGatedModal
        requireHcpGating={!!requireHcpGating}
        // After submit, will expose tab if HCP selected.
        onSubmit={handleHcpGating}
        // Do nothing on close, prevents accessing the tab
        onClose={handleClose}
      />
    ),
  }
}

import useOrderLabels from '@ggs/components/ecomm/Customer/useOrderLabels'
import { Title } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { first } from 'lodash'
import React from 'react'
import useCodeTranslationMap from '../../../hooks/useCodeTranslationMap'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} OrderStatusProps
 * @property {Order} order
 * @property {boolean=} showStatus
 */

/**
 * Checkout review title.
 * @param {OrderStatusProps} props
 * @return {JSX.Element}
 */
const OrderStatus = ({ order, showStatus = true }) => {
  const { t } = useI18n()
  const mapCodeToTranslation = useCodeTranslationMap()
  const orderLabels = useOrderLabels(order)

  if (!order) {
    return null
  }

  const shipments = first(order?.shipments)
  const shippingMethod = shipments?.shipping_method

  return (
    <Box>
      {showStatus ? (
        <Box
          display={'inline-flex'}
          alignItems={'baseline'}
          sx={{
            mb: 3,
          }}
        >
          <Typography
            variant={'h3'}>{t('ecomm:label.shippingStatus')}
          </Typography>
          {orderLabels.shippingMethod.fulfillmentStatus}
        </Box>
      ) : <Title title={t('ecomm:label.shippingMethod')} style="h3"
        sx={{ mb: 3 }}/>}
      {shippingMethod ? (
        <Box>
          <Typography
            variant={'body1'}
            sx={{
              fontWeight: !showStatus ? 'regular' : 'bold',
              // mb: 2,
            }}
          >
            {t('ecomm:label.shippingOption')}: {' '}
            {mapCodeToTranslation(orderLabels.shippingMethod.reference)}
          </Typography>
          <Typography
            variant={'body1'}
            sx={{
              fontWeight: !showStatus ? 'regular' : 'bold',
              // mb: 2,
            }}
          >
            {t('ecomm:label.shippingPriority')}: {orderLabels.shippingMethod.costLabel}
          </Typography>
          {orderLabels.shippingMethod.leadTime && (
            <Typography
              variant={'body1'}
              sx={{
                fontWeight: !showStatus ? 'regular' : 'bold',
                // mb: 2,
              }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: orderLabels.shippingMethod.leadTime }}/>
            </Typography>
          )}
        </Box>
      ) : null}
    </Box>
  )
}

export default OrderStatus

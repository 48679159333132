import Geonames from 'geonames.js'
import cache from 'memory-cache'
import { sortBy } from 'lodash'
const username = `${process.env.GATSBY_GEONAMES_USERNAME}`
const geonames = Geonames({
  username: username,
  lan: 'en',
  encoding: 'JSON',
})

/**
 * @typedef {Object} GeonamesCountry
 * @property {string}  continent
 * @property  {string} capital
 * @property  {string} languages
 * @property  {number} geonameId
 * @property  {number} south
 * @property  {string} isoAlpha3
 * @property  {number} north
 * @property  {string} fipsCode
 * @property  {string} population
 * @property  {number} east
 * @property  {string} isoNumeric
 * @property  {string} areaInSqKm
 * @property  {string} countryCode
 * @property  {number} west
 * @property  {string} countryName
 * @property  {string} postalCodeFormat
 * @property  {string} continentName
 * @property  {string} currencyCode
 */

/**
 * @typedef {Object} AdminCodes
 * @property {string} ISO3166_2
 */

/**
 * @typedef {Object} GeonamesState
 * @property  {string} adminCode1
 * @property  {string} lng
 * @property  {number} geonameId
 * @property  {string} toponymName
 * @property  {string} countryId
 * @property  {string} fcl
 * @property  {number} population
 * @property  {string} countryCode
 * @property  {string} name
 * @property  {string} fclName
 * @property  {AdminCodes} adminCodes1
 * @property  {string} countryName
 * @property  {string} fcodeName
 * @property  {string} adminName1
 * @property  {string} lat
 * @property  {string} fcode
 */

/**
 * @typedef {Object} GeonamesCountryInfo
 * @property {Array<GeonamesCountry>} geonames
 */

/**
 * @typedef {Object} GeonamesStateInfo
 * @property {number} totalResultsCount
 * @property {Array<GeonamesState>} geonames
 */

/**
 * @typedef {Object} RegionOption
 * @property {String} label Country name
 * @property {String} value Country iso2 code
 */

/**
 * @typedef {Array<RegionOption>} RegionOptionList
 */

/**
 * Get Geonames countries information.
 * @param {string} langCode Language code
 * @return {Promise<Array<GeonamesCountry>>}
 */
export async function getGeonamesCountryInfo(langCode = 'en') {
  /**
   * @return {Promise<Array<GeonamesCountry>>}
   */
  const getData = async () => {
    const cacheId = `geoname_countries_${langCode}`
    const cachedData = cache.get(cacheId)

    if (cachedData) {
      return cachedData
    }

    geonames.config.lan = langCode
    // eslint-disable-next-line no-debugger

    /**
     * @type {GeonamesCountryInfo}
     */
    const response = await geonames.countryInfo({})
    console.log('Retrieved countries from Geonames API')
    const data = response.geonames || []
    cache.put(cacheId, data)

    return data
  }

  return await getData()
}

/**
 * Get Geonames countries states information.
 * @param {number} geonameId Geoname country ID
 * @param {string} langCode Language code
 * @return {Promise<Array<GeonamesState>>}
 */
export async function getGeonamesStatesInfo(geonameId, langCode = 'en') {
  /**
   * @return {Promise<Array<GeonamesState>>}
   */
  const getData = async () => {
    const cacheKey = `geonames_country_states_${geonameId}_${langCode}`
    const cachedData = cache.get(cacheKey)

    if (cachedData) {
      return cachedData
    }

    /**
     * @type {GeonamesStateInfo}
     */
    const response = await geonames.children({ geonameId: geonameId })
    console.log('Retrieved country states from Geonames API')
    const data = response.geonames || []
    cache.put(cacheKey, data)

    return data
  }

  return await getData()
}

/**
 * Get countries options list.
 * @param {string} langCode Language code
 * @return {Promise<RegionOptionList>}
 */
export async function getCountriesOptions(langCode = 'en') {
  /**
   * @type {Array<GeonamesCountry>}
   */
  const countries = await getGeonamesCountryInfo(langCode)
  // console.log('Countries: ', countries)
  const countriesOptions = countries.map((country) => ({
    label: country.countryName,
    value: country.countryCode,
  }))

  return sortBy(countriesOptions, 'label')
}

/**
 * Get states options for a given country.
 * @param {String} countryCode Country iso2 code
 * @param {string} langCode Language code
 * @return {Promise<RegionOptionList>}
 */
export async function getCountryStateOptions(countryCode, langCode = 'en') {
  /**
   * @type {Array<GeonamesCountry>}
   */
  const countries = await getGeonamesCountryInfo(langCode)
  const currentCountry = countries.find((country) => country.countryCode === countryCode)

  /**
   * @type {Array<GeonamesState>}
   */
  const countryStates = await getGeonamesStatesInfo(currentCountry?.geonameId)
  const stateOptions = countryStates.map((countryState) => ({
    label: countryState.adminName1,
    value: countryState.adminCodes1?.ISO3166_2 || '',
  }))

  return sortBy(stateOptions, 'label')
}

import React from 'react'
import { ImageStyles, RawImage } from '@ggs/gatsby/components/media'
import Button from '@mui/material/Button'
import { Link } from '@ggs/gatsby/components/nav'
import MenuList from '@ggs/components/nav/MainMenu/MenuList'

// Assets.
import { icons } from '@ggs/components'
import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */
/**
 * @typedef {Object} MainItemProps
 * @property {Number=} id
 * @property {Number=} level
 * @property {Array<DrupalMenuLink>=} menuItems
 * @property {String=} label
 * @property {String=} url
 * @property {ImageStyles=} icon
 * @property {Boolean=} megaMenu
 * @property {String=} footerContent
 * @property {function} toggleDropdownMenu
 * @property {Boolean|Number=} dropdownOpen
 * @property {Number=} headerHeight
 */
/**
 * @param {MainItemProps} props
 * @return {JSX.Element}
 */
export default function MenuItem({
  id,
  level,
  menuItems,
  label,
  url,
  icon,
  megaMenu = false,
  footerContent = null,
  toggleDropdownMenu = null,
  dropdownOpen = false,
  headerHeight = null,
}) {
  return (
    <li
      className={
        menuItems
          ? `menu-item menu-item--level-${level} menu-item--has-submenu`
          : `menu-item menu-item--level-${level}`
      }
    >
      {!menuItems ? (
        <Link
          className="menu-item__link"
          href={url}
          data-label={label}
          data-action-type={'navigate-menu-link'}
          ignoreImpression
        >
          <>
            {icon && (
              <span className="menu-item__link-image">
                {/*<ImageStyles {...icon} selectedStyles={['menuFaqIcon']} loading={'eager'} />*/}
                <RawImage imageData={icon} imageStyle={'menuFaqIcon'} />
              </span>
            )}
            <span dangerouslySetInnerHTML={{ __html: label }} />
          </>
        </Link>
      ) : (
        <>
          <Button
            className={dropdownOpen === id ? 'menu-item__link dropdown-open' : 'menu-item__link'}
            onClick={() => {
              toggleDropdownMenu(dropdownOpen === id ? false : id)
              pushDataLayer({
                eventName: 'click',
                dataLayer: {
                  label,
                  action_type: 'navigate-menu-link',
                },
              })
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: label }} />
            {icons.ChevronDown}
          </Button>
          <div
            className={
              dropdownOpen === id || level > 0
                ? 'menu-item__content dropdown-open'
                : 'menu-item__content'
            }
          >
            {level === 0 && (
              <Button className="menu-item__back" onClick={() => toggleDropdownMenu(false)}>
                {icons.ChevronDown}
                <span dangerouslySetInnerHTML={{ __html: label }} />
              </Button>
            )}
            <MenuList
              megaMenu={megaMenu}
              level={level + 1}
              menuItems={menuItems}
              footerContent={footerContent}
              toggleDropdownMenu={toggleDropdownMenu}
              dropdownOpen={dropdownOpen}
              headerHeight={headerHeight}
            />
          </div>
        </>
      )}
    </li>
  )
}

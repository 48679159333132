// @ts-nocheck
import getWindow from './getWindow'

const win = getWindow()

/**
 * @param {number} adjusted Window top position.
 */
export const top = (adjusted = 0) => {
  win.scrollTo({
    top: adjusted,
    behavior: 'smooth',
  })
}

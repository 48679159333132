import { useDispatch } from 'react-redux'
import { setCustomerType, useSelector } from '@ggs/store'
import { useEffect, useMemo } from 'react'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { useSupportingLinks } from '@ggs/components/ecomm'
import { navigate } from 'gatsby'
import { useLayoutMeta } from '@ggs/hooks'

/**
 * If the user is already logged in, they shouldn't be here.
 */
export const useShouldUserBeOnDashboard = () => {
  const { customer } = useCheckoutContext()
  const siteLinks = useSupportingLinks()

  useEffect(() => {
    if (customer?.id) {
      console.log('User is already logged in. Redirecting to dashboard.', siteLinks.account)
      navigate(siteLinks.account)
    }
  }, [customer?.id])
}

/**
 * Contents of user permissions across the sitee.
 * @return {{customerRole: Number, isHcpUser: Boolean, setCustomerRole: setCustomerRole, hcp: {hcpCustomerRole: Number,
 *   shouldGateHcpContent: (number|*|boolean), forceHcpRole: forceHcpRole}}}
 */
export default function useUserPermissions() {
  const dispatch = useDispatch()
  const {
    currentStore: { hcpCustomerRole, hcpGatedContent },
  } = useLayoutMeta()
  const customerRole = useSelector((state) => state.commerce?.customer?.customerType)
  const isHcpUser = customerRole === hcpCustomerRole
  const shouldGateHcpContent = useMemo(
    () => customerRole && hcpGatedContent && !isHcpUser,
    [customerRole, hcpGatedContent, hcpCustomerRole]
  )

  /**
   *
   * @param {Number} tid
   */
  const setCustomerRole = (tid) => {
    tid && dispatch(setCustomerType(tid))
  }

  // When the modal is triggered, set the customer type to HCP.
  const forceHcpRole = () => {
    setCustomerRole(hcpCustomerRole)
  }

  // console.log('HCPLandingPageData: ', {
  //   customerType,
  //   shouldGateHcpContent,
  //   hcpCustomerRole,
  //   hcpGatedContent,
  // })

  return {
    customerRole,
    isHcpUser,
    setCustomerRole,
    hcp: {
      hcpCustomerRole,
      shouldGateHcpContent,
      forceHcpRole,
    },
  }
}

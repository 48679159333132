// @ts-nocheck
import { useEffect, useState } from 'react'

/**
 * Media hook, allow to bind a set value event listener for the desired media queries.
 * @param {Array<string>} queries Media queries string array, e.x. '(max-width: 600px)'
 * @param {Array<Any>} values Mapping values associated to a media index
 * @param {Any} defaultValue Default value
 * @return {Any} Current media value
 */
function useMedia(queries, values, defaultValue) {
  const mediaQueryLists = queries.map((q) => typeof window !== 'undefined' && window.matchMedia(q))

  const getValue = () => {
    const index = mediaQueryLists.findIndex((mql) => mql.matches)

    return typeof values[index] !== 'undefined' ? values[index] : defaultValue
  }
  const [value, setValue] = useState(getValue)

  useEffect(() => {
    const handler = () => setValue(getValue)
    mediaQueryLists.forEach((mql) => mql.addListener(handler))
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler))
  }, [])

  return value
}
export default useMedia

import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import { TextCard } from '@ggs/components/paragraphs'
import { useSupportingLinks } from '@ggs/components/ecomm'
import { ButtonVariant } from '@ggs/types'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} CreateAccountBannerProps
 * @property {Order=} order
 */

/**
 * Checkout placed message.
 * @param {CreateAccountBannerProps} props
 * @return {JSX.Element}
 */
const CreateAccountBanner = ({ order }) => {
  const { t } = useI18n()
  const ecommLinks = useSupportingLinks()
  const signupPath = `${ecommLinks.account}/signup`

  return (
    <Box
      sx={{
        '.grid-container__inner': {
          padding: 0,
          mb: 3,
        },
      }}
    >
      <TextCard
        label={t('global:notice.createAccountCta')}
        link={{
          uri: signupPath,
          title: t('global:button.createAnAccount'),
        }}
        linkStyle={ButtonVariant.contained}
        // variant={TextCard.variant.default}
      />
    </Box>
  )
}

export default CreateAccountBanner

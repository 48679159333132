import React from 'react'
import { useModal } from '@ggs/components/common'
import { Title } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { BackLink } from '@ggs/components/nav/index'
import CustomerTypeSelect from './CustomerTypeSelect'

/**
 * @typedef {import('@ggs/components/common/Modal/Dialog')} Dialog
 * @typedef {import('@ggs/components/common/Modal/useModal').ModalState} ModalState
 * @typedef {import('@ggs/types').CustomerGroup} CustomerGroup
 */
/**
 * @typedef {Object} CustomerSelectorModalProps
 * @property {Array<CustomerGroup>} customerGroups All available customer groups
 * @property {CustomerGroup} currentCustomerGroup All available customer groups
 */
/**
 *
 * @param {CustomerSelectorModalProps} props
 * @return {ModalState}
 */
export default function CustomerSelectorModal({ customerGroups, currentCustomerGroup }) {
  const { t } = useI18n()

  return useModal({
    sx: {
      // '.MuiDialog-container': {
      // },
      '.MuiPaper-root': {
        // p: 0,
      },
      '.dialog__content': {
        md: {
          width: 480,
          margin: '0 auto',
        },
      },
      '.back-link': {
        textAlign: 'left',
        pt: 0,
        ml: -1,
        button: {
          p: 0,
          mb: 2,
        },
      },
      '.title': {
        textAlign: 'left',
      },
      '.store-region__icon': {
        display: 'inline-block',
        mr: 1,
      },
    },
    content: ({ onClose }) => (
      <>
        <BackLink label={t('global:button.back')} onClick={onClose} />
        <Title
          title={t('global:nav.label.customerType')}
          subtitle={`<p>${t('global:nav.label.chooseACustomerType')}</p>`}
          style="h4"
        />
        <CustomerTypeSelect customerGroups={customerGroups} onClose={onClose} />
      </>
    ),
  })
}

import React from 'react'
import Box from '@mui/material/Box'
import { StackedContent } from '@ggs/components/layout'
// Assets

/**
 * @typedef {import('@ggs/types').ComponentProps} ComponentProps
 */

/**
 * @typedef {Object} TabProps
 * @property {String=} id
 * @property {String=} type
 * @property {String=} label
 * @property {{name: String, tid: Number}=} customerGroup
 * @property {Array<ComponentProps>} tabContent
 */

/**
 *
 * @param {TabProps} props
 * @return {JSX.Element}
 */
export default function Tab({ id, label, tabContent }) {
  return (
    <Box
      className="tab"
      sx={{
        p: 0,
      }}
    >
      <StackedContent content={tabContent}/>
    </Box>
  )
}

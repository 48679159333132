// @ts-nocheck
import { clClientFactory, getAllSkusData, startGuestSession } from '@ggs/commercelayer'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useStoreSelectors } from '@ggs/components/ecomm/Checkout/useStoreSelectors'

const globalDefaultScope = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`

const initClientWithToken = ({
  token: tokenStr,
  // scope = globalDefaultScope
}) => {
  if (tokenStr) {
    clClientFactory.setToken(tokenStr)
    return {
      clClient: clClientFactory.getClient(),
      // scope,
      active: true,
    }
  } else {
    return {
      clClient: null,
      active: false,
      // scope: null
    }
  }
}

// returns market data, library will revalidate token as needed
export const useCommerceLayerToken = ({ scope, accessToken }) => {
  const { token: localToken } = useStoreSelectors()
  const [activeClClient, setActiveClClient] = useState(() =>
    initClientWithToken({ token: localToken })
  )

  const dispatch = useDispatch()
  const { token, tokenExpiresAt } = accessToken

  // console.log(localToken)
  //
  // const {store} = selectors
  // const {}

  /**
   * Get all CL market skus whenever a token have changed.
   */
  const getMarketSkusData = (clClient) => {
    // clClientFactory.setToken(tokenString)
    // const clClient = clClientFactory.getClient()
    /**
     * Store market data in redux
     */
    try {
      dispatch(getAllSkusData(clClient))
    } catch (error) {
      if (error === 'invalid_scope') {
        // alert('invalid_scope')
      }
    }
  }

  useEffect(async () => {
    const accessTokenResolved = await accessToken
    dispatch(startGuestSession(scope, accessTokenResolved))
  }, [token, tokenExpiresAt, scope])

  useEffect(() => {
    setActiveClClient(() => initClientWithToken({ token: localToken }))
  }, [localToken])

  useEffect(() => {
    if (activeClClient.active) {
      getMarketSkusData(activeClClient.clClient)
    }
  }, [activeClClient.active, activeClClient.clClient])

  return {
    clClient: activeClClient?.clClient ?? null,
  }
}

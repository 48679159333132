import React from 'react'
import Box from '@mui/material/Box'
import MuiSnackbar from '@mui/material/Snackbar'
import { clearAlerts, useDispatch, useSelector } from '@ggs/store'
import { SnackbarAlert } from '@ggs/components/common'
import { size } from 'lodash'
import { rem, typography } from '@ggs/styles'

/**
 * @typedef {import('@mui/material').SnackbarCloseReason} SnackbarCloseReason
 */

const Snackbar = () => {
  const alerts = useSelector((state) => state.ui.alerts)
  const dispatch = useDispatch()
  const vertical = 'bottom'
  const horizontal = 'center'
  const alertsCount = size(alerts.notifications)
  const hasAlerts = Boolean(alertsCount)

  /**
   * Handle Snackbar close.
   * @param {React.SyntheticEvent<any>} event User interaction event
   * @param {SnackbarCloseReason} reason Close reason
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(clearAlerts())
  }

  if (!hasAlerts) {
    return null
  }

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical, horizontal }}
      open={hasAlerts}
      onClose={handleSnackbarClose}
      sx={{
        '.snackbar__close': {
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          typography: typography.menuItemRegular,
          position: 'absolute',
          right: '5px',
          top: '10px',
        },
        '& .MuiPaper-root': {
          borderRadius: rem(2),
          minHeight: rem(40),
          paddingBottom: 2,
          position: 'relative',
          width: {
            sm: '80vw',
            md: ' 600px',
          },
        },
      }}
    >
      <Box>
        {alerts.notifications.map((alert) => (
          <SnackbarAlert key={alert.id} alert={alert} severity={alert.type} />
        ))}
      </Box>
    </MuiSnackbar>
  )
}

export default Snackbar

/**
 * Palette colors.
 * @enum {string}
 */
const colors = {
  black: '#2b3740',
  white: '#ffffff',
  blue: '#5a6eac',
  darkGrey: '#425563',
  red: '#931723',
  ultraLightBlue: '#eff2fb',
  lighterRed: '#fcf3f4',
  lightGrey: '#c3cbd1',
  logoRead: '#ce0e2d',
  smokeBlue: '#f8faff',
  lightBlue: '#d8dff7',
  ultraLightGrey: '#dee2e4',
  ultraLightPink: '#fffdfd',
  ultraLightRed: '#FCF3F4',
  lightRed: '#e9a8b2',
  disabledButtonColour: '#d3a2a7',
  pageBackgroundBlue: '#f8faff',
  pageBackgroundPink: '#fffdfd',
  softPinkGradiant: 'radial-gradient(50% 50% at 50% 50%, #FDF9F9 0%, #F7DFDF 100%)',
  gold: '#FDD403',
}

module.exports = colors

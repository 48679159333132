// @ts-nocheck
import { useSupportingLinks } from '@ggs/components/ecomm'
import { OrderParse } from '@ggs/components/ecomm/Checkout/commerce/app/helpers/OrderParse'
import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'
import { clearPaypalMetadata, setCheckoutState, setLastOrderId, useDispatch, useSelector } from '@ggs/store'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { useWatchForChanges } from '@ggs/components/ecomm/Checkout/hooks/useWatchForChanges'
import { setViewsOrders } from '@ggs/store/actions/views'
import { useBillingShippingAddressController } from '@ggs/components/ecomm/Customer/useBillingShippingAddressController'
import { useCommerceSessionContext } from '@ggs/components/ecomm/CommmerceSession/CommerceSessionContext'
import { clearPlacedOrder } from '@ggs/commercelayer'
import { navigate } from 'gatsby'

const {
  AUTH, ORDER,
  PAYMENT, REVIEW, COMPLETE
} = CHECKOUT_STEP_ENUMS

export const useCheckoutStateInspector = ({
  // order: inProgressOrder,
  // currentStepName,
  handlers: {
    goToOrder,
    goToPayment,
  },
  resetStepByName,
  resetCheckoutExceptShippingAddress,
  isCurrentStepComplete,
  navigateToOrderReview
}) => {
  const { checkout: checkoutPath, productListing } = useSupportingLinks()
  const dispatch = useDispatch()

  const {
    // customer,
    checkout,
    // currentMarket,
    // currentStore,
    order,
  } = useCommerceSessionContext()
  //
  // const {
  //   // notifyShippingChanges,
  //   // notifyOrderChanges,
  //   notifyPaymentChanges
  // } = useWatchForChanges()
  const addressControllers = useBillingShippingAddressController()

  // const orderComplete = order?.status === 'placed'
  // from checkout redux state
  const { currentStepName, stepsComplete } = checkout

  const { billingAddress, shippingAddress } = addressControllers

  const draftOrders = useSelector((state) =>
    state.views.orders.draft
  )
  const placedOrders = useSelector((state) =>
    state.views.orders.placed
  )

  const placedOrderIds = Object.keys(placedOrders)

  const sortedOrders = placedOrderIds.sort((orderA, orderB) =>
    dayjs(placedOrders[orderA].placed_at).isAfter(dayjs(placedOrders[orderB].placed_at)) ? -1 : 1)

  const lastOrder = placedOrders[sortedOrders[0]]

  const { checkoutState: { lastOrderId } } = checkout

  const {
    // notifyShippingChanges,
    notifyOrderChanges,
    notifyPaymentChanges
  } = useWatchForChanges()


  useEffect(() => {
    const parsedOrder = OrderParse(
      {
        ...order, ...addressControllers.addressStepsComplete
      })

    // set checkout state from order data
    if (parsedOrder?.checkoutState?.orderId) {
      if (order && order.status && order.id) {
        dispatch(setViewsOrders(order))
      }
      dispatch(setCheckoutState(parsedOrder.checkoutState))
      // notifyOrderChanges(true)
    } else if (!lastOrderId) {
      // no order, no last order- reset checkout, but keep shipping if possible
      resetCheckoutExceptShippingAddress()
    }

    return () => notifyOrderChanges()
  }, [order, billingAddress, shippingAddress])

  // check we have prereqs to be on current step, if not, send to proper step
  useEffect(() => {
    if (currentStepName === REVIEW) {
      if (!stepsComplete.ORDER) {
        // TODO: show banner notif? review without ORDER
        goToOrder()
      } else if (!stepsComplete.PAYMENT) {
        // notifyPaymentChanges()
        goToPayment()
      }
    }
    // TODO: if not on payment step and the payment is nullified, display a message.
    if (currentStepName === REVIEW) {
      if (!order?.payment_method?.id
        || !order?.payment_source_details
        || !(order.payment_source_details?.payment_method_id
          || order.payment_source_details?.type
          || order.payment_source_details?.paypal_id
        )
      ) {
        // if we lose payment source method or payment source id, reset payment
        goToPayment()
        notifyPaymentChanges(true)
        resetStepByName(PAYMENT)
      }
    }


    // TODO: for logged in flow
    // if (currentStepName === ORDER) {
    //   if (!stepsComplete.AUTH) {
    //      goToAuth()
    //   }
    // }
  }, [stepsComplete, currentStepName, order])

  const useRedirectFromCheckout = () => {
    useEffect(() => {
      const inProgressOrderParsed = order ? OrderParse(order) : null
      // const lastOrderParsed = lastOrder ? OrderParse(lastOrder) : null
      // if active order status is complete or checkout is complete
      if (order && (currentStepName === COMPLETE && order.status === 'placed')) {
        // set last order Id, navigate to order review, and clear active order
        // alert('active order')
        dispatch(setLastOrderId(order))
        navigateToOrderReview(order)
        setTimeout(() => dispatch(clearPlacedOrder({
          order, callbacks: {
            onSuccess: () => {
              dispatch(clearPaypalMetadata())
            }
          }
        })), 1000)
      } else if (
        !order && lastOrder
      ) {
        navigateToOrderReview(lastOrder)
      } else if (!lastOrderId && (!order || !inProgressOrderParsed?.checks?.orderHasItems)) {
        navigate(productListing)
      }
    }, [order, placedOrders, currentStepName, lastOrderId])
  }

  return {
    inProgressOrder: order,
    addressControllers,
    lastOrder,
    checkCartHasItems: () => {
      if (!order) {
        return false
      }
      const inProgressOrderParsed = OrderParse(order)
      return inProgressOrderParsed?.checks?.orderHasItems
    },
    useRedirectFromCheckout
  }
}

// @ts-nocheck
import { ThunkAction } from 'redux-thunk'
import LanguageDetector from 'i18next-browser-languagedetector'
import { head, isArray } from 'lodash'

/**
 * @type {('DETECT_LANGUAGE')}
 */
export const DETECT_LANGUAGE = 'DETECT_LANGUAGE'

/**
 * @typedef {Object} DetectLanguageActionPayload
 * @property {string} language Resolved language
 */

/**
 * @typedef {Object} DetectLanguageAction
 * @property {string} type Action type ID
 * @property {DetectLanguageActionPayload} payload Action input data
 */

/**
 * Compose a detect language action.
 *
 * @param {string} language Geolocation resolved place data
 * @return {DetectLanguageAction} Start guest session action.
 */
const composeDetectLanguageAction = (language) => {
  return {
    type: DETECT_LANGUAGE,
    payload: {
      language: language
    },
  }
}

/**
 * Detect user language.
 * @param {any} allowedLanguages Geolocation resolved place data
 * @return {ThunkAction.<void, any, null, DetectLanguageAction>} return action object
 */
export const detectLanguage = (allowedLanguages = []) => {
  const fallbackLangCode = 'en-CA'
  return async (dispatch) => {
    const detectorOptions = {
      order: ['navigator', 'path'],
      lookupQuerystring: 'lng',
      lookupSessionStorage: 'lng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ['localStorage', 'cookie'],
      cookieOptions: { path: '/', sameSite: 'strict' },
    }

    // @ts-ignore
    const languageDetector = new LanguageDetector(null, detectorOptions)
    const languages = languageDetector.detectors.navigator.lookup()
    // When multiples defined languages use the first one in the list as default.
    console.log(allowedLanguages)
    const defaultLanguage = isArray(languages) && allowedLanguages
      .filter(
        (l) => l.id === head(languages))
      ? head(languages)
      : languages
    dispatch(composeDetectLanguageAction(defaultLanguage || fallbackLangCode))
  }
}

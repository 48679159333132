// @ts-nocheck
import React, { useMemo } from 'react'
import { Component } from '@ggs/gatsby/components'
import { uniqueId } from 'lodash'
import { ComponentLayout } from '@ggs/components/layout'

/**
 * @typedef {import('@ggs/types').SharedContent} SharedContent
 */

/**
 * SharedContent component.
 * @param {{content: Array<Object>}} params
 * @return {JSX.Element}
 */
export default function SharedContent({ content, ...props }) {
  const inner = useMemo(() => {
    return (content || []).map((item) => (
      <Component key={uniqueId(`shared-content-item_${item.id}`)} type={item.type} {...item} />
    ))
  }, [content])
  // eslint-disable-next-line prefer-rest-params
  // console.log('SharedContent render', content, inner) //, JSON.stringify(arguments[0]))

  return inner.length > 0 ? <ComponentLayout {...props}>{inner}</ComponentLayout> : null
}

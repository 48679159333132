import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { colors } from '@ggs/styles'

export const LightTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.ultraLightBlue,
    color: colors.black,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    top: `${theme.spacing(2)} !important`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.ultraLightBlue,
  },
}))

export const HtmlTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

import { LineItems, Orders } from '@atoms-studio/commercelayer-sdk'

const atomsDefault = [
  'line_items',
  'line_items.item',
  'shipments',
  'shipments.available_shipping_methods',
  'available_payment_methods',
  'payment_method',
  'shipping_address',
  'billing_address',
]

const orderIncludesDefault = [
  'authorizations',
  'available_payment_methods',
  'billing_address',
  'captures',
  'customer',
  'line_items',
  'payment_method',
  'payment_source',
  'shipments.available_shipping_methods',
  'shipments.shipping_method',
  'shipments.available_shipping_methods.delivery_lead_time_for_shipment',
  'shipping_address',
  'transactions',
]


const includeLineItems = ['line_items', 'line_items.item']

const fetchOrder = ({ id, include = [], includeDefault = true }) => Orders.find(id, {
  include: includeDefault ? [...orderIncludesDefault, ...include] : [...include]
})

const fetchOrderLineItems = ({ id: orderId }) => fetchOrder({
  id: orderId,
  include: includeLineItems,
  // includeDefault:
})

const createOrder = () => Orders.create()

// returns updated order
const addToCart = ({ order, skuCode, quantity = 1, ...other }) => LineItems.create({
  attributes: {
    sku_code: skuCode,
    quantity: quantity,
    _update_quantity: true,
    ...other
  },
  query: {
    include: ['order', 'order.line_items', 'order.line_items.item'],
  },
  relationships: {
    order,
  },
}).then((lineItem) => lineItem.order)

const removeItem = ({ order, id }) => LineItems.delete(id).then(() => fetchOrderLineItems(order))

const updateQuantity = ({ order, id, quantity = 1 }) => LineItems.update(id, {
  attributes: {
    quantity,
  }
}).then(() => fetchOrderLineItems(order))

export const orderMethods = {
  createOrder,
  fetchOrder,
  fetchOrderLineItems,
  addToCart,
  removeItem,
  updateQuantity
}
import React, { createContext, useContext } from 'react'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'

export const GTMContext = createContext({ pushDataLayer: () => null, gtmId: null })
export const GTMProvider = ({
  clClient,
  order,
  analytics,
  children,
}) => {

  const {
    firePurchase,
    fireBeginCheckout,
    fireAddShippingInfo,
    fireAddPaymentInfo,
    fireAddToCart,
    fireRemoveFromCart
  } = useCheckoutAnalytics({ order })

  // const {
  //   beginCheckout,
  //   addShippingInfo,
  //   addPaymentMethod,
  //   purchase
  // } = analytics

  //
  // const ctx = useContext(AppContext)
  //
  // const { accessToken, orderId, slug, domain } = ctx
  //
  // const cl = CommerceLayer({
  //   organization: slug,
  //   accessToken: accessToken,
  //   domain,
  // })


  return (
    <GTMContext.Provider
      value={{
        firePurchase,
        fireBeginCheckout,
        fireAddShippingInfo,
        fireAddPaymentInfo,
        fireAddToCart,
        fireRemoveFromCart
      }}
    >
      {children}
    </GTMContext.Provider>
  )
}


// hook for using the checkout context state; makes sure context: order, token,
// scope, etc. is valid, then passes checkout context values (using
// useactivecheckout functional hook) to checkout context consumers.
export const useGTMContext = () => {
  const gtmContext = useContext(GTMContext)
  if (gtmContext === undefined) {
    throw new Error(
      'gtmContext is undefined- something wrong with token or scope or client... fallback default scope.'
    )
  }

  const {
    firePurchase,
    beginCheckout,
    fireAddShippingInfo,
    fireAddPaymentInfo,
    fireAddToCart,
    fireRemoveFromCart
  } = gtmContext

  // bootstrap API with token if set on local && valid..
  return {
    firePurchase,
    beginCheckout,
    fireAddShippingInfo,
    fireAddPaymentInfo,
    fireAddToCart,
    fireRemoveFromCart
  }
}
import React from 'react'
import './HeadingFlare.scss'

/**
 *
 * @return {JSX.Element}
 */
export default function HeadingFlare() {
  return (
    <div className="heading-flare">
      <div className="heading-flare__line"/>
      <div className="heading-flare__ball" />
    </div>
  )
}

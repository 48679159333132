import React from 'react'
import { EventSpeakerGroup } from '@ggs/components/paragraphs'

/**
 * @typedef {import('../EventSpeakerGroup/EventSpeakerGroup').EventSpeakerGroupProps} EventSpeakerGroup
 */
/**
 * @typedef {Object} EventSpeakersProps
 * @property {Array<EventSpeakerGroup>=} eventSpeakerGroups
 */
/**
 * EventSpeakers component.
 * @param {EventSpeakersProps} props
 * @return {JSX.Element}
 */
export default function EventSpeakers({ eventSpeakerGroups = [] }) {
  return(
    <>
      {eventSpeakerGroups.length > 0 && eventSpeakerGroups.map((item) => (
        <EventSpeakerGroup key={item.id} {...item} />
      ))}
    </>
  )
}
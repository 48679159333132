import { serverlessPath } from '@ggs/gatsby/hosting'

/**
 * @typedef {import('@commercelayer/sdk').Address} Address
 */

/**
 * Validate address through serverless 'validate-address' function.
 * @param {Address} addressValues Ship engine address
 * @return {Promise<boolean>}
 */
export const validateAddress = async (addressValues) => {
  let result = false
  try {
    const targetApi = `${serverlessPath}validate-address`
    const fetchDetails = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        host: 'https',
      },
      body: JSON.stringify(addressValues),
    }
    /** @type {Response} */
    const validationResponse = await fetch(targetApi, fetchDetails)

    if (validationResponse.status >= 400) {
      throw new Error(`Validation failed with status ${validationResponse.status}`)
    }

    // @ts-ignore
    const validationResult = await validationResponse.json()
    const valid = validationResult?.valid || false

    // const validationResult = await validationResponse.text() || null
    console.log('ValidateResponse:', valid, { addressValues, validationResponse })

    result = valid
  } catch (e) {
    // if the end point is down, bypass.
    if (e?.name === 'SyntaxError' && e?.message === 'Unexpected end of JSON input') {
      result = true
    }
  }
  return result
}

export const testAddressValidation = () => {
  // @ts-ignore
  const addy1 = validateAddress({
    line_1: '63 somehwere st',
    line_2: '',
    country_code: 'CA',
    full_name: 'Seaaaa Homçer',
    company: '',
    phone: '4164164161',
    city: 'Thorold',
    state_code: 'ON',
    zip_code: 'L2T1A2',
  })
  console.log('addy1, valid?', addy1)
  // @ts-ignore
  const addy2 = validateAddress({
    line_1: '63 michael st',
    line_2: '',
    country_code: 'CA',
    full_name: 'Sean Homer',
    company: '',
    phone: '4168540717',
    city: 'Thorold',
    state_code: 'ON',
    zip_code: 'L2T1S2',
  })
  console.log('addy1, valid?', addy2)
}

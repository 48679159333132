import { getCustomerToken, getIntegrationToken, getRefreshToken, getSalesChannelToken } from '@commercelayer/js-auth'
import { clClientFactory } from '../services/CommerceLayerClientFactory'
import { ThunkAction } from 'redux-thunk'
import { Callbacks, Customer } from '../lib/types'
import { handleApiError, invokeErrorCallback, invokeSuccessCallback } from './CommonActions'

const endpointUrl = `${process.env.GATSBY_COMMERCE_LAYER_API}`
const clientId = `${process.env.GATSBY_COMMERCE_LAYER_CLIENT_ID}`

/**
 * @typedef {import('@commercelayer/js-auth/lib/cjs/clientCredentials').ClientCredentials}
 *   ClientCredentials
 * @typedef {import('@commercelayer/js-auth/lib/cjs/salesChannel').User}
 *   UserAuth
 */

/**
 * Authorization action types.
 * @enum {string}
 */
export const authActionTypes = {
  START_GUEST_SESSION: 'START_GUEST_SESSION',
  START_INTEGRATION_SESSION: 'START_INTEGRATION_SESSION',
  START_CUSTOMER_SESSION: 'START_CUSTOMER_SESSION',
  RENEW_CUSTOMER_SESSION: 'RENEW_CUSTOMER_SESSION',
  SET_LAST_AUTH_TIME: 'SET_LAST_AUTH_TIME'
}

/**
 * @typedef {Object} OAuthToken
 * @property {string} token
 * @property {Date} expiresAt
 * @property {string=} refreshToken
 */

/**
 * @typedef {Object} SessionActionPayload
 * @property {OAuthToken} token Commerce layer access token
 * @property {Customer|null} customer Customer entity that owns the token
 * @property {string} scope Commerce layer OAuth Token object
 */

/**
 * @typedef {Object} SessionAction
 * @property {authActionTypes} type Action type key
 * @property {SessionActionPayload} payload Action input data
 */

/**
 * Compose a guest session store action.
 * @param {authActionTypes} actionType Authorization action type.
 * @param {OAuthToken} token Commerce layer OAuth Token object
 * @param {string} scope
 * @param {Customer=} customer Commerce layer customer associated to token, null
 *   when is a guest customer
 * @return {SessionAction} Start guest session action
 */
const actionStartSession = (actionType, token, scope, customer = null) => {
  return {
    type: actionType,
    payload: {
      token,
      scope,
      customer,
    },
  }
}

const globalDefaultScope = `${process.env.GATSBY_COMMERCE_LAYER_DEFAULT_MARKET_ID}`
/**
 * Start a guest session using sales channel.
 * @param {string=} scope Market access scope, e.x. 'market:5812'.
 * @return {ThunkAction.<void, any, null, SessionAction>} return action object
 */
export const startGuestSession = (scope = globalDefaultScope) => {
  return async (dispatch) => {
    if (!scope) {
      throw new Error('Market ID scope is required to start guest token.')
    }
    const reqOptions = {
      clientId: clientId,
      endpoint: endpointUrl,
      scope: scope,
    }

    try {
      const access = await getSalesChannelToken(/** @type {ClientCredentials} */ (reqOptions))
      clClientFactory.setToken(access.accessToken)
      dispatch(actionStartSession(
        authActionTypes.START_GUEST_SESSION,
        {
          token: access.accessToken,
          expiresAt: access.expires,
        },
        scope,
      ))
    } catch (error) {
      handleApiError(dispatch, error)
    }
  }
}

/**
 * Start a integration session using sales channel.
 * @param {string} endpointUrl API endpoint
 * @param {string} clientId API Client ID
 * @param {string} clientSecret API Client secret
 * @param {string=} scope Market access scope
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, SessionAction>} return action object
 */
export const startIntegrationSession = (endpointUrl, clientId, clientSecret, scope, callbacks) => {
  return async (dispatch) => {
    try {
      const options = {
        endpoint: endpointUrl,
        clientId: clientId,
        clientSecret: clientSecret,
        scope: scope,
      }

      const access = await getIntegrationToken(options)
      // @ts-ignore
      const action = actionStartSession(
        authActionTypes.START_INTEGRATION_SESSION,
        {
          token: access.accessToken,
          expiresAt: access.expires,
        },
        scope,
      )

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Start a customer session using sales channel.
 * @param {UserAuth} userData User authentication data
 * @param {string} scope Market access scope, e.x. 'market:5812'
 * @param {any} clClient cl client
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, SessionAction>} return action object
 */
export const startCustomerSession = (userData, scope, clClient, callbacks) => {
  return async (dispatch) => {
    if (!scope) {
      throw new Error('Market ID scope is required to start customer token.')
    }
    console.log(userData)

    /**
     * @type {ClientCredentials}
     */
    const reqOptions = {
      clientId: clientId,
      endpoint: endpointUrl,
      scope: scope,
    }

    try {
      const access = await getCustomerToken(reqOptions, userData)
      console.log('ACCESS TOKEN', access)
      const customerId = access.data?.owner_id || null

      if (!customerId) {
        throw new Error('Customer ID is undefined at CL access data.')
      }

      clClientFactory.setToken(access.accessToken)
      const customer = await clClient.customers.retrieve(customerId)
      const action = actionStartSession(
        authActionTypes.START_CUSTOMER_SESSION,
        {
          token: access.accessToken,
          expiresAt: access.expires,
          refreshToken: access.refreshToken,
        },
        scope,
        customer
      )

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Renew CL registered customer token.
 * @param {string} refreshToken CL refresh token
 * @param {string} scope Market access scope, e.x. 'market:5812'
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, SessionAction>} return action object
 */
export const renewCustomerToken = (refreshToken, scope, callbacks) => {
  return async (dispatch) => {
    try {
      const access = await getRefreshToken({
        clientId: clientId,
        endpoint: endpointUrl,
        scope: scope,
        refreshToken: refreshToken,
      })

      const customerId = access.data?.owner_id || null
      if (!customerId) {
        throw new Error('Customer ID is undefined at CL access data.')
      }

      clClientFactory.setToken(access.accessToken)
      const clClient = clClientFactory.getClient()
      const customer = await clClient.customers.retrieve(customerId)
      const action = actionStartSession(
        authActionTypes.RENEW_CUSTOMER_SESSION,
        {
          token: access.accessToken,
          expiresAt: access.expires,
          refreshToken: access.refreshToken,
        },
        scope,
        customer,
      )

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

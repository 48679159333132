// @ts-nocheck
import React, {useMemo} from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import {uniqueId} from 'lodash'
// Data
import {Forms} from '@ggs/forms/schema'
import useFormField from './useFormField'
// Components
import PhoneInput from './PhoneInput'
import TextField from './TextField'
import Checkbox from './Checkbox'
import CustomDatePicker from './CustomDatePicker'
import RadioGroup from './RadioGroup'
import NumberInput from './NumberInput'
import AccordionCheckbox from './AccordionCheckbox/AccordionCheckbox'
import AutoCompleteSelect from './AutoCompleteSelect'
import CurrencyInput from './CurrencyInput'
import DropdownTreeSelect from './DropdownTreeSelect/DropdownTreeSelect'
import Select from './Select'
import SelectableAccordionList from './SelectableAccordionList/SelectableAccordionList'
import CheckboxModal from './CheckboxModal'
import FileUpload from '../FileUpload/FileUpload'
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MuiRadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
// Assets

const FormSubField = (props) => {
  const {
    value,
    touched,
    id,
    label,
    placeholder,
    disabled,
    options,
    autoCompleteOptions,
    name,
    type,
    icon,
    maxDate,
    waitForTouched,
    inputProps,
    fieldProps,
    ref,
    formik,
    labelPlacement,
    // For fieldWrapper
    hasError,
    displayed,
    descriptor,
    caption,
    helpText,
    afterFieldText,
    error,
  } = useFormField(props)
  /**
   * Generate compiled input, memo'd to reduce rendering.
   * @return {JSX.Element}
   */
  const field = useMemo(() => {
    let comp = <></>

    switch (type) {
      case Forms.FieldTypes.text:
      case Forms.FieldTypes.email:
      case Forms.FieldTypes.password:
        comp = (
          <TextField
            {...fieldProps}
            type={type}
            label={label}
            inputProps={inputProps}
            disabled={disabled}
            icon={icon}
          />
        )
        break
      case Forms.FieldTypes.tel:
        comp = <PhoneInput {...fieldProps} label={label} inputProps={inputProps}/>
        break
      case Forms.FieldTypes.currency:
        comp = (
          <CurrencyInput
            {...fieldProps}
            label={label}
            inputProps={inputProps}
            disabled={disabled}
          />
        )
        break
      case Forms.FieldTypes.number:
        comp = (
          <>
            {icon !== null && icon}
            <NumberInput
              {...fieldProps}
              label={label}
              inputProps={inputProps}
              disabled={disabled}
              options={options}
            />
          </>
        )
        break
      case Forms.FieldTypes.date:
        comp = (
          // <Suspense fallback={' '}>
          <CustomDatePicker
            id={fieldProps.id}
            ref={ref}
            name={name}
            value={fieldProps.value}
            onChange={fieldProps.onChange}
            label={label}
            max={maxDate}
            inputProps={inputProps}
            formik={formik}
          />
          //</Suspense>
        )
        break
      case Forms.FieldTypes.textarea:
        comp = (
          <TextField
            {...fieldProps}
            type={type}
            inputProps={inputProps}
            label={label}
            multiline
            rows={1}
            maxRows={4}
          />
        )
        break
      case Forms.FieldTypes.checkbox:
      case Forms.FieldTypes.switch:
        const adjustedPlacement
          = labelPlacement
          ?? (type === Forms.FieldTypes.switch ? Forms.LabelPlacement.top : Forms.LabelPlacement.end)
        // console.log(
        //   'adjustedPlacement',
        //   type,
        //   labelPlacement,
        //   adjustedPlacement,
        //   labelPlacement ?? 'wtf!!?!?!?!?'
        // )
        comp = (
          <Checkbox
            {...fieldProps}
            disabled={disabled}
            inputProps={inputProps}
            labelPlacement={adjustedPlacement}
            isSwitch={type === Forms.FieldTypes.switch}
          />
        )
        break
      case Forms.FieldTypes.select:
        comp = <Select {...props} {...fieldProps} label={label}/>
        break
      case Forms.FieldTypes.autoComplete:
        comp = (
          <AutoCompleteSelect
            {...fieldProps}
            autoCompleteOptions={autoCompleteOptions}
            disabled={disabled}
          />
        )
        break
      case Forms.FieldTypes.radioGroup:
        comp = <RadioGroup {...props} fieldProps={fieldProps} disabled={disabled}/>
        break
      case Forms.FieldTypes.shippingSelect:
        comp = <FormControl className="form-field__radiogroup" component="fieldset"
                            disabled={disabled}>
          <FormLabel component="legend">{label}</FormLabel>
          <MuiRadioGroup
            ref={ref}
            name={name}
            aria-label={label}
          >
            {options.map((item, index) => (
              <FormControlLabel
                key={uniqueId(`radio-item-${index}_`)}
                value={item?.value || item?.label}
                disabled={item?.disabled}
                control={<Radio color="primary"/>}
                label={item?.label}
                labelPlacement={item?.labelPlacement}
              />
            ))}
          </MuiRadioGroup>
        </FormControl>
        break
      case Forms.FieldTypes.hidden:
        comp = <TextField {...props} {...fieldProps} type={type}/>
        break
      case Forms.FieldTypes.selectableAccordionList:
        comp = <SelectableAccordionList {...props} {...fieldProps} />
        break
      case Forms.FieldTypes.accordionCheckbox:
        comp = <AccordionCheckbox {...props} {...fieldProps} />
        break
      case Forms.FieldTypes.dropdownTreeSelect:
        comp = <DropdownTreeSelect {...props} {...fieldProps} />
        break
      case Forms.FieldTypes.checkboxModal:
        comp = <CheckboxModal {...props} {...fieldProps} />
        break
      case Forms.FieldTypes.file:
        comp = <FileUpload {...fieldProps} options={options}/>
        break
      default:
      // console.warn('FormInput -- invalid field type in', props)
    }

    // console.log(`renderField ${name}`, comp)

    return comp
  }, [
    value,
    touched,
    id,
    label,
    placeholder,
    disabled,
    options,
    autoCompleteOptions,
    name,
    type,
    icon,
    maxDate,
    waitForTouched,
    inputProps,
  ])

  // console.log('SubFormField', { name, label, type, comp, fieldProps, props, inputProps })

  return (
    <div
      className="form-field"
      data-name={name}
      data-input-type={type}
      data-error={hasError}
      data-valid={!hasError}
      data-visible={displayed}
    >
      {descriptor && type !== Forms.FieldTypes.radioGroup && (
        <div className="form-field__descriptor" dangerouslySetInnerHTML={{__html: descriptor}}/>
      )}
      {caption && <div className="field-caption">{caption}</div>}
      {field}
      <FormHelperText id={`${id}-helper-text`}>
        {!hasError && !!helpText && <span>{helpText}</span>}
        {hasError && <span className="form-field__error">{error}</span>}
      </FormHelperText>
      {afterFieldText && (
        <div
          className="form-field__bottom-text"
          dangerouslySetInnerHTML={{__html: afterFieldText}}
        />
      )}
    </div>
  )
}

export default FormSubField

import { useSelector } from '@ggs/store'

/**
 * @typedef {import('@commercelayer/sdk').LineItem} LineItem
 */

/**
 * @typedef {(sku: string) => LineItem|null} LookupItemFunction
 * @typedef {(sku: Array<string>) => Array<LineItem>} LookupItemsFunction
 */

/**
 * @typedef {Object} OrderLookupHook
 * @property {LookupItemsFunction} lookupLineItems Lookup CL order line items (multiple) within draft order
 * @property {LookupItemFunction} lookupLineItem Lookup CL order line item (single) within draft order
 * @property {Array<LineItem>} lineItems Current order line items
 */

/**
 * Lookup Order related data from commerce order state.
 * @return {OrderLookupHook}
 */
export function useOrderLookup() {
  const order = useSelector((state) => state.commerce.order)
  const lineItems = order?.line_items || null

  /**
   * Lookup the CL order line item by SKUs (multiple).
   * @param {Array<string>} sku Line item SKUs
   * @return {Array<LineItem>} CL line items that match with the SKUs, empty array otherwise.
   */
  const lookupLineItems = (sku) => {
    if (!lineItems) {
      return []
    }

    const itemMatches = lineItems.filter((lineItem) => {
      return sku.includes(lineItem.sku_code)
    })

    return itemMatches
  }

  /**
   * Lookup the CL order line items by SKU (single).
   * @param {string} sku Line item SKUs
   * @return {LineItem|null} CL line item that match with a given SKU, null otherwise.
   */
  const lookupLineItem = (sku) => {
    if (!lineItems) {
      return null
    }

    const itemMatches = lineItems.find((lineItem) => {
      return sku === lineItem.sku_code
    })

    return itemMatches
  }

  return {
    lookupLineItems,
    lookupLineItem,
    lineItems,
  }
}

import React, { useState } from 'react'
import SearchFilters from './SearchFilters'
// Assets.

/**
 * @typedef {import('@ggs/types').FaqCategory} FaqCategory
 */
/**
 * @typedef {Object} useSearchFilters
 * @property {Array<*>=} categories
 */
/**
 *
 * @param {useSearchFilters} props
 * @return {{renderedFilters: JSX.Element, activeFilters: *[]}}
 */
export default function useSearchFilters({ categories }) {
  const [activeFilters, setActiveFilters] = useState([])

  const updateFilters = (
    /** @type {EventTarget & HTMLInputElement} */ target,
    /** @type {number} */ id
  ) => {
    setActiveFilters(
      !activeFilters.includes(id)
        ? activeFilters.concat(id)
        : activeFilters.filter((item) => item !== id)
    )
  }

  const resetFilters = () => {
    setActiveFilters([])
  }

  const renderedFilters = (
    <SearchFilters
      categories={categories}
      activeFilters={activeFilters}
      updateFilters={updateFilters}
      resetFilters={resetFilters}
    />
  )

  return {
    renderedFilters,
    activeFilters,
  }
}

export const EcActionType = {
  // A user adds one or more products to a shopping cart.
  addToCart: 'add_to_cart',
  //  A user initiates the checkout process for one or more products.
  beginCheckout: 'begin_checkout',
  // A user completes checkout steps after the first checkout step.
  checkoutProgress: 'checkout_progress',
  // A user completes a purchase.refund  A user is issued a refund for one or more products.
  purchase: 'purchase',
  // A user removes one or more products from a shopping cart.
  removeFromCart: 'remove_from_cart',
  // user views the cart
  viewCart: 'view_cart',
  //  A user clicks on a product or product link.
  selectItem: 'select_item',
  // The checkout step that a user is completing.
  setCheckoutOption: 'set_checkout_option',
  // user views details for a product.
  viewItem: 'view_item',
  //  A user views a list of one or more products.
  viewItemList: 'view_item_list',
  //  A user clicks on an internal promotion.
  viewPromo: 'view_promotion',
  // A user views a refund for one or more products.
  viewRefund: 'view_refund',
  // select Content
  selectContent: 'select_content',
  // maybe not supported
  addShippingInfo: 'add_shipping_info',
  addPaymentInfo: 'add_payment_info'
}
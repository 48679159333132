/**
 * @typedef {import('@ggs/types').Customer} Customer
 */

/**
 * Customer action types.
 * @enum {string}
 */
export const customerNoticeCodes = {
  SIGN_OUT: 'SIGN_OUT',
}

/**
 * Customer action types.
 * @enum {string}
 */
export const customerActionType = {
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_CUSTOMER_TYPE: 'SET_CUSTOMER_TYPE',
}

/**
 * @typedef {Object} SetCustomerActionPayload
 * @property {Customer} customer
 */

/**
 * @typedef {Object} SetCustomerAction
 * @property {customerActionType} type Action type key
 * @property {SetCustomerActionPayload} payload Action input data
 */

/**
 * Compose customer action.
 *
 * @param {customerActionType} actionType Action type
 * @param {Customer} customer Customer simplified entity
 * @return {SetCustomerAction}
 */
const composeDataAction = (actionType, customer) => {
  return {
    type: actionType,
    payload: {
      customer,
    },
  }
}

/**
 * Set commerce customer entity.
 *
 * @param {Customer} customer Customer simplified entity
 * @return {SetCustomerAction}
 */
export const setCustomer = (customer) => {
  console.log('setCustomer', customer)
  return composeDataAction(customerActionType.SET_CUSTOMER, customer)
}

/**
 * Set commerce customer HCP sample.
 *
 * @param {number} customerGroupId Customer group taxonomy ID for HCP.
 * @return {SetCustomerAction}
 */
export const setCustomerHCPSample = (customerGroupId) => {
  return setCustomer({
    email: 'hcp1@example.com',
    customerType: customerGroupId,
  })
}

/**
 * Set commerce customer HCP sample.
 *
 * @param {number} customerGroupId Customer group taxonomy ID for guest.
 * @return {SetCustomerAction}
 */
export const setCustomerGuestSample = (customerGroupId) => {
  return setCustomer({
    email: '',
    customerType: customerGroupId,
  })
}

/**
 * @typedef {Object} SetCustomerTypeActionPayload
 * @property {number} customerType
 */

/**
 * @typedef {Object} SetCustomerTypeAction
 * @property {customerActionType} type Action type key
 * @property {SetCustomerTypeActionPayload} payload Action input data
 */

const defaultTID = `${process.env.GATSBY_DEFAULT_CUSTOMER_GROUP_ID}`
/**
 * Set commerce customer group.
 *
 * @param {number} customerGroupId Customer group taxonomy ID for guest.
 * @return {SetCustomerTypeAction}
 */
export const setCustomerType = (customerGroupId) => {
  return {
    type: customerActionType.SET_CUSTOMER_TYPE,
    payload: {
      customerType: customerGroupId ?? defaultTID,
    },
  }
}

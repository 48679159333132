import { find } from 'lodash'

/**
 * @typedef {import('@ggs/types').Store} Store
 */
/**
 * Find user preferred store from stores definition.
 * @param {Array<Store>} stores All Drupal stores definitions
 * @param {string} userCountry User country iso2 code
 * @return {Store} Preferred store based on user country location.
 */
export function findUserPreferredStore(stores, userCountry) {
  // Find stores that includes user country within countries scope.
  const countryMatch = stores.find((store) => {
    return find(store.country, { iso2: userCountry })
  })

  return countryMatch
}

/**
 * Resolve preferred website language based on user language.
 * @param {Array<string>} webLangCodes Website languages codes array, e.x. ['en-US', 'en-CA']
 * @param {string} userLangCode User language code
 * @return {string} Locale website base path.
 */
export function resolveUserPreferredLangCode(webLangCodes = [], userLangCode = '') {
  // Split user language from country for localization codes e.x. 'en-CA'.
  const [baseLangCode] = userLangCode.split('-')
  const webLangMatch = webLangCodes.find((webLangCode) => {
    return webLangCode === userLangCode || webLangCode.includes(baseLangCode)
  })

  // Use matched language or fallback in first language from definition list.
  const preferredLangCode = webLangMatch || webLangCodes?.[0] || null

  return preferredLangCode
}

import React from 'react'
import Box from '@mui/material/Box'
import { Button } from '@ggs/gatsby/components/nav'
import useProductOptionSx from '@ggs/components/ecomm/ProductOption/useProductOptionSx'
import ProductOptionColor from '@ggs/components/ecomm/ProductOption/ProductOptionColor'
import { icons } from '@ggs/components'
import Grid from '@mui/material/Grid'
import { noop } from 'lodash'

/**
 * @typedef {import('react').ElementType} ElementType
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').ProductOptionVariant} ProductOptionVariant
 */
/**
 * @typedef {Object} ProductOptionProps
 * @property {ProductOptionVariant} variant
 * @property {ProductOption} option
 * @property {Boolean=} active
 * @property {function=} selectOption
 * @property {ElementType=} component
 */
/**
 *
 * @param {ProductOptionProps} props
 * @return {JSX.Element}
 */
export default function ProductOption({ variant, option, active, selectOption = noop, component }) {
  const sx = useProductOptionSx(variant)
  const isIncluded = variant === 'included'

  // eslint-disable-next-line prefer-rest-params
  // console.log('ProductOption render', arguments[0]) //, JSON.stringify(arguments[0]))

  return (
    <Box className="product-option" sx={sx} data-active={active} component={component}>
      <Button
        onClick={() => selectOption(option)}
        variant={Button.variant.text}
        // @ts-ignore
        disabled={isIncluded}
      >
        <Grid container alignItems="center" flexDirection="row" flexWrap="nowrap">
          <Grid item xs={'auto'}>
            {isIncluded && icons.GreenCheckmark}
          </Grid>
          <Grid item xs={'auto'} sx={{ height: 16 }}>
            {!isIncluded && <ProductOptionColor {...option?.color?.colorData} />}
          </Grid>
          <Grid className="product-option__labels" item xs={'auto'} flexShrink={1}>
            <span
              className="product-option__label"
              dangerouslySetInnerHTML={{ __html: option.label }}
            />
          </Grid>
        </Grid>
      </Button>
    </Box>
  )
}

import React from 'react'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { Button } from '@ggs/gatsby/components/nav'
import Box from '@mui/material/Box'
import StoreSelectorModal from './StoreSelectorModal'

/**
 * @typedef {import('@ggs/components/common/Modal/Dialog')} Dialog
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */

/**
 *
 * @param {LayoutMeta} layoutMeta
 * @return {JSX.Element}
 */
export default function StoreSelector({ layoutMeta }) {
  const { currentStore } = layoutMeta
  const { modal, triggerModal } = StoreSelectorModal(layoutMeta)

  const countryCode = currentStore?.country?.length === 1 ? currentStore?.country?.[0]?.iso2 : ''
  const countryFlagIcon = countryCode ? getUnicodeFlagIcon(countryCode) : null

  return (
    <Box
      className={'store-selector'}
      sx={{
        // mr: 3,
        '.store-region__select': {
          px: 0,
        },
        '.store-region__icon': {
          display: 'inline-block',
          mr: 1,
        },
      }}
    >
      <Button className="store-region__select" onClick={triggerModal} variant={Button.variant.text}>
        <>
          {countryFlagIcon && <span className="store-region__icon">{countryFlagIcon}</span>}
          <span className="store-region__label">{currentStore?.name}</span>
        </>
      </Button>
      {modal}
    </Box>
  )
}

import React from 'react'
import { ProductPrice } from '@ggs/components/ecomm'
import { Divider } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import Grid from '@mui/material/Grid'
import useProductPurchaseActions from './useProductPurchaseActions'
import useExistingQuantities from '@ggs/components/ecomm/ProductAddCart/useExistingQuantities'
import useProductAddCart from '@ggs/components/ecomm/ProductAddCart/useProductAddCart'
import { useOnAllowEcommListCheck } from '@ggs/components/ecomm/ProductPurchaseOptions/useCurrentMetadata'
/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').Product} Product
 * @typedef {import('./useProductPurchaseActions').ProductPurchaseActions} ProductPurchaseActions
 */

/**
 * @param {ProductPurchaseActions} props
 * @return {JSX.Element}
 */
export default function ProductPurchaseActions(props = {}) {
  const { currentOption } = props
  const { t } = useI18n()
  const {
    sx,
    isSalable,
    showWhereToBuy,
    showContactARep,
    unavailableForSale,
    displayContactARep,
    displayWhereToBuy,
    whereToBuyModalContent,
    contactRepModalContent,
  } = useProductPurchaseActions(props)
  const { notice: existingQuantityNotice } = useExistingQuantities(currentOption)
  const { handleSubmit, displayUnavailableForSale, displayQuantitySelector, displayAddButton }
    = useProductAddCart({
      ...props,
      buttonLabel: t('ecomm:product.button.addToCart'),
    })

  const onAllowList = useOnAllowEcommListCheck()

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid
          className={'product-purchase-actions'}
          container
          spacing={1}
          alignItems={'flex-end'}
          sx={sx}
        >
          {
            // If the product is not salable
            !onAllowList || !isSalable || displayUnavailableForSale ? (
              // And, if not salable, that there is where to buy content, display unavailable message.
              unavailableForSale
            ) : (
              // Else, if the product is salable, display normally
              <>
                <Grid
                  className={'product-purchase-actions__salable-price'}
                  item
                  sm={12}
                  // md={12}
                >
                  <ProductPrice currentOption={currentOption} {...props.product} />
                </Grid>
                <Grid
                  className={'product-purchase-actions__salable-addToCart'}
                  item
                  xs={12}
                  md={'auto'}
                  sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    mr: {
                      xs: 1,
                      // If we hit max quantity, we need a bit of space here, otherwise, we're ok.
                      md: existingQuantityNotice ? 1 : 0,
                      // aboveSmallDesktop: 0,
                      xl: 2,
                    },
                  }}
                >
                  {displayQuantitySelector}
                  {displayAddButton}
                </Grid>
              </>
            )
          }
          {
            // If there is either the where to buy or contact a rep button, display them.
            (showWhereToBuy || showContactARep) && (
              <>
                {showWhereToBuy && displayWhereToBuy}
                {showContactARep && displayContactARep}
              </>
            )
          }
          {existingQuantityNotice}
        </Grid>
      </form>
      {whereToBuyModalContent}
      {contactRepModalContent}
      {(showWhereToBuy || showContactARep || isSalable) && <Divider/>}
    </>
  )
}

// @ts-nocheck
import {
  isFunction,
  get,
  has,
  defer,
  upperFirst,
  camelCase,
  sampleSize,
  size,
  uniqBy,
} from 'lodash'

// Default limit on stat list method execution times.
const DEFAULT_EXECUTION_TIME = 50

/**
 * Gatsby nodes static list that provide index by node properties for fast lookup.
 */
export default class StaticList {
  idField = 'id'

  /** @type {null|function} */
  defaultSort = null

  list = {
    all: [],
    byId: {},
    byAlias: {},
  }

  /**
   * @param {object} config
   */
  constructor(config) {
    const { idField, defaultSort } = config || {}

    // Update common fields.
    if (idField) {
      this.idField = idField
    }
    if (defaultSort) {
      this.defaultSort = defaultSort
    }
  }

  /**
   * Method to parse and transform a large dataset.
   * @param {array<any>} nodes Array of node objects.
   * @param {function} transformMethod Node data transformation function.
   */
  parse(nodes, transformMethod) {
    const { list, defaultSort } = this

    // expensive procedure
    if (!list.all.length) {
      if (isFunction(transformMethod)) {
        list.all = nodes.map((node) => transformMethod(node))

        // console.log(':parse list parsed', list.all)

        // If a default sorting method is configured, execute this to ensure all entries are sorted
        // upfront to save time/processing later.
        if (isFunction(defaultSort)) {
          list.all = defaultSort(list.all)
          // console.log(':parse list sorted', list.all)
        }

        // Now ensure to reduce the records by unique ones only.
        list.all = uniqBy(list.all, 'id')
      }

      defer(() => {
        this.indexById()
        this.indexByAlias()
      })
    }
  }

  /**
   * @param {string} prop Node property name used for index creation.
   */
  indexByProp(prop) {
    const { list } = this
    const targetIndex = `by${upperFirst(camelCase(prop))}`

    // If no index previously, initialize.
    if (!list[targetIndex]) {
      list[targetIndex] = {}
    }

    // Set the index now if it hasn't been set yet, and it's possible to, that
    // there is a valid field to index with.
    if (list.all.length && !size(list[targetIndex]) && has(list, `all.[0].${prop}`)) {
      list.all.forEach((item) => {
        list[targetIndex][get(item, prop)] = item
      })

      // console.log(`:indexByProp(${prop}) `, list[targetIndex])
    }
  }

  /**
   * Index nodes by ID property.
   */
  indexById() {
    this.indexByProp(this.idField)
  }

  /**
   * Index nodes by alias property.
   */
  indexByAlias() {
    this.indexByProp('alias')
  }

  /**
   * Filter nodes by all properties that match the filter key.
   * @param {string} filter Filter key.
   * @return {Array<Any>}
   */
  filterAllBy(filter) {
    return this.list.all(filter)
  }

  /**
   * Provides the collection of all nodes that have been processed by constructor.
   * @return {Array<Any>}
   */
  getAll() {
    return this.list.all
  }

  /**
   * Retrieves a nodes random sample.
   *
   * @param {number} limit Maximum number of items to retrieve.
   * @return {Array<Any>}
   */
  getSample(limit = 5) {
    return sampleSize(this.list.all, limit)
  }

  /**
   * Promise to control nodes list processing operation abortion when max exec time is exceeded.
   *
   * @param {string} type List type identifier.
   * @param {function(): *} dataResolver Resolver callback.
   * @param {number} maxExecutionTime Execution time limit (milliseconds).
   * @return {Promise}
   */
  promise(type, dataResolver, maxExecutionTime = DEFAULT_EXECUTION_TIME) {
    return new Promise((resolve, reject) => {
      // Track execute time of intervals.
      let ticks = 0

      const interval = setInterval(() => {
        let result

        // Bump interval
        ticks++

        // Attempt to obtain the data, or reject on failure.
        try {
          result = dataResolver()
        } catch (e) {
          clearInterval(interval)
          // console.log(`:${type} dataResolver error at ${ticks}ms`)
          reject(e)
        }

        // If we have results, send along the resolve with data.
        if (size(result)) {
          // console.log(`:${type} resolved in ${ticks}ms`, result)
          clearInterval(interval)
          resolve(result)
        } else if (ticks > maxExecutionTime) {
          // If we ran longer than desired with no results (or error), auto-reject
          clearInterval(interval)
          // console.log(`:${type} maxExecutionTime (${maxExecutionTime}ms) error`)
          reject(new Error('Max Execution Time'))
        }
      }, 1)
    })
  }
}

import { useMemo } from 'react'
import { colors } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 *
 * @return {SxProps}
 */
export default function useAddressFormSx() {
  return useMemo(
    () => ({
      '.MuiFilledInput-root': {
        backgroundColor: colors.white,
      },
      '.MuiButton-root': {
        mt: 2,
        mb: 5,
      },
      '& .form__fields': {
        [`
      [data-name="email"],
      [data-name="phone"],
      [data-name="line_2"],
      [data-name="city"],
      [data-name="state_code"],
      [data-name="zip_code"],
      [data-name="first_name"],
      [data-name="last_name"]
      `]: {
          boxSizing: 'border-box',
          flex: {
            xs: '0 0 100%',
            md: '0 0 50%',
          },
        },
        [`
      [data-name="email"],
      [data-name="line_2"],
      [data-name="first_name"],
      [data-name="city"],
      `]: {
          pr: {
            xs: 0,
            md: 2,
          },
        },
        [`
      [data-name="phone"],
      [data-name="zip_code"],
      [data-name="state_code"],
      [data-name="last_name"]
      `]: {
          pl: {
            xs: 0,
            md: 2,
          },
        },
        '[data-name="zip_code"] input': {
          textTransform: 'uppercase',
        },
      },
    }),
    []
  )
}

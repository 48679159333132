import React from 'react'
import { Button, Link } from '@ggs/gatsby/components/nav'
import { isObject, omit, size } from 'lodash'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { Title } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { GridContainer } from '@ggs/components/layout'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Skeleton from '@mui/material/Skeleton'
import { ProductPrice } from '@ggs/components/ecomm' // Assets
import './ProductCard.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import useProductPrice from '@ggs/components/ecomm/ProductPrice/useProductPrice'
import colors from '@ggs/styles/theme/colors'
import striptags from 'striptags'
import { useLayoutMeta } from '@ggs/hooks'
import { useOnAllowEcommListCheck } from '@ggs/components/ecomm/ProductPurchaseOptions/useCurrentMetadata'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'
import { parseDefaultItemFromOption } from '@ggs/components/hooks/ViewProductListObserver'

export const shopOrLearnMore = 'shopOrLearnMore'

const getProductAsItemProperties = ({ defaultOption, productOptions }) => {}

/**
 * @typedef {import('@ggs/types').ProductCategory} ProductCategory
 * @typedef {import('@ggs/types').ProductUsage} ProductUsage
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').Store} Store
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */
/**
 * @typedef {Object} ProductCardProps
 * @property {String=} id
 * @property {String=} type
 * @property {String=} url
 * @property {String=} name
 * @property {String=} subtitle
 * @property {Array<Store>=} stores
 * @property {String=} listingDescription
 * @property {Array<ProductUsage>=} productUse
 * @property {Array<ProductCategory>=} productCategories
 * @property {ImageStyles=} listingImage
 * @property {ProductOption=} defaultOption
 * @property {Array<ProductOption>=} productOptions
 * @property {Boolean=} isNew
 * @property {String=} actionLabel
 */
/**
 *
 * @param {ProductCardProps} props
 * @return {JSX.Element}
 */
export default function ProductCard(props) {
  const {
    url,
    name,
    subtitle = '',
    stores,
    listingDescription = '',
    productUse,
    productCategories,
    listingImage,
    defaultOption,
    productOptions,
    isNew = false,
    actionLabel,
  } = props
  const { t } = useI18n()
  const {
    currentStore: { ecommEnabled },
  } = useLayoutMeta(props)
  const { hasPricing } = useProductPrice({
    defaultOption,
    productOptions,
  })
  const onAllowList = useOnAllowEcommListCheck()
  const { fireSelectItem, fireSelectContent } = useCheckoutAnalytics()

  // const isShopOrLearnMore = actionLabel === shopOrLearnMore
  // const linkTitle = !isShopOrLearnMore
  //   ? actionLabel || t('global:button.viewDetails')
  //   : hasPricing
  //     ? t('global:button.shop')
  //     : t('global:button.learnMore')
  const cardAction = (
    <>
      <Button
        link={{
          title:
            !ecommEnabled || !hasPricing || !onAllowList
              ? t('global:button.learnMore')
              : t('global:button.shop'),
          uri: url,
        }}
        onClick={() => {
          const item = parseDefaultItemFromOption(props)
          if (item.type === 'product') {
            fireSelectItem({
              items: [parseDefaultItemFromOption(props)],
              item_list_name: `${props.type}--${props?.entityId}`,
              item_list_id: `${props.type}--${props?.entityId}`,
            })
          } else {
            fireSelectContent({
              items: [parseDefaultItemFromOption(props)],
              item_list_name: `${props.type}--${props?.entityId}`,
              item_list_id: `${props.type}--${props?.entityId}`,
            })
          }
        }}
        data-label={
          !ecommEnabled || !hasPricing || !onAllowList
            ? t('global:button.learnMore')
            : t('global:button.shop')
        }
        data-action-type="navigate-product-card"
        fireOnClickForLink={true}
        variant={!ecommEnabled || !hasPricing ? Button.variant.text : undefined}
        endIcon={<ChevronRightIcon />}
      />
    </>
  )

  const cleanName = striptags(String(name))
  const displayListingImage = isObject(listingImage)
    && size(omit(listingImage?.styles, ['alt'])) && (
      <div className="product-card__image">
        <Link
          href={url}
          tabIndex={-1}
          aria-label={`View ${cleanName}`}
          data-label={name}
          data-action-type="navigate-product-card"
        >
          <ImageStyles {...listingImage} selectedStyles={['productCard']} />
        </Link>
      </div>
    )

  return (
    <GridContainer className="product-card">
      <Card className="product-card__card" data-is-new={isNew}>
        <CardContent className="product-card__content">
          {isNew && <div className="product-card__new-label">{t('ecomm:product.label.new')}</div>}
          {displayListingImage || (
            <div className="product-card__image">
              <Skeleton
                variant="rectangular"
                style={{ display: 'block', borderRadius: 0 }}
                width="100%"
                height={232}
                animation={!url ? false : null}
              />
            </div>
          )}
          {name ? (
            <Title
              title={name}
              subtitle={subtitle}
              style="h5"
              href={url}
              sx={{
                a: {
                  color: colors.black,
                  '&:hover': {
                    textDecoration: 'none',
                  },
                },
              }}
            />
          ) : (
            <Skeleton variant="text" width="100%" />
          )}
          {listingDescription ? (
            <div
              className="product-card__description"
              dangerouslySetInnerHTML={{ __html: listingDescription }}
            />
          ) : (
            <Skeleton variant="text" width="100%" height={100} />
          )}
        </CardContent>
        <CardActions className="product-card__actions">
          {defaultOption ? (
            <ProductPrice defaultOption={defaultOption} productOptions={productOptions} />
          ) : (
            <Skeleton variant="text" width="100%" />
          )}
          {url ? cardAction : <Skeleton variant="rectangular" width={142} height={46} />}
        </CardActions>
      </Card>
    </GridContainer>
  )
}

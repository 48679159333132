import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import { Button, Link } from '@ggs/gatsby/components/nav'
// Assets
import './BackLink.scss'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 * @typedef {Object} BackLinkProps
 * @property {String=} label
 * @property {String=} href
 * @property {function=} onClick
 * @property {SxProps=} sx
 */
/**
 *
 * @param {BackLinkProps} props
 * @return {JSX.Element}
 */
export default function BackLink({ href, onClick, label, sx }) {
  // eslint-disable-next-line prefer-rest-params
  // console.log('BackLink render', arguments[0]) //, JSON.stringify(arguments[0]))
  const buttonLabel = (
    <>
      <ChevronLeftIcon />
      {label}
    </>
  )
  const button = useMemo(
    () =>
      onClick ? (
        <Button onClick={onClick} variant={'text'}>{buttonLabel}</Button>
      ) : (
        <Link href={href}>{buttonLabel}</Link>
      ),
    [buttonLabel, onClick, href]
  )

  return (
    <Box
      className="back-link"
      sx={{
        pt: {
          xs: 2,
          lg: 5,
        },
      }}
    >
      {button}
    </Box>
  )
}

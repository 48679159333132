import { STEP_FLOW } from '@ggs/components/ecomm/Checkout/enums'
import React from 'react'

// library functions
/**
 * @typedef {Object} stepIsInFlowParams
 * @property {Array<String>} stepFlow stepflow, array of all the steps, can
 *   change.
 * @property {String} STEP_NAME injected State from localstorage, initial values
 *   from account, etc.
 */

/**
 * check if a step exists in a flow.
 * @param {stepIsInFlowParams} args
 * @return {any}
 */
export const stepIsInFlow = ({ STEP_NAME, stepFlow }) =>
  STEP_NAME && Array.isArray(stepFlow) && stepFlow.indexOf(STEP_NAME) > -1

/**
 * the users current progress through the checkout step flow.
 * use to go from CHECKOUT_STEP_NAME to STEPPER INDEX
 * @param {stepIsInFlowParams} args
 * @return {number}
 */
export const stepIndexFrom = ({ STEP_NAME, stepFlow }) =>
  stepIsInFlow({ STEP_NAME, stepFlow }) ? stepFlow.indexOf(STEP_NAME) : null

// for managing, and updating step flow on checkout, eg [AUTH, ORDER, PAYMENT,
// REVIEW, COMPLETE] for managing the current VIEW step of the STEPPER. (not
// CHECKOUT). Updating the index, updates the step, which then updates view.
/**
 * @typedef {Object} useStepFlowParams
 * @property {boolean} complete stepflow, array of all the steps, can change.
 */

/**
 * check if a step exists in a flow.
 * @param {useStepFlowParams} args
 * @return {any}
 */
export const useStepFlow = ({ complete }) => {
  const stepFlow = STEP_FLOW
  // TODO: use different flow for authenticated user
  // const customer = useSelector((state) => state.commerce.customer)
  // role is one of: auth, guest
  // const { isAuth } = useIsAuth()
  // // initial state
  // const [stepFlow, setStepFlow] = useState(() =>
  //   isAuth() ? AUTH_STEP_FLOW : GUEST_STEP_FLOW)

  // change based on role, remove the auth step when logged in.
  // useEffect(() => {
  //   setStepFlow(isAuth() ? AUTH_STEP_FLOW : GUEST_STEP_FLOW)
  // }, [isAuth, complete])

  /**
   * Check if a string representing a checkout step is in the active checkout
   * flow.
   * @param {{STEP_NAME: string}} stepParam
   * @return {boolean}
   */
  const stepIsInActiveFlow
    = ({ STEP_NAME }) => stepIsInFlow({
      stepFlow,
      STEP_NAME
    })

  /**
   * Get the index number of the step if in the current step flow, or null
   * @param {{STEP_NAME: string}} stepParam
   * @return {number|null}
   */
  const getStepIndex = ({ STEP_NAME }) => stepIndexFrom({
    STEP_NAME,
    stepFlow,
  })

  return {
    stepFlow: STEP_FLOW,
    stepIsInActiveFlow,
    getStepIndex
  }
}
import { combineReducers } from 'redux'
import settings from './settings'
import commerce from './commerce'
import ui from './ui'
import views from './views'
import checkout from './checkout'
import analytics from './analytics'

export default combineReducers({
  commerce,
  settings,
  ui,
  views,
  checkout,
  analytics
})

import { extend } from 'lodash'

/**
 * @typedef {Object} MetaTagItem
 * @property {string} key Item key
 * @property {string} value Item value
 */

/**
 * Normalize into Helmet meta items format.
 * @param {MetaTagItem} item
 * @return {Object<key, string> | null}
 */
export function normalizeMetaTags(item) {
  let dynamicFields = {}

  // Ignore Drupal canonical, this needs custom resolution at Gatsby so handled separately.
  if (item.key === 'canonical') {
    return null
  }

  // OG metas should have 'property' attribute.
  if (item.key.match(/^\w+:\w+$/i)) {
    dynamicFields = extend(dynamicFields, {
      'property': item.key
    })
  } else {
    dynamicFields = extend(dynamicFields, {
      'name': item.key
    })
  }

  return extend(dynamicFields, {
    content: item.value || '',
  })
}

import { getAllPageItems } from '../lib/pagination'
import { Skus } from '@atoms-studio/commercelayer-sdk'

/**
 * Get CL SKUs data for a list of SKU codes, max SKUs to get is 25.
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Array<string>} skus SKUs array
 */
const getSkuData = async (skus) => {
  if (skus.length > 25) {
    throw new Error('The maximum SKUs items to query in one request is 25.')
  }
  return await Skus.find({
    include: ['prices'],
    filters: {
      code_in: skus.join(','),
    },
    pageSize: 25,
  })
}

/**
 * Get CL all SKUs data for current session.
 * Note: CL automatically filter SKUs that are associated to the selected market scope (in session).
 *
 * @param {CommerceLayerClient} clClient CL API client
 */
const getAllSkusData = async () => {
  /**
   * Get SKUs page items callback.
   * @param {number} pageNumber Page number
   * @return {Promise<CLSkuListResponse>}
   */
  const getDataCallback = async (pageNumber = 1) => {
    return await Skus.findAll({
      include: ['prices'],
      page: {
        size: 25,
        number: pageNumber,
      },
    })
  }

  return await getAllPageItems(getDataCallback) //  skusData[0].items
}

export const skuMethods = {
  getSkuData,
  getAllSkusData
}

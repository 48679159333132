import React, { useMemo, useRef, useState } from 'react'
import { kebabCase, uniqueId, upperFirst } from 'lodash'
import striptags from 'striptags'
import { formDialogSx, useModal } from '@ggs/components/common'
import { useWindowSize } from '@ggs/hooks'
import Slider from 'react-slick'
import { useI18n } from '@ggs/gatsby/lib'
import MuiButton from '@mui/material/Button'
import { GridContainer } from '@ggs/components/layout'
import { Form, Title } from '@ggs/components/paragraphs'

// Assets.
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Carousel.scss'
import { icons } from '@ggs/components'
import { breakpoints } from '@ggs/styles'
import { Button } from '@ggs/gatsby/components/nav'
import { Component } from '@ggs/gatsby/components'

/**
 * @typedef {import('@ggs/types').CarouselItem} CarouselItem
 * @typedef {import('@ggs/components/common/Modal/useModal').ModalProps} ModalProps
 */

/**
 * Carousel Variant
 * @enum {string}
 */
const CarouselVariant = {
  default: 'default',
  landingPageHeroes: 'landing-page-heroes',
  largeQuotes: 'large-quotes',
  smallQuotes: 'small-quotes',
}

/**
 * @typedef {import('@ggs/types').FormParagraph} FormParagraph
 */
function CarouselItem({ item, component, openModal }) {
  return <Component
    key={item.id || uniqueId('carousel-item__')}
    {...item}
    type={component || item.type}
    openModal={openModal}
  />
}

const CarouselItemComponents = ({ carouselItems, activeIndex }) => {
  carouselItems?.map((item, index) => index === activeIndex
    ? <CarouselItem key={'carousel-item--' + uniqueId(item?.id)}
      item={item}/>
    : <></>)
}


/**
 * @typedef {Object} CarouselProps
 * @property {Array<CarouselItem>=} carouselItems
 * @property {String=} title
 * @property {FormParagraph=} submitForm
 * @property {CarouselVariant=} variant
 * @property {Object=} sx
 * @property {String=} component
 * @property {String=} className
 */
/**
 * Hero component.
 * @param {CarouselProps} props
 * @return {JSX.Element}
 */
export default function Carousel({
  carouselItems = [],
  title = '',
  submitForm = {},
  sx,
  variant = CarouselVariant.default,
  component,
  className,
}) {
  const { t } = useI18n()
  const windowSize = useWindowSize()
  const containerRef = useRef()
  const sliderRef = useRef()
  const slideIndexRef = useRef(0)
  const wrapperSx = {
    p: 0,
    '.grid-container': {
      px: 0,
    },
    '.grid-container__inner': {
      m: '0 auto',
    },
  }
  const slidesToShow = variant === CarouselVariant.smallQuotes ? 3 : 1
  const isRotate = carouselItems.length > slidesToShow

  /** @type {ModalProps} */
  const initialModalState = {
    title: null,
    content: (
      <div className="carousel__write-testimonial-form">
        <Form {...submitForm} />
      </div>
    ),
    sx: variant === CarouselVariant.smallQuotes ? formDialogSx : undefined,
  }
  const [/** @type {ModalProps} */ modalContent, setModalContent] = useState(initialModalState)
  const [activeSlide, setActiveSlide] = useState(0)
  // This modal crushes all modals in the Carousel with small quotes.
  const { modal: renderedModal, triggerModal } = useModal(modalContent)

  const openModal = (state = initialModalState) => {
    triggerModal(state)
  }

  const beforeChange = (/** @type {number} */ currentIndex, /** @type {number} */ nextIndex) => {
    // if (String(slideIndexRef.current)) {
    // alert('before change')
    // The useState hook makes the blinking issue. Because of this we are using useRef.
    // @ts-ignore
    // setForceRender(currentIndex)
    // setActiveSlide(nextIndex)
    // }
    setActiveSlide(nextIndex)
    slideIndexRef.current = nextIndex
  }

  const afterChange = (/** @type {number} */ currentIndex) => {
    // alert('before change')
    // The useState hook makes the blinking issue. Because of this we are using useRef.
    // @ts-ignore
    slideIndexRef.current = currentIndex
  }

  const settings = {
    arrows: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    beforeChange,
    afterChange,
    // afterChange: (currentIndex, nextIndex) => {
    //   // unmount focusable elements
    //   // setActiveSlide(carouselItemComponents[nextIndex])
    //   slideIndexRef.current.value = currentIndex
    // },
  }

  const next = () => {
    if (sliderRef.current) {
      // @ts-ignore
      sliderRef.current.slickNext()
    }
  }
  const previous = () => {
    if (sliderRef.current) {
      // @ts-ignore
      sliderRef.current.slickPrev()
    }
  }

  // Only run if variant changes, which is inlikely after page load.
  useMemo(() => {
    switch (variant) {
      case CarouselVariant.largeQuotes:
      case CarouselVariant.landingPageHeroes:
        Object.assign(settings, {
          // beforeChange,
          adaptiveHeight: true,
        })
        break
      case CarouselVariant.smallQuotes:
        Object.assign(settings, {
          fade: false,
          // beforeChange,
          responsive: [
            {
              breakpoint: breakpoints.lg,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: breakpoints.md,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        })
        break
      default:
        Object.assign(settings, {
          dots: true,
          adaptiveHeight: true,
          appendDots: (/** @type {any} */ dots) => (
            <GridContainer
              sx={{
                p: 0,
                position: 'relative',
                maxWidth: 1560,
              }}
            >
              <ul>{dots}</ul>
            </GridContainer>
          ),
          customPaging: (/** @type {number} */ i) => (
            <MuiButton>
              <>
                <div className="carousel__dot-count">{i + 1}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: upperFirst(striptags(String(carouselItems?.[i]?.title))),
                  }}
                />
                <span className="carousel__dot-grey-line"/>
                <span className="carousel__dot-red-line"/>
              </>
            </MuiButton>
          ),
        })
        Object.assign(wrapperSx, {
          maxWidth: '100%',
        })
    }
  }, [variant, settings, carouselItems])

  const carouselControls = () => {
    return (
      <>
        {(carouselItems.length > slidesToShow) && (
          <div className="carousel__controls-wrapper">
            <div className="carousel__controls">
              <div className="carousel__item-of-total">
                <span>
                  <input
                    className="carousel__current-item"
                    value={String(Number(activeSlide) + 1)}
                    defaultValue={String(1)}
                    readOnly={true}
                  />
                  {' / ' + carouselItems.length}
                </span>
              </div>
              <div className="carousel__arrows">
                <Button
                  className="carousel__prev-arrow"
                  aria-label={t('global:carousel.label.prevButton')}
                  onClick={previous}
                >
                  {icons.ChevronDown}
                </Button>
                <Button
                  className="carousel__next-arrow"
                  aria-label={t('global:carousel.label.nextButton')}
                  onClick={next}
                >
                  {icons.ChevronDown}
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <GridContainer
      ref={containerRef}
      className={`carousel ${className} carousel--${kebabCase(variant)} ${
        isRotate === false ? 'carousel--not-rotate' : ''
      }`}
      sx={{
        ...wrapperSx,
        ...sx,
      }}
    >
      <>
        <div className="carousel__inner">
          {title && (
            <GridContainer
              className="carousel__top"
              sx={{ pt: { xs: 0, md: 0 }, pb: { xs: 0, md: 0 } }}
            >
              <>
                <Title className="carousel__title" title={title} useFlare style="h2"/>
                {variant === CarouselVariant.smallQuotes && (
                  <Button
                    className="carousel__open-modal"
                    onClick={() => openModal()}
                    variant={Button.variant.outlined}
                  >
                    {t('global:carousel.label.writeATestimonial')}
                  </Button>
                )}
                {variant === CarouselVariant.smallQuotes && windowSize.lg && carouselControls()}
              </>
            </GridContainer>
          )}
          <Slider ref={sliderRef} {...settings}>
            {useMemo(() => carouselItems?.map((item, index) => {
              const isActiveSlide = String(index) === String(activeSlide)
              const linkActiveSlide = isActiveSlide ? item.link : undefined
              return (
                <Component
                  key={item.id || uniqueId('carousel-item__')}
                  wrapperSx={isActiveSlide
                    ? null
                    : ({
                      '.hero__content':
                        {
                          mb: 6
                        }
                    })
                  }
                  {...item}
                  type={component || item.type}
                  openModal={openModal}
                  link={linkActiveSlide}
                />
              )
            }), [activeSlide, slideIndexRef])}
          </Slider>
          {variant !== CarouselVariant.default && isRotate && (
            <GridContainer
              className="carousel__bottom"
              sx={{ pt: { xs: 0, md: 0 }, pb: { xs: 0, md: 0 } }}
            >
              {variant === CarouselVariant.smallQuotes && windowSize.belowLg
                ? carouselControls()
                : variant !== CarouselVariant.smallQuotes && carouselControls()}
            </GridContainer>
          )}
        </div>
        {renderedModal}
      </>
    </GridContainer>
  )
}

Carousel.variant = CarouselVariant

import React, { useMemo } from 'react'
import { uniqBy, uniqueId } from 'lodash'
import MegaMenu from '@ggs/components/nav/MainMenu/MegaMenu'
import MenuItem from '@ggs/components/nav/MainMenu/MenuItem'
import { useWindowSize } from '@ggs/hooks'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 */
/**
 * @typedef {Object} MainListProps
 * @property {Number=} level
 * @property {Array<DrupalMenuLink>=} menuItems
 * @property {Boolean=} megaMenu
 * @property {function} toggleDropdownMenu
 * @property {Boolean|Number=} dropdownOpen
 * @property {String=} footerContent
 * @property {Number=} headerHeight
 */
/**
 * @param {MainListProps} props
 * @return {JSX.Element}
 */
export default function MenuList({
  level,
  menuItems,
  megaMenu = false,
  toggleDropdownMenu = null,
  dropdownOpen = false,
  footerContent = null,
  headerHeight = null,
}) {
  const menu = useMemo(()=>uniqBy(menuItems,'uuid'),[menuItems])
  const windowSize = useWindowSize()

  // console.log('menuItems: ', menuItems)
  return (
    <>
      { megaMenu ? (
        <MegaMenu
          level={level}
          menuItems={menu}
          footerContent={footerContent}
          toggleDropdownMenu={toggleDropdownMenu}
          headerHeight={headerHeight}
        />
      ) : (
        <ul
          className={`menu-list menu-list--level-${level}`}
          style={{ top: (windowSize.lg === true && level === 1) ? `${headerHeight}px` : null }}
        >
          {menu.map((menuLink) =>
            <MenuItem
              key={uniqueId(`menu-item-${menuLink.uuid}`)}
              {...menuLink}
              level={level}
              toggleDropdownMenu={toggleDropdownMenu}
              dropdownOpen={dropdownOpen}
              headerHeight={headerHeight}
            />
          )}
        </ul>
      )}
    </>
  )
}

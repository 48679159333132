import { SiteNotice } from '@ggs/components/nav'
import { goToHomepage, useI18n } from '@ggs/gatsby/lib'
import React from 'react'
import { useUserPermissions } from '@ggs/components/hooks/index'
import { Button } from '@ggs/gatsby/components/nav'
import { useSelector } from '@ggs/store'

/**
 * @typedef {Object} HcpGatedModalProps
 * @property {import('react').MouseEventHandler=} onSubmit
 * @property {import('react').MouseEventHandler=} onClose
 * @property {boolean=} requireHcpGating
 */
const getHcpGatedModalFromPageContext = (pageContext) => pageContext?.data?.layoutMeta?.currentStore?.hcpGatedModal
/**
 * An optional modal for displaying a notice to users who are not HCPs.
 * @param {HcpGatedModalProps} props
 * @return {JSX.Element}
 */
export default function HcpGatedModal({ requireHcpGating, onSubmit, onClose = goToHomepage }) {
  const { t } = useI18n()
  const { hcpGatedModal } = useSelector((state) => state.ui.layoutMeta.currentStore)

  // Obtain the user permissions for HCP.
  const {
    hcp: { shouldGateHcpContent, forceHcpRole },
  } = useUserPermissions()

  return shouldGateHcpContent && requireHcpGating ? (
    <SiteNotice
      label={hcpGatedModal}
      variant={SiteNotice.variant.modal}
      modalActive={true}
      modalProps={{
        onSubmit: onSubmit || forceHcpRole,
        onClose,
        submitButton: {
          label: t('global:button.userIsHcp'),
          variant: Button.variant.contained,
        },
        closeButton: {
          label: t('global:button.userIsNotHcp'),
          variant: Button.variant.outlined,
        },
        sx: {
          '.MuiDialog-paper': {
            width: 580,
          },
          '.dialog__content': {
            textAlign: 'center',
          },
          '.dialog__actions': {
            flexWrap: 'wrap',
            '.MuiButton-root': {
              display: 'block',
              flex: {
                xs: '0 0 90%',
                md: '0 0 50%',
              },
              mb: 1,
              '&, &:hover': {
                mx: {
                  xs: '5%',
                  md: '25%',
                },
              },
              '&:last-child': {
                mb: 0,
              },
              span: {
                textTransform: 'none',
              },
            },
          },
        },
      }}
    />
  ) : null
}

import React from 'react'
// Assets
import Box from '@mui/material/Box'
// Hooks

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 * @typedef {import('@ggs/types').SettingsProps} SettingsProps
 */

/**
 * @typedef {Object} ComponentLayoutProps
 * @property {SettingsProps=} settings
 * @property {String=} wrapperClassName
 * @property {SxProps=} wrapperSx
 * @property {JSX.Element} children
 */

/**
 * @param {ComponentLayoutProps} props
 * @return {JSX.Element}
 */
export default function ComponentLayout(props) {
  const { wrapperClassName = null, wrapperSx = null, children } = props

  // for debug
  return (wrapperClassName || wrapperSx)
    ? <Box className={wrapperClassName} sx={wrapperSx}>{children}</Box>
    : children
}

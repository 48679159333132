import React from 'react'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'
import { ObservedElement } from '@ggs/components/hooks/useElementInViewPort'
import { GaProductItem } from '@ggs/components/ecommerceAnalytics/ga/GaProductItem'

const itemIdFromNodeSku = ({ nodeId, sku = null }) => {
  if (sku) {
    return sku
  }
  if (nodeId) {
    return `node_${nodeId}`
  }
  return null
}

export const categoryObj = ({ productCategories = [] }) =>
  Array.isArray(productCategories) && productCategories.length
    ? productCategories.reduce(
      (p, c, i) => ({
        ...p,
        [i > 0 ? `item_category${i + 1}` : 'item_category']: c.name,
      }),
      {}
    )
    : {}

export const parseDefaultItemFromOption = (product) => {
  if (product?.defaultOption?.sku?.length) {
    return {
      ...GaProductItem({
        item_id: itemIdFromNodeSku({
          nodeId: product?.entityId,
          sku: product?.defaultOption?.sku?.[0]?.sku,
        }),
        item_name: product.name ?? product.defaultOption.label,
        ...categoryObj(product),
      }),
      type: 'product',
    }
  } else if (product.productOptions?.[0]?.sku?.[0]?.sku) {
    return {
      ...GaProductItem({
        item_id: itemIdFromNodeSku({
          sku: product.productOptions?.[0]?.sku?.[0]?.sku,
          nodeId: product.entityId,
        }),
        item_name:
          product.name ?? product.productOptions?.[0]?.label ?? product?.defaultOption?.name,
        ...categoryObj(product),
      }),
      type: 'product',
    }
  } else {
    return {
      ...GaProductItem({
        item_id: itemIdFromNodeSku({
          nodeId: product.entityId,
        }),
        ...categoryObj(product),
        item_name:
          product.name ?? product.productOptions?.[0]?.label ?? product?.defaultOption?.name,
      }),
      type: 'content',
    }
  }
}

export const selectedProductOrDefault = (product) => {
  const { activeOption = null, defaultOption = null, productOptions = [] } = product
  if (activeOption?.id) {
    return {
      item_id: itemIdFromNodeSku({
        nodeId: activeOption.id,
        sku: activeOption?.sku?.[0]?.sku,
      }),
      item_name: product.name ?? product.defaultOption.label,
    }
  } else return parseDefaultItemFromOption(product)
}

/**
 * @param products
 * @returns {Array}
 */
export const parseDefaultItemFromOptionsList = ({ products = [] }) =>
  products.map(parseDefaultItemFromOption)

const filterProductsFromContent = (product) => {
  return (
    product?.defaultOption?.sku?.length || product.productOptions.filter((pO) => pO.sku?.length)
  )
}

const parseObservedList = (items) => ({
  products: items.filter((product) => filterProductsFromContent),
  content: items.filter((product) => !filterProductsFromContent),
})

/**
 * A container that will fire the viewProductList when the element is viewed (using intersection observer)
 * @param items
 * @param item_list_name
 * @param item_list_id
 * @param children
 * @returns {JSX.Element}
 */
export const ViewProductListObserver = ({ items, item_list_name, item_list_id, children }) => {
  const { fireViewProductInList } = useCheckoutAnalytics()
  // we have viewed a list of items
  // are the items products?
  // does the item have a default option with a SKU?
  // does the item have a product Option with a SKU?
  // does the item have a price in the current market
  return (
    <ObservedElement
      callback={() => {
        const { products, content } = parseObservedList(items)
        const productItems = products.map(productToGaProductItem)
        const gtmItems = Array.isArray(products)
          ? parseDefaultItemFromOptionsList({ products })
          : []
        fireViewProductInList({ items: gtmItems, item_list_name, item_list_id })
      }}
    >
      {children}
    </ObservedElement>
  )
}

const productToGaProductItem = (product) => {
  const { productCategories = [], defaultOption, productOptions } = product

  if (product?.defaultOption?.sku?.length) {
    return {
      ...GaProductItem({
        item_id: itemIdFromNodeSku({
          nodeId: product?.entityId,
          sku: product?.defaultOption?.sku?.[0]?.sku,
        }),
        item_name: product.name ?? product.defaultOption.label,
        type: 'product',
        ...categoryObj(product),
      }),
    }
  } else if (product.productOptions?.[0]?.sku?.[0]?.sku) {
    return {
      ...GaProductItem({
        item_id: itemIdFromNodeSku({
          sku: product.productOptions?.[0]?.sku?.[0]?.sku,
          nodeId: product.entityId,
        }),
        item_name:
          product.name ?? product.productOptions?.[0]?.label ?? product?.defaultOption?.name,
        type: 'product',
        ...categoryObj(product),
      }),
    }
  } else {
    return {
      ...GaProductItem({
        item_id: itemIdFromNodeSku({
          nodeId: product.entityId,
        }),
        item_name:
          product.name ?? product.productOptions?.[0]?.label ?? product?.defaultOption?.name,
        type: 'content',
        ...categoryObj(product),
      }),
    }
  }
}

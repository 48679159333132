import { parseOrder } from '@ggs/components/ecomm/Checkout/commerce/app/helpers/parseCommerceOrderDataFieldMap'
import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'

const {
  // AUTH,
  COMPLETE,
  ORDER: SHIPPING,
  PAYMENT: BILLING,
  REVIEW
} = CHECKOUT_STEP_ENUMS

export const parseOrderFn = (o) => {
  const orderId = o?.id
  const orderItems = o?.line_items
  // auth
  // const customerId = parseOrder[AUTH].customerId(o)
  // shipping.
  const shippingCountryCode = parseOrder[SHIPPING].shippingCountryCode(o)
  const shippingStateCode = parseOrder[SHIPPING].shippingStateCode(o)
  const shippingAddressId = parseOrder[SHIPPING].shippingAddressId(o)
  const shippingMethodId = parseOrder[SHIPPING].shippingMethodId(o)

  // BILLING / PAYMENT
  const billingCountryCode = parseOrder[BILLING].billingCountryCode(o)
  const billingStateCode = parseOrder[BILLING].billingStateCode(o)
  const billingAddressId = parseOrder[BILLING].billingAddressId(o)

  // PAYMENT METHOD
  const paymentMethodId = parseOrder[BILLING].paymentMethodId(o)

  // PAYMENT SOURCE
  const paymentSourceId = parseOrder[BILLING].paymentSourceId(o)

  // PLACED ORDER // COMPLETE
  // "status":"placed"
  // data: {type: "orders", attributes: {_place: true}, relationships: {}, id:
  // "qQgYhDybrE"} attributes: {_place: true} id: "qQgYhDybrE" relationships: {}
  // type: "orders"
  const acceptTerms = o.status === 'placed' ? o.id : null// last?

  return {
    // cart order id
    orderId,
    orderItems,
    // AUTH
    // customerId,
    // SHIPPING ADDRESS
    shippingCountryCode,
    shippingStateCode,
    shippingAddressId,
    // SHIPPING METHOD
    shippingMethodId,
    // BILLING ADDRESS
    billingCountryCode,
    billingStateCode,
    billingAddressId,
    // PAYMENT METHOD
    paymentMethodId,
    paymentSourceId,
    acceptTerms
  }

}

export const OrderParse = (/** @type {any} */ o) => {
  const parsedOrder = parseOrderFn(o)
  const orderInspector = OrderInspector(parsedOrder)

  return {
    checkoutState: parsedOrder,
    stepsComplete: orderInspector.stepsComplete,
    ...orderInspector
  }
}

/**
 * @param {any} parsedOrder
 * @return {any}
 */
const OrderInspector = (
  parsedOrder
) => {

  const { // cart order id
    orderId,
    orderItems,
    // AUTH
    customerId,
    // SHIPPING ADDRESS
    shippingCountryCode,
    shippingStateCode,
    shippingAddressId,
    // SHIPPING METHOD
    shippingMethodId,
    // BILLING ADDRESS
    billingCountryCode,
    billingStateCode,
    billingAddressId,
    // PAYMENT METHOD
    paymentMethodId,
    paymentSourceId
  } = parsedOrder

  const orderExists = orderId && orderItems?.length
  const isGuest = !customerId
  const isShippingAddressComplete = orderExists && shippingCountryCode && shippingStateCode && shippingAddressId
  const isShippingMethodSelected = isShippingAddressComplete && shippingMethodId
  const isBillingAddressComplete
    = isShippingMethodSelected && billingCountryCode && billingStateCode && billingAddressId
  const isPaymentComplete = isBillingAddressComplete && paymentMethodId && paymentSourceId
  const orderHasItems = Array.isArray(parsedOrder.orderItems)
    && (parsedOrder.orderItems.length)

  const checkPrereqs = () => {
    return {
      // [AUTH]: orderExists && !isGuest,
      [SHIPPING]: orderExists,
      [BILLING]: isShippingMethodSelected,
      [REVIEW]: isPaymentComplete,
      [COMPLETE]: orderExists
    }
  }

  const calculateStepsComplete = () => {
    return {
      // [AUTH]: true,
      [SHIPPING]: isShippingMethodSelected,
      [BILLING]: isPaymentComplete,
      [REVIEW]: !orderExists,
      [COMPLETE]: !orderExists
    }
  }

  return {
    prereqs: checkPrereqs(),
    // stepsComplete: calculateStepsComplete(),
    checks: {
      orderExists,
      orderHasItems,
      // isGuest,
      isShippingAddressComplete,
      isShippingMethodSelected,
      isBillingAddressComplete,
      isPaymentComplete,
    }
  }
}
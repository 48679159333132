import React, { useEffect, useMemo, useState } from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import Grid from '@mui/material/Grid'
import { Link } from '@ggs/gatsby/components/nav'
import MenuList from '@ggs/components/nav/MainMenu/MenuList'
import { useWindowSize } from '@ggs/hooks'

// Assets.
import { icons } from '@ggs/components'
import { uniqBy } from 'lodash'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */
/**
 * @typedef {Object} MegaMenuItemHasSubmenuProps
 * @property {Number=} level
 * @property {String=} label
 * @property {String=} url
 * @property {ImageStyles=} icon
 * @property {Array<DrupalMenuLink>=} menuItems
 * @property {function} toggleDropdownMenu
 */
/**
 * @param {MegaMenuItemHasSubmenuProps} props
 * @return {JSX.Element}
 */
export default function MegaMenuItemHasSubmenu({
  level,
  label,
  url,
  menuItems,
  toggleDropdownMenu
}) {
  const { t } = useI18n()
  const windowSize = useWindowSize()
  const [columnCount, setColumnCount] = useState(3)
  const menu = useMemo(()=>uniqBy(menuItems,'uuid'),[menuItems])

  useEffect(() => {
    // Calculate grid column count for the Mega Menu item with submenu.
    /** @type {Number} */
    if (windowSize.lg === true && windowSize.smallDesktopOnly === false) {
      setColumnCount(3)
    } else if (windowSize.lg === true && windowSize.smallDesktopOnly === true) {
      setColumnCount(4)
    }
  }, [windowSize.smallDesktopOnly])

  return (
    <Grid
      className="mega-menu-item-has-submenu"
      item
      xs={12}
      // @ts-ignore
      lg={columnCount}
    >
      <div className="mega-menu-item-has-submenu__inner">
        <span className="mega-menu-item-has-submenu__label" dangerouslySetInnerHTML={{ __html: label }} />
        <Link
          className="mega-menu-item-has-submenu__link"
          href={url}
        >
          <>
            <span>{t('global:button.learnMore')}</span>
            {icons.ChevronDown}
          </>
        </Link>
        <MenuList
          megaMenu={false}
          level={level+1}
          menuItems={menu}
          toggleDropdownMenu={toggleDropdownMenu}
        />
      </div>
    </Grid>
  )
}

import React from 'react'
import { kebabCase } from 'lodash'
import striptags from 'striptags'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './Image.scss'

/**
 * @typedef {import('gatsby-plugin-image')} ImageDataLike
 */
/**
 * @typedef {Object} ImageProps
 * @property {String=} className
 * @property {String=} type
 * @property {ImageDataLike=} image
 * @property {String} alt
 * @property {"eager" | "lazy" | undefined} loading
 */
/**
 * @param {ImageProps} props
 * @return {JSX.Element}

 */
export default function Image({ className = '', image, type = '', alt, loading }) {
  // @ts-ignore
  const rendered = getImage(image)
  return rendered ? (
    <GatsbyImage
      className={`${className} image-type__${kebabCase(type)}`}
      image={rendered}
      alt={striptags(String(alt))}
      loading={loading}
    />
  ) : null
}

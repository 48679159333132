import React, { useMemo } from 'react'
import { Divider } from '@ggs/components/paragraphs'
import { find, size } from 'lodash'
import { Forms } from '@ggs/forms/schema'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 * @typedef {import('@ggs/types').LayoutMeta} LayoutMeta
 */
/**
 * @typedef {Object} ReviewReminderProgram
 * @property {Order} order
 * @property {string=} checkoutReminderProgramNotice
 * @property {{reminderPrograms: Array<{tid: number, name: string}>}=} items
 */
/**
 *
 * @param {ReviewReminderProgram} props
 * @return {Object|null}
 */
export default function useReminderProgramFields(props) {
  const {
    order,
    checkoutReminderProgramNotice,
  } = props
  const reminderPrograms = props?.items?.reminderPrograms || []
  /** @type {Object} */
  const cartReminderPrograms = useMemo(() => {
    /** @type {*} */
    const programs = {}
    reminderPrograms.forEach(({ tid, name }) => {
      if (find(order?.line_items, ['metadata.reminder_programs', tid])) {
        programs[`reminder_${tid}`] = {
          label: name,
          type: Forms.FieldTypes.checkbox,
          defaultValue: false,
        }
      }
    })
    return programs
  }, [order?.line_items])

  console.log('useReminderProgramFields', {
    reminderPrograms,
    cartReminderPrograms,
  })

  return size(cartReminderPrograms) > 0
    ? {
      caption: {
        caption: <div dangerouslySetInnerHTML={{ __html: checkoutReminderProgramNotice }}/>,
        type: Forms.FieldTypes.content,
      },
      ...cartReminderPrograms,
      footer: {
        caption: <Divider sx={{ my: 3 }}/>,
        type: Forms.FieldTypes.content,
      },
    }
    : null
}

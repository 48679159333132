import React from 'react'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { Text } from '@ggs/components/paragraphs'

// Assets.
import './ImageTextItem.scss'

/**
 * @typedef {import('@ggs/types').ImageStyles} ImageStyles
 */
/**
 * @typedef {Object} ImageTextItemProps
 * @property {ImageStyles=} image
 * @property {String=} description
 */
/**
 * ImageTextItem component.
 * @param {ImageTextItemProps} props
 * @return {JSX.Element}
 */
export default function ImageTextItem({ image, description = '' }) {
  return (
    <div className="image-text-item">
      <div className="image-text-item__image">
        <ImageStyles {...image} selectedStyles={['inlineImageFull', 'inlineImageSmall']}/>
      </div>
      <Text textContent={description}/>
    </div>
  )
}

import React from 'react'
import { uniqueId } from 'lodash'
import { GridContainer } from '@ggs/components/layout'
import { ImageCta, Title } from '@ggs/components/paragraphs'
import { Button } from '@ggs/gatsby/components/nav'
// Assets
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import HeaderBackgroundAccent from '@ggs/components/flare/HeaderBackgroundAccent'

/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 * @typedef {import('@ggs/components/paragraphs/ImageCta/ImageCta').ImageCtaProps} ImageCtaProps
 */

/**
 * @typedef {Object} HighlightCtaProps
 * @property {string} title
 * @property {Array<ImageCtaProps>} ctas
 * @property {LinkField=} link
 */

/**
 * SharedContent component.
 * @param {HighlightCtaProps} props
 * @return {JSX.Element}
 */
export default function HighlightCta({ title, ctas, link }) {
  // eslint-disable-next-line prefer-rest-params
  // console.log('SharedContent render', content) //, JSON.stringify(arguments[0]))

  const sx = {
    position: 'relative',
    pt: {
      xs: 10,
      md: 16,
    },
    pb: {
      xs: 7,
      md: 10,
    },
    '.header-background-accent': {
      display: {
        xs: 'none',
        md: 'block',
      },
    },
    '.highlight-cta__content': {
      '> .title, .highlight-cta__link': {
        textAlign: 'center',
      },
      '> .title .heading-flare': {
        left: 'calc(50% - 32px)',
      },
      '.highlight-cta__ctas': {
        maxWidth: 1080,
        mx: 'auto',
        '.button': {
          p: 0,
        },
        '> .highlight-cta__item': {
          '&:first-child': {
            '.grid-container__inner': {
              pt: {
                md: 5,
              },
            },
          },
          '.grid-container__inner': {
            pt: {
              md: 2,
            },
          },
          '.gatsby-image-wrapper': {
            boxShadow: '0 0 16px rgba(0, 0, 0, .04)',
            width: '100%',
          },
          '.image-cta__content': {
            flexBasis: {
              md: '50%',
            },
            maxWidth: {
              md: '50%',
            },
          },
          '.image-cta__image': {
            flexBasis: {
              md: '50%',
            },
            maxWidth: {
              md: '50%',
            },
          },
          '.image-cta__link': {
            mt: {
              lg: 3,
            },
          },
        },
      },
    },
  }

  return (
    <GridContainer className={'highlight-cta'} sx={sx}>
      <HeaderBackgroundAccent variant={HeaderBackgroundAccent.variant.pink} />
      <div className={'highlight-cta__content'}>
        <Title title={title} useFlare />
        <div className={'highlight-cta__ctas'}>
          {ctas.map((item) => (
            <ImageCta
              className={'highlight-cta__item'}
              key={uniqueId(`highlight_cta_item_${item.id}`)}
              buttonTheme={Button.variant.text}
              {...item}
            />
          ))}
        </div>
        {link?.title && link?.url && (
          <div className={'highlight-cta__link'}>
            <Button
              link={link}
              endIcon={<ChevronRightIcon />}
              variant={Button.variant.outlined}
              data-label={link.title}
              data-action-type={'navigate-highlight-cta'}
              data-page-path={link.url}
            >
              <span
                dangerouslySetInnerHTML={{
                  // @ts-ignore
                  __html: link.title,
                }}
              />
            </Button>
          </div>
        )}
      </div>
    </GridContainer>
  )
}

// @ts-nocheck
/* eslint-disable react/jsx-no-duplicate-props */
import React, { forwardRef } from 'react'
import MaskedInput from 'react-text-mask'
// Components
import TextField from './TextField'
// Data
// Assets

// const propTypes = Forms.PhoneInputProps
// const defaultProps = Forms.PhoneInputDefaults

function PhoneMask(props) {
  // eslint-disable-next-line react/prop-types
  const { ref: inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef && inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
      guide={false}
    />
  )
}

// eslint-disable-next-line react/display-name
const PhoneInput = forwardRef(({ label, inputProps, ...fieldProps }, ref) => {
  return (
    <TextField
      {...fieldProps}
      ref={ref}
      label={label}
      inputProps={inputProps}
      InputProps={{
        inputComponent: PhoneMask,
      }}
    />
  )
})

// Exports
// PhoneInput.defaultProps = defaultProps
// PhoneInput.propTypes = propTypes
export default PhoneInput

// @ts-nocheck
import React, { useEffect, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@ggs/components/nav/MainMenu/MenuList'
import ToggleMenu from '@ggs/components/nav/MainMenu/ToggleMenu'
import { SocialMedia } from '@ggs/components/nav'
import HeaderSelectors from '@ggs/components/layout/Header/HeaderSelectors'
import { useWindowSize } from '@ggs/hooks'
import { Memoized } from '@ggs/components/common'
// Assets
import './MainMenu.scss'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 */
/**
 * @typedef {Object} MainMenuProps
 * @property {Number=} level
 * @property {Array<DrupalMenuLink>=} menuItems
 * @property {Array<DrupalMenuLink>=} socialLinks
 * @property {String=} email
 * @property {Object=} headerRef
 */
/**
 * @param {MainMenuProps} props
 * @return {JSX.Element}
 */
export default function MainMenu({ level = 0, menuItems = [], socialLinks = [], email = '', headerRef }) {
  const windowSizes = useWindowSize()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  // @TODO: This will need for active menu classes.
  const [activeMenuItem, setActiveMenuItem] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(null)

  useEffect(() => {
    // Logic to hide logo if the mobile menu is open.
    if (!mobileOpen) {
      document.body.classList.remove('mobile-open')
    } else if (mobileOpen) {
      document.body.classList.add('mobile-open')
    }
  }, [mobileOpen])

  const toggleMobileMenu = (open = false) => {
    setMobileOpen(open)
  }

  const toggleDropdownMenu = (id = false) => {
    setHeaderHeight(headerRef.current.clientHeight)
    setDropdownOpen(id)
  }

  const menuClickAway = () => {
    setDropdownOpen(false)
  }

  return (
    <Memoized deps={[mobileOpen, dropdownOpen, windowSizes.belowMd]}>
      <ClickAwayListener onClickAway={menuClickAway}>
        <div className="main-menu__wrapper">
          <ToggleMenu toggleMenu={toggleMobileMenu} mobileOpen={mobileOpen}/>
          <div className={mobileOpen !== false ? 'main-menu mobile-open' : 'main-menu'}>
            <nav role="navigation" aria-label="Main menu">
              <MenuList
                level={level}
                menuItems={menuItems}
                toggleDropdownMenu={toggleDropdownMenu}
                dropdownOpen={dropdownOpen}
                headerHeight={headerHeight}
              />
            </nav>
            {windowSizes.belowMd && <HeaderSelectors/>}
            <SocialMedia email={email} socialLinks={socialLinks}/>
          </div>
        </div>
      </ClickAwayListener>
    </Memoized>
  )
}

import React from 'react'
import { find, uniqueId } from 'lodash'
import { useI18n } from '@ggs/gatsby/lib'
import { Link } from '@ggs/gatsby/components/nav'

// Assets
import { icons } from '@ggs/components'
import './SocialMedia.scss'

/**
 * SocialMediaVariant
 * @enum {string}
 */
const SocialMediaVariant = {
  default: 'default',
  footer: 'footer',
}

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 */
/**
 * @typedef {Object} MainMenuProps
 * @property {Array<DrupalMenuLink>=} socialLinks
 * @property {String=} email
 * @property {SocialMediaVariant=} variant
 */
/**
 * @param {MainMenuProps} props
 * @return {JSX.Element}
 */
export default function SocialMedia({
  socialLinks,
  email = '',
  variant = SocialMediaVariant.default,
}) {
  const { t } = useI18n()
  // console.log('SocialLinks: ', socialLinks)

  return (
    <div className={`social-media social-media--${variant}`}>
      {email && (
        <div className="social-media__get-in-touch">
          <div className="social-media__label">{t('global:label.getInTouch')}</div>
          <div className="social-media__content">
            <Link
              href={`mailto:${email}`}
              data-label={`mailto ${email}`}
              data-action-type={'email-us'}
            >
              {email}
            </Link>
          </div>
        </div>
      )}
      <div className="social-media__links">
        {variant === SocialMediaVariant.default && (
          <div className="social-media__label">{t('global:label.followUs')}</div>
        )}
        <div className="social-media__items">
          {socialLinks.map((socialLink) => (
            <Link
              key={uniqueId(`social-media-link-${socialLink.label}`)}
              className="social-media__item"
              aria-label={socialLink.label}
              href={socialLink.url}
            >
              {
                // @ts-ignore
                icons[socialLink.label]
              }
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

SocialMedia.variant = SocialMediaVariant

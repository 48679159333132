/* eslint-disable global-require */

module.exports = {
  // onClientEntry: require('./onClientEntry'),
  onInitialClientRender: require('./onInitialClientRender'),
  onPreRouteUpdate: require('./onPreRouteUpdate'),
  onRouteUpdate: require('./onRouteUpdate'),
  wrapRootElement: require('./wrapRootElement'),
  wrapPageElement: require('./wrapPageElement'),
}

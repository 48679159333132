/* eslint-disable max-len */
import Box from '@mui/material/Box'
import React from 'react'
import { Divider, Text } from '@ggs/components/paragraphs'

/**
 * @param {{productDisclaimer:String}} props
 * @return {JSX.Element}
 */
export default function OrderShippingAndReturnPolicies({ productDisclaimer = '' }) {
  return (
    <Box
      className="order-cart-checkout-flow"
      sx={{
        mb: 4,
        '& .divider': {
          mt: 4,
          mb: 0,
        },
      }}
    >
      {' '}
      {/*  This product data model will be provided some global content from the Order - Cart/Checkout Flow ctype, which t hen will be feed here */}
      {/*This content will come from Drupal later*/}
      <Text textContent={productDisclaimer}/>
      {/* ^^^^ This content will come from Drupal later*/}
      <Divider />
    </Box>
  )
}

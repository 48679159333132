import React, { useLayoutEffect, useMemo } from 'react'
import { GridContainer } from '@ggs/components/layout'
import { useModal } from '@ggs/components/common'
import { useI18n } from '@ggs/gatsby/lib'
import { Button } from '@ggs/gatsby/components/nav'
import { icons } from '@ggs/components'
// Assets
import useSiteNoticeSx, { SiteNoticeVariant } from '@ggs/components/nav/SiteNotice/useSiteNoticeSx'
import { typography } from '@ggs/styles'
import { useDispatch } from 'react-redux'
import { clearNotice, useSelector } from '@ggs/store'
import { hashValue } from '@ggs/utils'
import Text from '../../paragraphs/Text/Text'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 * @typedef {import('@ggs/components/common/Modal/useModal').ModalProps} ModalProps
 */

/**
 * @typedef {Object} SiteNoticeProps
 * @property {String} label
 * @property {SiteNoticeVariant} variant
 * @property {ModalProps=} modalProps
 * @property {SxProps=} sx
 */
/**
 *
 * @param {SiteNoticeProps} props
 * @return {JSX.Element}
 */
export default function SiteNotice(props) {
  const { t } = useI18n()
  const { label, variant, modalProps, modalActive = false } = props
  const { sx, backgroundColor } = useSiteNoticeSx(variant, props.sx)
  const hashId = useMemo(() => hashValue(label), [label])

  const dispatch = useDispatch()
  const clearedNotices = useSelector((state) => state.settings.clearedNotices)

  // Track whether the notice should vbe displayed or not.
  const shouldDisplay = useMemo(() => {
    const disabledNotice = clearedNotices.indexOf(hashId) !== -1
    return !!label && !disabledNotice
  }, [label, clearedNotices])
  const closeNotice = () => {
    dispatch(clearNotice(label))
  }
  // Generate the notice
  const notice = useMemo(
    () =>
      shouldDisplay ? (
        <GridContainer
          className="site-notice"
          fullWidthBackground
          backgroundColor={backgroundColor}
          sx={sx}
        >
          <div className="site-notice__label">
            <Text textContent={label}/>
          </div>
          <Button
            className="site-notice__close"
            onClick={closeNotice}
            aria-label={t('global:aria.label.closeThisNotice')}
            variant={'icon'}
          >
            {icons.CloseBlack}
          </Button>
        </GridContainer>
      ) : null,
    [label, shouldDisplay]
  )

  const { modal, triggerModal } = useModal({
    sx: {
      // '.MuiDialog-container': {
      // },
      '.MuiPaper-root': {
        // p: 0,
      },
      '.dialog__content': {
        typography: typography.pBold,
        textAlign: 'left',
        md: {
          width: 480,
          margin: '0 auto',
        },
      },
      ...modalProps?.sx,
    },
    content: ({ onClose }) => <div dangerouslySetInnerHTML={{ __html: label }}/>,
    ...modalProps,
  })

  useLayoutEffect(() => {
    if (modalActive && label && variant === SiteNoticeVariant.modal) {
      triggerModal()
    }
  }, [variant, modalActive, label])

  // console.log('SiteNotice render', {
  //   label,
  //   variant,
  //   modalProps,
  //   sx,
  //   backgroundColor,
  //   hashId,
  //   clearedNotices,
  //   shouldDisplay,
  // })

  // If we have a label, display the desired element. Else, nothing.
  // @ts-ignore
  return (variant === SiteNoticeVariant.modal ? modal : null) || notice
}

SiteNotice.variant = SiteNoticeVariant

import React, { useMemo } from 'react'
import { useLink } from '@ggs/gatsby/lib'
import { GridContainer } from '@ggs/components/layout'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { Link } from '@ggs/gatsby/components/nav'
import { Title } from '@ggs/components/paragraphs'
import Box from '@mui/material/Box'
// Assets
import './TextCard.scss'
import { icons } from '@ggs/components'
import { ButtonVariant } from '@ggs/types'
import { colors, zIndex } from '@ggs/styles'
import { getGTMEventLabel } from '@ggs/utils/getGTMEventLabel'

/**
 * @typedef {import('@ggs/types').LinkField} LinkField
 */

/**
 * TextCard Variant
 * @enum {string}
 */
const TextCardVariant = {
  default: 'default',
  selectGroup: 'select-group',
  centered: 'centered',
}

/**
 * @typedef {Object} TextCardProps
 * @property {String=} id
 * @property {String=} type
 * @property {Element=} image
 * @property {String=} label
 * @property {String=} textContent
 * @property {LinkField=} link
 * @property {function=} onClick
 * @property {Boolean=} active
 * @property {String=} variant
 * @property {String=} linkStyle
 * @property {Element=} children
 */

const processLinkLangCode = ({ link, langCode = 'en-CA' }) => {
  if (link?.url) {
    try {
      const langLinkMap = JSON.parse(link.url)
      return langLinkMap?.[langCode] ?? langLinkMap['en-CA']
    } catch {
      return link?.url
    }
  }
}
/**
 * @param {TextCardProps} props
 * @return {JSX.Element}
 */
export default function TextCard({
  label = '',
  textContent = '',
  image = null,
  link = null,
  active = false,
  linkStyle = ButtonVariant.text,
  variant = TextCardVariant.default,
  language: { id: langCode = 'en-CA' } = { id: 'en-CA' },
  children,
} = {}) {
  const safeUrl = useLink(true)
  const sx = {}
  switch (variant) {
    case TextCardVariant.selectGroup:
    case TextCardVariant.centered:
      Object.assign(sx, {
        p: 0,
      })
      break
  }

  const { uri } = useMemo(
    () =>
      langCode
        ? safeUrl({ href: processLinkLangCode({ link, langCode }) ?? link?.uri ?? link?.url })
        : safeUrl({ href: link?.uri || link?.url }),
    [link?.url, link?.uri]
  )

  const cardContent = useMemo(() => {
    const sx = {
      '.text-card__content': {
        p: 4,
        '.title': {
          mb: 2,
        },
      },
    }

    if (image) {
      Object.assign(sx, {
        display: {
          lg: 'inline-flex',
        },
        alignItems: 'center',
        justifyContent: 'space-between',
        '.text-card__copy': {
          flex: '1 1 auto',
          p: 2,
        },
      })
    }

    console.log('TextCard', {
      link,
      uri,
    })

    return (
      <Card
        className="text-card__card"
        sx={{
          backgroundColor: `${colors.white}`,
          zIndex: zIndex.zOverlay,
          position: 'relative',
        }}
      >
        <Box sx={sx}>
          {image && (
            <Box
              className={'text-card__image'}
              sx={{
                pl: 7,
                flex: '0 0 128px',
              }}
            >
              {image}
            </Box>
          )}
          <div className={'text-card__copy'}>
            <CardContent className="text-card__content">
              {!!label && <Title title={label} style="h3" />}
              {textContent && (
                <div
                  className="text-card__body"
                  dangerouslySetInnerHTML={{ __html: textContent }}
                />
              )}
              {children}
            </CardContent>
            {uri && link?.title && (
              <CardActions
                className="text-card__actions"
                sx={{
                  pt: 0,
                  pb: 4,
                  px: 2,
                }}
              >
                <div className={`text-card__link text-card__link--${linkStyle}`}>
                  <span
                    dangerouslySetInnerHTML={{
                      // @ts-ignore
                      __html: link?.title,
                    }}
                  />
                  {linkStyle !== ButtonVariant.contained && icons.ChevronDown}
                </div>
              </CardActions>
            )}
          </div>
        </Box>
      </Card>
    )
  }, [label, link, children, textContent, image])

  // console.log('TextCard', { link, uri, label, textContent, image })

  return (
    <GridContainer
      sx={variant === TextCardVariant.default ? undefined : { p: 0 }}
      className={
        active ? `text-card text-card--${variant} active` : `text-card text-card--${variant}`
      }
    >
      {uri ? (
        <Link
          data-label={link?.title || label}
          data-action-type={getGTMEventLabel(link?.title || label, 'navigate-text-card')}
          className={'text-card__link-wrapper'}
          href={uri}
        >
          {cardContent}
        </Link>
      ) : (
        cardContent
      )}
    </GridContainer>
  )
}

TextCard.variant = TextCardVariant

import React from 'react'
import { uniqueId } from 'lodash'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

/**
 * @typedef {Object} FaqFiltersListProps
 * @property {Array<*>=} categories
 * @property {Array<Number>=} filters
 * @property {function=} updateFilters
 */

/**
 * FaqFilters component.
 * @param {FaqFiltersListProps} props
 * @return {JSX.Element}
 */
const SearchFiltersList = ({ categories, updateFilters, filters }) => (
  <ul className="search-filters__list">
    {[...categories].map((category) => (
      <li key={uniqueId(`faq-filters-item-${category.id}`)}>
        <FormControlLabel
          control={
            <Checkbox
              color={'secondary'}
              checked={filters.includes(category.id) || false}
              onChange={(e) => updateFilters(e.target, category.id)}
            />
          }
          label={`${category.label || category.faqCategory}`}
        />
      </li>
    ))}
  </ul>
)

export default SearchFiltersList

import { mapItemsToGTM } from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/mapItemsToGTM'
import { LINE_ITEMS_SHOPPABLE } from '@ggs/components/ecommerceAnalytics/gtm/types/LINE_ITEMS_SHOPPABLE'

export const gtmItems = (order) => Array.isArray(order?.line_items)
  ? order.line_items.filter((line_item) => {
    return LINE_ITEMS_SHOPPABLE.includes(line_item.item_type)
  }).map(mapItemsToGTM)
  : []
export const gtmCoupon = (order) => order?.coupon_code
export const gtmCurrency = (order) => order?.currency_code
export const gtmCheckoutValue = (order) => order?.total_amount_with_taxes_float
export const gtmTransactionId = (order) => order?.number
export const gtmTaxValue = (order) => order?.total_tax_amount_float
export const gtmShippingValue = (order) => order?.shipping_amount_float


export const gtmDataLayer = (order) => ({
  coupon: gtmCoupon(order),
  currency: gtmCurrency(order),
  items: gtmItems(order),
  value: gtmCheckoutValue(order),
})
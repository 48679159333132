import { flatten, get } from 'lodash'
import { Order, PaymentMethodsList, ShippingMethodsList } from './types'

/**
 * Extract a list with all shipping methods from order shipments.
 * @param {Order} order CL order to digest available shipping methods from.
 * @return {ShippingMethodsList} Available shipping methods
 */
function extractAvailableShippingMethods(order) {
  if (!get(order, 'shipments')) {
    return []
  }

  const shippingMethodsRaw = order.shipments.map((shipment) => {
    return shipment.available_shipping_methods
  })

  const allShippingMethods = flatten(shippingMethodsRaw)

  return allShippingMethods
}

/**
 * Extract a list with all payment methods from order.
 * @param {Order} order CL order to digest available payment methods from.
 * @return {PaymentMethodsList} Available payment methods
 */
function extractAvailablePaymentMethods(order) {
  if (!get(order, 'available_payment_methods')) {
    return []
  }

  return order.available_payment_methods
}

export { extractAvailableShippingMethods, extractAvailablePaymentMethods }

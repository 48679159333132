/* eslint-disable require-jsdoc */
import { getCountryDivisionIndex } from '@ggs/utils'
import { getCountriesOptions, getCountryStateOptions } from '@ggs/commercelayer'
import zipcodes from 'zipcodes-regex'
import RandExp from 'randexp'
import { useSelector } from '@ggs/store'
import { decomposeLanguageLocaleCode } from '@ggs/utils/languagesDigest'

/**
 * @typedef {import('@ggs/commercelayer/lib/address').RegionOption} RegionOption
 */
/**
 * @typedef {{countryOptions: Array<RegionOption>, zipCodeSample: *, countryStateOptions: (Array<RegionOption>|*[]),
 *   countryDivisions: *}} GeoData
 */

// @ts-ignore
export default function useGeoData() {
  const ui = useSelector((state) => state.ui)
  const settings = useSelector((state) => state.settings)
  const currentLanguage = decomposeLanguageLocaleCode(
    ui?.layoutMeta?.currentStore?.language?.id || settings?.language
  )
  const countryDivisionsIndex = getCountryDivisionIndex()

  /**
   *
   * @param {string} countryCode
   * @return {Promise<GeoData>}
   */
  const getGeoData = async (countryCode) => {
    const countryOptions = await getCountriesOptions(currentLanguage.langCode)
    const countryStateOptions
      = (await getCountryStateOptions(countryCode, currentLanguage.langCode)) || []
    // console.log('CountryStateOptions: ', countryStateOptions)

    // @ts-ignore
    const zipCodeRegex = zipcodes?.[countryCode] || ''
    const randexp = new RandExp(zipCodeRegex)
    const zipCodeSample = randexp.gen()
    // console.log('ZipCodeRegex:', zipCodeRegex, 'Sample:', zipCodeSample)

    // @ts-ignore
    const countryDivisions = countryDivisionsIndex[countryCode] || null

    return {
      countryOptions,
      countryStateOptions,
      countryDivisions,
      zipCodeSample,
    }
  }

  return {
    getGeoData,
  }
}

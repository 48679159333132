import * as React from 'react'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { getWindow } from '@ggs/utils'
import { find, uniqueId } from 'lodash'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

/**
 * @typedef {object} SidebarMenuProps
 * @property {Array<Object>} menuItems
 * @property {function} onClick
 */

/**
 *
 * @param {SidebarMenuProps} props
 * @return {JSX.Element}
 */
export default function SidebarMenuMobile({ menuItems, onClick }) {
  const pathname = getWindow('location.pathname')
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (/** @type Event */ event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (/** @type KeyboardEvent */ event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const buttonId = uniqueId('sidebar-menu-mobile-button')

  // @ts-ignore
  const currentPage = find(menuItems, ['uri', pathname])?.title

  return (
    <div className={'sidebar-menu__mobile'}>
      <Button
        ref={anchorRef}
        id={buttonId}
        className={'sidebar-menu__mobile-button'}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleToggle}
      >
        <span dangerouslySetInnerHTML={{ __html: currentPage }} />
      </Button>
      <Popper
        className={'sidebar-menu__mobile-popper'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {/* @ts-ignore */}
                <MenuList
                  autoFocusItem={open}
                  id={uniqueId('sidebar-menu-mobile-list')}
                  aria-labelledby={buttonId}
                  // @ts-ignore
                  onKeyDown={handleListKeyDown}
                >
                  {menuItems.map((/** @type * */ item) => (
                    // @ts-ignore
                    <MenuItem
                      key={item.id}
                      onClick={(/** @type Event */ e) => {
                        handleClose(e)
                        onClick(item, e)
                      }}
                      selected={pathname === item.uri}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.title }} />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

import { useMemo } from 'react'
import { colors, typography } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 *
 * @return {SxProps}
 */
export default function useSearchInputSx() {
  return useMemo(
    () => ({
      py: {
        xs: 1,
        md: 2,
      },
      px: {
        xs: 2,
        md: 3,
      },
      mt: {
        xs: 3,
        md: 4,
      },
      position: 'relative',
      zIndex: 1,
      boxShadow: 'none',
      boxSizing: 'border-box',
      border: `2px solid ${colors.ultraLightGrey}`,
      display: 'flex',
      alignItems: 'center',
      maxWidth: {
        md: 630,
      },
      justifyContent: 'center',
      mx: 'auto',
      '& .MuiInputBase-root': {
        m: 0,
        'input[type="text"]': {
          p: 0,
          typography: {
            xs: typography.pSmall,
            md: typography.paragraphLarge,
          },
        },
      },
      '& .search-input__submit': {
        p: 0,
        '&:focus': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
      },
      '.Mui-focusVisible.search-input__reset': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      },
    }),
    []
  )
}

import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import {
  gtmCheckoutValue,
  gtmCoupon,
  gtmCurrency,
  gtmItems,
  gtmShippingValue,
  gtmTaxValue,
  gtmTransactionId
} from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/gtagFromOrderParse'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'

export const firePurchase = async ({ order }) => {
  return pushDataLayer({
    eventName: EaActionType.purchase,
    dataLayer: {
      coupon: gtmCoupon(order),
      currency: gtmCurrency(order),
      items: gtmItems(order),
      transaction_id: gtmTransactionId(order),
      shipping: gtmShippingValue(order),
      value: gtmCheckoutValue(order),
      tax: gtmTaxValue(order)
    },
  })
}
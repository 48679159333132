import React, { useEffect, useState, useMemo } from 'react'
import { find, uniqBy, uniqueId } from 'lodash'
import Grid from '@mui/material/Grid'
import { GridContainer } from '@ggs/components/layout'
import MegaMenuItemHasSubmenu from '@ggs/components/nav/MainMenu/MegaMenuItemHasSubmenu'
import MegaMenuItem from '@ggs/components/nav/MainMenu/MegaMenuItem'
import { useWindowSize } from '@ggs/hooks'
import { Text } from '@ggs/components/paragraphs'

// Assets.
import { colors } from '@ggs/styles'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 */
/**
 * @typedef {Object} MegaMenuProps
 * @property {Number=} level
 * @property {Array<DrupalMenuLink>=} menuItems
 * @property {function} toggleDropdownMenu
 * @property {String=} footerContent
 * @property {Number=} headerHeight
 */
/**
 * @param {MegaMenuProps} props
 * @return {JSX.Element}
 */
export default function MegaMenu({
  level,
  menuItems,
  toggleDropdownMenu = null,
  footerContent,
  headerHeight,
}) {
  const menu = useMemo(()=>uniqBy(menuItems,'uuid'),[menuItems])
  const windowSize = useWindowSize()
  const [singleItemsColumnCount, setSingleItemsColumnCount] = useState(12)

  const menuLinksWithChild = menu.filter((link) => link.menuItems)
  const menuLinksWithoutChild = menu.filter((link) => !link.menuItems)

  useEffect(() => {
    // Calculate grid column count for the Single items wrapper.
    /** @type {Number} */
    if (windowSize.lg === true && windowSize.smallDesktopOnly === false) {
      setSingleItemsColumnCount(12 - (menuLinksWithChild.length * 3 % 12))
    } else if (windowSize.lg === true && windowSize.smallDesktopOnly === true) {
      setSingleItemsColumnCount(12 - (menuLinksWithChild.length * 4 % 12))
    }
  }, [windowSize.smallDesktopOnly])

  return (
    <>
      <div
        className={`mega-menu mega-menu--level-${level}`}
        style={{ top: (windowSize.lg === true && level === 1) ? `${headerHeight}px` : null }}
      >
        <GridContainer sx={{ pt: { xs: 0, md: 0, lg: 4 }, pb: { xs: 3, md: 3, lg: 6 } }}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            rowSpacing={3}
            columnSpacing={{ lg: 3, xl: 5 }}
          >
            {menuLinksWithChild.map((menuLink) =>
              <MegaMenuItemHasSubmenu
                key={uniqueId(`mega-menu-item-has-submenu-${menuLink.uuid}`)}
                level={level}
                label={menuLink.label}
                url={menuLink.url}
                menuItems={menuLink.menuItems}
                toggleDropdownMenu={toggleDropdownMenu}
              />
            )}
            <Grid
              className="mega-menu__single-items-wrapper"
              item
              xs={12}
              // @ts-ignore
              lg={singleItemsColumnCount}
            >
              <Grid
                container
                rowSpacing={3}
                columnSpacing={5}
              >
                {menuLinksWithoutChild.map((menuLink) =>
                  <MegaMenuItem
                    key={uniqueId(`mega-menu-item-${menuLink.uuid}`)}
                    label={menuLink.label}
                    url={menuLink.url}
                    // @ts-ignore
                    icon={menuLink.icon}
                    parentWrapperColumnCount={singleItemsColumnCount}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </GridContainer>
        <GridContainer
          className="mega-menu__footer-cta"
          sx={{ pt: { xs: 3, md: 3 }, pb: { xs: 3, md: 3 } }}
          backgroundColor={colors.blue}
          fullWidthBackground={true}
        >
          <Text textContent={footerContent} />
        </GridContainer>
      </div>
      {level === 1 && <div className="mega-menu__overlay" onClick={(e) => toggleDropdownMenu(false)}/>}
    </>
  )
}

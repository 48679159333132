// @ts-nocheck
import { useLayoutEffect, useState } from 'react'
import { breakpoints } from '@ggs/styles'
import { debounce } from 'lodash'

/**
 * @typedef {Object} WindowSizeHook
 * @property {number} width Window width
 * @property {number} height Window height
 * @property {boolean} belowSm
 * @property {boolean} sm Is small or above
 * @property {boolean} belowMd
 * @property {boolean} md Is md or above
 * @property {boolean} belowLg
 * @property {boolean} lg Is lg or above
 * @property {boolean} belowXl
 * @property {boolean} xl Is xl or above
 * @property {boolean} smallDesktopOnly Is between 1200px and 1016px
 */

/**
 * Window size hook.
 * @return {WindowSizeHook}
 */
function useWindowSize() {
  const isClient = typeof window === 'object'
  const getSize = (isClient, { innerWidth = undefined, innerHeight = undefined }) => {
    const clientDimensions = {
      width: isClient ? innerWidth : undefined,
      height: isClient ? innerHeight : undefined,
    }

    return {
      ...clientDimensions,
      belowSm: clientDimensions.width < breakpoints.sm,
      sm: clientDimensions.width >= breakpoints.sm,
      belowMd: clientDimensions.width < breakpoints.md,
      md: clientDimensions.width >= breakpoints.md,
      belowLg: clientDimensions.width < breakpoints.lg,
      lg: clientDimensions.width >= breakpoints.lg,
      belowXl: clientDimensions.width < breakpoints.xl,
      xl: clientDimensions.width >= breakpoints.xl,
      smallDesktopOnly: clientDimensions.width >= breakpoints.lg && clientDimensions.width < 1200,
    }
  }
  const [windowSize, setWindowSize] = useState(getSize(isClient, window))

  useLayoutEffect(() => {
    if (!isClient) {
      return false
    }

    /**
     * Resize window to current state width/height dimensions.
     */
    function handleResize() {
      setWindowSize(() => {
        return getSize(isClient, window)
      })
      // debounce(() => {
      //
      // }, 400, { leading: true })
    }

    const debouncedResize = debounce(handleResize, 500, { trailing: true })

    window.addEventListener('resize', debouncedResize)
    return () => window.removeEventListener('resize', debouncedResize)
  }, [])

  return windowSize
}

export default useWindowSize

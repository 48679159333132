import React from 'react'
import { useI18n } from '@ggs/gatsby/lib'
import { AddressForm } from '@ggs/components/ecomm'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} BillingMethodFormProps
 * @property {Order} order
 * @property {function=} onSuccess onSuccess Callback Fn
 */

/**
 * Checkout billing address form (order payment step).
 * @param {BillingMethodFormProps} props
 * @return {JSX.Element}
 */
const BillingAddressForm = ({ order, onSuccess }) => {
  const { t } = useI18n()

  const formProps = {
    orderPropertyKey: 'billing_address',
    labels: {
      email: t('account:label.billingInformation'),
      country: t('account:label.billingAddress'),
      submit: t('global:button.submit'),
      submitLabel: t('ecomm:button.updateAddress'),
      afterEmail: t('account:notice.orderConfirmationToThisEmail'),
      afterPhone: t('account:notice.orderConfirmationToThisPhone'),
    },
    onSuccess,
  }

  return <AddressForm {...formProps} />
}

export default BillingAddressForm

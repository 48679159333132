import { env } from './env.js'
import { init } from '@atoms-studio/commercelayer-sdk'
import { authMethod } from '../api/authMethod'
import { skuMethods } from '../api/skuMethods'
import { orderMethods } from '../api/orderMethods'

const { endpointUrl, clientId, globalDefaultScope } = env
// const defaultOptions = { endpointUrl, clientId, globalDefaultScope }

init({
  host: endpointUrl,
  clientId: clientId,
  timeout: 5000,
  clientSecret: '', // do not use in FE!!
  refreshTokens: true,
  refreshTokensAttempts: 3,
  onRefreshError: /* istanbul ignore next */ (error) => {
    // Do nothing by default
  },
  cookies: {
    scopes: 'cl_scopes',
    customer_token: 'cl_customer_token',
    customer_refresh_token: 'cl_customer_refresh_token',
  },
})

export const api = ({
  scope = globalDefaultScope,
  marketId,
  sessionData,
  ...options
} = { scope: globalDefaultScope }) => {
  // const parsedScope = (scope).includes('market:') ? scope.split('market:')[1] : scope
  // auth is the auth object, access is the access token

  return {
    ...authMethod({ scope }),
    ...skuMethods,
    ...orderMethods
  }
}

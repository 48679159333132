import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { colors } from '@ggs/styles'
import { Button } from '@ggs/gatsby/components/nav'

/**
 * @typedef {import('@mui/material').TypographyProps} TypographyProps
 * @typedef {TypographyProps["variant"]} TypographyVariants
 */

/**
 * @typedef {Object} ReviewTitleProps
 * @property {String} title
 * @property {TypographyVariants} titleVariant
 * @property {String=} buttonLabel
 * @property {function=} goToEdit Button action callback
 */

/**
 * Checkout review title.
 * @param {ReviewTitleProps} props
 * @return {JSX.Element}
 */
const ReviewTitle = ({ title, titleVariant, buttonLabel, goToEdit }) => {
  return (
    <Box display={'flex'} alignItems={'baseline'}>
      <Typography
        variant={titleVariant}
        sx={{
          fontWeight: 'bold',
          mb: 2,
          mr: 2,
        }}
      >
        {title}
      </Typography>
      {buttonLabel && (
        <Button
          onClick={goToEdit}
          variant={'outlined'}
          sx={{
            '& > span': {
              color: colors.red,
            },
            cursor: 'pointer',
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
  )
}

export default ReviewTitle

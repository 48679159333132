import { ThunkAction } from 'redux-thunk'
import Geonames from 'geonames.js'
const username = `${process.env.GATSBY_GEONAMES_USERNAME}`
const geonames = Geonames({
  username: username,
  lan: 'en',
  encoding: 'JSON',
})

/**
 * @type {('GEOCODE_LATLON')}
 */
export const GEOCODE_LATLON = 'GEOCODE_LATLON'

/**
 * @typedef {Object} GeolocationPlaceActionPayload
 * @property {string} country Geolocation resolved country
 */

/**
 * @typedef {Object} GeolocationPlaceAction
 * @property {string} type Action type ID
 * @property {GeolocationPlaceActionPayload} payload Action input data
 */

/**
 * Compose a resolve geolocation place action.
 *
 * @param {GeolocationPlaceActionPayload} geolocationPlace Geolocation resolved place data
 * @return {GeolocationPlaceAction} Start guest session action.
 */
const composeGeolocationPlaceAction = (geolocationPlace) => {
  return {
    type: GEOCODE_LATLON,
    payload: {
      ...geolocationPlace,
    },
  }
}

/**
 * Resolve geolocation place from lat/lon coordinates using reverse geocoding.
 * @param {number} latitude Geo latitude
 * @param {number} longitude Geo longitude
 * @return {ThunkAction.<void, any, null, GeolocationPlaceAction>} return action object
 */
export const resolveGeolocationPlace = (latitude, longitude) => {
  // console.log(`ResolvingGeolocationPlace for coords: ${latitude},${longitude}`)
  return async (dispatch) => {
    try {
      const countryInfo = await geonames.countryCode({ lat: latitude, lng: longitude })

      /**
       * @type {String}
       */
      const countryCode = countryInfo.countryCode
      dispatch(composeGeolocationPlaceAction({ country: countryCode }))
    } catch (error) {
      console.log('Geocoding error: ', error)
    }
  }
}

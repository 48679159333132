/**
 * Analytics action types.
 * @enum {string}
 */

export const analyticsActionsType = {
  SET_BEGIN_CHECKOUT: 'SET_BEGIN_CHECKOUT',
  RESET_BEGIN_CHECKOUT: 'RESET_BEGIN_CHECKOUT',
  SET_ADD_SHIPPING_INFO: 'SET_ADD_SHIPPING_INFO',
  RESET_ADD_SHIPPING_INFO: 'RESET_ADD_SHIPPING_INFO',
  SET_ADD_PAYMENT_METHOD: 'SET_ADD_PAYMENT_METHOD',
  RESET_ADD_PAYMENT_METHOD: 'RESET_ADD_PAYMENT_METHOD',
  SET_PURCHASE: 'SET_PURCHASE',
  RESET_PURCHASE: 'RESET_PURCHASE',
  SET_VIEW_CART: 'SET_VIEW_CART',
  RESET_VIEW_CART: 'RESET_VIEW_CART'
}
/**
 * @typedef {import('../initialState').DefaultAnalytics} DefaultAnalytics
 * @typedef {import('@ggs/commercelayer').Order} Order
 * @typedef {DefaultAnalytics['setCheckout']} DefaultSetCheckout
 */

/**
 * @typedef {import('@ggs/store').initialState} DefaultState
 * @typedef {import('../initialState').DefaultAnalytics} InitialAnalytics
 */

/**
 * @typedef {Object} SetBeginCheckoutPayload
 * @property {string} orderId Action type key
 */

/**
 * @typedef {Object} setBeginCheckoutAction
 * @property {analyticsActionsType} type Action type key
 * @property {SetBeginCheckoutPayload} payload Action input data
 */

/**
 * @typedef {Object} setBeginCheckoutProps
 * @property {Order} order
 */


/**
 * @typedef {Object} SetCheckoutCurrentStepAction
 * @property {checkoutActionsType} type Action type key
 * @property {setCurrentStepParams} payload Action input data
 */
/**
 * Set checkout state
 * @typedef {Object} setCurrentStepParams
 * @property {string} currentStepName
 * @property {number} currentStepperIndex
 * @return {SetCheckoutCurrentStepAction}
 */

/**
 * @typedef {Object} SetCheckoutStepsCompleteAction
 * @property {checkoutActionsType} type Action type key
 * @property {DefaultCheckoutStepsComplete} payload Action input data
 */
/*
checkoutState: {
      orderId: null,
      customerId: null,
      shippingCountryCode: null,
      shippingCountryLabel: null,
      shippingStateCode: null,
      shippingStateLabel: null,
      shippingAddressId: null,
      shippingMethodId: null,
      billingCountryCode: null,
      billingCountryLabel: null,
      billingStateCode: null,
      billingStateLabel: null,
      paymentMethodId: null,
      paymentSourceId: null,
      acceptTerms: false,
    },
 */
/**
 * Set checkout state
 * @param {setBeginCheckoutProps} props
 * @return {{payload, type: (analyticsActionsType|string)}}
 */
export const setBeginCheckout = ({ checkoutState }) => {
  const { orderId } = checkoutState
  return {
    type: analyticsActionsType.SET_BEGIN_CHECKOUT,
    payload: {
      orderId,
    },
  }
}


export const resetBeginCheckout = () => {
  return {
    type: analyticsActionsType.RESET_BEGIN_CHECKOUT,
    payload: {
      orderId: null,
    }
  }
}

export const setAddShippingInfo = ({ checkoutState }) => {
  const {
    orderId,
    shippingCountryCode,
    shippingStateCode,
    shippingAddressId,
    shippingMethodId
  } = checkoutState
  return {
    type: analyticsActionsType.SET_ADD_SHIPPING_INFO,
    payload: {
      orderId,
      shippingCountryCode,
      shippingStateCode,
      shippingAddressId,
      shippingMethodId
    }
  }
}

export const resetAddShippingInfo = () => {
  return {
    type: analyticsActionsType.RESET_ADD_SHIPPING_INFO,
    payload: {
      orderId: '',
      shippingCountryCode: '',
      shippingStateCode: '',
      shippingStateLabel: '',
      shippingAddressId: '',
      shippingMethodId: ''
    }
  }
}

export const setAddPaymentMethod = ({ checkoutState }) => {
  const {
    orderId,
    billingCountryCode,
    billingStateCode,
    paymentMethodId,
    paymentSourceId,
  } = checkoutState

  return {
    type: analyticsActionsType.SET_ADD_PAYMENT_METHOD,
    payload: {
      orderId,
      billingCountryCode,
      billingStateCode,
      paymentMethodId,
      paymentSourceId
    }
  }
}

export const resetAddPaymentMethod = () => {
  return {
    type: analyticsActionsType.RESET_ADD_PAYMENT_METHOD,
    payload: {
      orderId: '',
      billingCountryCode: '',
      billingStateCode: '',
      paymentMethodId: '',
      paymentSourceId: '',
    }
  }
}

export const setPurchase = ({ orderId }) => {
  return {
    type: analyticsActionsType.SET_PURCHASE,
    payload: {
      orderId: orderId,
      reported: true
    }
  }
}

export const resetPurchase = () => {
  return {
    type: analyticsActionsType.RESET_PURCHASE,
    payload: {
      orderId: null,
      reported: false
    }
  }
}

export const setViewCart = (items = []) => {
  return {
    type: analyticsActionsType.SET_VIEW_CART,
    payload: {
      items,
    }
  }
}

export const resetViewCart = () => {
  return {
    type: analyticsActionsType.RESET_VIEW_CART,
  }
}

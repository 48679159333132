import React from 'react'
import { useSelector } from '@ggs/store'
import visibilityHelper from '@ggs/utils/visibilityHelper'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 * @typedef {Object} VisibilitySettingsProps
 * @property {boolean|null=} include
 * @property {string=} type
 * @property {String} visibility
 * @property {SxProps=} sx
 * @property {JSX.Element} children
 */

/**
 * Compare visibility field against the users state (store/region/custom type) to control content display.
 * @return {String}
 */
export const useCurrentAudience = () => {
  const currentStoreId = useSelector(({ ui }) => ui?.layoutMeta?.currentStore?.entityId)
  const currentRegionId = useSelector(({ settings }) => settings?.currentMarket?.region?.entityId)
  const currentCustomerRole = useSelector(({ commerce }) => commerce?.customer?.customerType)

  // derive value of state, to avoid re-renders.
  // TODO: revist if this can be simplified to store / customer type / region
  return JSON.stringify(`${currentStoreId ? `${currentStoreId}-` : ''}${
    currentRegionId ? `${currentRegionId}-` : ''
  }${currentCustomerRole ? `${currentCustomerRole}` : ''}`)
}

/**
 * @typedef {Object} useVisibilityProps
 * @property {boolean|null=} include
 * @property {String} visibility
 */
/**
 * Compare visibility field against the users state (store/region/custom type) to control content display.
 * @param {useVisibilityProps} props
 * @return {{shouldDisplay: boolean, debugSx: object=, debugText: string=}} Parsed visibility
 */
export default function useVisibility({ entitySettingsValue, settings } = {}) {
  const userSettings = useCurrentAudience()

  if (!entitySettingsValue && !settings) return {
    shouldDisplay: true,
  }

  const resolvedSettings = {
    visibility: determineVis({ entitySettingsValue, settings }),
    include: determineInclude({ entitySettingsValue, settings })
  }
  if (!resolvedSettings.visibility) return { shouldDisplay: true }
  return visibilityHelper(resolvedSettings.visibility, resolvedSettings.include, userSettings, {
    entitySettingsValue,
    settings
  })
}

const determineVis = ({ entitySettingsValue, settings }) => {
  if (entitySettingsValue) {
    return JSON.parse(entitySettingsValue)?.visibility?.[0]?.data
  }
  if (settings.visibility) {
    return JSON.parse(settings.visibility)
  }
  return []
}

const determineInclude = ({ entitySettingsValue, settings }) => {
  if (entitySettingsValue) {
    return (String(JSON.parse(entitySettingsValue)?.include?.[0]?.value) === '1')
  }
  if (settings) {
    return settings.include === true
  }
  return true
}
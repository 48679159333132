import React from 'react'
import Tab from '../Tab'

/**
 * @typedef {import("../Tab").TabProps} TabProps
 */
/**
 * @typedef {Object} TabPanelProps
 * @property {String} tabId
 * @property {TabProps} content
 * @property {Number} value
 * @property {Number} index
 */
/**
 *
 * @param {TabPanelProps} props
 * @return {JSX.Element}
 */
export default function TabPanel({ tabId, content, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      className="tabs__panel"
      hidden={value !== index}
      id={`${tabId}-panel-${index}`}
      aria-labelledby={`${tabId}-${index}`}
      {...other}
    >
      {value === index && <Tab {...content} />}
    </div>
  )
}

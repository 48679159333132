import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import { GridContainer } from '@ggs/components/layout'
import { SocialMedia } from '@ggs/components/nav'
import FooterMenuList from '@ggs/components/nav/FooterMenu/FooterMenuList'
// Assets
import './FooterMenu.scss'
import { colors } from '@ggs/styles'
import { useI18n } from '@ggs/gatsby/lib'
import { Link } from '@ggs/gatsby/components/nav'

/**
 * @typedef {import('@ggs/types').DrupalMenuLink} DrupalMenuLink
 * @typedef {import('@ggs/types').Store} Store
 */
/**
 * @typedef {Object} FooterMenuProps
 * @property {Number=} level
 * @property {Array<DrupalMenuLink>=} menuItems
 * @property {Store=} store
 * @property {Array<DrupalMenuLink>=} socialLinks
 */
/**
 * @param {FooterMenuProps} props
 * @return {JSX.Element}
 */
export default function FooterMenu({ level = 0, menuItems, store, socialLinks }) {
  const { t } = useI18n()
  const { officeName = '', supportEmail = '', officeAddress = '', officePhone = '' } = store || {}

  return (
    <GridContainer
      sx={{ py: { xs: 4, md: 10 } }}
      className="footer-menu"
      fullWidthBackground
      backgroundColor={colors.darkGrey}
    >
      <Grid className="footer-menu__inner" container columnSpacing={{ lg: 5 }}>
        <Grid item xs={12} lg={9}>
          <nav role="navigation" aria-label="Footer menu">
            <FooterMenuList level={level} menuItems={menuItems} />
          </nav>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div className="footer-menu__store-details">
            <div className="footer-menu__store-details-label">{officeName}</div>
            <div className="footer-menu__store-details-content">
              <p>{officeAddress}</p>
              <Link data-label={officeName} data-acion-type={'call-us'} href={`tel:${officePhone}`}>
                {`${t('global:store.tel')} ${officePhone}`}
              </Link>
            </div>
          </div>
          <SocialMedia
            socialLinks={socialLinks}
            email={supportEmail}
            variant={SocialMedia.variant.footer}
          />
        </Grid>
      </Grid>
    </GridContainer>
  )
}

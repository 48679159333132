// @ts-nocheck
import React, { useEffect, useState } from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import Collapse from '@mui/material/Collapse'

import List from '@mui/material/List'
import * as PropTypes from 'prop-types'
import { isFunction, noop } from 'lodash'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import MaterialUIWrapperSelectableAccordion from './MaterialUIWrapperSelectableAccordion'
// import './_selectable-accordion.scss'

const propTypes = {
  panelId: PropTypes.string,
  description: PropTypes.string,
  competency: PropTypes.string,
  liItems: PropTypes.func,
  isOpen: PropTypes.func,
  className: PropTypes.string,
}

const defaultProps = {
  isOpen: noop,
  liItems: () => {},
  panelId: '',
  description: '',
  competency: '',
  className: '',
}

const SelectableAccordion = ({
  panelId,
  description,
  competency,
  liItems = [],
  isOpen,
  isChecked,
  isDisabled,
  className,
  onChange = null,
  name,
}) => {
  const [open, setOpen] = useState(isOpen)
  const [checked, setChecked] = useState(isChecked)

  const handleSubclick = () => {
    setOpen(!open)
  }

  const handleCheckClick = (event) => {
    setChecked(!checked)
    onChange(event)
  }

  useEffect(() => {
    window.thisListItem = liItems
    if (!window.liItems) {
      window.liItems = []
    }
    window.liItems.push(liItems)
  })

  const disabled = !isFunction(onChange)

  return (
    <>
      <MaterialUIWrapperSelectableAccordion
        handleClick={handleSubclick}
        isOpen={open}
        isDisabled={isDisabled}
        panelId={`panelId--${panelId}`}
        className={`material-ui-wrapper--selectable-accordion${className}`}
        elevation={2}
        summaryContent={
          <>
            <FormControlLabel
              aria-label={`${description}`}
              onClick={(event) => {
                event.stopPropagation()
              }}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  name={name}
                  onChange={handleCheckClick}
                  value={description}
                  checked={checked}
                  disabled={disabled}
                />
              }
              label={`${description}`}
            />
            <span className="material-ui-wrapper--selectable-accordion--expand-icon">
              {open ? <ExpandLess /> : <ExpandMore />}
            </span>
          </>
        }
      >
        <Collapse
          key="todo"
          in={open}
          // timeout auto makes it jerky
          timeout={0}
          unmountOnExit
        >
          <section>
            <h3>{competency}</h3>
            <List
              component="ul"
              className="selectable-accordion-details-list-wrapper"
              disablePadding
            >
              {liItems.length > 0
                ? liItems.map((liItem) => <li key={`${liItem}`}>{liItem}</li>)
                : null}
            </List>
          </section>
        </Collapse>
      </MaterialUIWrapperSelectableAccordion>
    </>
  )
}

SelectableAccordion.propTypes = propTypes
SelectableAccordion.defaultProps = defaultProps

export default SelectableAccordion

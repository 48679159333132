// @ts-nocheck
import * as Sentry from '@sentry/react'
import { useSelector } from '@ggs/store/store'
import { environment, hosting } from '@ggs/gatsby/hosting'

/*
 * @enum {string}
 */
const Severity = {
  fatal: 'fatal',
  error: 'error',
  warning: 'warning',
  log: 'log',
  info: 'info',
  debug: 'debug',
  critical: 'critical',
}

/**
 * @typedef {Object} UseSentryError
 * @param {object} error - Expects valid JavaScript Error() object
 * @param {string=} name - This will be the title that show sin Sentry Issues
 * @param {string=} actionPerformed - Group transactional data by a label
 * @param {Severity=} level - Expects a valid string matching a Sentry.Severity
 * value https://github.com/getsentry/sentry-javascript/blob/master/packages/types/src/severity.ts
 * @param {object=} context - Expects an object with only one node of key => value (string) pairs
 * @param {object=} tags - Expects an object with only one node of key => value (string) pairs
 */
let SENTRY_STATE = {
  customer: {},
  context: {
    lang: '',
    store: '',
  },
}

export const useSentryState = () => {
  const { customer = {} } = useSelector((state) => state?.commerce) || {}
  const { lang = 'invalid', currentStore } = useSelector((state) => state?.ui?.layoutMeta) || {}

  SENTRY_STATE = {
    customer,
    context: {
      lang,
      store: currentStore?.storeAlias || 'invalid',
    },
  }
}

/**
 *
 * @param {UseSentryError} props
 */
export const triggerSentry = ({
  name = '',
  error = {},
  actionPerformed,
  level = Severity.error,
  context = {},
  tags = {},
}) => {
  const {
    customer,
    context: { lang, store },
  } = SENTRY_STATE

  Sentry.withScope((scope) => {
    if (actionPerformed) {
      scope.setTransactionName(actionPerformed)
    }
    scope.setLevel(level)
    scope.setUser(customer)
    scope.setContext('Additional Context', {
      ...context,
      lang,
    })
    if (name) {
      error.name = name
    }
    Sentry.captureException(error, {
      tags: {
        ...tags,
        environment,
        lang,
        store,
        hosting,
      },
    })
  })
}
triggerSentry.severity = Severity

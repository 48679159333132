import React, { useMemo } from 'react'
import { generateGatsbyImage } from '@ggs/gatsby/lib'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { useModal } from '@ggs/components/common'
import { Title } from '@ggs/components/paragraphs'
import { Button } from '@ggs/gatsby/components/nav'
import { safeVideoId } from '@ggs/utils'
import VideoPlayIcon from './VideoPlayIcon'
// Assets
import './Video.scss'
import { useSelector } from '@ggs/store'
import Box from '@mui/material/Box'

/**
 * Video Variant
 * @enum {string}
 */
const VideoVariant = {
  default: 'default',
  textIframe: 'text-iframe',
  openInModal: 'open-in-modal',
}

/**
 * @typedef {import('@ggs/components/common/Modal/useModal').ModalProps} ModalProps
 */
/**
 * @typedef {Object} VideoProps
 * @property {String} videoId
 * @property {String=} title
 * @property {Boolean=} autoplay
 * @property {'youtube'|'vimeo'=} videoType
 * @property {VideoVariant=} variant
 */
/**
 * @param {VideoProps} props
 * @return {JSX.Element}
 */
export default function Video({
  videoId,
  title = '',
  autoplay = false,
  videoType = 'youtube',
  variant = VideoVariant.default,
}) {
  // eslint-disable-next-line prefer-rest-params
  // console.log('Video render', arguments[0], JSON.stringify(arguments[0]))
  const lang = useSelector((state) => state.ui.layoutMeta.lang)
  const shouldOpenInModal = variant === VideoVariant.openInModal

  const embed = useMemo(() => {
    let comp = null
    switch (videoType.toString().toLowerCase()) {
      case 'youtube': {
        // @ts-ignore
        const params = new URLSearchParams({
          autoplay: shouldOpenInModal ? 1 : autoplay ? 1 : 0,
          controls: 1,
          showinfo: 0,
          rel: 0,
          modestbranding: 1,
          playsinline: 1,
          hl: lang,
          cc_lang_pref: lang,
          enablejsapi: 1,
        })
        const videoSrc = `https://www.youtube-nocookie.com/embed/${safeVideoId(videoId)}?${params}`
        // console.log('videoSrc', videoSrc, params)
        comp = (
          <iframe
            className="video"
            src={videoSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )
        break
      }
    }
    return comp
  }, [videoType, videoId])

  /** @type ModalProps */
  const modalContent = {
    title: <Title className="video__modal-title" title={title} useFlare style="h2" />,
    content: (
      <Box
        sx={{
          iframe: {
            minHeight: '100%',
          },
        }}
        className="video__modal-video"
      >
        {embed}
      </Box>
    ),
    scroll: 'body',
  }
  const { modal: renderedModal, triggerModal } = useModal(modalContent)

  return (
    <div className={`video video--${variant}`}>
      {variant !== VideoVariant.openInModal && embed}
      {shouldOpenInModal && (
        <Button
          className="video__modal-trigger"
          onClick={triggerModal}
          variant={Button.variant.text}
        >
          <>
            <div className="video__thumbnail">
              <ImageStyles
                {...generateGatsbyImage({
                  alt: title || 'Video thumbnail',
                  img: `https://img.youtube.com/vi/${safeVideoId(videoId)}/hqdefault.jpg`,
                  styles: [
                    {
                      style: 'medium',
                      width: 300,
                      height: 170,
                    },
                  ],
                })}
                selectedStyles={['medium']}
              />
              <VideoPlayIcon />
            </div>
            <Title className="video__title" title={title} style="h4" />
          </>
        </Button>
      )}
      {shouldOpenInModal && renderedModal}
    </div>
  )
}

Video.variant = VideoVariant

// @ts-nocheck
import React, { forwardRef, useRef, useState } from 'react'
import { trim } from 'lodash'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Radio from '@mui/material/Radio'
import MuiTextField from '@mui/material/TextField'
import TreeItem from '@mui/lab/TreeItem'
import TreeView from '@mui/lab/TreeView'
import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RemoveIcon from '@mui/icons-material/Remove'
import { whenPressingEscape } from '@ggs/utils'
// import './_DropdownTreeSelect.scss'

const useStyles = makeStyles({
  root: {
    width: '99%',
    margin: '0 auto',
    padding: '10px 20px',
    'max-height': '400px',
    'overflow-y': 'scroll',
    'background-color': 'white',
    'box-shadow': '0 0 5px #cccccc',
  },
})

const StyledTreeItem = withStyles((theme) => ({
  root: {
    padding: '4px 0',
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} />)

const StyledRadio = ({ label, value, selectedValue }) => (
  <FormControlLabel
    value="end"
    control={<Radio color="primary" name="treeItemButton" checked={selectedValue === value} />}
    label={label}
  />
)

const Label = ({ label }) => (
  <span className="dropdown-tree-list__label" style={{ padding: '10px 0' }}>
    {label}
  </span>
)

const processTreeData = (treeData, stack, defaultExpanded, defaultValue) => {
  Object.keys(treeData).forEach((k) => {
    const node = treeData[k]
    if (typeof node === 'object') {
      node.path = stack ? `${stack}.${k}` : k
      // pass value with '_' to id to mark out nodes with children
      // TreeView returns node.id as the value of selected item
      node.id = (node.children ? '_' : '') + node.label
      // push in potential node to build default expanded path
      if (
        defaultExpanded.length === 0 ||
        defaultExpanded[defaultExpanded.length - 1] !== defaultValue
      ) {
        defaultExpanded.push(node.id)
      }
      processTreeData(node, node.path, defaultExpanded, defaultValue)
      // pop out potential node to build default expanded path
      if (defaultExpanded[defaultExpanded.length - 1] !== defaultValue) {
        defaultExpanded.pop()
      }
    }
  })
}

// eslint-disable-next-line react/display-name
const DropdownTreeSelectWrapper = forwardRef(
  ({ className, icon, onChange, name, value, label, treeData, ...props }, ref) => {
    const classes = useStyles()

    const [isFocused, setIsFocused] = useState(false)

    const [fieldValue, setFieldValue] = useState(value)

    const inputRef = useRef()

    const defaultExpanded = []

    // useEffect(() => {}, [])

    processTreeData(treeData, null, defaultExpanded, value)

    const handleFocus = () => {
      setIsFocused(true)
    }
    const handleClickAway = () => {
      setIsFocused(false)
    }

    const handleNodeSelect = (event, nodeId) => {
      const input = inputRef.current.querySelector('input')
      if (nodeId.split('_').length === 1) {
        input.value = nodeId
        // console.log(`${name} onChange`, nodeId, input.value)
        setFieldValue(nodeId)
        setIsFocused(false)
        // Change event target to input for Formik onChange handler
        event.target = input
        onChange(event)
      }
    }

    const renderTree = (nodes) => (
      <StyledTreeItem
        key={nodes.path}
        nodeId={nodes.id}
        label={
          nodes.children ? (
            <Label label={trim(nodes.label)} />
          ) : (
            <StyledRadio value={nodes.id} label={trim(nodes.label)} selectedValue={fieldValue} />
          )
        }
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </StyledTreeItem>
    )

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <MuiTextField
            className={`${className} form-field__text-field`}
            margin="normal"
            label={label}
            variant="filled"
            fullWidth
            InputLabelProps={{ shrink: Boolean(fieldValue) }}
            ref={inputRef}
            name={name}
            onFocus={handleFocus}
            defaultValue={value}
            onKeyPress={whenPressingEscape(handleClickAway)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ExpandMoreIcon />
                </InputAdornment>
              ),
              readOnly: true,
              disabled: props.disabled,
            }}
          />
          <TreeView
            style={{
              display: isFocused ? 'block' : 'none',
            }}
            className={classes.root}
            onNodeSelect={handleNodeSelect}
            defaultCollapseIcon={<RemoveIcon />}
            defaultExpandIcon={<AddIcon />}
            defaultSelected={fieldValue}
            defaultExpanded={defaultExpanded}
          >
            {treeData.map(renderTree)}
          </TreeView>
        </div>
      </ClickAwayListener>
    )
  }
)

export default DropdownTreeSelectWrapper

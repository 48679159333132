import { PaymentPayPalChosen } from '@ggs/components/ecomm'
import { useI18n } from '@ggs/gatsby/lib'
import Typography from '@mui/material/Typography'
import { capitalize, get, isFunction, noop } from 'lodash'
import React from 'react'
// @ts-ignore
import Amex from 'react-pay-icons/lib/Amex'
// @ts-ignore
import Diners from 'react-pay-icons/lib/Diners'
// @ts-ignore
import DinersClub from 'react-pay-icons/lib/DinersClub'
// @ts-ignore
import Discover from 'react-pay-icons/lib/Discover'
// @ts-ignore
import Jcb from 'react-pay-icons/lib/Jcb'
// @ts-ignore
import Mastercard from 'react-pay-icons/lib/Mastercard'
// @ts-ignore
import UnionPay from 'react-pay-icons/lib/UnionPay'
// @ts-ignore
import Visa from 'react-pay-icons/lib/Visa'

const ccStyle = { width: 48, display: 'inline-block', marginLeft: 4 }

export const paymentSourceIcons = {
  visa: () => <Visa style={ccStyle}/>,
  mastercard: () => <Mastercard style={ccStyle}/>,
  discover: () => <Discover style={ccStyle}/>,
  diners: () => <Diners style={ccStyle}/>,
  dinersclub: () => <DinersClub style={ccStyle}/>,
  jcb: () => <Jcb style={ccStyle}/>,
  unionpay: () => <UnionPay style={ccStyle}/>,
  amex: () => <Amex style={ccStyle}/>,
}

/*

 // @ts-ignore
 const PayIcons = React.lazy(() => import('react-pay-icons'))

 /!*
 * @enum {JSX.Element}
 *!/
 export const paymentSourceIcons = {
 // @ts-ignore
 visa: () => <PayIcons.Visa style={ccStyle} />,
 // @ts-ignore
 mastercard: () => <PayIcons.Mastercard style={ccStyle} />,
 // @ts-ignore
 discover: () => <PayIcons.Discover style={ccStyle} />,
 // @ts-ignore
 diners: () => <PayIcons.Diners style={ccStyle} />,
 // @ts-ignore
 dinersclub: () => <PayIcons.DinersClub style={ccStyle} />,
 // @ts-ignore
 jcb: () => <PayIcons.Jcb style={ccStyle} />,
 // @ts-ignore
 unionpay: () => <PayIcons.UnionPay style={ccStyle} />,
 // @ts-ignore
 amex: () => <PayIcons.Amex style={ccStyle} />,
 }
 */

/**
 * @typedef {import("@commercelayer/sdk").Order} Order
 */

/**
 * @typedef {Object} PaymentSourceDetailsProps
 * @property {Order} order
 * @property {('payment' | 'review' | 'complete')} variant
 */

/**
 * Payment source method details (payment type and source details).
 * @param {PaymentSourceDetailsProps} props
 * @return {JSX.Element}
 */
const PaymentSourceDetails = ({ order, variant }) => {
  const { t } = useI18n()
  const paymentMethod = order?.payment_method
  const paymentSourceMethod = get(order, 'payment_source.payment_method', null)
  const isStripePaymentMethod = paymentMethod?.payment_source_type === 'stripe_payments'

  /**
   * @typedef {Object} PaymentMethodDetails
   * @property {string} brand Payment source type
   * @property {string} digits Payment method source details
   * @property {string} icon CL payment method name
   */

  /**
   * Compose payment source method details to allow customer identify the method
   * currently used.
   * @return {string}
   */
  const composePaymentMethodDetails = () => {
    let details = t('ecomm:stripe.label.savedPaymentMethod')
    const paymentSourceType = paymentSourceMethod?.type || 'unknown'

    console.log('compose', isStripePaymentMethod, paymentSourceMethod?.card)

    if (isStripePaymentMethod) {
      const {
        brand = '',
        last4 = '',
        exp_year = ''
      } = paymentSourceMethod?.card || {}
      const cardDetails = {
        brand: capitalize(brand),
        digits: last4,
      }
      // @ts-ignore
      const icon = paymentSourceIcons?.[brand] || noop()
      // const icon = <Suspense
      // fallback={<div>Icon...</div>}>{paymentSourceIcons?.[brand]}</Suspense>
      // || noop()

      details = (
        <>
          <span>{t('ecomm:stripe.label.payingWithCard', cardDetails)}</span>
          {isFunction(icon) && icon()}
        </>
      )

      console.log('payingWithCard', {
        cardDetails,
        icon,
      })
    } else {
      details = <PaymentPayPalChosen variant={variant}/>
    }

    console.log('composePaymentMethodDetails', {
      details,
      paymentSourceType,
      paymentSourceMethod,
    })

    return details
  }

  return (
    <Typography className={'payment-source-details'} display={'inline-flex'}
      alignItems={'center'}>
      {composePaymentMethodDetails()}
    </Typography>
  )
}

export default PaymentSourceDetails

// @ts-nocheck
import React from 'react'
import { CartCoupon, useSupportingLinks } from '@ggs/components/ecomm'
import useOrderLabels from '@ggs/components/ecomm/Customer/useOrderLabels'
import { Button } from '@ggs/gatsby/components/nav'
// Utils
import { useI18n } from '@ggs/gatsby/lib'

import { colors, common, typography } from '@ggs/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { navigate } from 'gatsby'
import { useCheckoutAnalytics } from '../../ecommerceAnalytics/hooks/useCheckoutAnalytics'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {('cart'|'checkout'|'complete')} CartTotalsVariants
 */

// @ts-ignore
const CartTotalLabel = ({ label, details, detailsStyle = typography.pSmall }) => {
  return (
    <>
      <Typography
        sx={{
          typography: typography.pSmall,
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          typography: detailsStyle,
        }}
      >
        {details}
      </Typography>
    </>
  )
}

/**
 * @typedef {Object} CartTotalsProp
 * @property {CartTotalsVariants} variant
 * @property {Order} order
 *
 */

/**
 * Shopping cart totals summary.
 * @param {CartTotalsProp} props
 * @return {JSX.Element}
 */
export default function CartTotals({ order, variant = 'cart' }) {
  const { t } = useI18n()
  const { checkout: checkoutUrl } = useSupportingLinks()
  const orderLabels = useOrderLabels(order)
  const { dispatchClearAllAnalyticsReported } = useCheckoutAnalytics({ order })

  if (!order || !Array.isArray(order?.line_items)) {
    return null
  }

  const hasPaymentMethod = Boolean(order?.payment_method)
  const itemStyle = {
    justifyContent: 'space-between',
    mt: 0,
    mb: variant === 'complete' ? 0 : 2,
    pl: 0,
    pr: 0,
    borderBottom: variant === 'complete' ? 'none' : common.borderLightGrey,
  }

  /**
   * Go to checkout URL.
   */
  const goToCheckout = () => {
    // Restart analytics report every time user start checkout from shopping cart so tracking events fire.
    dispatchClearAllAnalyticsReported()
    navigate(checkoutUrl)
  }

  const sx = {
    backgroundColor: colors.white,
    mb: 1,
    '& .title, & .cart-coupon': {
      borderBottom: common.borderLightBlue,
      pb: 2,
      mb: 2,
    },
    '& ul': {
      p: 0,
    },
    '& .MuiListItem-root': {
      pt: 0,
      pb: 2,
    },
    '& .cart-totals__checkout': {
      width: '100%',
    },
    '.Mui-List-root': {
      '& .MuiTypography-caption': {
        minWidth: '70px',
      },
      '& .MuiTypography-body1': {
        textAlign: 'right',
      },
    },
  }

  return (
    <Box className="cart-totals" sx={sx}>
      {!hasPaymentMethod && <CartCoupon order={order} />}
      <List>
        <ListItem key="subtotal" sx={itemStyle}>
          <CartTotalLabel
            label={t('ecomm:cart.label.subtotal')}
            details={orderLabels.totals.subTotal}
            detailsStyle={typography.menuItemRegular}
          />
        </ListItem>
        {order?.discount_amount_float < 0 && (
          <ListItem key="discount" sx={itemStyle}>
            <CartTotalLabel
              label={t('ecomm:cart.label.discount')}
              details={orderLabels.totals.discountTotal}
            />
          </ListItem>
        )}
        <ListItem key="shipping" sx={itemStyle}>
          <CartTotalLabel
            label={t('ecomm:cart.label.shipping')}
            details={orderLabels.totals.shippingTotal}
          />
        </ListItem>
        <ListItem key="tax" sx={itemStyle}>
          <CartTotalLabel label={t('ecomm:cart.label.tax')} details={orderLabels.totals.taxTotal} />
        </ListItem>
        <ListItem
          key="total"
          sx={{
            ...itemStyle,
            border: 'none',
          }}
        >
          <CartTotalLabel
            label={t('ecomm:cart.label.total')}
            details={orderLabels.totals.orderTotal}
            detailsStyle={typography.h4}
          />
        </ListItem>
      </List>
      {variant === 'cart' && (
        <Button
          className="cart-totals__checkout"
          label={t('ecomm:cart.button.checkout')}
          endIcon={<ChevronRightIcon />}
          onClick={goToCheckout}
        />
      )}
    </Box>
  )
}

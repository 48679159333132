// @ts-nocheck
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { findIndex, compact, defer, forEach, delay, keys, find, get } from 'lodash'
import * as Yup from 'yup'
// Data
import { useI18n } from '@ggs/gatsby/lib'
// Components
import FormSubField from './FormSubField'
// Assets
// import './_FormCollection.scss'
import animateScrollTo from 'animated-scroll-to'
import {Button} from "@mui/material";

const FormCollection = forwardRef(
  (
    {
      formik,
      fieldProps: { name: formName, ...fieldProps } = {},
      setField,
      setValidators,
      options,
    },
    ref
  ) => {
    const { t } = useI18n()
    const { formFields, type, action, maxEntries = 5, minEntries = 1, title } = options
    const wrapperRef = useRef()
    const [initialValues, setInitialValues] = useState(fieldProps.defaultValue)

    /**
     * Method to regenerate form fields, once on load, or behind the scene if oen gets hidden.
     * @param i
     * @return {[{name: string, label: string, type: string}]}
     */
    const generateFields = (i) => formFields(`${formName}[${i}].`, formik)

    /**
     * Generate the initial forms, and supporting state for tracking which are enabled, and a count of how many are
     * currently displayed.
     */
    const [state, setState] = useState(() => {
      // console.log(`FormCollection::${name} - init state!`)
      const enabled = Array(maxEntries).fill(false)
      const forms = enabled.map((v, i) => generateFields(i))
      const validators = {}
      forms[0].forEach(({ name, validator }) => {
        validators[name.split('.')[1]] = validator
      })
      const validator = Yup.array()
        .required(t('global:form.subforms.required', { items: type }))
        .min(minEntries, t('global:form.subforms.required', { items: type, min: minEntries }))
        .max(maxEntries, t('global:form.subforms.maxRequired', { max: maxEntries }))
        .of(Yup.object().shape(validators))
      // Set field validators, one and done!
      setValidators(() => {
        return { [formName]: validator }
      })
      // console.log('FormCollection::validator', validator)

      return {
        forms,
        enabled,
        displayed: 0,
      }
    })

    const formDisplay = (index, display = true) => {
      // If we're disabling a form, ensure we clear out the forms.
      if (!display) {
        // Clear our formik values
        // formik.setValues((values) => {
        try {
          // Scrub out the data inside the indexed subform
          // const subform = values?.[formName]?.[index] || {}
          const formDom = document.querySelector(`#subform_${formName}_${index}`)
          const clearButtons = formDom.querySelectorAll('.MuiAutocomplete-clearIndicator, .form-field__reset') || []
          clearButtons.forEach((btn) => btn.click())
          // state.forms[index].forEach(({ name }) => {
          //   const input = document.querySelector(
          //     `input[name*="${name.substr(1)}"], textarea[name*="${name.substr(1)}"]`
          //   )
          //   if (input.type === 'radio' || input.type === 'checkbox') {
          //     input.removeAttribute('checked')
          //   } else {
          //     input.focus()
          //     input.value = ''
          //   }
          //   input.dispatchEvent(new Event('change'))
          //   input.blur()
          //   if (subform[name.split('.')[1]]) {
          //     subform[name.split('.')[1]] = undefined
          //   }
          //   console.log(`Clearing: values: ${name}`, input.value, input)
          // })
        } catch (e) {
          // console.log('Error',e)
        }

        // console.log('formDisplay, cleared index', index)

        // return values
        // })

        // Rebuild form fields as new.
        /*setState({ ...state, [index]: [] })
        setState((old) => {
          if (old.forms[index]) {
            old.forms[index] = generateFields(index)
            console.log('clearing forms[index], fresh comps', old.forms[index])
          }
          return old
        })*/

        // setInitialValues((init) => {
        //   try {
        //     delete init[formName][index]
        //   } catch (e) {
        //     //
        //   }
        //   console.log('cleared init values', init)
        //   return init
        // })

        // For debugging
        // eslint-disable-next-line no-param-reassign
        // display = true
      }

      // Now adjust the subform visibility
      delay(() => {
        setState(({ enabled, ...old }) => {
          // Update the enabled list
          enabled[index] = display

          // Update state with revised fields
          return Object.assign(old, {
            enabled,
            // Determine new displayed count of enabled forms.
            displayed: compact(enabled).length,
          })
        })
      }, 100)
    }

    /**
     * Enable the next available form index, in the event ones in between were cancelled.
     */
    const addSubform = () => {
      // Ignore if we're displaying all already.
      if (state.displayed === maxEntries) {
        return
      }

      // Find the next form to display
      const nextIndex = findIndex(state.enabled, (r) => !r)
      defer(() => formDisplay(nextIndex))
      delay(() => {
        animateScrollTo(
          document.querySelector(
            `[data-name='${formName}'] .form-collection__subform:last-of-type`
          ),
          {
            elementToScroll: window,
            maxDuration: 3000,
            minDuration: 250,
            speed: 500,
          }
        ).catch((e) => {})
      }, 300)
    }

    /**
     * Hide the matching form, and clear our data from formik state.
     * @param index
     */
    const removeForm = (index) => {
      defer(() => formDisplay(index, false))
    }

    useEffect(() => {
      state.forms.forEach((form, index) => {
        if (formik?.values[formName]?.[index]) {
          formDisplay(index)
        }
      })
    }, [])

    // console.log(`FormCollection::${formName}`, state, formik.values)

    return (
      <div ref={wrapperRef} className="form-collection" data-form-count={maxEntries}>
        <div className="form-collection__forms">
          {state.forms.map((form, index) => {
            const formId = `subform_${formName}_${index}`
            return (
              <div
                id={formId}
                className="form-collection__subform"
                data-subform-type={type}
                key={formId}
                style={{
                  display: state.enabled[index] ? 'block' : 'none',
                }}
              >
                <Button className="form-collection__remove" onClick={() => removeForm(index)}>
                  <span>X</span>&nbsp;Clear
                </Button>
                <header>
                  <div className="form-collection__subform-title">
                    {title}
                    {/*{index + 1}*/}
                  </div>
                </header>
                {form.map((subfieldProps) => {
                  const defaultValue = get(
                    initialValues,
                    subfieldProps.name.substr(subfieldProps.name.indexOf('['))
                  )

                  return (
                    <FormSubField
                      key={subfieldProps.name}
                      {...fieldProps}
                      {...subfieldProps}
                      defaultValue={defaultValue}
                      formik={formik}
                      setValidators={setValidators}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <button className="form-collection__add-wrapper" type="button" onClick={addSubform}>
          <Button
            className="form-collection__add"
            disabled={state.displayed === maxEntries}
            onClick={addSubform}
          >
            + {action}
          </Button>
        </button>
      </div>
    )
  }
)

// Exports
// FormCollection.defaultProps = defaultProps
// FormCollection.propTypes = propTypes
FormCollection.displayName = 'FormCollection'
export default FormCollection

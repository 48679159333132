import { CHECKOUT_STEP_ENUMS } from '@ggs/components/ecomm/Checkout/enums'

const {
  // AUTH,
  COMPLETE,
  ORDER: SHIPPING,
  PAYMENT: BILLING,
  REVIEW
} = CHECKOUT_STEP_ENUMS

// methods to extract information from cl order response data
export const parseOrder = {
  // [AUTH]: {
  //   customerId: (/** @type {{ customer: { id: any; }; }} */ order) =>
  // order?.customer?.id,// order } },
  [SHIPPING]: {
    shippingCountryCode: (/** @type {any} */ order) => order?.shipping_address?.country_code ?? null,
    shippingStateCode: (/** @type {any} */ order) => order?.shipping_address?.state_code ?? null,
    shippingAddressId: (/** @type {any} */ order) => order?.shipping_address?.id ?? null,
    // shippingMethod = get(order, 'shipments[0].shipping_method'))
    //TODO: will this always be the first and only ???
    shippingMethodId: (/** @type {any} */ order) => order?.shipments?.[0]?.shipping_method?.id ?? null
  },
  [BILLING]: {
    billingCountryCode: (/** @type {any} */ order) => order?.billing_address?.country_code ?? null,
    billingStateCode: (/** @type {any} */ order) => order?.billing_address?.state_code ?? null,
    billingAddressId: (/** @type {any} */ order) => order?.billing_address?.id ?? null,
    paymentMethodId: (/** @type {any} */ order) => order?.payment_method?.id ?? null,
    paymentSourceId: (/** @type {any} */ order) => order?.payment_source?.id ?? null,
  },
  [REVIEW]: { acceptTerms: (/** @type {any} */ order) => order ?? null }
}
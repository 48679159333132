// Measure additions to and removals from shopping carts
// To measure the addition of a product to a shopping cart, send an add_to_cart event with the product information:
import { pushDataLayer } from '@ggs/components/ecommerceAnalytics/gtm/dataLayer/pushDataLayer'
import { EcActionType as EaActionType } from '@ggs/components/ecommerceAnalytics/gtm/types/gtag/EcActionTypeGtag'

export const fireAddToCart = async ({ gtmProduct, quantity, value }) => {
  return pushDataLayer({
    eventName: EaActionType.addToCart,
    dataLayer: {
      items: [{ ...gtmProduct, quantity }],
      currency: gtmProduct.currency,
      value: value
    },
  })
}

// @ts-nocheck
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import {
  addBillingAddress,
  addShippingAddress,
  getCountriesOptions,
  getCountryStateOptions,
  resetOrderShippingMethod,
} from '@ggs/commercelayer'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import useAddressFormSx from '@ggs/components/ecomm/Customer/AddressForm/useAddressFormSx'
import useGeoData from '@ggs/components/ecomm/Customer/AddressForm/useGeoData'
import { Divider, Title } from '@ggs/components/paragraphs'
import { useForm } from '@ggs/forms/Form'
import { Forms } from '@ggs/forms/schema'
import { useI18n } from '@ggs/gatsby/lib'
import { useValidators } from '@ggs/hooks'
import { useDispatch, useSelector } from '@ggs/store'
import { hashValue } from '@ggs/utils'
import { decomposeLanguageLocaleCode } from '@ggs/utils/languagesDigest'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Box from '@mui/material/Box'
import {
  defaults,
  delay,
  extend,
  find,
  first,
  get,
  isEmpty,
  isFunction,
  isObject,
  omit,
  pick,
  size,
} from 'lodash'
import { validateAddress } from './AddressForm/validateAddress'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'

export const ADDRESS_FIELDS = {
  // Contact
  email: 'email',
  phone: 'phone',
  // Address
  firstName: 'first_name',
  lastName: 'last_name',
  address1: 'line_1',
  address2: 'line_2',
  city: 'city',
  stateProvince: 'state_code',
  zipPostal: 'zip_code',
  countryCode: 'country_code',
}

/**
 * @typedef {Object} AddressLabels
 * @property {String} email Email label
 * @property {String} country Country label
 * @property {String} submitLabel Submit button label
 * @property {String} afterEmail After email description
 * @property {string} afterPhone After phone description
 */

/**
 * @typedef {Object} AddressFormProps
 * @property {String} orderPropertyKey Order property that contains the address
 *   state
 * @property {AddressLabels} labels Form labels
 * @property {function=} onSuccess onSuccess Callback Fn
 */

/**
 * Checkout base address form (order information step).
 * @return {JSX.Element}
 */
const AddressForm = ({ orderPropertyKey, labels, onSuccess, onError }) => {
  const ui = useSelector((state) => state.ui)

  const {
    order,
    clClient,
    shippingAddress: lastShippingAddress,
    billingAddress: lastBillingAddress,
    billingSameAsShipping: selectedBillingSameAsShipping,
    onShippingAddressDispatch,
    onBillingAddressDispatch,
    onBillingSameAsShipping,
    billingAddressController,
    shippingAddressController,
    // billingGeoCodes,
    // shippingGeoCodes,
  } = useCheckoutContext()

  const { t } = useI18n()
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)
  const { getGeoData } = useGeoData()
  const settings = useSelector((state) => state.settings)
  const currentLanguage = decomposeLanguageLocaleCode(
    settings?.language || ui?.layoutMeta?.currentStore?.language?.id
  )

  const isBillingAddress = orderPropertyKey === 'billing_address'
  const isShippingAddress = orderPropertyKey === 'shipping_address'
  const firstOrderShipment = first(order?.shipments) || null
  const { [orderPropertyKey]: addressController }
    = orderPropertyKey === 'billing_address' ? billingAddressController : shippingAddressController
  console.log(addressController)
  const stateCodeSelected = addressController?.stateCodeSelected
  const setCountryCodeSelected = addressController?.setCountryCodeSelected
  const countryCodeSelected = addressController?.countryCodeSelected
  const setStateCodeSelected = addressController?.setStateCodeSelected
  const onAddressSubmit = addressController?.onAddressSubmit
  const onAddressSubmitIsoCodes = addressController?.onAddressSubmitIsoCodes
  const addressValuesToIso = addressController?.addressValuesToIso
  const shippingAddress = order?.['shipping_address']
  const address = order[orderPropertyKey]
  const countryRef = useRef('')
  const validators = useValidators({ country: countryRef })

  const mapCountryLabelToCode = (countryOptions, label) => {
    const option = find(countryOptions, (countryOption) => {
      if (isObject(countryOption)) {
        return countryOption?.label?.toLowerCase() === label.toLowerCase()
      }
    })

    return option?.value || ''
  }

  const mapStateLabelToCode = (countryStateOptions, label) => {
    // console.log('mapStateLabel: ', countryStateOptions)
    const option = find(countryStateOptions, ['label', label])
    // console.log('OptionMatch: ', option)
    return option?.value || ''
  }

  const sx = useAddressFormSx()
  const { fireAddShippingAddress } = useCheckoutAnalytics({ order })

  const fieldsDefinition = {
    title: {
      caption: (
        <>
          <Title
            title={labels.country}
            style="h3"
            sx={{
              mb: !isBillingAddress ? 3 : 0,
            }}
          />
        </>
      ),
      type: Forms.FieldTypes.content,
    },
    // cloneAddress: {
    //   label: t('ecomm:label.billingSameAsShippingAddress'),
    //   type: Forms.FieldTypes.checkbox,
    //   validator: validators.yupBoolean,
    //   defaultChecked: prevCloneRef.current,
    //   checked: prevCloneRef.current,
    // },
    [ADDRESS_FIELDS.countryCode]: {
      label: t('account:input.label.country'),
      type: Forms.FieldTypes.select,
      style: Forms.SelectStyles.autocomplete,
      options: [],
      validator: validators.nonBlank,
      defaultValue: String(address?.metadata?.country_name || address?.country_code || ''),
      autoComplete: 'country-name',
      autoHighlight: true,
      autoSelect: true,
    },
    [ADDRESS_FIELDS.firstName]: {
      label: t('account:input.label.firstName'),
      type: Forms.FieldTypes.text,
      validator: validators.firstName,
      defaultValue: address?.first_name || '',
      // || customer?.metadata?.first_name
      autoComplete: 'given-name',
    },
    [ADDRESS_FIELDS.lastName]: {
      label: t('account:input.label.lastName'),
      type: Forms.FieldTypes.text,
      validator: validators.lastName,
      defaultValue: address?.last_name || '',
      // || customer?.metadata?.last_name ||
      autoComplete: 'family-name',
    },
    [ADDRESS_FIELDS.address1]: {
      label: t('account:input.label.streetAddress'),
      type: Forms.FieldTypes.text,
      validator: validators.nonBlank,
      defaultValue: address?.line_1 || '',
      autoComplete: 'address-line1',
    },
    [ADDRESS_FIELDS.address2]: {
      label: t('account:input.label.streetLine2'),
      type: Forms.FieldTypes.text,
      validator: validators.yupString.max(
        61,
        t('global:form.error.maxXCharactersLong', { max: 61 })
      ),
      defaultValue: isEmpty(address?.line_2) ? '' : address?.line_2,
      autoComplete: 'address-line2',
    },
    [ADDRESS_FIELDS.stateProvince]: {
      label: t('account:input.label.state'),
      type: Forms.FieldTypes.select,
      style: Forms.SelectStyles.autocomplete,
      options: [],
      validator: validators.nonBlank,
      autoComplete: 'address-level1',
      defaultValue: address?.metadata?.state_name,
    },
    [ADDRESS_FIELDS.city]: {
      label: t('account:input.label.city'),
      type: Forms.FieldTypes.text,
      helpText: t('account:notice.cityWhenRequired'),
      defaultValue: address?.city || '',
      validator: validators.city,
      autoComplete: 'address-level2',
    },
    [ADDRESS_FIELDS.zipPostal]: {
      label: t('account:input.label.postalOrZipCode'),
      type: Forms.FieldTypes.text,
      validator: validators.postalCode,
      defaultValue: address?.zip_code || '',
      placeholder: '',
      autoComplete: 'postal-code',
    },
    divider: {
      caption: (
        <Divider
          sx={{
            mt: 3,
            mb: 3,
          }}
        />
      ),
      type: Forms.FieldTypes.content,
    },
    [ADDRESS_FIELDS.email]: {
      caption: <Title title={labels.email} style="h3" sx={{ mb: 3 }} />,
      label: t('account:input.label.email'),
      type: Forms.FieldTypes.email,
      validator: validators.email,
      helpText: labels.afterEmail,
      defaultValue: address?.email || '',
      // customer?.email || '',
      // disabled: isAuth(),
      // TODO: disable when authenticated
      autoComplete: 'email',
    },
    [ADDRESS_FIELDS.phone]: {
      caption: (
        <Title
          title={'&nbsp;'}
          style="h3"
          sx={{
            mb: 3,
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        />
      ),
      label: t('account:input.label.phone'),
      type: Forms.FieldTypes.text,
      validator: validators.phoneNumber,
      helpText: labels.afterPhone,
      defaultValue: address?.phone || '', // shippingAddress?.phone || '',
      autoComplete: 'tel',
    },
  }

  const disableField = (field) => {
    return {
      ...field,
      type: Forms.FieldTypes.hidden,
      validator: null,
      afterFieldText: '',
    }
  }

  const defaultField = (field, fieldKey) => {
    return {
      ...field,
      type: fieldsDefinition[fieldKey].type,
      validator: fieldsDefinition[fieldKey].validator,
      afterFieldText: fieldsDefinition[fieldKey].afterFieldText,
    }
  }

  // Avoid clone address in shipping or when billing address exists.
  if (!isBillingAddress) {
    delete fieldsDefinition['cloneAddress']
  }
  // Contact details on billing not required, will be assumed from staging.
  else {
    delete fieldsDefinition['divider']
    delete fieldsDefinition[ADDRESS_FIELDS.email]
    delete fieldsDefinition[ADDRESS_FIELDS.phone]
  }

  const formActions = [
    {
      // If we have a stored address on load, we can only update addresses then.
      label:
        !address?.[ADDRESS_FIELDS.countryCode] || !firstOrderShipment?.shipping_method
          ? labels.submitLabel
          : t('ecomm:button.updateAddress'),
      variant: 'outlined',
      endIcon: <ChevronRightIcon />,
      // displayed: !disableWhenBillingSameAsShipping,
    },
  ]

  // eslint-disable-next-line prefer-const
  let Form

  /**
   *
   * @param {Object} values
   * @return {Promise<{countryCode: *, stateProvince: *}>}
   */
  const getGeoIsoCodes = async (values) => {
    const countryOptions = Form.fields?.[ADDRESS_FIELDS.countryCode]?.options || [
      {
        label: values?.[ADDRESS_FIELDS.countryCode],
        value: values?.[ADDRESS_FIELDS.countryCode],
      },
    ]
    const countryCode = mapCountryLabelToCode(countryOptions, values?.[ADDRESS_FIELDS.countryCode])
    const countryStateOptions = (await getCountryStateOptions(
      countryRef.current,
      currentLanguage.langCode
    )) || [
      {
        label: values?.[ADDRESS_FIELDS.countryCode],
        value: values?.[ADDRESS_FIELDS.countryCode],
      },
    ]
    const stateProvince = mapStateLabelToCode(
      countryStateOptions,
      values?.[ADDRESS_FIELDS.stateProvince]
    )
    return { countryCode, stateProvince }
  }

  /**
   * Trigger shipping method reset when current selected country have changed.
   * @return {Promise<void>}
   */
  const checkNeedsShippingMethodReset = async () => {
    // Sometimes formik has country name, others has iso2, depending if value
    // was submitted or still in draft.
    const currentOrderCountry
      = size(Form.context?.values?.country_code) === 2
        ? address?.country_code
        : address?.metadata?.country_name
    const currentOrderProvince
      = size(Form.context?.values?.state_code) <= 3
        ? address?.state_code
        : address?.metadata?.state_name
    const isCountryChanged = Form.context?.values?.country_code !== currentOrderCountry
    const isProvinceChanged = Form.context?.values?.state_code !== currentOrderProvince
    const isZipCodeChanged = Form.context?.values?.zip_code !== address?.zip_code
    const hasShippingMethod = firstOrderShipment?.shipping_method

    // When currently selected country changes, reset currently selected
    // shipping method.
    if (hasShippingMethod && (isCountryChanged || isProvinceChanged || isZipCodeChanged)) {
      dispatch(resetOrderShippingMethod(clClient, order))
    }
  }

  const onSubmit = async (values, { setSubmitting }, formError) => {
    const fieldKeys = Object.keys(fieldsDefinition).concat(['id'])
    const isoCodes = await getGeoIsoCodes(values)
    const { countryCode, stateProvince: stateCode } = onAddressSubmitIsoCodes({
      isoCodes,
      country: values?.[ADDRESS_FIELDS.countryCode],
      state: values?.[ADDRESS_FIELDS.stateProvince],
    })

    onAddressSubmit(values)

    const addressValues = addressValuesToIso({
      addressValues: {
        ...(get(order, `${orderPropertyKey}.id`)
          ? defaults(values, pick(order[orderPropertyKey], fieldKeys))
          : defaults({}, values)),
      },
      stateProvince: isoCodes?.stateProvince, // ?? stateCodeSelected,
      countryCode: isoCodes?.countryCode, //?? countryCodeSelected,
      orderPropertyKey,
    })

    if (addressValues.state_code === '') {
      addressValues.state_code = stateCodeSelected
    }

    setStateCodeSelected(isoCodes?.stateProvince)
    setCountryCodeSelected(isoCodes?.countryCode)

    const actionData = extend(
      {
        ...omit(addressValues, ['cloneAddress', ADDRESS_FIELDS.phone]),
      },
      {
        metadata: {},
      }
    )

    // Process shipping address validation.
    if (isShippingAddress) {
      // Add phone for shipping address.
      actionData[ADDRESS_FIELDS.phone] = addressValues?.[ADDRESS_FIELDS.phone]

      const currentAddressHash = addressValues.metadata?.validation_hash
      const newAddressHash = hashValue(addressValues)
      // Only revalidate if address has changed. Else, the suer is just saving the same address again.
      if (currentAddressHash !== newAddressHash) {
        const validateResponse = await validateAddress(addressValues)
        // If the address is valid, store meta hash to avoid re-validation unless changed.
        if (validateResponse) {
          actionData.metadata = {
            ...actionData.metadata,
            validation_hash: newAddressHash,
          }
        } else {
          fireAddShippingAddress({ order, type: 'failure' })
          formError(t('ecomm:notification.addressValidationFailed'), 'error')
          setSubmitting(false)
          return
        }
      }
    }

    // Set country and state name when string size is not of code size.
    const { country_code = '', state_code = '' } = values
    if (size(country_code) > 2) {
      actionData.metadata = {
        ...actionData.metadata,
        country_name: country_code,
        state_name: state_code,
      }
    }

    // const { tax_calculations_count } = order
    if (shippingAddress) {
      onShippingAddressDispatch(actionData)
    } else {
      onBillingSameAsShipping(values?.cloneAddress)
      onBillingAddressDispatch(actionData)
    }

    // create new ones and destroy only, never update.
    const shipAction = addShippingAddress
    const billAction = addBillingAddress

    // If we're on the shipping step, add or update the shipping address.
    if (!isBillingAddress) {
      dispatch(
        shipAction(clClient, order, actionData, {
          onSuccess: (action) => {
            // console.log('AddressAction:', action)'
            isFunction(onSuccess) && onSuccess(action)
            setSubmitting(false)
            fireAddShippingAddress({ order, type: 'success' })
            if (isShippingAddress && address) {
              checkNeedsShippingMethodReset()
            }
          },
          onError: (action) => {
            fireAddShippingAddress({ order, type: 'failure' })
            setSubmitting(false)
            // dispatch(addNotification('max_tax_calculations_count_retry', 'error'))
            dispatch(resetOrderShippingMethod(clClient, order))
            onError()
          },
        })
      )
    }

    // If the billing address matched the shipping address are run time, and
    // we're updating shipping, we must force update billing again. If we're
    // on the shipping address step and billing is blank, add it, else
    // add/update from billing step.
    if (
      (!isBillingAddress && (!order?.billing_address || selectedBillingSameAsShipping))
      || isBillingAddress
    ) {
      // Custom phone field billing seems to require vs phone_number.
      actionData.phone
        = addressValues?.[ADDRESS_FIELDS.phone] || shippingAddress?.[ADDRESS_FIELDS.phone] || '-'

      dispatch(
        billAction(clClient, order, actionData, {
          onSuccess: (action) => {
            // console.log('AddressAction:', action)
            setSubmitting(false)
            isFunction(onSuccess) && onSuccess(action)
            // Refresh actions with latest data.
            Form.setActions(formActions)
          },
          onError: (action) => {
            // console.log('AddressActionError:', action)
            setSubmitting(false)
          },
        })
      )
    }
  }
  Form = useForm({
    autoComplete: true,
    className: `${orderPropertyKey.replace('_', '-')}-form`,
    fields: fieldsDefinition,
    actions: formActions,
    onSubmit,
    sx,
  })

  const loadCountryStateOptions = useCallback(async () => {
    const { countryOptions, countryStateOptions, countryDivisions, zipCodeSample }
      = await getGeoData(countryRef.current || address?.[ADDRESS_FIELDS.countryCode])

    const defaultStateValue = address?.[ADDRESS_FIELDS.stateProvince]

    const newFields = {
      ...Form.fields,
      [ADDRESS_FIELDS.stateProvince]: {
        ...Form.fields[ADDRESS_FIELDS.stateProvince],
        // Obtain new state options
        options: countryStateOptions,
        value:
          address?.metadata?.state_name
          || address?.metadata?.state_code
          || address?.state_code
          || stateCodeSelected
          || defaultStateValue,
        defaultValue:
          address?.metadata?.state_name
          || address?.metadata?.state_code
          || address?.state_code
          || stateCodeSelected
          || defaultStateValue,
      },
      [ADDRESS_FIELDS.countryCode]: {
        ...Form.fields[ADDRESS_FIELDS.countryCode],
        options: countryOptions,
        value: countryRef.current,
        defaultValue:
          address?.country_code || address?.metadata?.country_name || countryRef.current,
        autoSelect: true,
        autoHighlight: true,
        validator: isShippingAddress ? validators.countryCanadaOnly : null,
      },
      [ADDRESS_FIELDS.zipPostal]: {
        ...Form.fields[ADDRESS_FIELDS.zipPostal],
        placeholder: zipCodeSample,
      },
    }

    const disableOrEnableField = (target, targetField) => {
      if (!countryDivisions.includes(target)) {
        newFields[targetField] = disableField(newFields[targetField])
      } else {
        newFields[targetField] = defaultField(newFields[targetField], targetField)
      }
    }

    // Adaptive address fields based on country divisions.
    if (countryDivisions) {
      disableOrEnableField('administrativeArea', ADDRESS_FIELDS.stateProvince)
      disableOrEnableField('locality', ADDRESS_FIELDS.city)
      disableOrEnableField('addressLine2', ADDRESS_FIELDS.address2)
      disableOrEnableField('postalCode', ADDRESS_FIELDS.zipPostal)
    }

    // Update state options
    Form.setFields(newFields)
  }, [Form.context?.values?.country_code])

  /**
   * Resolve currently selected country code using latest formik value and
   * mapping to iso2 code.
   * @return {Promise<string>} The currently selected country iso2 code.
   */
  const resolveCurrentSelectedCountryCode = async () => {
    const countryOptions = await getCountriesOptions(currentLanguage.langCode)
    const currentCountryCode = mapCountryLabelToCode(
      countryOptions,
      Form.context?.values?.country_code || ''
    )
    return currentCountryCode
  }

  // As the country code input is changed
  useLayoutEffect(() => {
    const runAsync = async () => {
      const newCountry = await resolveCurrentSelectedCountryCode()
      // Country changed, update state options and reset state.
      if (countryRef.current !== newCountry) {
        countryRef.current = newCountry
        await loadCountryStateOptions()
        // Reset state code value.
        // Form.context.setFieldValue('state_code', '')
        // Update ref
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    runAsync()
  }, [Form.context?.values?.country_code, address?.country_code])

  // // As the country changes, reset state field.
  useLayoutEffect(() => {
    const runAsync = async () => {
      // Only reset if we have a country code, and a state code.
      const stateSelect = wrapperRef?.current?.querySelector('[name="state_code"]')
      if (stateSelect && Form.context?.values?.country_code && Form.context?.values?.state_code) {
        stateSelect.querySelector('input')?.focus()
        stateSelect.querySelector('.MuiAutocomplete-clearIndicator')?.click()
        delay(() => {
          wrapperRef?.current?.querySelector('[name="first_name"]')?.focus()
        }, 0)
        // console.log('State reset needed', field)
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    runAsync()
  }, [Form?.context?.values?.country_code])

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    loadCountryStateOptions()
  }, [])

  // Fire shipping address error tracking event on shipping address form errors.
  useEffect(() => {
    const hasErrors = !isEmpty(Form.context?.errors)
    const isSubmitting = Form.context?.isSubmitting && Form.context?.submitCount > 0 && !Form.context?.isValid
    if (isShippingAddress && isSubmitting && hasErrors) {
      fireAddShippingAddress({ order, type: 'failure' })
    }
  }, [Form.context?.isSubmitting, Form.context?.submitCount, Form.context?.isValid])

  return (
    <Box
      ref={wrapperRef}
      // className={shouldHideBillingFields
      //   ? 'shouldHideBillingFields'
      //   : ''}
      sx={{
        '.form__actions': {
          justifyContent: 'flex-start',
        },
        // '&.shouldHideBillingFields': {
        //   '.form__actions': {
        //     display: 'none',
        //   },
        //   '.form-field:not([data-input-type="checkbox"],[data-input-type="content"])':
        // { display: 'none', }, },
      }}
    >
      {Form.FormComp}
      <Divider
        sx={{
          mt: isBillingAddress ? 0 : 3,
          mb: 3,
        }}
      />
    </Box>
  )
}

export default AddressForm

const { flatten, get } = require('lodash')

/**
 * Checks if results list page is the last one.
 * @param {any} currentList Current price list
 * @return {Boolean} True if has more pages, false otherwise.
 */
export function isLastPage(currentList) {
  const currentPage = get(currentList, 'meta.currentPage', 0)
  const pageCount = get(currentList, 'meta.pageCount', 0)

  return currentPage >= pageCount
}

/**
 * @template R
 * @typedef {(pageNumber: number) => R} GetDataFunction
 */

/**
 * Get all page items using a CL get data callback.
 * @template R
 * @param {GetDataFunction<R>} getDataCallback
 * @return {Promise<any>}
 */
export async function getAllPageItems(getDataCallback) {
  const pagePointer = {
    current: await getDataCallback(1),
  }

  /**
   * Collections of page items.
   * @template R
   * @type {Array.<any>}
   */
  const allItems = []
  allItems.push(pagePointer.current)

  while (
    pagePointer.current.hasMore
      || pagePointer.current.meta?.pageCount > pagePointer.current.meta?.currentPage
  ) {
    // @ts-ignore
    const currentPage = pagePointer.current.meta.currentPage
    const nextPage = currentPage + 1
    pagePointer.current = await getDataCallback(nextPage)
    allItems.push(pagePointer.current)
  }

  // Inject first page items to all items array.
  return flatten(allItems)
}

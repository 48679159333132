import { useMemo } from 'react'
import { breakpoints } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 *
 * @return {SxProps}
 */
export default function useProductHeroSx() {
  return useMemo(
    () => ({
      mt: {
        xs: 2,
        lg: 5,
      },
      mb: {
        xs: 2,
        lg: 4,
      },
      maxWidth: breakpoints.xl,
      '.product-hero__gallery': {
        mb: {
          xs: 0,
          lg: 4,
        },
      },
      '.share-page': {
        justifyContent: 'normal',
      },
      '.title__title': {
        mb: 2,
        mt: 0,
        pt: 0,
      },
      '.title__subtitle': {
        mb: 3,
        mt: 0,
        pt: 0,
      },
      '.product-hero__categories': {
        mb: 3,
      },
      '.product-hero__supporting': {
        display: 'flex',
        flexDirection: 'column',
      },
      '.divider': {
        px: 0,
      },
    }),
    []
  )
}

import { colors, common, typography } from '@ggs/styles'
import { useMemo } from 'react'
import { rem } from '@ggs/styles'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * Image Cta Variant
 * @enum {string}
 */
export const CartLineItemVariant = {
  mini: 'mini',
  full: 'full',
}

/**
 * @param {CartLineItemVariant} variant
 * @return {SxProps}
 */
export default function useCartLineItemSx(variant) {
  return useMemo(() => {
    const sx = {
      alignItems: 'start',
      p: 0,
      pb: 3,
      mb: 3,
      backgroundColor: 'none',
      borderBottom: common.borderLightGrey,
      '&:last-child': {
        mb: 0
      },
      '& .cart-line-item__avatar-wrapper': {
        '& a:hover, & a:focus': {
          textDecoration: 'none',
        },
        mr: 2,
        '& *, & .MuiAvatar-root': {
          width: '100%',
        },
        width: {
          xs: rem(88),
          md: rem(120),
        },
        '&, & *, & .MuiAvatar-root': {
          height: rem(88),
        },
      },
      '& .cart-line-item__item-text': {
        m: 0,
        '& .cart-line-item__item-name, & .product-option': {
          mb: 1,
        },
        '& .cart-line-item__item-name': {
          typography: typography.menuItemSmall,
        },
        '& .cart-line-item__item-details, & p': {
          mt: 0,
        },
        '& .cart-mini-view__item-subtitle, & .cart-mini-view__item-quantity': {
          typography: typography.menuItemRegular,
          fontWeight: 'normal',
        },
        '& .cart-mini-view__item-quantity': {
          display: 'flex',
          alignItems: 'center',
          m: 0,
        },
        '& .cart-mini-view__item-included': {
          typography: typography.menuItemSmall,
        },
        '& .cart-mini-view__item-quantity span': {
          display: 'inline-block',
          mr: 1,
          typography: typography.menuItemSmall,
        },
      },
    }

    // Override styling based on theme variant
    switch (variant) {
      case CartLineItemVariant.full:
        Object.assign(sx, {
          py: {
            xs: 2,
            md: 5,
          },
          px: {
            xs: 2,
            md: 4,
          },
          mb: {
            xs: 3,
            md: 1,
          },
          backgroundColor: colors.white,
          boxShadow: '0 0 16px rgba(0, 0, 0, .04)',
          borderBottom: 0,
          borderRadius: '4px',
          // flexDirection: {
          //   xs: 'column',
          //   md: 'inherit',
          // },
          '& .cart-line-item__avatar-wrapper': {
            ...sx['& .cart-line-item__avatar-wrapper'],
            position: 'relative',
            mb: 6,
            mr: {
              xs: 2,
              md: 5,
            },
            minWidth: {
              xs: rem(88),
              md: rem(184),
            },
            '&, & *, & .MuiAvatar-root': {
              height: {
                xs: rem(88),
                md: rem(136),
              },
            },
            '& > a': {
              border: `2px solid ${colors.ultraLightGrey}`,
              borderRadius: '4px',
              boxSizing: 'borer-box',
              display: 'block',
            }
            // flex: {
            //   xs: '0 0 100%',
            //   md: 'inherit',
            // },
            // alignSelf: {
            //   xs: 'center',
            //   md: 'inherit',
            // }
          },
          '& .cart-line-item__item-text': {
            ...sx['& .cart-line-item__item-text'],
            '& .cart-line-item__item-name': {
              typography: typography.h4,
              mb: 2,
              fontSize: {
                xs: 14,
                md: 'inherit',
              },
            },
          },
          '& .cart-line-item__remove': {
            // position: 'absolute',
            p: 0,
            mt: 2,
            height: 'auto',
            '&:hover span, &:focus span': {
              textDecoration: 'underline',
            },
            '& span': {
              typography: typography.pRegular,
              fontWeight: 500,
              color: colors.red,
            },
          },
        })
        break
    }

    return sx
  }, [variant])
}

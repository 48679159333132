/**
 * Custom GTM event label for links
 * @param {String} text
 * @param {String} defaultValue
 * @returns {String}
 */
export function getGTMEventLabel(text, defaultValue) {
  const cleanText = text.toLowerCase().trim()
  if (cleanText.includes('request a kit')) {
    return 'click-demo-kit'
  } else {
    return defaultValue
  }
}

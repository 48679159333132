
/**
 *
 * @param {String} id
 * @param {Number} index
 * @return {{"aria-controls": string, id: string}}
 */
export default function a11yProps(id, index) {
  return {
    id: `${id}-${index}`,
    'aria-controls': `${id}-panel-${index}`,
  }
}

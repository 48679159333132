import { initialState } from '../initialState'
import { createReducer } from '@reduxjs/toolkit'
import { analyticsActionsType } from '../actions/analytics'

/**
 * For tracking transaction on google enchance ecommerce
 * @returns {DefaultState['analytics']} New analytics state.
 */
const analytics = createReducer(initialState.analytics, {
  [analyticsActionsType.SET_VIEW_CART]: (state, action) => {
    // console.log(action)
    state.reportedCart = {
      items: action.payload.items ?? [],
      // line_items: action.payload.line_items,
      // orderId: action.payload.orderId,
      reported: true
    }
  },
  [analyticsActionsType.RESET_VIEW_CART]: (state) => {
    // console.log(action)
    state.reportedCart = {
      items: [],
      reported: false
    }
  },
  [analyticsActionsType.SET_BEGIN_CHECKOUT]: (state, action) => {
    // console.log(action)
    state.reportedCheckout = {
      orderId: action.payload.orderId,
      reported: true
    }
  },
  [analyticsActionsType.RESET_BEGIN_CHECKOUT]: (state, action) => {
    // console.log(action)
    state.reportedCheckout = {
      orderId: null,
      reported: false
    }
  },
  [analyticsActionsType.SET_ADD_SHIPPING_INFO]: (state, action) => {
    state.reportedShippingInfo = action.payload
  },
  [analyticsActionsType.RESET_ADD_SHIPPING_INFO]: (state, action) => {
    state.reportedShippingInfo = {}
  },
  [analyticsActionsType.SET_ADD_PAYMENT_METHOD]: (state, action) => {
    state.reportedPaymentInfo = action.payload
  },
  [analyticsActionsType.RESET_ADD_PAYMENT_METHOD]: (state, action) => {
    state.reportedPaymentInfo = {
      orderId: null,
      reported: false
    }
  },
  [analyticsActionsType.SET_PURCHASE]: (state, action) => {
    state.reportedPurchase = action.payload.orderId
  },
  [analyticsActionsType.RESET_PURCHASE]: (state, action) => {
    state.reportedPurchase = null
  },
})


export default analytics

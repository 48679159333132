import { CommerceLayerClient } from '@commercelayer/sdk'
import { ThunkAction } from 'redux-thunk'
import { handleApiError, invokeSuccessCallback, invokeErrorCallback } from './CommonActions'
import {
  AddressCreate,
  AddressList,
  AddressUpdate,
  ApiErrorAction,
  Callbacks,
  Customer,
  CustomerCreate,
  CustomerAddress,
  CustomerPaymentSourceList,
  CustomerUpdate,
  OrderList,
  StripePaymentCreate,
} from '../lib/types'
import { filter, sortBy } from 'lodash'

/**
 * Customer action types.
 * @enum {string}
 */
export const customerActionType = {
  ADD_PAYMENT_SOURCE: 'ADD_PAYMENT_SOURCE',
  ADD_CUSTOMER_PAYMENT_SOURCE: 'ADD_CUSTOMER_PAYMENT_SOURCE',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_ADDRESS: 'CREATE_CUSTOMER_ADDRESS',
  DELETE_CUSTOMER_ADDRESS: 'DELETE_CUSTOMER_ADDRESS',
  DELETE_CUSTOMER_PAYMENT_SOURCE: 'DELETE_CUSTOMER_PAYMENT_SOURCE',
  GET_CUSTOMER_ADDRESSES: 'GET_CUSTOMER_ADDRESSES',
  GET_CUSTOMER_ORDERS: 'GET_CUSTOMER_ORDERS',
  GET_CUSTOMER_DRAFT_ORDERS: 'GET_CUSTOMER_DRAFT_ORDERS',
  GET_CUSTOMER_PASS_RESET_TOKEN: 'GET_CUSTOMER_PASS_RESET_TOKEN',
  GET_CUSTOMER_PAYMENT_SOURCES: 'GET_CUSTOMER_PAYMENT_SOURCES',
  UPDATE_CUSTOMER_ADDRESS: 'UPDATE_CUSTOMER_ADDRESS',
  UPDATE_CUSTOMER_INFORMATION: 'UPDATE_CUSTOMER_INFORMATION',
  LOGIN_EXISTING_CUSTOMER: 'LOGIN_EXISTING_CUSTOMER'
}

/**
 * Retrieve customer in latest state.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} customerId ID of the order entity to update
 * @return {Promise<Customer>} CL customer
 */
const retrieveCustomer = async (clClient, customerId) => {
  return await clClient.customers.retrieve(customerId, {
    include: ['customer_group', 'customer_addresses.address', 'customer_payment_sources', 'orders'],
  })
}

/**
 * Update customer address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Customer} customer Address customer owner
 * @param {AddressUpdate} address Address to update
 * @return {Promise<Customer>} Updated order
 */
const updateAddress = async (clClient, customer, address) => {
  await clClient.addresses.update(address)
  return await retrieveCustomer(clClient, customer.id)
}

/**
 * @typedef {Object} CustomerOrdersActionPayload
 * @property {OrderList} orders Orders list
 */

/**
 * @typedef {Object} CustomerOrdersAction
 * @property {customerActionType} type Action type key
 * @property {CustomerOrdersActionPayload} payload Action input data
 */

/**
 * Get customer orders.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} customerId CL customer ID
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerOrdersAction|ApiErrorAction>} Thunk action or error action
 */
export const getCustomerOrders = (clClient, customerId = null, callbacks) => {
  return async (dispatch) => {
    try {
      const customer = await clClient.customers.retrieve(customerId, {
        include: [
          'orders.line_items',
          'orders.shipping_address',
          'orders.billing_address',
          'orders.market',
        ],
        fields: { market: ['number', 'name'] },
      })

      const action = {
        type: customerActionType.GET_CUSTOMER_ORDERS,
        payload: {
          orders: customer.orders,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Get customer draft orders.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} customerId CL customer ID
 * @param {Object<string, string|number|boolean>} filters Extra filters for query the orders list.
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerOrdersAction|ApiErrorAction>} Thunk action or error action
 */
export const getCustomerDraftOrders = (clClient, customerId = null, filters = {}, callbacks) => {
  return async (dispatch) => {
    try {
      const customer = await clClient.customers.retrieve(customerId, {
        include: ['orders', 'orders.market'],
      })

      // TODO: Is not possible to resolve the order market number so as workaround we inject market number
      // in metadata to allow lookup orders for current market. Follow with CL a better solution.
      const filteredOrders = filter(customer.orders, {
        status: 'pending',
        ...filters,
      })

      const sortedOrders = sortBy(filteredOrders, 'updated_at').reverse()
      const action = {
        type: customerActionType.GET_CUSTOMER_DRAFT_ORDERS,
        payload: {
          orders: sortedOrders,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * @typedef {Object} CustomerAddressesActionPayload
 * @property {AddressList} addresses Addresses list
 */

/**
 * @typedef {Object} CustomerAddressesAction
 * @property {customerActionType} type Action type key
 * @property {CustomerAddressesActionPayload} payload Action input data
 */

/**
 * Get customer addresses.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} customerId CL customer ID
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerAddressesAction|ApiErrorAction>} Thunk action or error action
 */
export const getCustomerAddresses = (clClient, customerId = null, callbacks) => {
  return async (dispatch) => {
    try {
      const customer = await clClient.customers.retrieve(customerId, {
        include: ['customer_addresses'],
      })

      const action = {
        type: customerActionType.GET_CUSTOMER_ADDRESSES,
        payload: {
          addresses: customer.customer_addresses,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Get customer payment sources.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} customerId CL customer ID
 * @return {Promise<CustomerPaymentSourceList>} Thunk action or error action
 */
export const retrieveCustomerPaymentSources = async (clClient, customerId = null) => {
  const customer = await clClient.customers.retrieve(customerId, {
    include: ['customer_payment_sources'],
  })

  return customer.customer_payment_sources || []
}

/**
 * @typedef {Object} CustomerPaymentSourcesActionPayload
 * @property {CustomerPaymentSourceList} payment_sources PaymentSources list
 */

/**
 * @typedef {Object} CustomerPaymentSourcesAction
 * @property {customerActionType} type Action type key
 * @property {CustomerPaymentSourcesActionPayload} payload Action input data
 */

/**
 * Add customer payment source.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} customerId CL customer ID
 * @param {StripePaymentCreate} paymentSource New stripe payment source
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerPaymentSourcesAction|ApiErrorAction>} Thunk action or error action
 */
export const addCustomerPaymentSourceStripe = (
  clClient,
  customerId = null,
  paymentSource,
  callbacks
) => {
  return async (dispatch) => {
    try {
      const newPaymentSource = await clClient.stripe_payments.create(paymentSource)
      await clClient.customer_payment_sources.create({
        customer: clClient.customers.relationship(customerId),
        payment_source: clClient.stripe_payments.relationship(newPaymentSource.id),
      })

      const paymentSources = await retrieveCustomerPaymentSources(clClient, customerId)
      const action = {
        type: customerActionType.ADD_CUSTOMER_PAYMENT_SOURCE,
        payload: {
          payment_sources: paymentSources,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Get customer payment sources.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} customerId CL customer ID
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerPaymentSourcesAction|ApiErrorAction>} Thunk action or error action
 */
export const getCustomerPaymentSources = (clClient, customerId = null, callbacks) => {
  return async (dispatch) => {
    try {
      const paymentSources = await retrieveCustomerPaymentSources(clClient, customerId)
      const action = {
        type: customerActionType.GET_CUSTOMER_PAYMENT_SOURCES,
        payload: {
          payment_sources: paymentSources,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Delete customer payment source.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Customer} customer Payment source customer owner
 * @param {string} paymentSourceId Payment source ID
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerAction|ApiErrorAction>} Thunk action or error action
 */
export const deleteCustomerPaymentSource = (clClient, customer, paymentSourceId, callbacks) => {
  return async (dispatch) => {
    try {
      await clClient.customer_payment_sources.delete(paymentSourceId)
      const updatedCustomer = await retrieveCustomer(clClient, customer.id)
      const action = {
        type: customerActionType.DELETE_CUSTOMER_PAYMENT_SOURCE,
        payload: {
          customer: updatedCustomer,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * @typedef {Object} CustomerActionPayload
 * @property {Customer} customer CL customer entity
 */

/**
 * @typedef {Object} CustomerAction
 * @property {customerActionType} type Action type key
 * @property {CustomerActionPayload} payload Action input data
 */

/**
 * Create customer.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {CustomerCreate} customer CL customer create data
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerAction|ApiErrorAction>} Thunk action or error action
 */
export const createCustomer = (clClient, customer = null, callbacks) => {
  return async (dispatch) => {
    try {
      const newCustomer = await clClient.customers.create(customer)
      const action = {
        type: customerActionType.CREATE_CUSTOMER,
        payload: {
          customer: newCustomer,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Update customer address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Customer} customer Address customer owner
 * @param {AddressUpdate} address Address data to update
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerAction|ApiErrorAction>} Thunk action or error action
 */
export const updateCustomerAddress = (clClient, customer, address, callbacks) => {
  return async (dispatch) => {
    try {
      const updatedCustomer = await updateAddress(clClient, customer, address)
      const action = {
        type: customerActionType.UPDATE_CUSTOMER_ADDRESS,
        payload: {
          customer: updatedCustomer,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Create customer address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Customer} customer Address customer owner
 * @param {AddressCreate} address Address to create
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerAction|ApiErrorAction>} Thunk action or error action
 */
export const createCustomerAddress = (clClient, customer, address, callbacks) => {
  return async (dispatch) => {
    try {
      const newAddress = await clClient.addresses.create(address)
      const customerAddressRel = {
        customer: clClient.customers.relationship(customer.id),
        address: clClient.addresses.relationship(newAddress.id),
      }

      await clClient.customer_addresses.create(customerAddressRel)
      const newCustomer = await retrieveCustomer(clClient, customer.id)
      const action = {
        type: customerActionType.CREATE_CUSTOMER_ADDRESS,
        payload: {
          customer: newCustomer,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Delete customer address.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {Customer} customer Address customer owner
 * @param {CustomerAddress} customerAddress Customer address to delete
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerAction|ApiErrorAction>} Thunk action or error action
 */
export const deleteCustomerAddress = (clClient, customer, customerAddress, callbacks) => {
  return async (dispatch) => {
    try {
      // Delete address reference to customer
      await clClient.customer_addresses.delete(customerAddress.id)
      // Delete the address resource
      await clClient.addresses.delete(customerAddress.address.id)
      const updatedCustomer = await retrieveCustomer(clClient, customer.id)
      const action = {
        type: customerActionType.DELETE_CUSTOMER_ADDRESS,
        payload: {
          customer: updatedCustomer,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * Update customer data.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {CustomerUpdate} customer CL customer update data
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerAction|ApiErrorAction>} Thunk action or error action
 */
export const updateCustomerInformation = (clClient, customer = null, callbacks) => {
  return async (dispatch) => {
    try {
      await clClient.customers.update(customer)
      const updatedCustomer = await retrieveCustomer(clClient, customer.id)
      const action = {
        type: customerActionType.UPDATE_CUSTOMER_INFORMATION,
        payload: {
          customer: updatedCustomer,
        },
      }

      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

/**
 * @typedef {Object} CustomerPassResetTokenActionPayload
 * @property {String} resetToken CL customer password reset token
 */

/**
 * @typedef {Object} CustomerPassResetTokenAction
 * @property {customerActionType} type Action type key
 * @property {CustomerPassResetTokenActionPayload} payload Action input data
 */

/**
 * Request customer password reset token.
 *
 * @param {CommerceLayerClient} clClient CL API client
 * @param {String} email CL customer email
 * @param {Callbacks=} callbacks
 * @return {ThunkAction.<void, any, null, CustomerPassResetTokenAction|ApiErrorAction>} Thunk action or error action
 */
export const getCustomerPassResetToken = (clClient, email, callbacks) => {
  return async (dispatch) => {
    try {
      const reset = await clClient.customer_password_resets.create({
        customer_email: email,
      })

      // console.log('PASS RESET: ', reset)
      const action = {
        type: customerActionType.GET_CUSTOMER_PASS_RESET_TOKEN,
        payload: {
          resetToken: reset.reset_password_token,
        },
      }
      dispatch(action)
      invokeSuccessCallback(callbacks, action)
    } catch (error) {
      handleApiError(dispatch, error)
      invokeErrorCallback(callbacks, error)
    }
  }
}

import React from 'react'
import List from '@mui/material/List'
import { Title } from '@ggs/components/paragraphs'
import { CartLineItem } from '@ggs/components/ecomm'
import { find, isEmpty } from 'lodash'
import { useI18n } from '@ggs/gatsby/lib'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 */

/**
 * @typedef {Object} OrderProp
 * @property {Order} order
 */

/**
 * Shopping cart line items
 *
 * @param {OrderProp} props CL draft order
 * @return {JSX.Element}
 */
export default function CartItemList({ order }) {
  const { t } = useI18n()
  const cartIsEmpty = !order || !Array.isArray(order.line_items) || isEmpty(order.line_items)
  const hasIncompleteItems = !!find(order?.line_items, ['metadata'])

  return cartIsEmpty ? (
    <Title title={t('ecomm:cart.label.cartIsEmpty')} style="h4" />
  ) : (
    <List
      sx={{
        maxWidth: '100%',
        p: '0',
      }}
    >
      {order.line_items.map((lineItem) => {
        return lineItem.item_type === 'skus' ? (
          <CartLineItem key={lineItem.id} order={order} lineItem={lineItem} variant={'full'} />
        ) : null
      })}
      {hasIncompleteItems && <p>{t('ecomm:cart.notice.itemsCantBeRetrieved')}</p>}
    </List>
  )
}

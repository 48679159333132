import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useI18n, useLocalizedList } from '@ggs/gatsby/lib'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { ProductPrice } from '@ggs/components/ecomm'
// Assets
import './FrequentlyBoughtProducts.scss'
import { Link } from '@ggs/gatsby/components/nav'
import { colors } from '@ggs/styles'
import { uniqueId } from 'lodash'
import striptags from 'striptags'
import { ViewProductListObserver } from '@ggs/components/hooks/ViewProductListObserver'

/**
 * @typedef {import('@ggs/types').SalableProduct} SalableProduct
 */
/**
 * @typedef {Object} FrequentlyBoughtProducts
 * @property {Array<SalableProduct>=} products
 */
/**
 A list of mini product card. The product data under the frequentlyBought data point will be condensed to only
 the required fields for this section as defined in lib/graphql/fragments/nodes/productCardLite.graphql
 Since this is an isolated usage of the product as a card, of no value anywhere else, this component will just
 render out the micro data inside itself
 * @param {FrequentlyBoughtProducts} props
 * @return {JSX.Element}
 */
export default function FrequentlyBoughtProducts({ products, productData }) {
  const { t } = useI18n()
  /** @type {Array<SalableProduct>} */
  const listing = useLocalizedList(products)
  // Disable if no related products.
  if (!Array.isArray(listing) || !listing.length) {
    return null
  }
  const item_list_name = `FrequentlyBoughtProducts--${
    productData?.product?.entityId ?? productData?.product?.id
  }`
  return (
    <Box
      className="frequently-bought"
      sx={{
        mb: {
          xs: 4,
          md: 0,
        },
        '& .frequently-bought__title': {
          mb: 3,
        },
        '& .frequently-bought__product-name': {
          wordBreak: 'break-word',
          mb: 1,
        },
        '& .frequently-bought__product-name a': {
          color: colors.black,
        },
      }}
    >
      <div className="frequently-bought__title">
        {t('ecomm:productListing.label.frequentlyBoughtWith')}
      </div>
      <ViewProductListObserver
        items={products}
        item_list_name={item_list_name}
        item_list_id={item_list_name}
      >
        <Grid
          container
          spacing={{
            xs: 2,
            lg: 5,
          }}
        >
          {listing.map((product) => (
            <Grid item key={uniqueId(`frequently-bought__product_${product.id}`)} xs={12} md={6}>
              <Grid
                container
                spacing={2}
                alignItems={{
                  xs: 'center',
                  md: 'start',
                }}
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
              >
                <Grid className="frequently-bought__product-image" item xs={12}>
                  <Link
                    href={product.url}
                    aria-label={t('global:button.viewProduct', {
                      productName: striptags(String(product.name)),
                    })}
                    data-label={t('global:button.viewProduct', {
                      productName: striptags(String(product.name)),
                    })}
                    data-action-type="navigate-frequently-bought-product"
                  >
                    <ImageStyles {...product.listingImage} selectedStyles={['productCard']} />
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <div className="frequently-bought__product-name">
                    <Link
                      href={product.url}
                      data-label={t('global:button.viewProduct', {
                        productName: striptags(String(product.name)),
                      })}
                      data-action-type="navigate-frequently-bought-product"
                    >
                      <span dangerouslySetInnerHTML={{ __html: product.name }} />
                    </Link>
                  </div>
                  <ProductPrice
                    className="frequently-bought__product-price"
                    defaultOption={product.defaultOption}
                    productOptions={product.productOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </ViewProductListObserver>
    </Box>
  )
}

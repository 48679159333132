import React from 'react'
import { uniqueId } from 'lodash'
import Stack from '@mui/material/Stack'
import { Component } from '@ggs/gatsby/components'
// Assets
import useStackedContentSx from './useStackedContentSx'

/**
 * @typedef {import('./useStackedContentSx').StackedContentProps} StackedContentProps
 */

/**
 *
 * @param {StackedContentProps} props
 * @return {JSX.Element|null}
 */
export default function StackedContent(props) {
  const { spacing = { xs: 4, md: 5 }, content } = props
  const sx = useStackedContentSx(props)

  return Array.isArray(content) ? (
    <Stack className="paragraph-content" sx={sx} spacing={spacing}>
      {content.map((item) =>
        item?.type ? (
          <Component
            key={uniqueId(`paragraph_content_${item?.id}`)}
            wrapperClassName={'paragraph-content__item'}
            {...item}
          />
        ) : null
      )}
    </Stack>
  ) : null
}

import React from 'react'
import { ImageStyles } from '@ggs/gatsby/components/media'

// Assets.
import './EventSpeaker.scss'
import { Title } from '@ggs/components/paragraphs'

/**
 * @typedef {Object} EventSpeakerProps
 * @property {Number=} id
 * @property {String=} title
 * @property {String=} biography
 * @property {ImageStyles=} speakerPortrait
 */
/**
 * EventSpeaker component.
 * @param {EventSpeakerProps} props
 * @return {JSX.Element}
 */
export default function EventSpeaker({
  title = '',
  biography = '',
  speakerPortrait
}) {
  return (
    <div className="event-speaker">
      {speakerPortrait && (
        <div className="event-speaker__image">
          <ImageStyles {...speakerPortrait} selectedStyles={['eventSpeaker']} />
        </div>
      )}
      <div className="event-speaker__content">
        <Title title={title} subtitle={biography} style="h4" />
      </div>
    </div>
  )
}
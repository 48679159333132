import { useMemo } from 'react'
import { colors } from '@ggs/styles'
import { get } from 'lodash'

/**
 * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */

/**
 * Common buttons sx styles.
 * @param {string} colorKey Palette color key
 * @return {SxProps}
 */
export default function useFullWidthButtonSx(colorKey) {
  return useMemo(
    () => ({
      width: '100%',
      '& .button.MuiButton-root': {
        width: '100%',
      },
      '.button.MuiButton-root > span': { color: get(colors, colorKey) }
    }),
    []
  )
}

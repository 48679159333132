// @ts-nocheck
import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const propTypes = {
  checkboxItemLabel: PropTypes.string,
}

const defaultProps = {
  checkboxItemLabel: '',
}

const AccordionCheckboxItem = ({ checkboxItemLabel, checked, onChange, name, value, disabled }) => {
  const [isChecked, setIsChecked] = useState(checked)

  const handleChange = (event) => {
    setIsChecked(!isChecked)
    onChange(event)
  }

  useEffect(() => {
    if (disabled) {
      setIsChecked(false)
    }
  }, [disabled])

  return (
    <li key={`${checkboxItemLabel}`} className="accordion-checkbox-list-itemlist">
      <FormControlLabel
        aria-label={`${checkboxItemLabel}`}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <Checkbox
            name={name}
            onChange={handleChange}
            value={checkboxItemLabel}
            disabled={disabled}
            checked={isChecked}
          />
        }
        label={`${checkboxItemLabel}`}
      >
        {checkboxItemLabel}
      </FormControlLabel>
    </li>
  )
}

AccordionCheckboxItem.propTypes = propTypes
AccordionCheckboxItem.defaultProps = defaultProps

export default AccordionCheckboxItem

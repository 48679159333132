import { filter, find, first, sortBy } from 'lodash'
import { useSelector } from '@ggs/store'

/**
 * @typedef {import('@ggs/types').ProductOption} ProductOption
 * @typedef {import('@ggs/types').ProductOptionSku} ProductOptionSku
 */

/**
 * @typedef {Object} ProductPriceProps
 * @property {String=} className
 * @property {ProductOption=} currentOption
 * @property {ProductOption} defaultOption
 * @property {Array<ProductOption>=} productOptions
 */

/**
 * @typedef {import('@commercelayer/sdk').Price} Price
 * @typedef {import('@commercelayer/sdk').Sku} Sku
 */

/**
 * @typedef {Object} SkuLookupHook
 * @property {function} lookupProductOptionCurrentMarketSku Lookup Drupal product option sku for current market
 * @property {function} lookupProductOptionPrice Lookup CL price entity for a product option
 * @property {function} lookupProductOptionLowerPrice Lookup CL lowest price entity for a product option
 * @property {function} lookupProductOptionSku Lookup CL SKU for a product option
 * @property {function} lookupSku Lookup CL SKU by code
 */

/**
 * Lookup SKU related data from commerce SKUs state.
 * @return {SkuLookupHook}
 */
export function useSkuLookup() {
  const skus = useSelector((state) => state.commerce.skus)
  const currentMarket = useSelector((state) => state.settings.currentMarket)

  /**
   * Lookup product option sku for current (selected) market.
   * @param {ProductOption} productOption Product option to use for SKU lookup
   * @return {String} Product option SKU code, empty string when no SKU match
   */
  const lookupProductOptionCurrentMarketSku = (productOption) => {
    const productOptionSkus = productOption?.sku || []
    // console.log('ProductOptionsSkus:', productOptionSkus)
    const productSkuMatch = find(productOptionSkus, (productOptionSku) => {
      const currentMarketId = currentMarket?.marketId
      return (
        productOptionSku?.enabled && String(productOptionSku?.market?.marketId) === String(currentMarketId)
      )
    })

    const skuMatch = productSkuMatch?.sku

    if (!skuMatch) {
      // console.log('Not found any SKU match for productOption: ', productOption)
    }

    // console.log('Product option current market SKU: ', productSkuMatch)
    return skuMatch
  }

  /**
   * Resolve the CL SKU by code.
   * @param {String} code SKU code
   * @return {Sku|null} CL SKU entity when found, null otherwise.
   */
  const lookupSku = (code) => {
    const skuMatch = skus[code]
    if (Array.isArray(skuMatch)) {
      return skuMatch[0]
    }

    return null
  }

  /**
   * Resolve the CL SKU for a product option.
   * @param {ProductOption} productOption
   * @return {Sku|null} CL SKU entity when found, null otherwise.
   */
  const lookupProductOptionSku = (productOption) => {
    const sku = lookupProductOptionCurrentMarketSku(productOption)
    return lookupSku(sku)
  }

  /**
   * Resolve the CL SKU price for a product option.
   * @param {ProductOption} productOption
   * @return {Price|null} CL SKU entity when found, null otherwise.
   */
  const lookupProductOptionPrice = (productOption) => {
    const skuEntity = lookupProductOptionSku(productOption)
    return Array.isArray(skuEntity?.prices) ? first(skuEntity.prices) : null
  }

  /**
   * Resolve the CL SKU win lowest price from product options array.
   * @param {Array<ProductOption>} productOptions
   * @return {Price|null} CL SKU entity when found, null otherwise.
   */
  const lookupProductOptionLowerPrice = (productOptions) => {
    const productOptionSkuPrices = filter(
      productOptions.map((productOption) => {
        return lookupProductOptionPrice(productOption)
      })
    )

    if (Array.isArray(productOptionSkuPrices)) {
      const sortedSkuPrices = sortBy(productOptionSkuPrices, 'amount_cents')
      return first(sortedSkuPrices)
    }

    return null
  }

  return {
    lookupProductOptionCurrentMarketSku,
    lookupProductOptionLowerPrice,
    lookupProductOptionPrice,
    lookupProductOptionSku,
    lookupSku,
  }
}

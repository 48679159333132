import { DETECT_LANGUAGE, GEOCODE_LATLON, GET_USER_GEOLOCATION } from '@ggs/commercelayer/index'
import { initialState } from '../initialState'
import { createReducer } from '@reduxjs/toolkit'
import { settingsActionType } from '../actions/settings'

/**
 * @returns {StateSettingsType} New Settings state.
 */
const settings = createReducer(initialState.settings, {
  [GET_USER_GEOLOCATION]: (state, action) => {
    state.geolocation = action.payload
  },

  [GEOCODE_LATLON]: (state, action) => {
    state.country = action.payload.country
  },

  [DETECT_LANGUAGE]: (state, action) => {
    state.language = action.payload.language
  },

  [settingsActionType.SET_CURRENT_MARKET]: (state, action) => {
    state.currentMarket = action.payload
  },

  [settingsActionType.SET_CURRENT_LANGUAGE]: (state, action) => {
    state.language = action.payload.language
  },

  [settingsActionType.CLEAR_NOTICE]: (state, action) => {
    state.clearedNotices.push(action.payload)
  },

  [settingsActionType.SET_PREFERRED_STORE_NOTICE]: (state, action) => {
    state.hidePreferredStoreNotice = action.payload.status
  },
})

export default settings

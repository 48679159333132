import { useI18n } from '@ggs/gatsby/lib'
import { colors } from '@ggs/styles'
import { numberFormatter } from '@ggs/utils'
import Typography from '@mui/material/Typography'
import { first, get } from 'lodash'
import React from 'react'
import useCodeTranslationMap from '../../hooks/useCodeTranslationMap'
import { useShippingMethodLabelMaker } from '@ggs/components/ecomm/Customer/useShippingMethodLabelMaker'
import { useLayoutMeta } from '@ggs/hooks'

/**
 * @typedef {import('@commercelayer/sdk').Order} Order
 * @typedef {import('@commercelayer/sdk').ShippingMethod} ShippingMethod
 * @typedef {import('@ggs/components/ecomm/Customer/useShippingMethodLabelMaker').OrderShippingLabels}
 * OrderShippingLabels
 */

/**
 * @typedef {Object} OrderCostLabels
 * @property {*|string} shippingTotal
 * @property {*|string} discountTotal
 * @property {*|string} taxTotal
 * @property {*|string} subTotal
 * @property {*|string} orderTotal
 */

/**
 * @typedef {Object} useOrderTotalsProps
 * @property {boolean} hasShippingMethod
 * @property {Order} order
 */

/**
 * @param {useOrderTotalsProps} props
 * @return {function}
 */
const useOrderTotals = ({ hasShippingMethod, order }) => {
  const { t } = useI18n()
  const { lang } = useLayoutMeta()
  const currencyFormatter = numberFormatter.currency(order?.currency_code, lang)

  /**
   *  @param {Order} order
   *  @return {OrderCostLabels}
   **/
  return (order) => {
    const shippingTotal = hasShippingMethod
      ? order.shipping_amount_cents > 0
        ? currencyFormatter(order?.shipping_amount_float)
        : t('global:label.free')
      : t('ecomm:cart.label.appliedDuringCheckout')
    const taxTotal = order?.billing_address
      ? currencyFormatter(order.total_tax_amount_float)
      : t('ecomm:cart.label.appliedDuringCheckout')
    const discountTotal = `${currencyFormatter(order?.discount_amount_float)}`
    const subTotal = `${currencyFormatter(order?.subtotal_amount_float)}`
    const orderTotal = `${order?.currency_code} ${currencyFormatter(
      order?.total_amount_with_taxes_float
    )}`

    return {
      shippingTotal,
      taxTotal,
      discountTotal,
      subTotal,
      orderTotal,
    }
  }
}

/**
 * @typedef {Object} UseOrderLabelsProps
 * @property {OrderShippingLabels} shippingMethod
 * @property {OrderCostLabels} totals
 */

/**
 *
 * @param {Order} order
 * @return {UseOrderLabelsProps}
 */
export default function useOrderLabels(order) {
  const mapCodeToTranslation = useCodeTranslationMap()
  // const currencyFormatter = numberFormatter.currency(order?.currency_code, lang)
  const shipments = first(order?.shipments)
  const shippingMethod = shipments?.shipping_method
  const hasShippingMethod = Boolean(get(order, 'shipments[0].shipping_method'))

  const orderTotalsLabelMaker = useOrderTotals({ hasShippingMethod, order })
  const shippingMethodLabelMaker = useShippingMethodLabelMaker(order)

  const fulfillmentStatus = order?.fulfillment_status ? (
    <Typography
      className={'status'}
      variant={'caption'}
      textTransform={'capitalize'}
      sx={{
        backgroundColor: colors.blue,
        color: colors.white,
        p: 1,
        pt: 0.5,
        pb: 0.5,
        ml: 2,
      }}
    >
      {mapCodeToTranslation(order?.fulfillment_status.toLowerCase())}
    </Typography>
  ) : null

  const labels = {
    shippingMethod: {
      ...shippingMethodLabelMaker(shippingMethod),
      fulfillmentStatus,
    },
    totals: {
      ...orderTotalsLabelMaker(order),
    },
  }

  console.log('useOrderLabels', labels)

  return labels
}

import React from 'react'
import { persistor, store } from '@ggs/store/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

/**
 * @typedef {Object} StoreProviderProps
 * @property {Element|JSX.Element} children
 */

/**
 *
 * @param {StoreProviderProps} props
 * @return {JSX.Element}
 */
export function StoreProvider({ children }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  )
}

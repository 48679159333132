import React from 'react'

/**
 // * @typedef {import('@mui/system/styleFunctionSx').SxProps} SxProps
 */
/**
 * @typedef {Object} AnchorProps
 // * @property {SxProps=} sx
 * @property {String=} label
 */
/**
 * A simple text divider.
 * @param {AnchorProps} props
 * @return {JSX.Element}
 */
export default function Anchor({ label }) {
  return <div className="anchor" id={label}/>
}

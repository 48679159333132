import React, { useEffect, useState } from 'react'
import { useFindModalForCustomerType } from '@ggs/components/hooks'
import { useSelector } from '@ggs/store'

/**
 * @typedef {import('@ggs/types').CustomerModal} CustomerModal
 */
/**
 * @param {CustomerModal[]} modals
 * @return {number|null}
 */
export default function useCustomerModal(modals) {
  const [activeIndex, setActiveIndex] = useState(null)
  const findModalForCustomerType = useFindModalForCustomerType()
  const customer = useSelector((state) => state.commerce.customer)

  // React if either the modals change, or the customerType does
  useEffect(() => {
    const activeIndex = findModalForCustomerType(modals)
    if (activeIndex || activeIndex === false || activeIndex === 0) {
      setActiveIndex(activeIndex)
    }
  }, [modals, customer.customerType])

  return activeIndex
}

import * as Sentry from '@sentry/gatsby'

const options = {
  dsn: 'https://0c76a56b16434d47babd4edffa9e1b7b@o1220772.ingest.sentry.io/6363887',
  debug: true,
  sampleRate: 0.7, // Adjust this value in production
  beforeSend(event) {
    // Modify the event here
    if (event.user) {
      // Don't send user's email address
      delete event.user.email
    }
    return event
  },
  // ...
}

if(process.env.GATSBY_SENTRY_RELEASE) {
  options.release = process.env.GATSBY_SENTRY_RELEASE
}

Sentry.init(options)

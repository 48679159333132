const React = require('react')
// @ts-ignore
import { ThemeProvider } from 'emotion-theming'
import { muiTheme } from '@ggs/styles'
import RootProviders from './RootProviders'

/**
 * @typedef {Object} RootProvidersProps
 * @property {Element|JSX.Element} children
 */

/**
 * Root providers for Storybook, due to some third party library conflict between Storybook and MUI we need to wrap
 * the webapp element within emotion ThemeProvider in order to make all the theme settings works properly in stories.
 * @see https://github.com/mui-org/material-ui/issues/24282
 * @param {RootProvidersProps} props
 * @return {JSX.Element}
 */
export default function StorybookRootProviders({ children }) {
  return (
    <RootProviders>
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </RootProviders>
  )
}

import { clearActiveOrder, orderActionType, removeOrderItems, updateOrderItems } from '@ggs/commercelayer'
import { ProgressIndicator } from '@ggs/components/common'

import { ProductOption, QuantitySelect } from '@ggs/components/ecomm'
import useCartLineItemSx from '@ggs/components/ecomm/Cart/hooks/useCartLineItemSx'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { useSkuLookup } from '@ggs/components/ecomm/hooks'
import useQuantitySelect from '@ggs/components/hooks/useQuantitySelect'
import { Button, Link } from '@ggs/gatsby/components/nav'
import { useI18n } from '@ggs/gatsby/lib'
import { addNotification } from '@ggs/store'
import { numberFormatter } from '@ggs/utils'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import striptags from 'striptags'
import { useLayoutMeta } from '@ggs/hooks'
import { useProductLink } from '@ggs/components/nav/StoreSelector/useLinkChangeLanguage'
import { triggerSentry } from '@ggs/gatsby/sentry'
import { Severity } from '@sentry/gatsby'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'
import { mapItemsToGTM } from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/mapItemsToGTM'

/**
 * @typedef {import("@commercelayer/sdk").LineItem} LineItem
 * @typedef {import("@commercelayer/sdk").Order} Order
 * @typedef {import("@commercelayer/sdk").Sku} Sku
 */

/**
 * @typedef {Object} CartLineItemProps
 * @property {Order=} order
 * @property {LineItem} lineItem
 * @property {('mini' | 'full')=} variant
 */

/**
 * Shopping cart line item display.
 * @param {CartLineItemProps} props
 * @return {JSX.Element}
 */
const CartLineItem = ({ order, lineItem, variant = 'mini' }) => {
  const sx = useCartLineItemSx(variant)
  const { resetCheckoutExceptShippingAddress, clClient } = useCheckoutContext()
  const { alias, id, metadata, quantity: existingQuantity, sku_code } = lineItem
  const { name } = metadata
  const { t } = useI18n()
  const dispatch = useDispatch()
  // const store = useStore()
  const { lookupSku } = useSkuLookup()
  const currentSku = lookupSku(sku_code)
  const [removing, setRemoving] = useState(false)
  const { lang } = useLayoutMeta()
  const currency_code = order?.currency_code
  const currencyFormatter = numberFormatter.currency(currency_code, lang)

  // TODO: handle via sentry.io life cycle checking for fault points on specific
  // line items for missing metadata.
  const { currentQuantity, quantityOptions, handleQuantitySelection }
    = useQuantitySelect(currentSku)

  const { fireAddToCart, fireRemoveFromCart } = useCheckoutAnalytics({ order })


  /**
   * Handle line item quantity update.
   * @param {React.ChangeEvent<HTMLInputElement>} e Change event
   */
  const handleUpdate = (e) => {
    handleQuantitySelection(e)
    const { target } = e
    const value = target?.value
    console.log(existingQuantity)
    /**
     * On success action callback.
     * @param {any} action
     */
    const onSuccess = (action) => {
      if (action.type === orderActionType.UPDATE_ORDER_ITEMS) {
        resetCheckoutExceptShippingAddress()
        // add to cart

        // dispatch(
        //   addNotification(
        //     orderActionType.UPDATE_ORDER_ITEMS,
        //     'success',
        //     t('ecomm:cart.notification.quantityUpdated')
        //   )
        // )
        triggerSentry({
          name: 'addToCartSuccess',
          actionPerformed: 'add_to_cart_success',
          level: Severity.Info,
        })
      }
    }

    /**
     * Handle failures
     * @param {Error} error
     */
    const onError = (error) => {
      dispatch(clearActiveOrder())
      triggerSentry({
        name: 'addToCartError',
        error: error,
        actionPerformed: 'add_to_cart_failure',
        level: Severity.Error,
      })
      console.log('addCouponCode error', {
        error,
      })
    }


    if (value) {
      const updateLineItem = {
        id: lineItem.id,
        quantity: Number(value),
      }

      if (value < existingQuantity) {
        fireRemoveFromCart({
          gtmProduct: mapItemsToGTM(lineItem),
          quantity: existingQuantity - value,
          value: parseFloat((existingQuantity - value) * lineItem?.unit_amount_float).toFixed(2),
          order
        })
      } else {
        fireAddToCart({
          gtmProduct: mapItemsToGTM(lineItem),
          quantity: value - existingQuantity,
          value: parseFloat((value - existingQuantity) * lineItem?.unit_amount_float).toFixed(2),
          order
        })
      }

      dispatch(
        updateOrderItems(clClient, order, [updateLineItem], {
          onSuccess,
          onError
        })
      )
    }
  }

  /**
   * Handle order item remove.
   */
  const handleRemove = () => {
    // console.log('Removing line item: ', lineItem)

    // Order don't exits, cannot continue.
    if (!order) {
      // console.log('Order not found in state, cannot remove the item')
      return
    }

    /**
     * On success action callback.
     * @param {any} action
     */
    const onSuccess = (action) => {
      if (action.type === orderActionType.REMOVE_ORDER_ITEMS) {
        resetCheckoutExceptShippingAddress()
        fireRemoveFromCart({
          gtmProduct: mapItemsToGTM(lineItem),
          quantity: existingQuantity,
          value: lineItem.unit_amount_float,
          currency_code: lineItem.currency_code
        })

        // remove from cart
        dispatch(
          addNotification(
            orderActionType.REMOVE_ORDER_ITEMS,
            'success',
            t('ecomm:cart.notification.couponRemoved')
          )
        )
      }
    }
    /**
     * Handle failures
     * @param {Error} error
     */
    const onError = (error) => {
      dispatch(clearActiveOrder())
      triggerSentry({
        name: 'removeFromCartError',
        error: error,
        actionPerformed: 'remove_from_cart_failure',
        level: Severity.Error,
      })
      console.log('addCouponCode error', {
        error,
      })
    }

    setRemoving(true)
    dispatch(
      removeOrderItems(clClient, order, [lineItem.id], {
        onSuccess,
        onError
      })
    )
  }

  const variantFull = variant === 'full'

  // console.log('lineItem', lineItem)

  const listItemContent = (
    <Box
      sx={{
        '.product-option': {
          '*': {
            maxWidth: '100%',
          },
        },
      }}
    >
      {metadata.subtitle && (
        <p
          className="cart-mini-view__item-subtitle"
          dangerouslySetInnerHTML={{ __html: metadata.subtitle }}
        />
      )}
      {metadata?.includedItems?.length ? (
        <Grid container>
          <Grid item xs={'auto'}>
            <p className="cart-mini-view__item-included">
              {t('ecomm:cart.label.includedWithThisProduct')}
            </p>
          </Grid>
          {metadata.includedItems.map((/** @type {String} */ label) => (
            <Grid item key={label}>
              <ProductOption
                // @ts-ignore
                option={{ label }}
                variant="included"
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
      {metadata?.optionLabel && (
        <ProductOption
          variant="cart"
          // @ts-ignore
          option={{
            label: metadata.optionLabel,
            color: metadata.color,
          }}
          component="p"
        />
      )}
      <p className="cart-mini-view__item-quantity">
        {variant === 'mini' && <span>{lineItem.quantity} x</span>}
        {currencyFormatter(lineItem.unit_amount_float)} {lineItem.currency_code}
      </p>
      {variantFull && (
        <Box marginTop={2}>
          <QuantitySelect
            existingQuantity={existingQuantity}
            currentQuantity={existingQuantity}
            quantityOptions={quantityOptions}
            handleQuantitySelection={handleUpdate}
          />
        </Box>
      )}
    </Box>
  )

  const itemName = <span dangerouslySetInnerHTML={{ __html: name }}/>
  const itemImage = <Avatar src={metadata?.image} alt={name} variant={'square'}/>

  console.log('getting product', metadata.url, alias, lineItem)

  const itemLink = useProductLink({ entityId: metadata.url, productLink: metadata.url })

  return (
    <ListItem key={id} sx={sx}>
      <ListItemAvatar className="cart-line-item__avatar-wrapper">
        {variantFull ? (
          <Link
            href={itemLink}
            aria-label={name}>
            {itemImage}
          </Link>
        ) : (
          itemImage
        )}
        {variantFull && !removing && (
          <Button
            className="cart-line-item__remove"
            label={t('ecomm:cart.button.remove')}
            variant={'text'}
            onClick={handleRemove}
            aria-label={t('ecomm:cart.label.removeProductFromCart', {
              productName: striptags(String(name)),
            })}
          />
        )}
        {variantFull && removing && <ProgressIndicator/>}
      </ListItemAvatar>
      <div className="cart-line-item__item-text">
        <div className="cart-line-item__item-name">
          {/*{variantFull*/}
          {/*  ? (<Link href={selectLanguage(userLanguage)} aria-label={name}>{itemName}</Link>) : itemName}*/}
          {variantFull
            ? (
              <Link
                href={itemLink}
              >
                {itemName}
              </Link>)
            : itemName}
        </div>
        <div className="cart-line-item__item-details">{listItemContent}</div>
      </div>
    </ListItem>
  )
}

export default CartLineItem
